import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import { AppConstant, translate } from '@shalina-app/shared';

import { InputPropsType } from '.';
import { CountryDropdown, Dropdown } from '..';
import styles from './input.module.scss';

export const MobileInput: React.FC<InputPropsType> = (props) => {
  const {
    defaultCountryCode,
    formControlVariant,
    labelText,
    inputId,
    inputVariant,
    isRequired,
    dropdownOptions,
    dropdownVariant,
    isError,
    errorMessage,
    countryCodeError,
    countryValue,
    mobileValue,
    handleChange,
    handleCountryCodeChange,
    maxMobileLength,
    handleOnBlur,
    countryFlag,
    placeHolderText
  } = props;

  const t = translate();

  return (
    <div className={styles.shalinaMobileinputWrapper}>
      <FormControl fullWidth variant={formControlVariant}>
        <label htmlFor={inputId}>
          {labelText}
          {isRequired && <span>*</span>}
        </label>
        <div className={styles.shalinaMobileinput}>
          <CountryDropdown
            defaultValue={defaultCountryCode}
            value={countryValue}
            dropdownId='grouped-select'
            dropdownVariant={dropdownVariant}
            dropdownOptions={dropdownOptions}
            className='countrySelect'
            isError={countryCodeError}
            errorMessage={t(AppConstant.ERROR.INCORRECT)}
            handleDropDownChange={handleCountryCodeChange}
            countryFlag={countryFlag}
          />
          <TextField
            placeholder={placeHolderText ? placeHolderText : ''}
            id={inputId}
            variant={inputVariant}
            error={isError}
            onChange={handleChange}
            value={mobileValue}
            inputProps={{ maxLength: maxMobileLength }}
            onBlur={handleOnBlur}
          />
        </div>
        {isError && <FormHelperText error>{errorMessage}</FormHelperText>}
      </FormControl>
    </div>
  );
};
