import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { api } from '@shalina-app/shared';

import { retrieveData } from '../../../../../utils';
import { useNavigatePageUtils } from '../../../../../utils';
import { URLConstant } from '../../../../../routes';

const ReferralRouting = () => {
  const token = JSON.parse(retrieveData('access_token'));
  const navigatePage = useNavigatePageUtils();
  const { urls } = URLConstant;

  const param = useParams();

  const navigateDetails = {
    stateParams: {
      referralCode: `${param?.referralId}`
    },
    isDynamicUrl: false
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (token) {
      api.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      navigatePage(urls.homePage, navigateDetails);
    } else {
      api.defaults.headers.common['Authorization'] = '';
      navigatePage(urls.signUpPage, navigateDetails);
    }
  }, [token]);

  return <div></div>;
};

export default ReferralRouting;
