import styles from './helpDesk.module.scss';
import { HelpDeskPropsType } from './helpDesk.interface';

export const HelpDesk: React.FC<HelpDeskPropsType> = ({
  handleClickOpen,
  handleClose,
  handleHelpAction,
  open,
  toggleRequestHelpDesk
}) => {
  return (
    <div className={styles.helpdesk}>
      <span className={styles.helpdeskCta} onClick={handleClickOpen}>
        <svg
          width='33'
          height='33'
          viewBox='0 0 33 33'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M14.85 26.4H18.15V23.1H14.85V26.4ZM16.5 0C7.392 0 0 7.392 0 16.5C0 25.608 7.392 33 16.5 33C25.608 33 33 25.608 33 16.5C33 7.392 25.608 0 16.5 0ZM16.5 29.7C9.2235 29.7 3.3 23.7765 3.3 16.5C3.3 9.2235 9.2235 3.3 16.5 3.3C23.7765 3.3 29.7 9.2235 29.7 16.5C29.7 23.7765 23.7765 29.7 16.5 29.7ZM16.5 6.6C12.8535 6.6 9.9 9.5535 9.9 13.2H13.2C13.2 11.385 14.685 9.9 16.5 9.9C18.315 9.9 19.8 11.385 19.8 13.2C19.8 16.5 14.85 16.0875 14.85 21.45H18.15C18.15 17.7375 23.1 17.325 23.1 13.2C23.1 9.5535 20.1465 6.6 16.5 6.6Z'
            fill='white'
          />
        </svg>
      </span>
    </div>
  );
};
