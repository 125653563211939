import React, { useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import styles from './toast.module.scss';
import { TOAST_MESSAGE_HIDE_DURATION } from '../../app.constants';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export const Toast: React.FC<any> = ({
  showToast,
  isSuccess,
  message,
  subMessage,
  handleToastClose
}) => {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(showToast);
  }, [showToast]);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    handleToastClose();
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={
          isSuccess
            ? TOAST_MESSAGE_HIDE_DURATION.SUCCESS
            : TOAST_MESSAGE_HIDE_DURATION.FAILURE
        }
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        className={styles.shalinaToast}
      >
        <Alert
          onClose={handleClose}
          severity={isSuccess ? 'success' : 'error'}
          sx={{ width: '100%' }}
        >
          <h4 className={styles.toastTitle}>{message}</h4>
          <p className={styles.toastMsg}>{subMessage}</p>
        </Alert>
      </Snackbar>
    </>
  );
};
