import { CircularProgress } from '@mui/material';

import styles from './buttonLoader.module.scss';

interface ButtonLoaderProps {
  loader: boolean;
}

export const ButtonLoader: React.FC<ButtonLoaderProps> = ({ loader }) => {
  return (
    <>
      {loader && (
        <span className={styles.buttonLoader}>
          <CircularProgress
            size={15}
            thickness={4}
            sx={{
              color: 'var(--secondaryOrange)'
            }}
          />
        </span>
      )}
    </>
  );
};
