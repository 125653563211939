import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import {
  addOrUpdateUserGamificationPoints,
  AppConstant,
  azureFile,
  DetailTabEnumType,
  DETAIL_TABS_ENUM,
  GAMIFICATION_ENABLED,
  GAMIFICATION_TYPE_UNIQUE_CODE,
  getAdditionalInfoDetails,
  getBlobFilePath,
  getDefaultImage,
  getDefaultValue,
  getHideAuthor,
  getIconForContentType,
  getTabsEnum,
  handleRecognitionPopup,
  ImageConstant,
  listGamificationPointDistribution,
  RECOGNITION_MESSAGE_TEXT,
  savePageNoLeftWhileReading,
  translate,
  updateUserRecognition,
  useAppDispatch,
  useAppSelector,
} from '@shalina-app/shared';
import parse from 'html-react-parser';
import { Link, useLocation, useParams } from 'react-router-dom';

import { EBookDetailsProps } from '.';
import { ButtonComponent, Speciality } from '../../../..';
import {
  convertToSpecialties,
  getImageSrc,
  retrieveData,
  STORAGE_CONSTANTS,
} from '../../../../../utils';
import { ShareSection } from '../../components/shareSection';
import styles from './eBookDetails.module.scss';
import { URLConstant } from '../../../../../routes';
import { SignUpPopup } from '../../../../../features';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const EBookDetails: React.FC<EBookDetailsProps> = ({
  contentData,
  handleNextStep,
  ctaAction,
}) => {
  const t = translate();
  const location = useLocation();
  const params = useParams();

  const [value, setValue] = useState(0);
  const [reload, setReload] = useState(false);
  const [isStartReadingDisabled, setIsStartReadingDisabled] = useState(true);
  const [accessUrlLoader, setAccessUrlLoader] = useState(false);
  const [accessUrl, setAccessUrl] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const [showSignUp, setShowSignUp] = useState(false);

  const pathName = location?.pathname;
  const contentId = params?.contentId || '';

  const dispatch = useAppDispatch();
  // const [showViews, setShowViews] = useState(true);
  const contentDetails: any = contentData ? contentData : null;

  const isArticleDocLinkLoading = useAppSelector(
    (state) => state?.knowledgehub?.articleDocLink?.isArticleDocLinkLoading
  );
  const gamificationPointDistributionData = useAppSelector(
    listGamificationPointDistribution
  );

  useEffect(() => {
    setAccessUrlLoader(true);
  }, []);

  useEffect(() => {
    if (accessUrlLoader) {
      // loadAccessUrl();
    }
  }, [accessUrlLoader, contentDetails]);

  const loadAccessUrl = async () => {
    if (
      contentDetails &&
      contentDetails?.field_supporting_docs &&
      !contentDetails?.field_is_ebsco
    ) {
      const response = await dispatch(
        azureFile({ fileUrl: contentDetails?.field_supporting_docs }) as any
      ).unwrap();
      if (
        response &&
        response?.statusCode === 200 &&
        response?.status === 'SUCCESS' &&
        response?.data
      ) {
        setAccessUrl(response?.data);
      } else {
        setAccessUrl('');
      }
      setAccessUrlLoader(false);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const {
    ViewsIcon,
    AuthorIcon,
    BookmarkIcon,
    ThumbIcon,
    ShareIcon,
    DateIcon,
  } = ImageConstant.SVG_ICONS;

  const isBookmarked: boolean = contentDetails?.is_bookmarked || false;
  const isLiked: boolean = contentDetails?.is_liked || false;

  const contentIcon: any = useAppSelector(
    getIconForContentType(contentDetails?.field_unique_code)
  );

  useEffect(() => {
    if (contentDetails?.field_is_ebsco && contentDetails?.field_upload_link) {
      setIsStartReadingDisabled(false);
    } else if (contentDetails?.field_supporting_docs) {
      setIsStartReadingDisabled(false);
    } else {
      setIsStartReadingDisabled(true);
    }
  }, [contentDetails]);

  const { urls } = URLConstant;
  const isLoggedIn: boolean = retrieveData(STORAGE_CONSTANTS.IS_LOGGED_IN);
  const shortDesc = contentDetails?.field_description?.substring(0, 330);
  const guestUserDescription = shortDesc ? parse(shortDesc) : '';
  const description = contentDetails?.field_description
    ? parse(contentDetails?.field_description)
    : '';

  const data = getAdditionalInfoDetails(contentDetails?.event_data);
  const hideAuthor = getHideAuthor(
    contentDetails?.field_module_type,
    contentDetails?.field_unique_code
  );

  const saveTimeStamp = (lastReadAt: any) => {
    const payload = {
      id: contentDetails?.id,
      lastreadat: lastReadAt,
    };
    dispatch(savePageNoLeftWhileReading(payload));
  };

  const handleImgClick = () => {
    if (!isLoggedIn) {
      setShowSignUp(true);
    } else {
      setShowSignUp(false);
    }
  };

  const handleStartReading = async () => {
    if(isLoggedIn) {
      await saveTimeStamp(0);
      handleNextStep();
    } else {
      setShowSignUp(true);
    }
  };

  const handleSignUpClose = () => {
    setShowSignUp(false);
  };

  const defaultTabs = [
    {
      id: 0,
      enumId: DETAIL_TABS_ENUM.DESCRIPTION,
      label: t(AppConstant.KNOWLEDGE_HUB_PAGE.BOOK_DESCRIPTION_TAB),
      component: (
        <>
          {isLoggedIn ? (
            <div className={styles.ebookContent}>
              {description
                ? description
                : t(AppConstant.KNOWLEDGE_HUB_PAGE.NO_DESCRIPTION_AVAILABLE)}
            </div>
          ) : (
            <div
              className={`${styles.ebookContent} ${styles.guestDescWrapper}`}
            >
              {guestUserDescription
                ? guestUserDescription
                : t(AppConstant.KNOWLEDGE_HUB_PAGE.NO_DESCRIPTION_AVAILABLE)}
              {'...'}
              <div className={styles.guestDesc}>
                {/* <Link to={urls.signUpPage} state={{ pathName: pathName }}>
                  <button>
                    {t(AppConstant.AUTHENTICATION_PAGE.SIGN_UP_TO_CONTINUE)}
                  </button>
                </Link> */}
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      id: 1,
      enumId: DETAIL_TABS_ENUM.ADDITIONAL_INFO,
      label: t(AppConstant.COMMON.ADDITIONAL_INFO),
      component: (
        <div
          className={`${styles.bookDetail} ${
            !isLoggedIn && styles.guestDescWrapper
          }`}
        >
          {!isLoggedIn && (
            <div className={styles.guestDesc}>
              {/* <Link to={urls.signUpPage} state={{ pathName: pathName }}>
                <button>
                  {t(AppConstant.AUTHENTICATION_PAGE.SIGN_UP_TO_CONTINUE)}
                </button>
              </Link> */}
            </div>
          )}
          {data && data?.length > 0 ? (
            data?.map((item: any) => (
              <p>
                <label>{item?.title}</label>
                <span>{item?.value}</span>
              </p>
            ))
          ) : (
            <div className={styles.notFoundText}>
              <p>{t(AppConstant.COMMON.NO_ADDITIONAL_INFO_AVALIABLE)}</p>
            </div>
          )}
        </div>
      ),
    },
  ];

  const tabsEnum: any = getTabsEnum(
    contentDetails?.field_unique_code,
    contentDetails?.field_module_type
  );

  const tabs = tabsEnum?.length
    ? defaultTabs?.filter((item) =>
        tabsEnum?.includes(item.enumId as DetailTabEnumType)
      )
    : defaultTabs;

  return (
    <>
      <div className={`${styles.detailPage}`}>
        <div className={styles.detailTop}>
          <div className={styles.detailImg}>
            {contentDetails?.field_is_ebsco ? (
              <img
                className={!isLoggedIn ? styles.thumbnailImageGuest : ''}
                src={
                  contentDetails?.field_thumbnail_image
                    ? getImageSrc(contentDetails?.field_thumbnail_image)
                    : getDefaultImage(
                        true,
                        contentDetails?.field_module_type,
                        contentDetails?.field_unique_code
                      )
                }
                alt='card img'
                onError={(e) =>
                  ((e.target as HTMLInputElement).src =
                    ImageConstant.SOURCE.pdfDetailThumbnail)
                }
                onClick={handleImgClick}
              />
            ) : (
              <img
                className={!isLoggedIn ? styles.thumbnailImageGuest : ''}
                src={
                  contentDetails?.field_detail_image
                    ? getImageSrc(contentDetails?.field_detail_image)
                    : getDefaultImage(
                        true,
                        contentDetails?.field_module_type,
                        contentDetails?.field_unique_code
                      )
                }
                alt='card img'
                onError={(e) =>
                  ((e.target as HTMLInputElement).src =
                    ImageConstant.SOURCE.pdfDetailThumbnail)
                }
                onClick={handleImgClick}
              />
            )}

            {/* <div className={styles.dateView}>
              <div className={styles.views}>
                <span className={styles.icon}>
                  <DateIcon />
                </span>
                <span className={styles.text}>
                  {t(AppConstant.COMMON.DATE)}
                </span>
                {getOnlyDate(contentDetails?.field_authored_on)}
              </div>

              <div className={styles.views}>
                <span className={styles.icon}>
                  <ViewsIcon />
                </span>
                <span className={styles.text}>
                  {t(AppConstant.COMMON.VIEWS)}
                </span>
                {contentDetails?.view_count ? contentDetails?.view_count : 0}
              </div>
            </div> */}
          </div>
          <div className={styles.detailInfo}>
            <div className={styles.tag}>
              {contentIcon ? (
                <img src={getImageSrc(contentIcon)} alt='content icon' />
              ) : null}
              <span>{contentDetails?.field_select_article_type}</span>
            </div>
            {contentDetails?.title && (
              <h1 className={styles.detailTitle}>{contentDetails?.title}</h1>
            )}
            {contentDetails?.field_area_of_interest && (
              <div className={styles.specialityWrap}>
                <Speciality
                  speciality={convertToSpecialties(
                    contentDetails?.field_area_of_interest || ''
                  )}
                  showAll={false}
                />
              </div>
            )}
            {!hideAuthor && contentDetails?.field_authored_by && (
              <div className={styles.publisher}>
                <AuthorIcon />
                <span className={styles.authorLabel}>
                  {t(AppConstant.COMMON.AUTHORED_BY)}
                </span>
                <span
                  className={styles.authorText}
                  title={contentDetails?.field_authored_by}
                >
                  {contentDetails?.field_authored_by || getDefaultValue()}
                </span>
              </div>
            )}

            <div className={styles.dateView}>
              {/* <div className={styles.views}>
                <span className={styles.icon}>
                  <DateIcon />
                </span>
                <span className={styles.text}>
                  {t(AppConstant.COMMON.DATE)}
                </span>
                {getOnlyDate(contentDetails?.field_authored_on)}
              </div> */}

              <div className={styles.views}>
                <span className={styles.icon}>
                  <ViewsIcon />
                </span>
                <span className={styles.text}>
                  {t(AppConstant.COMMON.VIEWS)}
                </span>
                {contentDetails?.view_count ? contentDetails?.view_count : 0}
              </div>
            </div>
            <div className={styles.shareAndCta}>
              <div className={styles.detailCta}>
                <span className={styles.disableSpan}></span>
                {ctaAction && (
                  <ButtonComponent
                    variant='outlined'
                    buttonText={t(AppConstant.KNOWLEDGE_HUB_PAGE.START_READING)}
                    onPress={handleStartReading}
                    disabled={isStartReadingDisabled}
                    loader={isArticleDocLinkLoading}
                  />
                )}
              </div>
              <div className={styles.detailActions}>
                <ShareSection
                  isBookmarked={isBookmarked}
                  isLiked={isLiked}
                  fieldActions={contentDetails?.field_actions}
                  fileDownloadUrl={getBlobFilePath(
                    contentDetails?.field_supporting_docs
                  )}
                  title={contentDetails?.title}
                  contentId={contentDetails?.nid}
                  fileUrl={contentDetails?.field_supporting_docs}
                  content={contentDetails}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.shalinaTabs}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label='Detail Tabs'
            className={styles.custTabs}
          >
            {tabs?.map((tab, index: number) => (
              <Tab label={tab?.label} {...a11yProps(index)} />
            ))}
            {/* <Tab
              label={t(AppConstant.KNOWLEDGE_HUB_PAGE.BOOK_DESCRIPTION_TAB)}
              {...a11yProps(0)}
            />
            <Tab
              label={t(AppConstant.COMMON.ADDITIONAL_INFO)}
              {...a11yProps(1)}
            /> */}
          </Tabs>
          <div className={styles.custTabContent}>
            {tabs?.map((tab: any, index: number) => (
              <CustomTabPanel value={tabValue} index={index}>
                {tab?.component}
              </CustomTabPanel>
            ))}
          </div>
        </div>
      </div>
      <SignUpPopup open={showSignUp} handleClose={handleSignUpClose} />
    </>
  );
};
