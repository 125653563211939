"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApprovalIcon = void 0;
const React = __importStar(require("react"));
const ApprovalIcon = (props) => (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: 168, height: 166, fill: "none" }, props),
    React.createElement("path", { fill: "url(#a)", d: "M0 0h168v166H0z" }),
    React.createElement("defs", null,
        React.createElement("pattern", { id: "a", width: 1, height: 1, patternContentUnits: "objectBoundingBox" },
            React.createElement("use", { xlinkHref: "#b", transform: "matrix(.0103 0 0 .01042 .006 0)" })),
        React.createElement("image", { xlinkHref: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAAEp0lEQVR4nO2dzYscRRTAfxcTTaJrxET0JghiFPEDIfhxEAQloogXPZgcBfEQFHUPQdyLbpKbYOIHngTzH/gBirqCOodt9aAInkSDxhiN5kNNND4peQ3j7sxkuru66k33+8G77VRP/d5sTU911StwHMdxHMdxHMfJw3rgaeB74C/gM+ARYG2EttdqW58Df+s19ug1HWANMABkRBwEdgLraphap689OKbtj/TaveepMYKG4xDwJLBhClsb9G8PTdFuuHavuRD4eQpRZRwBFvR1o8SHT/wPFdo7ClxEj1msIGs4jgG7Vd4FwDzwS822nqOnXAqcrCltOBHHGrZxQt9L73ixobiYsY+ecTlwyoD4Mk4DV9AjDhiQLividXrCtcAZA8JlRfwDXE8PeNOAbBkTb9Bx7jIgWc4S2+gAc8CNwA7geeAd4LABuTJlhFvbZeA1/Y1xD3AJRrkKeEgnuMLw8q0BgdJShHmlt4G9+uHaklP8ZmDJgBTJHEvqIinnAF8a6LwYiS/USTJ2GOi0GIswDCfjVQMdFmPxcsoE7DPQYTEWL6RMwBMGOizG4vGUCbjPQIfFWNybMgFXG+iwGIvweygZ5xqdUJNMcUadJKXLv3ilYnxDBt4z0HExEu/mSMArBjouEWLQYHFAGS9ZXc9jPZaBjdqf+QbthNvy5NzfIfk0TEK4Lc/yaLFL8psk4Roy8IABkVJzzA8PjCaxULHN4CIpdxtbViIR5ZdU+WI+pU6ScAfwhwGZEnHYWcnchBXbk5LQ+vPkW4DjBmSKMfll/A7c3pb8rRHWYXZZvmiE9a63xZZ/XYPVx32SLxq/AjfFvN08YkCmzIj84b0HNzSVf2XFDQ9WYjmz/DIO69R9LTZP2F/VRixGElHlVrNN+WV8B2yqk4DdCeUvRBJiTX4Zz9ZJwIeJ3twzI8R8MsPDzqh4v04CljLIr5sEy/JFn52YG4IWIomyOuw0HoLa/BJejCRsMAPyw5fwxRi7DS0q7M+dGzMcWR92Gt+Gtv1DrGiQhFmQf1T3SJieiihqJGEW5P8Wcyqi5OaWZkIHFcdx62P+cXXV2nT0icz/CZbltzod3fYDmSJSEnLJT/JApuRO4E+DSZjLJP+0buRLyoMtdWZQYZy3IF/URaeWpRQV/xNyyhd10bmFWcWUScgtX9RFJ5cmFmdJggX52UqfpVqcW4xJghX5oi46vTy9WJEES/JrTzfP2gaNQpNgTb6oi6Scl2mL0sCg/LLWUHCSDN+kx6okNJ52roJvUyXvHgHfqE3eXTJeqoBVCdifMgFerINVCQhOkuHlaliVgO0pE+AFm8hbsCngJcv4T/4Hddd+xmKLDkl7taBdygW9kmGNz1va1+2pC3RUIUwX3Ao8PFS28icDAqXCqoaybOVOfQxrtmxlFbYZkCt9KNw6CS9dnBkv3m0Ai+XrD9Aj/AAHA+w38KnPMo9jhcuMHOJzUt9LL1lsIN6Pscp4kNvGEW35QW41mZ9C/I+69ub8yEcZhmv3njUTHrSHk08fa+kwz4/9MM//ywrH2X6t+w/CcbaPRiqGWh5n+6l+d3wF7KqZVMdxHMdxHMdxHJryL4s7dGSCBfRTAAAAAElFTkSuQmCC", id: "b", width: 96, height: 96 }))));
exports.ApprovalIcon = ApprovalIcon;
