import { reverse } from 'named-urls';
import { useNavigate } from 'react-router-dom';

interface INavigateOptions {
  isDynamicUrl?: boolean;
  dynamicUrlOptions?: any;
  stateParams?: any;
  replace?: boolean;
}
export const useNavigatePageUtils: any = () => {
  const navigate = useNavigate();

  const navigatePage: any = (url: string, options: INavigateOptions) => {
    const urlValue = options.isDynamicUrl
      ? reverse(url, options.dynamicUrlOptions)
      : url;
    navigate(urlValue, {
      state: options.stateParams,
      replace: options?.replace ? options?.replace : false
    });
  };
  return navigatePage;
};

export const replaceSlashesAndSpaces: any = (input: string) => {
  const result = input
    .replace(/\//g, '-')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-')
    .toLowerCase();
  return result;
};
