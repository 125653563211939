"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DYNAMIC_SURVEY_DETAILS_ENDPOINT = exports.DYNAMIC_LIST_SURVEY_ENDPOINT = exports.LIST_SPEAKER_DETAILS = exports.GET_NOTIFICATION_COUNT = exports.UPDATE_POINTS_ENDPOINT = exports.GAMIFICATION_MASTER_ENPOINT = exports.MARK_READ_NOTIFICATION_ENDPOINT = exports.LIST_NOTIFICATION_ENDPOINT = exports.SEND_NOTIFICATION_ENDPOINT = exports.REGISTER_NOTIFICATION_ENDPOINT = exports.FETCH_ARTICLE_DOCUMENT_LINK = exports.FETCH_ARTICLE_LIST_BY_ISSN = exports.LIST_MEDICAL_CALCULATOR_ENDPOINT = exports.LIST_RSS_NEWS_FEED_ENDPOINT = exports.UPDATE_PROFILE_PICTURE_ENDPOINT_VIA_FORM_DATA = exports.UPDATE_PROFILE_PICTURE_ENDPOINT = exports.UPDATE_BOOKMARK_ENDPOINT = exports.LIST_BOOKMARK_ENDPOINT = exports.LIST_OUR_COMMUNITIES_ENDPOINT = exports.DELETE_RECENT_SEARCH_ENDPOINT = exports.LIST_RECENT_SEARCH_ENDPOINT = exports.SEARCH_CONTENT_ENDPOINT = exports.LIST_SURVEY_ENDPOINT = exports.MEDICAL_NEWS_ENDPOINT = exports.LIST_EXPERT_ENDPOINT = exports.GET_REQUEST_STATUS_TYPE_ENDPOINT = exports.GET_REQUEST_CONTENT_TYPE_ENDPOINT = exports.GET_REQUEST_TYPE_ENDPOINT = exports.REGISTRATION_CODE_ENDPOINT = exports.INCREASE_COUNT_ENDPOINT = exports.GET_CONTACT_MODE_ENDPOINT = exports.GET_ACCOUNT_DEACTIVATE_REASON = exports.UPDATE_USER_DETAILS = exports.GET_USER_DETAILS = exports.SAVE_TIMESTAMP_PAGE_READ_ENDPOINT = exports.GET_AZURE_STORAGE_ENDPOINT = exports.GET_LIKE_CONTENT_ENDPOINT = exports.GET_CONTENT_CATEGORIES_ENDPOINT = exports.GET_FILTER_CATEGORIES_ENDPOINT = exports.GET_SORT_OPTIONS_ENDPOINT = exports.GET_PREFERRED_LANGUAGE_ENDPOINT = exports.GET_COUNTRIES_ENDPOINT = exports.AREA_OF_INTEREST_ENDPOINT = exports.QUALIFICATION_ENDPOINT = exports.SPECIALIZATION_ENDPOINT = exports.VALIDATE_OTP_ENDPOINT = exports.VALIDATE_CONTACT_DETAIL_ENDPOINT = exports.SIGN_IN_ENDPOINT = exports.SIGN_UP_ENDPOINT = exports.SEND_OTP_ENDPOINT = void 0;
exports.SUBMIT_REDEEM_POINTS_ENDPOINT = exports.RECOGNITION_HIDE_VISIBILITY_ENDPOINT = exports.UPDATE_USER_RECOGNITION_ENDPOINT = exports.GET_USER_RECOGNITION_BADGES_ENDPOINT = exports.USER_GAMIFICATION_POINT_HISTORY_ENDPOINT = exports.GAMIFICATION_POINT_DISTRIBUTION_ENDPOINT = exports.ADD_GAMIFICATION_ENDPOINT = exports.REVOKE_QUESTION_ENDPOINT = exports.GET_DISCUSSION_QUESTION_ENDPOINT = exports.UPDATE_COMMUNITY_ENDPOINT = exports.SUBMIT_QUESTION_ENDPOINT = exports.SUBMIT_ANSWER_ENDPOINT = exports.LEAVE_COMMUNITY_ENDPOINT = exports.GET_COMMUNITY_DISCUSSIONS = exports.GET_COMMUNITY_DETAIL = exports.GET_DISCUSSION_TYPES_ENDPOINT = exports.GET_COMMUNITY_RESOURCES = exports.GET_COMMUNITY_MEMBERS = exports.GET_COMMUNITY_QUESTION_STATUS_ENDPOINT = exports.GET_COMMUNITY_REQUEST_STATUS_ENDPOINT = exports.GET_COMMUNITY_GUIDELINE_ENDPOINT = exports.COMMUNITY_LANDING_CONTENT_ENDPOINT = exports.SUBMIT_REQUEST_ENDPOINT = exports.REQUEST_DETAILS_ENDPOINT = exports.REQUEST_LIST_ENDPOINT = exports.EBSCO_SAML_SESSION_INITIATOR_ENDPOINT = exports.HOME_API_ENDPOINT_CACHE = exports.USER_REGISTERED_EVENTS_LIST_ENDPOINT = exports.LIST_SPEAKER = exports.WEBINAR_CONTENT_DETAILS_ENDPOINT = exports.REGISTER_AND_JOIN_WEBINAR_ENDPOINT = exports.SUBMIT_QUIZ = exports.QUIZ_DETAILS_ENDPOINT = void 0;
exports.SEND_OTP_ENDPOINT = "api/auth/send-otp";
exports.SIGN_UP_ENDPOINT = "api/user/signup";
exports.SIGN_IN_ENDPOINT = "api/user/signin";
exports.VALIDATE_CONTACT_DETAIL_ENDPOINT = "api/user/validate-contact-detail";
exports.VALIDATE_OTP_ENDPOINT = "api/auth/validate-otp";
exports.SPECIALIZATION_ENDPOINT = "api/master/specialization"; // no use
exports.QUALIFICATION_ENDPOINT = "api/master/qualification"; // no use
exports.AREA_OF_INTEREST_ENDPOINT = "api/master/area-of-interest"; // no use
exports.GET_COUNTRIES_ENDPOINT = "api/master/countries"; // no use
exports.GET_PREFERRED_LANGUAGE_ENDPOINT = "api/master/languages"; // no use
exports.GET_SORT_OPTIONS_ENDPOINT = "/api/master/sort-options"; // no use
exports.GET_FILTER_CATEGORIES_ENDPOINT = "/api/master/knowledge-hub-types"; // no use
exports.GET_CONTENT_CATEGORIES_ENDPOINT = "/api/master/content-types"; // no use
exports.GET_LIKE_CONTENT_ENDPOINT = "api/content/like";
exports.GET_AZURE_STORAGE_ENDPOINT = "api/azure/accessurl";
exports.SAVE_TIMESTAMP_PAGE_READ_ENDPOINT = "api/content/update-read-position";
exports.GET_USER_DETAILS = "api/user/info";
exports.UPDATE_USER_DETAILS = "api/user";
exports.GET_ACCOUNT_DEACTIVATE_REASON = "/api/master/deactivate-reason"; // no use
exports.GET_CONTACT_MODE_ENDPOINT = "api/master/contact-mode"; // no use
exports.INCREASE_COUNT_ENDPOINT = "api/content/view";
exports.REGISTRATION_CODE_ENDPOINT = "api/user/registration-code";
exports.GET_REQUEST_TYPE_ENDPOINT = "api/master/request-type"; // no use
exports.GET_REQUEST_CONTENT_TYPE_ENDPOINT = "api/master/request-content-type"; // no use
exports.GET_REQUEST_STATUS_TYPE_ENDPOINT = "api/master/request-status"; // no use
//Home
// export const FEATURED_CONTENT_ENDPOINT = "api/home/featured-content";
// export const NEWLY_ADDED_ENDPOINT = "api/home/recent-content";
exports.LIST_EXPERT_ENDPOINT = "api/home/experts";
exports.MEDICAL_NEWS_ENDPOINT = "api/home/medical-news-content"; // no use
// export const TRENDING_NOW_ENDPOINT = "/api/home/trending-content";
// export const CONTINUE_WHERE_LEFT_ENDPOINT =
//   "api/home/continue-where-you-left-content";
// export const RECOMMENED_FOR_YOU_ENDPOINT =
//   "api/home/recommended-for-you-content";
exports.LIST_SURVEY_ENDPOINT = "api/survey/list"; // no use
// export const CONTENT_DETAILS_ENDPOINT = "api/content";
exports.SEARCH_CONTENT_ENDPOINT = "api/home/search";
exports.LIST_RECENT_SEARCH_ENDPOINT = "api/search-history";
exports.DELETE_RECENT_SEARCH_ENDPOINT = "api/search-history";
exports.LIST_OUR_COMMUNITIES_ENDPOINT = "api/communities"; // no use
exports.LIST_BOOKMARK_ENDPOINT = "api/content/bookmark-content";
exports.UPDATE_BOOKMARK_ENDPOINT = "api/content/bookmark";
exports.UPDATE_PROFILE_PICTURE_ENDPOINT = "api/user/picture";
exports.UPDATE_PROFILE_PICTURE_ENDPOINT_VIA_FORM_DATA = "api/user/picture";
exports.LIST_RSS_NEWS_FEED_ENDPOINT = "api/home/news-feed";
exports.LIST_MEDICAL_CALCULATOR_ENDPOINT = "api/master/calculator";
// export const CONTENT_DETAILS_ENDPOINT = "api/content";
//knowledge-hub
// export const LIST_KNOWLEDGE_HUB_CONTENT_ENDPOINT = "api/content";
// export const FETCH_LIST_BY_TYPE_ENDPOINT = "api/content-list";
exports.FETCH_ARTICLE_LIST_BY_ISSN = "api/rest/articles";
exports.FETCH_ARTICLE_DOCUMENT_LINK = "api/rest/retrieve";
//Notification
exports.REGISTER_NOTIFICATION_ENDPOINT = "api/notification/register";
exports.SEND_NOTIFICATION_ENDPOINT = "api/notification/send";
exports.LIST_NOTIFICATION_ENDPOINT = "api/notification/list";
exports.MARK_READ_NOTIFICATION_ENDPOINT = "api/notification/";
exports.GAMIFICATION_MASTER_ENPOINT = "api/master/gamification"; // no use
exports.UPDATE_POINTS_ENDPOINT = "api/gamification/points";
exports.GET_NOTIFICATION_COUNT = "api/notification/count";
// export const MASTER_API_ENDPOINT = "api/auth/master";
// export const HOME_API_ENDPOINT = "api/home-api";
exports.LIST_SPEAKER_DETAILS = "api/content/speaker-list"; // No use
//Survey
exports.DYNAMIC_LIST_SURVEY_ENDPOINT = "api/survey-list";
exports.DYNAMIC_SURVEY_DETAILS_ENDPOINT = "api/survey-detail";
exports.QUIZ_DETAILS_ENDPOINT = "survey-question/list";
exports.SUBMIT_QUIZ = "survey-question/submit";
//Learning Center
exports.REGISTER_AND_JOIN_WEBINAR_ENDPOINT = "api/event/register";
exports.WEBINAR_CONTENT_DETAILS_ENDPOINT = "api/event/detail";
exports.LIST_SPEAKER = "api/event/speakers";
exports.USER_REGISTERED_EVENTS_LIST_ENDPOINT = "/api/event/registered-event-list";
// Cache services endpoint
exports.HOME_API_ENDPOINT_CACHE = "api/home/home-api"; // no use from here
//EBESCO SAML AUTHENTICATION
exports.EBSCO_SAML_SESSION_INITIATOR_ENDPOINT = "api/home/session-initiator";
//REQUEST
exports.REQUEST_LIST_ENDPOINT = "api/request/list";
exports.REQUEST_DETAILS_ENDPOINT = "api/request";
exports.SUBMIT_REQUEST_ENDPOINT = "api/request/submit";
//COMMUNITY
// export const LIST_COMMUNITY = "api/my-communities";
exports.COMMUNITY_LANDING_CONTENT_ENDPOINT = "api/communities";
exports.GET_COMMUNITY_GUIDELINE_ENDPOINT = "api/master/community-guidelines"; // No use
exports.GET_COMMUNITY_REQUEST_STATUS_ENDPOINT = "api/master/community-request-status"; // No use
exports.GET_COMMUNITY_QUESTION_STATUS_ENDPOINT = "api/master/community-question-status"; // No use
exports.GET_COMMUNITY_MEMBERS = "api/communities/members";
exports.GET_COMMUNITY_RESOURCES = "api/communities/resources";
exports.GET_DISCUSSION_TYPES_ENDPOINT = "api/master/community-category"; // no use
// export const GET_COMMUNITY_REQUESTS_ENDPOINT = "api/communities/requests";
exports.GET_COMMUNITY_DETAIL = "api/communities-detail";
exports.GET_COMMUNITY_DISCUSSIONS = "/api/discussions";
exports.LEAVE_COMMUNITY_ENDPOINT = "api/communities/leave";
exports.SUBMIT_ANSWER_ENDPOINT = "api/discussions/answer";
exports.SUBMIT_QUESTION_ENDPOINT = "api/discussions/questions";
exports.UPDATE_COMMUNITY_ENDPOINT = "api/communities/update-request";
exports.GET_DISCUSSION_QUESTION_ENDPOINT = "api/discussions/questions";
exports.REVOKE_QUESTION_ENDPOINT = "api/communities/update-question-status";
//Gamification
exports.ADD_GAMIFICATION_ENDPOINT = 'api/gamification/point';
exports.GAMIFICATION_POINT_DISTRIBUTION_ENDPOINT = 'api/master/user-gamification';
exports.USER_GAMIFICATION_POINT_HISTORY_ENDPOINT = 'api/gamification/accumulated';
exports.GET_USER_RECOGNITION_BADGES_ENDPOINT = 'api/gamification/badges';
exports.UPDATE_USER_RECOGNITION_ENDPOINT = 'api/gamification/recognition';
exports.RECOGNITION_HIDE_VISIBILITY_ENDPOINT = 'api/gamification/recognition';
exports.SUBMIT_REDEEM_POINTS_ENDPOINT = 'api/gamification/redeem-request';
