"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewsIcon = void 0;
const React = __importStar(require("react"));
const ViewsIcon = (props) => (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: 17, height: 13, fill: "none" }, props),
    React.createElement("path", { stroke: "#1D3256", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 1.5, d: "M10.75 6.253A2.252 2.252 0 0 1 8.5 8.504a2.25 2.25 0 0 1-2.25-2.25A2.252 2.252 0 0 1 8.5 4.001a2.25 2.25 0 0 1 2.25 2.251Z" }),
    React.createElement("path", { stroke: "#1D3256", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 1.5, d: "M1 6.253C2.2 3.178 5.002 1 8.5 1s6.3 2.178 7.5 5.253c-1.2 3.074-4.002 5.253-7.5 5.253S2.2 9.327 1 6.253Z" })));
exports.ViewsIcon = ViewsIcon;
