"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LIST_OUR_COMMUNITIES = exports.DELETE_RECENT_SEARCH = exports.LIST_RECENT_SEARCH = exports.SEARCH_CONTENT = exports.CONTENT_DETAILS = exports.LIST_SURVEY = exports.RECOMMENED_FOR_YOU = exports.CONTINUE_WHERE_LEFT = exports.TRENDING_NOW = exports.MEDICAL_NEWS = exports.LIST_EXPERT = exports.NEWLY_ADDED = exports.FEATURED_CONTENT = exports.HOME = exports.REQUEST_STATUS_TYPE = exports.REQUEST_CONTENT_TYPE = exports.REQUEST_TYPE = exports.GAMIFICATION = exports.COUNT_INCREASE = exports.GET_ALL_CONTENT_ENDPOINT = exports.CONTACT_MODE = exports.VALIDATE_CONTACT_DETAIL = exports.VALIDATE_OTP = exports.UPDATE_USER = exports.GET_DEACTIVATE_REASON = exports.USER_DETAILS = exports.USER = exports.COMMON = exports.REGISTERATION_CODE = exports.AREA_OF_INTEREST = exports.QUALIFICATION = exports.SPECIALIZATION = exports.SIGN_IN = exports.SIGN_UP = exports.SEND_OTP = exports.AUTHENTICATION = exports.UPDATE_POINTS = exports.MARK_NOTIFICATION = exports.GET_COUNT_NOTIFICATION = exports.GET_NOTIFICATION = exports.SEND_NOTIFICATION = exports.REGISTER_NOTIFICATION = exports.NOTIFICATION = exports.SAVE_TIMESTAMP = exports.AZURE_FILE_ACCESS = exports.LIKE_CONTENT = exports.GET_FILTER_CATEGORIES = exports.GET_SORT_OPTIONS = exports.PREFERRED_LANGUAGE = exports.GET_COUNTRY_CODE_LIST = void 0;
exports.FETCH_COMMUNITY_DISCUSSIONS = exports.FETCH_COMMUNITY_DETAIL = exports.FETCH_COMMUNITY_RECEIVED_REQUEST = exports.FETCH_COMMUNITY_REQUEST = exports.FETCH_DISCUSSION_TYPES = exports.FETCH_COMMUNITY_MEMBERS = exports.FETCH_COMMUNITY_RESOURCES = exports.FETCH_COMMUNITY_QUESTION_STATUS = exports.FETCH_COMMUNITY_REQUEST_STATUS = exports.FETCH_COMMUNITY_GUIDELINES = exports.FETCH_MY_COMMUNITY_LIST_FOR_LISTING = exports.FETCH_COMMUNITY_LANDING_CONTENT = exports.FETCH_MY_COMMUNITY_LIST = exports.COMMUNITY = exports.SUBMIT_REQUEST = exports.REQUEST_DETAILS = exports.REQUEST_LIST = exports.REQUEST = exports.REDIRECTOR = exports.SAML_AUTHENTICATION = exports.EBESCO = exports.USER_REGISTERED_LIST = exports.FETCH_SPEAKER_LIST = exports.WEBINAR_CONTENT_DETAIL = exports.REGISTER_JOIN_LEARNING_CENTER_WEBINAR = exports.LEARNING_CENTER = exports.SUBMIT_QUIZ_DETAILS = exports.QUIZ_DETAILS = exports.SURVEY_DETAILS = exports.FETCH_LOAD_SURVEY = exports.FETCH_RECENT_SURVEY = exports.DYNAMIC_LIST_SURVEY = exports.SURVEY = exports.FETCH_SPEAKER_DETAILS = exports.HOME_CONTENT = exports.GET_ALL_MASTER_DATA = exports.FETCH_SHALINA_TV_CONTENT_BY_TYPE = exports.LIST_SHALINA_TV = exports.SHALINA_TV = exports.FETCH_ARTICLE_DOCUMENT = exports.FETCH_ARTICLE_LIST = exports.FETCH_LIST_BY_TYPE = exports.LIST_KNOWLEDGE_HUB = exports.KNOWLEDGE_HUB = exports.UPDATE_PROFILE_PICTURE = exports.ADD_REMOVE_BOOKMARK = exports.LIST_BOOKMARKS = exports.PROFILE = exports.LIST_MEDICAL_CALCULATOR = exports.LIST_RSS_NEWS_FEED = void 0;
exports.UPDATE_LIKE_CONTENT = exports.SUBMIT_REDEEM_POINTS = exports.RECOGNITION_HIDE_VISIBILITY = exports.UPDATE_USER_RECOGNITION = exports.USER_RECOGNITION_BADGES = exports.ADD_GAMIFICATION_POINT = exports.USER_GAMIFICATION_POINT_HISTORY = exports.GAMIFICATION_POINT_DISTRIBUTION = exports.COMBINED_EXPLORE_COMMUNITY = exports.REVOKE_QUESTION = exports.GET_DISCUSSION_QUESTIONS = exports.UPDATE_COMMUNITY = exports.SUBMIT_QUESTION = exports.SUBMIT_ANSWER = exports.LEAVE_COMMUNITY = exports.FETCH_COMMUNITY_DISCUSSIONS_ANSWER_TAB = exports.FETCH_COMMUNITY_DETAIL_DISCUSSIONS = void 0;
exports.GET_COUNTRY_CODE_LIST = "GetCountryCodeList";
exports.PREFERRED_LANGUAGE = "PreferredLanguage";
exports.GET_SORT_OPTIONS = "SortOptions";
exports.GET_FILTER_CATEGORIES = "FilterCategoriesOptions";
exports.LIKE_CONTENT = "LikeContent";
exports.AZURE_FILE_ACCESS = "AzureFileAccess";
exports.SAVE_TIMESTAMP = "SaveTimestamp";
exports.NOTIFICATION = "Notification";
exports.REGISTER_NOTIFICATION = "RegisterNotification";
exports.SEND_NOTIFICATION = "SendNotification";
exports.GET_NOTIFICATION = "GetNotification";
exports.GET_COUNT_NOTIFICATION = "GetCountNotification";
exports.MARK_NOTIFICATION = "MarkNotification";
exports.UPDATE_POINTS = "UpdatePoints";
exports.AUTHENTICATION = "Authentication";
exports.SEND_OTP = "SendOtp";
exports.SIGN_UP = "SignUp";
exports.SIGN_IN = "SignIn";
exports.SPECIALIZATION = "Specialization";
exports.QUALIFICATION = "Qualification";
exports.AREA_OF_INTEREST = "AreaOfInterest";
exports.REGISTERATION_CODE = "RegistrationCode";
exports.COMMON = "Common";
exports.USER = "User";
exports.USER_DETAILS = "UserDetails";
exports.GET_DEACTIVATE_REASON = "GetDeactivateReason";
exports.UPDATE_USER = "UpdateUser";
exports.VALIDATE_OTP = "ValidateOTP";
exports.VALIDATE_CONTACT_DETAIL = "ValidateContactDetail";
exports.CONTACT_MODE = "ContactMode";
exports.GET_ALL_CONTENT_ENDPOINT = "AllContentTypes";
exports.COUNT_INCREASE = "CountIncrease";
exports.GAMIFICATION = "Gamification";
exports.REQUEST_TYPE = "RequestType";
exports.REQUEST_CONTENT_TYPE = "RequestContentType";
exports.REQUEST_STATUS_TYPE = "RequestStatusType";
//Home
exports.HOME = "Home";
exports.FEATURED_CONTENT = "FeaturedContent";
exports.NEWLY_ADDED = "NewlyAdded";
exports.LIST_EXPERT = "ListExpert";
exports.MEDICAL_NEWS = "MedicalNews";
exports.TRENDING_NOW = "TrendingNow";
exports.CONTINUE_WHERE_LEFT = "ContinueWhereLeft";
exports.RECOMMENED_FOR_YOU = "RecomendedForYou";
exports.LIST_SURVEY = "ListoFSurvey";
exports.CONTENT_DETAILS = "ContentDetails";
exports.SEARCH_CONTENT = "SearchContent";
exports.LIST_RECENT_SEARCH = "ListRecentSearch";
exports.DELETE_RECENT_SEARCH = "DeleteRecentSearch";
exports.LIST_OUR_COMMUNITIES = "ListOurCommunities";
exports.LIST_RSS_NEWS_FEED = "ListRssNewsFeed";
exports.LIST_MEDICAL_CALCULATOR = "ListMediccalCalculator";
//Profile
exports.PROFILE = "Profile";
exports.LIST_BOOKMARKS = "Bookmarks";
exports.ADD_REMOVE_BOOKMARK = "UpdateBookmark";
exports.UPDATE_PROFILE_PICTURE = "UpdateProfilePicture";
//KnowledgeHub
exports.KNOWLEDGE_HUB = "KnowledgeHub";
exports.LIST_KNOWLEDGE_HUB = "ListContent";
exports.FETCH_LIST_BY_TYPE = "FetchListByType";
exports.FETCH_ARTICLE_LIST = "FetchArticleList";
exports.FETCH_ARTICLE_DOCUMENT = "FetchArticleDocument";
//ShalinaTV
exports.SHALINA_TV = "ShalinaTV";
exports.LIST_SHALINA_TV = "ListShalinaTVContent";
exports.FETCH_SHALINA_TV_CONTENT_BY_TYPE = "FetchShalinaListByType";
exports.GET_ALL_MASTER_DATA = "AllMasterData";
exports.HOME_CONTENT = "HomeContent";
exports.FETCH_SPEAKER_DETAILS = "FetchSpeakerDetails";
//Survey
exports.SURVEY = "Survey";
exports.DYNAMIC_LIST_SURVEY = "DynamicListContent";
exports.FETCH_RECENT_SURVEY = "FetchRecentSurvey";
exports.FETCH_LOAD_SURVEY = "FetchOnLoadSurvey";
exports.SURVEY_DETAILS = "SurveyDetails";
exports.QUIZ_DETAILS = "QuizDetails";
exports.SUBMIT_QUIZ_DETAILS = "SubmitQuizDetails";
//LearningCenter
exports.LEARNING_CENTER = "LearningCenter";
exports.REGISTER_JOIN_LEARNING_CENTER_WEBINAR = "RegisterJoinWebinar";
exports.WEBINAR_CONTENT_DETAIL = "WebinarContentDetails";
exports.FETCH_SPEAKER_LIST = "FetchSpeakerList";
exports.USER_REGISTERED_LIST = "UserRegisteredList";
//EBESCO SAML AUTHENTICATION
exports.EBESCO = "EBESCO";
exports.SAML_AUTHENTICATION = "SamlAuthentication";
exports.REDIRECTOR = "redirector";
//REQUEST
exports.REQUEST = "REQUEST";
exports.REQUEST_LIST = "RequestList";
exports.REQUEST_DETAILS = "RequestDetails";
exports.SUBMIT_REQUEST = "SubmitRequest";
//Community
exports.COMMUNITY = "Community";
exports.FETCH_MY_COMMUNITY_LIST = "FetchMyCommunityList";
exports.FETCH_COMMUNITY_LANDING_CONTENT = "FetchCommunityLandingContent";
exports.FETCH_MY_COMMUNITY_LIST_FOR_LISTING = "FetchMyCommunityListForListing";
exports.FETCH_COMMUNITY_GUIDELINES = "FetchCommunityGuidelines";
exports.FETCH_COMMUNITY_REQUEST_STATUS = "FetchCommunityRequestStatus";
exports.FETCH_COMMUNITY_QUESTION_STATUS = "FetchCommunityQuestionStatus";
exports.FETCH_COMMUNITY_RESOURCES = "FetchCommunityResources";
exports.FETCH_COMMUNITY_MEMBERS = "FetchCommunityMembers";
exports.FETCH_DISCUSSION_TYPES = "FetchDiscussionTypes";
exports.FETCH_COMMUNITY_REQUEST = "FetchCommunityRequest";
exports.FETCH_COMMUNITY_RECEIVED_REQUEST = "FetchCommunityReceivedRequest";
exports.FETCH_COMMUNITY_DETAIL = "FetchCommunityDetail";
exports.FETCH_COMMUNITY_DISCUSSIONS = "FetchCommunityDiscussions";
exports.FETCH_COMMUNITY_DETAIL_DISCUSSIONS = "FetchCommunityDetailDiscussions";
exports.FETCH_COMMUNITY_DISCUSSIONS_ANSWER_TAB = "FetchCommunityDiscussionsAnswer";
exports.LEAVE_COMMUNITY = "LeaveCommunity";
exports.SUBMIT_ANSWER = "SubmitAnswer";
exports.SUBMIT_QUESTION = "SubmitQuestion";
exports.UPDATE_COMMUNITY = "UpdateCommunity";
exports.GET_DISCUSSION_QUESTIONS = "GetDiscussionQuestions";
exports.REVOKE_QUESTION = "revokeQuestion";
exports.COMBINED_EXPLORE_COMMUNITY = "combinedExploreCommunity";
//GAMIFICATION
exports.GAMIFICATION_POINT_DISTRIBUTION = "GamificationPointDistribution";
exports.USER_GAMIFICATION_POINT_HISTORY = "UserGamificationPointHistory";
exports.ADD_GAMIFICATION_POINT = "AddGamificationPoint";
exports.USER_RECOGNITION_BADGES = "UserRecognitionBadges";
exports.UPDATE_USER_RECOGNITION = "UpdateUserRecognition";
exports.RECOGNITION_HIDE_VISIBILITY = "RecognitionHideVisibility";
exports.SUBMIT_REDEEM_POINTS = "SubmitRedeemPoints";
exports.UPDATE_LIKE_CONTENT = 'UpdateLikeContent';
