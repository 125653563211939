import {
  BLOB_BASE_URL,
  BLOB_FILES_CONTAINER_PARAMS,
} from '@shalina-app/shared';
import { useEffect } from 'react';

export const AppStoreRedirector = () => {
  const url = `${BLOB_BASE_URL}/config/qrcode.html${BLOB_FILES_CONTAINER_PARAMS}`;

  useEffect(() => {
    if (url) {
      window.location.href = url;
    }
  }, [url]);

  return <></>;
};
