import { Dialog, DialogContent } from '@mui/material';
import {
  AppConstant,
  GAMIFICATION_ACTION_ID,
  ImageConstant,
  useAppDispatch,
  useAppSelector,
  updateProfileImage,
  getNotificationActionList,
  getMilestoneData,
  GAMIFICATION_ENABLED
} from '@shalina-app/shared';
import { useEffect, useState } from 'react';

import { ProfileSummarySectionPropsType } from '.';
import { PROFILE_PAGE_TABS } from '../../../../app.constants';
import { URLConstant } from '../../../../routes';
import {
  getImageSrc,
  getTranslationFormat,
  useNavigatePageUtils
} from '../../../../utils';
import styles from './profileSummarySection.module.scss';

export const ProfileSummarySection: React.FC<
  ProfileSummarySectionPropsType
> = ({
  earnedPoints,
  userName,
  userRole,
  achieveMilestone,
  milestoneToReach,
  handleSaveProfilePic,
  profilePic,
  isLastMileStone
}) => {
  const dispatch = useAppDispatch();
  const keyImg = Math.random() * 1000;
  const navigatePage = useNavigatePageUtils();
  const { urls } = URLConstant;
  const { RightArrowIcon, EditIcon } = ImageConstant.SVG_ICONS;

  const [editProfilePopup, setEditProfilePopupn] = useState(false);
  const [image, setImage] = useState('');
  const [userImg, setUserImg] = useState('');
  const [binaryData, setBinaryData] = useState<any>();
  const [removePic, setRemovePic] = useState(false);
  const [completedProfile, setCompletedProfile] = useState(false);
  const [achievedMileStone, setAchievedMileStone] = useState<any>();
  const [nextMileStone, setNextMileStone] = useState<any>();
  const [percentage, setPercentage] = useState(0);

  const milestoneData = useAppSelector(getMilestoneData);
  const notificationList = useAppSelector(getNotificationActionList);

  useEffect(() => {
    if (profilePic) {
      setUserImg(profilePic);
      setImage(`${getImageSrc(profilePic)}?timestamp=${keyImg}`); // adding uniqueness to the url, so it fetch updated image every time.
    }
  }, [profilePic]);

  useEffect(() => {
    if (GAMIFICATION_ENABLED && milestoneData) {
      if (isLastMileStone) {
        const lastElement = milestoneData?.slice(-1)?.[0];
        const achieveElement = milestoneData?.slice(-2)?.[0];

        setNextMileStone(lastElement);
        setAchievedMileStone(achieveElement);
        setPercentage(100);
      } else {
        const prev = milestoneData?.filter(
          (item: any) => item?.tid === achieveMilestone?.tid
        )?.[0];
        const next = milestoneData?.filter(
          (item: any) => item?.tid === milestoneToReach?.[0]?.tid
        )?.[0];

        const lastAchivedPoints = achieveMilestone?.points;
        const percentage =
          ((parseInt(earnedPoints) - parseInt(lastAchivedPoints)) /
            (milestoneToReach?.[0]?.points - lastAchivedPoints)) *
          100;

        setNextMileStone(next);
        setAchievedMileStone(prev);
        setPercentage(percentage);
      }
    }
  }, [milestoneData, earnedPoints, isLastMileStone]);

  const handleEditPic = () => {
    setEditProfilePopupn(true);
  };

  const handleEditProfileClose = () => {
    setEditProfilePopupn(false);
  };

  const handleProfilePicChange = (e: any) => {
    const file = e?.target?.files[0];
    const src = file && URL.createObjectURL(file);
    setImage(src);
    const reader = new FileReader();
    reader.onload = (e) => {
      const binaryData = e?.target?.result; // Get the file content as ArrayBuffer or data URL
      setBinaryData(binaryData);
    };

    reader.readAsArrayBuffer(file);
    setRemovePic(false);
  };

  const handleRemoveProfilePic = () => {
    setImage('');
    setRemovePic(true);
  };

  const handleSave = async (binaryData: any, removePic: boolean) => {
    setEditProfilePopupn(false);
    dispatch(updateProfileImage(ImageConstant.SOURCE.defaultUserPic));
    handleSaveProfilePic(binaryData, removePic);
  };

  useEffect(() => {
    if (completedProfile) {
      sendNotificationCall();
      setCompletedProfile(false);
    }
  }, [completedProfile]);

  const sendNotificationCall = async () => {
    const action = notificationList?.find(
      (item: any) =>
        item?.field_unique_code === GAMIFICATION_ACTION_ID.PROFILE_COMPLETION
    )?.title;

    const req = {
      action: action
    };
    // await dispatch(updatePointsWithNotification(req));
  };

  const handleDiscoverProgress = () => {
    const navigateDetails = {
      stateParams: {
        tab: PROFILE_PAGE_TABS.ACHIEVEMENTS
      },
      isDynamicUrl: false
    };
    navigatePage(urls.profilePage, navigateDetails); // Commenting as mentioned: bug > #259
  };

  return (
    <>
      <div className={styles.homeProfile}>
        <div className={styles.profileInfo}>
          {GAMIFICATION_ENABLED && (
            <div className={styles.profilePoint}>
              <img
                src={getImageSrc(achieveMilestone?.field_icon)}
                alt='user img'
              />
              {earnedPoints} {getTranslationFormat(AppConstant.COMMON.POINTS)}
            </div>
          )}

          <div className={styles.profileImg}>
            <img
              src={
                userImg
                  ? `${getImageSrc(userImg)}?timestamp=${keyImg}`
                  : ImageConstant.SOURCE.defaultUserPic
              }
              alt='user img'
              onError={(e) =>
                ((e.target as HTMLInputElement).src =
                  ImageConstant.SOURCE.defaultUserPic)
              }
            />
            <span onClick={handleEditPic}>
              <EditIcon />
            </span>
          </div>
          <div className={styles.userName}>
            <span>Dr.</span> {userName}
          </div>
          <div className={styles.userRole}>{userRole}</div>
          {GAMIFICATION_ENABLED && (
            <>
              <div className={styles.profileProgress}>
                <img
                  src={getImageSrc(achievedMileStone?.field_icon)}
                  alt='progress'
                  className={styles.active}
                />
                <img
                  src={getImageSrc(nextMileStone?.field_icon)}
                  alt='progress'
                />
                <div className={styles.progressBarWrap}>
                  {/* <div className={styles.pointDots}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div> */}
                  <span
                    className={styles.progressBar}
                    style={{ width: `${percentage}%` }}
                  ></span>
                </div>
              </div>
              <div className={styles.totalPoint}>
                <div>
                  {getTranslationFormat(AppConstant.PROFILE.TOTAL_EARNED_TEXT)}:{' '}
                  <strong>{earnedPoints || '0'}</strong>{' '}
                  <span>{getTranslationFormat(AppConstant.COMMON.POINTS)}</span>
                </div>
                <div>
                  {nextMileStone?.name}:{' '}
                  <strong>{nextMileStone?.points}</strong>{' '}
                  <span>{getTranslationFormat(AppConstant.COMMON.POINTS)}</span>
                </div>
              </div>
              <div
                className={styles.unlockProgess}
                onClick={handleDiscoverProgress}
              >
                <p>
                  {getTranslationFormat(
                    AppConstant.PROFILE.PROFILE_SECTION_DISCOVER_TEXT
                  )}
                </p>
                <span className={styles.arrow}>
                  <RightArrowIcon />
                </span>
              </div>
            </>
          )}
        </div>
      </div>
      <Dialog
        open={editProfilePopup}
        onClose={handleEditProfileClose}
        className={styles.editImgModal}
      >
        <DialogContent>
          <h4 className={styles.title}>
            {getTranslationFormat(
              AppConstant.PROFILE.EDIT_PROFILE_PICTURE_TEXT
            )}
          </h4>
          <div className={styles.imgCta}>
            <div className={styles.profileImg}>
              <img
                src={image ? image : ImageConstant.SOURCE.defaultUserPic}
                alt='profile img'
                onError={(e) =>
                  ((e.target as HTMLInputElement).src =
                    ImageConstant.SOURCE.defaultUserPic)
                }
              />
            </div>
            <div className={styles.ctas}>
              <label className={styles.uploadCta}>
                <input
                  type='file'
                  accept='image/*'
                  onChange={(e: any) => handleProfilePicChange(e)}
                />
                {getTranslationFormat(AppConstant.PROFILE.UPLOAD_CTA)}
              </label>
              <button onClick={handleRemoveProfilePic}>
                {getTranslationFormat(AppConstant.PROFILE.REMOVE_CTA)}
              </button>
            </div>
          </div>
          <div className={styles.actionCtas}>
            <button onClick={handleEditProfileClose}>
              {getTranslationFormat(AppConstant.PROFILE.CANCEL_CTA)}
            </button>
            <button
              className={styles.save}
              onClick={() => handleSave(binaryData, removePic)}
            >
              {getTranslationFormat(AppConstant.PROFILE.SAVE_CTA)}
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
