import {
  AppConstant,
  ImageConstant,
  getOnlyDate,
  translate,
} from '@shalina-app/shared';

import styles from './surveyCard.module.scss';
import { SurveyCardPropsItems } from './surveyCard.interface';
import { URLConstant } from '../../../routes';
import { convertToSpecialties, getImageSrc, useNavigatePageUtils } from '../../../utils';
import { Speciality } from '../../../components';

export const SurveyCardComponent: React.FC<SurveyCardPropsItems> = ({
  data,
}) => {
  const { DateIcon, AuthorIcon } = ImageConstant.SVG_ICONS;
  const { urls } = URLConstant;
  const t = translate();
  const navigatePage = useNavigatePageUtils();
  const navigateToDetails = () => {
    const navigateDetails = {
      isDynamicUrl: true,
      dynamicUrlOptions: {
        surveyId: data?.nid,
      },
    };
    navigatePage(urls.surveyDetails, navigateDetails);
  };
  return (
    <div>
      <div className={styles.cardContainer}>
        <div className={styles.cardWrapper}>
          <div className={styles.contentType}>
            <img src={ImageConstant.SOURCE.SurveysIcon} alt='tag' />
            <span>{t(AppConstant.COMMON.SURVEY)}</span>
          </div>
          <div className={styles.titleImg}>
            <h4 className={styles.cardTitle} onClick={navigateToDetails}>
              {data?.title || ''}
            </h4>
            {data?.field_thumbnail_image ? (
              <img
                src={getImageSrc(data?.field_thumbnail_image)}
                alt='card img'
              />
            ) : (
              <img
                src={ImageConstant.SOURCE.cardDefaultThumbnail}
                alt='card img'
              />
            )}
          </div>
          {/* {data?.date && ( */}
          <div className={styles.heightWrapper}>
            <div className={styles.speciality}>
              {data?.field_area_of_interest && (
                <Speciality
                  speciality={convertToSpecialties(
                    data?.field_area_of_interest || ''
                  )}
                  showAll={false}
                />
              )}
            </div>
            <div className={styles.date}>
              <DateIcon />
              <span>{t(AppConstant.COMMON.DATE)}</span>
              {getOnlyDate(data?.field_start_date)}
            </div>
          </div>
          <div className={styles.postBy}>
            <AuthorIcon />
            <span>
              <strong>{t(AppConstant.COMMON.BY_TEXT)}</strong>
              {data?.field_survey_posted_by
                ? data?.field_survey_posted_by
                : 'NA'}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
