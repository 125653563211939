import {
  AppConstant,
  COMMUNITIES_API_CATEGORY_TYPES,
  SHOW_RECOMMENDED_COUNT,
  translate
} from '@shalina-app/shared';

import { CommunitiesShortCard } from '..';
import { RecommendedSectionProps } from './recommended.interface';
import styles from './recommended.module.scss';

export const RecommendedSection: React.FC<RecommendedSectionProps> = ({
  viewAllCategory,
  communitiesList,
  handleCommunityClick,
  handleCommunityViewAllClick,
  viewAllVisible,
  tabChange,
  handleMainApiCall
}) => {
  const t = translate();
  return (
    <div className={styles.recommended}>
      <div className={styles.head}>
        <h4>{t(AppConstant.COMMUNITY.RECOMMENDED_HEADING)}</h4>
        {viewAllVisible ? (
          <button
            onClick={() =>
              handleCommunityViewAllClick(
                COMMUNITIES_API_CATEGORY_TYPES.RECOMMENDED_COMMUNITY
              )
            }
          >
            {t(AppConstant.COMMUNITY.COMMUNITIES_VIEW_ALL_CTA)}
          </button>
        ) : null}
      </div>
      {communitiesList?.map(
        (community: any, index: number) =>
          index < SHOW_RECOMMENDED_COUNT && (
            <CommunitiesShortCard
              isRequest={false}
              categoryType={viewAllCategory}
              content={community}
              handleCommunityClick={handleCommunityClick}
              tabChange={tabChange}
              handleMainApiCall={handleMainApiCall}
            />
          )
      )}
    </div>
  );
};
