import React, { useEffect, useState } from 'react';
import { AppConstant, ImageConstant, translate } from '@shalina-app/shared';

import { ImageSelectorItemProps } from './imageSelector.interface';
import styles from './imageSelector.module.scss';
import { ImageShowDialogComponent } from '../imageShowDialog';

export const ImageSelector: React.FC<ImageSelectorItemProps> = ({
  handleFileChange,
  isReset,
  inputImage,
  handleError,
  handleRemoveImage,
  imageDisplayUrl
}) => {
  const { UploadIcon } = ImageConstant.SVG_ICONS;
  const t = translate();
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [imageBase64, setImageBase64] = useState<string | ArrayBuffer | null>(
    ''
  );
  const [errorMessage, setErrorMessage] = useState('');
  const [showImagePopUp, setShowImagePopUp] = useState(false);

  // effect
  useEffect(() => {
    if (inputImage) {
      setSelectedImage({ name: inputImage } as any);
    } else {
      setSelectedImage(null);
    }
  }, [inputImage]);

  useEffect(() => {
    if (isReset) {
      setSelectedImage(null);
      setErrorMessage('');
    }
  }, [isReset]);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0];

    if (
      file &&
      file.type.startsWith('image/') &&
      file.size <= 3 * 1024 * 1024
    ) {
      const reader = new FileReader();
      reader.readAsDataURL(file); // Start reading the file asynchronously
      reader.onloadend = () => {
        const base64String = reader.result as string; // Type assertion to string

        setSelectedImage(file);
        setErrorMessage('');

        if (base64String) {
          handleFileChange({
            name: file.name,
            image_base64: base64String
          });
        }
        handleError(false);
        setErrorMessage('');
      };

      reader.onerror = (error) => {
        setErrorMessage(`Error reading the file: ${error}`);
      };
    } else {
      // setSelectedImage(file ? file : null);
      handleError(true);
      setErrorMessage(`${t(AppConstant.HOME_PAGE.IMAGE_MAX_MB_ERROR_TEXT)}`);
    }
  };

  const handleRemove = () => {
    setSelectedImage(null);
    setErrorMessage('');
    handleRemoveImage();
  };

  const handleImageShowClosePopUp = () => {
    setShowImagePopUp(false);
  };

  return (
    <>
      <div className={styles.shalinaUploadCta}>
        <label htmlFor='uploadFile' className={styles.uploadLabel}>
          {t(AppConstant.HOME_PAGE.UPLOAD_ATTACHMENT)}
        </label>
        {selectedImage ? (
          <>
            <div className={styles.uploadedImageWrapper}>
              <span onClick={() => setShowImagePopUp(true)}>
                {selectedImage.name}
              </span>
              <button onClick={handleRemove}>&times;</button>
            </div>
            {/* {errorMessage && <p>{errorMessage}</p>} */}
          </>
        ) : (
          <>
            <label className={styles.uploadWrapper}>
              <UploadIcon /> {t(AppConstant.HOME_PAGE.UPLOAD_IMAGE_TEXT)}
              <input
                type='file'
                accept='image/jpg, image/jpeg, image/png'
                id='uploadFile'
                onChange={handleImageChange}
              />
            </label>
            {errorMessage && <p>{errorMessage}</p>}
          </>
        )}
      </div>

      {showImagePopUp && imageDisplayUrl && (
        <ImageShowDialogComponent
          imageUrl={imageDisplayUrl}
          onClose={handleImageShowClosePopUp}
        />
      )}
    </>
  );
};
