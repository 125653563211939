import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { AppConstant, ImageConstant, translate } from '@shalina-app/shared';
import { Link } from 'react-router-dom';

import { BreadCrumb } from '../../../../../breadcrumbs';
import { CircularLoader } from '../../../../../loader';
import styles from './rssDetail.module.scss';
import { STORAGE_CONSTANTS, retrieveData } from '../../../../../../utils';
import { URLConstant } from '../../../../../../routes';
import { HyperLink } from '../../../../../hyperLink';

const RssDetailPage = () => {
  const { urls } = URLConstant;
  const location = useLocation();
  const t = translate();

  const pathName = location?.pathname;
  const rssContentData = location?.state?.rssContentData;
  const isLoggedIn: boolean = retrieveData(STORAGE_CONSTANTS.IS_LOGGED_IN);
  const shortDesc =
    rssContentData?.content?.substring(0, 100) ||
    rssContentData?.contentSnippet?.substring(0, 100);
  const guestUserDescription = shortDesc
    ? parse(shortDesc)
    : t(AppConstant.KNOWLEDGE_HUB_PAGE.NO_DESCRIPTION_AVAILABLE);

  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const readMoreClick = () => {
    // setLoading(true);
    // setOpen(true);
    window.open(rssContentData?.link, '_blank');
    setUrl(rssContentData?.link);
  };

  const handleClose = () => setOpen(false);
  const handleLoad = () => {
    setLoading(false);
  };
  const { DropDownArrowIcon } = ImageConstant.SVG_ICONS;

  return (
    <div className={styles.rssPage}>
      <div className='cust-container'>
        <BreadCrumb rssFeed={true} />
        <div className={styles.pageWrapper}>
          {!rssContentData && <CircularLoader size={50} thickness={4} />}
          <span className={styles.header}>{rssContentData?.header}</span>
          <h1 className={styles.title}>{rssContentData?.title}</h1>
          {/* <div className={styles.shortDesc}>
            {rssContentData?.contentSnippet
              ? rssContentData?.contentSnippet
              : t(AppConstant.KNOWLEDGE_HUB_PAGE.NO_DESCRIPTION_AVAILABLE)}
          </div> */}
          {isLoggedIn ? (
            <>
              <div className={styles.description}>
                {rssContentData?.content
                  ? parse(rssContentData?.content as string)
                  : parse(rssContentData?.contentSnippet) ||
                    t(AppConstant.KNOWLEDGE_HUB_PAGE.NO_DESCRIPTION_AVAILABLE)}
              </div>
              <HyperLink
                handleClick={readMoreClick}
                hyperText={t(AppConstant.COMMON.READ_MORE_TEXT)}
                disabled={false}
              />
            </>
          ) : (
            <div className={`${styles.description} ${styles.guestDesc}`}>
              {' '}
              {guestUserDescription} {'...'}
              <div className={styles.guestDesc}>
                <Link
                  to={urls.signUpPage}
                  state={{ pathName: pathName, rssContentData: rssContentData }}
                >
                  <button>
                    {t(AppConstant.AUTHENTICATION_PAGE.SIGN_UP_TO_CONTINUE)}
                  </button>
                </Link>
              </div>
            </div>
          )}
        </div>

        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          className={styles.ebescoModal}
        >
          <DialogContent>
            <div className={styles.closeDiv} id='closeDiv'>
              {loading && <CircularLoader size={50} thickness={4} />}
              <button className={styles.backBtn} onClick={handleClose}>
                <DropDownArrowIcon />
                {t(AppConstant.COMMON.BACK_CTA)}
              </button>
            </div>

            {/* <iframe src={url} className={styles.iframe} /> */}
            <object
              data={url}
              className={styles.iframe}
              // onLoad={handleLoad}
            ></object>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default RssDetailPage;
