import {
  AppConstant,
  getOnlyDate,
  getOnlyTime,
  ImageConstant,
  translate
} from '@shalina-app/shared';

import { RssFeedItemsProps } from './rssNewsFeedCard.interface';
import styles from './rssNewsFeedCard.module.scss';

const { DateIcon, TimeIcon } = ImageConstant.SVG_ICONS;

export const RssNewsFeedCard: React.FC<RssFeedItemsProps> = ({
  rssData,
  isDescription,
  handleRssCardTitleClick
}) => {
  const t = translate();
  const handleCardClick = (rssId: string) => {
    if (handleRssCardTitleClick && rssId) {
      handleRssCardTitleClick(rssId);
    }
  };

  return (
    <>
      <div className={styles.slideItem}>
        <div className={styles.cardContainer}>
          <div className={styles.cardWrapper}>
            <div className={styles.contentType}>
              <img
                src={ImageConstant.SOURCE.MedicalNewsIcon}
                alt='content icon'
              />
              <span>{t(AppConstant.HOME_PAGE.MEDICAL_NEWS)}</span>
            </div>
            <h4
              className={styles.cardTitle}
              onClick={() => handleCardClick(rssData?.id as string)}
            >
              {rssData?.title}
            </h4>
            {isDescription && <span>{rssData?.contentSnippet}</span>}
            <div className={styles.dateWrapper}>
              <div className={`${styles.dateTime} ${styles.date}`}>
                <DateIcon />
                <span>{t(AppConstant.COMMON.DATE)}</span>
                {getOnlyDate(rssData?.isoDate)}
              </div>
              <div className={styles.dateTime}>
                <TimeIcon />
                <span>{t(AppConstant.COMMON.TIME)}</span>
                {getOnlyTime(rssData?.isoDate)}
              </div>
            </div>
            <div className={styles.publisher}>
              <span>{t(AppConstant.COMMON.PUBLISHED_BY)}</span>
              {rssData?.header}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
