"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetSpeakerDetails = exports.updateBookmarkStateForShalinaTV = exports.updateBookmarkStateForShalinaContentDetails = exports.getSpeakerDetails = exports.getShalinaTvViewAllListData = exports.getShalinaTVContentListData = exports.fetchSpeakerDetails = exports.fetchShalinaTVContentByType = exports.fetchShalinaTVContent = void 0;
const api_1 = require("../../../api");
const toolkit_1 = require("@reduxjs/toolkit");
const constants_1 = require("../../constants");
const utils_1 = require("../../utils");
const initialState = {
    isLoading: false,
    shalinaTV: {
        isError: false,
        isListLoading: false,
        shalinaTVContentList: {},
    },
    viewAllContent: {
        isError: false,
        isAllContentLoading: false,
        viewAllContentList: {},
    },
    speakerListContent: {
        isError: false,
        isSpeakerListContentLoading: false,
        speakerListContent: {},
    },
};
const shalinaTVSlice = (0, toolkit_1.createSlice)({
    name: constants_1.SHALINA_TV,
    initialState,
    reducers: {
        updateBookmarkStateForShalinaTV: (state, action) => {
            var _a, _b;
            const { nid, contentTypeId } = action.payload;
            const contentListByContentType = ((_a = state.shalinaTV.shalinaTVContentList) === null || _a === void 0 ? void 0 : _a.data)
                ? (_b = state.shalinaTV.shalinaTVContentList) === null || _b === void 0 ? void 0 : _b.data[contentTypeId]
                : [];
            if (contentListByContentType && (contentListByContentType === null || contentListByContentType === void 0 ? void 0 : contentListByContentType.length) > 0) {
                const updatedContentList = contentListByContentType.map((item) => item.nid === nid ? Object.assign(Object.assign({}, item), { is_bookmarked: !item.is_bookmarked }) : item);
                state.shalinaTV.shalinaTVContentList.data[contentTypeId] = updatedContentList;
            }
        },
        updateBookmarkStateForShalinaContentDetails: (state, action) => {
            var _a, _b;
            const { id } = action.payload;
            const contentListByContentType = ((_a = state.shalinaTV.shalinaTVContentList) === null || _a === void 0 ? void 0 : _a.data)
                ? (_b = state.shalinaTV.shalinaTVContentList) === null || _b === void 0 ? void 0 : _b.data
                : [];
            if (contentListByContentType && (contentListByContentType === null || contentListByContentType === void 0 ? void 0 : contentListByContentType.length) > 0) {
                const updatedContentList = contentListByContentType.map((item) => item.nid === id ? Object.assign(Object.assign({}, item), { is_bookmarked: !item.is_bookmarked }) : item);
                state.shalinaTV.shalinaTVContentList.data = updatedContentList;
            }
        },
        resetSpeakerDetails: (state) => {
            state.speakerListContent.speakerListContent = {};
        }
    },
    extraReducers(builder) {
        /**
             *Content API
            //  */
        builder.addCase(exports.fetchShalinaTVContent.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.shalinaTV.isListLoading = true;
        });
        builder.addCase(exports.fetchShalinaTVContent.fulfilled, (state, action) => {
            // Add logic if any when API state is fulfilled or data is received from API
            state.shalinaTV.isListLoading = false;
            state.shalinaTV.shalinaTVContentList = action === null || action === void 0 ? void 0 : action.payload;
        });
        builder.addCase(exports.fetchShalinaTVContent.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.shalinaTV.shalinaTVContentList = false;
        });
        builder.addCase(exports.fetchShalinaTVContentByType.pending, (state, action) => {
            state.viewAllContent.isAllContentLoading = true;
        });
        builder.addCase(exports.fetchShalinaTVContentByType.fulfilled, (state, action) => {
            state.viewAllContent.isAllContentLoading = false;
            state.viewAllContent.viewAllContentList = action === null || action === void 0 ? void 0 : action.payload;
        });
        builder.addCase(exports.fetchShalinaTVContentByType.rejected, (state, action) => {
            state.viewAllContent.viewAllContentList = false;
        });
        builder.addCase(exports.fetchSpeakerDetails.pending, (state, action) => {
            state.speakerListContent.isSpeakerListContentLoading = true;
        });
        builder.addCase(exports.fetchSpeakerDetails.fulfilled, (state, action) => {
            state.speakerListContent.isSpeakerListContentLoading = false;
            state.speakerListContent.speakerListContent = action === null || action === void 0 ? void 0 : action.payload;
        });
        builder.addCase(exports.fetchSpeakerDetails.rejected, (state, action) => {
            state.speakerListContent.isSpeakerListContentLoading = false;
        });
    },
});
exports.fetchShalinaTVContent = (0, toolkit_1.createAsyncThunk)(`${constants_1.SHALINA_TV}/${constants_1.LIST_SHALINA_TV}`, (filterOptions) => __awaiter(void 0, void 0, void 0, function* () {
    const params = (0, utils_1.filterOptionsParams)(filterOptions);
    const contentList = yield (0, api_1.get)(`${api_1.ENDPOINTS.LIST_CONTENT_ENDPOINT}${params}`, {
        baseURL: api_1.baseUrl
    });
    return contentList;
}));
// export const fetchShalinaTVContentByType = createAsyncThunk(`${SHALINA_TV}/${FETCH_SHALINA_TV_LIST_BY_TYPE_ENDPOINT}`, async (contentID:string) => {
//     const contentList: any | null = await get(`${FETCH_SHALINA_TV_LIST_BY_TYPE_ENDPOINT(contentID)}`);
//     return contentList as any;
// });
exports.fetchShalinaTVContentByType = (0, toolkit_1.createAsyncThunk)(`${constants_1.SHALINA_TV}/${constants_1.FETCH_LIST_BY_TYPE}`, (parameter) => __awaiter(void 0, void 0, void 0, function* () {
    const params = parameter.filterOptions
        ? (0, utils_1.filterOptionsParams)(parameter.filterOptions)
        : "";
    const contentList = yield (0, api_1.get)(`${api_1.ENDPOINTS.FETCH_LIST_BY_TYPE_ENDPOINT}/${parameter.contentId}${params}`, {
        baseURL: api_1.baseUrl
    });
    return contentList;
}));
exports.fetchSpeakerDetails = (0, toolkit_1.createAsyncThunk)(`${constants_1.SHALINA_TV}/${constants_1.FETCH_SPEAKER_DETAILS}`, (parameter) => __awaiter(void 0, void 0, void 0, function* () {
    const speakerList = yield (0, api_1.get)(`${constants_1.LIST_SPEAKER_DETAILS}?event_id=${(parameter === null || parameter === void 0 ? void 0 : parameter.eventId) ? parameter.eventId : ""}`);
    return speakerList;
}));
exports.getShalinaTVContentListData = (0, toolkit_1.createSelector)((state) => state.shalinatv, (shalinatv) => { var _a, _b; return (_b = (_a = shalinatv === null || shalinatv === void 0 ? void 0 : shalinatv.shalinaTV) === null || _a === void 0 ? void 0 : _a.shalinaTVContentList) === null || _b === void 0 ? void 0 : _b.data; });
exports.getShalinaTvViewAllListData = (0, toolkit_1.createSelector)((state) => state.shalinatv, (shalinatv) => { var _a, _b; return (_b = (_a = shalinatv === null || shalinatv === void 0 ? void 0 : shalinatv.viewAllContent) === null || _a === void 0 ? void 0 : _a.viewAllContentList) === null || _b === void 0 ? void 0 : _b.data; });
exports.getSpeakerDetails = (0, toolkit_1.createSelector)((state) => state.shalinatv, (shalinatv) => { var _a, _b; return (_b = (_a = shalinatv === null || shalinatv === void 0 ? void 0 : shalinatv.speakerListContent) === null || _a === void 0 ? void 0 : _a.speakerListContent) === null || _b === void 0 ? void 0 : _b.data; });
_a = shalinaTVSlice.actions, exports.updateBookmarkStateForShalinaContentDetails = _a.updateBookmarkStateForShalinaContentDetails, exports.updateBookmarkStateForShalinaTV = _a.updateBookmarkStateForShalinaTV, exports.resetSpeakerDetails = _a.resetSpeakerDetails;
exports.default = shalinaTVSlice.reducer;
