import { TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {
  AppConstant,
  REQUEST_TYPE_UNIQUE_CODE,
  getRequestType,
  submitRequest,
  translate,
  useAppDispatch,
  useAppSelector,
  CUSTOM_ANALYTICS_EVENT_NAMES,
  logEvent,
  PlatformNames,
  getContentTypesListForRequest,
  getAnalyticsUserDetails,
  getLoggedInUserInfo,
} from '@shalina-app/shared';
import React, { useEffect, useState } from 'react';

import {
  ButtonComponent,
  Dropdown,
  ImageSelector,
  SuccessDialogComponent,
} from '../../../../components';
import { RequestFormItemProps } from './requestForm.interface';
import styles from './requestForm.module.scss';
import { useLocation } from 'react-router-dom';
import {
  getImageSrc,
  retrieveData,
  STORAGE_CONSTANTS,
} from '../../../../utils';
import { REQUEST_MODULE } from '../../../../app.constants';
import { appInsights } from '../../../../applicationInsight';

export const RequestFormComponent: React.FC<RequestFormItemProps> = ({
  isModifyRequest,
  data,
  showForm,
  modifiedDialogClose,
  hadleSuccessClose,
  handleToast,
}) => {
  const t = translate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const user = retrieveData(STORAGE_CONSTANTS.USER);
  const isLoggedIn: boolean = retrieveData(STORAGE_CONSTANTS.IS_LOGGED_IN);
  const userDetails = useAppSelector(getLoggedInUserInfo);

  // states
  const isNavigatedFromSearch = location?.state?.isNavigatedFromSearch;
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [letterCount, setLetterCount] = useState(0);
  const [modifyPopUp, setModifyPopup] = useState(false);
  const [successPopUp, setSuccessPopUp] = useState(false);
  const [requestType, setRequestType] = useState('');
  const [imageName, setImageName] = useState('');
  const [contentType, setContentType] = useState('');
  const [description, setDescription] = useState('');
  const [imagePayload, setImagePayload] = useState<any>({});
  const [requestTypeDropdown, setRequestTypeDropdown] = useState<any>([]);
  const [contentTypeDropdown, setContentTypeDropdown] = useState([]);
  const [showContentTypeDropdown, setShowContentTypeDropdown] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [successButtonText, setSuccessButtonText] = useState('');
  const [imageError, setImageError] = useState(false);
  const [isResetImage, setIsResetImage] = useState(false);
  const requestList = useAppSelector(getRequestType);
  const [descriptionError, setDescriptionError] = useState('');
  const [imageDisplayUrl, setImageDisplayUrl] = useState('');

  const contentTypeList = useAppSelector(getContentTypesListForRequest);

  // effects
  useEffect(() => {
    //window.scrollTo(0, 0);
    setRefresh(true);
    setShowContentTypeDropdown(isNavigatedFromSearch);
  }, []);

  useEffect(() => {
    if (refresh) {
      loadAPI();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    if (requestList && requestList?.length) {
      setRequestTypeDropdown(requestList);
    }
  }, [requestList]);

  useEffect(() => {
    if (contentTypeList?.length) {
      setContentTypeDropdown(contentTypeList);
    }
  }, [contentTypeList]);

  useEffect(() => {
    setLetterCount(description?.length ? description?.length : 0);
  }, [description]);

  useEffect(() => {
    if (requestType && description.trim()) {
      setSubmitButtonDisabled(false);
    } else {
      setSubmitButtonDisabled(true);
    }

    if (requestType) {
      const requestUnique = requestTypeDropdown.find(
        (element: any) => element.value === requestType
      );
      if (
        requestUnique?.field_unique_code ===
        REQUEST_TYPE_UNIQUE_CODE.REQUEST_CONTENT_RELATED_SUPPORT
      ) {
        setShowContentTypeDropdown(true);
      } else {
        setShowContentTypeDropdown(false);
        setContentType('');
      }
    }
  }, [description, requestType, imageError]);

  useEffect(() => {
    if (isModifyRequest && data?.nid) {
      setIsLoading(true);
      if (data?.field_description && !description) {
        setDescription(data?.field_description ? data?.field_description : '');
      }
      if (data?.field_attachment && !imageName) {
        setImageDisplayUrl(getImageSrc(data?.field_attachment));
        const urlParts = data?.field_attachment
          ? data?.field_attachment?.split('/')
          : '';
        // Extract the last part of the array, which is the image name
        const imgName = urlParts[urlParts.length - 1];
        setImageName(imgName);
        setImagePayload({
          name: imgName,
        });
      }
      if (requestTypeDropdown?.length && contentTypeDropdown?.length) {
        if (data?.field_request_type_id) {
          // const requestObj = requestTypeDropdown?.find((e: any) => e.label === data.field_request_type);
          setRequestType(data?.field_request_type_id);
        }

        if (data?.field_content_type_id) {
          // const contentObj = contentTypeDropdown?.find((e: any) => e.label === data?.field_content_type) as any;
          setContentType(data?.field_content_type_id);
        }
        setIsLoading(false);
      } else if (
        (requestTypeDropdown && requestTypeDropdown?.length === 0) ||
        (contentTypeDropdown && contentTypeDropdown?.length === 0)
      ) {
        setIsLoading(false);
      }
    } else {
      if (isNavigatedFromSearch && requestTypeDropdown?.length > 0) {
        const requestItem = requestTypeDropdown.find(
          (e: any) =>
            e.field_unique_code ===
            REQUEST_TYPE_UNIQUE_CODE.REQUEST_CONTENT_RELATED_SUPPORT
        );
        setRequestType(requestItem?.value ? requestItem?.value : '');
      }
    }
  }, [data, requestTypeDropdown, contentTypeDropdown]);

  // functions

  const loadAPI = async () => {
    isModifyRequest ? handleModifyOpen() : handleModifyClose();
    // await dispatch(getRequestTypeList());
  };

  const handleModifyClose = () => {
    setModifyPopup(false);
    modifiedDialogClose();
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const response = await dispatch(
      submitRequest({
        contentType: contentType ? contentType : (null as any),
        requestType: requestType,
        image: (imagePayload?.name as any) ? imagePayload : null,
        description: description,
      })
    ).unwrap();
    setIsLoading(false);
    if (response?.statusCode === 200 && response?.data) {
      handleModifyClose();
      setSuccessPopUp(true);
      // setSuccessMessage(t(response.message));
      setSuccessMessage(
        `${
          contentType
            ? t(AppConstant.HOME_PAGE.RAISE_REQUEST_CONTENT_MESSAGE)
            : t(AppConstant.HOME_PAGE.RAISE_REQUEST_MESSAGE)
        }`
      );

      const requestTypeName = requestTypeDropdown?.find(
        (item: any) => item?.value === requestType
      )?.label;

      const userObj = userDetails || user;
      const userInfo = getAnalyticsUserDetails(userObj);

      if (isLoggedIn && requestTypeName && userInfo) {
        logEvent(
          appInsights,
          'custom',
          CUSTOM_ANALYTICS_EVENT_NAMES.USER_REQUEST,
          {
            userEmail: userInfo?.emailId,
            userMobile: userInfo?.mobile,
            userName: userInfo?.name,
            userQualification: userInfo?.qualification,
            userInterest: userInfo?.areaOfInterest,
            userSpecialization: userInfo?.specialization,
            userCountryOfPractice: userInfo?.countryOfPractice,
            userPreferredLanguage: userInfo?.preferredLanguage,
            requestType: requestTypeName,
            requestDescription: description || "NA",
            platform: PlatformNames.WEB,
          }
        );
      }
      setSuccessButtonText(
        `${t(AppConstant.HOME_PAGE.GO_TO_REQUEST_LIST_CTA)}`
      );
      resetForm();
    }
  };

  const handleModify = async () => {
    setIsLoading(true);
    const response = await dispatch(
      submitRequest({
        nid: data?.nid,
        contentType: contentType ? contentType : (null as any),
        requestType: requestType,
        image: ((imagePayload?.name && imagePayload?.image_base64) as any)
          ? imagePayload
          : imagePayload?.name === imageName && imageName
          ? {
              name: imageName,
              image_base64: '',
            }
          : {
              name: imageName,
              image_base64: null,
            },
        description: description,
      })
    ).unwrap();
    setIsLoading(false);
    if (response.statusCode === 200 && response.data) {
      setModifyPopup(false);
      handleToast({
        showToast: true,
        isSuccess: true,
        toastMainMessage: t(AppConstant.COMMON.REQUEST_MODIFIED_MSG),
      });
      handleModifyClose();
      resetForm();
    } else {
      handleToast({
        showToast: true,
        isSuccess: false,
        toastMainMessage: t(AppConstant.ERROR.SOMETHING_WENT_WRONG),
      });
    }
  };

  const resetForm = () => {
    setContentType('');
    setRequestType('');
    setDescription('');
    setImagePayload({});
    setImageName('');
    setIsResetImage(true);
  };

  const handleModifyOpen = () => {
    setModifyPopup(true);
  };

  const successPopUpClose = () => {
    setSuccessPopUp(false);
  };

  const handleDescriptionChange = (event: any) => {
    let inputValue = event.target.value;
    if (inputValue?.length > REQUEST_MODULE.TEXTBOX_CHAR_LIMIT) {
      inputValue = inputValue.slice(0, REQUEST_MODULE.TEXTBOX_CHAR_LIMIT);
    }
    setDescriptionError('');
    setDescription(
      inputValue?.trim()?.length === 0 ? inputValue?.trim() : inputValue
    );
  };

  const handleRequestTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRequestType(e?.target?.value);
  };

  const handleContentTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setContentType(e?.target?.value);
  };

  const handleFileChange = (payload: { name: string; image_base64: any }) => {
    setImagePayload(payload);
    if (requestType && description.trim()) {
      setSubmitButtonDisabled(false);
    } else {
      setSubmitButtonDisabled(true);
    }
  };

  const handleImageError = (err: boolean) => {
    setImageError(err);
    if (requestType && description.trim()) {
      setSubmitButtonDisabled(false);
    } else {
      setSubmitButtonDisabled(true);
    }
  };

  const handleRemoveImage = () => {
    setImageName('');
    setImagePayload({});
    setImageError(false);
    if (requestType && description.trim()) {
      setSubmitButtonDisabled(false);
    } else {
      setSubmitButtonDisabled(true);
    }
  };

  const closeSuccessHandle = () => {
    resetForm();
    successPopUpClose();
    hadleSuccessClose();
  };

  const handleDescriptionError = () => {
    if (description?.trim()?.length === 0) {
      setDescriptionError(t(AppConstant.COMMON.DESCRIPTION_TEXT_ERROR));
    }
  };

  return (
    <>
      {/* modify and revoke popup */}
      {!modifyPopUp && showForm && (
        <div className={styles.helpdeskForm}>
          <div className={styles.helpdeskHead}>
            <h4 className={styles.title}>
              {t(AppConstant.HOME_PAGE.SUBMIT_REQUEST)}
            </h4>
          </div>
          <div className={styles.helpdeskFormCol}>
            <Dropdown
              defaultValue=''
              value={requestType}
              dropdownId='request-type'
              dropdownVariant='outlined'
              labelText={t(AppConstant.HOME_PAGE.REQUEST_TYPE)}
              isRequired={true}
              dropdownOptions={requestTypeDropdown}
              handleDropDownChange={(e: any) => handleRequestTypeChange(e)}
              isError={false}
              errorMessage={'Please Select Valid Option'}
              placeholder={t(AppConstant.COMMON.SELECT_REQUEST_TYPE)}
            />
          </div>
          {showContentTypeDropdown && (
            <div className={styles.helpdeskFormCol}>
              <Dropdown
                defaultValue=''
                value={contentType}
                dropdownId='content-type'
                dropdownVariant='outlined'
                labelText={t(AppConstant.HOME_PAGE.CONTENT_TYPE)}
                isRequired={false}
                handleDropDownChange={(e: any) => handleContentTypeChange(e)}
                dropdownOptions={contentTypeDropdown}
                isError={false}
                errorMessage={'Please Select Valid Option'}
                placeholder={t(AppConstant.COMMON.SELECT_CONTENT_TYPE)}
              />
            </div>
          )}
          <div className={styles.helpdeskFormCol}>
            <div className={styles.shalinaTextArea}>
              <label htmlFor='description' className={styles.textAreaLabel}>
                <div>
                  {t(AppConstant.HOME_PAGE.DESCRIPTION_TEXT)}
                  <span className={`${styles.astrick}`}>*</span>
                </div>
                {/* <span className={`${styles.count}`}>{letterCount}/{REQUEST_MODULE.TEXTBOX_CHAR_LIMIT}</span> */}
              </label>
              <TextField
                placeholder={t(AppConstant.COMMON.DESCRIPTION_TEXT_PLACEHOLDER)}
                id='description'
                fullWidth
                multiline
                rows={4}
                onBlur={handleDescriptionError}
                defaultValue=''
                value={description}
                onChange={handleDescriptionChange}
                disabled={false} // Ensure it is not set to true
              />
              {descriptionError && <p>{descriptionError}</p>}
            </div>
          </div>
          <div className={styles.helpdeskFormCol}>
            <ImageSelector
              handleFileChange={handleFileChange}
              inputImage={imageName}
              isReset={isResetImage}
              handleError={handleImageError}
              handleRemoveImage={handleRemoveImage}
              imageDisplayUrl=''
            />
            <span className={styles.fileNote}>
              {t(AppConstant.COMMON.SUPPORT_UPLOAD_IMAGE_HELP_TEXT)}
            </span>
          </div>
          <div>
            <ButtonComponent
              variant='outlined'
              buttonText={t(AppConstant.HOME_PAGE.APPLY_CTA)}
              loader={isLoading}
              disabled={submitButtonDisabled}
              onPress={handleSubmit}
            />
          </div>
        </div>
      )}

      {/* modify and revoke popup */}
      <Dialog
        open={modifyPopUp}
        // onClose={handleRevokeClose}
        className={styles.modifyModal}
      >
        {/* {isLoading &&
					<span className={styles.downloadLoader}>
						<CircularProgress
							size={25}
							thickness={4}
							sx={{
								color: "var(--secondaryOrange)",
							}}
						/>
					</span>
				} */}
        <DialogContent>
          <div className={styles.helpdeskForm}>
            <div className={styles.helpdeskHead}>
              <h4 className={styles.title}>
                {t(AppConstant.HOME_PAGE.MODIFY_REQUEST)}
              </h4>
              <span
                className={`${styles.closeCta}`}
                onClick={() => {
                  handleModifyClose();
                }}
              >
                &times;
              </span>
            </div>
            <div className={styles.helpdeskFormCol}>
              <Dropdown
                defaultValue=''
                value={requestType}
                dropdownId='request-type'
                dropdownVariant='outlined'
                labelText={t(AppConstant.HOME_PAGE.REQUEST_TYPE)}
                isRequired={true}
                dropdownOptions={requestTypeDropdown}
                handleDropDownChange={(e: any) => handleRequestTypeChange(e)}
                isError={false}
                errorMessage={'Incorrect'}
                placeholder={t(AppConstant.COMMON.SELECT_REQUEST_TYPE)}
              />
            </div>
            {showContentTypeDropdown && (
              <div className={styles.helpdeskFormCol}>
                <Dropdown
                  defaultValue=''
                  value={contentType}
                  dropdownId='content-type'
                  dropdownVariant='outlined'
                  labelText={t(AppConstant.HOME_PAGE.CONTENT_TYPE)}
                  isRequired={false}
                  dropdownOptions={contentTypeDropdown}
                  handleDropDownChange={(e: any) => handleContentTypeChange(e)}
                  isError={false}
                  errorMessage={'Please Select Valid Option'}
                  placeholder={t(AppConstant.COMMON.SELECT_CONTENT_TYPE)}
                />
              </div>
            )}
            <div className={styles.helpdeskFormCol}>
              <div className={styles.shalinaTextArea}>
                <label htmlFor='description' className={styles.textAreaLabel}>
                  <div>
                    {t(AppConstant.HOME_PAGE.DESCRIPTION_TEXT)}
                    <span className={`${styles.astrick}`}>*</span>
                  </div>
                  {/* <span className={`${styles.dialogCount}`}>{letterCount}/{REQUEST_MODULE.TEXTBOX_CHAR_LIMIT}</span> */}
                </label>
                <TextField
                  id='description'
                  placeholder={t(
                    AppConstant.COMMON.DESCRIPTION_TEXT_PLACEHOLDER
                  )}
                  fullWidth
                  multiline
                  rows={4}
                  onBlur={handleDescriptionError}
                  defaultValue=''
                  value={description}
                  onChange={handleDescriptionChange}
                  disabled={false} // Ensure it is not set to true
                />
                {descriptionError && <p>{descriptionError}</p>}
              </div>
            </div>
            <div className={styles.helpdeskFormCol}>
              <div className={styles.shalinaUploadCta}>
                <ImageSelector
                  handleFileChange={handleFileChange}
                  inputImage={imageName}
                  isReset={isResetImage}
                  handleError={handleImageError}
                  handleRemoveImage={handleRemoveImage}
                  imageDisplayUrl={imageDisplayUrl}
                />
                <span className={styles.fileNote}>
                  {t(AppConstant.COMMON.SUPPORT_UPLOAD_IMAGE_HELP_TEXT)}
                </span>
              </div>
            </div>
            <div>
              <ButtonComponent
                variant='outlined'
                buttonText={t(AppConstant.HOME_PAGE.APPLY_CTA)}
                loader={isLoading}
                disabled={submitButtonDisabled}
                onPress={handleModify}
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>

      {successPopUp && (
        <SuccessDialogComponent
          successMessage={successMessage}
          buttonText={successButtonText}
          closeSuccessDialog={closeSuccessHandle}
        />
      )}
    </>
  );
};
