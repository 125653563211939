import { useState, useEffect } from 'react';
import {
  useAppSelector,
  updateUserDetails,
  getLoggedInUserInfo,
  useAppDispatch,
  AppConstant,
  translate,
  NOTIFICATIONS_SETTINGS_ENUM
} from '@shalina-app/shared';

import {
  NotificationSettingProps,
  SettingPropsType
} from './notificationSetting.interface';
import styles from './notificationSetting.module.scss';
import { Toast } from '../../../../components';
import { storeAccessToken } from '../../../../utils';

export const NotificationSetting: React.FC<NotificationSettingProps> = (
  notificationSettingProps: NotificationSettingProps
) => {
  const t = translate();
  const dispatch = useAppDispatch();
  const userDetails: any = useAppSelector(getLoggedInUserInfo);

  const settingsArr: SettingPropsType[] = [
    {
      id: '1',
      enumId: NOTIFICATIONS_SETTINGS_ENUM.APP,
      label: t(AppConstant.COMMON.APP_NOTIFICATION_TEXT),
      isSwitch: false,
      disabled: false,
      shouldToggle: true
    },
    {
      id: '2',
      enumId: NOTIFICATIONS_SETTINGS_ENUM.EMAIL,
      label: t(AppConstant.PROFILE.EMAIL_NOTIFICATION_TEXT),
      isSwitch: false,
      disabled: false,
      shouldToggle: true
    },
    /* Hide for 1A */
    // {
    //   id: "4",
    // enumId: NOTIFICATIONS_SETTINGS_ENUM.SMS,
    //   label: t(AppConstant.PROFILE.SMS_NOTIFICATION_TEXT),
    //   isSwitch: false,
    //   disabled: false
    // },
    /* Hide for 1A */
    {
      id: '3',
      enumId: NOTIFICATIONS_SETTINGS_ENUM.WHATSAPP,
      label: t(AppConstant.PROFILE.WHATS_APP_NOTIFICATION_TEXT),
      isSwitch: false,
      disabled: false,
      shouldToggle: true
    }
  ];

  const [notificationItems, setNotificationItems] = useState(settingsArr);
  const [isSaveEnabled, setIsSaveEnabled] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const isNotificationLoading = useAppSelector(
    (state) => state?.user?.isLoading
  );

  useEffect(() => {
    if (userDetails) {
      setInitialValues();
    }
  }, [userDetails]);

  const setInitialValues = () => {
    const valuesFromAPI: SettingPropsType[] = [
      {
        id: '1',
        enumId: NOTIFICATIONS_SETTINGS_ENUM.APP,
        label: t(AppConstant.COMMON.APP_NOTIFICATION_TEXT),
        isSwitch:
          userDetails?.isInAppNotification || userDetails?.isPushNotification
            ? true
            : false,
        disabled: false,
        shouldToggle: true
      },
      {
        id: '2',
        enumId: NOTIFICATIONS_SETTINGS_ENUM.EMAIL,
        label: t(AppConstant.PROFILE.EMAIL_NOTIFICATION_TEXT),
        isSwitch: userDetails?.isEmailNotification ? true : false,
        disabled: false,
        shouldToggle: true
      },
      /* Hide for 1A */
      // {
      //   id: "4",
      // enumId: NOTIFICATIONS_SETTINGS_ENUM.SMS,
      //   label: t(AppConstant.PROFILE.SMS_NOTIFICATION_TEXT),
      //   isSwitch: HIDE_FOR_FIRST_RELEASE ? false : userDetails?.isSMSNotification ? true : false,
      //   disabled: HIDE_FOR_FIRST_RELEASE || false
      // },
      /* Hide for 1A */
      {
        id: '3',
        enumId: NOTIFICATIONS_SETTINGS_ENUM.WHATSAPP,
        label: t(AppConstant.PROFILE.WHATS_APP_NOTIFICATION_TEXT),
        isSwitch: userDetails?.isWhatsappNotification ? true : false,
        disabled: false,
        shouldToggle: true
      }
    ];
    setNotificationItems(valuesFromAPI);
  };

  const isValid = (item: any) => {
    const isSelected = !item.isSwitch;
    const notificationLabelsCopy = [...notificationItems];
    if (
      item.label === `${t(AppConstant.PROFILE.WHATS_APP_NOTIFICATION_TEXT)}`
    ) {
      if (!isSelected && !notificationLabelsCopy[1]?.isSwitch) {
        setShowToast(true);
        setIsSuccess(false);
        setToastMessage(t(AppConstant.COMMON.ENABLE_EMAIL_OR_WHATSAPP_MESSAGE));
        return false;
      } else {
        return true;
      }
    } else if (
      item.label === `${t(AppConstant.PROFILE.EMAIL_NOTIFICATION_TEXT)}`
    ) {
      if (!isSelected && !notificationLabelsCopy[2]?.isSwitch) {
        setShowToast(true);
        setIsSuccess(false);
        setToastMessage(t(AppConstant.COMMON.ENABLE_EMAIL_OR_WHATSAPP_MESSAGE));
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const handleCheckboxChange = (currentItem: SettingPropsType) => {
    const valid = isValid(currentItem);
    if (valid) {
      setNotificationItems((prevItems) => {
        return prevItems?.map((item: SettingPropsType) => {
          if (item?.enumId === currentItem?.enumId) {
            return { ...item, isSwitch: !item.isSwitch };
          }
          return item;
        });
      });
    }
  };

  useEffect(() => {
    const localAppNotification = notificationItems?.find(
      (item) => item?.enumId === NOTIFICATIONS_SETTINGS_ENUM?.APP
    )?.isSwitch;
    // const localPushNotification = notificationItems?.find(
    //   (item) => item?.enumId === NOTIFICATIONS_SETTINGS_ENUM?.APP
    // )?.isSwitch;
    const localEmailNotification = notificationItems?.find(
      (item) => item?.enumId === NOTIFICATIONS_SETTINGS_ENUM?.EMAIL
    )?.isSwitch;
    // const localSMSNotification = notificationItems?.find(
    //   (item) => item?.enumId === NOTIFICATIONS_SETTINGS_ENUM?.SMS
    // )?.isSwitch;
    const localWhatsappNotification = notificationItems?.find(
      (item) => item?.enumId === NOTIFICATIONS_SETTINGS_ENUM?.WHATSAPP
    )?.isSwitch;

    if (
      localAppNotification !== userDetails?.isInAppNotification ||
      localAppNotification !== userDetails?.isPushNotification ||
      localEmailNotification !== userDetails?.isEmailNotification ||
      localWhatsappNotification !== userDetails?.isWhatsappNotification
      /* Hide for 1A */
      // || localSMSNotification !== userDetails?.isSMSNotification
      /* Hide for 1A */
    ) {
      setIsSaveEnabled(true);
    } else {
      setIsSaveEnabled(false);
    }
  }, [notificationItems, userDetails]);

  const onSubmit = async () => {
    const payload = {
      isInAppNotification:
        notificationItems?.find(
          (item) => item?.enumId === NOTIFICATIONS_SETTINGS_ENUM?.APP
        )?.isSwitch || false,
      isPushNotification:
        notificationItems?.find(
          (item) => item?.enumId === NOTIFICATIONS_SETTINGS_ENUM?.APP
        )?.isSwitch || false,
      isEmailNotification:
        notificationItems?.find(
          (item) => item?.enumId === NOTIFICATIONS_SETTINGS_ENUM?.EMAIL
        )?.isSwitch || false,
      isWhatsappNotification:
        notificationItems?.find(
          (item) => item?.enumId === NOTIFICATIONS_SETTINGS_ENUM?.WHATSAPP
        )?.isSwitch || false
    };

    const response = await dispatch(updateUserDetails(payload)).unwrap();

    if (
      response &&
      response?.status === 'SUCCESS' &&
      response?.statusCode === 200
    ) {
      setShowToast(true);
      setIsSuccess(true);
      setToastMessage(t(response?.message));
      setIsSaveEnabled(false);
      if (response?.data?.token) {
        storeAccessToken(response?.data?.token);
      }
    } else {
      setShowToast(true);
      setIsSuccess(false);
      setToastMessage(t(response?.message));
      setIsSaveEnabled(true);
    }
  };

  const handleToastClose = () => {
    setShowToast(false);
  };

  return (
    <>
      <div className={styles.notificationWrapper}>
        <div className={styles.listWrapper}>
          {notificationItems?.map((item: SettingPropsType) => (
            <div className={styles.notificationOption} key={item?.enumId}>
              {item?.label}
              <label
                className={`${styles.notificationBtn} ${
                  item?.disabled && styles.disabled
                }`}
              >
                <input
                  type='checkbox'
                  checked={item?.isSwitch}
                  onChange={() => handleCheckboxChange(item)}
                  disabled={item?.disabled}
                />
                <div>{item?.shouldToggle && <span></span>}</div>
              </label>
            </div>
          ))}
        </div>
        <div className={styles.saveCta}>
          <button onClick={onSubmit} disabled={!isSaveEnabled}>
            {t(AppConstant.PROFILE.SAVE_CTA)}
          </button>
        </div>
      </div>
      <Toast
        showToast={showToast}
        isSuccess={isSuccess}
        message={toastMessage}
        handleToastClose={handleToastClose}
      />
      {/* {isNotificationLoading && <CircularLoader size={50} thickness={4} />} */}
    </>
  );
};
