"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_MOBILE_LENGTH = exports.ApiStatusValues = void 0;
var ApiStatusValues;
(function (ApiStatusValues) {
    ApiStatusValues[ApiStatusValues["idle"] = 0] = "idle";
    ApiStatusValues[ApiStatusValues["loading"] = 1] = "loading";
    ApiStatusValues[ApiStatusValues["success"] = 2] = "success";
    ApiStatusValues[ApiStatusValues["error"] = 3] = "error";
})(ApiStatusValues = exports.ApiStatusValues || (exports.ApiStatusValues = {}));
exports.DEFAULT_MOBILE_LENGTH = "10";
// export type RequestTypeListProps = {
//   data: RequestTypeItemProps[];
// };
