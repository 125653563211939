import {
  getNotificationCount,
  ImageConstant,
  useAppDispatch,
  useAppSelector,
  AppConstant,
  translate,
  getPreferredLanguagesList,
  COMMUNITY_ENABLED,
} from '@shalina-app/shared';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  HeaderProps,
  LangSelector,
  menuSequence,
  MobileMenuPage,
  NavBar,
  Notification,
  SearchBar,
  UserProfile,
} from '.';
import { ABOUT_US_PAGE_TABS, pageNames } from '../../app.constants';
import { URLConstant } from '../../routes';
import {
  retrieveData,
  retrieveProfilePic,
  STORAGE_CONSTANTS,
  useNavigatePageUtils,
} from '../../utils';
import { SignUpPopup } from '../Authentication';
import styles from './header.module.scss';

export const Header: React.FC<HeaderProps> = () => {
  const t = translate();
  const dispatch = useAppDispatch();
  const navigatePageUtils = useNavigatePageUtils();

  const isLoggedIn: boolean = retrieveData(STORAGE_CONSTANTS.IS_LOGGED_IN);
  const profilePic = retrieveProfilePic();

  const preferredLanguagesList = useAppSelector(getPreferredLanguagesList);

  const notificationCount =
    useAppSelector((state) => state?.notification?.totalCount) || 0;

  const location = useLocation();
  const pathName = location?.pathname;
  const pathNameSplit = pathName?.split('/');
  const { urls } = URLConstant;

  const [showSignUp, setShowSignUp] = useState(false);
  const [reload, setReload] = useState(false);
  const [isUnreadNotification, setIsUnreadNotification] = useState(false);

  const handleSignUpClose = () => {
    setShowSignUp(false);
  };

  const handleSignUpFlow = () => {
    setShowSignUp(true);
  };

  useEffect(() => {
    if (isLoggedIn) {
      setReload(true);
    }
  }, []);

  useEffect(() => {
    if (reload) {
      loadApi();
      setReload(false);
    }
  }, [reload]);

  const loadApi = async () => {
    const notificationCount = await dispatch(getNotificationCount()).unwrap();

    if (notificationCount > 0) {
      setIsUnreadNotification(true);
    } else {
      setIsUnreadNotification(false);
    }
  };

  const commonMenuItems = [
    {
      menuName: `${t(AppConstant.HEADER.HOME_MENU)}`,
      route: urls.homePage,
      isActive:
        pathName === urls.homePage ||
        pathName === urls.termsAndConditionsPage ||
        pathName === urls.searchPage
          ? true
          : false,
      state: null,
      allowGuestUser: true,
      enumId: 'home',
    },
    {
      menuName: `${t(AppConstant.HEADER.KNOWLEDGE_HUB_MENU)}`,
      route: urls.knowledgeHubPage,
      isActive:
        pathName === urls.knowledgeHubPage ||
        pathNameSplit?.includes(pageNames.KnowledgeHub)
          ? true
          : false,
      state: null,
      allowGuestUser: true,
      enumId: 'knowledgeHub',
    },
    {
      menuName: `${t(AppConstant.HEADER.SHALINA_TV_MENU)}`,
      route: urls.shalinaTv,
      isActive:
        pathName === urls.shalinaTv ||
        pathNameSplit?.includes(pageNames.ShalinaTv)
          ? true
          : false,
      state: null,
      allowGuestUser: true,
      enumId: 'shalinaTv',
    },
    {
      menuName: `${t(AppConstant.HEADER.LEARNING_CENTER_MENU)}`,
      route: urls.learningCenterPage,
      isActive:
        pathName === urls.learningCenterPage ||
        pathNameSplit?.includes(pageNames.LearningCenter)
          ? true
          : false,
      state: null,
      allowGuestUser: true,
      enumId: 'learningCenter',
    },
    {
      menuName: `${t(AppConstant.HOME_PAGE.MEDICAL_NEWS)}`,
      route: urls.rssListing,
      isActive:
        pathName === urls.rssListing || pathName === urls.rssDetail
          ? true
          : false,
      state: null,
      allowGuestUser: true,
      enumId: 'medicalNews',
    },
    {
      menuName: `${t(AppConstant.COMMON.SURVEY)}`,
      route: urls.surveyPage,
      isActive:
        pathName === urls.surveyPage ||
        pathNameSplit?.includes(pageNames.surveyPage)
          ? true
          : false,
      state: null,
      allowGuestUser: true,
      enumId: 'survey',
    },
    {
      menuName: `${t(AppConstant.HEADER.MEDICAL_CALCULATOR_MENU)}`,
      route: urls.medicalCalculator,
      isActive: pathName === urls.medicalCalculator ? true : false,
      state: null,
      allowGuestUser: true,
      enumId: 'medicalCalculator',
    },
    {
      menuName: `${t(AppConstant.HEADER.ABOUT_US_MENU)}`,
      route: urls.aboutUsPage,
      isActive: pathName === urls.aboutUsPage ? true : false,
      state: { tab: ABOUT_US_PAGE_TABS.WHO_WE_ARE },
      allowGuestUser: true,
      enumId: 'aboutUs',
    },
    {
      menuName: `${t(AppConstant.HEADER.HELP_SUPPORT_MENU)}`,
      route: urls.helpSupport,
      isActive: pathName === urls.helpSupport ? true : false,
      state: null,
      allowGuestUser: true,
      enumId: 'helpSupport',
    },
  ];

  const list = COMMUNITY_ENABLED
    ? [
        ...commonMenuItems,
        {
          menuName: `${t(AppConstant.HEADER.COMMUNITIES_MENU)}`,
          route: urls.commiunitiesPage,
          isActive: pathName === urls.commiunitiesPage ? true : false,
          state: null,
          allowGuestUser: true,
          enumId: 'community',
        },
      ]
    : commonMenuItems;

  const menuList = list?.sort((a: any, b: any) => {
    return (
      menuSequence?.indexOf(a?.enumId) -
      menuSequence?.indexOf(b?.enumId)
    );
  });

  const onLogoClick = () => {
    const navigateDetails = {
      isDynamicUrl: false,
    };
    navigatePageUtils(urls.homePage, navigateDetails);
  };

  return (
    <>
      <div className={styles.headerContainer}>
        <div className='cust-container'>
          <div className={styles.headerWrapper}>
            <div className={styles.logNavSec}>
              <img
                src={ImageConstant.SOURCE.ShalinaMedspaceLogo}
                alt='logo'
                className={styles.logo}
                onClick={onLogoClick}
              />
            </div>
            <div className={styles.searchUserSec}>
              <NavBar menuList={menuList as any} />
              <SearchBar />
              <LangSelector
                preferredLanguages={preferredLanguagesList}
                isFooter={false}
              />
              <Notification
                totalCount={notificationCount}
                isLoggedIn={isLoggedIn}
                handleSignUpFlow={handleSignUpFlow}
                isUnreadNotification={isUnreadNotification}
              />
              <UserProfile
                isLoggedIn={isLoggedIn}
                handleSignUpFlow={handleSignUpFlow}
                // refreshPage={refreshPage}
              />
            </div>
            <div className={styles.mobileMenu}>
              <MobileMenuPage isUnreadNotification={isUnreadNotification} />
            </div>
          </div>
        </div>
      </div>

      {/* mobile header start */}
      <div className={`${styles.headerContainer} ${styles.mobile}`}>
        <div className='cust-container'>
          <div className={styles.mobileMenu}>
            <MobileMenuPage isUnreadNotification={isUnreadNotification} />
          </div>
        </div>
      </div>
      {/* mobile header end */}

      <SignUpPopup open={showSignUp} handleClose={handleSignUpClose} />
    </>
  );
};
