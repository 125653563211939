"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditIcon = void 0;
const React = __importStar(require("react"));
const EditIcon = (props) => (React.createElement("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M14.916 4.81673C14.9165 4.72297 14.8986 4.63002 14.8631 4.54322C14.8277 4.45641 14.7754 4.37746 14.7094 4.31089L11.6884 1.29012C11.6218 1.22409 11.5429 1.17185 11.456 1.13639C11.3692 1.10094 11.2763 1.08297 11.1825 1.08351C11.0887 1.08297 10.9958 1.10094 10.909 1.13639C10.8222 1.17185 10.7432 1.22409 10.6766 1.29012L8.66026 3.30634L0.872652 11.0934C0.806617 11.16 0.754373 11.2389 0.718915 11.3257C0.683458 11.4125 0.665485 11.5055 0.666028 11.5992V14.62C0.666028 14.809 0.741094 14.9902 0.874714 15.1238C1.00833 15.2574 1.18956 15.3324 1.37853 15.3324H4.39952C4.49922 15.3379 4.59894 15.3223 4.69223 15.2867C4.78551 15.2511 4.87028 15.1963 4.94102 15.1258L12.6859 7.33879L14.7094 5.35819C14.7744 5.28914 14.8274 5.20967 14.8661 5.12308C14.873 5.06629 14.873 5.00888 14.8661 4.95209C14.8695 4.91893 14.8695 4.88552 14.8661 4.85235L14.916 4.81673ZM4.1074 13.9076H2.09103V11.8913L9.16614 4.81673L11.1825 6.83295L4.1074 13.9076ZM12.1871 5.8284L10.1708 3.81218L11.1825 2.80763L13.1918 4.81673L12.1871 5.8284Z", fill: "white", stroke: "white", "stroke-width": "0.2" })));
exports.EditIcon = EditIcon;
