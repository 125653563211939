import { getImageForContentType, useAppSelector } from "@shalina-app/shared";
import { ExploreCardContentType } from ".";
import { getImageSrc } from "../../../../utils";
import styles from "./exploreMore.module.scss";

export const ExploreMoreCard: React.FC<ExploreCardContentType> = ({
  name,
  id,
  handleContentTypeClick,
  unique_id,
  icon,
}) => {
  const contentImage: any = useAppSelector(getImageForContentType(unique_id));
  return (
    <div className={styles.exploreMoreWrap} onClick={handleContentTypeClick}>
      <div className={styles.exploreMoreCard}>
        <div className={styles.imgWrap}>
          {/* {contentImage ? ( */}
          <img src={icon} alt="content icon" />
          {/* ) : null} */}
        </div>
      </div>
      <p className={styles.exploreCardTitle}>{name}</p>
    </div>
  );
};
