import { ImageConstant } from '@shalina-app/shared';

import styles from '../calculator.module.scss';

export const DecisionTrees: React.FC<any> = ({ handleArrowClick, data }) => {
  const { DropDownArrowIcon } = ImageConstant.SVG_ICONS;
  return (
    <div className={styles.calcList}>
      {data?.map((item: any) => (
        <div
          className={styles.calcListItem}
          onClick={() => handleArrowClick(item?.field_url, item?.name)}
        >
          <span className={styles.calctext}>{item?.name}</span>
          <span className={styles.calcicon}>
            <DropDownArrowIcon />
          </span>
        </div>
      ))}
    </div>
  );
};
