import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import {
  AppConstant,
  DiscussionQuestionsProps,
  DISCUSSION_CATEGORY,
  fetchCommunityMembers,
  fetchDiscussionQuestions,
  getCommunityMembers,
  getDiscussionQuestionsList,
  getOnlyDate,
  getPostedBy,
  ImageConstant,
  isBeforeCurrentDateTime,
  SHOW_COMMENTS_INITIAL_COUNT,
  submitQuestion,
  translate,
  useAppDispatch,
  useAppSelector,
} from '@shalina-app/shared';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import styles from './CommunitiesContentCard.module.scss';
import { Speciality } from '../../../../components';
import { CommunitiesContentProps, DiscussionCommentsPopup } from '.';
import {
  convertToSpecialties,
  getImageSrc,
  retrieveData,
  STORAGE_CONSTANTS,
  UserInterface,
} from '../../../../utils';
import { CommentCard } from '../CommentCard/CommentCard.component';
import { ButtonLoader } from '../buttonLoader';

export const CommunitiesContentCard: React.FC<CommunitiesContentProps> = ({
  discussion,
  showCommunityInfo,
  isQuestionAnswersTab,
  isExpert,
  handleCommunityClick,
}) => {
  const t = translate();
  const dispatch = useAppDispatch();
  const { DateIcon, MessageIcon, SendIcon } = ImageConstant.SVG_ICONS;

  const userDetail: UserInterface | any = retrieveData(STORAGE_CONSTANTS.USER);

  const [viewAllOpen, setviewAllOpen] = useState(false);
  const [viewMoreDesc, setViewMoreDesc] = useState(false);
  const [discussionId, setDiscussionId] = useState('');
  const [questionText, setQuestionText] = useState('');
  const [questionPosted, setQuestionPosted] = useState(false);
  const [questionsData, setQuestionsData] = useState<any>([]);
  const [totalQuestionCount, setTotalQuestionCount] = useState(0);
  const [submitQueLoader, setSubmitQueLoader] = useState(false);
  const [submitQuestionDisabled, setSubmitQuestionDisabled] = useState(true);
  const [selectedTagId, setSelectedTagId] = useState<Array<string>>([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorHtmlValue, setEditorHtmlValue] = useState('');
  const [membersList, setMembersList] = useState<any>([]);

  const communityMembers = useAppSelector(getCommunityMembers);

  const onEditorStateChange = (editorState: any) => {
    const htmlString = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setEditorState(editorState);
    setEditorHtmlValue(htmlString);

    const content = editorState?.getCurrentContent();
    const plainText = content?.getPlainText(); // Get plain text content

    const lastWord = plainText?.split(/\s+/)?.pop(); // Get the last word
    
    if (lastWord?.startsWith('@')) {
      const payload = {
        communityId: discussion?.community_id,
      };
      dispatch(fetchCommunityMembers(payload));
    }

    const entityMap = convertToRaw(content)?.entityMap;
    const mentions: any = [];

    Object?.values(entityMap)?.forEach((entity) => {
      if (entity?.type === 'MENTION') {
        mentions?.push(entity?.data);
      }
    });
    const selectedTags = mentions
      ?.map((mention: any) => mention?.url)
      .join(', ');
    setSelectedTagId(selectedTags);

    if (htmlString) {
      setSubmitQuestionDisabled(false);
    } else {
      setSubmitQuestionDisabled(true);
    }
  };

  useEffect(() => {
    if (communityMembers && communityMembers?.length > 0) {
      const suggestions = communityMembers?.map((item) => ({
        text: `${item?.member_first_name} ${item?.member_last_name}`, // Combine first and last names
        value: `${item?.member_first_name} ${item?.member_last_name}`, // Use member_id as the value
        url: `${item?.member_id}`
      }));
      const filteredData = suggestions?.filter(
        (item: any) => item?.url !== userDetail?.uid
      );

      setMembersList(filteredData);
    }
  }, [communityMembers]);
  
  const handleViewAllOpen = () => {
    setviewAllOpen(true);
  };

  const handleViewAllClose = () => {
    loadApi();
    setviewAllOpen(false);
  };

  const loadApi = async () => {
    const response = await dispatch(
      fetchDiscussionQuestions({
        skip: 0,
        take: 2,
        discussion_id: discussionId || discussion?.discussion_id,
      })
    ).unwrap();
    if (response && response?.count > 0) {
      setTotalQuestionCount(response?.count);
    }
    setQuestionPosted(true);
  };

  const handleSeeMore = () => {
    setViewMoreDesc(!viewMoreDesc);
  };

  useEffect(() => {
    if (discussion?.discussion_id) {
      setDiscussionId(discussion?.discussion_id);
    }
  }, [discussion]);

  // const handleRevokeQuestion = async (questionId: string) => {
  //   const req = {
  //     questionId: questionId,
  //     status: "revoke",
  //   };
  //   await dispatch(revokeQuestion(req));
  //   await dispatch(
  //     fetchDiscussionQuestions({
  //       skip: 0,
  //       take: 2,
  //       discussion_id: discussionId || discussion?.discussion_id,
  //     })
  //   );
  //   setQuestionPosted(true);
  // };

  const handleQuestion = (e: any) => {
    const text = e?.target?.value?.trim();
    if (text) {
      setSubmitQuestionDisabled(false);
    } else {
      setSubmitQuestionDisabled(true);
    }
    setQuestionText(e?.target?.value);
  };

  const handleMainApiCall = async () => {
    const response = await dispatch(
      fetchDiscussionQuestions({
        skip: 0,
        take: 2,
        discussion_id: discussionId || discussion?.discussion_id,
      })
    ).unwrap();
    if (response && response?.count > 0) {
      setTotalQuestionCount(response?.count);
    }
    setQuestionPosted(true);
  };

  const handleSubmitQuestion = async () => {
    // const text = questionText?.trim();
    const text = editorHtmlValue?.trim();
    const taggedId = selectedTagId?.toString();
    if (text) {
      setSubmitQueLoader(true);
      const payload = {
        discussion_id: discussionId || discussion?.discussion_id,
        question: text,
        taggedId: taggedId,
      };
      const response = await dispatch(submitQuestion(payload)).unwrap();

      if (
        response &&
        response?.statusCode === 200 &&
        response?.status === 'SUCCESS'
      ) {
        const res = await dispatch(
          fetchDiscussionQuestions({
            skip: 0,
            take: 2,
            discussion_id: discussionId,
          })
        ).unwrap();

        if (res && res?.count > 0) {
          setTotalQuestionCount(res?.count);
          setSubmitQuestionDisabled(true);
        }
        setQuestionText('');
        setQuestionPosted(true);
        setSubmitQueLoader(false);
        setEditorState(EditorState.createEmpty());
      } else {
        setQuestionPosted(false);
        setSubmitQueLoader(false);
        setEditorState(EditorState.createEmpty());
      }
    } else {
      setQuestionPosted(false);
      setSubmitQueLoader(false);
    }
  };

  const questionsList = useAppSelector(getDiscussionQuestionsList);

  useEffect(() => {
    if (questionPosted) {
      setQuestionsData(questionsList);
      setQuestionPosted(false);
    }
  }, [questionPosted]);

  useEffect(() => {
    if (discussion) {
      setQuestionsData(discussion?.questions?.data);
      setTotalQuestionCount(discussion?.questions?.count);
    }
  }, [discussion]);

  const shortDesc = discussion?.description
    ? discussion?.description?.substring(0, 200)
    : '';
  const description = discussion?.description ? discussion?.description : '';
  const showSeeMore = description?.length > shortDesc?.length ? true : false;

  const specialityArr = convertToSpecialties(discussion?.field_interests || '');

  const moreCount =
    totalQuestionCount > SHOW_COMMENTS_INITIAL_COUNT
      ? totalQuestionCount - SHOW_COMMENTS_INITIAL_COUNT
      : totalQuestionCount;

  const isQAEvent =
    discussion?.field_category === DISCUSSION_CATEGORY?.QA || false;
  const isTopicEvent =
    discussion?.field_category === DISCUSSION_CATEGORY?.TOPIC || false;

  const isQuestionSubmissionDateEnd = isBeforeCurrentDateTime(
    discussion?.field_question_submission_closed
  );

  const isEventStarted = isBeforeCurrentDateTime(discussion?.field_from_date);
  const isEventEnded = isBeforeCurrentDateTime(discussion?.field_to_date);

  return (
    <>
      <div className={styles.cardWrapper}>
        <div className={styles.cardTop}>
          <div className={styles.titleAndIntrest}>
            {showCommunityInfo ? (
              <>
                <div className={styles.cardIconWrapper}>
                  <img
                    className={styles.cardIcon}
                    src={
                      getImageSrc(discussion?.field_community_icon) ||
                      ImageConstant.SOURCE.communityIcon
                    }
                    alt='img'
                    onError={(e) =>
                      ((e.target as HTMLInputElement).src =
                        ImageConstant.SOURCE.communityIcon)
                    }
                  />
                </div>
                <div className={styles.detail}>
                  <h4
                    className={styles.title}
                    onClick={() =>
                      handleCommunityClick(discussion?.community_id)
                    }
                  >
                    {discussion?.community_title}
                  </h4>
                  <div className={styles.speciality}>
                    <Speciality speciality={specialityArr} showAll={false} />
                  </div>
                </div>
              </>
            ) : (
              <p className={styles.descTitle}>{discussion?.title}</p>
            )}
          </div>

          <div className={styles.dateAndLabel}>
            <span className={styles.postDate}>
              <strong>{t(AppConstant.COMMUNITY.POSTED)}</strong>
              {getPostedBy(discussion?.created_date)}
            </span>
            <span className={styles.cardLabel}>
              {discussion?.field_category_name}
            </span>
          </div>
        </div>
        <div className={styles.cardDesc}>
          <div className={styles.descText}>
            {showCommunityInfo && (
              <p className={styles.descTitle}>{discussion?.title}</p>
            )}
            {!viewMoreDesc ? (
              <>
                {parse(shortDesc)}
                {showSeeMore && (
                  <strong onClick={handleSeeMore} className={styles.moreLessText}>
                    {t(AppConstant.COMMUNITY.SEE_MORE_TEXT)}...
                  </strong>
                )}
              </>
            ) : (
              <>
                {parse(description)}
                <strong onClick={handleSeeMore} className={styles.moreLessText}>
                  {t(AppConstant.COMMUNITY.SEE_LESS_TEXT)}...
                </strong>
              </>
            )}
          </div>

          {discussion?.field_image && (
            <div className={styles.cardImg}>
              <img
                src={getImageSrc(discussion?.field_image)}
                alt='img'
                onError={(e) =>
                  ((e.target as HTMLInputElement).src =
                    ImageConstant.SOURCE.communityLargeIcon)
                }
              />
            </div>
          )}
        </div>
        {discussion?.field_category === DISCUSSION_CATEGORY?.QA && (
          <div className={styles.dateWrapper}>
            <div className={styles.date}>
              <DateIcon />
              <strong>{t(AppConstant.COMMUNITY.EVENT_TEXT)}</strong>
              <span>{getOnlyDate(discussion?.field_from_date)}</span>

              <strong>{t(AppConstant.COMMUNITY.EVENT_ENDS)}</strong>
              <span>{getOnlyDate(discussion?.field_to_date)}</span>
            </div>
            <div className={styles.date}>
              <MessageIcon />
              <strong>
                {t(AppConstant.COMMUNITY.QUESTION_SUBMISSION_CLOSES_ON_TEXT)}
              </strong>
              <span>
                {getOnlyDate(discussion?.field_question_submission_closed)}
              </span>
            </div>
          </div>
        )}
        {discussion?.field_category === DISCUSSION_CATEGORY?.QA && isExpert ? (
          <></>
        ) : (
          !isQuestionSubmissionDateEnd && (
            <div className={styles.enterPostWrapper}>
              <img
                className={styles.userImg}
                src={
                  userDetail?.userPicture
                    ? getImageSrc(userDetail?.userPicture)
                    : ImageConstant.SOURCE.defaultUserPic
                }
                alt='img'
                onError={(e) =>
                  ((e.target as HTMLInputElement).src =
                    ImageConstant.SOURCE.defaultUserPic)
                }
              />
              <div className={styles.postInput}>
                <Editor
                  toolbarHidden
                  wrapperClassName='demo-wrapper'
                  placeholder={
                    discussion?.field_category === DISCUSSION_CATEGORY?.TOPIC
                      ? t(AppConstant.COMMUNITY.TOPIC_COMMENT_PLACEHOLDER_TEXT)
                      : t(AppConstant.COMMUNITY.QA_COMMENT_PLACEHOLDER_TEXT)
                  }
                  editorState={editorState}
                  onEditorStateChange={onEditorStateChange}
                  // editorClassName='demo-editor'
                  mention={{
                    separator: ' ',
                    trigger: '@',
                    suggestions: membersList,
                  }}
                />

                {/* <textarea
                  placeholder={
                    discussion?.field_category === DISCUSSION_CATEGORY?.TOPIC
                      ? t(AppConstant.COMMUNITY.TOPIC_COMMENT_PLACEHOLDER_TEXT)
                      : t(AppConstant.COMMUNITY.QA_COMMENT_PLACEHOLDER_TEXT)
                  }
                  value={questionText}
                  onChange={handleQuestion}
                  rows={5}
                ></textarea> */}

                <button
                  className={`${styles.submitCta} ${
                    submitQuestionDisabled && styles.disabled
                  }`}
                  disabled={submitQuestionDisabled}
                  onClick={handleSubmitQuestion}
                >
                  <SendIcon />
                  <ButtonLoader loader={submitQueLoader} />
                </button>
              </div>
            </div>
          )
        )}
        <div className={styles.commentList}>
          {questionsData?.map(
            (comment: DiscussionQuestionsProps, index: number) =>
              index < SHOW_COMMENTS_INITIAL_COUNT && (
                <CommentCard
                  discussionId={discussion?.discussion_id}
                  comment={comment}
                  isQAEvent={isQAEvent}
                  isTopicEvent={isTopicEvent}
                  isExpert={isExpert}
                  handleMainApiCall={handleMainApiCall}
                  isEventStarted={isEventStarted}
                  isEventEnded={isEventEnded}
                />
              )
          )}
        </div>
        {totalQuestionCount > SHOW_COMMENTS_INITIAL_COUNT && (
          <div className={styles.viewAllComment}>
            <button onClick={handleViewAllOpen}>
              {discussion?.field_category === DISCUSSION_CATEGORY?.TOPIC
                ? `${t(
                    AppConstant.COMMUNITY.VIEW_MORE_COMMENTS_CTA
                  )} (${moreCount})`
                : `${t(
                    AppConstant.COMMUNITY.VIEW_MORE_COMMENTS_CTA
                  )} (${moreCount})`}
            </button>
          </div>
        )}
      </div>

      <Dialog
        open={viewAllOpen}
        onClose={handleViewAllClose}
        className={styles.viewAllModal}
      >
        <DialogContent>
          <DiscussionCommentsPopup
            discussion={discussion}
            handleViewAllClose={handleViewAllClose}
            showSeeMore={showSeeMore}
            shortDesc={shortDesc}
            discussionId={discussionId}
            isQuestionAnswersTab={isQuestionAnswersTab}
            isQAEvent={isQAEvent}
            isTopicEvent={isTopicEvent}
            isExpert={isExpert}
            handleMainApiCall={handleMainApiCall}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
