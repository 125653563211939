import React, { useState } from 'react';
import { ImageConstant } from '@shalina-app/shared';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { ExpertCardProps } from '.';
import { getImageSrc } from '../../../../utils';
import styles from './expertSection.module.scss';

export const ExpertCard: React.FC<ExpertCardProps> = ({
  name,
  qualification,
  speciality,
  country,
  userImg,
  designation,
  description
}) => {
  let role = '';
  const specialityArr = speciality ? speciality?.split(',') : [];
  if (qualification && speciality) {
    role = `${qualification} | ${speciality}`;
  } else if (qualification) {
    role = `${qualification}`;
  } else if (speciality) {
    role = `${speciality}`;
  }

  const [showAllSpecialities, setShowAllSpecialities] = useState(false);
  const [expertPopup, setExpertPopup] = useState(false);

  const displayedSpecialities = showAllSpecialities
    ? specialityArr
    : specialityArr?.slice(0, 1);

  const handleExpertOpen = () => {
    setExpertPopup(true);
  };
  const handleExpertPopupClose = () => {
    setExpertPopup(false);
  };

  return (
    <>
      <div className={styles.experCardWrap}>
        <div className={styles.experCard} onClick={handleExpertOpen}>
          <img
            src={userImg ? getImageSrc(userImg) : ''}
            alt='Expert img'
            onError={(e) =>
              ((e.target as HTMLInputElement).src =
                ImageConstant.SOURCE.defaultUserPic)
            }
          />
          <div className={styles.details}>
            <p className={styles.expertName}>{name}</p>
            <div className={styles.expertRole}>
              {qualification && (
                <span
                  className={`${styles.qualification} ${
                    displayedSpecialities?.length > 0 && styles.isBorder
                  }`}
                  title={qualification}
                >
                  {qualification}
                </span>
              )}
              {displayedSpecialities?.map((item: string, index: number) => (
                <div key={index} className={styles.specialityItem}>
                  <span title={item}>{item}</span>
                </div>
              ))}
              {specialityArr?.length - 1 > 0 && !showAllSpecialities && (
                <div
                  className={styles.moreSpeciality}
                  onClick={() => setShowAllSpecialities(true)}
                >
                  <span className={styles.count}>{`+${
                    specialityArr?.length - 1
                  }`}</span>
                  <div className={styles.specialityTooltipWrapper}>
                    <div className={styles.specialityTooltip}>
                      {specialityArr
                        ?.slice(1)
                        .map((item: string, index: number) => (
                          <span className={`${styles.tooltiptext}`} key={index}>
                            {item}
                          </span>
                        ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* <p className={styles.geoLocation}>Africa Care Medical Center</p> */}
            <div className={styles.location}>
              {country && (
                <>
                  <svg
                    width='14'
                    height='17'
                    viewBox='0 0 14 17'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M7 9.25C8.24264 9.25 9.25 8.24264 9.25 7C9.25 5.75736 8.24264 4.75 7 4.75C5.75736 4.75 4.75 5.75736 4.75 7C4.75 8.24264 5.75736 9.25 7 9.25Z'
                      stroke='#002E5B'
                      stroke-width='1.5'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                    <path
                      d='M7 1C5.4087 1 3.88258 1.63214 2.75736 2.75736C1.63214 3.88258 1 5.4087 1 7C1 8.419 1.3015 9.3475 2.125 10.375L7 16L11.875 10.375C12.6985 9.3475 13 8.419 13 7C13 5.4087 12.3679 3.88258 11.2426 2.75736C10.1174 1.63214 8.5913 1 7 1Z'
                      stroke='#002E5B'
                      stroke-width='1.5'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </svg>
                  <span>{country}</span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={expertPopup}
        onClose={handleExpertPopupClose}
        className={styles.expertDialog}
      >
        <DialogContent>
          <div className={styles.expertWrapper}>
            <button
              className={styles.closeCta}
              onClick={handleExpertPopupClose}
            >
              &times;
            </button>
            <div className={styles.expertContent}>
              <img
                src={userImg ? getImageSrc(userImg) : ''}
                alt='Expert img'
                className={styles.expertImg}
                onError={(e) =>
                  ((e.target as HTMLInputElement).src =
                    ImageConstant.SOURCE.defaultUserPic)
                }
              />
              <div className={styles.dialogName}>{name}</div>
              {qualification && (
                <div className={styles.dialogQualification}>
                  {qualification}
                </div>
              )}
              <div className={styles.dialogRole}>{designation ?? ''}</div>
              <div className={styles.dialogLocation}>
                {country && (
                  <>
                    <svg
                      width='14'
                      height='17'
                      viewBox='0 0 14 17'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7 9.25C8.24264 9.25 9.25 8.24264 9.25 7C9.25 5.75736 8.24264 4.75 7 4.75C5.75736 4.75 4.75 5.75736 4.75 7C4.75 8.24264 5.75736 9.25 7 9.25Z'
                        stroke='#002E5B'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                      <path
                        d='M7 1C5.4087 1 3.88258 1.63214 2.75736 2.75736C1.63214 3.88258 1 5.4087 1 7C1 8.419 1.3015 9.3475 2.125 10.375L7 16L11.875 10.375C12.6985 9.3475 13 8.419 13 7C13 5.4087 12.3679 3.88258 11.2426 2.75736C10.1174 1.63214 8.5913 1 7 1Z'
                        stroke='#002E5B'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                    {country}
                  </>
                )}
              </div>
            </div>
            <div className={styles.dialogMsg}>{description ?? ''}</div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
