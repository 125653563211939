import React from 'react';
import {
  useAppSelector,
  getContentDetails,
  getFilterCategoriesOptionsValues,
  AppConstant,
  translate
} from '@shalina-app/shared';
import { useLocation } from 'react-router-dom';

import {
  transformedStrings,
  translatedKeys,
  useNavigatePageUtils
} from '../../utils';
import { BreadCrumbProps } from './breadcrumbs.interface';
import styles from './breadcrumbs.module.scss';

export const BreadCrumb: React.FC<BreadCrumbProps> = ({
  hasDark,
  pageName,
  rssFeed,
  titleProps,
  isSurvey,
  surveyTitle
}) => {
  const t = translate();
  const location = useLocation();
  const navigatePage = useNavigatePageUtils();

  const contentDetailsResponse = useAppSelector(getContentDetails);
  const masterContentTypeData = useAppSelector(
    getFilterCategoriesOptionsValues
  );

  const handleRoutes = (route: string) => {
    const navigateDetails = {
      isDynamicUrl: false
    };
    navigatePage(route, navigateDetails);
  };

  const pathnames = location?.pathname?.split('/')?.filter((x) => x);

  const getTitle = (text: any, pathnames: any, index: number) => {
    let title = '';
    if (!rssFeed && !isSurvey) {
      if (pathnames?.length >= 3) {
        if (index === 2) {
          title = titleProps as string;
        } else if (index === 1) {
          const contentTypeName: any = masterContentTypeData?.find(
            (item: any) => item?.value === text
          )?.label;

          title = `${contentTypeName}-${t(AppConstant.COMMON.LISTING_TEXT)}`;
        } else {
          title = text;
        }
      } else if (pathnames?.length === 2) {
        if (index === pathnames?.length - 1) {
          const contentTypeName: any = masterContentTypeData?.find(
            (item: any) => item?.value === text
          )?.label;

          title = `${contentTypeName}-${t(AppConstant.COMMON.LISTING_TEXT)}`;
        } else {
          title = text;
        }
      } else {
        title = text
          ? `${t(translatedKeys(text))}-${t(AppConstant.COMMON.LISTING_TEXT)}`
          : text;
      }
    } else if (isSurvey) {
      if (pathnames?.length >= 2) {
        if (index === 1) {
          title = surveyTitle || '';
        } else {          
          title =  text && text?.includes(`survey`)
          ? `${t(AppConstant.COMMON.SURVEY)}`
          : text
        }
      }
    } else {
      title =
        text && text.includes(`medical-news-feeds-listing`)
          ? `${t(AppConstant.COMMON.MEDICAL_NEWS_LISTING)}`
          : text && text.includes(`medical-news-feeds-detail`)
          ? `${t(AppConstant.COMMON.MEDICAL_NEWS_DETAILS)}`
          : text;
    }

    return title;
  };

  return (
    <nav aria-label='breadcrumb' className={styles.breadWrapper}>
      <ol className={`${styles.breadcrumb} ${hasDark && styles.hasDarkBg}`}>
        {pathnames?.map((value, index) => {
          const last = index === pathnames?.length - 1;
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;

          const title = getTitle(value, pathnames, index);
          return (
            <li key={index}>
              {last ? (
                <span className={styles.breadcrumbCurrent}>
                  {t(transformedStrings(title?.trim()))}
                </span>
              ) : (
                <>
                  <a
                    onClick={() => handleRoutes(to)}
                    style={{ cursor: 'pointer', borderBottom: '1px' }}
                  >
                    {t(transformedStrings(title?.trim()))}
                  </a>
                  {/* <span> &gt; </span> */}
                  <span>
                    <svg
                      width='7'
                      height='11'
                      viewBox='0 0 7 11'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M1 1L6 5.5L0.999999 10'
                        stroke='#002E5B'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                  </span>
                </>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default BreadCrumb;
