import { useEffect, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import {
  AppConstant,
  changeLanguage,
  isTranslationSupported,
  updateLanguageForWeb,
  updateUserDetails,
  useAppDispatch,
  useAppSelector
} from '@shalina-app/shared';

import { LanguagePreferencePagePropsType } from '.';
import { CircularLoader } from '../../../../components';
import {
  getImageSrc,
  getTranslationFormat,
  retrieveData,
  STORAGE_CONSTANTS,
  storeAccessToken,
  storeData,
  UserInterface
} from '../../../../utils';
import styles from './languagePreference.module.scss';

export const LanguagePreferencePage: React.FC<
  LanguagePreferencePagePropsType
> = ({ languageList }) => {
  const dispatch = useAppDispatch();
  const storedUser: UserInterface | any = retrieveData(STORAGE_CONSTANTS.USER);

  const updatedUser = useAppSelector((state) => state?.user?.user);
  const userUpdateLoading = useAppSelector((state) => state?.user?.isLoading);

  const [language, setLanguage] = useState('');
  const [saveEnable, setSaveEnable] = useState(false);

  useEffect(() => {
    if (storedUser?.language?.field_unique_code) {
      setLanguage(storedUser?.language?.field_unique_code);
    }
  }, [storedUser]);

  const handleLanguageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLanguage(event?.target?.value);

    if (event?.target?.value !== storedUser?.language?.field_unique_code) {
      setSaveEnable(true);
    } else {
      setSaveEnable(false);
    }
  };

  const storeLanguage = async (lang: any) => {
    await dispatch(updateLanguageForWeb(lang));
  };

  const handleSave = async () => {
    const code: any = languageList?.find(
      (item: any) => item?.field_unique_code === language
    );
    const req = {
      language: code?.tid
    };
    const response = await dispatch(updateUserDetails(req)).unwrap();

    if (
      response &&
      response?.status === 'SUCCESS' &&
      response?.statusCode === 200
    ) {
      const filterData = languageList?.find(
        (item: any) => item?.field_unique_code === language
      );

      if (response?.data) {
        if (response?.data?.token) {
          storeAccessToken(response?.data?.token);
        }
        storeData(STORAGE_CONSTANTS.USER, response?.data);
        setLanguage(language);
        storeLanguage(filterData?.field_unique_code);
        changeLanguage(filterData?.field_unique_code);
        storeData(STORAGE_CONSTANTS.SELECTED_LANGUAGE, filterData);
        setSaveEnable(false);
      }
    }
    // await setCheckStatus(true);
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.langList}>
          <h4 className={styles.title}>
            {userUpdateLoading && <CircularLoader size={50} thickness={4} />}
            {getTranslationFormat(AppConstant.PROFILE.SELECTED_LANGUAGE_TEXT)}
          </h4>
          <div className={styles.langItem}>
            <RadioGroup
              aria-labelledby='demo-radio-buttons-group-label'
              // defaultValue={selectedLanguage}
              name='radio-buttons-group'
              value={language}
              onChange={handleLanguageChange}
            >
              {languageList &&
                languageList?.map((lng) => (
                  <label className={styles.langLabel}>
                    <FormControlLabel
                      value={lng?.field_unique_code}
                      control={<Radio />}
                      label={lng?.name}
                      disabled={!isTranslationSupported(lng?.field_unique_code)}
                    />
                    <img
                      className={`${styles.langFlag} ${
                        !isTranslationSupported(lng?.field_unique_code) &&
                        styles.isDisabled
                      }`}
                      src={getImageSrc(lng?.field_language_image)}
                      alt='Language_Image'
                    />
                  </label>
                ))}
            </RadioGroup>
          </div>
        </div>
        <div className={styles.saveCta}>
          <button disabled={saveEnable ? false : true} onClick={handleSave}>
            {getTranslationFormat(AppConstant.PROFILE.SAVE_CTA)}
          </button>
        </div>
      </div>
    </>
  );
};
