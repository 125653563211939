import {
  CONTENT_TYPE_ID,
  convertUTCDate,
  convertUTCTime,
  getOnlyDate,
  getOnlyTime,
  translate,
  viewDurationInHours,
} from '@shalina-app/shared';

import { ContentSliderPropsType } from '.';
import { Card, CardSlider } from '../..';
import { convertToSpecialties, getImageSrc } from '../../../utils';
import styles from './contentSlider.module.scss';

export const ContentSlider: React.FC<ContentSliderPropsType> = ({
  data,
  contentHeading,
  slidesToShow,
  isViewAllExist,
  pageName,
  pageRoute,
  handleViewAllClick,
  loading,
  ebookClassName,
  staticImagePath,
}) => {
  const showControl = data && data?.length > slidesToShow ? true : false;
  const length: number = data?.length;
  const t = translate();

  return (
    <>
      <CardSlider
        slidesToShow={slidesToShow}
        sliderHeading={contentHeading}
        isViewAllExist={isViewAllExist ? showControl : false}
        showControl={showControl}
        handleViewAllButtonClick={handleViewAllClick}
        isDataExist={data && data.length > 0 ? true : false}
        ebookClassName={ebookClassName}
        dataLength={length}
        loading={loading}
      >
        {data &&
          data.length > 0 &&
          data.map((item: any, index: number) => (
            <div className={styles.slideItem} key={index}>
              <Card
                id={item?.nid}
                contentType={item?.field_select_article_type || ''}
                contentTypeId={item?.field_select_article_type_id || ''}
                title={item?.title || ''}
                speciality={convertToSpecialties(
                  item?.field_area_of_interest || ''
                )}
                date={
                  item?.field_unique_code === CONTENT_TYPE_ID?.WEBINARS
                    ? convertUTCDate(item?.field_starts_on)
                    : getOnlyDate(item?.field_authored_on)
                }
                time={
                  item?.field_unique_code === CONTENT_TYPE_ID?.WEBINARS
                    ? convertUTCTime(item?.field_starts_on)
                    : getOnlyTime(item?.field_authored_on)
                }
                publishedName={item?.field_authored_by || ''}
                viewsCount={item?.view_count || ''}
                duration={viewDurationInHours(item?.field_duration || '')}
                thumbnailImage={getImageSrc(item?.field_thumbnail_image)}
                pageName={pageName}
                pageRoute={pageRoute}
                isBookmarked={item?.is_bookmarked}
                mostViewed={item?.most_viewed}
                uniqueContentTypeId={item?.field_unique_code}
                category={item?.type}
                tags={item?.field_tags}
                eventId={item?.event_id}
              />
            </div>
          ))}
      </CardSlider>
    </>
  );
};
