import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import parse from 'html-react-parser';
import {
  ImageConstant,
  handleRecognitionPopup,
  useAppDispatch,
  useAppSelector,
  recognitionHideVisibility,
  AppConstant,
  translate,
} from '@shalina-app/shared';
import { getImageSrc } from '../../utils';

import styles from './modal.module.scss';

export const RecognitionPopup: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const t = translate();

  const showRecognitionPopup = useAppSelector(
    (state: any) => state?.common?.showRecognitionPopup
  );
  const recognitionData = useAppSelector(
    (state: any) => state?.common?.recognitionData
  );

  const handleRecognitionModalClose = () => {
    const obj = {
      showRecognitionPopup: false,
      recognitionData: {},
    };
    dispatch(handleRecognitionPopup(obj));
    dispatch(
      recognitionHideVisibility({
        recognitionId: recognitionData?.tid,
      })
    );
  };

  return (
    <Dialog
      open={showRecognitionPopup}
      // onClose={handleClose} // removed to restrict outside click
      className={styles.recognitionModal}
      disableEscapeKeyDown={true}
    >
      <DialogContent>
        <div className={styles.recognition}>
          <span
            className={styles.closeCTA}
            onClick={handleRecognitionModalClose}
          >
            &times;
          </span>
          <img
            src={getImageSrc(recognitionData?.field_icon)}
            alt='img'
            className={styles.badgeImg}
          />
          <div className={styles.msg}>
            <h4>{`${t(AppConstant.COMMON.CONGRATULATIONS_TEXT)} ${recognitionData?.name}!`}</h4>
            <p>
              {recognitionData?.field_description_gamification
                ? parse(recognitionData?.field_description_gamification)
                : ''}
            </p>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
