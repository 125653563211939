"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetSkipCountForMyCommunity = exports.updateSkipCountForMyCommunity = exports.updateDiscussionList = exports.updateMembersList = exports.updateResourcesList = exports.getExploreTotalCount = exports.getCombinedExploreCommunityCount = exports.getDiscussionQuestionsList = exports.getCommunityDiscussionAnswerTabList = exports.getCommunityDetailDiscussionList = exports.getCommunityDiscussionList = exports.getCommunityReceivedRequests = exports.getCommunityRequests = exports.getCommunityRequestsOnLoad = exports.getCommunityMembersTotalCount = exports.getCommunityMembersForMentions = exports.getCommunityMembers = exports.getCommunityResourcesTotalCount = exports.getCommunityResources = exports.getCommunityDetail = exports.getCommunityGuidelines = exports.getExploreCommunitiesListCount = exports.getExploreCommunitiesListForCommunity = exports.getExploreCommunitiesListForHome = exports.getExploreCommunitiesList = exports.getRecommendedCommunitiesListCount = exports.getRecommendedCommunitiesList = exports.getMyCommunitiesListCount = exports.getMyCommunitiesList = exports.fetchCombinedExploreCommunity = exports.revokeQuestion = exports.fetchDiscussionQuestions = exports.updateCommunity = exports.submitQuestion = exports.submitAnswer = exports.leaveCommunity = exports.fetchCommunityDetailDiscussions = exports.fetchCommunityDiscussionsAnswerTab = exports.fetchCommunityDiscussions = exports.fetchCommunityDetail = exports.fetchCommunityMembers = exports.fetchCommunityResources = exports.fetchCommunityReceivedRequests = exports.fetchCommunityRequests = exports.fetchDiscussionTypes = exports.fetchCommunityGuideline = exports.fetchCommunityList = exports.fetchCommunityLandingContent = exports.fetchMyCommunityList = void 0;
const api_1 = require("../../../api");
const toolkit_1 = require("@reduxjs/toolkit");
const constants_1 = require("../../constants");
const utils_1 = require("../../utils");
const utils_2 = require("../../../utils");
const reducer_constants_1 = require("../reducer.constants");
const initialState = {
    communityLandingContent: {
        isCommunityLandingContentLoading: false,
        communityLandingContent: {},
    },
    communitiesList: {
        isCommunitiesListLoading: false,
        isCombinedExploreListLoading: false,
        exploreCommunitiesList: {
            data: [],
            message: "",
            status: "",
            statusCode: "",
        },
        recommendedCommunitiesList: null,
        myCommunitiesList: null,
        combinedExploreCommunityList: {
            data: [],
            message: "",
            status: "",
            statusCode: ""
        }
    },
    communitiesListForListing: {
        isCommunitiesListLoading: false,
        communityList: null,
    },
    communityGuidelines: {
        communityGuidelinesList: [],
    },
    communityResources: {
        communityResourcesList: {
            message: "",
            status: "",
            statusCode: 0,
            count: 0,
            data: [],
        },
        communityResourcesLoading: false,
    },
    communityMembers: {
        communityMembersList: {
            message: "",
            status: "",
            statusCode: 0,
            count: 0,
            data: [],
        },
        communityMembersLoading: false,
    },
    discussionTypes: {
        discussionTypesList: {
            message: "",
            status: "",
            statusCode: 0,
            data: [],
        },
        discussionTypesListLoading: false,
    },
    communityRequest: {
        communityRequestList: {
            message: "",
            status: "",
            statusCode: 0,
            data: [],
        },
        communityReceivedRequestList: {
            message: "",
            status: "",
            statusCode: 0,
            data: [],
        },
        isRequestListLoading: false,
    },
    communityDetail: {
        communityDetail: {
            message: "",
            status: "",
            statusCode: 0,
            data: {},
        },
        communityDetailLoading: false,
    },
    communityDiscussions: {
        communityDiscussionsList: {
            message: "",
            status: "",
            statusCode: 0,
            data: [],
        },
        communityDetailDiscussionsList: {
            message: "",
            status: "",
            statusCode: 0,
            data: [],
        },
        communityDiscussionsAnswerTabList: {
            message: "",
            status: "",
            statusCode: 0,
            data: [],
        },
        communityDiscussionsLoading: false,
    },
    updateCommunity: {
        isCommunityUpdating: false,
        updateCommunityResponse: {
            message: "",
            status: "",
            statusCode: 0,
            data: {},
        },
    },
    leaveCommunity: {
        isLeaveCommunityUpdating: false,
        leaveCommunityResponse: {
            message: "",
            status: "",
            statusCode: 0,
            data: {},
        },
    },
    discussionQuestions: {
        discussionQuestionsLoading: false,
        discussionQuestions: {
            count: 0,
            message: "",
            status: "",
            statusCode: 0,
            data: [],
        },
    },
    revokeQuestion: {
        revokeQuestionLoading: false,
        revokeQuestion: null,
    },
    skipCountForMyCommunity: 0
};
const communitySlice = (0, toolkit_1.createSlice)({
    name: constants_1.COMMUNITY,
    initialState,
    reducers: {
        updateResourcesList: (state, action) => {
            state.communityResources.communityResourcesList.data = action.payload;
        },
        updateMembersList: (state, action) => {
            state.communityMembers.communityMembersList.data = action.payload;
        },
        updateDiscussionList: (state, action) => {
            state.communityDiscussions.communityDiscussionsList.data = action.payload;
        },
        updateSkipCountForMyCommunity: (state, action) => {
            state.skipCountForMyCommunity = action.payload;
        },
        resetSkipCountForMyCommunity: (state) => {
            state.skipCountForMyCommunity = 0;
        }
    },
    extraReducers(builder) {
        /**
             * List API
            //  */
        builder.addCase(exports.fetchMyCommunityList.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.communitiesList.isCommunitiesListLoading = true;
        });
        builder.addCase(exports.fetchMyCommunityList.fulfilled, (state, action) => {
            var _a, _b, _c, _d, _e, _f;
            // Add logic if any when API state is fulfilled or data is received from API
            state.communitiesList.isCommunitiesListLoading = false;
            if (((_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.category) ===
                utils_2.COMMUNITIES_API_CATEGORY_TYPES.MY_COMMUNITY) {
                state.communitiesList.myCommunitiesList = (_b = action === null || action === void 0 ? void 0 : action.payload) === null || _b === void 0 ? void 0 : _b.data;
            }
            else if (((_c = action === null || action === void 0 ? void 0 : action.payload) === null || _c === void 0 ? void 0 : _c.category) ===
                utils_2.COMMUNITIES_API_CATEGORY_TYPES.EXPLORE_COMMUNITY) {
                state.communitiesList.combinedExploreCommunityList = (_d = action === null || action === void 0 ? void 0 : action.payload) === null || _d === void 0 ? void 0 : _d.data;
            }
            else if (((_e = action === null || action === void 0 ? void 0 : action.payload) === null || _e === void 0 ? void 0 : _e.category) ===
                utils_2.COMMUNITIES_API_CATEGORY_TYPES.RECOMMENDED_COMMUNITY) {
                state.communitiesList.recommendedCommunitiesList =
                    (_f = action === null || action === void 0 ? void 0 : action.payload) === null || _f === void 0 ? void 0 : _f.data;
            }
        });
        builder.addCase(exports.fetchMyCommunityList.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.communitiesList.isCommunitiesListLoading = false;
        });
        // for listing page
        builder.addCase(exports.fetchCommunityList.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.communitiesListForListing.isCommunitiesListLoading = true;
        });
        builder.addCase(exports.fetchCommunityList.fulfilled, (state, action) => {
            // Add logic if any when API state is fulfilled or data is received from API
            state.communitiesListForListing.isCommunitiesListLoading = false;
            state.communitiesListForListing.communityList = action === null || action === void 0 ? void 0 : action.payload;
        });
        builder.addCase(exports.fetchCommunityList.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.communitiesList.isCommunitiesListLoading = false;
        });
        builder.addCase(exports.fetchCommunityGuideline.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
        });
        builder.addCase(exports.fetchCommunityGuideline.fulfilled, (state, action) => {
            var _a;
            // Add logic if any when API state is fulfilled or data is received from API
            state.communityGuidelines.communityGuidelinesList = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.data;
        });
        builder.addCase(exports.fetchCommunityGuideline.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
        });
        builder.addCase(exports.fetchCommunityResources.pending, (state, action) => {
            state.communityResources.communityResourcesLoading = true;
        });
        builder.addCase(exports.fetchCommunityResources.fulfilled, (state, action) => {
            state.communityResources.communityResourcesLoading = false;
            state.communityResources.communityResourcesList = action.payload;
        });
        builder.addCase(exports.fetchCommunityResources.rejected, (state, action) => {
            state.communityResources.communityResourcesLoading = false;
        });
        builder.addCase(exports.fetchCommunityMembers.pending, (state, action) => {
            state.communityMembers.communityMembersLoading = true;
        });
        builder.addCase(exports.fetchCommunityMembers.fulfilled, (state, action) => {
            state.communityMembers.communityMembersLoading = false;
            state.communityMembers.communityMembersList = action.payload;
        });
        builder.addCase(exports.fetchCommunityMembers.rejected, (state, action) => {
            state.communityMembers.communityMembersLoading = false;
        });
        builder.addCase(exports.fetchDiscussionTypes.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.discussionTypes.discussionTypesListLoading = true;
        });
        builder.addCase(exports.fetchDiscussionTypes.fulfilled, (state, action) => {
            // Add logic if any when API state is fulfilled or data is received from API
            state.discussionTypes.discussionTypesListLoading = false;
            state.discussionTypes.discussionTypesList = action.payload;
        });
        builder.addCase(exports.fetchDiscussionTypes.rejected, (state, action) => {
            state.discussionTypes.discussionTypesListLoading = false;
        });
        builder.addCase(exports.fetchCommunityRequests.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.communityRequest.isRequestListLoading = true;
        });
        builder.addCase(exports.fetchCommunityRequests.fulfilled, (state, action) => {
            // Add logic if any when API state is fulfilled or data is received from API
            state.communityRequest.isRequestListLoading = false;
            state.communityRequest.communityRequestList = action.payload;
        });
        builder.addCase(exports.fetchCommunityRequests.rejected, (state, action) => {
            state.communityRequest.isRequestListLoading = false;
        });
        builder.addCase(exports.fetchCommunityReceivedRequests.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.communityRequest.isRequestListLoading = true;
        });
        builder.addCase(exports.fetchCommunityReceivedRequests.fulfilled, (state, action) => {
            // Add logic if any when API state is fulfilled or data is received from API
            state.communityRequest.isRequestListLoading = false;
            state.communityRequest.communityReceivedRequestList = action.payload;
        });
        builder.addCase(exports.fetchCommunityReceivedRequests.rejected, (state, action) => {
            state.communityRequest.isRequestListLoading = false;
        });
        builder.addCase(exports.fetchCommunityDetail.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.communityDetail.communityDetailLoading = true;
        });
        builder.addCase(exports.fetchCommunityDetail.fulfilled, (state, action) => {
            // Add logic if any when API state is fulfilled or data is received from API
            state.communityDetail.communityDetailLoading = false;
            state.communityDetail.communityDetail = action.payload;
        });
        builder.addCase(exports.fetchCommunityDetail.rejected, (state, action) => {
            state.communityDetail.communityDetailLoading = false;
        });
        builder.addCase(exports.fetchCommunityDiscussions.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.communityDiscussions.communityDiscussionsLoading = true;
        });
        builder.addCase(exports.fetchCommunityDiscussions.fulfilled, (state, action) => {
            // Add logic if any when API state is fulfilled or data is received from API
            state.communityDiscussions.communityDiscussionsLoading = false;
            state.communityDiscussions.communityDiscussionsList = action.payload;
        });
        builder.addCase(exports.fetchCommunityDiscussions.rejected, (state, action) => {
            state.communityDiscussions.communityDiscussionsLoading = false;
        });
        builder.addCase(exports.fetchCommunityDiscussionsAnswerTab.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.communityDiscussions.communityDiscussionsLoading = true;
        });
        builder.addCase(exports.fetchCommunityDiscussionsAnswerTab.fulfilled, (state, action) => {
            // Add logic if any when API state is fulfilled or data is received from API
            state.communityDiscussions.communityDiscussionsLoading = false;
            state.communityDiscussions.communityDiscussionsAnswerTabList =
                action.payload;
        });
        builder.addCase(exports.fetchCommunityDiscussionsAnswerTab.rejected, (state, action) => {
            state.communityDiscussions.communityDiscussionsLoading = false;
        });
        builder.addCase(exports.fetchCommunityDetailDiscussions.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.communityDiscussions.communityDiscussionsLoading = true;
        });
        builder.addCase(exports.fetchCommunityDetailDiscussions.fulfilled, (state, action) => {
            // Add logic if any when API state is fulfilled or data is received from API
            state.communityDiscussions.communityDiscussionsLoading = false;
            state.communityDiscussions.communityDetailDiscussionsList =
                action.payload;
        });
        builder.addCase(exports.fetchCommunityDetailDiscussions.rejected, (state, action) => {
            state.communityDiscussions.communityDiscussionsLoading = false;
        });
        builder.addCase(exports.updateCommunity.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.updateCommunity.isCommunityUpdating = true;
        });
        builder.addCase(exports.updateCommunity.fulfilled, (state, action) => {
            // Add logic if any when API state is fulfilled or data is received from API
            state.updateCommunity.isCommunityUpdating = false;
            state.updateCommunity.updateCommunityResponse = action === null || action === void 0 ? void 0 : action.payload;
        });
        builder.addCase(exports.updateCommunity.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.updateCommunity.isCommunityUpdating = false;
        });
        builder.addCase(exports.leaveCommunity.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.leaveCommunity.isLeaveCommunityUpdating = true;
        });
        builder.addCase(exports.leaveCommunity.fulfilled, (state, action) => {
            // Add logic if any when API state is fulfilled or data is received from API
            state.leaveCommunity.isLeaveCommunityUpdating = false;
            state.leaveCommunity.leaveCommunityResponse = action === null || action === void 0 ? void 0 : action.payload;
        });
        builder.addCase(exports.leaveCommunity.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.leaveCommunity.isLeaveCommunityUpdating = false;
        });
        builder.addCase(exports.fetchDiscussionQuestions.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.discussionQuestions.discussionQuestionsLoading = true;
        });
        builder.addCase(exports.fetchDiscussionQuestions.fulfilled, (state, action) => {
            // Add logic if any when API state is fulfilled or data is received from API
            state.discussionQuestions.discussionQuestionsLoading = false;
            state.discussionQuestions.discussionQuestions = action.payload;
        });
        builder.addCase(exports.fetchDiscussionQuestions.rejected, (state, action) => {
            state.discussionQuestions.discussionQuestionsLoading = false;
        });
        builder.addCase(exports.revokeQuestion.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.revokeQuestion.revokeQuestionLoading = true;
        });
        builder.addCase(exports.revokeQuestion.fulfilled, (state, action) => {
            // Add logic if any when API state is fulfilled or data is received from API
            state.revokeQuestion.revokeQuestionLoading = false;
            state.revokeQuestion.revokeQuestion = action.payload;
        });
        builder.addCase(exports.revokeQuestion.rejected, (state, action) => {
            state.revokeQuestion.revokeQuestionLoading = false;
        });
        //Landing content api
        builder.addCase(exports.fetchCommunityLandingContent.pending, (state, action) => {
            state.communityLandingContent.isCommunityLandingContentLoading = true;
        });
        builder.addCase(exports.fetchCommunityLandingContent.fulfilled, (state, action) => {
            state.communityLandingContent.isCommunityLandingContentLoading = false;
            state.communityLandingContent.communityLandingContent = action === null || action === void 0 ? void 0 : action.payload;
        });
        builder.addCase(exports.fetchCommunityLandingContent.rejected, (state, action) => {
            state.communityLandingContent.isCommunityLandingContentLoading = false;
        });
        //Combined Explore Community
        builder.addCase(exports.fetchCombinedExploreCommunity.pending, (state, action) => {
            state.communitiesList.isCombinedExploreListLoading = true;
        });
        builder.addCase(exports.fetchCombinedExploreCommunity.fulfilled, (state, action) => {
            var _a, _b, _c, _d, _e;
            state.communitiesList.isCombinedExploreListLoading = false;
            const finalArray = Object.assign(Object.assign({}, action.payload.data), { data: action.payload.isFilterApplied === true ? action.payload.data.data : (0, utils_1.removeDuplicates)([...(_b = (_a = state.communitiesList.combinedExploreCommunityList) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : [], ...(_e = (_d = (_c = action === null || action === void 0 ? void 0 : action.payload) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.data) !== null && _e !== void 0 ? _e : []], 'id') });
            state.communitiesList.combinedExploreCommunityList = finalArray;
        });
        builder.addCase(exports.fetchCombinedExploreCommunity.rejected, (state, action) => {
            state.communitiesList.isCombinedExploreListLoading = false;
        });
    },
});
exports.fetchMyCommunityList = (0, toolkit_1.createAsyncThunk)(`${constants_1.COMMUNITY}/${constants_1.FETCH_MY_COMMUNITY_LIST}`, (payload) => __awaiter(void 0, void 0, void 0, function* () {
    const params = (0, utils_1.generateQueryString)(payload);
    const contentList = yield (0, api_1.get)(`${api_1.ENDPOINTS.LIST_COMMUNITY}${params}`, {
        baseURL: api_1.baseUrl,
    });
    return { data: contentList, category: payload === null || payload === void 0 ? void 0 : payload.category };
}));
exports.fetchCommunityLandingContent = (0, toolkit_1.createAsyncThunk)(`${constants_1.COMMUNITY}/${constants_1.FETCH_COMMUNITY_LANDING_CONTENT}`, (payload) => __awaiter(void 0, void 0, void 0, function* () {
    const params = (0, utils_1.generateQueryString)(payload);
    const contentList = yield (0, api_1.get)(`${constants_1.COMMUNITY_LANDING_CONTENT_ENDPOINT}${params}`, {
        baseURL: api_1.BASE_URL_NODE,
    });
    return contentList;
}));
exports.fetchCommunityList = (0, toolkit_1.createAsyncThunk)(`${constants_1.COMMUNITY}/${constants_1.FETCH_MY_COMMUNITY_LIST_FOR_LISTING}`, (payload) => __awaiter(void 0, void 0, void 0, function* () {
    const params = (0, utils_1.generateQueryString)(payload);
    const contentList = yield (0, api_1.get)(`${api_1.ENDPOINTS.LIST_COMMUNITY}${params}`, {
        baseURL: api_1.baseUrl,
    });
    return contentList;
}));
exports.fetchCommunityGuideline = (0, toolkit_1.createAsyncThunk)(`${constants_1.COMMUNITY}/${constants_1.FETCH_COMMUNITY_GUIDELINES}`, () => __awaiter(void 0, void 0, void 0, function* () {
    const communityGuideline = yield (0, api_1.get)(constants_1.GET_COMMUNITY_GUIDELINE_ENDPOINT);
    return communityGuideline;
}));
exports.fetchDiscussionTypes = (0, toolkit_1.createAsyncThunk)(`${constants_1.COMMUNITY}/${constants_1.FETCH_DISCUSSION_TYPES}`, () => __awaiter(void 0, void 0, void 0, function* () {
    const discussionTypes = yield (0, api_1.get)(constants_1.GET_DISCUSSION_TYPES_ENDPOINT);
    return discussionTypes;
}));
exports.fetchCommunityRequests = (0, toolkit_1.createAsyncThunk)(`${constants_1.COMMUNITY}/${constants_1.FETCH_COMMUNITY_REQUEST}`, (payload) => __awaiter(void 0, void 0, void 0, function* () {
    const params = (0, utils_1.generateQueryString)(payload);
    const requests = yield (0, api_1.get)(`${api_1.ENDPOINTS.GET_COMMUNITY_REQUESTS_ENDPOINT}${params}`, {
        baseURL: api_1.baseUrl,
    });
    return requests;
}));
exports.fetchCommunityReceivedRequests = (0, toolkit_1.createAsyncThunk)(`${constants_1.COMMUNITY}/${constants_1.FETCH_COMMUNITY_RECEIVED_REQUEST}`, (payload) => __awaiter(void 0, void 0, void 0, function* () {
    const params = (0, utils_1.generateQueryString)(payload);
    const requests = yield (0, api_1.get)(`${api_1.ENDPOINTS.GET_COMMUNITY_REQUESTS_ENDPOINT}${params}`, {
        baseURL: api_1.baseUrl,
    });
    return requests;
}));
exports.fetchCommunityResources = (0, toolkit_1.createAsyncThunk)(`${constants_1.COMMUNITY}/${constants_1.FETCH_COMMUNITY_RESOURCES}`, (payload) => __awaiter(void 0, void 0, void 0, function* () {
    const params = (0, utils_1.generateQueryString)(payload);
    const resourcesList = yield (0, api_1.get)(`${constants_1.GET_COMMUNITY_RESOURCES}${params}`);
    return resourcesList;
}));
exports.fetchCommunityMembers = (0, toolkit_1.createAsyncThunk)(`${constants_1.COMMUNITY}/${constants_1.FETCH_COMMUNITY_MEMBERS}`, (payload) => __awaiter(void 0, void 0, void 0, function* () {
    const params = (0, utils_1.generateQueryString)(payload);
    const membersList = yield (0, api_1.get)(`${constants_1.GET_COMMUNITY_MEMBERS}${params}`);
    return membersList;
}));
exports.fetchCommunityDetail = (0, toolkit_1.createAsyncThunk)(`${constants_1.COMMUNITY}/${constants_1.FETCH_COMMUNITY_DETAIL}`, (communityId) => __awaiter(void 0, void 0, void 0, function* () {
    const detail = yield (0, api_1.get)(`${constants_1.GET_COMMUNITY_DETAIL}/${communityId}`);
    return detail;
}));
exports.fetchCommunityDiscussions = (0, toolkit_1.createAsyncThunk)(`${constants_1.COMMUNITY}/${constants_1.FETCH_COMMUNITY_DISCUSSIONS}`, (payload) => __awaiter(void 0, void 0, void 0, function* () {
    const params = (0, utils_1.generateQueryString)(payload);
    const communityDiscussions = yield (0, api_1.get)(`${constants_1.GET_COMMUNITY_DISCUSSIONS}/${params}`);
    return communityDiscussions;
}));
exports.fetchCommunityDiscussionsAnswerTab = (0, toolkit_1.createAsyncThunk)(`${constants_1.COMMUNITY}/${constants_1.FETCH_COMMUNITY_DISCUSSIONS_ANSWER_TAB}`, (payload) => __awaiter(void 0, void 0, void 0, function* () {
    const params = (0, utils_1.generateQueryString)(payload);
    const communityDiscussions = yield (0, api_1.get)(`${constants_1.GET_COMMUNITY_DISCUSSIONS}/${params}`);
    return communityDiscussions;
}));
exports.fetchCommunityDetailDiscussions = (0, toolkit_1.createAsyncThunk)(`${constants_1.COMMUNITY}/${constants_1.FETCH_COMMUNITY_DETAIL_DISCUSSIONS}`, (payload) => __awaiter(void 0, void 0, void 0, function* () {
    const params = (0, utils_1.generateQueryString)(payload);
    const communityDiscussions = yield (0, api_1.get)(`${constants_1.GET_COMMUNITY_DISCUSSIONS}/${params}`);
    return communityDiscussions;
}));
exports.leaveCommunity = (0, toolkit_1.createAsyncThunk)(`${constants_1.COMMUNITY}/${constants_1.LEAVE_COMMUNITY}`, (payload) => __awaiter(void 0, void 0, void 0, function* () {
    const leaveCommunityResponse = yield (0, api_1.post)(constants_1.LEAVE_COMMUNITY_ENDPOINT, payload);
    return leaveCommunityResponse;
}));
exports.submitAnswer = (0, toolkit_1.createAsyncThunk)(`${constants_1.COMMUNITY}/${constants_1.SUBMIT_ANSWER}`, (payload) => __awaiter(void 0, void 0, void 0, function* () {
    const submitAnswerResponse = yield (0, api_1.post)(constants_1.SUBMIT_ANSWER_ENDPOINT, payload, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
    return submitAnswerResponse;
}));
exports.submitQuestion = (0, toolkit_1.createAsyncThunk)(`${constants_1.COMMUNITY}/${constants_1.SUBMIT_QUESTION}`, (payload) => __awaiter(void 0, void 0, void 0, function* () {
    const submitQuestionResponse = yield (0, api_1.post)(constants_1.SUBMIT_QUESTION_ENDPOINT, payload);
    return submitQuestionResponse;
}));
exports.updateCommunity = (0, toolkit_1.createAsyncThunk)(`${constants_1.COMMUNITY}/${constants_1.UPDATE_COMMUNITY}`, (req) => __awaiter(void 0, void 0, void 0, function* () {
    const updateCommunity = yield (0, api_1.put)(constants_1.UPDATE_COMMUNITY_ENDPOINT, req);
    return updateCommunity;
}));
exports.fetchDiscussionQuestions = (0, toolkit_1.createAsyncThunk)(`${constants_1.COMMUNITY}/${constants_1.GET_DISCUSSION_QUESTIONS}`, (payload) => __awaiter(void 0, void 0, void 0, function* () {
    const params = (0, utils_1.generateQueryString)(payload);
    const questions = yield (0, api_1.get)(`${constants_1.GET_DISCUSSION_QUESTION_ENDPOINT}${params}`);
    return questions;
}));
exports.revokeQuestion = (0, toolkit_1.createAsyncThunk)(`${constants_1.COMMUNITY}/${constants_1.REVOKE_QUESTION}`, (req) => __awaiter(void 0, void 0, void 0, function* () {
    const questionRes = yield (0, api_1.put)(constants_1.REVOKE_QUESTION_ENDPOINT, req);
    return questionRes;
}));
exports.fetchCombinedExploreCommunity = (0, toolkit_1.createAsyncThunk)(`${constants_1.COMMUNITY}/${constants_1.COMBINED_EXPLORE_COMMUNITY}`, (payload, { getState, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
    const { community, user } = getState();
    const params = (0, utils_1.generateQueryString)(payload);
    const paramsForMyCommunity = (0, utils_1.generateQueryString)(Object.assign(Object.assign({}, payload), { category: utils_2.COMMUNITIES_API_CATEGORY_TYPES.MY_COMMUNITY, skip: community === null || community === void 0 ? void 0 : community.skipCountForMyCommunity }));
    const isLoggedIn = (_b = payload === null || payload === void 0 ? void 0 : payload.isLoggedIn) !== null && _b !== void 0 ? _b : (_d = (_c = user === null || user === void 0 ? void 0 : user.user) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.uid;
    const skipCount = Number((payload === null || payload === void 0 ? void 0 : payload.skip) || '0');
    const takeCount = Number((payload === null || payload === void 0 ? void 0 : payload.take) || '5');
    const pageNumber = ((skipCount / takeCount) + 1);
    const totalRecordsCanBeFetched = ((_e = (pageNumber * takeCount)) !== null && _e !== void 0 ? _e : 5);
    const contentList = yield (0, api_1.get)(`${api_1.ENDPOINTS.LIST_COMMUNITY}${params}`, {
        baseURL: api_1.baseUrl,
    });
    const contentListLength = Number((_g = (_f = contentList === null || contentList === void 0 ? void 0 : contentList.data) === null || _f === void 0 ? void 0 : _f.length) !== null && _g !== void 0 ? _g : '0');
    const contentListCount = Number((contentList === null || contentList === void 0 ? void 0 : contentList.count) || '0');
    if (contentList && (contentListLength === 0 || contentListLength < 5 || Number(totalRecordsCanBeFetched) >= (contentListCount || 0)) && isLoggedIn) {
        const myCommunityList = yield (0, api_1.get)(`${api_1.ENDPOINTS.LIST_COMMUNITY}${paramsForMyCommunity}`, {
            baseURL: api_1.baseUrl,
        });
        let storedExploreContentCount = 0;
        let storedCommunityContentCount = 0;
        if (contentList === null || contentList === void 0 ? void 0 : contentList.count) {
            storedExploreContentCount = contentList === null || contentList === void 0 ? void 0 : contentList.count;
        }
        else {
            storedExploreContentCount = (_m = (_l = (_k = (_j = (_h = community === null || community === void 0 ? void 0 : community.communitiesList) === null || _h === void 0 ? void 0 : _h.combinedExploreCommunityList) === null || _j === void 0 ? void 0 : _j.data) === null || _k === void 0 ? void 0 : _k.filter((item) => (item === null || item === void 0 ? void 0 : item.iscommunityjoined) === false)) === null || _l === void 0 ? void 0 : _l.length) !== null && _m !== void 0 ? _m : 0;
        }
        if (myCommunityList === null || myCommunityList === void 0 ? void 0 : myCommunityList.count) {
            storedCommunityContentCount = myCommunityList === null || myCommunityList === void 0 ? void 0 : myCommunityList.count;
        }
        else {
            storedCommunityContentCount = (_s = (_r = (_q = (_p = (_o = myCommunityList === null || myCommunityList === void 0 ? void 0 : myCommunityList.communitiesList) === null || _o === void 0 ? void 0 : _o.combinedExploreCommunityList) === null || _p === void 0 ? void 0 : _p.data) === null || _q === void 0 ? void 0 : _q.filter((item) => (item === null || item === void 0 ? void 0 : item.iscommunityjoined) === true)) === null || _r === void 0 ? void 0 : _r.length) !== null && _s !== void 0 ? _s : 0;
        }
        const responses = [Object.assign(Object.assign({}, contentList), { count: storedExploreContentCount !== null && storedExploreContentCount !== void 0 ? storedExploreContentCount : 0 }), Object.assign(Object.assign({}, myCommunityList), { count: storedCommunityContentCount !== null && storedCommunityContentCount !== void 0 ? storedCommunityContentCount : 0 })];
        const dataToStoreInternally = {
            message: contentList === null || contentList === void 0 ? void 0 : contentList.message,
            statusCode: contentList === null || contentList === void 0 ? void 0 : contentList.statusCode,
            status: contentList === null || contentList === void 0 ? void 0 : contentList.status,
            data: [...(_t = contentList === null || contentList === void 0 ? void 0 : contentList.data) !== null && _t !== void 0 ? _t : [], ...(_u = myCommunityList === null || myCommunityList === void 0 ? void 0 : myCommunityList.data) !== null && _u !== void 0 ? _u : []],
            count: String(responses.reduce((acc, item) => {
                var _a;
                return acc + parseInt((_a = item === null || item === void 0 ? void 0 : item.count) !== null && _a !== void 0 ? _a : 0, 10);
            }, 0))
        };
        const isFilterApplied = (payload === null || payload === void 0 ? void 0 : payload.skip) === 0 && community.skipCountForMyCommunity === 0;
        dispatch((0, exports.updateSkipCountForMyCommunity)(community.skipCountForMyCommunity + 5));
        return { data: dataToStoreInternally, isFilterApplied: isFilterApplied };
    }
    else {
        return { data: contentList, isFilterApplied: (payload === null || payload === void 0 ? void 0 : payload.skip) === 0 };
    }
}));
exports.getMyCommunitiesList = (0, toolkit_1.createSelector)((state) => state.community, (community) => {
    var _a, _b, _c, _d;
    const myCommunityList = (_d = (_c = (_b = (_a = community === null || community === void 0 ? void 0 : community.communityLandingContent) === null || _a === void 0 ? void 0 : _a.communityLandingContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.key) === (reducer_constants_1.COMMUNITY_API_KEY === null || reducer_constants_1.COMMUNITY_API_KEY === void 0 ? void 0 : reducer_constants_1.COMMUNITY_API_KEY.LIST_OF_MY_COMMUNITIES))) === null || _d === void 0 ? void 0 : _d.result;
    return myCommunityList;
});
exports.getMyCommunitiesListCount = (0, toolkit_1.createSelector)((state) => state.community, (community) => {
    var _a, _b, _c, _d;
    const myCommunityList = (_d = (_c = (_b = (_a = community === null || community === void 0 ? void 0 : community.communityLandingContent) === null || _a === void 0 ? void 0 : _a.communityLandingContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.key) === (reducer_constants_1.COMMUNITY_API_KEY === null || reducer_constants_1.COMMUNITY_API_KEY === void 0 ? void 0 : reducer_constants_1.COMMUNITY_API_KEY.LIST_OF_MY_COMMUNITIES))) === null || _d === void 0 ? void 0 : _d.result;
    return (myCommunityList === null || myCommunityList === void 0 ? void 0 : myCommunityList.length) || 0;
});
exports.getRecommendedCommunitiesList = (0, toolkit_1.createSelector)((state) => state.community, (community) => {
    var _a, _b, _c, _d;
    const recommendedCommunityList = (_d = (_c = (_b = (_a = community === null || community === void 0 ? void 0 : community.communityLandingContent) === null || _a === void 0 ? void 0 : _a.communityLandingContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.key) === (reducer_constants_1.COMMUNITY_API_KEY === null || reducer_constants_1.COMMUNITY_API_KEY === void 0 ? void 0 : reducer_constants_1.COMMUNITY_API_KEY.LIST_OF_RECOMMENDED_COMMUNITIES))) === null || _d === void 0 ? void 0 : _d.result;
    return recommendedCommunityList;
});
exports.getRecommendedCommunitiesListCount = (0, toolkit_1.createSelector)((state) => state.community, (community) => {
    var _a, _b, _c, _d;
    const recommendedCommunityList = (_d = (_c = (_b = (_a = community === null || community === void 0 ? void 0 : community.communityLandingContent) === null || _a === void 0 ? void 0 : _a.communityLandingContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.key) === (reducer_constants_1.COMMUNITY_API_KEY === null || reducer_constants_1.COMMUNITY_API_KEY === void 0 ? void 0 : reducer_constants_1.COMMUNITY_API_KEY.LIST_OF_RECOMMENDED_COMMUNITIES))) === null || _d === void 0 ? void 0 : _d.result;
    return (recommendedCommunityList === null || recommendedCommunityList === void 0 ? void 0 : recommendedCommunityList.length) || 0;
});
exports.getExploreCommunitiesList = (0, toolkit_1.createSelector)((state) => state.community, (community) => {
    var _a, _b, _c, _d;
    const exploreCommunityList = (_d = (_c = (_b = (_a = community === null || community === void 0 ? void 0 : community.communityLandingContent) === null || _a === void 0 ? void 0 : _a.communityLandingContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.key) === (reducer_constants_1.COMMUNITY_API_KEY === null || reducer_constants_1.COMMUNITY_API_KEY === void 0 ? void 0 : reducer_constants_1.COMMUNITY_API_KEY.LIST_OF_EXPLORE_COMMUNITIES))) === null || _d === void 0 ? void 0 : _d.result;
    return exploreCommunityList;
});
exports.getExploreCommunitiesListForHome = (0, toolkit_1.createSelector)((state) => state.community, (community) => {
    var _a, _b;
    const communityData = (_b = (_a = community === null || community === void 0 ? void 0 : community.communitiesList) === null || _a === void 0 ? void 0 : _a.combinedExploreCommunityList) === null || _b === void 0 ? void 0 : _b.data;
    if (communityData && (communityData === null || communityData === void 0 ? void 0 : communityData.length) > 0) {
        return communityData === null || communityData === void 0 ? void 0 : communityData.slice(0, 5);
    }
    else {
        return [];
    }
});
exports.getExploreCommunitiesListForCommunity = (0, toolkit_1.createSelector)((state) => state.community, (community) => { var _a, _b; return (_b = (_a = community === null || community === void 0 ? void 0 : community.communitiesList) === null || _a === void 0 ? void 0 : _a.combinedExploreCommunityList) === null || _b === void 0 ? void 0 : _b.data; });
exports.getExploreCommunitiesListCount = (0, toolkit_1.createSelector)((state) => state.community, (community) => { var _a, _b; return (_b = (_a = community === null || community === void 0 ? void 0 : community.communitiesList) === null || _a === void 0 ? void 0 : _a.combinedExploreCommunityList) === null || _b === void 0 ? void 0 : _b.count; });
exports.getCommunityGuidelines = (0, toolkit_1.createSelector)((state) => state.community, (community) => { var _a; return (_a = community === null || community === void 0 ? void 0 : community.communityGuidelines) === null || _a === void 0 ? void 0 : _a.communityGuidelinesList; });
exports.getCommunityDetail = (0, toolkit_1.createSelector)((state) => state.community, (community) => { var _a, _b; return (_b = (_a = community === null || community === void 0 ? void 0 : community.communityDetail) === null || _a === void 0 ? void 0 : _a.communityDetail) === null || _b === void 0 ? void 0 : _b.data; });
exports.getCommunityResources = (0, toolkit_1.createSelector)((state) => state.community, (community) => { var _a, _b; return (_b = (_a = community === null || community === void 0 ? void 0 : community.communityResources) === null || _a === void 0 ? void 0 : _a.communityResourcesList) === null || _b === void 0 ? void 0 : _b.data; });
exports.getCommunityResourcesTotalCount = (0, toolkit_1.createSelector)((state) => state.community, (community) => { var _a, _b; return (_b = (_a = community === null || community === void 0 ? void 0 : community.communityResources) === null || _a === void 0 ? void 0 : _a.communityResourcesList) === null || _b === void 0 ? void 0 : _b.count; });
exports.getCommunityMembers = (0, toolkit_1.createSelector)((state) => state.community, (community) => { var _a, _b; return (_b = (_a = community === null || community === void 0 ? void 0 : community.communityMembers) === null || _a === void 0 ? void 0 : _a.communityMembersList) === null || _b === void 0 ? void 0 : _b.data; });
const getCommunityMembersForMentions = (user) => (0, toolkit_1.createSelector)((state) => state.community, (community) => {
    var _a, _b;
    const communityMemberData = (_b = (_a = community === null || community === void 0 ? void 0 : community.communityMembers) === null || _a === void 0 ? void 0 : _a.communityMembersList) === null || _b === void 0 ? void 0 : _b.data;
    const communityMembersWithoutLoggedInUser = communityMemberData === null || communityMemberData === void 0 ? void 0 : communityMemberData.filter((item) => {
        const loggedInUserName = `${user === null || user === void 0 ? void 0 : user.firstName} ${user === null || user === void 0 ? void 0 : user.lastName}`;
        const communityUserName = `${item === null || item === void 0 ? void 0 : item.member_first_name} ${item === null || item === void 0 ? void 0 : item.member_last_name}`;
        return loggedInUserName !== communityUserName;
    });
    return communityMembersWithoutLoggedInUser === null || communityMembersWithoutLoggedInUser === void 0 ? void 0 : communityMembersWithoutLoggedInUser.map((item) => {
        return {
            name: `${item === null || item === void 0 ? void 0 : item.member_first_name} ${item === null || item === void 0 ? void 0 : item.member_last_name}`,
            id: ((item === null || item === void 0 ? void 0 : item.member_id) && (item === null || item === void 0 ? void 0 : item.member_id)) || "",
        };
    });
});
exports.getCommunityMembersForMentions = getCommunityMembersForMentions;
exports.getCommunityMembersTotalCount = (0, toolkit_1.createSelector)((state) => state.community, (community) => { var _a, _b; return (_b = (_a = community === null || community === void 0 ? void 0 : community.communityMembers) === null || _a === void 0 ? void 0 : _a.communityMembersList) === null || _b === void 0 ? void 0 : _b.count; });
exports.getCommunityRequestsOnLoad = (0, toolkit_1.createSelector)((state) => state.community, (community) => {
    var _a, _b, _c, _d;
    const requestList = (_d = (_c = (_b = (_a = community === null || community === void 0 ? void 0 : community.communityLandingContent) === null || _a === void 0 ? void 0 : _a.communityLandingContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.key) === (reducer_constants_1.COMMUNITY_API_KEY === null || reducer_constants_1.COMMUNITY_API_KEY === void 0 ? void 0 : reducer_constants_1.COMMUNITY_API_KEY.LIST_OF_REQUESTS))) === null || _d === void 0 ? void 0 : _d.result;
    return requestList;
});
exports.getCommunityRequests = (0, toolkit_1.createSelector)((state) => state.community, (community) => { var _a, _b; return (_b = (_a = community === null || community === void 0 ? void 0 : community.communityRequest) === null || _a === void 0 ? void 0 : _a.communityRequestList) === null || _b === void 0 ? void 0 : _b.data; });
exports.getCommunityReceivedRequests = (0, toolkit_1.createSelector)((state) => state.community, (community) => { var _a, _b; return (_b = (_a = community === null || community === void 0 ? void 0 : community.communityRequest) === null || _a === void 0 ? void 0 : _a.communityReceivedRequestList) === null || _b === void 0 ? void 0 : _b.data; });
exports.getCommunityDiscussionList = (0, toolkit_1.createSelector)((state) => state.community, (community) => { var _a, _b; return (_b = (_a = community === null || community === void 0 ? void 0 : community.communityDiscussions) === null || _a === void 0 ? void 0 : _a.communityDiscussionsList) === null || _b === void 0 ? void 0 : _b.data; });
exports.getCommunityDetailDiscussionList = (0, toolkit_1.createSelector)((state) => state.community, (community) => { var _a, _b; return (_b = (_a = community === null || community === void 0 ? void 0 : community.communityDiscussions) === null || _a === void 0 ? void 0 : _a.communityDetailDiscussionsList) === null || _b === void 0 ? void 0 : _b.data; });
exports.getCommunityDiscussionAnswerTabList = (0, toolkit_1.createSelector)((state) => state.community, (community) => { var _a, _b; return (_b = (_a = community === null || community === void 0 ? void 0 : community.communityDiscussions) === null || _a === void 0 ? void 0 : _a.communityDiscussionsAnswerTabList) === null || _b === void 0 ? void 0 : _b.data; });
exports.getDiscussionQuestionsList = (0, toolkit_1.createSelector)((state) => state.community, (community) => { var _a, _b; return (_b = (_a = community === null || community === void 0 ? void 0 : community.discussionQuestions) === null || _a === void 0 ? void 0 : _a.discussionQuestions) === null || _b === void 0 ? void 0 : _b.data; });
exports.getCombinedExploreCommunityCount = (0, toolkit_1.createSelector)((state) => state.community, (community) => { var _a, _b; return (_b = (_a = community === null || community === void 0 ? void 0 : community.communitiesList) === null || _a === void 0 ? void 0 : _a.combinedExploreCommunityList) === null || _b === void 0 ? void 0 : _b.count; });
exports.getExploreTotalCount = (0, toolkit_1.createSelector)((state) => state.community, (community) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = community === null || community === void 0 ? void 0 : community.communitiesList) === null || _a === void 0 ? void 0 : _a.combinedExploreCommunityList) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.filter((item) => (item === null || item === void 0 ? void 0 : item.iscommunityjoined) === false)) === null || _d === void 0 ? void 0 : _d.length; });
_a = communitySlice.actions, exports.updateResourcesList = _a.updateResourcesList, exports.updateMembersList = _a.updateMembersList, exports.updateDiscussionList = _a.updateDiscussionList, exports.updateSkipCountForMyCommunity = _a.updateSkipCountForMyCommunity, exports.resetSkipCountForMyCommunity = _a.resetSkipCountForMyCommunity;
exports.default = communitySlice.reducer;
