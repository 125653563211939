"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThumbActiveIcon = void 0;
const React = __importStar(require("react"));
const ThumbActiveIcon = (props) => (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: 24, height: 24, fill: "none" }, props),
    React.createElement("path", { fill: "#E84E1B", fillRule: "evenodd", d: "m12.236 5.263-.839-.56-2.564 3.848a1.272 1.272 0 0 1-1.06.567v.001H3.764A.765.765 0 0 0 3 9.884v8.157a2.804 2.804 0 0 0 2.804 2.805h11.584a2.805 2.805 0 0 0 2.75-2.255v.001l1.224-6.118-.56-.113.56.112a2.81 2.81 0 0 0-1.551-3.085 2.804 2.804 0 0 0-1.2-.269h-2.727l.674-4.04a1.79 1.79 0 0 0-.399-1.447A1.784 1.784 0 0 0 14.798 3h-.218a3.823 3.823 0 0 0-3.182 1.702l.839.56Z", clipRule: "evenodd" }),
    React.createElement("path", { fill: "#E84E1B", d: "M7 9v12Z" }),
    React.createElement("path", { stroke: "#fff", strokeWidth: 1.7, d: "M7 9v12" })));
exports.ThumbActiveIcon = ThumbActiveIcon;
