import { AppConstant, translate, ImageConstant } from '@shalina-app/shared';
import { Link, useLocation } from 'react-router-dom';

import { SignUpSectionPropsType } from '.';
import { URLConstant } from '../../../../routes';
import styles from './signUpSection.module.scss';

export const SignUpSection: React.FC<SignUpSectionPropsType> = ({
  mainText,
  subText
}) => {
  const { urls } = URLConstant;
  const t = translate();
  const location = useLocation();
  const pathName = location?.pathname;
  return (
    <div className={styles.signUpSec}>
      <img src={ImageConstant.SOURCE.guestSignup} alt='user img' />
      <p className={styles.title}>{mainText}</p>
      <p className={styles.desc}>{subText}</p>
      <Link
        to={urls.signUpPage}
        className={styles.cta}
        state={{ pathName: pathName }}
      >
        {/* <div className={styles.cta} onClick={handleSignUp}> */}
        {t(AppConstant.AUTHENTICATION_PAGE.SIGN_UP_CTA)}
        {/* </div> */}
      </Link>
      <div className={styles.signIn}>
        <p className={styles.text}>
          {t(AppConstant.AUTHENTICATION_PAGE.ALREADY_HAVE_ACCOUNT_TEXT)}
        </p>
        <Link
          to={urls.signInPage}
          state={{ pathName: pathName }}
          className={styles.cta}
        >
          {t(AppConstant.AUTHENTICATION_PAGE.SIGN_IN_CTA)}
        </Link>
      </div>
    </div>
  );
};
