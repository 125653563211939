import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import { InfiniteScrollProps } from './infiniteScroll.interface';
import styles from './infiniteScroll.module.scss';

export const InfiniteScrollComponent: React.FC<InfiniteScrollProps> = ({
  children,
  fetchMoreData,
  dataLength,
  hasMore,
  height,
  loader,
  scrollableTarget,
  scrollThreshold
}) => {
  return (
    <>
      {/* <div id="scrollableDiv" style={{ height: 800, overflow: 'scroll' }}> */}
      <InfiniteScroll
        dataLength={dataLength}
        next={fetchMoreData}
        hasMore={hasMore}
        scrollableTarget={scrollableTarget}
        // height={height}
        // scrollThreshold={0.4}
        scrollThreshold={scrollThreshold}
        loader={
          loader ? (
            <div className={`${styles.listLoader} globalPaginationLoader`}>
              <Box sx={{ display: 'flex' }} className={styles.loaderWrapper}>
                <CircularProgress
                  size={40}
                  thickness={4}
                  sx={{
                    color: 'var(--secondaryOrange)'
                  }}
                />
              </Box>
            </div>
          ) : null
        }
        // height={400}
        // endMessage={
        //     <p style={{ textAlign: "center" }}>
        //       <b>Yay! You have seen it all</b>
        //     </p>
        //   }
      >
        {children}
      </InfiniteScroll>
      {/* </div> */}
    </>
  );
};
