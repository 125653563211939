"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateArticleSearchKeyword = exports.updateLearningCenterViewAllList = exports.updateLearningCenterTabList = exports.updateShalinaTvViewAllList = exports.updateKnowledgeHubViewAllList = exports.updateBookmarkStateForContentList = exports.updateBookmarkState = exports.getArticleSearchKeyword = exports.getLearningCenterTabContentByEventId = exports.getViewAllContentById = exports.getCommonListingContent = exports.getArticleListData = exports.getViewAllListData = exports.getContentListData = exports.fetchArticleDocumentLink = exports.fetchArticleList = exports.fetchContentListByType = exports.fetchknowledgehubContent = void 0;
const api_1 = require("../../../api");
const toolkit_1 = require("@reduxjs/toolkit");
const constants_1 = require("../../constants");
const utils_1 = require("../../utils");
const utils_2 = require("../../../utils");
const initialState = {
    isLoading: false,
    listContent: {
        isError: false,
        isListLoading: false,
        knowledgeHubContentList: {},
    },
    viewAllContent: {
        isError: false,
        isAllContentLoading: false,
        viewAllContentList: {},
        knowledgeHubViewAllList: [],
        shalinaTvViewAllList: [],
        learningCenterTabList: {},
        learningCenterViewAllList: [],
    },
    articleList: {
        isError: false,
        isArticleListLoading: false,
        articleList: {},
    },
    articleDocLink: {
        articleDocLink: {},
        isArticleDocLinkLoading: false,
    },
    searchArticleKeyword: '',
};
const knowledgeHubSlice = (0, toolkit_1.createSlice)({
    name: constants_1.KNOWLEDGE_HUB,
    initialState,
    reducers: {
        updateKnowledgeHubViewAllList: (state, action) => {
            state.viewAllContent.knowledgeHubViewAllList = action.payload;
        },
        updateShalinaTvViewAllList: (state, action) => {
            state.viewAllContent.shalinaTvViewAllList = action.payload;
        },
        updateLearningCenterTabList: (state, action) => {
            const { key, dataToStore } = action.payload;
            state.viewAllContent.learningCenterTabList[key] = dataToStore;
        },
        updateLearningCenterViewAllList: (state, action) => {
            state.viewAllContent.learningCenterViewAllList = action.payload;
        },
        updateBookmarkState: (state, action) => {
            var _a, _b;
            const { nid, contentTypeId } = action.payload;
            const contentListByContentType = ((_a = state.listContent.knowledgeHubContentList) === null || _a === void 0 ? void 0 : _a.data)
                ? (_b = state.listContent.knowledgeHubContentList) === null || _b === void 0 ? void 0 : _b.data[contentTypeId]
                : [];
            if (contentListByContentType && (contentListByContentType === null || contentListByContentType === void 0 ? void 0 : contentListByContentType.length) > 0) {
                const updatedContentList = contentListByContentType.map((item) => item.nid === nid
                    ? Object.assign(Object.assign({}, item), { is_bookmarked: !item.is_bookmarked }) : item);
                state.listContent.knowledgeHubContentList.data[contentTypeId] =
                    updatedContentList;
            }
        },
        updateBookmarkStateForContentList: (state, action) => {
            var _a, _b;
            const { nid } = action.payload;
            const contentListByContentType = ((_a = state.viewAllContent.viewAllContentList) === null || _a === void 0 ? void 0 : _a.data)
                ? (_b = state.viewAllContent.viewAllContentList) === null || _b === void 0 ? void 0 : _b.data
                : [];
            if (contentListByContentType && (contentListByContentType === null || contentListByContentType === void 0 ? void 0 : contentListByContentType.length) > 0) {
                const updatedContentList = contentListByContentType.map((item) => item.nid === nid
                    ? Object.assign(Object.assign({}, item), { is_bookmarked: !item.is_bookmarked }) : item);
                state.viewAllContent.viewAllContentList.data = updatedContentList;
            }
        },
        updateCommonListingData: (state, action) => {
            var _a;
            state.viewAllContent.commonListingData = (_a = action.payload) !== null && _a !== void 0 ? _a : [];
        },
        updateArticleSearchKeyword: (state, action) => {
            state.searchArticleKeyword = action.payload;
        },
    },
    extraReducers(builder) {
        /**
             *Content API
            //  */
        builder.addCase(exports.fetchknowledgehubContent.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.listContent.isListLoading = true;
        });
        builder.addCase(exports.fetchknowledgehubContent.fulfilled, (state, action) => {
            // Add logic if any when API state is fulfilled or data is received from API
            state.listContent.isListLoading = false;
            state.listContent.knowledgeHubContentList = action === null || action === void 0 ? void 0 : action.payload;
        });
        builder.addCase(exports.fetchknowledgehubContent.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.listContent.knowledgeHubContentList = false;
        });
        builder.addCase(exports.fetchContentListByType.pending, (state, action) => {
            state.viewAllContent.isAllContentLoading = true;
        });
        builder.addCase(exports.fetchContentListByType.fulfilled, (state, action) => {
            state.viewAllContent.isAllContentLoading = false;
            state.viewAllContent.viewAllContentList = action === null || action === void 0 ? void 0 : action.payload;
        });
        builder.addCase(exports.fetchContentListByType.rejected, (state, action) => {
            state.viewAllContent.isAllContentLoading = false;
        });
        builder.addCase(exports.fetchArticleList.pending, (state, action) => {
            state.articleList.isArticleListLoading = true;
        });
        builder.addCase(exports.fetchArticleList.fulfilled, (state, action) => {
            state.articleList.isArticleListLoading = false;
            state.articleList.articleList = action === null || action === void 0 ? void 0 : action.payload;
        });
        builder.addCase(exports.fetchArticleList.rejected, (state, action) => {
            state.articleList.isArticleListLoading = false;
        });
        builder.addCase(exports.fetchArticleDocumentLink.pending, (state, action) => {
            state.articleDocLink.isArticleDocLinkLoading = true;
        });
        builder.addCase(exports.fetchArticleDocumentLink.fulfilled, (state, action) => {
            state.articleDocLink.isArticleDocLinkLoading = false;
            state.articleDocLink.articleDocLink = action === null || action === void 0 ? void 0 : action.payload;
        });
        builder.addCase(exports.fetchArticleDocumentLink.rejected, (state, action) => {
            state.articleDocLink.isArticleDocLinkLoading = false;
        });
    },
});
exports.fetchknowledgehubContent = (0, toolkit_1.createAsyncThunk)(`${constants_1.KNOWLEDGE_HUB}/${constants_1.LIST_KNOWLEDGE_HUB}`, (filterOptions) => __awaiter(void 0, void 0, void 0, function* () {
    const params = (0, utils_1.filterOptionsParams)(filterOptions);
    const contentList = yield (0, api_1.get)(`${api_1.ENDPOINTS.LIST_CONTENT_ENDPOINT}${params}`, {
        baseURL: api_1.baseUrl,
    });
    return contentList;
}));
exports.fetchContentListByType = (0, toolkit_1.createAsyncThunk)(`${constants_1.KNOWLEDGE_HUB}/${constants_1.FETCH_LIST_BY_TYPE}`, (parameter) => __awaiter(void 0, void 0, void 0, function* () {
    const params = parameter.filterOptions
        ? (0, utils_1.filterOptionsParams)(parameter.filterOptions)
        : '';
    const contentList = yield (0, api_1.get)(`${api_1.ENDPOINTS.FETCH_LIST_BY_TYPE_ENDPOINT}/${parameter.contentId}${params}`, {
        baseURL: api_1.baseUrl,
    });
    return contentList;
}));
exports.fetchArticleList = (0, toolkit_1.createAsyncThunk)(`${constants_1.KNOWLEDGE_HUB}/${constants_1.FETCH_ARTICLE_LIST}`, (parameter) => __awaiter(void 0, void 0, void 0, function* () {
    const params = parameter.filterOptions
        ? (0, utils_1.filterOptionsParams)(parameter.filterOptions)
        : '';
    const contentList = yield (0, api_1.get)(`${constants_1.FETCH_ARTICLE_LIST_BY_ISSN}${params}&issn=${parameter === null || parameter === void 0 ? void 0 : parameter.issn}&search=${parameter === null || parameter === void 0 ? void 0 : parameter.searchKeyword}`, {
        baseURL: api_1.BASE_URL_NODE,
    });
    return contentList;
}));
exports.fetchArticleDocumentLink = (0, toolkit_1.createAsyncThunk)(`${constants_1.KNOWLEDGE_HUB}/${constants_1.FETCH_ARTICLE_DOCUMENT}`, (parameter) => __awaiter(void 0, void 0, void 0, function* () {
    const contentList = yield (0, api_1.get)(`${constants_1.FETCH_ARTICLE_DOCUMENT_LINK}?an=${parameter === null || parameter === void 0 ? void 0 : parameter.an}&dbid=${parameter === null || parameter === void 0 ? void 0 : parameter.dbId}`, {
        baseURL: api_1.BASE_URL_NODE,
    });
    return contentList;
    // return ARTICLE_DOCUMENT_LINK as any;
}));
exports.getContentListData = (0, toolkit_1.createSelector)((state) => state.knowledgehub, (knowledgehub) => { var _a, _b; return (_b = (_a = knowledgehub === null || knowledgehub === void 0 ? void 0 : knowledgehub.listContent) === null || _a === void 0 ? void 0 : _a.knowledgeHubContentList) === null || _b === void 0 ? void 0 : _b.data; });
exports.getViewAllListData = (0, toolkit_1.createSelector)((state) => state.knowledgehub, (knowledgehub) => { var _a, _b; return (_b = (_a = knowledgehub === null || knowledgehub === void 0 ? void 0 : knowledgehub.viewAllContent) === null || _a === void 0 ? void 0 : _a.viewAllContentList) === null || _b === void 0 ? void 0 : _b.data; });
exports.getArticleListData = (0, toolkit_1.createSelector)((state) => state.knowledgehub, (knowledgehub) => { var _a, _b; return (_b = (_a = knowledgehub === null || knowledgehub === void 0 ? void 0 : knowledgehub.articleList) === null || _a === void 0 ? void 0 : _a.articleList) === null || _b === void 0 ? void 0 : _b.data; });
exports.getCommonListingContent = (0, toolkit_1.createSelector)((state) => state.knowledgehub, (knowledgehub) => { var _a; return (_a = knowledgehub === null || knowledgehub === void 0 ? void 0 : knowledgehub.viewAllContent) === null || _a === void 0 ? void 0 : _a.commonListingData; });
const getViewAllContentById = (id, category) => (0, toolkit_1.createSelector)((state) => state.knowledgehub, (knowledgehub) => {
    var _a, _b, _c, _d, _e, _f, _g;
    let list = [];
    if (category === utils_2.API_CATEGORY_TYPES.KNOWLEDGE_HUB) {
        return (list =
            (_c = (_b = (_a = knowledgehub === null || knowledgehub === void 0 ? void 0 : knowledgehub.viewAllContent) === null || _a === void 0 ? void 0 : _a.knowledgeHubViewAllList) === null || _b === void 0 ? void 0 : _b.find((item) => (item === null || item === void 0 ? void 0 : item.id) === id)) === null || _c === void 0 ? void 0 : _c.data);
    }
    else if (category === utils_2.API_CATEGORY_TYPES.SHALINA_TV) {
        return (list = (_f = (_e = (_d = knowledgehub === null || knowledgehub === void 0 ? void 0 : knowledgehub.viewAllContent) === null || _d === void 0 ? void 0 : _d.shalinaTvViewAllList) === null || _e === void 0 ? void 0 : _e.find((item) => (item === null || item === void 0 ? void 0 : item.id) === id)) === null || _f === void 0 ? void 0 : _f.data);
    }
    else if (category === utils_2.API_CATEGORY_TYPES.LEARNING_CENTER) {
        return (list = (_g = knowledgehub === null || knowledgehub === void 0 ? void 0 : knowledgehub.viewAllContent) === null || _g === void 0 ? void 0 : _g.learningCenterViewAllList);
    }
    return [];
});
exports.getViewAllContentById = getViewAllContentById;
const getLearningCenterTabContentByEventId = (eventType) => (0, toolkit_1.createSelector)((state) => state.knowledgehub, (knowledgehub) => {
    var _a, _b;
    let list = [];
    return (list =
        (_b = (_a = knowledgehub === null || knowledgehub === void 0 ? void 0 : knowledgehub.viewAllContent) === null || _a === void 0 ? void 0 : _a.learningCenterTabList[eventType]) !== null && _b !== void 0 ? _b : []);
});
exports.getLearningCenterTabContentByEventId = getLearningCenterTabContentByEventId;
const getArticleSearchKeyword = () => (0, toolkit_1.createSelector)((state) => state.knowledgehub, (knowledgehub) => {
    return knowledgehub === null || knowledgehub === void 0 ? void 0 : knowledgehub.searchArticleKeyword;
});
exports.getArticleSearchKeyword = getArticleSearchKeyword;
_a = knowledgeHubSlice.actions, exports.updateBookmarkState = _a.updateBookmarkState, exports.updateBookmarkStateForContentList = _a.updateBookmarkStateForContentList, exports.updateKnowledgeHubViewAllList = _a.updateKnowledgeHubViewAllList, exports.updateShalinaTvViewAllList = _a.updateShalinaTvViewAllList, exports.updateLearningCenterTabList = _a.updateLearningCenterTabList, exports.updateLearningCenterViewAllList = _a.updateLearningCenterViewAllList, exports.updateArticleSearchKeyword = _a.updateArticleSearchKeyword;
exports.default = knowledgeHubSlice.reducer;
