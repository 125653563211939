import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { AppConstant, translate } from '@shalina-app/shared';

import styles from './achievements.module.scss';
import {
  AchievementsPagePropsType,
  MembershipBadgePath,
  PointsPath,
  Recognition,
} from '.';
import { BarLoader } from '../../../../components';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const AchievementsPage: React.FC<AchievementsPagePropsType> = ({
  earnedPoints,
  achieveMilestone,
  milestoneToReach,
  isLastMileStone,
}) => {
  const t = translate();

  const [value, setValue] = useState(0);
  const [apiCall, setApiCall] = useState(false);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <div className={styles.achievementTabs}>
        <div className={styles.loaderContainer}>
          {apiCall && (
            <div className={styles.loaderWrapper}>
              <BarLoader />
            </div>
          )}
        </div>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='Detail Tabs'
          className={styles.custTabs}
        >
          <Tab
            label={t(AppConstant.COMMON.MEMBERSHIP_AND_BENEFITS)}
            {...a11yProps(0)}
          />
          <Tab label={t(AppConstant.PROFILE.RECOGNITION)} {...a11yProps(1)} />
          <Tab label={t(AppConstant.COMMON.POINTS)} {...a11yProps(2)} />
        </Tabs>
        <div className={styles.custTabContent}>
          <CustomTabPanel value={value} index={0}>
            <MembershipBadgePath
              earnedPoints={earnedPoints}
              achieveMilestone={achieveMilestone}
              milestoneToReach={milestoneToReach}
              apiCall={apiCall}
              setApiCall={setApiCall}
              isLastMileStone={isLastMileStone}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Recognition />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <PointsPath apiCall={apiCall} setApiCall={setApiCall} />
          </CustomTabPanel>
        </div>
      </div>
    </>
  );
};
