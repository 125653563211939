import {
  ANALYTICS_PAGE_NAMES,
  API_CATEGORY_TYPES,
  CONTENT_TYPE_ID,
  CUSTOM_ANALYTICS_EVENT_NAMES,
  getAnalyticPageNameByCategory,
  getAnalyticsUserDetails,
  getFilterCategoriesOptionsValues,
  getLoggedInUserInfo,
  logEvent,
  PageNames,
  PlatformNames,
  useAppSelector,
} from '@shalina-app/shared';
import React, { useEffect } from 'react';

import { pageNames } from '../../app.constants';
import { appInsights } from '../../applicationInsight';
import { URLConstant } from '../../routes/urls';
import {
  retrieveData,
  STORAGE_CONSTANTS,
  useNavigatePageUtils,
  UserInterface,
} from '../../utils';
import { LearningCenterProps } from './learningCenter.interface';

export const LearningCenterPage: React.FC<LearningCenterProps> = () => {
  const { urls } = URLConstant;
  const navigatePage = useNavigatePageUtils();
  const userInfo = useAppSelector(getLoggedInUserInfo);
  // const [filterCall, setFilterCall] = useState(false);
  // const [filterOptions, setFilterOptions] = useState<SortFilterOptions>({
  //   category: API_CATEGORY_TYPES.LEARNING_CENTER,
  //   contentType: "",
  //   interest: "",
  //   sort: "",
  //   speciality: "",
  //   skip: 0,
  //   take: DEFAULT_SHORT_TAKE,
  // });
  // const [apiCall, setApiCall] = useState(false);

  useEffect(() => {
    logEvent(appInsights, 'pageView', ANALYTICS_PAGE_NAMES.LEARNING_CENTER);
    if (isLoggedIn && user) {
      const userObj = userInfo || user;
      const userData = getAnalyticsUserDetails(userObj);
      logEvent(
        appInsights,
        'custom',
        CUSTOM_ANALYTICS_EVENT_NAMES.USER_MOST_PAGE_VIEWED,
        {
          userEmail: userData?.emailId,
          userMobile: userData?.mobile,
          userName: userData?.name,
          userQualification: userData?.qualification,
          userInterest: userData?.areaOfInterest,
          userSpecialization: userData?.specialization,
          userCountryOfPractice: userData?.countryOfPractice,
          userPreferredLanguage: userData?.preferredLanguage,
          moduleName: getAnalyticPageNameByCategory(API_CATEGORY_TYPES.LEARNING_CENTER) || '',
          pageName: `${getAnalyticPageNameByCategory(API_CATEGORY_TYPES.LEARNING_CENTER)} ${PageNames?.LANDING}`,
          platform: PlatformNames.WEB,
        }
      );
    }
  }, []);

  const contentTypes = useAppSelector(getFilterCategoriesOptionsValues);
  const user: UserInterface | any = retrieveData(STORAGE_CONSTANTS.USER);
  const isLoggedIn: boolean = retrieveData(STORAGE_CONSTANTS.IS_LOGGED_IN);

  useEffect(() => {
    if (contentTypes) {
      const contentId = contentTypes.find(
        (value: any) => value.unique_id === CONTENT_TYPE_ID.WEBINARS
      );
      navigateToLearningCenter(contentId?.value);
    }
  }, [contentTypes]);

  // useEffect(() => {
  //   if (apiCall) {
  //     loadAPI(filterOptions);
  //     setApiCall(false);
  //   }
  // }, [apiCall]);

  // useEffect(() => {
  //   if (filterCall) {
  //     loadAPI(filterOptions);
  //     setFilterCall(false);
  //   }
  // }, [filterCall]);

  // const loadAPI = async (filterOptions: SortFilterOptions) => {
  //   await dispatch(fetchShalinaTVContent(filterOptions));
  // };
  // const filterCategoriesOptions = useAppSelector(
  //   getFilterCategoriesOptionsValues
  // );
  // const listLearningCenterContentResponse = useAppSelector(
  //   getShalinaTVContentListData
  // );

  // const currentLanguage = useAppSelector(getCurrentLanguageForWeb);

  // const categoryList = filterCategoriesOptions?.filter(
  //   (element: {
  //     label: string;
  //     value: string;
  //     category: string;
  //     icon: string;
  //   }) => element.category === API_CATEGORY_TYPES.LEARNING_CENTER
  // );
  // // useEffect(() => {
  // //   setApiCall(true);
  // // }, [currentLanguage]);

  // const contentDataRaw =
  //   listLearningCenterContentResponse && categoryList?.length
  //     ? categoryList?.map((item: any) => {
  //         if (listLearningCenterContentResponse[`${item.value}`]) {
  //           return {
  //             title: item.label,
  //             id: item.value,
  //             data: listLearningCenterContentResponse[`${item.value}`],
  //           };
  //         } else {
  //           return null;
  //         }
  //       })
  //     : [];
  // const contentData = contentDataRaw?.filter(
  //   (content: any) => content !== null
  // );
  // const isLearningCenterContentLoading = useAppSelector(
  //   (state) => state?.shalinatv?.shalinaTV?.isListLoading
  // );
  // // const isUpdateBookmarkLoading = useAppSelector(
  // //   (state) => state?.profile?.updateBookmarks?.isUpdateBookmarkLoading
  // // );

  // const handleReadingAction = (urls: string) => {
  //   navigatePage(urls, { isDynamicUrl: false });
  // };

  const getInitialFilterOptions = () => {
    if (
      (![null, undefined, []].includes(userInfo?.areaOfInterest) ||
      ![null, undefined, []].includes(user?.areaOfInterest)) && isLoggedIn
    ) {
      const userDetails = userInfo?.areaOfInterest ?? user?.areaOfInterest;
      const areaOfInterestIds = userDetails?.map(
        (item: any) => item?.tid && String(item?.tid)
      );
      return areaOfInterestIds;
    }
  };

  const navigateToLearningCenter = async (contentId: string) => {
    if (contentId) {
      const filterOptions = await getInitialFilterOptions();
      const navigateDetails = {
        isDynamicUrl: true,
        stateParams: {
          userSelectedAreaOfInterest: filterOptions
            ? filterOptions?.toString()
            : undefined,
          parentCategory: API_CATEGORY_TYPES.LEARNING_CENTER,
          filterRetain: filterOptions ? true : false,
        },
        dynamicUrlOptions: {
          contentTypeId: contentId,
          pageName: pageNames.LearningCenter,
        },
      };
      navigatePage(urls.listingPageWithModule, navigateDetails);
    }
  };

  // const handleBookmark = (contentHeader: string) => {
  //   loadAPI(filterOptions);
  // };

  // const onApplyButtonClick = (
  //   selectedContentType: Array<string>,
  //   selectedSpecialization: Array<string>
  // ) => {
  //   // if (selectedContentType?.length || selectedSpecialization?.length) {
  //   setFilterOptions((prevOptions) => ({
  //     ...prevOptions,
  //     speciality: selectedSpecialization?.toString(),
  //     contentType: selectedContentType?.toString(),
  //   }));
  //   setFilterCall(true);
  //   // }
  // };

  // const onSortChange = (value: string) => {
  //   // if (value) {
  //   setFilterOptions((prevOptions) => ({
  //     ...prevOptions,
  //     sort: value,
  //   }));
  //   setFilterCall(true);
  //   // }
  // };

  // const onAreaOfInterest = (value: string[]) => {
  //   // if (value?.length) {
  //   setFilterOptions((prevOptions) => ({
  //     ...prevOptions,
  //     interest: value?.toString(),
  //   }));
  //   setFilterCall(true);
  //   // }
  // };

  // const onResetFilter = () => {
  //   setFilterOptions((prev) => ({
  //     ...prev,
  //     contentType: "",
  //     speciality: "",
  //   }));
  //   setFilterCall(true);
  // };

  return (
    <>
      {/* <div className={styles.listingPage}>
        <div className={styles.listingTop}>
          <div className="cust-container">
            <h1 className={styles.pageTitle}>
              {t(AppConstant.HEADER.LEARNING_CENTER_MENU)}
            </h1>
            <SortFilter
              isDark={false}
              categoryType={API_CATEGORY_TYPES.LEARNING_CENTER}
              handleApplyFilter={onApplyButtonClick}
              handleSortChange={onSortChange}
              handleAreaOfInterest={onAreaOfInterest}
              handleResetFilter={onResetFilter}
            />
          </div>
        </div>

        <div className="cust-container">
          <div className={styles.listingRow}>
            <div className={styles.listingCol8}>
              {isLearningCenterContentLoading ? (
                <CircularLoader size={50} thickness={4} />
              ) : contentData && contentData?.length > 0 ? (
                contentData?.map((item: any, index: number) => (
                  <div className={styles.learningSec}>
                    <ContentSlider
                      data={item?.data ? item?.data : []}
                      contentHeading={item?.title}
                      slidesToShow={3}
                      isViewAllExist={true}
                      pageName={pageNames.LearningCenter}
                      pageRoute={urls.learningCenterPage}
                      handleViewAllClick={() =>
                        handleViewAllClick(item?.title, item?.id)
                      }
                      ebookClassName={index % 2 == 0 ? "" : "resources"}
                      staticImagePath={true}
                    />
                  </div>
                ))
              ) : (
                <div className={styles.contentNotFound}>
                  <div className={styles.notFoundImg}>
                    <img
                      src={ImageConstant.SOURCE.emptyContent}
                      alt="not found"
                    />
                  </div>
                  <div className={styles.notFoundText}>
                    <h3>{`${t(AppConstant.HOME_PAGE.NO_TEXT)} ${t(
                      AppConstant.HOME_PAGE.DATA_TEXT
                    )} ${t(AppConstant.HOME_PAGE.FOUND_TEXT)}`}</h3>
                    <p>{t(AppConstant.HOME_PAGE.NO_DATA_FOUND_MESSAGE)}</p>
                  </div>
                </div>
              )}
            </div>
            <div className={styles.listingCol4}>
              <QuickLinkSection enumIds={["bookmark", "achievements"]} />
              <div className={styles.readingSec}>
                <h4 className={styles.title}>{`${t(
                  AppConstant.KNOWLEDGE_HUB_PAGE.DISCOVER_MORE
                )}`}</h4>
                <div className={styles.readingCard}>
                  <div className={styles.readingCardTitle}>
                    {t(
                      AppConstant.KNOWLEDGE_HUB_PAGE
                        .DISCOVER_TEXT_FOR_KNOWLEDGE_HUB_MENU
                    )}
                  </div>
                  <div className={styles.msg}>
                    {t(
                      AppConstant.KNOWLEDGE_HUB_PAGE
                        .DISCOVER_TEXT_FOR_KNOWLEDGE_HUB_MENU_SUB_SENTENCE
                    )}
                  </div>
                  <ButtonComponent
                    variant="outlined"
                    buttonText={t(
                      AppConstant.KNOWLEDGE_HUB_PAGE.EXPLORE_MORE_KNOWLEDGE_HUB
                    )}
                    onPress={() => handleReadingAction(urls.knowledgeHubPage)}
                  />
                </div>
                <div className={styles.readingCard}>
                  <div className={styles.readingCardTitle}>
                    {t(
                      AppConstant.KNOWLEDGE_HUB_PAGE.DISCOVER_SHALINA_TV_HEADER
                    )}
                  </div>
                  <div className={styles.msg}>
                    {t(
                      AppConstant.KNOWLEDGE_HUB_PAGE
                        .DISCOVER_SHALINA_TV_SUB_SENTENCE
                    )}
                  </div>
                  <ButtonComponent
                    variant="outlined"
                    buttonText={t(
                      AppConstant.KNOWLEDGE_HUB_PAGE.EXPLORE_MORE_SHALINA_TV
                    )}
                    onPress={() => handleReadingAction(urls.shalinaTv)}
                  />
                </div>
                <div className={styles.readingCard}>
                  <div className={styles.readingCardTitle}>
                    {t(
                      AppConstant.KNOWLEDGE_HUB_PAGE.DISCOVER_COMMUNITIES_HEADER
                    )}
                  </div>
                  <div className={styles.msg}>
                    {t(
                      AppConstant.KNOWLEDGE_HUB_PAGE
                        .DISCOVER_COMMUNITIES_SUB_SENTENCE
                    )}
                  </div>
                  <ButtonComponent
                    variant="outlined"
                    buttonText={`${t(
                      AppConstant.KNOWLEDGE_HUB_PAGE.EXPLORE_MORE_COMMUNITIES
                    )}`}
                    onPress={() => handleReadingAction(urls.commiunitiesPage)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};
