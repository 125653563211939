import { AppConstant, ImageConstant, translate } from '@shalina-app/shared';

import { getImageSrc } from '../../../../utils';
import styles from './members.module.scss';

export const Members: React.FC<any> = ({ memberCount, memberImages }) => {
  const t = translate();
  return (
    <div className={styles.members}>
      <div className={styles.memberImages}>
        {memberImages?.length > 0 ? (
          memberImages?.map(
            (image: any, index: number) =>
              index < 2 && (
                <span className={styles.imageWrapper}>
                  <img
                    src={
                      image
                        ? getImageSrc(image?.image_url)
                        : ImageConstant.SOURCE.defaultUserPic
                    }
                    alt='img'
                    onError={(e) =>
                      ((e.target as HTMLInputElement).src =
                        ImageConstant.SOURCE.defaultUserPic)
                    }
                  />
                </span>
              )
          )
        ) : (
          <>
            <span className={styles.imageWrapper}>
              <img src={ImageConstant.SOURCE.defaultUserPic} alt='img' />
            </span>
            {memberCount > 1 && (
              <span className={styles.imageWrapper}>
                <img src={ImageConstant.SOURCE.defaultUserPic} alt='img' />
              </span>
            )}
          </>
        )}
      </div>
      <span>
        {memberCount
          ? `${memberCount} ${
              memberCount === 1
                ? t(AppConstant.HOME_PAGE.MEMBER)
                : t(AppConstant.HOME_PAGE.MEMBERS)
            }`
          : `0 ${t(AppConstant.HOME_PAGE.MEMBER)}`}
      </span>
    </div>
  );
};
