export const pageNames = {
  SignUp: 'sign-up',
  SignIn: 'sign-in',
  Profile: 'my-profile',
  Home: 'home',
  KnowledgeHub: 'knowledge-hub',
  ShalinaTv: 'shalina-tv',
  Notification: 'notification',
  LearningCenter: 'learning-center',
  RssFeedListing: 'rss-feed-listing',
  Communities: 'communities',
  surveyPage: 'survey'
};

export const apiErrorMessageConstants = {
  OTP_INVALID_OR_EXPIRED: 'OTP_INVALID_OR_EXPIRED'
};

export const DEFAULT_TAKE = 10;
export const DEFAULT_SKIP = 10;
export const DEFAULT_SHORT_TAKE = 5;
export const FEATURED_TAKE = 20;

export const DEFAULT_ARTICLES_TAKE = 20;
export const DEFAULT_ARTICLES_SKIP = 20;

export const DEFAULT_COMMUNITY_TAKE = 5;
export const DEFAULT_COMMUNITY_SKIP = 5;

export const COMMUNITY_RECOMMENDED_SHORT_TAKE = 1;
export const MY_COMMUNITY_SHORT_TAKE = 4;

export enum homeListingRoutes {
  CONTINUE_WHERE_YOU_LEFT_OFF = 'continue-where-you-left',
  RECOMMENDED_FOR_YOU = 'recommended-for-you',
  TRENDING_NOW = 'trending-now',
  NEWLY_ADDED = 'newly-added',
  MEDICAL_NEWS = 'medical-news',
  EXPLORE_COMMUNITIES = 'explore-communities'
}

export const VIDEO_TYPES = [
  'case-study-videos',
  'conference-coverage',
  'demonstration-videos',
  'disease-awareness-videos',
  'kol-talks-expert-opinions',
  'panel-discussions',
  'therapy-updates'
];

export const ABOUT_US_PAGE_TABS = {
  WHO_WE_ARE: 0,
  OUR_JOURNEY: 1,
  EXPERTS: 2
};

export const MEDICAL_CALCULATOR_TABS = {
  CLINICAL_CRITERIA: 0,
  DECISION_TREES: 1,
  MEDICAL_EQUATIONS: 2
};

export const PROFILE_PAGE_TABS = {
  BOOKMARKS: 0,
  ACHIEVEMENTS: 2,
  REGISTERED_EVENTS: 1,
  REQUEST: 4,
  NOTIFICATION_SETTING: 3,
  ACCOUNT_SETTING: 5,
  LANGUAGE_PREFERENCE: 6,
  UPDATE_PROFILE: 7
};

export const TOAST_MESSAGE_HIDE_DURATION = {
  SUCCESS: 2000,
  FAILURE: 4000
};

export const REQUEST_MODULE = {
  TEXTBOX_CHAR_LIMIT: 255
};
