"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountIcon = void 0;
const React = __importStar(require("react"));
const AccountIcon = (props) => (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: 18, height: 18, fill: "none" }, props),
    React.createElement("path", { stroke: "#E84E1B", strokeWidth: 1.7, d: "M17.105 9.055a8.023 8.023 0 0 1-1.365 4.49 8.045 8.045 0 0 1-6.687 3.567 8.04 8.04 0 0 1-6.688-3.567A8.06 8.06 0 0 1 6.707 1.35a8.048 8.048 0 0 1 9.537 4.082 8.06 8.06 0 0 1 .861 3.624Z" }),
    React.createElement("mask", { id: "a", fill: "#fff" },
        React.createElement("path", { d: "M9.947 6.37a.895.895 0 0 1-.894.894v1.79a2.684 2.684 0 0 0 2.684-2.685h-1.79Zm-.894.894a.894.894 0 0 1-.895-.895h-1.79a2.686 2.686 0 0 0 2.685 2.686v-1.79Zm-.895-.895a.895.895 0 0 1 .895-.895v-1.79a2.683 2.683 0 0 0-2.684 2.685h1.789Zm.895-.895a.894.894 0 0 1 .894.895h1.79a2.686 2.686 0 0 0-2.684-2.685v1.79Zm-6.115 8.823-.858-.255-.139.468.318.37.68-.583Zm12.23 0 .68.583.316-.37-.139-.468-.858.255Zm-8.8-1.661h5.369v-1.79H6.369v1.79Zm0-1.79a4.473 4.473 0 0 0-4.288 3.196l1.715.51a2.688 2.688 0 0 1 2.574-1.916v-1.79Zm2.685 5.37a7.136 7.136 0 0 1-5.436-2.502L2.26 14.88a8.93 8.93 0 0 0 6.794 3.127v-1.79Zm2.684-3.58c1.214 0 2.242.807 2.573 1.917l1.715-.511a4.48 4.48 0 0 0-4.288-3.197v1.79Zm2.751 1.078a7.14 7.14 0 0 1-5.435 2.502v1.79a8.925 8.925 0 0 0 6.794-3.126l-1.359-1.166Z" })),
    React.createElement("path", { fill: "#E84E1B", d: "M9.947 6.37a.895.895 0 0 1-.894.894v1.79a2.684 2.684 0 0 0 2.684-2.685h-1.79Zm-.894.894a.894.894 0 0 1-.895-.895h-1.79a2.686 2.686 0 0 0 2.685 2.686v-1.79Zm-.895-.895a.895.895 0 0 1 .895-.895v-1.79a2.683 2.683 0 0 0-2.684 2.685h1.789Zm.895-.895a.894.894 0 0 1 .894.895h1.79a2.686 2.686 0 0 0-2.684-2.685v1.79Zm-6.115 8.823-.858-.255-.139.468.318.37.68-.583Zm12.23 0 .68.583.316-.37-.139-.468-.858.255Zm-8.8-1.661h5.369v-1.79H6.369v1.79Zm0-1.79a4.473 4.473 0 0 0-4.288 3.196l1.715.51a2.688 2.688 0 0 1 2.574-1.916v-1.79Zm2.685 5.37a7.136 7.136 0 0 1-5.436-2.502L2.26 14.88a8.93 8.93 0 0 0 6.794 3.127v-1.79Zm2.684-3.58c1.214 0 2.242.807 2.573 1.917l1.715-.511a4.48 4.48 0 0 0-4.288-3.197v1.79Zm2.751 1.078a7.14 7.14 0 0 1-5.435 2.502v1.79a8.925 8.925 0 0 0 6.794-3.126l-1.359-1.166Z" })));
exports.AccountIcon = AccountIcon;
