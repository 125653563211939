import { useEffect, useState } from 'react';
import {
  AppConstant,
  convertArrayOfObjectToString,
  ImageConstant,
  updateProfileImage,
  useAppDispatch,
  useAppSelector
} from '@shalina-app/shared';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { UserInfoSectionProps } from '.';
import { getImageSrc, getTranslationFormat } from '../../../../utils';
import styles from './userInfoSection.module.scss';

export const UserInfoSection: React.FC<UserInfoSectionProps> = ({
  userName,
  profilePic,
  qualification,
  specialization,
  percentage,
  isUpdateProfileVisible,
  handleSaveProfilePic,
  handleUpdateProfile,
  closePopup
}) => {
  const dispatch = useAppDispatch();
  /* edit profile modal start */
  const keyImg = Math.random() * 1000;
  const updateProfilePic = useAppSelector(
    (state) => state?.profile?.updateProfilePic?.profilePic
  );
  const updatedUser = useAppSelector((state) => state?.user?.user);

  const [editProfilePopup, setEditProfilePopupn] = useState(false);
  const [profileImg, setProfileImg] = useState('');
  const [binaryData, setBinaryData] = useState<any>();
  const [removePic, setRemovePic] = useState(false);
  const [profileImage, setProfileImage] = useState('');
  const [image, setImage] = useState('');

  const handleEditProfile = () => {
    setEditProfilePopupn(true);
  };
  const handleEditProfileClose = () => {
    setEditProfilePopupn(false);
    setImage('');
  };

  useEffect(() => {
    if (profilePic) {
      setProfileImage(profilePic);
      setImage(`${getImageSrc(profilePic)}?timestamp=${keyImg}`); // adding uniqueness to the url, so it fetch updated image every time.
    }
  }, [profilePic]);

  /* edit profile modal end */

  const handleProfilePicChange = (e: any) => {
    const file = e?.target?.files[0];
    const src = file && URL.createObjectURL(file);
    setImage(src);
    const reader = new FileReader();
    reader.onload = (e) => {
      const binaryData = e?.target?.result; // Get the file content as ArrayBuffer or data URL
      setBinaryData(binaryData);
    };

    reader.readAsArrayBuffer(file);
    setRemovePic(false);
  };

  const { EditIcon } = ImageConstant.SVG_ICONS;

  const handleRemoveProfilePic = () => {
    setImage('');
    setRemovePic(true);
  };

  // useEffect(() => {
  //   if (profileImage) {
  //     setImage(profileImage);
  //   }
  // }, [profileImage]);

  const handleSaveClick = () => {
    dispatch(updateProfileImage(ImageConstant.SOURCE.defaultUserPic));
    handleEditProfileClose();
    handleSaveProfilePic(binaryData, removePic);
    // if (removePic) {
    //   setProfileImage("");
    // } else {
    //   setProfileImage(image);
    // }
  };

  const qualificationString = convertArrayOfObjectToString(
    qualification,
    'title'
  );
  const specializationString = convertArrayOfObjectToString(
    specialization,
    'title'
  );

  return (
    <>
      <div className={styles.userDetails}>
        <div className={styles.userDetailWrap}>
          <div className={styles.userImg}>
            <img
              key={keyImg}
              src={
                profileImage
                  ? `${getImageSrc(profileImage)}?timestamp=${keyImg}`
                  : ImageConstant.SOURCE.defaultUserPic
              }
              alt='point img'
              onError={(e) =>
                ((e.target as HTMLInputElement).src =
                  ImageConstant.SOURCE.defaultUserPic)
              }
            />
            <span className={styles.editProfileImg} onClick={handleEditProfile}>
              <EditIcon />
            </span>
          </div>
          <div className={styles.userInfo}>
            <div className={styles.userName}>
              <span>Dr.</span> {userName}
            </div>
            <div className={styles.userDesignation}>
              <span>{qualificationString ? qualificationString : null}</span>
              {specializationString ? specializationString : null}
            </div>

            {/* <div className={styles.userRole}>General Practitioner</div> */}
            <div className={styles.profileProgress}>
              <div className={styles.progressLabel}>
                {getTranslationFormat(
                  AppConstant.PROFILE.PROFILE_COMPLETION_TEXT
                )}
                <span>{percentage}%</span>
              </div>
              <div className={styles.profileProgressBar}>
                <span
                  className={styles.progressCompleted}
                  style={{ width: `${percentage}%` }}
                ></span>
              </div>
            </div>
            <button
              className={`${styles.updateCta} ${
                !isUpdateProfileVisible && styles.hide
              }`}
              onClick={handleUpdateProfile}
            >
              {getTranslationFormat(AppConstant.PROFILE.UPDATE_PROFILE_CTA)}
            </button>
          </div>
        </div>
      </div>
      <Dialog
        open={editProfilePopup}
        onClose={handleEditProfileClose}
        className={styles.editImgModal}
      >
        <DialogContent>
          <h4 className={styles.title}>
            {getTranslationFormat(
              AppConstant.PROFILE.EDIT_PROFILE_PICTURE_TEXT
            )}
          </h4>
          <div className={styles.imgCta}>
            <div className={styles.profileImg}>
              <img
                src={image ? image : ImageConstant.SOURCE.defaultUserPic}
                alt='profile img'
                onError={(e) =>
                  ((e.target as HTMLInputElement).src =
                    ImageConstant.SOURCE.defaultUserPic)
                }
              />
            </div>
            <div className={styles.ctas}>
              <label className={styles.uploadCta}>
                <input
                  type='file'
                  accept='image/*'
                  onChange={(e: any) => handleProfilePicChange(e)}
                />
                {getTranslationFormat(AppConstant.PROFILE.UPLOAD_CTA)}
              </label>
              <button onClick={handleRemoveProfilePic}>
                {getTranslationFormat(AppConstant.PROFILE.REMOVE_CTA)}
              </button>
            </div>
          </div>
          <div className={styles.actionCtas}>
            <button onClick={handleEditProfileClose}>
              {getTranslationFormat(AppConstant.PROFILE.CANCEL_CTA)}
            </button>
            <button className={styles.save} onClick={handleSaveClick}>
              {getTranslationFormat(AppConstant.PROFILE.SAVE_CTA)}
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
