import {
  AppConstant,
  deleteRecentSearch,
  getListRecenetSearch,
  listRecentSearch,
  ListRecentSearchItemProps,
  translate,
  useAppSelector
} from '@shalina-app/shared';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { DEFAULT_TAKE } from '../../../../app.constants';
import { URLConstant } from '../../../../routes';
import {
  retrieveData,
  STORAGE_CONSTANTS,
  useNavigatePageUtils
} from '../../../../utils';
import { ISearchHistory, SearchBarPropsType } from './searchBar.interface';
import styles from './searchBar.module.scss';

export const SearchBar: React.FC<SearchBarPropsType> = () => {
  const t = translate();
  const ref: any = useRef(null);

  const isLoggedIn: boolean = retrieveData(STORAGE_CONSTANTS.IS_LOGGED_IN);
  const location = useLocation();
  const pathName = location?.pathname;

  const dispatch = useDispatch();
  const navigatePage = useNavigatePageUtils();
  const { urls } = URLConstant;
  const today = new Date();
  today?.setHours(0, 0, 0, 0);

  const [searchKeyword, setSearchKeyword] = useState('');
  const [toggleRecentSearch, setToggleRecentSearch] = useState(false);

  useEffect(() => {
    const searchPageUrl = urls.searchPage;

    if (pathName && !pathName?.includes(searchPageUrl?.split('/:')?.[0])) {
      setSearchKeyword('');
    }
  }, [pathName]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref?.current && !ref?.current?.contains(event?.target)) {
        setToggleRecentSearch(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const handleSearch = async () => {
    //LOGIC TO SEND THE KEYWORD IN THE PARAMS
    const keyWord = searchKeyword?.trim();
    setToggleRecentSearch(false);

    if (keyWord) {
      const navigateDetails = {
        stateParams: {
          searchWord: keyWord,
          pathName: pathName
        },
        isDynamicUrl: false
      };
      navigatePage(urls.searchPage, navigateDetails);
    }
  };

  const loadApi = () => {
    if (isLoggedIn) {
      setToggleRecentSearch(true);
      dispatch(listRecentSearch({ skip: 0, take: DEFAULT_TAKE }) as any);
    }
  };

  const listOfSearch = useAppSelector(
    getListRecenetSearch
  ) as ListRecentSearchItemProps;
  const history = listOfSearch?.history?.length
    ? listOfSearch.history
    : ([] as ISearchHistory[]);

  const handleSearchBarClick = () => {
    // setToggleRecentSearch(true);
    if (toggleRecentSearch) {
      setToggleRecentSearch(false);
    } else {
      loadApi();
    }
  };

  const searchOnClick = (event: React.BaseSyntheticEvent) => {
    // setToggleRecentSearch(true);
    const value = event.target.value;
    setSearchKeyword(value);
  };

  const clickEventFromRecentSearchList = (searchBox: any) => {
    const keyWord = searchBox?.trim();
    setSearchKeyword(keyWord);
    // setSearchKeyword(searchBox);
    setToggleRecentSearch(false);
    const navigateDetails = {
      stateParams: {
        searchWord: keyWord,
        pathName: pathName
      },
      isDynamicUrl: false
    };
    navigatePage(urls.searchPage, navigateDetails);
    // setToggleRecentSearch(false);
  };

  const onDelete = async (id: any) => {
    await dispatch(deleteRecentSearch(id) as any);
    await loadApi();
  };

  // const close = () => {
  //   setToggleRecentSearch(false);
  // };

  const [isActive, setActive] = useState<boolean>(false);
  const mobileSearchToggle = () => {
    setActive(!isActive);
  };

  return (
    <>
      <button
        type='button'
        className={styles.mobileIcon}
        onClick={mobileSearchToggle}
      >
        <svg
          width='18'
          height='17'
          viewBox='0 0 18 17'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M2 7.88607C2 4.63477 4.63413 2 7.88235 2C11.1306 2 13.7647 4.63477 13.7647 7.88607C13.7647 11.1374 11.1306 13.7721 7.88235 13.7721C4.63413 13.7721 2 11.1374 2 7.88607ZM7.88235 0C3.52854 0 0 3.53122 0 7.88607C0 12.2409 3.52854 15.7721 7.88235 15.7721C9.96855 15.7721 11.8653 14.9614 13.2751 13.6377L16.1743 16.5384C16.5647 16.9291 17.1978 16.9292 17.5885 16.5388C17.9791 16.1484 17.9793 15.5152 17.5888 15.1246L14.5538 12.0879C15.3209 10.8714 15.7647 9.43054 15.7647 7.88607C15.7647 3.53122 12.2362 0 7.88235 0Z'
            fill='#002E5B'
          />
        </svg>
      </button>
      <div className={`${styles.navSearchWrapper} ${isActive && styles.open}`}>
        <div className={styles.closeSearch}>
          <button onClick={mobileSearchToggle}>&times;</button>
        </div>
        <div className={styles.navSearch}>
          <div onClick={handleSearch}>
            <svg
              width='18'
              height='17'
              viewBox='0 0 18 17'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M2 7.88607C2 4.63477 4.63413 2 7.88235 2C11.1306 2 13.7647 4.63477 13.7647 7.88607C13.7647 11.1374 11.1306 13.7721 7.88235 13.7721C4.63413 13.7721 2 11.1374 2 7.88607ZM7.88235 0C3.52854 0 0 3.53122 0 7.88607C0 12.2409 3.52854 15.7721 7.88235 15.7721C9.96855 15.7721 11.8653 14.9614 13.2751 13.6377L16.1743 16.5384C16.5647 16.9291 17.1978 16.9292 17.5885 16.5388C17.9791 16.1484 17.9793 15.5152 17.5888 15.1246L14.5538 12.0879C15.3209 10.8714 15.7647 9.43054 15.7647 7.88607C15.7647 3.53122 12.2362 0 7.88235 0Z'
                fill='#002E5B'
              />
            </svg>
          </div>
          <input
            type='text'
            name='search'
            value={searchKeyword}
            placeholder={t(AppConstant.COMMON.SEARCH)}
            onClick={handleSearchBarClick}
            onChange={searchOnClick}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
          />
          {toggleRecentSearch ? (
            <div ref={ref}>
              {history?.length !== 0 ? (
                <div className={styles.searchResult}>
                  <span>
                    <p className={styles.searchTitle}>
                      {t(AppConstant.HOME_PAGE.RECENT_SEARCH)}
                    </p>
                  </span>
                  {history?.length ? (
                    history?.map((item: ISearchHistory) => (
                      <div className={styles.recentList} key={item?.id}>
                        <div>
                          <span
                            onClick={() =>
                              clickEventFromRecentSearchList(
                                item?.description as string
                              )
                            }
                          >
                            {item?.description}
                          </span>
                          <button onClick={() => onDelete(item?.id)}>
                            &times;
                          </button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};
