"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfileIcon = void 0;
const React = __importStar(require("react"));
const ProfileIcon = (props) => (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: 36, height: 36, fill: "none" }, props),
    React.createElement("mask", { id: "a", width: 36, height: 36, x: 0, y: 0, maskUnits: "userSpaceOnUse", style: {
            maskType: "alpha",
        } },
        React.createElement("circle", { cx: 18, cy: 18, r: 18, fill: "#002E5B" })),
    React.createElement("g", { mask: "url(#a)" },
        React.createElement("path", { fill: "#002E5B", d: "M-11.701 0h59.4v89.1h-59.4z" })),
    React.createElement("path", { fill: "#fff", d: "M18 9c-.928 0-1.819.38-2.475 1.054A3.653 3.653 0 0 0 14.5 12.6c0 .955.369 1.87 1.025 2.546A3.452 3.452 0 0 0 18 16.2c.928 0 1.819-.38 2.475-1.054A3.653 3.653 0 0 0 21.5 12.6c0-.955-.369-1.87-1.025-2.546A3.451 3.451 0 0 0 18 9Zm-1.75 3.6c0-.477.184-.935.513-1.273A1.726 1.726 0 0 1 18 10.8c.464 0 .91.19 1.237.527.329.338.513.796.513 1.273 0 .477-.184.935-.513 1.273A1.726 1.726 0 0 1 18 14.4c-.464 0-.91-.19-1.237-.527a1.826 1.826 0 0 1-.513-1.273Zm6.125 5.4h-8.75c-.696 0-1.364.285-1.856.79A2.74 2.74 0 0 0 11 20.7c0 2.009.803 3.618 2.121 4.707C14.418 26.478 16.157 27 18 27c1.843 0 3.582-.522 4.879-1.593C24.195 24.318 25 22.709 25 20.7a2.74 2.74 0 0 0-.769-1.91 2.589 2.589 0 0 0-1.856-.79Zm-8.75 1.8h8.75c.232 0 .455.095.619.264a.913.913 0 0 1 .256.636c0 1.47-.569 2.561-1.466 3.303-.921.76-2.244 1.197-3.784 1.197-1.54 0-2.863-.437-3.784-1.197-.897-.742-1.466-1.832-1.466-3.303 0-.239.092-.468.256-.636a.863.863 0 0 1 .619-.264Z" })));
exports.ProfileIcon = ProfileIcon;
