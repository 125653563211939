import {
  API_CATEGORY_TYPES,
  AppConstant,
  fetchknowledgehubContent,
  getContentListData,
  getFilterCategoriesOptionsValues,
  ImageConstant,
  SortFilterOptions,
  translate,
  useAppDispatch,
  useAppSelector,
  SLIDE_TO_SHOW_WEB,
  getCurrentLanguageForWeb,
  KNOWLEDGE_HUB_SEQUENCE,
  updateKnowledgeHubViewAllList,
  ANALYTICS_PAGE_NAMES,
  FILTER_BY_TABS_ENUM,
  getLoggedInUserInfo,
  logEvent,
  CUSTOM_ANALYTICS_EVENT_NAMES,
  PageNames,
  PlatformNames,
  getAnalyticPageNameByCategory,
  getAnalyticsUserDetails,
} from '@shalina-app/shared';
import React, { useEffect, useState } from 'react';

import { KnowledgeHubPagePropsType } from '.';
import { DEFAULT_SHORT_TAKE, pageNames } from '../../app.constants';
import { appInsights } from '../../applicationInsight';
import {
  BarLoader,
  ButtonComponent,
  ContentSlider,
  QuickLinkSection,
  SortFilter,
} from '../../components';
import { URLConstant } from '../../routes/urls';
import {
  getPageNameByCategory,
  retrieveData,
  STORAGE_CONSTANTS,
  useNavigatePageUtils,
  UserInterface,
} from '../../utils';
import styles from './knowledgeHub.module.scss';

export const KnowledgeHubPage: React.FC<KnowledgeHubPagePropsType> = () => {
  const t = translate();
  const { urls } = URLConstant;
  const dispatch = useAppDispatch();
  const navigatePage = useNavigatePageUtils();
  const [filterCall, setFilterCall] = useState(false);
  const [filterOptions, setFilterOptions] = useState<SortFilterOptions>({
    category: API_CATEGORY_TYPES.KNOWLEDGE_HUB,
    contentType: '',
    interest: '',
    sort: '',
    speciality: '',
    skip: 0,
    take: DEFAULT_SHORT_TAKE,
  });
  const [apiCall, setApiCall] = useState(false);
  const [selectedAreaOfInterest, setSelectedAreaOfInterest] = useState([]);
  const userInfo = useAppSelector(getLoggedInUserInfo);
  const user: UserInterface | any = retrieveData(STORAGE_CONSTANTS.USER);
  const isLoggedIn: boolean = retrieveData(STORAGE_CONSTANTS.IS_LOGGED_IN);

  useEffect(() => {
    window.scrollTo(0, 0);
    logEvent(appInsights, 'pageView', ANALYTICS_PAGE_NAMES.KNOWLEDGE_HUB);
    if (isLoggedIn && user) {     
      const userObj = userInfo || user;
      const userData = getAnalyticsUserDetails(userObj);
      logEvent(
        appInsights,
        'custom',
        CUSTOM_ANALYTICS_EVENT_NAMES.USER_MOST_PAGE_VIEWED,
        {
          userEmail: userData?.emailId,
          userMobile: userData?.mobile,
          userName: userData?.name,
          userQualification: userData?.qualification,
          userInterest: userData?.areaOfInterest,
          userSpecialization: userData?.specialization,
          userCountryOfPractice: userData?.countryOfPractice,
          userPreferredLanguage: userData?.preferredLanguage,
          moduleName: getAnalyticPageNameByCategory(API_CATEGORY_TYPES.KNOWLEDGE_HUB) || '',
          pageName: `${getAnalyticPageNameByCategory(API_CATEGORY_TYPES.KNOWLEDGE_HUB)} ${PageNames?.LANDING}`,
          platform: PlatformNames.WEB,
        }
      );
    }
    setInitialValue();
    setApiCall(true);
  }, []);

  useEffect(() => {
    if (apiCall) {
      loadAPI(filterOptions);
    }
  }, [apiCall]);

  useEffect(() => {
    if (filterCall) {
      loadAPI(filterOptions);
    }
  }, [filterCall]);

  const loadAPI = async (filterOptions: SortFilterOptions) => {
    await dispatch(fetchknowledgehubContent(filterOptions));
    setApiCall(false);
    setFilterCall(false);
  };

  const setInitialValue = () => {
    if (
      (![null, undefined, []].includes(userInfo?.areaOfInterest) ||
        ![null, undefined, []].includes(user?.areaOfInterest)) &&
      isLoggedIn
    ) {
      const userDetails = userInfo?.areaOfInterest ?? user?.areaOfInterest;
      const areaOfInterestIds = userDetails?.map(
        (item: any) => item?.tid && String(item?.tid)
      );
      setSelectedAreaOfInterest(areaOfInterestIds);
    }
  };

  const filterCategoriesOptions = useAppSelector(
    getFilterCategoriesOptionsValues
  );
  const listKnowledgeHubContentResponse = useAppSelector(getContentListData);
  const currentLanguage = useAppSelector(getCurrentLanguageForWeb);

  // useEffect(() => {
  //   setApiCall(true);
  // }, [currentLanguage]);

  const categoryList = filterCategoriesOptions?.filter(
    (element: {
      label: string;
      value: string;
      category: string;
      icon: string;
    }) => element.category === API_CATEGORY_TYPES.KNOWLEDGE_HUB
  );

  const contentDataRaw =
    listKnowledgeHubContentResponse && categoryList?.length
      ? categoryList?.map((item: any) => {
          if (listKnowledgeHubContentResponse[`${item.value}`]) {
            return {
              title: item?.label,
              id: item?.value,
              unique_id: item?.unique_id,
              data: listKnowledgeHubContentResponse[`${item?.value}`],
            };
          }
          return null;
        })
      : [];

  const contentData = contentDataRaw?.filter(
    (content: any) => content !== null
  );

  useEffect(() => {
    if (contentData) {
      dispatch(updateKnowledgeHubViewAllList(contentData));
    }
  }, [contentData]);

  const isListKnowledgeHubContentLoading = useAppSelector(
    (state) => state?.knowledgehub?.listContent?.isListLoading
  );

  const loader =
    !contentData || contentData?.length === 0
      ? isListKnowledgeHubContentLoading
      : false;
  // const isUpdateBookmarkLoading = useAppSelector((state) => state?.profile?.updateBookmarks?.isUpdateBookmarkLoading);

  const handleReadingAction = (urls: string) => {
    navigatePage(urls, { isDynamicUrl: false });
  };

  const handleViewAllClick = (contentType: string, contentTypeId: string) => {
    if (contentTypeId) {
      const navigateDetails = {
        stateParams: {
          parentSelectedAreaOfInterest: filterOptions?.interest,
          parentCategory: API_CATEGORY_TYPES.KNOWLEDGE_HUB,
          filterRetain: true,
          userSelectedAreaOfInterest:
            filterOptions?.interest || selectedAreaOfInterest?.toString(),
        },
        isDynamicUrl: true,
        dynamicUrlOptions: {
          contentTypeId: contentTypeId,
          pageName: pageNames.KnowledgeHub,
        },
      };
      navigatePage(urls.listingPageWithModule, navigateDetails);
    }
  };

  const onApplyButtonClick = (
    selectedContentType: Array<string>,
    selectedSpecialization: Array<string>
  ) => {
    // if (selectedContentType?.length || selectedSpecialization?.length) {
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      speciality: selectedSpecialization?.toString(),
      contentType: selectedContentType?.toString(),
    }));
    setFilterCall(true);
    // }
  };

  const onSortChange = (value: string) => {
    // if (value) {
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      sort: value,
    }));
    setFilterCall(true);
    // }
  };

  const onAreaOfInterest = (value: string[]) => {
    // if (value?.length) {
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      interest: value?.toString(),
    }));
    setFilterCall(true);
    // }
  };

  const onResetFilter = () => {
    setFilterOptions((prevValue) => ({
      ...prevValue,
      contentType: '',
      speciality: '',
    }));
    setFilterCall(true);
  };

  contentData?.sort((a: any, b: any) => {
    return (
      KNOWLEDGE_HUB_SEQUENCE?.indexOf(a?.unique_id) -
      KNOWLEDGE_HUB_SEQUENCE?.indexOf(b?.unique_id)
    );
  });
  return (
    <>
      <div className={styles.listingPage}>
        <div className={styles.listingTop}>
          <div className='cust-container'>
            <h1 className={styles.pageTitle}>
              {t(AppConstant.KNOWLEDGE_HUB_PAGE.KNOWLEDGE_HUB_TEXT)}
            </h1>
            <div className={styles.landingFilter}>
              <SortFilter
                isDark={false}
                categoryType={API_CATEGORY_TYPES.KNOWLEDGE_HUB}
                handleApplyFilter={onApplyButtonClick}
                handleSortChange={onSortChange}
                handleAreaOfInterest={onAreaOfInterest}
                handleResetFilter={onResetFilter}
                tabsEnum={[
                  FILTER_BY_TABS_ENUM.CONTENT_TYPE,
                  FILTER_BY_TABS_ENUM.SPECIALITY,
                ]}
                selectedAreaOfInterest={selectedAreaOfInterest}
              />
            </div>
          </div>
        </div>
        {(filterCall || apiCall) && <BarLoader />}

        <div className='cust-container'>
          <div className={styles.listingRow}>
            <div className={styles.listingCol8}>
              {/* {loader ? (
                <CircularLoader size={50} thickness={4} />
              ) :  */}
              {contentData && contentData?.length > 0
                ? contentData?.map((item: any, index: number) => (
                    <div className={styles.kHubSec}>
                      <ContentSlider
                        data={item?.data ? item?.data : []}
                        contentHeading={item?.title}
                        slidesToShow={SLIDE_TO_SHOW_WEB.DEFAULT}
                        isViewAllExist={true}
                        pageName={pageNames.KnowledgeHub}
                        pageRoute={urls.knowledgeHubPage}
                        handleViewAllClick={() =>
                          handleViewAllClick(item?.title, item?.id)
                        }
                        ebookClassName={index % 2 === 0 ? '' : 'resources'}
                      />
                    </div>
                  ))
                : !filterCall &&
                  !apiCall && (
                    <div className={styles.contentNotFound}>
                      <div className={styles.notFoundImg}>
                        <img
                          src={ImageConstant.SOURCE.emptyContent}
                          alt='not found'
                        />
                      </div>
                      <div className={styles.notFoundText}>
                        <h3>{`${t(AppConstant.HOME_PAGE.NO_TEXT)} ${t(
                          AppConstant.HOME_PAGE.DATA_TEXT
                        )} ${t(AppConstant.HOME_PAGE.FOUND_TEXT)}`}</h3>
                        <p>{t(AppConstant.HOME_PAGE.NO_DATA_FOUND_MESSAGE)}</p>
                      </div>
                    </div>
                  )}
            </div>
            <div className={styles.listingCol4}>
              <QuickLinkSection />
              <div className={styles.readingSec}>
                <h4 className={styles.title}>{`${t(
                  AppConstant.KNOWLEDGE_HUB_PAGE.DISCOVER_MORE
                )}`}</h4>
                <div className={styles.readingCard}>
                  <div className={styles.readingCardTitle}>{`${t(
                    AppConstant.KNOWLEDGE_HUB_PAGE.DISCOVER_SHALINA_TV_HEADER
                  )}`}</div>
                  <div className={styles.msg}>{`${t(
                    AppConstant.KNOWLEDGE_HUB_PAGE
                      .DISCOVER_SHALINA_TV_SUB_SENTENCE
                  )}`}</div>
                  <ButtonComponent
                    variant='outlined'
                    buttonText={`${t(
                      AppConstant.KNOWLEDGE_HUB_PAGE.EXPLORE_MORE_SHALINA_TV
                    )}`}
                    onPress={() => handleReadingAction(urls.shalinaTv)}
                  />
                </div>
                <div className={styles.readingCard}>
                  <div className={styles.readingCardTitle}>{`${t(
                    AppConstant.KNOWLEDGE_HUB_PAGE
                      .DISCOVER_LEARNING_CENTER_HEADER
                  )}`}</div>
                  <div className={styles.msg}>{`${t(
                    AppConstant.KNOWLEDGE_HUB_PAGE
                      .DISCOVER_LEARNING_CENTER_SUB_SENTENCE
                  )}`}</div>
                  <ButtonComponent
                    variant='outlined'
                    buttonText={`${t(
                      AppConstant.KNOWLEDGE_HUB_PAGE
                        .EXPLORE_MORE_LEARNING_CENTER
                    )}`}
                    onPress={() => handleReadingAction(urls.learningCenterPage)}
                  />
                </div>
                {/* <div className={styles.readingCard}>
                  <div className={styles.readingCardTitle}>{`${t(
                    AppConstant.KNOWLEDGE_HUB_PAGE.DISCOVER_COMMUNITIES_HEADER
                  )}`}</div>
                  <div className={styles.msg}>{`${t(
                    AppConstant.KNOWLEDGE_HUB_PAGE
                      .DISCOVER_COMMUNITIES_SUB_SENTENCE
                  )}`}</div>
                  <ButtonComponent
                    variant="outlined"
                    buttonText={`${t(
                      AppConstant.KNOWLEDGE_HUB_PAGE.EXPLORE_MORE_COMMUNITIES
                    )}`}
                    onPress={() => handleReadingAction(urls.commiunitiesPage)}
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
