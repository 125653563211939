"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.differenceBetweenTimestamp = exports.appendTrailingZeros = void 0;
const appendTrailingZeros = (value) => {
    if (value > 9) {
        return value;
    }
    else {
        return `0${value}`;
    }
};
exports.appendTrailingZeros = appendTrailingZeros;
const differenceBetweenTimestamp = (end, start) => {
    const date1 = new Date(end);
    const date2 = new Date(start);
    // Calculate the difference in milliseconds
    const differenceMs = date1.getTime() - date2.getTime();
    // Convert milliseconds to a positive value (in case of negative due to different time zones)
    const absoluteDifferenceMs = Math.abs(differenceMs);
    // Convert milliseconds to seconds, minutes, hours, and days
    let seconds = Math.floor(absoluteDifferenceMs / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    // Calculate remaining minutes, seconds, and hours
    const remainingMinutes = minutes % 60;
    seconds = seconds % 60;
    hours = hours % 24;
    if (hours > 0) {
        return `${(0, exports.appendTrailingZeros)(hours)}:${(0, exports.appendTrailingZeros)(remainingMinutes)}:${(0, exports.appendTrailingZeros)(seconds)}`;
    }
    else {
        return `00:${(0, exports.appendTrailingZeros)(remainingMinutes)}:${(0, exports.appendTrailingZeros)(seconds)}`;
    }
};
exports.differenceBetweenTimestamp = differenceBetweenTimestamp;
