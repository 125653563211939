"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AchievementsSmallIcon = void 0;
const React = __importStar(require("react"));
const AchievementsSmallIcon = (props) => (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: 15, height: 12, fill: "none", viewBox: "0 0 20 16" }, props),
    React.createElement("path", { fill: "#E84E1B", stroke: "#fff", strokeWidth: 0.17, d: "M16.69 1.92v.085h1.697c.405 0 .794.16 1.08.444.287.284.448.67.448 1.071V4.8A3.425 3.425 0 0 1 18.9 7.228a3.482 3.482 0 0 1-2.448 1.007H16.31l-.02.056c-.834 2.284-2.872 3.998-5.335 4.354l-.073.01v1.51h1.698c.234 0 .459.092.624.257a.871.871 0 0 1 0 1.236.887.887 0 0 1-.624.257H7.419a.887.887 0 0 1-.624-.256.871.871 0 0 1 0-1.238.887.887 0 0 1 .624-.256h1.698V12.658l-.073-.01a6.73 6.73 0 0 1-3.288-1.44 6.65 6.65 0 0 1-2.065-2.917l-.02-.056h-.123A3.482 3.482 0 0 1 1.1 7.228 3.425 3.425 0 0 1 .085 4.8V3.52a1.509 1.509 0 0 1 .943-1.4 1.54 1.54 0 0 1 .585-.115H3.31V1.6c0-.402.16-.787.447-1.071a1.535 1.535 0 0 1 1.08-.444h10.323c.406 0 .794.16 1.08.444.287.284.448.67.448 1.071v.32ZM1.85 4.8c0 .39.137.768.386 1.069a1.7 1.7 0 0 0 .98.583l.11.022-.008-.112a3.75 3.75 0 0 1-.007-.282V3.755H1.85V4.8Zm3.311-2.965h-.085V6.08a4.852 4.852 0 0 0 1.456 3.468 4.957 4.957 0 0 0 3.506 1.417c2.695-.02 4.886-2.244 4.886-4.957V1.835H5.16Zm11.519 4.53-.006.109.107-.021a1.7 1.7 0 0 0 .982-.583c.25-.302.386-.68.386-1.07V3.755h-1.46v2.253c0 .12-.003.239-.01.357Z" })));
exports.AchievementsSmallIcon = AchievementsSmallIcon;
