import React, { useMemo, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { api, AppConstant, translate } from '@shalina-app/shared';

import { DetailPage, ListingPage, Toast, UniversalShareComponent } from '../components';
import {
  Footer,
  Header,
  HomePage,
  KnowledgeHubPage,
  NotificationPage,
  Profile,
  ProfileSetupPage,
  SignInPage,
  SignUpPage,
  VerificationPage,
  AboutUsPage,
  TermAndConditionsPage,
  Survey,
  SurveyDetails,
  SurveyQuiz,
  Calculator,
  HelpSupport,
  SamlRedirector,
  AppStoreRedirector,
} from '../features';
import { ShalinaTV } from '../features/ShalinaTv';
import { URLConstant } from './urls';
import { SearchListPage } from '../features/Header/components/searchBar/listing/searchList.component';
import RssLisitngPage from '../components/common/contentDetails/pages/rssFeed/rssListing.component';
import RssDetailPage from '../components/common/contentDetails/pages/rssFeed/rssDetail/rssDetail.component';
import ReferralRouting from '../features/Header/components/userProfile/referral/referralRouting.component';
import { LearningCenterPage } from '../features/learningCenter/learningCenter.component';
import { decodeLink, retrieveData, STORAGE_CONSTANTS, useNavigatePageUtils, userSignOut } from '../utils';
import EncryptedDetailRouting from '../components/common/contentDetails/pages/encryptedDetailRoute/ecryptedDetailRoute.component';
import { CommunitiesLandingPage } from '../features/communities';

export const RoutesComponent = () => {
  const { urls } = URLConstant;
  const t = translate();
  const navigatePage = useNavigatePageUtils();
  const isLoggedIn: boolean = retrieveData(STORAGE_CONSTANTS.IS_LOGGED_IN);


  const [showToast, setShowToast] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const handleToastClose = () => {
    setShowToast(false);
  };

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return new Promise((resolve, reject) => {
        if (error.response) {
          const errorMessage = error.response.data.message;
          const status = error.response.status;
          if (((errorMessage === 'TOKEN_INVALID' && isLoggedIn) || (errorMessage === 'USER_BLOCKED' && isLoggedIn)) && status === 403) {
            setShowToast(true);
            setIsSuccess(false);
            setToastMessage(t(AppConstant.COMMON.SESSION_EXPIRED_MESSAGE));
            setTimeout(()=>{
              handleSignOut();
            }, 200)
          }
        }
        reject(error);
      });
    }
  );

  const handleSignOut = () => {
    userSignOut();
    const navigateDetails = {
      isDynamicUrl: false,
    };
    navigatePage(urls.signInPage, navigateDetails);
  };

  const routeComponent = (
    showHeader: boolean,
    showFooter: boolean,
    Component: React.FC
  ) => {
    return (
      <div>
        {showHeader && <Header />}
        <Component />
        {showFooter && <Footer />}
      </div>
    );
  };

  const routes = useMemo(() => {
    return (
      <>
        <Routes>
          <Route
            path={urls.appStoreRedirect}
            element={routeComponent(false, false, AppStoreRedirector)}
          />
          <Route
            path={urls.universalShare}
            element={routeComponent(false, false, UniversalShareComponent)}
          />
          <Route
            path={urls.samlRedirectorForMobile}
            element={routeComponent(false, false, SamlRedirector)}
          />
          <Route
            path={urls.signUpPage}
            element={routeComponent(false, false, SignUpPage)}
          />
          <Route
            path={urls.signInPage}
            element={routeComponent(false, false, SignInPage)}
          />
          <Route
            path={urls.verificationPage}
            element={routeComponent(false, false, VerificationPage)}
          />
          <Route
            path={urls.profileSetupPage}
            element={routeComponent(false, false, ProfileSetupPage)}
          />
          <Route
            path={urls.knowledgeHubPage}
            element={routeComponent(true, true, KnowledgeHubPage)}
          />
          <Route
            path={urls.shalinaTv}
            element={routeComponent(true, true, ShalinaTV)}
          />
          <Route
            path={urls.rssListing}
            element={routeComponent(true, true, RssLisitngPage)}
          />
          <Route
            path={urls.rssDetail}
            element={routeComponent(true, true, RssDetailPage)}
          />
          <Route
            path={urls.listingPage}
            element={routeComponent(true, true, ListingPage)}
          />
          <Route
            path={urls.listingPageWithModule}
            element={routeComponent(true, true, ListingPage)}
          />
          <Route
            path={urls.detailPageWithModule}
            element={routeComponent(true, true, DetailPage)}
          />
          {/* Add a route for DetailPage with encodedLink */}
          <Route
            path={decodeLink(urls.detailPageEncrypted)}
            element={routeComponent(false, false, EncryptedDetailRouting)}
          />
          {/*  */}
          <Route
            path={decodeLink(urls.detailPage)}
            element={routeComponent(true, true, DetailPage)}
          />
          <Route
            path={urls.referral}
            element={routeComponent(false, false, ReferralRouting)}
          />
          <Route
            path={urls.notificationPage}
            element={routeComponent(true, true, NotificationPage)}
          />
          <Route
            path={urls.profilePage}
            element={routeComponent(true, true, Profile)}
          />
          <Route
            path={urls.aboutUsPage}
            element={routeComponent(true, true, AboutUsPage)}
          />
          <Route
            path={urls.termsAndConditionsPage}
            element={routeComponent(true, true, TermAndConditionsPage)}
          />
          <Route
            path={urls.helpSupport}
            element={routeComponent(true, true, HelpSupport)}
          />
          <Route
            path={urls.searchPage}
            element={routeComponent(true, true, SearchListPage)}
          />
          <Route
            path={urls.commiunitiesPage}
            element={routeComponent(true, true, CommunitiesLandingPage)}
          />
          <Route
            path={urls.surveyQuiz}
            element={routeComponent(true, true, SurveyQuiz)}
          />
          <Route
            path={urls.surveyDetails}
            element={routeComponent(true, true, SurveyDetails)}
          />
          <Route
            path={urls.surveyPage}
            element={routeComponent(true, true, Survey)}
          />
          <Route
            path={urls.medicalCalculator}
            element={routeComponent(true, true, Calculator)}
          />
          {/* Keep home route at last */}
          <Route
            path={urls.homePage}
            element={routeComponent(true, true, HomePage)}
          />
          <Route
            path={urls.learningCenterPage}
            element={routeComponent(true, true, LearningCenterPage)}
          />
          <Route
            path={urls.commiunitiesDetailPage}
            element={routeComponent(true, true, CommunitiesLandingPage)}
          />
          {/* Error route */}
          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
      </>
    );
  }, []);

  return (
    <>
      {routes}
      <Toast
        showToast={showToast}
        isSuccess={isSuccess}
        message={toastMessage}
        handleToastClose={handleToastClose}
      />
    </>
  );
};
