"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COMMUNITY_API_KEY = exports.HOME_API_KEY = exports.MASTER_DATA_KEY = void 0;
exports.MASTER_DATA_KEY = {
    LANGUAGES: 'LANGUAGES',
    COUNTRIES: 'LIST_COUNTRIES',
    AREA_OF_INTEREST: 'LIST_AREA_OF_INTEREST',
    QUALIFICATION: 'LIST_QUALIFICATION',
    SPECIALIZATION: 'LIST_SPECIALIZATION',
    CONTENT_TYPE: 'CONTENT_TYPE',
    CONTACT_MODE: 'CONTACT_MODE_MASTER',
    GAMIFICATION_LIST: 'GAMIFICATION_LIST',
    DEACTIVATE_REASON: 'DEACTIVATE_REASON',
    MILESTONES: 'MILESTONES',
    SORT_OPTION: 'SORT_CONTENT_OPTION',
    NOTIFICATION_LIST: 'NOTIFICATION_LIST',
    REQUEST_STATUS: 'REQUEST_STATUS',
    SORT_OPTION_COMMUNITY: 'SORT_CONTENT_OPTION_COMMUNITY',
    COMMUNITY_CATEGORY: 'COMMUNITY_CATEGORY',
    USER_GAMIFICATION: 'USER_GAMIFICATION',
    REQUEST_TYPE: 'REQUEST_TYPE',
    LIST_OF_CALCULATOR: 'LIST_OF_CALCULATOR',
    REQUEST_CONTENT_TYPES: 'REQUEST_CONTENT_TYPE',
    RECOGNITION_BADGES_LIST: 'RECOGNITION_MATRIX',
    GAMIFICATION_RECOGNITION_BADGES_LIST: 'GAMIFICATION_RECOGNITION_MATRIX',
    GAMIFICATION_MASTER_KEYS: 'GAMIFICATION_MASTER_KEYS'
};
exports.HOME_API_KEY = {
    NEWLY_ADDED: 'newlyAddedContent',
    OUR_EXPERT: 'ourExpert',
    TRENDING_NOW: 'trendingNow',
    MEDICAL_NEWS: 'medicalNewsContent',
    CONTINUE_WHERE_YOU_LEFT: 'continueWhereYouLeft',
    RECOMMENDED_FOR_YOU: 'recommendedForYou'
};
exports.COMMUNITY_API_KEY = {
    LIST_OF_EXPLORE_COMMUNITIES: 'LIST_OF_EXPLORE_COMMUNITIES',
    LIST_OF_MY_COMMUNITIES: 'LIST_OF_MY_COMMUNITIES',
    LIST_OF_RECOMMENDED_COMMUNITIES: 'LIST_OF_RECOMMENDED_COMMUNITIES',
    LIST_OF_REQUESTS: 'LIST_OF_REQUESTS'
};
