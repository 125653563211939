import {
  AppConstant,
  COMMUNITY_TYPE,
  CUSTOM_ANALYTICS_EVENT_NAMES,
  getAnalyticsUserDetails,
  getLoggedInUserInfo,
  ImageConstant,
  logEvent,
  PlatformNames,
  ShareModules,
  translate,
  useAppSelector,
} from '@shalina-app/shared';
import parse from 'html-react-parser';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from 'react-share';

import { CommunityShareProps } from '..';
import { appInsights } from '../../../../../applicationInsight';
import { Toast } from '../../../../../components';
import { retrieveData, STORAGE_CONSTANTS } from '../../../../../utils';
import styles from '../CommunityDetail.module.scss';

export const CommunityShare: React.FC<CommunityShareProps> = ({ content }) => {
  const t = translate();
  const params = useParams();

  const isLoggedIn: boolean = retrieveData(STORAGE_CONSTANTS.IS_LOGGED_IN);
  const user = retrieveData(STORAGE_CONSTANTS.USER);
  const userDetails = useAppSelector(getLoggedInUserInfo);

  const [showShare, setShowShare] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const handleCopyButton = () => {
    const { communityId } = params;
    const baseUrl = window.location.origin;
    const link = `${baseUrl}/share?moduleName=communities&communityId=${communityId}`;

    navigator.clipboard.writeText(link);
    setShowToast(true);
    setIsSuccess(true);
    setToastMessage(t(AppConstant.COMMON.LINK_COPY_MESSAGE));
  };

  const handleShareClick = () => {
    setShowShare((prev) => !prev);
    const { communityId } = params;
    if (isLoggedIn && communityId && content && user) {
      const userObj = userDetails || user;
      const userInfo = getAnalyticsUserDetails(userObj);
      logEvent(
        appInsights,
        'custom',
        CUSTOM_ANALYTICS_EVENT_NAMES.USER_SHARE_CONTENT,
        {
          contentId: communityId,
          contentTitle: content?.label,
          userEmail: userInfo?.emailId,
          userMobile: userInfo?.mobile,
          userName: userInfo?.name,
          userQualification: userInfo?.qualification,
          userInterest: userInfo?.areaOfInterest,
          userSpecialization: userInfo?.specialization,
          userCountryOfPractice: userInfo?.countryOfPractice,
          userPreferredLanguage: userInfo?.preferredLanguage,
          platform: PlatformNames.WEB,
          moduleName: ShareModules.COMMUNITY,
        }
      );
    }
  };

  const handleToastClose = () => {
    setShowToast(false);
  };

  const getShareableLink = useMemo(() => {
    const { communityId } = params;
    const baseUrl = window.location.origin;
    const link = `${baseUrl}/share?moduleName=communities&communityId=${communityId}`;

    const userName = user?.firstName + ' ' + user?.lastName;
    const contentMsg = `${t(AppConstant.PROFILE.HI_TEXT)}, ${userName} ${t(
      AppConstant.PROFILE.SHARE_CONTENT_MSG
    )} ${t(AppConstant.COMMUNITY.COMMUNITY_TEXT)} "${content?.label}" ${t(
      AppConstant.PROFILE.CLICK_TEXT
    )} ${link} ${t(AppConstant.COMMUNITY.SHARE_COMMUNITY_REMAINING_MSG)}`;
    return `${contentMsg}`;
  }, [content, user]);

  const { ShareIcon, CopyClipboardIcon } = ImageConstant.SVG_ICONS;

  const openCommunity = content.field_type === COMMUNITY_TYPE.OPEN;

  return (
    <>
      <div className={styles.shareWrapper}>
        <div className={styles.detailActions}>
          <button
            className={`${
              !isLoggedIn || !openCommunity ? styles.disabled : ''
            }`}
            disabled={!isLoggedIn || !openCommunity}
          >
            <ShareIcon onClick={handleShareClick} />
          </button>
        </div>
        {showShare && (
          <div className={styles.shareList}>
            <div className={styles.shareListIcon}>
              <span className={styles.copyIcon}>
                <CopyClipboardIcon onClick={handleCopyButton} />
              </span>
            </div>
            <div className={styles.shareListIcon}>
              <FacebookShareButton
                url={getShareableLink}
                className='Demo__some-network__share-button'
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
            </div>
            <div className={styles.shareListIcon}>
              <TwitterShareButton
                url={getShareableLink}
                className='Demo__some-network__share-button'
              >
                <XIcon size={32} round />
              </TwitterShareButton>
            </div>
            <div className={styles.shareListIcon}>
              <WhatsappShareButton
                url={getShareableLink}
                separator=''
                className='Demo__some-network__share-button'
              >
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
            </div>
            <div className={styles.shareListIcon}>
              <LinkedinShareButton
                url={getShareableLink}
                className='Demo__some-network__share-button'
              >
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
            </div>
            <div className={styles.shareListIcon}>
              <EmailShareButton
                url={getShareableLink}
                subject={content?.label}
                className='Demo__some-network__share-button'
              >
                <EmailIcon size={32} round />
              </EmailShareButton>
            </div>
          </div>
        )}
      </div>
      <Toast
        showToast={showToast}
        isSuccess={isSuccess}
        message={toastMessage}
        handleToastClose={handleToastClose}
      />
    </>
  );
};
