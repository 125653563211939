import { useEffect, useState } from 'react';
import {
  AppConstant,
  GAMIFICATION_ACTION_ID,
  getNotificationActionList,
  translate,
  useAppDispatch,
  useAppSelector
} from '@shalina-app/shared';

import styles from './interests.module.scss';
import { ChipList, CircularLoader, Input } from '../../../../../../components';
import {
  eventType,
  getTranslationFormat,
  retrieveData,
  STORAGE_CONSTANTS,
  UserInterface
} from '../../../../../../utils';
import { ButtonLoader } from '../../../../../communities';

export const Interests: React.FC<any> = ({
  areaOfInterestList,
  handleSaveProfileClick,
  isSaveLoader
}) => {
  const t = translate();
  const user: UserInterface | any = retrieveData(STORAGE_CONSTANTS.USER);

  const dispatch = useAppDispatch();
  const isUpdatePointLoading = useAppSelector(
    (state) => state?.notification?.isLoading
  );
  const notificationList = useAppSelector(getNotificationActionList);

  const defaultInterest = user?.areaOfInterest?.map(
    (item: any) => item?.tid && String(item?.tid)
  );

  const [optionsSelected, setOptionsSelected] =
    useState<number[]>(defaultInterest);
  const [copyList, setCopyList] = useState(areaOfInterestList);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [completedProfile, setCompletedProfile] = useState(false);
  const [isCancelEnabled, setCancelEnabled] = useState(false);

  const handleInterestSearch = (text: string) => {
    if (text) {
      // Inserted text is not blank
      // Filter the masterDataSource and update FilteredDataSource
      const newData = areaOfInterestList.filter((item: any) => {
        // Applying filter for the inserted text in search bar
        const itemLabel = item?.label?.toUpperCase();
        const inputText = text.toUpperCase();
        return itemLabel.indexOf(inputText) > -1;
      });
      setCopyList(newData);
    } else {
      // Inserted text is blank
      // Update FilteredDataSource with masterDataSource
      setCopyList(areaOfInterestList);
    }
  };

  const handleSelectOption = (value: any) => {
    setOptionsSelected(value);
    const diff = defaultInterest
      ?.filter((x: any) => !value.includes(x))
      ?.concat(value.filter((x: any) => !defaultInterest.includes(x)));

    if (value && value?.length > 0 && diff && diff.length > 0) {
      setIsSaveEnabled(true);
      setCancelEnabled(true);
    } else {
      setIsSaveEnabled(false);
      setCancelEnabled(false);
    }
  };

  const handleSaveClick = async () => {
    const req = {
      areaOfInterest: optionsSelected || []
    };

    handleSaveProfileClick(req);
    setIsSaveEnabled(false);
  };

  const resetInterestDetails = () => {
    setOptionsSelected(defaultInterest);
    setCancelEnabled(false);
    setIsSaveEnabled(false);
  };

  useEffect(() => {
    if (completedProfile) {
      sendNotificationCall();
      setCompletedProfile(false);
    }
  }, [completedProfile]);

  const sendNotificationCall = async () => {
    const action = notificationList?.find(
      (item: any) =>
        item?.field_unique_code === GAMIFICATION_ACTION_ID.PROFILE_COMPLETION
    )?.title;

    const req = {
      action: action
    };
    // await dispatch(updatePointsWithNotification(req));
  };

  return (
    <div className={styles.profileUpdateForm}>
      {isUpdatePointLoading && <CircularLoader size={50} thickness={4} />}
      <div className={styles.profileUpdateFormCol12}>
        <Input
          formControlVariant={'standard'}
          labelText={getTranslationFormat(
            AppConstant.AUTHENTICATION_PAGE.SELECT_INTEREST_TEXT
          )}
          inputId={'selectInterests'}
          inputVariant={'outlined'}
          isRequired={true}
          isError={optionsSelected?.length === 0}
          errorMessage={t(AppConstant.ERROR.PLEASE_SELECT_AREA_OF_INTEREST)}
          handleChange={(e: eventType) => handleInterestSearch(e.target.value)}
          placeHolderText={getTranslationFormat(
            AppConstant.AUTHENTICATION_PAGE.SEARCH_TEXT
          )}
        />
      </div>
      <div className={styles.profileUpdateFormCol12}>
        <div className={styles.selectIntrest}>
          <label className={styles.siLabel}>
            {getTranslationFormat(
              AppConstant.AUTHENTICATION_PAGE.INTEREST_SELECTED_TEXT
            )}{' '}
            {optionsSelected?.length ? `(${optionsSelected?.length})` : '(0)'}
          </label>
          <div className={styles.selectIntrestList}>
            <ChipList
              options={copyList}
              selectedOptionsList={optionsSelected}
              onSelectOption={handleSelectOption}
              // onSelectOption={setOptionsSelected}
            />
          </div>
        </div>
      </div>
      <div className={styles.profileUpdateFormCol12}>
        <div className={styles.profileUpdateFormCtas}>
          <button
            disabled={isCancelEnabled ? false : true}
            onClick={resetInterestDetails}
          >
            {getTranslationFormat(AppConstant.PROFILE.CANCEL_CTA)}
          </button>
          <button
            className={styles.save}
            disabled={isSaveEnabled ? false : true}
            onClick={handleSaveClick}
          >
            {getTranslationFormat(AppConstant.PROFILE.SAVE_CTA)}
            <ButtonLoader loader={isSaveLoader} />
          </button>
        </div>
      </div>
    </div>
  );
};
