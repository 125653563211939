import {
  AppConstant,
  getAdditionalInfoDetails,
  translate
} from '@shalina-app/shared';
import { Link, useLocation } from 'react-router-dom';

import { URLConstant } from '../../../../../../routes';
import styles from '../articleDetails.module.scss';

export const JournalAdditionalInfo: React.FC<any> = ({
  isLoggedIn,
  contentDetails
}) => {
  const { urls } = URLConstant;
  const t = translate();
  const location = useLocation();
  const pathName = location?.pathname;

  const data = getAdditionalInfoDetails(contentDetails?.event_data);

  return (
    <div
      className={`${styles.bookDetail} ${
        !isLoggedIn && styles.guestDescWrapper
      }`}
    >
      {!isLoggedIn && (
        <div className={styles.guestDesc}>
          {/* <Link to={urls.signUpPage} state={{ pathName: pathName }}>
            <button>
              {t(AppConstant.AUTHENTICATION_PAGE.SIGN_UP_TO_CONTINUE)}
            </button>
          </Link> */}
        </div>
      )}
      {data && data?.length > 0 ? (
        data?.map((item: any) => (
          <p>
            <label>{item?.title}</label>
            <span>{item?.value}</span>
          </p>
        ))
      ) : (
        <div className={styles.notFoundText}>
          <p>{t(AppConstant.COMMON.NO_ADDITIONAL_INFO_AVALIABLE)}</p>
        </div>
      )}
    </div>
  );
};
