import {
  AppConstant,
  fetchCommunityMembers,
  getCommunityMembers,
  getCommunityMembersTotalCount,
  ImageConstant,
  MembersProps,
  translate,
  updateMembersList,
  useAppDispatch,
  useAppSelector
} from '@shalina-app/shared';
import { useEffect, useRef, useState } from 'react';

import { CommunityMembersProps } from '..';
import {
  DEFAULT_COMMUNITY_SKIP,
  DEFAULT_COMMUNITY_TAKE
} from '../../../../../app.constants';
import { BarLoader, InfiniteScrollComponent } from '../../../../../components';
import { getImageSrc } from '../../../../../utils';
import styles from '../CommunityDetail.module.scss';

export const CommunityMembers: React.FC<CommunityMembersProps> = ({
  communityId
}) => {
  const { LocationIcon } = ImageConstant.SVG_ICONS;
  const t = translate();
  const dispatch = useAppDispatch();
  const prevProps: any = useRef();

  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(DEFAULT_COMMUNITY_TAKE);
  const [hasMore, setHasMore] = useState(true);
  const [reload, setReload] = useState(false);
  const [initialLoader, setInitialLoader] = useState(true);

  useEffect(() => {
    if (reload) {
      loadApi();
      setReload(false);
    }
  }, [reload]);

  useEffect(() => {
    setReload(true);
    setInitialLoader(true);
  }, []);

  const communityMembers = useAppSelector(getCommunityMembers);
  const communityMembersTotalCount = useAppSelector(
    getCommunityMembersTotalCount
  );

  useEffect(() => {
    // if (prevProps?.current && prevProps?.current?.communityId !== communityId) {
    //   dispatch(updateMembersList([]));
    // }
    dispatch(updateMembersList([]));
    prevProps.current = { communityId };
  }, [communityId]);

  // useEffect(() => {
  //   if (communityMembers?.length === 0) {
  //     setReload(true);
  //     setInitialLoader(true);
  //   } else {
  //     if (communityMembers?.length < communityMembersTotalCount) {
  //       setSkip(communityMembers?.length);
  //     }
  //   }
  // }, [communityMembers]);

  const loadApi = async () => {
    const payload = {
      communityId: communityId,
      skip: skip,
      take: take
    };
    const response: any = await dispatch(
      fetchCommunityMembers(payload)
    ).unwrap();
    if (
      response &&
      response?.status === 'SUCCESS' &&
      response?.statusCode === 200 &&
      response?.data
    ) {
      dispatch(updateMembersList([...communityMembers, ...response?.data]));
    }
    setInitialLoader(false);
    setSkip((prevSkip) => prevSkip + DEFAULT_COMMUNITY_SKIP);
  };

  const fetchMoreData = () => {
    if (communityMembers?.length >= communityMembersTotalCount) {
      setHasMore(false);
      return;
    } else {
      // setSkip(communityMembers?.length + DEFAULT_COMMUNITY_SKIP);
    }
    setReload(true);
  };

  return (
    <>
      {initialLoader ? (
        <div className={styles.initialLoader}>
          <BarLoader />
        </div>
      ) : communityMembers?.length > 0 ? (
        <div className={styles.detailexpertList}>
          <InfiniteScrollComponent
            dataLength={communityMembers?.length}
            fetchMoreData={fetchMoreData}
            hasMore={hasMore}
            loader={true}
          >
            {communityMembers?.map((member: MembersProps) => (
              <div className={styles.itemWrapper}>
                <div className={styles.detailexperItem}>
                  <img
                    className={styles.expertImg}
                    src={
                      member?.member_picture
                        ? getImageSrc(member?.member_picture)
                        : ImageConstant.SOURCE.defaultUserPic
                    }
                    alt='img'
                    onError={(e) =>
                      ((e.target as HTMLInputElement).src =
                        ImageConstant.SOURCE.defaultUserPic)
                    }
                  />
                  <div className={styles.details}>
                    <div
                      className={styles.name}
                    >{`${member?.member_first_name} ${member?.member_last_name}`}</div>
                    <div className={styles.roleLocation}>
                      <span className={styles.role}>
                        {member?.member_qualification}
                      </span>
                      <span className={styles.location}>
                        {member?.member_countries ? (
                          <>
                            <LocationIcon /> {member?.member_countries}
                          </>
                        ) : null}
                      </span>
                    </div>
                  </div>
                </div>
                {(member?.member_group_role?.[0] === 'community-kol' ||
                  member?.member_group_role?.[0] === 'community-admin') && (
                  <span className={styles.expertTag}>
                    {t(AppConstant.COMMUNITY.COMMUNITY_EXPERT_FLAG)}
                  </span>
                )}
              </div>
            ))}
          </InfiniteScrollComponent>
        </div>
      ) : (
        <div className={styles.noData}>
          <img
            className={styles.noDataImg}
            src={ImageConstant.SOURCE.emptyContent}
            alt='img'
          />
          <p className={styles.noDataMsg}>
            {t(AppConstant.COMMUNITY.NO_MEMBERS_FOUND_FOR_COMMUNITY)}
          </p>
        </div>
      )}
    </>
  );
};
