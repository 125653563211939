import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const EncryptedDetailRouting = () => {
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (params?.encryptedID) {
      const urll = `/${params?.encryptedID}`;
      navigate(urll);
    }
  }, [params?.encryptedID]);

  return <></>;
};

export default EncryptedDetailRouting;
