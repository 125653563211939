import { useState } from 'react';
import { AppConstant, ImageConstant, translate } from '@shalina-app/shared';
import { Link } from 'react-router-dom';

import { QuickLinkEnum, QuickLinkSectionPropsType } from '.';
import styles from './quickLinkSection.module.scss';
import { URLConstant } from '../../../routes';
import { retrieveData, STORAGE_CONSTANTS } from '../../../utils';
import { SignUpPopup } from '../../../features';
import { ABOUT_US_PAGE_TABS, PROFILE_PAGE_TABS } from '../../../app.constants';

export const QuickLinkSection: React.FC<QuickLinkSectionPropsType> = ({
  enumIds
}) => {
  const {
    BookmarkIcon,
    BookmarkSmallIcon,
    SurveyIcon,
    AchievementsIcon,
    AchievementsSmallIcon,
    DosageIcon,
    AssessmentIcon,
    MonitorIcon,
    TreatmentIcon,
    AboutIcon
  } = ImageConstant.SVG_ICONS;

  const t = translate();

  const { urls } = URLConstant;
  const defaultList = [
    {
      enumId: 'bookmark',
      name: t(AppConstant.COMMON.BOOKMARKS_TEXT),
      route: urls.profilePage,
      icon: <BookmarkIcon />,
      state: { tab: PROFILE_PAGE_TABS.BOOKMARKS },
      allowGuestUser: false
    },
    // {
    //   enumId: "achievements",
    //   name: t(AppConstant.PROFILE.ACHIEVEMENTS_TAB_TEXT),
    //   route: "", // urls.profilePage, Commenting as mentioned: bug > #259
    //   icon: <AchievementsIcon />,
    //   state: { tab: PROFILE_PAGE_TABS.ACHIEVEMENTS }
    // },
    // {
    //   enumId: "surveys",
    //   name: t(AppConstant.HEADER.SURVEYS_MENU),
    //   route: "",
    //   icon: <SurveyIcon />,
    //   state: null
    // },
    {
      enumId: 'medicalCalculator',
      name: t(AppConstant.HEADER.MEDICAL_CALCULATOR_MENU),
      route: urls.medicalCalculator,
      icon: <DosageIcon />,
      state: null,
      allowGuestUser: true
    },
    // {
    //   enumId: "dosageCalculator",
    //   name: t(AppConstant.HEADER.DOSAGE_CALCULATOR_MENU),
    //   route: "",
    //   icon: <DosageIcon />,
    //   state: null
    // },
    // {
    //   enumId: "riskDiseaseAssessment",
    //   name: t(AppConstant.HEADER.RISK_DIESEASE_ASSESSMENT_MENU),
    //   route: "",
    //   icon: <AssessmentIcon />,
    //   state: null
    // },
    // {
    //   enumId: "vitalMonitoringApp",
    //   name: t(AppConstant.HEADER.VITAL_MONITORING_APP_MENU),
    //   route: "",
    //   icon: <MonitorIcon />,
    //   state: null
    // },
    // {
    //   enumId: "treatmentAlgorithm",
    //   name: t(AppConstant.HEADER.TREATMENT_ALGORITHM_MENU),
    //   route: "",
    //   icon: <TreatmentIcon />,
    //   state: null
    // },
    {
      enumId: 'aboutUs',
      name: t(AppConstant.HEADER.ABOUT_US_MENU),
      route: urls.aboutUsPage,
      icon: <AboutIcon />,
      state: { tab: ABOUT_US_PAGE_TABS.WHO_WE_ARE },
      allowGuestUser: true
    }
  ];

  const isLoggedIn: boolean = retrieveData(STORAGE_CONSTANTS.IS_LOGGED_IN);

  const [showSignUp, setShowSignUp] = useState(false);

  const handleSignUpClose = () => {
    setShowSignUp(false);
  };

  const handleSignUpFlow = () => {
    setShowSignUp(true);
  };

  const quickLinksList = enumIds?.length
    ? defaultList?.filter((item) =>
        enumIds.includes(item.enumId as QuickLinkEnum)
      )
    : defaultList;

  return (
    <div className={styles.quickLink}>
      <div className={styles.title}>
        {t(AppConstant.COMMON.QUICK_LINKS_TEXT)}
      </div>
      <ul>
        {quickLinksList &&
          quickLinksList.map((link, index) =>
            isLoggedIn || link?.allowGuestUser ? (
              <li key={index}>
                <Link to={link?.route} state={link?.state}>
                  <span className={styles.iconWrapper}>{link?.icon}</span>
                  {link?.name}
                </Link>
              </li>
            ) : (
              <li key={index}>
                <div
                  onClick={handleSignUpFlow}
                  className={styles?.quickLinkItem}
                >
                  <span className={styles.iconWrapper}>{link?.icon}</span>
                  {link?.name}
                </div>
              </li>
            )
          )}
      </ul>
      <SignUpPopup open={showSignUp} handleClose={handleSignUpClose} />
    </div>
  );
};
