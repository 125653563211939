"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SettingIcon = void 0;
const React = __importStar(require("react"));
const SettingIcon = (props) => (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: 19, height: 20, fill: "none" }, props),
    React.createElement("path", { stroke: "#E84E1B", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 1.7, d: "M9.502 12.319a2.318 2.318 0 1 0 0-4.637 2.318 2.318 0 0 0 0 4.636Z" }),
    React.createElement("path", { stroke: "#E84E1B", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 1.7, d: "M15.218 12.318a1.274 1.274 0 0 0 .255 1.407l.046.046a1.546 1.546 0 1 1-2.186 2.187l-.047-.047a1.275 1.275 0 0 0-1.406-.255 1.275 1.275 0 0 0-.773 1.167v.131a1.546 1.546 0 0 1-3.09 0v-.069a1.275 1.275 0 0 0-.835-1.167 1.274 1.274 0 0 0-1.407.255l-.046.046a1.546 1.546 0 1 1-2.187-2.186l.047-.047a1.276 1.276 0 0 0 .255-1.406 1.275 1.275 0 0 0-1.167-.773h-.132a1.546 1.546 0 0 1 0-3.09h.07a1.275 1.275 0 0 0 1.167-.835 1.275 1.275 0 0 0-.255-1.407L3.48 6.23a1.545 1.545 0 1 1 2.187-2.187l.047.047a1.275 1.275 0 0 0 1.406.255h.062a1.275 1.275 0 0 0 .773-1.167v-.132a1.545 1.545 0 1 1 3.09 0v.07a1.274 1.274 0 0 0 .773 1.167 1.275 1.275 0 0 0 1.407-.255l.046-.047a1.545 1.545 0 1 1 2.187 2.187l-.047.047a1.274 1.274 0 0 0-.255 1.406v.062a1.275 1.275 0 0 0 1.167.773h.131a1.545 1.545 0 1 1 0 3.09h-.069a1.274 1.274 0 0 0-1.167.773Z" })));
exports.SettingIcon = SettingIcon;
