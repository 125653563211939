"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateBookmarkStateForContentDetails = exports.getRecommendedForYou = exports.getContinueWhereLeft = exports.getMedicalNews = exports.getTrendingNow = exports.getNewlyAddedContent = exports.listRssNewsFeed = exports.getListOurCommunities = exports.getDeleteRecentSearch = exports.getListRecentSearchResult = exports.getListRecenetSearch = exports.getSearchContent = exports.getContentDetailItems = exports.getContentDetails = exports.getInitialSurveyList = exports.getSurveyList = exports.getExpertList = exports.getFeaturedContent = exports.fetchMedicalCalculator = exports.rssFeedNews = exports.listOurCommunities = exports.deleteRecentSearch = exports.listRecentSearch = exports.searchContent = exports.contentDetails = exports.listOfSurvey = exports.recommendedForYou = exports.continueWhereLeft = exports.trendingNow = exports.medicalNews = exports.listExpert = exports.newlyAdded = exports.featuredContent = exports.fetchHomeContent = void 0;
const api_1 = require("../../../api");
const constants_1 = require("../../constants");
const toolkit_1 = require("@reduxjs/toolkit");
const utils_1 = require("../../utils");
const reducer_constants_1 = require("../reducer.constants");
const initialState = {
    isLoading: false,
    featuredList: {
        featuredListData: [],
        isError: false,
        isFeaturedLoading: false,
    },
    listExpert: {
        listExpertData: [],
        isError: false,
        isExpertLoading: false,
    },
    newlyAdded: {
        newlyAddedData: [],
        isError: false,
        isNewlyAddedLoading: false,
    },
    medicalNews: {
        medicalNewsData: [],
        isError: false,
        isMedicalNewsLoading: false,
    },
    trendingNow: {
        trendingNowData: [],
        isError: false,
        isTrendingNowLoading: false,
    },
    continueWhereLeft: {
        continueWhereLeftData: [],
        isError: false,
        isContinueWhereLeftLoading: false,
    },
    recommendedForYou: {
        recommendedForYouData: [],
        isError: false,
        isRecommendedLoading: false,
    },
    listOfSurveyState: {
        listOfSurveyData: {},
        isError: false,
        isSurveyLoading: false,
    },
    contentDetails: {
        contentDetailsData: {},
        isError: false,
        isContentDetailsLoading: false,
    },
    searchContent: {
        searchContentData: {},
        isError: false,
        isSearchContentLoading: false,
    },
    listRecentSearch: {
        listRecentSearchData: {},
        isError: false,
        isListRecentSearchLoading: false,
    },
    deleteRecentSearch: {
        deleteRecentSearchData: {},
        isError: false,
        isDeleteRecentSearchLoading: false,
    },
    listOurCommunities: {
        listOurCommunitiesData: [],
        isError: false,
        isListOurCommunitiesLoading: false,
    },
    homeContent: {
        homeContent: [],
        isHomeContentLoading: false,
    },
    listRssNewsFeed: {
        listRssNewsFeedData: [],
        rssListCount: 0,
        isError: false,
        isListRssNewsFeedLoading: false,
    },
    medicalCalculatorContent: {
        medicalCalculatorContent: [],
        isMedicalCalculatorContentLoading: false,
    },
};
const homeSlice = (0, toolkit_1.createSlice)({
    name: constants_1.HOME,
    initialState,
    reducers: {
        updateBookmarkStateForContentDetails: (state, action) => {
            var _a, _b;
            const { nid } = action.payload;
            const contentListByContentType = ((_a = (0, toolkit_1.current)(state).contentDetails
                .contentDetailsData) === null || _a === void 0 ? void 0 : _a.data)
                ? (_b = state.contentDetails.contentDetailsData) === null || _b === void 0 ? void 0 : _b.data
                : [];
            if (contentListByContentType && (contentListByContentType === null || contentListByContentType === void 0 ? void 0 : contentListByContentType.length) > 0) {
                const updatedContentList = contentListByContentType.map((item) => item.nid === nid
                    ? Object.assign(Object.assign({}, item), { is_bookmarked: !item.is_bookmarked }) : item);
                state.contentDetails.contentDetailsData.data = updatedContentList;
            }
        },
    },
    extraReducers(builder) {
        /**
         * Featured Content API
         */
        builder.addCase(exports.featuredContent.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.isLoading = true;
            state.featuredList.isFeaturedLoading = true;
        });
        builder.addCase(exports.featuredContent.fulfilled, (state, action) => {
            var _a;
            // Add logic if any when API state is fulfilled or data is received from API
            state.isLoading = false;
            state.featuredList.isFeaturedLoading = false;
            const featureList = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data;
            const uniqueArray = featureList && (featureList === null || featureList === void 0 ? void 0 : featureList.length) > 0 ? (0, utils_1.removeDuplicates)(featureList, "nid") : [];
            state.featuredList.featuredListData = uniqueArray;
        });
        builder.addCase(exports.featuredContent.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.isLoading = false;
            state.featuredList.isFeaturedLoading = false;
        });
        /**
         * Newly Added API
         */
        builder.addCase(exports.newlyAdded.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.isLoading = true;
            state.newlyAdded.isNewlyAddedLoading = true;
        });
        builder.addCase(exports.newlyAdded.fulfilled, (state, action) => {
            var _a;
            // Add logic if any when API state is fulfilled or data is received from API
            state.isLoading = false;
            state.newlyAdded.isNewlyAddedLoading = false;
            const newlyAddedList = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data;
            const uniqueArray = newlyAddedList && (newlyAddedList === null || newlyAddedList === void 0 ? void 0 : newlyAddedList.length) > 0 ? (0, utils_1.removeDuplicates)(newlyAddedList, "nid") : [];
            state.newlyAdded.newlyAddedData = uniqueArray;
        });
        builder.addCase(exports.newlyAdded.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.isLoading = false;
            state.newlyAdded.isNewlyAddedLoading = false;
        });
        /**
         * Expert List API
         */
        builder.addCase(exports.listExpert.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.isLoading = true;
            state.listExpert.isExpertLoading = true;
        });
        builder.addCase(exports.listExpert.fulfilled, (state, action) => {
            var _a;
            // Add logic if any when API state is fulfilled or data is received from API
            state.isLoading = false;
            state.listExpert.isExpertLoading = false;
            const expertList = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data;
            const uniqueArray = expertList && (expertList === null || expertList === void 0 ? void 0 : expertList.length) > 0 ? (0, utils_1.removeDuplicates)(expertList, "uid") : [];
            state.listExpert.listExpertData = uniqueArray;
        });
        builder.addCase(exports.listExpert.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.isLoading = false;
            state.listExpert.isExpertLoading = false;
        });
        /**
         * Medical News API
         */
        builder.addCase(exports.medicalNews.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.isLoading = true;
            state.medicalNews.isMedicalNewsLoading = true;
        });
        builder.addCase(exports.medicalNews.fulfilled, (state, action) => {
            var _a;
            // Add logic if any when API state is fulfilled or data is received from API
            state.isLoading = false;
            state.medicalNews.isMedicalNewsLoading = false;
            const medicalNewsList = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data;
            const uniqueArray = medicalNewsList && (medicalNewsList === null || medicalNewsList === void 0 ? void 0 : medicalNewsList.length) > 0 ? (0, utils_1.removeDuplicates)(medicalNewsList, "nid") : [];
            state.medicalNews.medicalNewsData = uniqueArray;
        });
        builder.addCase(exports.medicalNews.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.isLoading = false;
            state.medicalNews.isMedicalNewsLoading = false;
        });
        /**
         * Trending Now API
         */
        builder.addCase(exports.trendingNow.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.isLoading = true;
            state.trendingNow.isTrendingNowLoading = true;
        });
        builder.addCase(exports.trendingNow.fulfilled, (state, action) => {
            var _a;
            // Add logic if any when API state is fulfilled or data is received from API
            state.isLoading = false;
            state.trendingNow.isTrendingNowLoading = false;
            const trendingNowList = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data;
            const uniqueArray = trendingNowList && (trendingNowList === null || trendingNowList === void 0 ? void 0 : trendingNowList.length) > 0 ? (0, utils_1.removeDuplicates)(trendingNowList, "nid") : [];
            state.trendingNow.trendingNowData = uniqueArray;
        });
        builder.addCase(exports.trendingNow.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.isLoading = false;
            state.trendingNow.isTrendingNowLoading = false;
        });
        /**
         * Continue Where Left API
         */
        builder.addCase(exports.continueWhereLeft.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.isLoading = true;
            state.continueWhereLeft.isContinueWhereLeftLoading = true;
        });
        builder.addCase(exports.continueWhereLeft.fulfilled, (state, action) => {
            var _a;
            // Add logic if any when API state is fulfilled or data is received from API
            state.isLoading = false;
            state.continueWhereLeft.isContinueWhereLeftLoading = false;
            const continueWhereLeftList = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data;
            const uniqueArray = continueWhereLeftList && (continueWhereLeftList === null || continueWhereLeftList === void 0 ? void 0 : continueWhereLeftList.length) > 0 ? (0, utils_1.removeDuplicates)(continueWhereLeftList, "nid") : [];
            state.continueWhereLeft.continueWhereLeftData = uniqueArray;
        });
        builder.addCase(exports.continueWhereLeft.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.isLoading = false;
            state.continueWhereLeft.isContinueWhereLeftLoading = false;
        });
        /**
         * Recommended For You API
         */
        builder.addCase(exports.recommendedForYou.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.isLoading = true;
            state.recommendedForYou.isRecommendedLoading = true;
        });
        builder.addCase(exports.recommendedForYou.fulfilled, (state, action) => {
            var _a;
            // Add logic if any when API state is fulfilled or data is received from API
            state.isLoading = false;
            state.recommendedForYou.isRecommendedLoading = false;
            const recommendedForYouList = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data;
            const uniqueArray = recommendedForYouList && (recommendedForYouList === null || recommendedForYouList === void 0 ? void 0 : recommendedForYouList.length) > 0 ? (0, utils_1.removeDuplicates)(recommendedForYouList, "nid") : [];
            state.recommendedForYou.recommendedForYouData = uniqueArray;
        });
        builder.addCase(exports.recommendedForYou.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.isLoading = false;
            state.recommendedForYou.isRecommendedLoading = false;
        });
        /**
         * List of Survey API
         */
        builder.addCase(exports.listOfSurvey.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.isLoading = true;
            state.listOfSurveyState.isSurveyLoading = true;
        });
        builder.addCase(exports.listOfSurvey.fulfilled, (state, action) => {
            var _a;
            // Add logic if any when API state is fulfilled or data is received from API
            state.isLoading = false;
            state.listOfSurveyState.isSurveyLoading = false;
            const surveyList = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data;
            state.listOfSurveyState.listOfSurveyData = surveyList;
        });
        builder.addCase(exports.listOfSurvey.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.isLoading = false;
            state.listOfSurveyState.isSurveyLoading = false;
        });
        /**
         * Content Details API
         */
        builder.addCase(exports.contentDetails.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.isLoading = true;
            state.contentDetails.isContentDetailsLoading = true;
        });
        builder.addCase(exports.contentDetails.fulfilled, (state, action) => {
            // Add logic if any when API state is fulfilled or data is received from API
            state.isLoading = false;
            state.contentDetails.isContentDetailsLoading = false;
            // const contentDetailsResponse = action?.payload?.data;
            state.contentDetails.contentDetailsData = action === null || action === void 0 ? void 0 : action.payload;
        });
        builder.addCase(exports.contentDetails.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.isLoading = false;
            state.contentDetails.isContentDetailsLoading = false;
        });
        /**
         * Search Content API
         *
         * */
        builder.addCase(exports.searchContent.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.isLoading = true;
            state.searchContent.isSearchContentLoading = true;
        });
        builder.addCase(exports.searchContent.fulfilled, (state, action) => {
            var _a;
            // Add logic if any when API state is fulfilled or data is received from API
            state.isLoading = false;
            state.searchContent.isSearchContentLoading = false;
            const searchContentResponse = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data;
            state.searchContent.searchContentData = searchContentResponse;
        });
        builder.addCase(exports.searchContent.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.isLoading = false;
            state.searchContent.isSearchContentLoading = false;
        });
        /**
         * List Recent Search API
         *
         * */
        builder.addCase(exports.listRecentSearch.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.isLoading = true;
            state.listRecentSearch.isListRecentSearchLoading = true;
        });
        builder.addCase(exports.listRecentSearch.fulfilled, (state, action) => {
            var _a;
            // Add logic if any when API state is fulfilled or data is received from API
            state.isLoading = false;
            state.listRecentSearch.isListRecentSearchLoading = false;
            const listRecentSearchResponse = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data;
            state.listRecentSearch.listRecentSearchData = listRecentSearchResponse;
        });
        builder.addCase(exports.listRecentSearch.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.isLoading = false;
            state.listRecentSearch.isListRecentSearchLoading = false;
        });
        /**
         * Delete Recent Search API
         *
         * */
        builder.addCase(exports.deleteRecentSearch.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.isLoading = true;
            state.deleteRecentSearch.isDeleteRecentSearchLoading = true;
        });
        builder.addCase(exports.deleteRecentSearch.fulfilled, (state, action) => {
            var _a;
            // Add logic if any when API state is fulfilled or data is received from API
            state.isLoading = false;
            state.deleteRecentSearch.isDeleteRecentSearchLoading = false;
            const deleteRecentSearchResponse = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data;
            state.deleteRecentSearch.deleteRecentSearchData =
                deleteRecentSearchResponse;
        });
        builder.addCase(exports.deleteRecentSearch.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.isLoading = false;
            state.deleteRecentSearch.isDeleteRecentSearchLoading = false;
        });
        /**
         * List Our Communities API
         *
         * */
        builder.addCase(exports.listOurCommunities.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.isLoading = true;
            state.listOurCommunities.isListOurCommunitiesLoading = true;
        });
        builder.addCase(exports.listOurCommunities.fulfilled, (state, action) => {
            var _a;
            // Add logic if any when API state is fulfilled or data is received from API
            state.isLoading = false;
            state.listOurCommunities.isListOurCommunitiesLoading = false;
            const listOurCommunitiesResponse = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data;
            state.listOurCommunities.listOurCommunitiesData =
                listOurCommunitiesResponse;
        });
        builder.addCase(exports.listOurCommunities.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.isLoading = false;
            state.listOurCommunities.isListOurCommunitiesLoading = false;
        });
        /**
         * Home Page API
         *
         * */
        builder.addCase(exports.fetchHomeContent.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.homeContent.isHomeContentLoading = true;
        });
        builder.addCase(exports.fetchHomeContent.fulfilled, (state, action) => {
            // Add logic if any when API state is fulfilled or data is received from API
            state.homeContent.isHomeContentLoading = false;
            state.homeContent.homeContent = action === null || action === void 0 ? void 0 : action.payload;
        });
        builder.addCase(exports.fetchHomeContent.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.homeContent.isHomeContentLoading = false;
        });
        /**
         * List RSS NEWS FEED API
         *
         * */
        builder.addCase(exports.rssFeedNews.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.isLoading = true;
            state.listRssNewsFeed.isListRssNewsFeedLoading = true;
        });
        builder.addCase(exports.rssFeedNews.fulfilled, (state, action) => {
            var _a, _b, _c, _d;
            // Add logic if any when API state is fulfilled or data is received from API
            state.isLoading = false;
            state.listRssNewsFeed.isListRssNewsFeedLoading = false;
            const rssFeed = (_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.items;
            const rssFeedCount = (_d = (_c = action === null || action === void 0 ? void 0 : action.payload) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.count;
            state.listRssNewsFeed.listRssNewsFeedData = rssFeed;
            state.listRssNewsFeed.rssListCount = rssFeedCount;
        });
        builder.addCase(exports.rssFeedNews.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.isLoading = false;
            state.listRssNewsFeed.isListRssNewsFeedLoading = false;
        });
        /**
         * List Medical Calculator
         *
         * */
        builder.addCase(exports.fetchMedicalCalculator.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.medicalCalculatorContent.isMedicalCalculatorContentLoading = true;
        });
        builder.addCase(exports.fetchMedicalCalculator.fulfilled, (state, action) => {
            // Add logic if any when API state is fulfilled or data is received from API
            state.medicalCalculatorContent.isMedicalCalculatorContentLoading = false;
            state.medicalCalculatorContent.medicalCalculatorContent = action.payload;
        });
        builder.addCase(exports.fetchMedicalCalculator.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.medicalCalculatorContent.isMedicalCalculatorContentLoading = false;
        });
    },
});
exports.fetchHomeContent = (0, toolkit_1.createAsyncThunk)(`${constants_1.HOME}/${constants_1.HOME_CONTENT}`, (filterOptions) => __awaiter(void 0, void 0, void 0, function* () {
    const params = (0, utils_1.filterOptionsParams)(filterOptions);
    const homeContent = yield (0, api_1.get)(`${api_1.baseUrl}${api_1.ENDPOINTS.HOME_API_ENDPOINT}${params}`);
    return homeContent;
}));
exports.featuredContent = (0, toolkit_1.createAsyncThunk)(`${constants_1.HOME}/${constants_1.FEATURED_CONTENT}`, (filterOptions) => __awaiter(void 0, void 0, void 0, function* () {
    const params = (0, utils_1.filterOptionsParams)(filterOptions);
    const featuredContent = yield (0, api_1.get)(`${api_1.ENDPOINTS.FEATURED_CONTENT_ENDPOINT}${params}`, {
        baseURL: api_1.baseUrl
    });
    return featuredContent;
}));
exports.newlyAdded = (0, toolkit_1.createAsyncThunk)(`${constants_1.HOME}/${constants_1.NEWLY_ADDED}`, (filterOptions) => __awaiter(void 0, void 0, void 0, function* () {
    const params = (0, utils_1.filterOptionsParams)(filterOptions);
    const newlyAdded = yield (0, api_1.get)(`${api_1.ENDPOINTS.NEWLY_ADDED_ENDPOINT}${params}`, {
        baseURL: api_1.baseUrl
    });
    return newlyAdded;
}));
exports.listExpert = (0, toolkit_1.createAsyncThunk)(`${constants_1.HOME}/${constants_1.LIST_EXPERT}`, (filterOptions) => __awaiter(void 0, void 0, void 0, function* () {
    const params = (0, utils_1.filterOptionsParams)(filterOptions);
    const listExpert = yield (0, api_1.get)(`${constants_1.LIST_EXPERT_ENDPOINT}${params}`);
    return listExpert;
}));
exports.medicalNews = (0, toolkit_1.createAsyncThunk)(`${constants_1.HOME}/${constants_1.MEDICAL_NEWS}`, (filterOptions) => __awaiter(void 0, void 0, void 0, function* () {
    const params = (0, utils_1.filterOptionsParams)(filterOptions);
    const medicalNews = yield (0, api_1.get)(`${constants_1.MEDICAL_NEWS_ENDPOINT}${params}`);
    return medicalNews;
}));
exports.trendingNow = (0, toolkit_1.createAsyncThunk)(`${constants_1.HOME}/${constants_1.TRENDING_NOW}`, (filterOptions) => __awaiter(void 0, void 0, void 0, function* () {
    const params = (0, utils_1.filterOptionsParams)(filterOptions);
    const trendingNow = yield (0, api_1.get)(`${api_1.ENDPOINTS.TRENDING_NOW_ENDPOINT}${params}`, {
        baseURL: api_1.baseUrl
    });
    return trendingNow;
}));
exports.continueWhereLeft = (0, toolkit_1.createAsyncThunk)(`${constants_1.HOME}/${constants_1.CONTINUE_WHERE_LEFT}`, (filterOptions) => __awaiter(void 0, void 0, void 0, function* () {
    const params = (0, utils_1.filterOptionsParams)(filterOptions);
    const continueWhereLeft = yield (0, api_1.get)(`${api_1.ENDPOINTS.CONTINUE_WHERE_LEFT_ENDPOINT}${params}`, {
        baseURL: api_1.baseUrl
    });
    return continueWhereLeft;
}));
exports.recommendedForYou = (0, toolkit_1.createAsyncThunk)(`${constants_1.HOME}/${constants_1.RECOMMENED_FOR_YOU}`, (filterOptions) => __awaiter(void 0, void 0, void 0, function* () {
    const params = (0, utils_1.filterOptionsParams)(filterOptions);
    const recommendedForYou = yield (0, api_1.get)(`${api_1.ENDPOINTS.RECOMMENED_FOR_YOU_ENDPOINT}${params}`, {
        baseURL: api_1.baseUrl
    });
    return recommendedForYou;
}));
exports.listOfSurvey = (0, toolkit_1.createAsyncThunk)(`${constants_1.HOME}/${constants_1.LIST_SURVEY}`, () => __awaiter(void 0, void 0, void 0, function* () {
    const listOfSurvey = yield (0, api_1.get)(`${constants_1.LIST_SURVEY_ENDPOINT}`, {
        baseURL: api_1.BASE_URL_NODE,
    });
    return listOfSurvey;
}));
exports.contentDetails = (0, toolkit_1.createAsyncThunk)(`${constants_1.HOME}/${constants_1.CONTENT_DETAILS}`, (payload) => __awaiter(void 0, void 0, void 0, function* () {
    const params = (0, utils_1.generateQueryString)(payload === null || payload === void 0 ? void 0 : payload.obj);
    const contentDetails = yield (0, api_1.get)(`${api_1.ENDPOINTS.CONTENT_DETAILS_ENDPOINT}/${payload === null || payload === void 0 ? void 0 : payload.id}${params}`, {
        baseURL: api_1.baseUrl
    });
    return contentDetails;
}));
exports.searchContent = (0, toolkit_1.createAsyncThunk)(`${constants_1.HOME}/${constants_1.SEARCH_CONTENT}`, (payload) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const category = (_a = payload === null || payload === void 0 ? void 0 : payload.category) !== null && _a !== void 0 ? _a : "";
    const searchContent = yield (0, api_1.get)(`${constants_1.SEARCH_CONTENT_ENDPOINT}?keyword=${payload.searchText ? payload.searchText : ""}&category=${category}&content-type=${payload.filter}&skip=${payload.skip}&take=${payload.take}`, {
        baseURL: api_1.BASE_URL_NODE,
    });
    return searchContent;
}));
exports.listRecentSearch = (0, toolkit_1.createAsyncThunk)(`${constants_1.HOME}/${constants_1.LIST_RECENT_SEARCH}`, (params) => __awaiter(void 0, void 0, void 0, function* () {
    const listRecentSearch = yield (0, api_1.get)(`${constants_1.LIST_RECENT_SEARCH_ENDPOINT}?skip=${params.skip ? params.skip : 0}&take=${params.take ? params.take : 3}`, {
        baseURL: api_1.BASE_URL_NODE,
    });
    return listRecentSearch;
}));
exports.deleteRecentSearch = (0, toolkit_1.createAsyncThunk)(`${constants_1.HOME}/${constants_1.DELETE_RECENT_SEARCH}`, (contentId) => __awaiter(void 0, void 0, void 0, function* () {
    const deleteRecentSearch = yield (0, api_1.del)(`${constants_1.DELETE_RECENT_SEARCH_ENDPOINT}/${contentId ? contentId : ""}`, {
        baseURL: api_1.BASE_URL_NODE,
    });
    return deleteRecentSearch;
}));
exports.listOurCommunities = (0, toolkit_1.createAsyncThunk)(`${constants_1.HOME}/${constants_1.LIST_OUR_COMMUNITIES}`, (filterOptions) => __awaiter(void 0, void 0, void 0, function* () {
    const params = (0, utils_1.filterOptionsParams)(filterOptions);
    const listOurCommunities = yield (0, api_1.get)(`${constants_1.LIST_OUR_COMMUNITIES_ENDPOINT}${params}`);
    return listOurCommunities;
}));
exports.rssFeedNews = (0, toolkit_1.createAsyncThunk)(`${constants_1.HOME}/${constants_1.LIST_RSS_NEWS_FEED}`, (filterOptions) => __awaiter(void 0, void 0, void 0, function* () {
    const params = (0, utils_1.filterOptionsParams)(filterOptions);
    const req = filterOptions === null || filterOptions === void 0 ? void 0 : filterOptions.req;
    const rssFeedNews = yield (0, api_1.post)(`${constants_1.LIST_RSS_NEWS_FEED_ENDPOINT}${params}`, req, {
        baseURL: api_1.BASE_URL_NODE,
    });
    return rssFeedNews;
}));
exports.fetchMedicalCalculator = (0, toolkit_1.createAsyncThunk)(`${constants_1.HOME}/${constants_1.LIST_MEDICAL_CALCULATOR}`, (params) => __awaiter(void 0, void 0, void 0, function* () {
    const medicalCalculatorResponse = yield (0, api_1.get)(`${constants_1.LIST_MEDICAL_CALCULATOR_ENDPOINT}?interest=${params === null || params === void 0 ? void 0 : params.interest}`);
    return medicalCalculatorResponse;
}));
exports.getFeaturedContent = (0, toolkit_1.createSelector)((state) => state.home, (home) => { var _a; return (_a = home === null || home === void 0 ? void 0 : home.featuredList) === null || _a === void 0 ? void 0 : _a.featuredListData; });
// export const getNewlyAddedContent = createSelector(
// 	(state: RootState) => state.home,
// 	(home) => home?.newlyAdded?.newlyAddedData
// );
const staticExperts = [
    {
        uid: "203",
        field_first_name: "Dr. Oluranti B",
        field_last_name: "Familoni",
        field_user_specialization: "Cardiology",
        user_picture: "/sites/default/files/pictures/2024-03/IMAGE2.png",
        field_country: "Nigeria",
        field_qualification: "MB, BS(IB), MD(NIG), FMCP, FNCS, FACC",
        field_designation: "Professor of Medicine, Consultant Cardiologist",
        field_description: "Dr. Oluranti B Familoni is a Professor of Medicine and Consultant Cardiologist from Nigeria. He has been a member of the Faculty Board of Internal Medicine at the National Postgraduate Medical College of Nigeria since 2004 and has served as an Editorial Consultant for Isegun Journal since 2002. Additionally, Dr. Familoni has been a member of the Editorial Boards for Medical Science Monitor and Cardiovascular Journal of Africa since 2005 and 2008, respectively. He has published 63 papers in leading medical journals and authored 2 books on Diabetes. ",
    },
    {
        uid: "197",
        field_first_name: "Dr. Kushal Dilip",
        field_last_name: "Sarda",
        field_user_specialization: "Pharmacology",
        user_picture: "/sites/default/files/pictures/2024-02/user_picture_271.png",
        field_country: "India",
        field_qualification: "MBBS, MD",
        field_designation: "GM & Head– Medical Affairs, Clinical Research & Pharmacovigilance",
        field_description: "Dr. Kushal Dilip Sarda is the GM & Head–Medical Affairs, Clinical Research & Pharmacovigilance at Shalina Healthcare. He has over 10 years of medical expertise, spanning multiple therapeutic areas, including Neurology, Psychiatry, Infections, and Gastroenterology. Furthermore, Dr. Kushal has initiated and executed over 25 clinical studies across all phases, authored 15 papers and contributed to the drafting of multiple therapy guidelines.",
    },
    {
        uid: "193",
        field_first_name: "Dr. Sodipo Oluwajimi",
        field_last_name: "Olenrewaju",
        field_user_specialization: "Internal Medicine",
        user_picture: "/sites/default/files/pictures/2024-03/image%201.png",
        field_country: "Nigeria",
        field_qualification: "MBBS, FWACP, FMCFM",
        field_designation: "Consultant Family Physician",
        field_description: "Dr. Sodipo Oluwajimi Olenrewaju is a Consultant Family Physician and the Head of Department of Family Medicine at Lagos State University Teaching Hospital, Ikeja. He is an active member of NMA, MDCAN, SOFPON, EMSON and ACEN, and also serves as the site coordinator for NCDC's Influenza Surveillance program. Renowned for his research on chronic medical illnesses, he has to his credit, multiple papers in national and international journals. Additionally, he has been a key member in drafting the Lagos State Asthma Guideline. ",
    },
];
exports.getExpertList = (0, toolkit_1.createSelector)((state) => state.home, (home) => {
    var _a;
    if (api_1.SHOW_DEFAULT_EXPERTS) {
        return staticExperts;
    }
    else {
        return (_a = home === null || home === void 0 ? void 0 : home.listExpert) === null || _a === void 0 ? void 0 : _a.listExpertData;
    }
});
// export const getMedicalNews = createSelector(
// 	(state: RootState) => state.home,
// 	(home) => home?.medicalNews?.medicalNewsData
// );
// export const getTrendingNow = createSelector(
// 	(state: RootState) => state.home,
// 	(home) => home?.trendingNow?.trendingNowData
// );
// export const getContinueWhereLeft = createSelector(
// 	(state: RootState) => state.home,
// 	(home) => home?.continueWhereLeft?.continueWhereLeftData
// );
// export const getRecommendedForYou = createSelector(
// 	(state: RootState) => state.home,
// 	(home) => home?.recommendedForYou?.recommendedForYouData
// );
exports.getSurveyList = (0, toolkit_1.createSelector)((state) => state.home, (home) => { var _a; return (_a = home === null || home === void 0 ? void 0 : home.listOfSurveyState) === null || _a === void 0 ? void 0 : _a.listOfSurveyData; });
exports.getInitialSurveyList = (0, toolkit_1.createSelector)((state) => state.home, (home) => {
    var _a, _b, _c, _d, _e;
    if (((_c = (_b = (_a = home === null || home === void 0 ? void 0 : home.listOfSurveyState) === null || _a === void 0 ? void 0 : _a.listOfSurveyData) === null || _b === void 0 ? void 0 : _b.surveys) === null || _c === void 0 ? void 0 : _c.length) > 0) {
        return (_e = (_d = home === null || home === void 0 ? void 0 : home.listOfSurveyState) === null || _d === void 0 ? void 0 : _d.listOfSurveyData) === null || _e === void 0 ? void 0 : _e.surveys[0];
    }
    return null;
});
exports.getContentDetails = (0, toolkit_1.createSelector)((state) => state.home, (home) => { var _a, _b; return (_b = (_a = home === null || home === void 0 ? void 0 : home.contentDetails) === null || _a === void 0 ? void 0 : _a.contentDetailsData) === null || _b === void 0 ? void 0 : _b.data; });
exports.getContentDetailItems = (0, toolkit_1.createSelector)((state) => state.home, (home) => {
    var _a, _b, _c, _d, _e, _f;
    if (((_c = (_b = (_a = home === null || home === void 0 ? void 0 : home.contentDetails) === null || _a === void 0 ? void 0 : _a.contentDetailsData) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.length) > 0) {
        return (_f = (_e = (_d = home === null || home === void 0 ? void 0 : home.contentDetails) === null || _d === void 0 ? void 0 : _d.contentDetailsData) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f[0];
    }
    return null;
});
exports.getSearchContent = (0, toolkit_1.createSelector)((state) => state.home, (home) => { var _a; return (_a = home === null || home === void 0 ? void 0 : home.searchContent) === null || _a === void 0 ? void 0 : _a.searchContentData; });
exports.getListRecenetSearch = (0, toolkit_1.createSelector)((state) => state.home, (home) => { var _a; return (_a = home === null || home === void 0 ? void 0 : home.listRecentSearch) === null || _a === void 0 ? void 0 : _a.listRecentSearchData; });
exports.getListRecentSearchResult = (0, toolkit_1.createSelector)((state) => state.home, (home) => { var _a, _b; return (_b = (_a = home === null || home === void 0 ? void 0 : home.listRecentSearch) === null || _a === void 0 ? void 0 : _a.listRecentSearchData) === null || _b === void 0 ? void 0 : _b.history; });
exports.getDeleteRecentSearch = (0, toolkit_1.createSelector)((state) => state.home, (home) => { var _a; return (_a = home === null || home === void 0 ? void 0 : home.deleteRecentSearch) === null || _a === void 0 ? void 0 : _a.deleteRecentSearchData; });
exports.getListOurCommunities = (0, toolkit_1.createSelector)((state) => state.home, (home) => { var _a; return (_a = home === null || home === void 0 ? void 0 : home.listOurCommunities) === null || _a === void 0 ? void 0 : _a.listOurCommunitiesData; });
exports.listRssNewsFeed = (0, toolkit_1.createSelector)((state) => state.home, (home) => { var _a; return (_a = home === null || home === void 0 ? void 0 : home.listRssNewsFeed) === null || _a === void 0 ? void 0 : _a.listRssNewsFeedData; });
exports.getNewlyAddedContent = (0, toolkit_1.createSelector)((state) => state.home, (home) => {
    var _a, _b, _c, _d;
    const newlyAddedList = (_d = (_c = (_b = (_a = home === null || home === void 0 ? void 0 : home.homeContent) === null || _a === void 0 ? void 0 : _a.homeContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.api) === (reducer_constants_1.HOME_API_KEY === null || reducer_constants_1.HOME_API_KEY === void 0 ? void 0 : reducer_constants_1.HOME_API_KEY.NEWLY_ADDED))) === null || _d === void 0 ? void 0 : _d.result;
    return newlyAddedList && (newlyAddedList === null || newlyAddedList === void 0 ? void 0 : newlyAddedList.length) > 0 ? newlyAddedList : [];
});
exports.getTrendingNow = (0, toolkit_1.createSelector)((state) => state.home, (home) => {
    var _a, _b, _c, _d;
    const trendingList = (_d = (_c = (_b = (_a = home === null || home === void 0 ? void 0 : home.homeContent) === null || _a === void 0 ? void 0 : _a.homeContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.api) === (reducer_constants_1.HOME_API_KEY === null || reducer_constants_1.HOME_API_KEY === void 0 ? void 0 : reducer_constants_1.HOME_API_KEY.TRENDING_NOW))) === null || _d === void 0 ? void 0 : _d.result;
    return trendingList && (trendingList === null || trendingList === void 0 ? void 0 : trendingList.length) > 0 ? trendingList : [];
});
exports.getMedicalNews = (0, toolkit_1.createSelector)((state) => state.home, (home) => {
    var _a, _b, _c, _d;
    const medicalNewsList = (_d = (_c = (_b = (_a = home === null || home === void 0 ? void 0 : home.homeContent) === null || _a === void 0 ? void 0 : _a.homeContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.api) === (reducer_constants_1.HOME_API_KEY === null || reducer_constants_1.HOME_API_KEY === void 0 ? void 0 : reducer_constants_1.HOME_API_KEY.MEDICAL_NEWS))) === null || _d === void 0 ? void 0 : _d.result;
    return medicalNewsList && (medicalNewsList === null || medicalNewsList === void 0 ? void 0 : medicalNewsList.length) > 0
        ? medicalNewsList
        : [];
});
exports.getContinueWhereLeft = (0, toolkit_1.createSelector)((state) => state.home, (home) => {
    var _a, _b, _c, _d;
    const continueWhereYouLeftList = (_d = (_c = (_b = (_a = home === null || home === void 0 ? void 0 : home.homeContent) === null || _a === void 0 ? void 0 : _a.homeContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.api) === (reducer_constants_1.HOME_API_KEY === null || reducer_constants_1.HOME_API_KEY === void 0 ? void 0 : reducer_constants_1.HOME_API_KEY.CONTINUE_WHERE_YOU_LEFT))) === null || _d === void 0 ? void 0 : _d.result;
    return continueWhereYouLeftList && (continueWhereYouLeftList === null || continueWhereYouLeftList === void 0 ? void 0 : continueWhereYouLeftList.length) > 0
        ? continueWhereYouLeftList
        : [];
});
exports.getRecommendedForYou = (0, toolkit_1.createSelector)((state) => state.home, (home) => {
    var _a, _b, _c, _d;
    const recommendedList = (_d = (_c = (_b = (_a = home === null || home === void 0 ? void 0 : home.homeContent) === null || _a === void 0 ? void 0 : _a.homeContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.api) === (reducer_constants_1.HOME_API_KEY === null || reducer_constants_1.HOME_API_KEY === void 0 ? void 0 : reducer_constants_1.HOME_API_KEY.RECOMMENDED_FOR_YOU))) === null || _d === void 0 ? void 0 : _d.result;
    return recommendedList && (recommendedList === null || recommendedList === void 0 ? void 0 : recommendedList.length) > 0
        ? recommendedList
        : [];
});
exports.updateBookmarkStateForContentDetails = homeSlice.actions.updateBookmarkStateForContentDetails;
exports.default = homeSlice.reducer;
