import { HorizontalDividerProps } from '.';
import styles from './horizontalDivider.module.scss';

export const HorizontalDivider: React.FC<HorizontalDividerProps> = () => {
  return (
    <div className={styles.horizontalDivider}>
      <span>or</span>
    </div>
  );
};
