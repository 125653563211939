import { AppConstant, ImageConstant, translate } from '@shalina-app/shared';
import parse from 'html-react-parser';

import { CommunityAboutProps } from '..';
import styles from '../CommunityDetail.module.scss';

export const CommunityAbout: React.FC<CommunityAboutProps> = ({
  description
}) => {
  const t = translate();
  return (
    <>
      {description ? (
        <div className={styles.aboutDetails}>
          <p>{parse(description)}</p>
        </div>
      ) : (
        <div className={styles.noData}>
          <img
            className={styles.noDataImg}
            src={ImageConstant.SOURCE.emptyContent}
            alt='img'
          />
          <p className={styles.noDataMsg}>
            {t(AppConstant.COMMUNITY.NO_DESCRIPTION_AVAILABLE_FOR_COMMUNITY)}
          </p>
        </div>
      )}
    </>
  );
};
