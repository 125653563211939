import { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';

import { CheckboxProps } from '.';
import styles from './checkbox.module.scss';

export const CheckboxComponent: React.FC<CheckboxProps> = (props) => {
  const { labelText, defaultChecked, errorMessage, isError, onChange } = props;

  const [isChecked, setIsChecked] = useState(defaultChecked || false);

  useEffect(() => {
    // Update internal state when the defaultChecked prop changes
    setIsChecked(defaultChecked || false);
  }, [defaultChecked]);

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newChecked = e.target.checked;
    setIsChecked(newChecked);
    onChange(labelText, newChecked);
  };

  return (
    <div className={styles['shalina-checkbox']}>
      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={isChecked}
              defaultChecked={defaultChecked}
              onChange={handleSwitchChange}
            />
          }
          label={labelText}
        />
        {isError && <FormHelperText error>{errorMessage}</FormHelperText>}
      </FormControl>
    </div>
  );
};
