"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImageConstant = void 0;
const svg_1 = require("./svg");
class ImageConstant {
}
exports.ImageConstant = ImageConstant;
ImageConstant.SOURCE = {
    dummyCardImg: require("../assets/images/card-img.png"),
    guestUserImg: require("../assets/images/guest_user.png"),
    logoutUserImg: require("../assets/images/logoutUser.png"),
    shalinaLabelImg: require("../assets/images/Shalina_Healthcare_Logo.png"),
    healthCareLabelImg: require("../assets/images/Healthcare_Logo.png"),
    bronzeLevelImg: require("../assets/images/bronze_level.png"),
    shalinaImagePattern: require("../assets/images/shalina-pattern.png"),
    communityLogoImg: require("../assets/images/community_logo.png"),
    overlayFirstImg: require("../assets/images/overlay_image_1.png"),
    overlaySecondImg: require("../assets/images/overlay_image_2.png"),
    journalImg: require("../assets/images/journal_image.png"),
    ebookImg: require("../assets/images/E-book_logo.png"),
    shalinaTvImg: require("../assets/images/shalina_tv.png"),
    expertOpinionImg: require("../assets/images/expert_opinion.png"),
    userCommunityImg: require("../assets/images/user_community.png"),
    GoldBronzeImg: require("../assets/images/GoldBronze.png"),
    silverBronzeImg: require("../assets/images/silverBronze.png"),
    goldMemberBadgeImg: require("../assets/images/GoldMembership.png"),
    requestedContentImg: require("../assets/images/requested-content.png"),
    peopleImg: require("../assets/images/peoples.png"),
    basketImg: require("../assets/images/basket.png"),
    realFakeImg: require("../assets/images/real_fake.png"),
    rectangleImg: require("../assets/images/Rectangle.png"),
    lineImg: require("../assets/images/Line.png"),
    headImg: require("../assets/images/Head.png"),
    brainImg: require("../assets/images/Brain.png"),
    aeroplaneImg: require("../assets/images/Aeroplane.png"),
    manImg: require("../assets/images/Man.png"),
    signUp: require("../assets/images/GuestUserSignUp.png"),
    medspaceShalina: require("../assets/images/Medspace_Shalina_Logo.png"),
    ShalinaMedspaceLogo: require("../assets/images/Medspace_Logo.png"),
    emptyContent: require("../assets/images/empty_content.png"),
    emptyNotification: require("../assets/images/empty_notification.png"),
    emptyBookmark: require("../assets/images/empty_bookmarks.png"),
    emptyRegisteredEvent: require("../assets/images/EmptyRegisteredEvents.png"),
    footerLogo: require("../assets/images/Medspace_Logo_footer.png"),
    healthcareFooterLogo: require("../assets/images/Healthcare_Logo_footer.png"),
    facebook: require("../assets/images/facebook.png"),
    youtube: require("../assets/images/youTube.png"),
    twitter: require("../assets/images/twitter.png"),
    linkedin: require("../assets/images/linkedin.png"),
    guestSignup: require("../assets/images/guest_signup.png"),
    exploreCommunities: require("../assets/images/Explore_Communities.png"),
    takeSurvey: require("../assets/images/Take_Survey.png"),
    appleStore: require("../assets/images/AppleStore.png"),
    googlePlay: require("../assets/images/GooglePlay.png"),
    engFlag: require("../assets/images/EngFlag.png"),
    frenchFlag: require("../assets/images/FrenchFlag.png"),
    portugueFlag: require("../assets/images/PortugueFlag.png"),
    languageImg: require("../assets/images/Language.png"),
    notificationImg: require("../assets/images/Notification.png"),
    signOutImg: require("../assets/images/SignOut.png"),
    rightArrowImg: require("../assets/images/RightArrow.png"),
    emptyDownload: require("../assets/images/empty_download.png"),
    cameraImg: require("../assets/images/Camera.png"),
    deleteImg: require("../assets/images/delete.png"),
    uploadImg: require("../assets/images/upload.png"),
    contentNotFoundImg: require("../assets/images/contentNotFound.png"),
    Journeypeoples: require("../assets/images/Journeypeoples.png"),
    defaultUserPic: require("../assets/images/default_user_pic.png"),
    cardDefaultThumbnail: require("../assets/images/Card_default_thumbnail.png"),
    bannerCardThumbnail: require("../assets/images/Banner_card_thumbnail.png"),
    eBookDetailThumbnail: require("../assets/images/eBook_detail_page_thumbnail.png"),
    eBookHorizontalCardThumbnail: require("../assets/images/eBook_horizontal_card_thumbnail.png"),
    articleDetailThumbnail: require("../assets/images/Article_detail_page_thumbnail.png"),
    videoDetailThumbnailOld: require("../assets/images/default_detail_img.png"),
    likeImg: require("../assets/images/like_img.png"),
    successStar: require("../assets/images/successStar.png"),
    questionMark: require("../assets/images/questionMark.png"),
    GoldBronzeNew: require("../assets/images/GoldBronze_New.png"),
    newGuestUserImg: require("../assets/images/NewGuestUser.png"),
    requestedImgs: require("../assets/images/requestedImg.png"),
    pdfCardThumbnail: require("../assets/images/pdf_card_thumbnail.png"),
    resourcesCardThumbnail: require("../assets/images/resources_card_thumbnail.png"),
    videoCardThumbnail: require("../assets/images/video_card_thumbnail.png"),
    webinarsCardThumbnail: require("../assets/images/webinars_card_thumbnail.png"),
    webinarsDetailThumbnail: require("../assets/images/webinars_detail_thumbnail.png"),
    pdfDetailThumbnail: require("../assets/images/pdf_detail_thumbnail.png"),
    videoDetailThumbnail: require("../assets/images/video_detail_thumbnail.png"),
    aboutUs: require("../assets/images/about-us1.png"),
    aboutImg: require("../assets/images/aboutUs.png"),
    surveyTickImg: require("../assets/images/surveyTick.png"),
    aboutUsCompanyImage: require("../assets/images/company.png"),
    Practice_Enhancement: require("../assets/images/Practice_Enhancement.png"),
    Knowledge_Building: require("../assets/images/Knowledge_Building.png"),
    Practical_Learning: require("../assets/images/Practical_Learning.png"),
    aboutUsNews: require("../assets/images/news.png"),
    aboutUsHistory: require("../assets/images/history.png"),
    aboutUsDoctors: require("../assets/images/doctors.png"),
    welcomeToCommunities: require("../assets/images/Welcome_to_Communities.png"),
    communityIcon: require("../assets/images/Communities_icon.png"),
    communityLargeIcon: require("../assets/images/Communities_icon_large.png"),
    pdfImage: require("../assets/images/PDF_image.png"),
    playCircle: require("../assets/images/Play_circle.png"),
    instagram: require("../assets/images/instagram.png"),
    newsIcon: require("../assets/images/NewsIcon.png"),
    newsBlueIcon: require("../assets/images/news_blue_icon.png"),
    VideoIcon: require("../assets/images/Video.png"),
    EBookIcon: require("../assets/images/EBook.png"),
    WebinarIcon: require("../assets/images/Webinar.png"),
    MagazineIcon: require("../assets/images/Magazine.png"),
    Medical_NewsIcon: require("../assets/images/Medical_News.png"),
    ClinicalGuidelinesIcon: require("../assets/images/Clinical_Guidelines.png"),
    MedicalCalculatorsIcon: require("../assets/images/Medical_Calculators.png"),
    ClinicalCriteriaIcon: require("../assets/images/Clinical_Criteria.png"),
    PracticeEssentialsIcon: require("../assets/images/Practice_Essentials.png"),
    DecisiontreesIcon: require("../assets/images/Decision_trees.png"),
    SurgeriesProceduresIcon: require("../assets/images/Surgeries_Procedures.png"),
    ResourcesIcon: require("../assets/images/Resources.png"),
    SurveysIcon: require("../assets/images/Surveys.png"),
    guidelineIcon: require("../assets/images/guidelineIcon.png"),
    bronzeGradient: require("../assets/images/BronzeGradient.png"),
    silverGradient: require("../assets/images/SilverGradient.png"),
    goldGradient: require("../assets/images/GoldGradient.png"),
    MarketingImg: require("../assets/images/marketingImg.png"),
    MedicalNewsIcon: require("../assets/images/news_feed_icon.png"),
    PdfIcon: require("../assets/images/Orange_PDF.png"),
    AvidReader: require("../assets/images/Avid_Reader.png"),
    ConsistentReader: require("../assets/images/Consistent_Reader.png"),
    PeerLearner: require("../assets/images/Peer_Learner.png"),
    ShareMaster: require("../assets/images/Share_Master.png"),
    FullAttendance: require("../assets/images/Full_Attendance.png"),
    BadgeNA: require("../assets/images/Badge_NA.png"),
};
ImageConstant.SVG_ICONS = {
    BronzeImg: svg_1.BronzeIcon,
    RightArrowIcon: svg_1.RightArrowIcon,
    ApprovalIcon: svg_1.ApprovalIcon,
    ShalinaLogo: svg_1.ShalinaLogo,
    NotificationIcon: svg_1.NotificationIcon,
    ProfileIcon: svg_1.ProfileIcon,
    DropDownArrowIcon: svg_1.DropDownArrowIcon,
    ViewsIcon: svg_1.ViewsIcon,
    DurationIcon: svg_1.DurationIcon,
    TimeIcon: svg_1.TimeIcon,
    DateIcon: svg_1.DateIcon,
    AuthorIcon: svg_1.AuthorIcon,
    BookmarkIcon: svg_1.BookmarkIcon,
    BookmarkSmallIcon: svg_1.BookmarkSmallIcon,
    BannerBg: svg_1.BannerBg,
    FilterIcon: svg_1.FilterIcon,
    BellIcon: svg_1.BellIcon,
    BellUnReadIcon: svg_1.BellUnReadIcon,
    BellOrangeIcon: svg_1.BellOrangeIcon,
    SortIcon: svg_1.SortIcon,
    ShareIcon: svg_1.ShareIcon,
    ThumbIcon: svg_1.ThumbIcon,
    SurveyIcon: svg_1.SurveyIcon,
    DosageIcon: svg_1.DosageIcon,
    AssessmentIcon: svg_1.AssessmentIcon,
    MonitorIcon: svg_1.MonitorIcon,
    TreatmentIcon: svg_1.TreatmentIcon,
    AboutIcon: svg_1.AboutIcon,
    EditIcon: svg_1.EditIcon,
    AchievementsIcon: svg_1.AchievementsIcon,
    AchievementsSmallIcon: svg_1.AchievementsSmallIcon,
    TVIcon: svg_1.TVIcon,
    RequestIcon: svg_1.RequestIcon,
    ContentIcon: svg_1.ContentIcon,
    AccountIcon: svg_1.AccountIcon,
    SignOutIcon: svg_1.SignOutIcon,
    LanguageIcon: svg_1.LanguageIcon,
    SettingIcon: svg_1.SettingIcon,
    StarIcon: svg_1.StarIcon,
    LocationIcon: svg_1.LocationIcon,
    DownloadIcon: svg_1.DownloadIcon,
    ThumbActiveIcon: svg_1.ThumbActiveIcon,
    UploadIcon: svg_1.UploadIcon,
    AuthenticationBgIcon: svg_1.AuthenticationBgIcon,
    CloseIcon: svg_1.CloseIcon,
    SliderArrowIcon: svg_1.SliderArrowIcon,
    AttachedIcon: svg_1.AttachedIcon,
    MessageIcon: svg_1.MessageIcon,
    AnswerQuestionsIcon: svg_1.AnswerQuestionsIcon,
    DiscussionsIcon: svg_1.DiscussionsIcon,
    ExploreCommunityIcon: svg_1.ExploreCommunityIcon,
    CommunityRequestIcon: svg_1.CommunityRequestIcon,
    SendIcon: svg_1.SendIcon,
    ClockIcon: svg_1.ClockIcon,
    TagIcon: svg_1.TagIcon,
    CrownIcon: svg_1.CrownIcon,
    TickIcon: svg_1.TickIcon,
    CopyClipboardIcon: svg_1.CopyClipboardIcon
};
