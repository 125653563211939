"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AboutIcon = void 0;
const React = __importStar(require("react"));
const AboutIcon = (props) => (React.createElement("svg", { width: "23", height: "20", viewBox: "0 0 23 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M20.16 8.42H14.53C14.53 8.42 14.44 8.42 14.39 8.42H2.84C1.83 8.42 1 9.25 1 10.27V12.65C1 13.3 1.15 13.94 1.43 14.52C1.71 15.1 2.13 15.61 2.64 16.01C3.15 16.41 3.75 16.68 4.38 16.8C4.83 16.89 6.44 16.9 7.55 16.84C7.73 17.11 7.93 17.37 8.16 17.6C9.05 18.49 10.25 19 11.5 19C12.75 19 13.96 18.5 14.84 17.6C15.09 17.35 15.3 17.07 15.48 16.77C16.54 16.88 18.04 16.91 18.61 16.8C19.24 16.67 19.84 16.4 20.35 16.01C20.86 15.61 21.27 15.11 21.56 14.52C21.85 13.93 21.99 13.3 21.99 12.65V10.27C21.99 9.25 21.17 8.42 20.15 8.42H20.16ZM4.64 15.24C4.25 15.15 3.89 14.98 3.58 14.73C3.27 14.48 3.02 14.17 2.84 13.81C2.67 13.45 2.58 13.05 2.58 12.65V10.27C2.58 10.2 2.61 10.13 2.66 10.08C2.71 10.03 2.78 10 2.85 10H6.82C6.81 10.09 6.79 10.17 6.79 10.26V14.23C6.79 14.59 6.84 14.94 6.92 15.28C6.02 15.3 4.89 15.28 4.65 15.22L4.64 15.24ZM14.66 14.24C14.66 15.08 14.33 15.89 13.74 16.49C13.15 17.09 12.35 17.42 11.51 17.42C10.67 17.42 9.87 17.09 9.28 16.49C8.69 15.89 8.36 15.09 8.36 14.24V10.27C8.36 10.2 8.39 10.13 8.44 10.08C8.49 10.03 8.56 10 8.63 10H14.41C14.48 10 14.55 10.03 14.6 10.08C14.65 10.13 14.68 10.2 14.68 10.27V14.24H14.66ZM20.43 12.65C20.43 13.05 20.34 13.44 20.17 13.8C20 14.16 19.75 14.47 19.44 14.72C19.13 14.97 18.77 15.14 18.38 15.23C18.07 15.3 16.88 15.3 16.12 15.26C16.19 14.93 16.24 14.58 16.24 14.24V10.27C16.24 10.18 16.23 10.09 16.21 10.01H20.17C20.24 10.01 20.31 10.04 20.36 10.09C20.41 10.14 20.44 10.21 20.44 10.28V12.66L20.43 12.65Z", fill: "#E84E1B", stroke: "#E84E1B", "stroke-width": "0.35" }),
    React.createElement("path", { d: "M4.68005 7.36C5.38005 7.36 6.04005 7.08 6.54005 6.58C7.03005 6.08 7.31005 5.41 7.31005 4.71C7.31005 4.01 7.03005 3.33 6.54005 2.84C6.05005 2.34 5.38005 2.06 4.68005 2.06C3.98005 2.06 3.32005 2.34 2.82005 2.84C2.33005 3.34 2.05005 4.01 2.05005 4.71C2.05005 5.41 2.33005 6.09 2.82005 6.58C3.31005 7.08 3.98005 7.36 4.68005 7.36ZM3.94005 3.96C4.14005 3.76 4.40005 3.65 4.68005 3.65C4.96005 3.65 5.23005 3.76 5.42005 3.96C5.61005 4.16 5.73005 4.43 5.73005 4.71C5.73005 4.99 5.62005 5.26 5.42005 5.46C5.22005 5.66 4.96005 5.77 4.68005 5.77C4.40005 5.77 4.13005 5.66 3.94005 5.46C3.74005 5.26 3.63005 4.99 3.63005 4.71C3.63005 4.43 3.74005 4.16 3.94005 3.96Z", fill: "#E84E1B", stroke: "#E84E1B", "stroke-width": "0.35" }),
    React.createElement("path", { d: "M18.33 7.36C19.03 7.36 19.69 7.08 20.19 6.58C20.68 6.08 20.96 5.41 20.96 4.71C20.96 4.01 20.68 3.33 20.19 2.84C19.7 2.34 19.03 2.06 18.33 2.06C17.63 2.06 16.97 2.34 16.47 2.84C15.98 3.34 15.7 4.01 15.7 4.71C15.7 5.41 15.98 6.09 16.47 6.58C16.96 7.08 17.63 7.36 18.33 7.36ZM17.59 3.96C17.79 3.76 18.05 3.65 18.33 3.65C18.61 3.65 18.88 3.76 19.07 3.96C19.27 4.16 19.38 4.43 19.38 4.71C19.38 4.99 19.27 5.26 19.07 5.46C18.87 5.66 18.61 5.77 18.33 5.77C18.05 5.77 17.78 5.66 17.59 5.46C17.39 5.26 17.28 4.99 17.28 4.71C17.28 4.43 17.39 4.16 17.59 3.96Z", fill: "#E84E1B", stroke: "#E84E1B", "stroke-width": "0.35" }),
    React.createElement("path", { d: "M11.5 7.36C12.34 7.36 13.14 7.03 13.73 6.43C14.32 5.83 14.65 5.03 14.65 4.18C14.65 3.33 14.32 2.53 13.73 1.93C13.14 1.33 12.34 1 11.5 1C10.66 1 9.85998 1.33 9.26998 1.93C8.67998 2.53 8.34998 3.33 8.34998 4.18C8.34998 5.03 8.67998 5.83 9.26998 6.43C9.85998 7.03 10.66 7.36 11.5 7.36ZM10.39 3.06C10.69 2.76 11.09 2.59 11.5 2.59C11.91 2.59 12.32 2.76 12.61 3.06C12.9 3.36 13.07 3.76 13.07 4.18C13.07 4.6 12.9 5.01 12.61 5.3C12.32 5.59 11.91 5.77 11.5 5.77C11.09 5.77 10.68 5.6 10.39 5.3C10.09 5 9.92998 4.6 9.92998 4.18C9.92998 3.76 10.1 3.35 10.39 3.06Z", fill: "#E84E1B", stroke: "#E84E1B", "stroke-width": "0.35" })));
exports.AboutIcon = AboutIcon;
