import {
  AppConstant,
  CONTENT_TYPE_ID,
  CUSTOM_ANALYTICS_EVENT_NAMES,
  EXTRA_CARD_CONTENT_STRING,
  getAnalyticsUserDetails,
  getDefaultImage,
  getDefaultValue,
  getExtraCardContent,
  getFilterCategoriesOptionsValues,
  getHideAuthor,
  getHideInterestFlag,
  getHideViewsFlag,
  getIconForContentType,
  getLoggedInUserInfo,
  getShowDurationFlag,
  hideDate,
  hideImage,
  hideTime,
  ImageConstant,
  logEvent,
  PlatformNames,
  translate,
  updateBookmark,
  useAppDispatch,
  useAppSelector
} from '@shalina-app/shared';
import { useEffect, useState } from 'react';

import { BannerCardProps } from '.';
import { pageNames } from '../../../../app.constants';
import { appInsights } from '../../../../applicationInsight';
import { Speciality } from '../../../../components';
import { URLConstant } from '../../../../routes';
import {
  getImageSrc,
  getPageNameByCategory,
  retrieveData,
  STORAGE_CONSTANTS,
  useNavigatePageUtils
} from '../../../../utils';
import { SignUpPopup } from '../../../Authentication';
import styles from './homeBanner.module.scss';

export const BannerCard: React.FC<BannerCardProps> = ({
  id,
  contentType,
  contentTypeId,
  title,
  speciality,
  publishedName,
  date,
  time,
  viewsCount,
  duration,
  thumbnailImage,
  mostViewed,
  isBookmarked,
  uniqueContentTypeId,
  eventId
}) => {
  const t = translate();
  const dispatch = useAppDispatch();
  const navigatePageUtils = useNavigatePageUtils();
  const { urls } = URLConstant;
  const isLoggedIn: boolean = retrieveData(STORAGE_CONSTANTS.IS_LOGGED_IN);
  const user = retrieveData(STORAGE_CONSTANTS.USER);

  const contentIcon: any = useAppSelector(
    getIconForContentType(uniqueContentTypeId)
  );
  const masterContentTypeData = useAppSelector(
    getFilterCategoriesOptionsValues
  );
  const userDetails = useAppSelector(getLoggedInUserInfo);
  
  const [showSignUp, setShowSignUp] = useState(false);
  const [bookmarked, setBookmarked] = useState(isBookmarked);
  const [contentCategory, setContentCategory] = useState('');

  const handleSignUpClose = () => {
    setShowSignUp(false);
  };

  const handleSignUpFlow = () => {
    setShowSignUp(true);
  };

  useEffect(() => {
    const category = masterContentTypeData?.find(
      (item: any) => item?.unique_id === uniqueContentTypeId
    )?.category;

    setContentCategory(category);
  }, [masterContentTypeData]);

  const handleCardClick = () => {
    // const contentTypeId = masterContentTypeData?.find((item) => item?.label === contentType)?.value;
    const category = masterContentTypeData?.find(
      (item: any) => item?.value === contentTypeId
    )?.category;

    const page = getPageNameByCategory(category);

    if (page !== pageNames.Home && page) {
      if (id && contentTypeId) {
        const navigateDetails = {
          stateParams: {
            eventID: eventId || ''
          },
          isDynamicUrl: true,
          dynamicUrlOptions: {
            pageName: page,
            contentId: id,
            contentTypeId: contentTypeId
          }
        };
        navigatePageUtils(urls.detailPageWithModule, navigateDetails);
      }
    } else {
      if (id && contentTypeId) {
        const navigateDetails = {
          isDynamicUrl: true,
          dynamicUrlOptions: {
            contentId: id,
            contentTypeId: contentTypeId
          }
        };
        navigatePageUtils(urls.detailPage, navigateDetails);
      }
    }
  };

  const handleBannerCardBookmarkClick = async (id: number | string) => {
    if (!isLoggedIn) {
      handleSignUpFlow();
    } else if(id && title && user) {
      const userObj = userDetails || user;
      const userInfo = getAnalyticsUserDetails(userObj);
      logEvent(
        appInsights,
        'custom',
        CUSTOM_ANALYTICS_EVENT_NAMES.USER_BOOKMARK_CONTENT,
        {
          contentId: id,
          contentTitle: title,
          userEmail: userInfo?.emailId,
          userMobile: userInfo?.mobile,
          userName: userInfo?.name,
          userQualification: userInfo?.qualification,
          userInterest: userInfo?.areaOfInterest,
          userSpecialization: userInfo?.specialization,
          userCountryOfPractice: userInfo?.countryOfPractice,
          userPreferredLanguage: userInfo?.preferredLanguage,
          platform: PlatformNames.WEB
        }
      );
      setBookmarked(!bookmarked);
      const req = {
        id: Number(id)
      };
      await dispatch(updateBookmark(req));
    }
  };

  const {
    ViewsIcon,
    DurationIcon,
    TimeIcon,
    DateIcon,
    AuthorIcon,
    BookmarkIcon
  } = ImageConstant.SVG_ICONS;

  const dateHide = hideDate(contentCategory, uniqueContentTypeId);
  const timeHide = hideTime(contentCategory, uniqueContentTypeId);
  const hideViews = getHideViewsFlag(contentCategory, uniqueContentTypeId);
  const hideInterest = getHideInterestFlag(
    contentCategory,
    uniqueContentTypeId
  );
  const showDuration = getShowDurationFlag(
    contentCategory,
    uniqueContentTypeId
  );
  const hideAuthor = getHideAuthor(contentCategory, uniqueContentTypeId);

  const extraContent = getExtraCardContent(
    contentCategory,
    uniqueContentTypeId
  );

  const renderImage = hideImage(contentCategory, uniqueContentTypeId);
  return (
    <div className={styles.cardContainer}>
      {mostViewed ? (
        <span className={styles.mostViewed}>
          {t(AppConstant.COMMON.MOST_VIEWED_TEXT)}
        </span>
      ) : null}
      <div className={styles.cardWrapper}>
        <div className={styles.cardContent}>
          <div className={styles.contentType}>
            {contentType && (
              <>
                {contentIcon ? (
                  <img src={getImageSrc(contentIcon)} alt='content icon' />
                ) : null}
                <span>{contentType}</span>
              </>
            )}
          </div>
          {title && (
            <h4
              className={`${styles.cardTitle} ${
                renderImage && styles.noThumbnail
              }`}
              onClick={handleCardClick}
            >
              {title}
            </h4>
          )}
          <div className={styles.speciality}>
            {!hideInterest && speciality && (
              <Speciality speciality={speciality} showAll={false} />
            )}
          </div>
          <div className={styles.datePublisher}>
            <div className={styles.dateTime}>
              <>
                {!dateHide && date && (
                  <div className={styles.date}>
                    <DateIcon />
                    <span>{t(AppConstant.COMMON.DATE)}</span>
                    {date}
                  </div>
                )}
                {!timeHide && time && (
                  <div className={styles.time}>
                    <TimeIcon />
                    <span>{t(AppConstant.COMMON.TIME)}</span>
                    <span className={styles.utcTime}>{time}</span>
                  </div>
                )}
              </>
            </div>
            {extraContent === EXTRA_CARD_CONTENT_STRING.DURATION ? (
              <div className={styles.publishedName}>
                <DurationIcon />
                <span>{t(AppConstant.COMMON.DURATION)}</span>
                {duration}
              </div>
            ) : // : extraContent === EXTRA_CARD_CONTENT_STRING.DATE ? (
            //   <div className={styles.publishedName}>
            //     <DateIcon />
            //     <span>{t(AppConstant.COMMON.DATE)} </span>
            //     {date}
            //   </div>
            // )
            extraContent === EXTRA_CARD_CONTENT_STRING.AUTHOR ? (
              <div className={styles.publishedName}>
                {!hideAuthor && publishedName && (
                  <>
                    <AuthorIcon />
                    <span>
                      <strong>{t(AppConstant.COMMON.BY_TEXT)}</strong>
                      {publishedName || getDefaultValue()}
                    </span>
                  </>
                )}
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        {!renderImage ? (
          thumbnailImage ? (
            <div className={styles.cardImg}>
              <img
                src={getImageSrc(thumbnailImage)}
                alt='card img'
                onError={(e) =>
                  ((e.target as HTMLInputElement).src = getDefaultImage(
                    false,
                    contentCategory,
                    uniqueContentTypeId
                  ))
                }
              />
            </div>
          ) : (
            <div className={styles.cardImg}>
              <img
                src={getDefaultImage(
                  false,
                  contentCategory,
                  uniqueContentTypeId
                )}
                alt='card img'
              />
            </div>
          )
        ) : (
          ''
        )}
        <div className={styles.viewsDurationBookmark}>
          {/* {uniqueContentTypeId === CONTENT_TYPE_ID.RESOURCES ? (
            <div className={styles.publishedName}>
              {!hideAuthor && (
                <>
                  <AuthorIcon />
                  <span><strong>{t(AppConstant.COMMON.BY_TEXT)}</strong>{publishedName || NOT_AVAILABLE_STRING}</span>
                </>
              )}
            </div>
          ) : ( */}
          <>
            <div className={styles.viewsDuration}>
              {!hideViews && (
                <div className={styles.views}>
                  <ViewsIcon />
                  <span>{t(AppConstant.COMMON.VIEWS)}</span>
                  {viewsCount ? viewsCount : 0}
                </div>
              )}
              {uniqueContentTypeId === CONTENT_TYPE_ID.WEBINARS &&
                showDuration &&
                duration && (
                  <div className={styles.duration}>
                    <DurationIcon />
                    <span>{t(AppConstant.COMMON.DURATION)}</span>
                    {duration}
                  </div>
                )}
            </div>
            <div
              className={`${styles.bookmark} ${
                bookmarked && isLoggedIn && styles.activeBookmark
              }`}
              onClick={() => {
                handleBannerCardBookmarkClick(id);
              }} // handleBookmark
            >
              <BookmarkIcon />
            </div>
          </>
          {/* )} */}
        </div>
      </div>
      <SignUpPopup open={showSignUp} handleClose={handleSignUpClose} />
    </div>
  );
};
