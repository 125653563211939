import {
  AppConstant,
  CONTENT_TYPE_ID,
  getFilterCategoriesOptionsValues,
  ImageConstant,
  SLIDE_TO_SHOW_WEB,
  translate,
  useAppSelector
} from '@shalina-app/shared';

import { ExploreMoreCard, ExploreMoreSectionPropsType } from '.';
import { MEDICAL_CALCULATOR_TABS, pageNames } from '../../../../app.constants';
import { CardSlider } from '../../../../components';
import { URLConstant } from '../../../../routes';
import { useNavigatePageUtils } from '../../../../utils';
import styles from './exploreMore.module.scss';

export const ExploreMoreSection: React.FC<ExploreMoreSectionPropsType> = ({
  exploreOptions,
  handleViewAllClick,
  loading,
  selectedAreaOfInterest,
  filterOptionsInterest
}) => {
  const navigatePage = useNavigatePageUtils();
  const { urls } = URLConstant;
  const t = translate();
  const slidesToShow = SLIDE_TO_SHOW_WEB.EXPLORE_MORE;

  const masterContentTypeData = useAppSelector(
    getFilterCategoriesOptionsValues
  );

  const handleContentTypeClick = (item: any) => {
    if (item?.isDynamicUrl) {
      const contentTypeId: any = masterContentTypeData?.find(
        (obj: any) => obj?.unique_id === item?.uniqueId
      )?.value;

      if (contentTypeId && item?.pageName) {
        const navigateDetails = {
          stateParams: {
            filterRetain: true,
            userSelectedAreaOfInterest:
              filterOptionsInterest || selectedAreaOfInterest?.toString(),
            tab: item?.selectedTab
          },
          isDynamicUrl: true,
          dynamicUrlOptions: {
            contentTypeId: contentTypeId,
            pageName: item?.pageName
          }
        };
        navigatePage(item?.redirectUrl, navigateDetails);
      }
    } else {
      const navigateDetails = {
        stateParams: {
          filterRetain: true,
          userSelectedAreaOfInterest:
            filterOptionsInterest || selectedAreaOfInterest?.toString(),
          tab: item?.selectedTab
        },
        isDynamicUrl: false
      };
      navigatePage(item?.redirectUrl, navigateDetails);
    }
  };

  const EXPLORE_QUICK_MENU = [
    {
      id: 1,
      label: t(AppConstant.COMMON.CLINICAL_CRITERIA_TAB),
      icon: ImageConstant.SOURCE.ClinicalCriteriaIcon,
      redirectUrl: urls.medicalCalculator,
      pageName: '',
      isDynamicUrl: false,
      uniqueId: '',
      selectedTab: MEDICAL_CALCULATOR_TABS.CLINICAL_CRITERIA
    },
    {
      id: 2,
      label: t(AppConstant.COMMON.PRACTICE_ESSENTIALS_MENU),
      icon: ImageConstant.SOURCE.PracticeEssentialsIcon,
      redirectUrl: urls.listingPageWithModule,
      pageName: pageNames.ShalinaTv,
      isDynamicUrl: true,
      uniqueId: CONTENT_TYPE_ID.PRACTICE_ESSENTIALS,
      selectedTab: ''
    },
    {
      id: 3,
      label: t(AppConstant.COMMON.DECISION_TREES_TAB),
      icon: ImageConstant.SOURCE.DecisiontreesIcon,
      redirectUrl: urls.medicalCalculator,
      pageName: '',
      isDynamicUrl: false,
      uniqueId: '',
      selectedTab: MEDICAL_CALCULATOR_TABS.DECISION_TREES
    },
    {
      id: 4,
      label: t(AppConstant.COMMON.SURGERIES_AND_PROCEDURES_MENU),
      icon: ImageConstant.SOURCE.SurgeriesProceduresIcon,
      redirectUrl: urls.listingPageWithModule,
      pageName: pageNames.ShalinaTv,
      isDynamicUrl: true,
      uniqueId: CONTENT_TYPE_ID.SURGURIES_AND_PROCEDURES,
      selectedTab: ''
    }
    // {
    //   id: 5,
    //   label: "Surveys",
    //   icon: ImageConstant.SOURCE.SurveysIcon,
    //   redirectUrl: urls.surveyPage,
    //   pageName: '',
    //   isDynamicUrl: false,
    //   uniqueId: ''
    // selectedTab: ''
    // },
    // {
    //   id: 6,
    //   label: t(AppConstant.HEADER.RESOURCES_MENU),
    //   icon: ImageConstant.SOURCE.ResourcesIcon,
    //   redirectUrl: urls.listingPageWithModule,
    //   pageName: pageNames.KnowledgeHub,
    //   isDynamicUrl: true,
    //   uniqueId: CONTENT_TYPE_ID.RESOURCES,
    //   selectedTab: "",
    // },
  ];

  const showControl =
    EXPLORE_QUICK_MENU && EXPLORE_QUICK_MENU.length > slidesToShow
      ? true
      : false;
  const length: number = EXPLORE_QUICK_MENU?.length;

  return (
    <div className={styles.exploreMoreSec}>
      <CardSlider
        slidesToShow={slidesToShow}
        sliderHeading={AppConstant.HOME_PAGE.EXPLORE_MORE}
        isViewAllExist={false}
        showControl={showControl}
        isDataExist={
          EXPLORE_QUICK_MENU && EXPLORE_QUICK_MENU.length > 0 ? true : false
        }
        handleViewAllButtonClick={() =>
          handleViewAllClick(t(AppConstant.HOME_PAGE.EXPLORE_MORE))
        }
        dataLength={length}
        loading={loading}
      >
        {EXPLORE_QUICK_MENU &&
          EXPLORE_QUICK_MENU?.map((item: any, index: number) => (
            <ExploreMoreCard
              name={item?.label}
              id={item?.id}
              key={item?.id}
              handleContentTypeClick={() => handleContentTypeClick(item)}
              unique_id={item?.uniqueId}
              icon={item?.icon}
            />
          ))}
      </CardSlider>
    </div>
  );
};
