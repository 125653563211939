import { useEffect, useState } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {
  changeLanguage,
  getCurrentLanguageForWeb,
  ImageConstant,
  updateLanguageForWeb,
  useAppDispatch,
  useAppSelector,
  LANGUAGE_UNIQUE_ID,
  isTranslationSupported
} from '@shalina-app/shared';

import styles from './langSelector.module.scss';
import { LangSelectorPropsType, languageProps } from '.';
import { HeaderConstants } from '../..';
import {
  getImageSrc,
  retrieveData,
  STORAGE_CONSTANTS
} from '../../../../utils';

export const LangSelector: React.FC<LangSelectorPropsType> = ({
  preferredLanguages,
  isFooter
}) => {
  const dispatch = useAppDispatch();
  const { DropDownArrowIcon } = ImageConstant.SVG_ICONS;

  const [langCode, setLangCode] = useState('');
  const [flag, setFlag] = useState('');
  const [open, setOpen] = useState(false);

  const localStorageLang = retrieveData(STORAGE_CONSTANTS.SELECTED_LANGUAGE);
  const preferredLanguage = useAppSelector(getCurrentLanguageForWeb);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (preferredLanguage) {
      const selectedLang: any = preferredLanguages?.find(
        (item: any) =>
          item?.field_unique_code?.toLowerCase() ===
          preferredLanguage?.toLowerCase()
      );
      setLangCode(preferredLanguage?.toLowerCase());
      changeLanguage(preferredLanguage);
      storeLanguage(preferredLanguage);
      setFlag(selectedLang?.field_language_image);
      // storeData(STORAGE_CONSTANTS.SELECTED_LANGUAGE, selectedLang);
    } else {
      const selectedLang: any = preferredLanguages?.find(
        (item: any) =>
          item?.field_unique_code?.toLowerCase() ===
          LANGUAGE_UNIQUE_ID?.ENGLISH?.toLowerCase()
      );
      setLangCode(selectedLang?.field_unique_code?.toLowerCase());
      changeLanguage(selectedLang?.field_unique_code);
      storeLanguage(selectedLang?.field_unique_code);
      setFlag(selectedLang?.field_language_image);
    }
    // }
  }, [preferredLanguages, preferredLanguage]);

  useEffect(() => {
    // if (preferredLanguages && preferredLanguages?.length > 0) {
    if (localStorageLang && localStorageLang?.field_unique_code) {
      setLangCode(localStorageLang?.field_unique_code?.toLowerCase());
      changeLanguage(localStorageLang?.field_unique_code);
      storeLanguage(localStorageLang?.field_unique_code);
      setFlag(localStorageLang?.field_language_image);
      // storeData(STORAGE_CONSTANTS.SELECTED_LANGUAGE, selectedLang);
    }
  }, [localStorageLang]);

  const handleChange = (event: any) => {
    const code = event?.target?.value;
    const selectedLang: any = preferredLanguages?.find(
      (item: any) =>
        item?.field_unique_code?.toLowerCase() === code?.toLowerCase()
    );

    setLangCode(code?.toLowerCase());
    changeLanguage(code);
    storeLanguage(code);
    setFlag(selectedLang?.field_language_image);
    // storeData(STORAGE_CONSTANTS.SELECTED_LANGUAGE, selectedLang);
  };

  const storeLanguage = async (lang: any) => {
    await dispatch(updateLanguageForWeb(lang));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div className={`${styles.country} ${isFooter && styles.footerLang}`}>
      <Select
        open={open}
        IconComponent={() => <DropDownArrowIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        value={langCode}
        name={HeaderConstants.LANGUAGE_SELECTOR}
        onChange={handleChange}
        renderValue={(value) => (
          <>
            {flag ? (
              <img src={flag ? getImageSrc(flag) : ''} alt={''} />
            ) : (
              <span className={styles.noFlag}></span>
            )}
            {langCode}
          </>
        )}
      >
        {preferredLanguages?.map((option: languageProps, key: any) => (
          <MenuItem
            value={option?.field_unique_code?.toLowerCase()}
            key={key}
            className={`${styles.countrySelectOption}`}
            disabled={!isTranslationSupported(option?.field_unique_code)}
          >
            {option?.field_language_image ? (
              <img src={getImageSrc(option?.field_language_image)} alt={''} />
            ) : (
              <span className={styles.noFlag}></span>
            )}
            <span>{option?.name}</span>
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
