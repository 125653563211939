import { useState } from 'react';
import {
  AppConstant,
  ImageConstant,
  REQUEST_STATUS,
  getOnlyDate,
  getOnlyTime,
  translate,
} from '@shalina-app/shared';

import { RequestCardsItemProps } from './requestCards.interface';
import styles from './requestCards.module.scss';
import { RequestFormComponent } from '../../../Header';
import { RequestRevokeDialog } from '../requestRevoke';

export const RequestCardsComponent: React.FC<RequestCardsItemProps> = ({
  requestStatusUniqueId,
  dataItem,
  handleCloseRevoke,
  handleModify,
  handleSubmit,
  hadleSuccessClose,
  handleToast,
  handleTitleClick,
}) => {
  const { TimeIcon, DateIcon, DropDownArrowIcon, UploadIcon } =
    ImageConstant.SVG_ICONS;
  const t = translate();

  // states
  const [modifiedDialog, setModifiedDialog] = useState(false);
  const [revokePopup, setRevokePopup] = useState(false);

  // functions

  const handleModifiedDialogOpen = () => {
    setModifiedDialog(true);
  };

  const handleModifiedDialogClose = () => {
    setModifiedDialog(false);
    handleModify();
  };

  const handleRevokeDialog = () => {
    setRevokePopup(true);
  };

  const handleRevokeClosePopup = () => {
    setRevokePopup((prev) => !prev);
    handleCloseRevoke();
  };

  return (
    <>
      <div className={styles.requestCol}>
        <div className={styles.requestCard}>
          <div className={styles.reqStatus}>
            <span
              className={
                requestStatusUniqueId === REQUEST_STATUS.OPEN
                  ? styles.open
                  : requestStatusUniqueId === REQUEST_STATUS.INPROGRESS
                  ? styles.inprogress
                  : requestStatusUniqueId === REQUEST_STATUS.CLOSE
                  ? 'green'
                  : styles.revoke
              }
            ></span>
            {dataItem?.field_update_request_status}
          </div>
          <h4
            className={styles.cardTitle}
            onClick={() => handleTitleClick(dataItem?.nid)}
          >
            {dataItem.field_request_type}
          </h4>
          <div className={styles.shotText}>{dataItem?.field_description}</div>
          <div className={styles.dateTime}>
            <div className={styles.date}>
              <DateIcon />
              <span>{t(AppConstant.COMMON.DATE)}</span>
              {getOnlyDate(dataItem?.field_request_raised)}
            </div>
            <div className={styles.time}>
              <TimeIcon />
              <span>{t(AppConstant.COMMON.TIME)}</span>
              {getOnlyTime(dataItem?.field_request_raised)}
            </div>
          </div>
          {requestStatusUniqueId === REQUEST_STATUS.OPEN && (
            <div className={styles.actionCtas}>
              <button onClick={handleRevokeDialog}>
                {t(AppConstant.HOME_PAGE.REVOKE_CTA)}
              </button>
              <button
                className={styles.modify}
                onClick={handleModifiedDialogOpen}
              >
                {t(AppConstant.HOME_PAGE.MODIFY_CTA)}
              </button>
            </div>
          )}
        </div>
      </div>

      {modifiedDialog && (
        <RequestFormComponent
          modifiedDialogClose={handleModifiedDialogClose}
          handleSubmit={handleSubmit}
          showForm={false}
          isModifyRequest={modifiedDialog}
          data={dataItem}
          hadleSuccessClose={hadleSuccessClose}
          handleToast={handleToast}
        />
      )}

      {revokePopup && (
        <RequestRevokeDialog
          closeRevokeDialog={handleRevokeClosePopup}
          handleRevoke={handleToast}
          openRevokeDialog={revokePopup}
          nid={dataItem.nid}
        />
      )}
    </>
  );
};
