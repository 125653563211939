"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TVIcon = void 0;
const React = __importStar(require("react"));
const TVIcon = (props) => (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: 18, height: 15, fill: "none" }, props),
    React.createElement("path", { fill: "#E84E1B", stroke: "#E84E1B", strokeWidth: 0.4, d: "M15.708 1H2.302C1.579 1 1 1.579 1 2.303v9.012c0 .724.588 1.313 1.312 1.313h3.906v.83c0 .366.299.665.665.665h4.244c.366 0 .665-.299.665-.665v-.83h3.896c.724 0 1.312-.589 1.312-1.313V2.303C17 1.589 16.421 1 15.708 1Zm.192 10.508H2.11V2.12H15.9v9.39Z" }),
    React.createElement("path", { fill: "#E84E1B", stroke: "#E84E1B", d: "M8.07 8.743v-3.84l3.103 1.92-3.103 1.92Z" })));
exports.TVIcon = TVIcon;
