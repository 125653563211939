import { useEffect, useState } from 'react';
import {
  AppConstant,
  fetchUserGamificationPointHistory,
  getOnlyDate,
  getOnlyTime,
  ImageConstant,
  listGamificationPointDistribution,
  listUserGamificationPointData,
  PointDistributionItem,
  PointHistoryItem,
  translate,
  useAppDispatch,
  useAppSelector,
  USER_GAMIFICATION_FILTER_RANGE_IN_DAYS
} from '@shalina-app/shared';
import moment from 'moment';

import { DEFAULT_SKIP, DEFAULT_TAKE } from '../../../../app.constants';
import { InfiniteScrollComponent } from '../../../../components';
import { PointsPathComponentPropsType } from './achievements.interface';
import styles from './achievements.module.scss';

export const PointsPath: React.FC<PointsPathComponentPropsType> = (props) => {
  const { apiCall, setApiCall } = props;
  const { DateIcon } = ImageConstant.SVG_ICONS;
  const dispatch = useAppDispatch();
  const t = translate();

  const GAMIFICATION_POINTS_TAB_ENUM = [
    {
      label: t(AppConstant.COMMON.HISTORY),
      value: 'History',
    },
    {
      label: t(AppConstant.COMMON.HOW_TO_EARN),
      value: 'How to earn',
    },
  ];
  
  const GAMIFICATION_POINTS_TAB_LABEL = {
    HISTORY: 'History',
    HOW_TO_EARN: 'How to earn',
  };

  const [activeFilter, setActiveFilter] = useState(
    GAMIFICATION_POINTS_TAB_ENUM[0].value
  );
  const [filterOptions, setFilterOptions] = useState({
    skip: 0,
    take: DEFAULT_TAKE,
    startDate: '',
    endDate: ''
  });
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [hasMore, setHasMore] = useState(false);
  const [pointsList, setPointsList] = useState<any>([]);
  const [totalPointCount, setTotalPointCount] = useState(0);
  const [skip, setSkip] = useState(0);
  const [filterCall, setFilterCall] = useState(false);
  const [maxEndDate, setMaxEndDate] = useState<any>('');
  const [minEndDate, setMinEndDate] = useState<any>('');
  const [dataFromStore, setDataFromStore] = useState(true);

  const userGamificationData = useAppSelector(listUserGamificationPointData);
  const gamificationPointsData = useAppSelector(
    listGamificationPointDistribution
  );

  useEffect(() => {
    setCurrentDate();
    setApiCall(true);
  }, [activeFilter]);

  const setCurrentDate = () => {
    const startDate = moment().subtract(
      USER_GAMIFICATION_FILTER_RANGE_IN_DAYS,
      'days'
    );
    const endDate = moment(startDate).add(
      USER_GAMIFICATION_FILTER_RANGE_IN_DAYS,
      'days'
    );
    const startDateFormat = moment(startDate).format('YYYY-MM-DD');
    const endDateFormat = moment(endDate).format('YYYY-MM-DD');
    setStartDate('');
    setEndDate('');
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      startDate: '',
      endDate: ''
    }));
  };

  useEffect(() => {
    if (apiCall) {
      if (activeFilter === GAMIFICATION_POINTS_TAB_LABEL.HISTORY) {
        getUserGamificationPointHistory();
      } else {
        setApiCall(false);
      }
    }
  }, [apiCall]);

  const getUserGamificationPointHistory = async () => {
    try {
      setApiCall(true);
      const response = await dispatch(
        fetchUserGamificationPointHistory(filterOptions)
      ).unwrap();

      if (
        response &&
        response?.status === 'SUCCESS' &&
        response?.statusCode === 200 &&
        response?.data
      ) {
        setPagination(response);
        setApiCall(false);
      } else {
        setApiCall(false);
        setDataFromStore(false);
      }
    } catch (error) {
      setApiCall(false);
      setDataFromStore(false);
    }
  };

  const handleFilterClick = (e: any) => {
    setApiCall(true);
    setActiveFilter(e.value);
    setSkip(0);
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      skip: 0
    }));
    setPointsList([]);
  };
  const handleSubmit = (e: any) => {
    setApiCall(true);
    const startDateFormat = moment(startDate).format('YYYY-MM-DD');
    const endDateFormat = moment(endDate).format('YYYY-MM-DD');
    setSkip(0);
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      skip: 0,
      startDate: startDateFormat,
      endDate: endDateFormat
    }));
    // setPointsList([]);
    setFilterCall(true);
  };

  const setPagination = (response: any) => {
    setDataFromStore(false);
    const data = response?.data?.accumulated;
    const totalCount = parseInt(response?.data?.count);
    setTotalPointCount(totalCount);

    if (apiCall) {
      if (pointsList?.length >= parseInt(response?.data?.count)) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      setApiCall(false);
    }
    if (filterCall) {
      setFilterCall(false);
      setPointsList(data);
    } else {
      setPointsList((prevOptions: any) => [...prevOptions, ...data]);
    }
  };

  const fetchMoreData = () => {
    if (pointsList?.length >= totalPointCount) {
      setHasMore(false);
      return;
    }
    setSkip(skip + DEFAULT_SKIP);
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      skip: skip + DEFAULT_SKIP,
      take: DEFAULT_TAKE
    }));
    setApiCall(true);
  };

  useEffect(() => {
    getMinimumStartDate();
  }, [startDate]);

  const getMinimumStartDate = async () => {
    const date: any = startDate ? moment(startDate) : moment();
    const endDate: any = moment(date).add(
      USER_GAMIFICATION_FILTER_RANGE_IN_DAYS,
      'days'
    );
    const dateForStart = new Date(endDate);
    const dateForEnd = new Date(date);
    const maxEndDateFormat = moment(dateForStart).format('YYYY-MM-DD');
    const minEndDateFormat = moment(dateForEnd).format('YYYY-MM-DD');
    setMaxEndDate(maxEndDateFormat);
    setMinEndDate(minEndDateFormat);
  };

  const isStartDateValid: boolean = startDate && endDate ? false : true;
  const content = dataFromStore
    ? userGamificationData?.accumulated
    : pointsList;

  return (
    <>
      {/* <div className={styles.pointCta}>
        <button className={styles.active}>History</button>
        <button>How to earn</button>
      </div> */}
      <div className={styles.pointCta}>
        {GAMIFICATION_POINTS_TAB_ENUM?.length > 0 && (
          <div className={styles.statusCtas}>
            {GAMIFICATION_POINTS_TAB_ENUM?.length &&
              GAMIFICATION_POINTS_TAB_ENUM.map((elements) => (
                <button
                  key={elements.value}
                  className={
                    elements.value === activeFilter ? styles.active : ''
                  }
                  onClick={() => handleFilterClick(elements)}
                >
                  {`${elements.label}`}
                </button>
              ))}
          </div>
        )}
      </div>
      {activeFilter === GAMIFICATION_POINTS_TAB_LABEL.HISTORY ? (
        <>
          <div className={styles.rssFilterWrapper}>
            {/* Start Date */}
            <div className={styles.startEndDate}>
              <label htmlFor='startDate'>
                {t(AppConstant.HOME_PAGE.START_DATE)}
              </label>
              <input
                type='date'
                id='startDate'
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                max={endDate}
              />
            </div>

            {/*  End Date */}
            <div className={styles.startEndDate}>
              <label htmlFor='endDate'>
                {t(AppConstant.HOME_PAGE.END_DATE)}
              </label>
              <input
                type='date'
                id='endDate'
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                min={startDate}
              />
            </div>
            <button
              onClick={handleSubmit}
              disabled={isStartDateValid}
              className={styles.submit}
            >
              {t(AppConstant.AUTHENTICATION_PAGE.SEARCH_TEXT)}
            </button>
          </div>
          <div className={styles.pointTable}>
            <div className={styles.pointTableHead}>
              <div className={styles.accumulated}>
                {t(AppConstant.COMMON.POINTS_ACCUMULATED)}
              </div>
              <div className={styles.Totalpoint}>
                {t(AppConstant.PROFILE.TOTAL_TEXT)}{' '}
                <span>
                  {userGamificationData?.points || 0}{' '}
                  {t(AppConstant.COMMON.POINTS)}
                </span>
              </div>
            </div>
          </div>
          {content && content?.length > 0 ? (
            <div className={styles.listingWrapper}>
              <InfiniteScrollComponent
                dataLength={content.length}
                fetchMoreData={fetchMoreData}
                hasMore={hasMore}
                loader={apiCall || false}
                scrollThreshold={'1000px'}
              >
                <div className={styles.pointList}>
                  {content?.map((gamification: PointHistoryItem) => {
                     const date = gamification?.expiredAt;
                     const expired = new Date().valueOf() > new Date(date ? date : '').valueOf();
                    return (
                      <div className={`${styles.pointItem} ${expired && styles.disabled}`}>
                        <div className={styles.pointText}>
                          <span>
                            {gamification.earned_points ?? 0}{' '}
                            {t(AppConstant.COMMON.POINTS)}
                          </span>{' '}
                          {gamification.name}
                        </div>
                        {gamification.created_at && (
                          <div className={styles.pointDate}>
                            <DateIcon />
                            <span>{t(AppConstant.COMMON.DATE)}</span>
                            {getOnlyDate(gamification.created_at)}{' '}
                            {getOnlyTime(gamification.created_at)}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </InfiniteScrollComponent>
            </div>
          ) : apiCall ? null : (
            <div>{t(AppConstant.COMMUNITY.NO_POINTS_EARNED_MESSAGE)}</div>
          )}
        </>
      ) : (
        <>
          <div className={styles.pointTable}>
            <div className={styles.pointTableHead}>
              <div className={styles.accumulated}>
                {t(AppConstant.COMMON.HOW_TO_EARN)}
              </div>
            </div>
          </div>
          <div className={styles.pointList}>
            {gamificationPointsData && gamificationPointsData?.length > 0
              ? gamificationPointsData?.map(
                  (gamification: PointDistributionItem) => {
                    return (
                      <div className={styles.pointItem}>
                        <div className={styles.pointText}>
                          {gamification.name}
                        </div>
                        {gamification.field_points && (
                          <div className={`${styles.pointText} ${styles.pointText2}`}>
                            <span>
                              +{gamification.field_points}{' '}
                              {t(AppConstant.COMMON.POINTS)}
                            </span>
                            {/* <strong>
                              {gamification?.field_limit && (
                                <>
                                  {`(${gamification.field_limit}${
                                    gamification?.field_limit_type
                                      ? ` times/${gamification.field_limit_type}`
                                      : ''
                                  })`}
                                </>
                              )}
                            </strong> */}
                          </div>
                        )}
                      </div>
                    );
                  }
                )
              : null}
          </div>
        </>
      )}
    </>
  );
};
