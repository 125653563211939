import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import {
  addOrUpdateUserGamificationPoints,
  AppConstant,
  azureFile,
  contentDetails,
  CONTENT_TYPE_ID,
  DetailTabEnumType,
  DETAIL_TABS_ENUM,
  fetchArticleDocumentLink,
  GAMIFICATION_ACTION_ID,
  GAMIFICATION_ENABLED,
  GAMIFICATION_TYPE_UNIQUE_CODE,
  getBlobFilePath,
  getDefaultImage,
  getDefaultValue,
  getHideAuthor,
  getIconForContentType,
  getNotificationActionList,
  getTabsEnum,
  handleRecognitionPopup,
  hideImage,
  ImageConstant,
  isJournalDetail,
  listGamificationPointDistribution,
  RECOGNITION_MESSAGE_TEXT,
  sendNotification,
  translate,
  updateUserRecognition,
  useAppDispatch,
  useAppSelector,
} from '@shalina-app/shared';
import parse from 'html-react-parser';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useParams } from 'react-router-dom';

import {
  ArticleDetailsProps,
  ButtonComponent,
  CircularLoader,
  ShalinaPDFViewer,
  Speciality,
} from '../../../..';
import {
  convertToSpecialties,
  getImageSrc,
  retrieveData,
  STORAGE_CONSTANTS,
} from '../../../../../utils';
import { ShareSection } from '../../components/shareSection';
import styles from './articleDetails.module.scss';
import {
  JournalAdditionalInfo,
  JournalArticles,
  JournalDescription,
} from './components';
import { SignUpPopup } from '../../../../../features';

export const ArticleDetails: React.FC<ArticleDetailsProps> = ({
  ctaAction,
  contentData,
  handleNextStep,
  startReadingLoader,
  category,
}) => {
  const params = useParams();
  const contentId: any = params?.contentId;
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const content: any = contentData ? contentData : null;

  const t = translate();
  const dispatch = useAppDispatch();

  const [accessUrlLoader, setAccessUrlLoader] = useState(false);
  const [accessUrl, setAccessUrl] = useState('');
  const [isStartReadingDisabled, setIsStartReadingDisabled] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [showSignUp, setShowSignUp] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updatePoints, setUpdatePoints] = useState(false);
  const [recentArticle, setRecentArticle] = useState({
    articleTitle: '',
    an: '',
    dbId: '',
  });
  const [pdfReaderInfo, setPdfReaderInfo] = useState({
    fileUrl: '',
    pageNumber: 0,
    articleTitle: '',
    dbId: '',
    an: '',
  });

  useEffect(() => {
    setAccessUrlLoader(true);
    setUpdatePoints(true);
  }, []);

  useEffect(() => {
    if (updatePoints) {
      if (
        content?.field_unique_code === CONTENT_TYPE_ID.JOURNALS_ARTICLES ||
        content?.field_unique_code === CONTENT_TYPE_ID.MAGZINES
      ) {
        updateGamificationPoints();
        setUpdatePoints(false);
      }
    }
  }, [content, updatePoints]);

  useEffect(() => {
    if (content?.article) {
      const obj = {
        articleTitle: content?.article?.article_title,
        an: content?.article?.an,
        dbId: content?.article?.dbid,
      };
      setRecentArticle(obj);
    }
  }, [content]);

  const gamificationPointDistributionData = useAppSelector(
    listGamificationPointDistribution
  );
  const notificationList = useAppSelector(getNotificationActionList);


  const sendNotificationForMilestoneUpdate = async () => {
    const action = notificationList?.find(
      (item: any) =>
        item?.field_unique_code === GAMIFICATION_ACTION_ID.UPDATE_MILESTONE
    )?.title;

    const req = {
      action: action,
      device: 'web',
    };
    // Hide Continue where you left feature
    await dispatch(sendNotification(req));
  };

  const updateGamificationPoints = async () => {
    if (isLoggedIn && GAMIFICATION_ENABLED) {
      checkRecognition();
      const gamificationDataItem = gamificationPointDistributionData?.find(
        (item: any) =>
          item.field_unique_code === GAMIFICATION_TYPE_UNIQUE_CODE.READ_CONTENT
      );
      if (gamificationDataItem && gamificationDataItem?.field_unique_code) {
        const payload = {
          field_unique_code: gamificationDataItem?.field_unique_code,
          entityTypeId: contentId,
        };
        const response: any = await dispatch(addOrUpdateUserGamificationPoints(payload)).unwrap();
        if(response && response?.info) {
          sendNotificationForMilestoneUpdate()
        }
      }
    }
  };

  const checkRecognition = async () => {
    if (contentId) {
      const response = await dispatch(
        updateUserRecognition({
          view: 1,
          contentId: contentId,
        })
      ).unwrap();
      if (
        response &&
        response?.status === 'SUCCESS' &&
        response?.statusCode === 200 &&
        response?.message === RECOGNITION_MESSAGE_TEXT.AVID_READER &&
        response?.data
      ) {
        const obj = {
          showRecognitionPopup: true,
          recognitionData: response?.data,
        };
        dispatch(handleRecognitionPopup(obj));
      } else {
        const obj = {
          showRecognitionPopup: false,
          recognitionData: {},
        };
        dispatch(handleRecognitionPopup(obj));
      }
    }
  };

  const showArticle = isJournalDetail(content?.field_unique_code);

  const loadAccessUrl = async () => {
    if (content && content?.field_supporting_docs && !content?.field_is_ebsco) {
      const response = await dispatch(
        azureFile({ fileUrl: content?.field_supporting_docs }) as any
      ).unwrap();
      if (
        response &&
        response?.statusCode === 200 &&
        response?.status === 'SUCCESS' &&
        response?.data
      ) {
        setAccessUrl(response?.data);
      } else {
        setAccessUrl('');
      }
      setAccessUrlLoader(false);
    }
  };

  const { ViewsIcon, AuthorIcon } = ImageConstant.SVG_ICONS;
  const isBookmarked: boolean = content?.is_bookmarked || false;
  const isLiked: boolean = content?.is_liked || false;

  const contentIcon: any = useAppSelector(
    getIconForContentType(content?.field_unique_code)
  );
  const isLoggedIn: boolean = retrieveData(STORAGE_CONSTANTS.IS_LOGGED_IN);
  const shortDesc = content?.field_description?.substring(0, 330);
  const guestUserDescription = shortDesc ? parse(shortDesc) : '';
  const description = content?.field_description
    ? parse(content?.field_description)
    : '';

  useEffect(() => {
    if (content?.field_is_ebsco && content?.field_upload_link) {
      setIsStartReadingDisabled(false);
    } else if (content?.field_supporting_docs) {
      setIsStartReadingDisabled(false);
    } else {
      setIsStartReadingDisabled(true);
    }
  }, [content]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  const handleModalClose = () => setModalOpen(false);

  const handleRecentArticleClick = async () => {
    if (isLoggedIn) {
      setLoading(true);
      const lastReadAt = content?.last_read_at
        ? parseInt(content?.last_read_at)
        : 0;
      const parameter = {
        an: content?.article?.an,
        dbId: content?.article?.dbid,
      };
      const response = await dispatch(
        fetchArticleDocumentLink(parameter as any)
      ).unwrap();

      if (
        response &&
        response?.status === 'SUCCESS' &&
        response?.statusCode === 200
      ) {
        setLoading(false);
        if (response?.data?.isPdfLink) {
          const pdfReaderInfo = {
            fileUrl: response?.data?.url,
            pageNumber: lastReadAt,
            articleTitle: content?.article?.article_title,
            dbId: content?.article?.dbid,
            an: content?.article?.an,
          };
          setPdfReaderInfo(pdfReaderInfo);
          setModalOpen(true);
        } else if (response?.data?.isSAML) {
          window.open(response?.data?.url, '_self');
        }
      }
    } else {
      setShowSignUp(true);
      setLoading(false);
    }
  };

  const handleSignUpClose = () => {
    setShowSignUp(false);
  };

  const handleReloadPage = (title: string, an: string, dbId: string) => {
    const obj = {
      articleTitle: title,
      an: an,
      dbId: dbId,
    };
    setRecentArticle(obj);
    // const payload = {
    //   id: contentId,
    //   obj: {
    //     category: category,
    //   },
    // };
    // dispatch(contentDetails(payload) as any);
  };

  const hideImageContent = hideImage('', content?.field_unique_code);

  const defaultTabs = [
    {
      id: 0,
      enumId: DETAIL_TABS_ENUM.ARTICLES,
      label: t(AppConstant.KNOWLEDGE_HUB_PAGE.ARTICLES),
      component: (
        <JournalArticles
          contentDetails={content}
          handleReload={handleReloadPage}
        />
      ),
    },
    {
      id: 1,
      enumId: DETAIL_TABS_ENUM.DESCRIPTION,
      label: t(AppConstant.KNOWLEDGE_HUB_PAGE.BOOK_DESCRIPTION_TAB),
      component: (
        <JournalDescription
          isLoggedIn={isLoggedIn}
          guestUserDescription={guestUserDescription}
          description={description}
        />
      ),
    },
    {
      id: 2,
      enumId: DETAIL_TABS_ENUM.ADDITIONAL_INFO,
      label: t(AppConstant.COMMON.ADDITIONAL_INFO),
      component: (
        <JournalAdditionalInfo
          isLoggedIn={isLoggedIn}
          contentDetails={content}
        />
      ),
    },
  ];

  const tabsEnum: any = getTabsEnum(
    content?.field_unique_code,
    content?.field_module_type
  );

  const tabs = tabsEnum?.length
    ? defaultTabs?.filter((item) =>
        tabsEnum?.includes(item.enumId as DetailTabEnumType)
      )
    : defaultTabs;

  // const tabs = showArticle ? tabsWithArticle : defaultTabs;
  const hideAuthor = getHideAuthor(
    content?.field_module_type,
    content?.field_unique_code
  );

  const { DropDownArrowIcon } = ImageConstant.SVG_ICONS;

  return (
    <>
      <div className={styles.detailTop}>
        {!hideImageContent &&
          (content?.field_is_ebsco ? (
            <div className={styles.detailImg}>
              <img
                src={
                  content?.field_thumbnail_image
                    ? getImageSrc(content?.field_thumbnail_image)
                    : getDefaultImage(
                        true,
                        content?.field_module_type,
                        content?.field_unique_code
                      )
                }
                alt='card img'
                onError={(e) =>
                  ((e.target as HTMLInputElement).src =
                    ImageConstant.SOURCE.pdfDetailThumbnail)
                }
              />
              {/* <div className={styles.dateViews}>
            <span>
              <DateIcon />
              <strong>{t(AppConstant.COMMON.DATE)}</strong>
              {getOnlyDate(contentDetails?.field_authored_on)}
            </span>
            <span>
              <ViewsIcon />
              <strong>{t(AppConstant.COMMON.VIEWS)}</strong>
              {contentDetails?.view_count ? contentDetails?.view_count : 0}
            </span>
          </div> */}
            </div>
          ) : (
            <div className={styles.detailImg}>
              <img
                src={
                  content?.field_detail_image
                    ? getImageSrc(content?.field_detail_image)
                    : getDefaultImage(
                        true,
                        content?.field_module_type,
                        content?.field_unique_code
                      )
                }
                alt='card img'
                onError={(e) =>
                  ((e.target as HTMLInputElement).src =
                    ImageConstant.SOURCE.pdfDetailThumbnail)
                }
              />
            </div>
          ))}
        <div
          className={`${styles.detailInfo} ${
            hideImageContent ? styles.noImg : ''
          }`}
        >
          <div className={styles.tag}>
            {contentIcon ? (
              <img src={getImageSrc(contentIcon)} alt='content icon' />
            ) : null}
            <span>{content?.field_select_article_type}</span>
          </div>
          <h1 className={styles.detailTitle}>{content?.title}</h1>
          {content?.field_area_of_interest && (
            <div className={styles.specialityWrap}>
              <Speciality
                speciality={convertToSpecialties(
                  content?.field_area_of_interest || ''
                )}
                showAll={false}
              />
            </div>
          )}
          {!hideAuthor && content?.field_authored_by && (
            <div className={styles.publisher}>
              <AuthorIcon />
              <span className={styles.authorLabel}>
                {t(AppConstant.COMMON.PUBLISHED_BY)}
              </span>
              <span
                className={styles.authorText}
                title={content?.field_authored_by}
              >
                {content?.field_authored_by || getDefaultValue()}
              </span>
            </div>
          )}
          <div className={styles.shareAndCta}>
            <div className={styles.dateCtaWrapper}>
              <div className={styles.dateViews}>
                {/* {content?.field_authored_on && (
              <span>
                <DateIcon />
                <strong>{t(AppConstant.COMMON.DATE)}</strong>
                {getOnlyDate(contentDetails?.field_authored_on)}
              </span>
            )} */}
                {/* {showViews && ( */}
                <span>
                  <ViewsIcon />
                  <strong>{t(AppConstant.COMMON.VIEWS)}</strong>
                  {content?.view_count ? content?.view_count : 0}
                </span>
                {/* )} */}
              </div>
              {ctaAction && (
                <div className={styles.detailCta}>
                  <span className={styles.disableSpan}></span>
                  <ButtonComponent
                    variant='outlined'
                    buttonText={t(AppConstant.KNOWLEDGE_HUB_PAGE.START_READING)}
                    onPress={handleNextStep}
                    disabled={isStartReadingDisabled}
                    loader={startReadingLoader}
                  />
                </div>
              )}
            </div>
            <div className={styles.shareAndViews}>
              <ShareSection
                isBookmarked={isBookmarked}
                isLiked={isLiked}
                fieldActions={content?.field_actions}
                contentId={content?.nid}
                title={content?.title}
                fileUrl={content?.field_supporting_docs}
                fileDownloadUrl={getBlobFilePath(
                  content?.field_supporting_docs
                )}
                content={content}
              />
            </div>
          </div>
          {recentArticle &&
            recentArticle?.an &&
            recentArticle?.articleTitle && (
              <div className={styles.recentArticle}>
                <h4>{t(AppConstant.COMMON.RECENT_ARTICLE_TEXT)}</h4>
                <span onClick={handleRecentArticleClick}>
                  {recentArticle?.articleTitle}
                </span>
              </div>
            )}
        </div>
      </div>

      <div className={styles.shalinaTabs}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label='Detail Tabs'
          className={styles.custTabs}
        >
          {tabs?.map((tab, index) => (
            <Tab label={tab?.label} {...a11yProps(index)} />
          ))}
        </Tabs>
        <div className={styles.custTabContent}>
          {tabs?.map((tab: any, index: number) => (
            <CustomTabPanel value={tabValue} index={index}>
              {tab?.component}
            </CustomTabPanel>
          ))}
        </div>
      </div>
      {loading && <CircularLoader size={50} thickness={4} />}
      <Dialog
        fullScreen
        open={modalOpen}
        onClose={handleModalClose}
        className={styles.journalModal}
      >
        <DialogContent>
          <div>
            <div className={styles.closeDiv}>
              <button className={styles.backBtn} onClick={handleModalClose}>
                <DropDownArrowIcon />
                {t(AppConstant.COMMON.BACK_CTA)}
              </button>
              {/* <span onClick={handleModalClose}>&times;</span> */}
            </div>
            <ShalinaPDFViewer
              fileUrl={pdfReaderInfo?.fileUrl}
              pageNumber={pdfReaderInfo?.pageNumber}
              id={content?.id}
              articleTitle={pdfReaderInfo?.articleTitle}
              dbId={pdfReaderInfo?.dbId}
              an={pdfReaderInfo?.an}
            />
          </div>
        </DialogContent>
      </Dialog>
      <SignUpPopup open={showSignUp} handleClose={handleSignUpClose} />
    </>
  );
};

export default ArticleDetails;
