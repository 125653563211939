import { AppConstant, translate } from '@shalina-app/shared';
import { Link, useLocation } from 'react-router-dom';

import { URLConstant } from '../../../../../../routes';
import styles from '../articleDetails.module.scss';

export const JournalDescription: React.FC<any> = ({
  isLoggedIn,
  description,
  guestUserDescription
}) => {
  const { urls } = URLConstant;
  const t = translate();
  const location = useLocation();
  const pathName = location?.pathname;

  return (
    <>
      {isLoggedIn ? (
        <div className={styles.ebookContent}>
          {description
            ? description
            : t(AppConstant.KNOWLEDGE_HUB_PAGE.NO_DESCRIPTION_AVAILABLE)}
        </div>
      ) : (
        <div className={`${styles.ebookContent} ${styles.guestDescWrapper}`}>
          {guestUserDescription
            ? guestUserDescription
            : t(AppConstant.KNOWLEDGE_HUB_PAGE.NO_DESCRIPTION_AVAILABLE)}
          {'...'}
          <div className={styles.guestDesc}>
            {/* <Link to={urls.signUpPage} state={{ pathName: pathName }}>
              <button>
                {t(AppConstant.AUTHENTICATION_PAGE.SIGN_UP_TO_CONTINUE)}
              </button>
            </Link> */}
          </div>
        </div>
      )}
    </>
  );
};
