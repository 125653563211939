import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import {
  addOrUpdateUserGamificationPoints,
  AppConstant,
  azureFile,
  convertUTCDate,
  convertUTCTime,
  fetchSpeakerList,
  GAMIFICATION_ACTION_ID,
  GAMIFICATION_ENABLED,
  GAMIFICATION_TYPE_UNIQUE_CODE,
  getBlobFilePath,
  getDefaultImage,
  getDefaultValue,
  getHideAuthor,
  getIconForContentType,
  getNotificationActionList,
  getOnlyDate,
  getSpeakerListSelector,
  handleRecognitionPopup,
  ImageConstant,
  listGamificationPointDistribution,
  RECOGNITION_MESSAGE_TEXT,
  savePageNoLeftWhileReading,
  sendNotification,
  translate,
  updateUserRecognition,
  useAppDispatch,
  useAppSelector,
} from '@shalina-app/shared';
import parse from 'html-react-parser';
import { Link, useLocation, useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';

import { ButtonComponent, Speciality } from '../../../..';
import {
  convertToSpecialties,
  getImageSrc,
  removedFromStore,
  retrieveData,
  STORAGE_CONSTANTS,
  storeData,
} from '../../../../../utils';
import { ShareSection } from '../../components/shareSection';
import { WebinarDetailsProps } from './webinarDetails.interface';
import styles from './webinarDetails.module.scss';
import { URLConstant } from '../../../../../routes';
import { SignUpPopup } from '../../../../../features';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const WebinarDetails: React.FC<WebinarDetailsProps> = ({
  contentData,
  // accessUrl,
  handleWebinarLink,
  isRegistered,
  buttonText,
  buttonLoader,
}) => {
  const t = translate();
  const location = useLocation();
  const params = useParams();
  const pathName = location?.pathname;

  const contentId = params?.contentId || '';
  const contentDetails: any = contentData ? contentData : null;
  const user = retrieveData(STORAGE_CONSTANTS.USER);
  const isPastWebinar: boolean =
    contentDetails?.event_data?.event_status === 'past';
  const isRegisteredValue: boolean = contentDetails?.event_data?.is_registered;
  const {
    ViewsIcon,
    DateIcon,
    TimeIcon,
    BookmarkIcon,
    DurationIcon,
    ThumbIcon,
    ShareIcon,
    AuthorIcon,
  } = ImageConstant.SVG_ICONS;
  const isBookmarked: boolean = contentDetails?.is_bookmarked || false;
  const isLiked: boolean = contentDetails?.is_liked || false;
  const contentIcon: any = useAppSelector(
    getIconForContentType(contentDetails?.field_unique_code)
  );
  const speakerDetails = useAppSelector(getSpeakerListSelector);
  const notificationList = useAppSelector(getNotificationActionList);
  const dispatch = useAppDispatch();
  const eventID = contentDetails?.event_id;

  const [value, setValue] = useState(0);
  const [reload, setReload] = useState(false);
  const [accessUrlLoader, setAccessUrlLoader] = useState(false);
  const [accessUrl, setAccessUrl] = useState('');
  const [videoPlaybackTime, setVideoPlaybackTime] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);

  const playerRef = useRef<any>(null);

  const gamificationPointDistributionData = useAppSelector(
    listGamificationPointDistribution
  );

  useEffect(() => {
    setReload(true);
    setAccessUrlLoader(true);

    return () => {
      const totalTime = retrieveData(STORAGE_CONSTANTS.TOTAL_VIDEO_TIME);
      const time = retrieveData(STORAGE_CONSTANTS.VIDEO_TIME);

      if (totalTime && time) {
        saveVideoTimeStamp(contentDetails?.id, time, totalTime);
        removedFromStore(STORAGE_CONSTANTS.TOTAL_VIDEO_TIME);
        removedFromStore(STORAGE_CONSTANTS.VIDEO_TIME);
      }
    };
  }, [contentDetails]);

  useEffect(() => {
    if (contentDetails?.last_read_at) {
      setVideoPlaybackTime(contentDetails?.last_read_at); // convert to seconds
    }
  }, [contentDetails?.last_read_at]);

  useEffect(() => {
    if (accessUrlLoader) {
      // loadAccessUrl();
    }
  }, [accessUrlLoader, contentDetails]);

  const sendNotificationForMilestoneUpdate = async () => {
    const action = notificationList?.find(
      (item: any) =>
        item?.field_unique_code === GAMIFICATION_ACTION_ID.UPDATE_MILESTONE
    )?.title;

    const req = {
      action: action,
      device: 'web',
    };
    // Hide Continue where you left feature
    await dispatch(sendNotification(req));
  };

  const updateGamificationPoints = async () => {
    if (isLoggedIn && GAMIFICATION_ENABLED) {
      checkRecognition();
      const gamificationDataItem = gamificationPointDistributionData?.find(
        (item: any) =>
          item.field_unique_code === GAMIFICATION_TYPE_UNIQUE_CODE.READ_CONTENT
      );
      if (gamificationDataItem && gamificationDataItem?.field_unique_code) {
        const payload = {
          field_unique_code: gamificationDataItem?.field_unique_code,
          entityTypeId: contentId,
        };
        const response: any = await dispatch(addOrUpdateUserGamificationPoints(payload)).unwrap();
        if(response && response?.info) {
          sendNotificationForMilestoneUpdate()
        }
      }
    }
  };

  const checkRecognition = async () => {
    if (contentId) {
      const response = await dispatch(
        updateUserRecognition({
          view: 1,
          contentId: contentId,
        })
      ).unwrap();
      if (
        response &&
        response?.status === 'SUCCESS' &&
        response?.statusCode === 200 &&
        response?.message === RECOGNITION_MESSAGE_TEXT.AVID_READER &&
        response?.data
      ) {
        const obj = {
          showRecognitionPopup: true,
          recognitionData: response?.data,
        };
        dispatch(handleRecognitionPopup(obj));
      } else {
        const obj = {
          showRecognitionPopup: false,
          recognitionData: {},
        };
        dispatch(handleRecognitionPopup(obj));
      }
    }
  };

  const loadAccessUrl = async () => {
    if (
      contentDetails &&
      contentDetails?.field_supporting_docs &&
      !contentDetails?.field_is_ebsco
    ) {
      const response = await dispatch(
        azureFile({ fileUrl: contentDetails?.field_supporting_docs }) as any
      ).unwrap();
      if (
        response &&
        response?.statusCode === 200 &&
        response?.status === 'SUCCESS' &&
        response?.data
      ) {
        setAccessUrl(response?.data);
        setAccessUrlLoader(false);
      } else {
        setAccessUrl('');
        setAccessUrlLoader(false);
      }
    } else if (
      contentDetails &&
      contentDetails?.field_upload_video_trailer &&
      !contentDetails?.field_is_ebsco
    ) {
      const response = await dispatch(
        azureFile({
          fileUrl: contentDetails?.field_upload_video_trailer,
        }) as any
      ).unwrap();
      if (
        response &&
        response?.statusCode === 200 &&
        response?.status === 'SUCCESS' &&
        response?.data
      ) {
        setAccessUrl(response?.data);
        setAccessUrlLoader(false);
      } else {
        setAccessUrl('');
        setAccessUrlLoader(false);
      }
    } else {
      setAccessUrlLoader(false);
    }
  };

  useEffect(() => {
    if (reload) {
      loadSpeakerDetailsApi();
      if (
        !contentDetails?.field_is_ebsco &&
        (contentDetails?.field_supporting_docs ||
          contentDetails?.field_upload_video_trailer)
      ) {
        updateGamificationPoints();
      }
      setReload(false);
    }
  }, [reload, eventID]);

  const loadSpeakerDetailsApi = async () => {
    if (eventID) {
      const eventIdString = eventID.toString(); // Convert eventID to string
      await dispatch(fetchSpeakerList({ eventId: eventIdString }));
    }
  };
  const { urls } = URLConstant;
  const isLoggedIn: boolean = retrieveData(STORAGE_CONSTANTS.IS_LOGGED_IN);
  const shortDesc = contentDetails?.field_description?.substring(0, 330);
  const guestUserDescription = shortDesc ? parse(shortDesc) : '';
  const description = contentDetails?.field_description
    ? parse(contentDetails?.field_description)
    : '';

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleWebinarClick = async () => {
    if(isLoggedIn) {
      setShowSignUp(false);
      const payload = {
        eventId: eventID,
        contentId: contentDetails?.id,
        body: {
          emailId: user?.emailId || '',
        },
        action: isPastWebinar
          ? 'register'
          : isRegistered
          ? 'join-now'
          : 'register',
      };
      if (handleWebinarLink) {
        handleWebinarLink(payload, isPastWebinar);
      }
    } else {
      setShowSignUp(true);
    }
  };

  const saveVideoTimeStamp = async (id: any, time: any, totalTime: any) => {
    const payload = {
      id: id,
      lastreadat: time === totalTime ? null : `${time}`,
    };
    await dispatch(savePageNoLeftWhileReading(payload));
  };

  const handleSignUpClose = () => {
    setShowSignUp(false);
  };

  const handleLaunchVideo = () => {
    if (!isLoggedIn) {
      setShowSignUp(true);
    }
  };

  const handleImgClick = () => {
    if (!isLoggedIn) {
      setShowSignUp(true);
    } else {
      setShowSignUp(false);
    }
  };

  const isAzureFileUrlLoading = useAppSelector(
    (state) => state?.common?.azureFileUrl.isAzureFileUrlLoading
  );

  const hideAuthor = getHideAuthor(
    contentDetails?.field_module_type,
    contentDetails?.field_unique_code
  );

  const isPlayVideo =
    !contentDetails?.field_is_ebsco &&
    (contentDetails?.field_supporting_docs ||
      contentDetails?.field_upload_video_trailer)
      ? true
      : false;

  const authoredBy =
    isPlayVideo && contentDetails?.field_authored_by
      ? contentDetails?.field_authored_by?.split(',')
      : [];

  const videoUrl = contentDetails?.field_supporting_docs
    ? getBlobFilePath(contentDetails?.field_supporting_docs)
    : getBlobFilePath(contentDetails?.field_upload_video_trailer);
  return (
    <>
      {isPlayVideo && (
        <>
          <div
            className={`${styles.videoPlayer} ${!isLoggedIn && styles.guest}`}
            onClick={handleImgClick}
          >
            <ReactPlayer
              ref={playerRef}
              url={videoUrl}
              controls
              playing={isLoggedIn ? true : false}
              onProgress={(progress) => {
                setVideoPlaybackTime(progress?.playedSeconds);
                storeData(
                  STORAGE_CONSTANTS.VIDEO_TIME,
                  progress?.playedSeconds
                );
                // saveVideoTimeStamp(false);
              }}
              onDuration={(time) => {
                storeData(STORAGE_CONSTANTS.TOTAL_VIDEO_TIME, time);
              }}
              onPause={() => {
                // saveVideoTimeStamp(false);
                // setWatchingFullVideo(false);
              }}
              onReady={() => {
                if (!isReady) {
                  if (playerRef?.current && videoPlaybackTime) {
                    playerRef?.current?.seekTo(videoPlaybackTime);
                  }
                  setIsReady(true);
                }
              }}
              height={'auto'}
              width={'auto'}
              // onEnded={() => {
              //   saveVideoTimeStamp(true);
              // }}
            />

            {!isLoggedIn && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}
              />
            )}
          </div>
        </>
      )}

      <div
        className={`${styles.detailTop} ${isPlayVideo && styles.isPlayVideo}`}
      >
        {!isAzureFileUrlLoading && (
          <div className={styles.detailImg}>
            <div>
              {contentDetails?.field_detail_image ? (
                <img
                  className={!isLoggedIn ? styles.thumbnailImageGuest : ''}
                  src={getImageSrc(contentDetails?.field_detail_image)}
                  alt='artical img'
                  onError={(e) =>
                    ((e.target as HTMLInputElement).src = getDefaultImage(
                      true,
                      contentDetails?.field_module_type,
                      contentDetails?.field_unique_code
                    ))
                  }
                  onClick={handleImgClick}
                />
              ) : (
                <img
                  className={!isLoggedIn ? styles.thumbnailImageGuest : ''}
                  src={ImageConstant.SOURCE.webinarsDetailThumbnail}
                  alt='artical img'
                  onClick={handleImgClick}
                />
              )}
            </div>
            {!isPlayVideo && (
              <div className={styles.dateViews}>
                {
                  <>
                    <span>
                      <DateIcon />
                      <strong>{t(AppConstant.COMMON.STARTS)}</strong>
                      {contentDetails?.field_starts_on ? (
                        <p>{`${convertUTCDate(
                          contentDetails?.field_starts_on
                        )} ${convertUTCTime(
                          contentDetails?.field_starts_on
                        )}`}</p>
                      ) : (
                        <p>{getDefaultValue()}</p>
                      )}
                    </span>
                  </>
                }
                {/* <div className={styles.lineBreak}></div> */}
                {/* {contentDetails?.field_duration && ( */}
                {
                  <>
                    <span>
                      <DateIcon />
                      <strong>{t(AppConstant.COMMON.ENDS)}</strong>
                      {contentDetails?.field_expires_on ? (
                        <p>{`${convertUTCDate(
                          contentDetails?.field_expires_on
                        )} ${convertUTCTime(
                          contentDetails?.field_expires_on
                        )}`}</p>
                      ) : (
                        <p>{getDefaultValue()}</p>
                      )}
                    </span>
                  </>
                }
                {/* <span>
              <DurationIcon />
              <strong>{t(AppConstant.COMMON.DURATION)}</strong>
              {viewDurationInHours(contentDetails?.field_duration)}
            </span> */}
                {/* )} */}
                <span>
                  <ViewsIcon />
                  <strong>{t(AppConstant.COMMON.VIEWS)}</strong>
                  {contentDetails?.view_count ? contentDetails?.view_count : 0}
                </span>
              </div>
            )}
          </div>
        )}
        <div className={styles.detailInfo}>
          <div className={styles.tag}>
            {contentIcon ? (
              <img src={getImageSrc(contentIcon)} alt='content icon' />
            ) : null}
            <span>{contentDetails?.field_select_article_type}</span>
          </div>
          <h1 className={styles.detailTitle}>{contentDetails?.title}</h1>
          {contentDetails?.field_area_of_interest && (
            <div className={styles.specialityWrap}>
              <Speciality
                speciality={convertToSpecialties(
                  contentDetails?.field_area_of_interest || ''
                )}
                showAll={false}
              />
            </div>
          )}
          {!hideAuthor && contentDetails?.field_authored_by && (
            <div className={styles.publisher}>
              <AuthorIcon />
              <span className={styles.authorLabel}>
                {t(AppConstant.COMMON.PUBLISHED_BY)}
              </span>
              <span
                className={styles.authorText}
                title={contentDetails?.field_authored_by}
              >
                {contentDetails?.field_authored_by || getDefaultValue()}
              </span>
            </div>
          )}
          <div className={`${styles.dateViews} ${styles.MobileDateViews}`}>
            {contentDetails?.field_starts_on && (
              <>
                <span>
                  <DateIcon />
                  <strong>{t(AppConstant.COMMON.STARTS)}</strong>
                  {contentDetails?.field_starts_on ? (
                    <p>{`${convertUTCDate(
                      contentDetails?.field_starts_on
                    )} ${convertUTCTime(contentDetails?.field_starts_on)}`}</p>
                  ) : (
                    <p>{getDefaultValue()}</p>
                  )}
                </span>
              </>
            )}
            {/* <div className={styles.lineBreak}></div> */}
            {/* {contentDetails?.field_duration && ( */}
            {contentDetails?.field_expires_on && (
              <>
                <span>
                  <DateIcon />
                  <strong>{t(AppConstant.COMMON.ENDS)}</strong>
                  {contentDetails?.field_expires_on ? (
                    <p>{`${convertUTCDate(
                      contentDetails?.field_expires_on
                    )} ${convertUTCTime(contentDetails?.field_expires_on)}`}</p>
                  ) : (
                    <p>{getDefaultValue()}</p>
                  )}
                </span>
              </>
            )}
            {/* <span>
              <DurationIcon />
              <strong>{t(AppConstant.COMMON.DURATION)}</strong>
              {viewDurationInHours(contentDetails?.field_duration)}
            </span> */}
            {/* )} */}
            <span>
              <ViewsIcon />
              <strong>{t(AppConstant.COMMON.VIEWS)}</strong>
              {contentDetails?.view_count ? contentDetails?.view_count : 0}
            </span>
          </div>
          {isPlayVideo && (
            <div className={styles.dateViews}>
              {
                <>
                  <span>
                    <DateIcon />
                    <strong>{t(AppConstant.COMMON.STARTS)}</strong>
                    {contentDetails?.field_starts_on ? (
                      <p>{`${convertUTCDate(
                        contentDetails?.field_starts_on
                      )} ${convertUTCTime(
                        contentDetails?.field_starts_on
                      )}`}</p>
                    ) : (
                      <p>{getDefaultValue()}</p>
                    )}
                  </span>
                </>
              }
              {/* <div className={styles.lineBreak}></div> */}
              {/* {contentDetails?.field_duration && ( */}
              {
                <>
                  <span>
                    <DateIcon />
                    <strong>{t(AppConstant.COMMON.ENDS)}</strong>
                    {contentDetails?.field_expires_on ? (
                      <p>{`${convertUTCDate(
                        contentDetails?.field_expires_on
                      )} ${convertUTCTime(
                        contentDetails?.field_expires_on
                      )}`}</p>
                    ) : (
                      <p>{getDefaultValue()}</p>
                    )}
                  </span>
                </>
              }
              {/* <span>
              <DurationIcon />
              <strong>{t(AppConstant.COMMON.DURATION)}</strong>
              {viewDurationInHours(contentDetails?.field_duration)}
            </span> */}
              {/* )} */}
              <span>
                <ViewsIcon />
                <strong>{t(AppConstant.COMMON.VIEWS)}</strong>
                {contentDetails?.view_count ? contentDetails?.view_count : 0}
              </span>
            </div>
          )}
          <div className={styles.ctaShareWrapper}>
            {!isAzureFileUrlLoading && !isPlayVideo ? (
              <div className={styles.ctaShare}>
                <div className={styles.fullVideoCta}>
                  <ButtonComponent
                    disabled={false}
                    variant='outlined'
                    buttonText={t(buttonText)}
                    onPress={handleWebinarClick}
                    loader={buttonLoader}
                  />
                </div>
              </div>
            ) : (
              isPlayVideo &&
              !isLoggedIn && (
                <div className={styles.ctaShare}>
                  <div className={styles.fullVideoCta}>
                    <ButtonComponent
                      disabled={false}
                      variant='outlined'
                      buttonText={t(AppConstant.COMMON.LAUNCH_VIDEO)}
                      onPress={handleLaunchVideo}
                    />
                  </div>
                </div>
              )
            )}
            <div className={styles.shareAndViews}>
              <ShareSection
                fieldActions={contentDetails?.field_actions}
                isBookmarked={isBookmarked}
                isLiked={isLiked}
                title={contentDetails?.title}
                fileUrl={contentDetails?.field_supporting_docs}
                contentId={contentDetails?.id}
                fileDownloadUrl={accessUrl}
                content={contentDetails}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.shalinaTabs}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='Detail Tabs'
          className={styles.custTabs}
        >
          <Tab
            label={t(AppConstant.KNOWLEDGE_HUB_PAGE.DESCRIPTION_TAB_TEXT)}
            {...a11yProps(0)}
          />
          <Tab
            label={t(AppConstant.KNOWLEDGE_HUB_PAGE.SPEAKER_DETAILS_TAB_TEXT)}
            {...a11yProps(1)}
          />
        </Tabs>
        <div className={styles.custTabContent}>
          <CustomTabPanel value={value} index={0}>
            {isLoggedIn ? (
              <div className={styles.articalContent}>
                {description
                  ? description
                  : t(AppConstant.KNOWLEDGE_HUB_PAGE.NO_DESCRIPTION_AVAILABLE)}
              </div>
            ) : (
              <div
                className={`${styles.articalContent} ${styles.guestDescWrapper}`}
              >
                {guestUserDescription
                  ? guestUserDescription
                  : t(AppConstant.KNOWLEDGE_HUB_PAGE.NO_DESCRIPTION_AVAILABLE)}
                {'...'}
                <div className={styles.guestDesc}>
                  {/* <Link to={urls.signUpPage} state={{ pathName: pathName }}>
                    <button>
                      {t(AppConstant.AUTHENTICATION_PAGE.SIGN_UP_TO_CONTINUE)}
                    </button>
                  </Link> */}
                </div>
              </div>
            )}
            {/* {contentDetails?.field_description && (
							<div className={styles.articalContent}>
								<div className={styles.guestDesc}>{description}</div>
							</div>
						)} */}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <div
              className={`${styles.speakerList} ${
                !isLoggedIn && styles.guestDescWrapper
              }`}
            >
              {!isLoggedIn && (
                <div className={styles.guestDesc}>
                  {/* <Link to={urls.signUpPage} state={{ pathName: pathName }}>
                    <button>
                      {t(AppConstant.AUTHENTICATION_PAGE.SIGN_UP_TO_CONTINUE)}
                    </button>
                  </Link> */}
                </div>
              )}
              {!isPlayVideo && speakerDetails && speakerDetails.length > 0 ? (
                speakerDetails?.map((item: any) => {
                  return (
                    <div className={styles.speaker}>
                      <div className={styles.speakerImg}>
                        <img
                          src={item?.picture}
                          alt='speaker image'
                          onError={(e) =>
                            ((e.target as HTMLInputElement).src =
                              ImageConstant.SOURCE.defaultUserPic)
                          }
                        />
                      </div>
                      <div className={styles.speakerinfo}>
                        <p className={styles.name}>{item?.user_name}</p>
                        {item.designation && !item.company && (
                          <p className={styles.role}>{`${item.designation}`}</p>
                        )}
                        {!item.designation && item.company && (
                          <p className={styles.role}>{`${item.company}`}</p>
                        )}
                        {item.designation && item.company && (
                          <p
                            className={styles.role}
                          >{`${item.designation} | ${item.company}`}</p>
                        )}
                      </div>
                    </div>
                  );
                })
              ) : authoredBy && authoredBy?.length > 0 ? (
                authoredBy?.map((author: any) => (
                  <div className={styles.speaker}>
                    <div className={styles.speakerImg}>
                      <img
                        src={ImageConstant.SOURCE.defaultUserPic}
                        alt='speaker image'
                        onError={(e) =>
                          ((e.target as HTMLInputElement).src =
                            ImageConstant.SOURCE.defaultUserPic)
                        }
                      />
                    </div>
                    <div className={styles.speakerinfo}>
                      <p className={styles.name}>{author}</p>
                    </div>
                  </div>
                ))
              ) : (
                <p>{t(AppConstant.COMMON.NO_SPEAKER_DETAILS)}</p>
              )}
            </div>
          </CustomTabPanel>
        </div>
      </div>
      <SignUpPopup open={showSignUp} handleClose={handleSignUpClose} />
    </>
  );
};

export default WebinarDetails;
