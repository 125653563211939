import { CardSlider, RssNewsFeedCard } from '../../../../components';
import { RssFeedSliderIteamsProps } from './rssFeedSlider.interface';

export const RssFeedSlider: React.FC<RssFeedSliderIteamsProps> = ({
  slidesToShow,
  contentHeading,
  isViewAllExist,
  showControl,
  handleViewAllClick,
  data,
  handleRssCardClick,
  loading
}) => {
  return (
    <>
      <CardSlider
        slidesToShow={slidesToShow}
        sliderHeading={contentHeading}
        isViewAllExist={isViewAllExist ? true : false}
        showControl={showControl}
        handleViewAllButtonClick={handleViewAllClick}
        isDataExist={data && data.length > 0 ? true : false}
        ebookClassName=''
        dataLength={data?.length}
        loading={loading}
      >
        {data &&
          data.length > 0 &&
          data.map((item: any, index: number) => (
            <div key={index}>
              <RssNewsFeedCard
                isDescription={false}
                rssData={item}
                handleRssCardTitleClick={handleRssCardClick}
              ></RssNewsFeedCard>
            </div>
          ))}
      </CardSlider>
    </>
  );
};
