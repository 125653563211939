"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMaxValueToRedeem = exports.RECOGNITION_MESSAGE_TEXT = exports.RECOGNITION_BADGES = exports.SHARE_MODULE_NAMES = exports.COMMUNITY_TYPE = exports.REQUEST_CONTENT_STATUS_TYPE = exports.PROFILE_PAGE_TABS_ENUM = exports.getBlobFilePath = exports.RESOURCES_TYPE = exports.COMMUNITY_API_REQUEST_STATUS = exports.FILTER_BY_TABS_ENUM = exports.SORT_FILTER_BY_TABS = exports.USER_GAMIFICATION_FILTER_RANGE_IN_DAYS = exports.GAMIFICATION_TYPE_UNIQUE_CODE = exports.GAMIFICATION_POINTS_TAB_LABEL = exports.GAMIFICATION_POINTS_TAB_ENUM = exports.COMMUNITIES_API_CATEGORY_TYPES = exports.TAB_SCREEN = exports.SCREEN_NOT_TO_PERSIST = exports.EVENTS_TAB_API_PAYLOAD_ENUM = exports.NOTIFICATIONS_SETTINGS_ENUM = exports.DETAIL_TABS_ENUM = exports.REQUEST_MODULE_VARIABLE = exports.REQUEST_NOTIFICATION_STATUS = exports.REQUEST_STATUS = exports.REQUEST_TYPE_UNIQUE_CODE = exports.DEFAULT_MAX_CARDS_TO_SHOW_FOR_VIEW_ALL = exports.DEFAULT_MAX_CARDS_TO_SHOW_FOR_ARTICLES = exports.EXTRA_CARD_CONTENT_STRING = exports.CTA_ACTIONS = exports.SHALINA_TV_SEQUENCE = exports.KNOWLEDGE_HUB_SEQUENCE = exports.DEFAULT_MAX_CARDS_TO_SHOW_FOR_FEATURED = exports.NOT_AVAILABLE_STRING = exports.SURVEY_QUESTION_FIELD_TYPE = exports.DEFAULT_MAX_CARDS_TO_SHOW = exports.SLIDE_TO_SHOW_MOBILE = exports.LANGUAGE_UNIQUE_ID = exports.SLIDE_TO_SHOW_WEB = exports.LANGUAGE_PREFERENCE = exports.FIELD_ACTIONS_MASTER = exports.FIELD_ACTIONS = exports.LISTING_PAGE_CONSTANTS = exports.REQUEST_NOTIFICATION_CODE = exports.COMMUNITY_ACTION_ID = exports.GAMIFICATION_ACTION_ID = exports.CONTENT_TYPE_ID = exports.HOME_CARD_CATEGORY_TYPES = exports.MARKETING_CARD_CONTENT_TYPES = exports.API_CATEGORY_TYPES = void 0;
exports.GAMIFICATION_MASTER_KEYS = void 0;
const api_1 = require("../api");
exports.API_CATEGORY_TYPES = {
    KNOWLEDGE_HUB: 'knowledge_hub',
    SHALINA_TV: 'shalina_tv',
    LEARNING_CENTER: 'learning_center',
    COMMUNITIES: 'communities',
};
/**
 * Medical calculator
medical calculator detail page
Survey listing
Survey detail
Community list
Community detail
Community Discussion
medical news list
medical news detail
Medical news external link
notification list
About us
 */
exports.MARKETING_CARD_CONTENT_TYPES = {
    KNOWLEDGE_HUB: 'knowledge-hub',
    SHALINA_TV: 'shalina-tv',
    LEARNING_CENTER: 'learning-center',
    COMMUNITIES: 'communities',
    MEDICAL_CALCULATOR: 'medical-calculator',
    SURVEY: 'survey',
    MEDICAL_NEWS_LISTING: 'medical-news-feeds-listing',
    MEDICAL_NEWS_DETAIL: 'medical-news-feeds-detail',
    NOTIFICATIONS: 'notifications',
    ABOUT_US: 'about-us',
    HELP_SUPPORT: 'help-support',
    MY_PROFILE: 'my-profile',
};
exports.HOME_CARD_CATEGORY_TYPES = {
    KNOWLEDGE_HUB: 'knowledge_hub',
    SHALINA_TV: 'shalina_tv',
    LEARNING_CENTER: 'learning_center',
    MEDICAL_CALCULATOR: 'MEDICAL_CALCULATOR',
    MEDICAL_NEWS: 'MEDICAL_NEWS',
};
exports.CONTENT_TYPE_ID = {
    CLINICAL_GUIDELINES: '1004',
    E_BOOKS: '1005',
    JOURNALS_ARTICLES: '1006',
    WEBINARS: '1009',
    RESOURCES: '1007',
    CLINICAL_ASSESSMENT_PROTOCOL: '1003',
    DISEASES_AND_CONDITIONS: '1000',
    PRACTICE_ESSENTIALS: '1002',
    SURGURIES_AND_PROCEDURES: '1001',
    MAGZINES: '1008',
    ARTICLE: '2001',
};
exports.GAMIFICATION_ACTION_ID = {
    CONTINUE_WHERE_YOU_LEFT: '1001',
    PROFILE_COMPLETION: '1002',
    CONTENT_PUBLISH: '1000',
    UPDATE_MILESTONE: '2001',
    WEBINAR_BEFORE_24_HOURS: '2006',
    WEBINAR_BEFORE_2_HOURS: '2007'
};
exports.COMMUNITY_ACTION_ID = [
    '10001',
    '10002',
    '10003',
    '10004',
    '10005',
    '10006',
    '10007',
    '10008',
    '10010',
    '2005'
];
exports.REQUEST_NOTIFICATION_CODE = '10009';
exports.LISTING_PAGE_CONSTANTS = {
    CONTINUE_WHERE_LEFT_OFF: 'CONTINUE_WHERE_LEFT_OFF',
    RECOMMENDED_FOR_YOU: 'RECOMMENDED_FOR_YOU',
    TRENDING_NOW: 'TRENDING_NOW',
    NEWLY_ADDED: 'NEWLY_ADDED',
    MEDICAL_NEWS: 'MEDICAL_NEWS',
    EXPLORE_COMMUNITIES: 'EXPLORE_COMMUNITIES',
};
exports.FIELD_ACTIONS = [
    { tid: '7', name: 'Download in App' },
    { tid: '8', name: 'Download on Device' },
    { tid: '9', name: 'Like' },
    { tid: '10', name: 'Number of Views' },
    { tid: '11', name: 'Share' },
];
exports.FIELD_ACTIONS_MASTER = {
    APP_DOWNLOAD: '7',
    WEB_DOWNLOAD: '8',
    LIKE: '9',
    VIEWS: '10',
    SHARE: '11',
};
exports.LANGUAGE_PREFERENCE = {
    ENGLISH: 'en',
    FRENCH: 'fr',
    PORTUGUESE: 'pt',
};
exports.SLIDE_TO_SHOW_WEB = {
    DEFAULT: 3,
    FEATURED: 1,
    EXPLORE_MORE: 4,
    EXPLORE_COMMUNITIES: 4,
    OUR_EXPERTS: 4,
};
exports.LANGUAGE_UNIQUE_ID = {
    ENGLISH: 'En',
    FRENCH: 'Fr',
    PORTUGUESE: 'Pt',
};
exports.SLIDE_TO_SHOW_MOBILE = {
    DEFAULT: 5,
    FEATURED: 1,
    EXPLORE_MORE: 4,
    EXPLORE_COMMUNITIES: 4,
    OUR_EXPERTS: 2,
};
exports.DEFAULT_MAX_CARDS_TO_SHOW = 5;
exports.SURVEY_QUESTION_FIELD_TYPE = {
    RADIO: 'radios',
    CHECKBOXES: 'checkboxes',
    INPUT: 'textfield',
    TEXT_AREA: 'textarea',
    SELECT: 'select',
    MULTI_SELECT: 'multi-select',
    STAR_RATING: 'webform_rating',
    RANGE_SLIDER: 'range',
    FILE_UPLOAD: 'webform_image_file',
};
exports.NOT_AVAILABLE_STRING = 'NA';
exports.DEFAULT_MAX_CARDS_TO_SHOW_FOR_FEATURED = 20;
exports.KNOWLEDGE_HUB_SEQUENCE = [
    exports.CONTENT_TYPE_ID === null || exports.CONTENT_TYPE_ID === void 0 ? void 0 : exports.CONTENT_TYPE_ID.JOURNALS_ARTICLES,
    exports.CONTENT_TYPE_ID === null || exports.CONTENT_TYPE_ID === void 0 ? void 0 : exports.CONTENT_TYPE_ID.E_BOOKS,
    exports.CONTENT_TYPE_ID === null || exports.CONTENT_TYPE_ID === void 0 ? void 0 : exports.CONTENT_TYPE_ID.CLINICAL_GUIDELINES,
    exports.CONTENT_TYPE_ID === null || exports.CONTENT_TYPE_ID === void 0 ? void 0 : exports.CONTENT_TYPE_ID.MAGZINES,
    exports.CONTENT_TYPE_ID === null || exports.CONTENT_TYPE_ID === void 0 ? void 0 : exports.CONTENT_TYPE_ID.RESOURCES,
    exports.CONTENT_TYPE_ID === null || exports.CONTENT_TYPE_ID === void 0 ? void 0 : exports.CONTENT_TYPE_ID.ARTICLE,
];
exports.SHALINA_TV_SEQUENCE = [
    exports.CONTENT_TYPE_ID === null || exports.CONTENT_TYPE_ID === void 0 ? void 0 : exports.CONTENT_TYPE_ID.DISEASES_AND_CONDITIONS,
    exports.CONTENT_TYPE_ID === null || exports.CONTENT_TYPE_ID === void 0 ? void 0 : exports.CONTENT_TYPE_ID.PRACTICE_ESSENTIALS,
    exports.CONTENT_TYPE_ID === null || exports.CONTENT_TYPE_ID === void 0 ? void 0 : exports.CONTENT_TYPE_ID.SURGURIES_AND_PROCEDURES,
    exports.CONTENT_TYPE_ID === null || exports.CONTENT_TYPE_ID === void 0 ? void 0 : exports.CONTENT_TYPE_ID.CLINICAL_ASSESSMENT_PROTOCOL,
];
exports.CTA_ACTIONS = {
    OPEN_PDF: 'openPdf',
    OPEN_MODAL_WITH_EVENT_URL: 'openModalWithEventUrl',
    OPEN_TAB: 'openTab',
    DEFAULT: 'default',
};
exports.EXTRA_CARD_CONTENT_STRING = {
    DATE: 'date',
    DURATION: 'duration',
    AUTHOR: 'author',
    TAGS: 'tags',
};
exports.DEFAULT_MAX_CARDS_TO_SHOW_FOR_ARTICLES = 20;
exports.DEFAULT_MAX_CARDS_TO_SHOW_FOR_VIEW_ALL = 10;
exports.REQUEST_TYPE_UNIQUE_CODE = {
    REPORT_ACCOUNT_RELATED_ISSUE: '1001',
    REPORT_PRODUCT_RELATED_ISSUE: '1002',
    REQUEST_APP_RELATED_ISSUE: '1000',
    REQUEST_CONNECT_WITH_SHALINA_DELEGATES: '1003',
    REQUEST_CONTENT_RELATED_SUPPORT: '1004',
};
exports.REQUEST_STATUS = {
    OPEN: '1000',
    INPROGRESS: '1001',
    CLOSE: '1002',
    REVOKE: '1003',
};
exports.REQUEST_NOTIFICATION_STATUS = {
    CLOSE: '10009',
    OPEN: '10008',
};
exports.REQUEST_MODULE_VARIABLE = {
    REVOKED_UNIQUE_ID: '1002',
    REVOKED: 'Revoked',
};
exports.DETAIL_TABS_ENUM = {
    ARTICLES: 'articles',
    DESCRIPTION: 'description',
    ADDITIONAL_INFO: 'additional_info',
};
exports.NOTIFICATIONS_SETTINGS_ENUM = {
    INAPP: 'inapp',
    PUSH: 'push',
    EMAIL: 'email',
    SMS: 'sms',
    WHATSAPP: 'whatsapp',
    APP: 'app',
};
exports.EVENTS_TAB_API_PAYLOAD_ENUM = [
    {
        label: 'all',
        value: '',
    },
    {
        label: 'upcoming',
        value: 'publish',
    },
    {
        label: 'active',
        value: 'active',
    },
    {
        label: 'past',
        value: 'past',
    },
];
exports.SCREEN_NOT_TO_PERSIST = [
    'OnBoarding',
    'Login',
    'Signup',
    'Verification',
    'RequestReferralCode',
    'RequestSent',
    'TermsAndCondition',
    // 'Home',
    'AuthenticationStack',
    'ProfileSetup',
    'ProfileSetupComplete',
    'HelpAndSupport',
];
exports.TAB_SCREEN = [
    'Home',
    'KHub',
    'ShalinaTV',
    'Learning',
    'Communities',
];
exports.COMMUNITIES_API_CATEGORY_TYPES = {
    MY_COMMUNITY: 'my_community',
    RECOMMENDED_COMMUNITY: 'recommended_community',
    EXPLORE_COMMUNITY: 'explore_community',
    DETAIL: 'community_detail',
};
exports.GAMIFICATION_POINTS_TAB_ENUM = [
    {
        label: 'History',
        value: 'History',
    },
    {
        label: 'How to earn',
        value: 'How to earn',
    },
];
exports.GAMIFICATION_POINTS_TAB_LABEL = {
    HISTORY: 'History',
    HOW_TO_EARN: 'How to earn',
};
exports.GAMIFICATION_TYPE_UNIQUE_CODE = {
    PEER_TO_PEER: '1005',
    PROFILE_COMPLETION: '1001',
    REFERRAL: '1003',
    REGISTRATION: '1000',
    SHARE_CONTENT: '1002',
    SURVEY_COMPLETION: '1004',
    READ_CONTENT: '1009',
    REDEEM_POINTS: '1007'
};
exports.USER_GAMIFICATION_FILTER_RANGE_IN_DAYS = 90;
exports.SORT_FILTER_BY_TABS = {
    CONTENT_TYPE: 0,
    SPECIALITY: 1,
    COMMUNITY_CATEGORY: 2,
    COMMUNITY_COUNTRY: 3,
};
exports.FILTER_BY_TABS_ENUM = {
    CONTENT_TYPE: 'contentType',
    SPECIALITY: 'speciality',
    COMMUNITY_CATEGORY: 'category',
    COMMUNITY_COUNTRY: 'country',
};
exports.COMMUNITY_API_REQUEST_STATUS = {
    SENT: 'sent',
    RECEIVED: 'received',
};
exports.RESOURCES_TYPE = {
    IMAGE: 'image',
    VIDEO: 'video',
    DOCUMENT: 'document',
};
const getBlobFilePath = (filePath) => {
    if ((filePath === null || filePath === void 0 ? void 0 : filePath.includes('https')) || (filePath === null || filePath === void 0 ? void 0 : filePath.includes('http'))) {
        return `${filePath}${api_1.BLOB_FILES_CONTAINER_PARAMS}`;
    }
    else if (filePath === null || filePath === void 0 ? void 0 : filePath.includes('/sites/default/files/')) {
        return `${api_1.IMAGE_BASE_URL_WITHOUT_SLASH}${filePath}`;
    }
    else {
        return '';
    }
};
exports.getBlobFilePath = getBlobFilePath;
exports.PROFILE_PAGE_TABS_ENUM = {
    BOOKMARKS: 'bookmarks',
    ACHIEVEMENTS: 'achievements',
    REGISTERED_EVENTS: 'registeredEvents',
    REQUEST: 'request',
    NOTIFICATION_SETTING: 'notificationSetting',
    ACCOUNT_SETTING: 'accountSetting',
    LANGUAGE_PREFERENCE: 'languagePreference',
    UPDATE_PROFILE: 'updateProfile',
};
exports.REQUEST_CONTENT_STATUS_TYPE = {
    OPEN: '448',
    CLOSE: '447',
    REVOKED: '449',
};
exports.COMMUNITY_TYPE = {
    OPEN: 'Open',
    CLOSE: 'Close',
};
exports.SHARE_MODULE_NAMES = {
    KHUB: 'knowledge-hub',
    SHALINA_TV: 'shalina-tv',
    LEARNING_CENTER: 'learning-center',
    COMMUNITY: 'communities',
    SURVEY: 'survey'
};
exports.RECOGNITION_BADGES = {
    AVID_READER: '1000',
    CONSISTENT_READER: '1001',
    PEER_LEARNER: '1002',
    SHARE_MASTER: '1003'
};
exports.RECOGNITION_MESSAGE_TEXT = {
    CONSISTENT_READER: 'CONSISTENT_READER',
    SHARE_MASTER: 'SHARE_MASTER',
    AVID_READER: 'AVID_READER'
};
const getMaxValueToRedeem = (points, interval) => {
    const maxAchieveValue = Math.floor(points / interval) * interval;
    return maxAchieveValue;
};
exports.getMaxValueToRedeem = getMaxValueToRedeem;
exports.GAMIFICATION_MASTER_KEYS = {
    MINIMUM_POINT_FOR_REDEMPTION: 'MINIMUM_POINT_FOR_REDEMPTION',
    REDEMPTION_RATE: 'REDEMPTION_RATE',
};
