import { useEffect, useMemo, useState } from 'react';
import {
  ImageConstant,
  surveyDetails,
  getDynamicSurveyDetails,
  useAppDispatch,
  useAppSelector,
  getOnlyDate,
  AppConstant,
  translate,
  logEvent,
  CUSTOM_ANALYTICS_EVENT_NAMES,
  PlatformNames,
  ShareModules,
  getAnalyticsUserDetails,
  getLoggedInUserInfo,
} from '@shalina-app/shared';
import { useLocation, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from 'react-share';

import { SurveyDetailsPropsItems } from './surveyDetails.interface';
import {
  STORAGE_CONSTANTS,
  getImageSrc,
  retrieveData,
  useNavigatePageUtils,
  extractEncodeAndCreateLink,
  convertToSpecialties,
} from '../../../../utils';
import { URLConstant } from '../../../../routes';
import { BarLoader, BreadCrumb, ButtonComponent, Speciality, Toast } from '../../../../components';
import styles from './surveyDetails.module.scss';
import { SignUpPopup } from '../../../Authentication';
import { appInsights } from '../../../../applicationInsight';

export const SurveyDetails: React.FC<SurveyDetailsPropsItems> = () => {
  const { urls } = URLConstant;
  const location = useLocation();
  const params = useParams();
  const navigatePage = useNavigatePageUtils();
  const dispatch = useAppDispatch();
  const t = translate();

  const pathName = location?.pathname;
  const surveyId = params?.surveyId;

  const isLoggedIn: boolean = retrieveData(STORAGE_CONSTANTS.IS_LOGGED_IN);
  const user = retrieveData(STORAGE_CONSTANTS.USER);
  const userDetails = useAppSelector(getLoggedInUserInfo);

  const [apiCall, setApiCall] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const data = useAppSelector(getDynamicSurveyDetails);

  const getShareableLink = useMemo(() => {
    const baseUrl = window.location.origin;
    const link = `${baseUrl}/share?moduleName=survey&surveyId=${surveyId}`;

    const userName = user?.firstName + ' ' + user?.lastName;
    const contentMsg = `${t(AppConstant.PROFILE.HI_TEXT)}, ${userName} ${t(
      AppConstant.PROFILE.SHARE_CONTENT_MSG
    )} ${t(AppConstant.COMMON.SURVEY)} ${t(
      AppConstant.PROFILE.CONTENT_TEXT
    )} "${data?.title}" ${t(AppConstant.PROFILE.CLICK_TEXT)} ${link} ${t(
      AppConstant.PROFILE.SURVEY_SHARE_CONTENT_REMAINING_MSG
    )}`;
    return `${contentMsg}`;
  }, [data, user]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setApiCall(true);
  }, []);

  useEffect(() => {
    if (apiCall && surveyId) {
      dispatch(surveyDetails(parseInt(surveyId)));
      setApiCall(false);
    }
  }, [apiCall, surveyId]);

  const shortDesc = data?.field_description.substring(0, 330);
  const guestUserDescription = shortDesc ? parse(shortDesc) : '';
  const description = data?.field_description
    ? parse(data?.field_description)
    : '';
  const isSurveyLisitngLoading = useAppSelector(
    (state) => state?.survey?.isLoading
  );

  const handleStartQuiz = () => {
    if (isLoggedIn) {
      if (isSurveyNotBegin()) {
        setShowToast(true);
        setIsSuccess(false);
        setToastMessage(t(AppConstant.COMMON.SURVEY_NOT_STARTED_MESSAGE));
      } else {
        const navigateDetails = {
          isDynamicUrl: true,
          dynamicUrlOptions: {
            surveyId: data?.nid,
          },
          stateParams: {
            surveyUniqueId: data?.survey_id,
            title: data?.title,
            startDate: data?.field_start_date,
            endDate: data?.field_end_date
          },
        };
        navigatePage(urls.surveyQuiz, navigateDetails);
      }
    } else {
      setShowSignUp(true);
    }
  };

  const handleSignUpClose = () => {
    setShowSignUp(false);
  };

  const handleShareClick = () => {
    setShowShare((prev) => !prev);
    if (isLoggedIn && surveyId && data && user) {
      const userObj = userDetails || user;
      const userInfo = getAnalyticsUserDetails(userObj);
      logEvent(
        appInsights,
        'custom',
        CUSTOM_ANALYTICS_EVENT_NAMES.USER_SHARE_CONTENT,
        {
          contentId: surveyId,
          contentTitle: data?.title,
          userEmail: userInfo?.emailId,
          userMobile: userInfo?.mobile,
          userName: userInfo?.name,
          userQualification: userInfo?.qualification,
          userInterest: userInfo?.areaOfInterest,
          userSpecialization: userInfo?.specialization,
          userCountryOfPractice: userInfo?.countryOfPractice,
          userPreferredLanguage: userInfo?.preferredLanguage,
          platform: PlatformNames.WEB,
          moduleName: ShareModules.SURVEY
        }
      );
    }
  };

  const handleCopyButton = () => {
    const baseUrl = window.location.origin;
    const link = `${baseUrl}/share?moduleName=survey&surveyId=${surveyId}`;

    navigator.clipboard.writeText(link);
    setShowToast(true);
    setIsSuccess(true);
    setToastMessage(t(AppConstant.COMMON.LINK_COPY_MESSAGE));
  };

  const handleImgClick = () => {
    if (!isLoggedIn) {
      setShowSignUp(true);
    } else {
      setShowSignUp(false);
    }
  };

  const { DateIcon, AuthorIcon, ShareIcon, CopyClipboardIcon } =
    ImageConstant.SVG_ICONS;

  const isSurveyExpired = () => {
    const exp = data?.field_end_date;
    return new Date().valueOf() > new Date(exp ? exp : '').valueOf();
  };

  const isSurveyNotBegin = () => {
    const start = data?.field_start_date;
    return new Date().valueOf() < new Date(start ? start : '').valueOf();
  };

  const handleToastClose = () => {
    setShowToast(false);
  };

  return (
    <>
      <div className={styles.detailPage}>
        {isSurveyLisitngLoading && <BarLoader />}
        {!isSurveyLisitngLoading && (
          <div className='cust-container'>
            <BreadCrumb isSurvey={true} surveyTitle={data?.title} />
          </div>
        )}
        {isSurveyLisitngLoading ? (
          // <CircularLoader size={50} thickness={4} />
          ''
        ) : (
          <div className='cust-container'>
            <div className={styles.detailWrapper}>
              <div className={styles.detailTop}>
                <div className={styles.detailImg}>
                  {data?.field_detail_image ? (
                    <img
                      className={isLoggedIn ? '' : styles.thumbnailImageGuest}
                      src={getImageSrc(data?.field_detail_image)}
                      alt='survey img'
                      onClick={handleImgClick}
                    />
                  ) : (
                    <img
                      className={isLoggedIn ? '' : styles.thumbnailImageGuest}
                      src={ImageConstant.SOURCE.pdfDetailThumbnail}
                      alt='survey img'
                      onClick={handleImgClick}
                    />
                  )}
                  <div className={styles.datesWrap}>
                    <div className={styles.date}>
                      <DateIcon />
                      <span>{t(AppConstant.COMMON.STARTS)}</span>
                      {getOnlyDate(data?.field_start_date)}
                    </div>
                    <div className={styles.date}>
                      <DateIcon />
                      <span>{t(AppConstant.HOME_PAGE.EXPIRES)}</span>
                      {getOnlyDate(data?.field_end_date)}
                    </div>
                  </div>
                </div>
                <div className={styles.detailInfo}>
                  <div className={styles.tag}>
                    <img src={ImageConstant.SOURCE.SurveysIcon} alt='tag' />
                    {t(AppConstant.COMMON.SURVEY)}
                  </div>
                  <h1 className={styles.title}>{data?.title}</h1>
                  <div className={`${styles.datesWrap} ${styles.mobile}`}>
                    <div className={styles.date}>
                      <DateIcon />
                      <span>{t(AppConstant.COMMON.STARTS)}</span>
                      {getOnlyDate(data?.field_start_date)}
                    </div>
                    <div className={styles.date}>
                      <DateIcon />
                      <span>{t(AppConstant.HOME_PAGE.EXPIRES)}</span>
                      {getOnlyDate(data?.field_end_date)}
                    </div>
                  </div>

                  {data?.field_area_of_interest && (
                    <div className={styles.specialityWrap}>
                      <Speciality
                        speciality={convertToSpecialties(
                          data?.field_area_of_interest || ''
                        )}
                        showAll={false}
                      />
                    </div>
                  )}
                  <div className={styles.author}>
                    <AuthorIcon />
                    <span title={data?.field_survey_posted_by}>
                      <strong>{t(AppConstant.COMMON.BY_TEXT)}</strong>
                      {data?.field_survey_posted_by
                        ? data?.field_survey_posted_by
                        : 'NA'}
                    </span>
                  </div>
                  <div className={styles.shareAndCta}>
                    <div className={styles.beginCta}>
                      <ButtonComponent
                        variant='outlined'
                        buttonText={t(AppConstant.HOME_PAGE.BEGIN_SURVEY)}
                        disabled={isSurveyExpired() || data?.isSubmitted}
                        onPress={handleStartQuiz}
                      />
                    </div>
                    <div className={styles.shareWrapper}>
                      <div className={styles.detailActions}>
                        <button
                          className={`${
                            !isLoggedIn ||
                            data?.field_survey_type?.toLowerCase() === 'close' ||
                            isSurveyExpired() ||
                            isSurveyNotBegin()
                              ? styles.disabled
                              : ''
                          }`}
                          disabled={
                            !isLoggedIn ||
                            data?.field_survey_type?.toLowerCase() === 'close' ||
                            isSurveyExpired() ||
                            isSurveyNotBegin()
                          }
                        >
                          <ShareIcon onClick={handleShareClick} />
                        </button>
                      </div>
                      {showShare && (
                        <div className={styles.shareList}>
                          <div className={styles.shareListIcon}>
                            <span className={styles.copyIcon}>
                              <CopyClipboardIcon onClick={handleCopyButton} />
                            </span>
                          </div>
                          <div className={styles.shareListIcon}>
                            <FacebookShareButton
                              url={getShareableLink}
                              className='Demo__some-network__share-button'
                            >
                              <FacebookIcon size={32} round />
                            </FacebookShareButton>
                          </div>
                          <div className={styles.shareListIcon}>
                            <TwitterShareButton
                              url={getShareableLink}
                              className='Demo__some-network__share-button'
                            >
                              <XIcon size={32} round />
                            </TwitterShareButton>
                          </div>
                          <div className={styles.shareListIcon}>
                            <WhatsappShareButton
                              url={getShareableLink}
                              separator=''
                              className='Demo__some-network__share-button'
                            >
                              <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                          </div>
                          <div className={styles.shareListIcon}>
                            <LinkedinShareButton
                              url={getShareableLink}
                              className='Demo__some-network__share-button'
                            >
                              <LinkedinIcon size={32} round />
                            </LinkedinShareButton>
                          </div>
                          <div className={styles.shareListIcon}>
                            <EmailShareButton
                              url={getShareableLink}
                              subject={data?.title}
                              className='Demo__some-network__share-button'
                            >
                              <EmailIcon size={32} round />
                            </EmailShareButton>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${styles.detailDescription}`}>
                <h2>
                  {' '}
                  {t(AppConstant.KNOWLEDGE_HUB_PAGE.DESCRIPTION_TAB_TEXT)}
                </h2>
                {isLoggedIn ? (
                  <div className={styles.desText}>
                    <p>
                      {description
                        ? description
                        : t(
                            AppConstant.KNOWLEDGE_HUB_PAGE
                              .NO_DESCRIPTION_AVAILABLE
                          )}
                    </p>
                  </div>
                ) : (
                  <div
                    className={`${styles.desText} ${styles.guestDescWrapper}`}
                  >
                    <p>
                      {guestUserDescription
                        ? guestUserDescription
                        : t(
                            AppConstant.KNOWLEDGE_HUB_PAGE
                              .NO_DESCRIPTION_AVAILABLE
                          )}
                      {'...'}
                    </p>
                    <div className={styles.guestDesc}>
                      {/* <Link to={urls.signUpPage} state={{ pathName: pathName }}>
                        <button>
                          {t(
                            AppConstant.AUTHENTICATION_PAGE.SIGN_UP_TO_CONTINUE
                          )}
                        </button>
                      </Link> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <SignUpPopup open={showSignUp} handleClose={handleSignUpClose} />
      <Toast
        showToast={showToast}
        isSuccess={isSuccess}
        message={toastMessage}
        handleToastClose={handleToastClose}
      />
    </>
  );
};
