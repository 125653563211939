import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import { ImageConstant, isTranslationSupported } from '@shalina-app/shared';

import { DropdownProps } from '.';
import styles from './dropdown.module.scss';

export const Dropdown: React.FC<DropdownProps> = (props) => {
  const { DropDownArrowIcon } = ImageConstant.SVG_ICONS;
  const {
    defaultValue,
    dropdownId,
    dropdownVariant,
    dropdownOptions,
    labelText,
    className,
    isError,
    errorMessage,
    isRequired,
    handleDropDownChange,
    value,
    placeholder,
    isLanguage
  } = props;

  return (
    <div className={`${styles.shalinaSelect} ${className}`}>
      {labelText ? (
        <label htmlFor={labelText}>
          {labelText}
          {isRequired && <span>*</span>}
        </label>
      ) : (
        ''
      )}
      <Select
        value={value}
        displayEmpty
        IconComponent={() => <span className={styles.selectIcon}><DropDownArrowIcon /></span>}
        error={isError}
        fullWidth
        id={dropdownId}
        // label="Grouping"
        variant={dropdownVariant}
        onChange={handleDropDownChange}
      >
        {placeholder && (
          <MenuItem disabled value={``}>
            <span className={styles.placeHolderText}>{placeholder}</span>
          </MenuItem>
        )}
        {dropdownOptions &&
          dropdownOptions?.map((opt: any) =>
            isLanguage ? (
              <MenuItem
                value={opt.value}
                disabled={!isTranslationSupported(opt?.field_unique_code)}
              >
                {opt.label}
              </MenuItem>
            ) : (
              <MenuItem value={opt.value}>{opt.label}</MenuItem>
            )
          )}
      </Select>
      {isError && <FormHelperText error>{errorMessage}</FormHelperText>}
    </div>
  );
};
