import { useEffect, useState } from 'react';
import {
  AppConstant,
  CONTENT_TYPE_ID,
  FILTER_BY_TABS_ENUM,
  getFilterCategoriesOptionsValues,
  getOnlyDate,
  getOnlyTime,
  ImageConstant,
  RegisterEventData,
  SortFilterOptions,
  translate,
  useAppDispatch,
  useAppSelector,
  userRegisteredEvents
} from '@shalina-app/shared';

import { DEFAULT_SKIP, DEFAULT_TAKE } from '../../../../app.constants';
import {
  BarLoader,
  ButtonComponent,
  CircularLoader,
  InfiniteScrollComponent,
  SortFilter
} from '../../../../components';
import {
  getImageSrc,
  retrieveData,
  STORAGE_CONSTANTS,
  useNavigatePageUtils
} from '../../../../utils';
import { RegisteredEventsPagePropsType } from './registeredEvents.interface';
import styles from './registeredEvents.module.scss';
import { RegisterEventCard } from '../../components';
import { URLConstant } from '../../../../routes';

export const RegisteredEventsPage: React.FC<RegisteredEventsPagePropsType> = ({
  pageName,
  pageRoute,
  registeredEvents
}) => {
  const t = translate();
  const dispatch = useAppDispatch();
  const { urls } = URLConstant;
  const navigatePageUtils = useNavigatePageUtils();

  const defaulfFilter: SortFilterOptions = {
    interest: '',
    speciality: '',
    contentType: '',
    sort: '',
    take: DEFAULT_TAKE,
    skip: 0
  };
  const user = retrieveData(STORAGE_CONSTANTS.USER);
  const contentTypes = useAppSelector(getFilterCategoriesOptionsValues);

  // state params
  const [isLoader, setIsLoader] = useState(false);
  const [contentTypeId, setContentTypeId] = useState('');
  const [filterOptions, setFilterOptions] = useState(defaulfFilter);
  const [reload, setReload] = useState(false);
  const [filterCall, setFilterCall] = useState(false);
  const [reset, setReset] = useState(false);
  const [skip, setSkip] = useState(0);
  const [eventDataList, setEventDataList] = useState([]);
  const [contentLength, setContentLength] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLastPage, setIsLastPage] = useState(false);

  const registeredEventsListLoading = useAppSelector(
    (state) =>
      state?.learningCenter?.userRegisteredEventsList
        ?.userRegisteredEventsListLoading
  );

  useEffect(() => {
    setReload(true);
    setIsLoader(true);
  }, []);

  useEffect(() => {
    if (contentTypes) {
      const contentId = contentTypes.find(
        (value: any) => value.unique_id === CONTENT_TYPE_ID.WEBINARS
      );
      setContentTypeId(contentId?.value);
    }
  }, [contentTypes]);

  useEffect(() => {
    if (reload) {
      fetchRegisterEventApi();
      setReload(false);
    }
  }, [reload, filterOptions]);

  const fetchRegisterEventApi = async () => {
    const response: any = await dispatch(
      userRegisteredEvents({
        emailId: user?.emailId ? user.emailId : '',
        filterOptions
      })
    ).unwrap();

    if (
      response &&
      response?.status === 'SUCCESS' &&
      response?.statusCode === 200 &&
      response?.data
    ) {
      setIsLoader(false);
      const isLast: any = response?.data?.is_last_page;
      setIsLastPage(isLast);
      setEventDataList((prev) => prev?.concat(response?.data?.items as any));
      setTotalLength(response?.data?.total_cnt as number);
      const currentCount = response?.data?.cur_page_cnt
        ? response?.data?.cur_page_cnt
        : 0;
      setContentLength((prev) => prev + currentCount);
    } else {
      setIsLoader(false);
    }
  };

  const fetchMoreData = () => {
    // const totalLength = eventDataList?.data?.total_cnt;
    if (totalLength && contentLength >= totalLength) {
      setHasMore(false);
      return;
    }
    setSkip(skip + DEFAULT_SKIP);
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      skip: skip + DEFAULT_SKIP,
      take: DEFAULT_TAKE
    }));
    setReload(true);
  };

  const handleDiscoverLearningClick = () => {
    const navigateDetails = {
      isDynamicUrl: false
    };
    navigatePageUtils(urls.learningCenterPage, navigateDetails);
  };

  const eventsData =
    !eventDataList || eventDataList?.length === 0
      ? registeredEvents
      : eventDataList;
  const loading = !eventsData || eventsData?.length === 0 ? isLoader : false;
  return (
    <>
      {registeredEventsListLoading && (
        <div className={styles.loaderDiv}>
          <BarLoader />
        </div>
      )}
      <div className={styles.registerFilter} style={{ display: 'none' }}>
        <SortFilter
          isDark={true}
          tabsEnum={[
            FILTER_BY_TABS_ENUM.CONTENT_TYPE,
            FILTER_BY_TABS_ENUM.SPECIALITY
          ]}
        />
      </div>
      {loading ? (
        <CircularLoader size={50} thickness={4} />
      ) : !registeredEventsListLoading &&
        eventsData?.length > 0 &&
        (eventsData?.length ? eventsData?.length : 0) > 0 ? (
        <div className={styles.bookmarkCards}>
          <InfiniteScrollComponent
            dataLength={eventDataList?.length}
            fetchMoreData={fetchMoreData}
            hasMore={!isLastPage || hasMore}
            loader={true}
          >
            {eventsData?.map((item: RegisterEventData) => (
              <div className={styles.bookmarkCard}>
                <RegisterEventCard
                  id={parseInt(item?.nid)}
                  contentType={item?.course_type}
                  contentTypeId={contentTypeId}
                  title={item?.name}
                  speciality={[]}
                  date={getOnlyDate(item?.start_date)}
                  time={getOnlyTime(item?.start_date)}
                  publishedName={''}
                  viewsCount={''}
                  duration={''}
                  pageName={pageName}
                  pageRoute={pageRoute}
                  thumbnailImage={getImageSrc(item?.logo)}
                  isBookmarked={false}
                  mostViewed={false}
                  eventId={String(item?.id)}
                  uniqueContentTypeId={CONTENT_TYPE_ID.WEBINARS}
                />
              </div>
            ))}
          </InfiniteScrollComponent>
        </div>
      ) : !registeredEventsListLoading ? (
        <div className={styles.emptyEvent}>
          <img
            src={ImageConstant.SOURCE.emptyRegisteredEvent}
            alt='empty bookmark'
          />
          <h4 className={styles.title}>
            {t(AppConstant.COMMON.NO_REGISTERED_EVENTS)}
          </h4>
          <p className={styles.infoText}>
            {t(AppConstant.COMMON.NO_REGISTERED_EVENTS_SUBTEXT)}
          </p>
          <div className={styles.ctaExplore}>
            <ButtonComponent
              variant='outlined'
              buttonText={t(AppConstant.COMMON.DISCOVER_LEARNING_CENTER_CTA)}
              onPress={handleDiscoverLearningClick}
            />
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};
