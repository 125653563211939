"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateInitialEventDataList = exports.getInitialRegisteredEventList = exports.getUserRegisteredEventList = exports.getSpeakerListSelector = exports.webinarDetailsSelector = exports.getWebinarDetailsSelector = exports.getRegisterAndJoinSelector = exports.userRegisteredEvents = exports.fetchSpeakerList = exports.getWebinarDetails = exports.getRegisterAndJoinLink = void 0;
/*
 * File: notification.slice.ts
 * Project: Shalina Healthcare
 * File Created: Wednesday, 17th January 2024 10:42:17 pm
 * Author: Extentia Team 2024
 * -----
 * Last Modified: Wednesday, 17th January 2024 10:42:19 pm
 * Modified By: Extentia Team 2024
 * -----
 * Copyright Shalina Healthcare - 2024
 */
const toolkit_1 = require("@reduxjs/toolkit");
const api_1 = require("../../../api");
const constants_1 = require("../../constants");
const common_1 = require("../common");
const utils_1 = require("../../utils");
const initialState = {
    id: "",
    status: common_1.ApiStatusValues.idle,
    error: null,
    isLoading: false,
    registerAndJoin: {
        registerAndJoinData: {},
        isError: false,
        isRegisterAndJoinLoading: false,
    },
    webinarContentDetails: {
        webinarContentDetailsData: [],
        isError: false,
        isWebinarDetailsLoading: false,
    },
    speakerListDetails: {
        speakerListData: [],
        isError: false,
        isSpeakerDetailsLoading: false,
    },
    userRegisteredEventsList: {
        userRegisteredEventsData: {},
        initialEventsDataList: {},
        isError: false,
        userRegisteredEventsLoading: false,
    },
};
const learningCenterSlice = (0, toolkit_1.createSlice)({
    name: constants_1.LEARNING_CENTER,
    initialState,
    reducers: {
        updateInitialEventDataList: (state, action) => {
            var _a;
            state.userRegisteredEventsList.initialEventsDataList = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data;
        },
    },
    extraReducers(builder) {
        /**
         * POST: Register / Join the Webinar
         */
        builder.addCase(exports.getRegisterAndJoinLink.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.status = common_1.ApiStatusValues.loading;
            state.registerAndJoin.isRegisterAndJoinLoading = true;
        });
        builder.addCase(exports.getRegisterAndJoinLink.fulfilled, (state, action) => {
            var _a;
            // Add logic if any when API state is fulfilled or data is received from API
            state.status = common_1.ApiStatusValues.success;
            // Add any fetched posts to the array
            state.registerAndJoin.registerAndJoinData = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data;
            state.registerAndJoin.isRegisterAndJoinLoading = false;
        });
        builder.addCase(exports.getRegisterAndJoinLink.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.status = common_1.ApiStatusValues.error;
            state.registerAndJoin.isRegisterAndJoinLoading = false;
        });
        /**
         * GET: Webinar Event Details
         */
        builder.addCase(exports.getWebinarDetails.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.status = common_1.ApiStatusValues.loading;
            state.webinarContentDetails.isWebinarDetailsLoading = true;
        });
        builder.addCase(exports.getWebinarDetails.fulfilled, (state, action) => {
            var _a;
            // Add logic if any when API state is fulfilled or data is received from API
            state.status = common_1.ApiStatusValues.success;
            // Add any fetched posts to the array
            state.webinarContentDetails.webinarContentDetailsData =
                (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data;
            state.webinarContentDetails.isWebinarDetailsLoading = false;
        });
        builder.addCase(exports.getWebinarDetails.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.status = common_1.ApiStatusValues.error;
            state.webinarContentDetails.isWebinarDetailsLoading = false;
        });
        /**
         * GET: Speaker List Details
         */
        builder.addCase(exports.fetchSpeakerList.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.status = common_1.ApiStatusValues.loading;
            state.speakerListDetails.isSpeakerDetailsLoading = true;
        });
        builder.addCase(exports.fetchSpeakerList.fulfilled, (state, action) => {
            var _a, _b;
            // Add logic if any when API state is fulfilled or data is received from API
            state.status = common_1.ApiStatusValues.success;
            // Add any fetched posts to the array
            state.speakerListDetails.speakerListData = (_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.items;
            state.speakerListDetails.isSpeakerDetailsLoading = false;
        });
        builder.addCase(exports.fetchSpeakerList.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.status = common_1.ApiStatusValues.error;
            state.speakerListDetails.isSpeakerDetailsLoading = false;
        });
        /**
         * GET: Fetch User registered event list
         */
        builder.addCase(exports.userRegisteredEvents.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.status = common_1.ApiStatusValues.loading;
            state.userRegisteredEventsList.userRegisteredEventsListLoading = true;
        });
        builder.addCase(exports.userRegisteredEvents.fulfilled, (state, action) => {
            var _a;
            // Add logic if any when API state is fulfilled or data is received from API
            state.status = common_1.ApiStatusValues.success;
            // Add any fetched posts to the array
            state.userRegisteredEventsList.userRegisteredEventsData = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data;
            state.userRegisteredEventsList.userRegisteredEventsListLoading = false;
        });
        builder.addCase(exports.userRegisteredEvents.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.status = common_1.ApiStatusValues.error;
            state.userRegisteredEventsList.userRegisteredEventsListLoading = false;
        });
    },
});
exports.getRegisterAndJoinLink = (0, toolkit_1.createAsyncThunk)(`${constants_1.LEARNING_CENTER}/${constants_1.REGISTER_JOIN_LEARNING_CENTER_WEBINAR}`, (paramter) => __awaiter(void 0, void 0, void 0, function* () {
    const registerAndJoin = yield (0, api_1.post)(`${constants_1.REGISTER_AND_JOIN_WEBINAR_ENDPOINT}/${paramter.contentId}?event_id=${paramter.eventId}&action=${paramter.action}`, paramter.body, {
        baseURL: api_1.BASE_URL_NODE,
    });
    return registerAndJoin;
}));
exports.getWebinarDetails = (0, toolkit_1.createAsyncThunk)(`${constants_1.LEARNING_CENTER}/${constants_1.WEBINAR_CONTENT_DETAIL}`, (paramter) => __awaiter(void 0, void 0, void 0, function* () {
    const webinarDetails = yield (0, api_1.get)(`${constants_1.WEBINAR_CONTENT_DETAILS_ENDPOINT}?id=${paramter.id}&event_id=${paramter.eventId}&email=${paramter.emailId}`, {
        baseURL: api_1.BASE_URL_NODE,
    });
    return webinarDetails;
}));
exports.fetchSpeakerList = (0, toolkit_1.createAsyncThunk)(`${constants_1.LEARNING_CENTER}/${constants_1.FETCH_SPEAKER_LIST}`, (parameter) => __awaiter(void 0, void 0, void 0, function* () {
    const speakerList = yield (0, api_1.get)(`${constants_1.LIST_SPEAKER}?event_id=${(parameter === null || parameter === void 0 ? void 0 : parameter.eventId) ? parameter.eventId : ""}`, {
        baseURL: api_1.BASE_URL_NODE,
    });
    return speakerList;
}));
exports.userRegisteredEvents = (0, toolkit_1.createAsyncThunk)(`${constants_1.LEARNING_CENTER}/${constants_1.USER_REGISTERED_LIST}`, (parameter) => __awaiter(void 0, void 0, void 0, function* () {
    const params = (0, utils_1.filterOptionsParams)(parameter.filterOptions);
    const userRegisteredEventData = yield (0, api_1.get)(`${constants_1.USER_REGISTERED_EVENTS_LIST_ENDPOINT}${params}&email=${parameter === null || parameter === void 0 ? void 0 : parameter.emailId}`, {
        baseURL: api_1.BASE_URL_NODE,
    });
    return userRegisteredEventData;
}));
exports.getRegisterAndJoinSelector = (0, toolkit_1.createSelector)((state) => state.learningCenter, (learningCenter) => { var _a; return (_a = learningCenter === null || learningCenter === void 0 ? void 0 : learningCenter.registerAndJoin) === null || _a === void 0 ? void 0 : _a.registerAndJoinData; });
exports.getWebinarDetailsSelector = (0, toolkit_1.createSelector)((state) => state.learningCenter, (learningCenter) => {
    var _a, _b, _c, _d;
    if (((_b = (_a = learningCenter === null || learningCenter === void 0 ? void 0 : learningCenter.webinarContentDetails) === null || _a === void 0 ? void 0 : _a.webinarContentDetailsData) === null || _b === void 0 ? void 0 : _b.length) >
        0) {
        return (_d = (_c = learningCenter === null || learningCenter === void 0 ? void 0 : learningCenter.webinarContentDetails) === null || _c === void 0 ? void 0 : _c.webinarContentDetailsData) === null || _d === void 0 ? void 0 : _d[0];
    }
    return null;
});
exports.webinarDetailsSelector = (0, toolkit_1.createSelector)((state) => state.learningCenter, (learningCenter) => {
    var _a, _b, _c;
    if (((_b = (_a = learningCenter === null || learningCenter === void 0 ? void 0 : learningCenter.webinarContentDetails) === null || _a === void 0 ? void 0 : _a.webinarContentDetailsData) === null || _b === void 0 ? void 0 : _b.length) >
        0) {
        return (_c = learningCenter === null || learningCenter === void 0 ? void 0 : learningCenter.webinarContentDetails) === null || _c === void 0 ? void 0 : _c.webinarContentDetailsData;
    }
    return null;
});
exports.getSpeakerListSelector = (0, toolkit_1.createSelector)((state) => state.learningCenter, (learningCenter) => {
    var _a;
    return (_a = learningCenter === null || learningCenter === void 0 ? void 0 : learningCenter.speakerListDetails) === null || _a === void 0 ? void 0 : _a.speakerListData;
});
exports.getUserRegisteredEventList = (0, toolkit_1.createSelector)((state) => state.learningCenter, (learningCenter) => {
    var _a;
    return (_a = learningCenter === null || learningCenter === void 0 ? void 0 : learningCenter.userRegisteredEventsList) === null || _a === void 0 ? void 0 : _a.userRegisteredEventsData;
});
exports.getInitialRegisteredEventList = (0, toolkit_1.createSelector)((state) => state.learningCenter, (learningCenter) => {
    var _a;
    return (_a = learningCenter === null || learningCenter === void 0 ? void 0 : learningCenter.userRegisteredEventsList) === null || _a === void 0 ? void 0 : _a.initialEventsDataList;
});
exports.updateInitialEventDataList = learningCenterSlice.actions.updateInitialEventDataList;
exports.default = learningCenterSlice.reducer;
