import {
  AppConstant,
  ImageConstant,
  SLIDE_TO_SHOW_WEB,
  translate,
} from "@shalina-app/shared";

import { ExploreCommunityCard, ExploreCommunitySectionPropsType } from ".";
import { CardSlider } from "../../../../components";
import styles from "./exploreCommunitySection.module.scss";
import { getImageSrc } from "../../../../utils";
import { homeListingRoutes } from "../../../../app.constants";

export const ExploreCommunitySection: React.FC<
  ExploreCommunitySectionPropsType
> = ({ communityData, handleViewAllClick, isLoggedIn, loading }) => {
  const t = translate();
  const slideToShow = SLIDE_TO_SHOW_WEB.EXPLORE_COMMUNITIES;
  const length: number = communityData?.length;
  const showControl = length > slideToShow ? true : false;

  return (
    <div className={styles.exploreSec}>
      <CardSlider
        slidesToShow={slideToShow}
        sliderHeading={AppConstant.HOME_PAGE.EXPLORE_COMMUNITIES}
        isViewAllExist={showControl}
        showControl={showControl}
        isDataExist={communityData && communityData?.length > 0 ? true : false}
        handleViewAllButtonClick={() =>
          handleViewAllClick(homeListingRoutes.EXPLORE_COMMUNITIES)
        }
        dataLength={length}
        loading={loading}
      >
        {communityData &&
          communityData?.length > 0 &&
          communityData?.map((item: any, index: number) => (
            <ExploreCommunityCard
              id={item?.id}
              name={item?.title}
              interests={item?.field_interests ? item?.field_interests : ""}
              memberCount={item?.member_count}
              icon={
                item?.field_community_icon
                  ? getImageSrc(item?.field_community_icon)
                  : ImageConstant.SOURCE.communityIcon
              }
              key={index}
              members={item?.member_image}
              isLoggedIn={isLoggedIn}
              guidelines={item?.field_community_guidelines}
              iscommunityjoined={item?.iscommunityjoined}
            />
          ))}
      </CardSlider>
    </div>
  );
};
