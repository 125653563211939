"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttachedIcon = void 0;
const React = __importStar(require("react"));
const AttachedIcon = (props) => (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: 22, height: 13, fill: "none" }, props),
    React.createElement("path", { fill: "#AFAFAF", stroke: "#fff", strokeWidth: 0.25, d: "M.125 6.458A5.83 5.83 0 0 1 5.958.625h11.375a4.207 4.207 0 0 1 4.209 4.208 4.207 4.207 0 0 1-4.209 4.209H8.125a2.584 2.584 0 0 1 0-5.167h7.042a.958.958 0 0 1 0 1.917h-7.14c-.192 0-.34.09-.435.22a.761.761 0 0 0-.136.446c0 .157.042.32.136.447.095.13.243.22.436.22h9.305a2.298 2.298 0 0 0 2.292-2.292 2.298 2.298 0 0 0-2.292-2.291H5.958a3.92 3.92 0 0 0-3.916 3.916 3.92 3.92 0 0 0 3.916 3.917h9.209a.958.958 0 0 1 0 1.917H5.958A5.83 5.83 0 0 1 .125 6.458Z" })));
exports.AttachedIcon = AttachedIcon;
