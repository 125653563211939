"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getResourceFileType = exports.searchKeywordsExtraction = exports.isActionItemDisabled = void 0;
const constants_utils_1 = require("./constants.utils");
const isActionItemDisabled = (fieldActions, sectionName) => {
    const actionItemIds = fieldActions ? Object.keys(fieldActions) : [];
    if (actionItemIds === null || actionItemIds === void 0 ? void 0 : actionItemIds.length) {
        return !(actionItemIds === null || actionItemIds === void 0 ? void 0 : actionItemIds.some((val) => val === sectionName));
    }
    else {
        return true;
    }
};
exports.isActionItemDisabled = isActionItemDisabled;
const searchKeywordsExtraction = (keyword) => {
    const chucks = keyword.split(" ");
    chucks.unshift(keyword);
    return chucks;
};
exports.searchKeywordsExtraction = searchKeywordsExtraction;
const getResourceFileType = (fileName) => {
    var _a, _b;
    const extension = (_b = (_a = fileName === null || fileName === void 0 ? void 0 : fileName.toLowerCase()) === null || _a === void 0 ? void 0 : _a.split(".")) === null || _b === void 0 ? void 0 : _b.pop();
    let result = null;
    if (extension === "png" || extension === "jpeg" || extension === "jpg") {
        result = constants_utils_1.RESOURCES_TYPE.IMAGE;
    }
    else if (extension === "mp4") {
        result = constants_utils_1.RESOURCES_TYPE.VIDEO;
    }
    else if (extension === "pdf" || extension === "ppt") {
        result = constants_utils_1.RESOURCES_TYPE.DOCUMENT;
    }
    return result;
};
exports.getResourceFileType = getResourceFileType;
