import { HyperLinkProps } from '.';
import styles from './hyperLink.module.scss';

export const HyperLink: React.FC<HyperLinkProps> = (props) => {
  const { handleClick, hyperText, disabled } = props;
  return (
    <>
      <a
        className={disabled ? styles.shalinaLinkDisable : styles.shalinaLink}
        onClick={handleClick}
      >
        {hyperText}
      </a>
    </>
  );
};
