import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';

import { CustomNavTabProps, NavTabPanelProps } from '.';
import styles from './navTabs.module.scss';

export const NavTabs: React.FC<CustomNavTabProps> = ({
  tabs,
  tabValue,
  handleTabChange
}) => {
  const CustomTabPanel = (props: NavTabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  const tabProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  };

  return (
    <>
      <div className={styles.custTabsWrapper}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          className={`${styles.custTabs} globalcustTabs`}
        >
          {tabs?.map((tab: any, index: number) => (
            <Tab key={tab?.id} label={tab?.label} {...tabProps(index)} />
          ))}
        </Tabs>
        <div className={styles.custTabContent}>
          {tabs?.map((tab: any, tabIndex: number) => (
            <CustomTabPanel key={tab?.id} value={tabValue} index={tabIndex}>
              {tab?.component}
            </CustomTabPanel>
          ))}
        </div>
      </div>
    </>
  );
};
