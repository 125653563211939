import React, { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
  AppConstant,
  CommunitiesTabEnumType,
  COMMUNITIES_API_CATEGORY_TYPES,
  COMMUNITIES_TABS,
  COMMUNITIES_TABS_ENUM,
  COMMUNITY_API_REQUEST_STATUS,
  fetchCommunityLandingContent,
  fetchCommunityRequests,
  fetchDiscussionTypes,
  fetchMyCommunityList,
  getCommunitiesTabs,
  getCommunityRequests,
  getExploreCommunitiesList,
  getExploreCommunitiesListForCommunity,
  getMyCommunitiesList,
  getMyCommunitiesListCount,
  getRecommendedCommunitiesList,
  getRecommendedCommunitiesListCount,
  ImageConstant,
  isKol,
  SHOW_MY_COMMUNITIES_COUNT,
  SHOW_RECOMMENDED_COUNT,
  translate,
  useAppDispatch,
  useAppSelector,
} from '@shalina-app/shared';

import { CommunitiesPropsItems, TabPanelProps } from './communities.interface';
import styles from './communities.module.scss';
import {
  CommunitiesDiscussion,
  CommunityDetail,
  CommunityListing,
  RequestCommunities,
} from './pages';
import { MyCommunities, RecommendedSection } from './components';
import { BarLoader, ButtonComponent, QuickLinkSection } from '../../components';
import { DEFAULT_COMMUNITY_TAKE } from '../../app.constants';
import {
  retrieveData,
  STORAGE_CONSTANTS,
  useNavigatePageUtils,
} from '../../utils';
import { SignUpSection } from '../Home';
import { useLocation, useParams } from 'react-router-dom';
import { SignUpPopup } from '../Authentication';
import { URLConstant } from '../../routes';

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const tabProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export const CommunitiesLandingPage: React.FC<CommunitiesPropsItems> = () => {
  const t = translate();
  const dispatch = useAppDispatch();
  const navigatePageUtils = useNavigatePageUtils();
  const location = useLocation();
  const params = useParams();
  const { urls } = URLConstant;
  const {
    AnswerQuestionsIcon,
    DiscussionsIcon,
    ExploreCommunityIcon,
    CommunityRequestIcon,
  } = ImageConstant.SVG_ICONS;

  const isLoggedIn: boolean = retrieveData(STORAGE_CONSTANTS.IS_LOGGED_IN);

  const communityIdParam = params?.communityId;

  const openDetail =
    location?.state?.isDetailPage || location?.state?.isNotification || false;
  const communityIdState =
    location?.state?.communityId || location?.state?.id || '';

  const selectedTab = location?.state?.selectedTab || 0;

  const [apiCall, setApiCall] = useState(false);
  const [tabValue, setTabValue] = useState<any>(null);
  const [isListing, setIsListing] = useState(false);
  const [isDetail, setIsDetail] = useState(openDetail);
  const [viewAllCategory, setViewAllCategory] = useState('');
  const [hideRecommendedSection, setHideRecommendedSection] = useState(false);
  const [hideMyCommunitySection, setHideMyCommunitySection] = useState(false);
  const [communityId, setCommunityId] = useState(communityIdState);
  const [refresh, setRefresh] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);

  const filterOptions = {
    skip: 0,
    take: DEFAULT_COMMUNITY_TAKE,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!openDetail) {
      setApiCall(true);
      setTabValue(1);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (communityIdParam) {
      setApiCall(true);
      setTabValue(null);
      setIsDetail(true);
    }
  }, [communityIdParam]);

  useEffect(() => {
    if (selectedTab && !openDetail) {
      setTabValue(selectedTab);
    }
  }, [selectedTab]);

  const tabChange = (tabId: number) => {
    if (tabId) {
      if (tabId === tabValue) {
        setRefresh(true);
      } else {
        setRefresh(false);
      }
      setTabValue(tabId);
      setIsListing(false);
      setIsDetail(false);
      const navigateDetails = {
        isDynamicUrl: false
      };
      navigatePageUtils(urls.commiunitiesPage, navigateDetails);
      setHideRecommendedSection(false);
      setHideMyCommunitySection(false);
    }
  };

  useEffect(() => {
    if (apiCall) {
      loadInitialApi();
    }
  }, [apiCall]);

  const loadInitialApi = () => {
    if (isLoggedIn) {
      loadApi();
    } else {
      if(!isDetail) {
        setTabValue(COMMUNITIES_TABS.EXPLORE_COMMUNITIES);
      }
      setApiCall(false);
    }
  };

  const loadApi = () => {
    // dispatch(fetchDiscussionTypes());
    dispatch(
      fetchCommunityLandingContent({
        ...filterOptions,
        type: 'open',
        status: COMMUNITY_API_REQUEST_STATUS.SENT,
      })
    );
    // dispatch(
    //   fetchMyCommunityList({
    //     ...filterOptions,
    //     category: COMMUNITIES_API_CATEGORY_TYPES.MY_COMMUNITY,
    //   })
    // );
    // dispatch(
    //   fetchMyCommunityList({
    //     ...filterOptions,
    //     category: COMMUNITIES_API_CATEGORY_TYPES.RECOMMENDED_COMMUNITY,
    //     type: "open",
    //   })
    // );
    // dispatch(
    //   fetchMyCommunityList({
    //     ...filterOptions,
    //     category: COMMUNITIES_API_CATEGORY_TYPES.EXPLORE_COMMUNITY,
    //     type: "open",
    //   })
    // );
    // dispatch(
    //   fetchCommunityRequests({
    //     ...filterOptions,
    //     status: COMMUNITY_API_REQUEST_STATUS.SENT,
    //   })
    // );
    setApiCall(false);
  };

  const myCommunitiesList = useAppSelector(getMyCommunitiesList);
  const recommendedCommunitiesList = useAppSelector(
    getRecommendedCommunitiesList
  );
  const exploreCommunitiesList = useAppSelector(getExploreCommunitiesListForCommunity);
  const requestList = useAppSelector(getCommunityRequests);

  const myCommunitiesListCount = useAppSelector(getMyCommunitiesListCount);
  const recommendedCommunitiesListCount = useAppSelector(
    getRecommendedCommunitiesListCount
  );

  const isKolUser = useAppSelector(isKol);
  const isCommunityDetailLoading = useAppSelector(
    (state: any) => state?.community?.communityDetail?.communityDetailLoading
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    if (!isLoggedIn && newValue !== COMMUNITIES_TABS.EXPLORE_COMMUNITIES) {
      setShowSignUp(true);
    } else {
      setIsListing(false);
      setIsDetail(false);
      const navigateDetails = {
        isDynamicUrl: false
      };
      navigatePageUtils(urls.commiunitiesPage, navigateDetails);
      setTabValue(newValue);
      setHideRecommendedSection(false);
      setHideMyCommunitySection(false);
      handleMainApiCall();
      if (newValue === COMMUNITIES_TABS.EXPLORE_COMMUNITIES) {
        setViewAllCategory(COMMUNITIES_API_CATEGORY_TYPES.EXPLORE_COMMUNITY);
      }
    }
  };

  const handleSignUpClose = () => {
    setShowSignUp(false);
  };

  const handleMainApiCall = () => {
    dispatch(
      fetchCommunityLandingContent({
        ...filterOptions,
        type: 'open',
        status: COMMUNITY_API_REQUEST_STATUS.SENT,
      })
    );
    // dispatch(
    //   fetchMyCommunityList({
    //     ...filterOptions,
    //     category: COMMUNITIES_API_CATEGORY_TYPES.MY_COMMUNITY,
    //   })
    // );
    // dispatch(
    //   fetchMyCommunityList({
    //     ...filterOptions,
    //     category: COMMUNITIES_API_CATEGORY_TYPES.RECOMMENDED_COMMUNITY,
    //     type: "open",
    //   })
    // );
  };

  const handleCommunityClick = (communityId: string) => {
    // if (isLoggedIn) {
    setCommunityId(communityId);
    setHideRecommendedSection(false);
    setHideMyCommunitySection(false);
    setIsListing(false);
    // setIsDetail(true);
    const navigateDetails = {
      isDynamicUrl: true,
      dynamicUrlOptions: {
        communityId: communityId,
      },
    };
    navigatePageUtils(urls.commiunitiesDetailPage, navigateDetails);
    setTabValue(null);
    // }
  };

  const handleCommunityViewAllClick = async (category: string) => {
    setViewAllCategory(category);
    if (category === COMMUNITIES_API_CATEGORY_TYPES.RECOMMENDED_COMMUNITY) {
      setHideRecommendedSection(true);
      setHideMyCommunitySection(false);
    } else if (category === COMMUNITIES_API_CATEGORY_TYPES.MY_COMMUNITY) {
      setHideMyCommunitySection(true);
      setHideRecommendedSection(false);
    } else {
      setHideRecommendedSection(false);
      setHideMyCommunitySection(false);
    }
    setIsListing(true);
    setIsDetail(false);
    const navigateDetails = {
      isDynamicUrl: false
    };
    navigatePageUtils(urls.commiunitiesPage, navigateDetails);
    setTabValue(null);
  };

  const handleJoinCommunityClick = () => {
    setTabValue(COMMUNITIES_TABS.EXPLORE_COMMUNITIES);
  };

  const defaultTabs = [
    {
      id: COMMUNITIES_TABS.DISCUSSIONS,
      enumId: COMMUNITIES_TABS_ENUM.DISCUSSIONS,
      label: t(AppConstant.COMMUNITY.DISCUSSIONS_TAB),
      icon: (
        <span className={styles.tabicon}>
          <DiscussionsIcon />
        </span>
      ),
      component: (
        <CommunitiesDiscussion
          heading={t(AppConstant.COMMUNITY.DISCUSSIONS_TAB)}
          showCommunityInfo={true}
          isCommunityDetail={false}
          isQuestionAnswersTab={false}
          handleJoinCommunityClick={handleJoinCommunityClick}
          isExpert={false}
          handleCommunityClick={handleCommunityClick}
        />
      ),
    },
    {
      id: COMMUNITIES_TABS.EXPLORE_COMMUNITIES,
      enumId: COMMUNITIES_TABS_ENUM.EXPLORE_COMMUNITIES,
      label: t(AppConstant.COMMUNITY.EXPLORE_COMMUNITIES_TAB),
      icon: (
        <span className={styles.tabicon}>
          <ExploreCommunityIcon />
        </span>
      ),
      component: (
        <CommunityListing
          viewAllCategory={COMMUNITIES_API_CATEGORY_TYPES.EXPLORE_COMMUNITY}
          communitiesList={exploreCommunitiesList}
          handleCommunityClick={handleCommunityClick}
          tabChange={tabChange}
          handleMainApiCall={handleMainApiCall}
        />
      ),
    },
    {
      id: COMMUNITIES_TABS.REQUESTS,
      enumId: COMMUNITIES_TABS_ENUM.REQUESTS,
      label: t(AppConstant.COMMUNITY.COMMUNITY_REQUEST_TAB),
      icon: (
        <span className={styles.tabicon}>
          <CommunityRequestIcon />
        </span>
      ),
      component: (
        <RequestCommunities
          requestList={requestList}
          handleCommunityClick={handleCommunityClick}
          handleMainApiCall={handleMainApiCall}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      ),
    },
    {
      id: COMMUNITIES_TABS.ANSWER_QUESTIONS,
      enumId: COMMUNITIES_TABS_ENUM.ANSWER_QUESTIONS,
      label: t(AppConstant.COMMUNITY.COMMUNITY_ANSWER_QUESTIONS_TAB),
      icon: (
        <span className={styles.tabicon}>
          <AnswerQuestionsIcon />
        </span>
      ),
      component: (
        <CommunitiesDiscussion
          heading={t(AppConstant.COMMUNITY.COMMUNITY_ANSWER_QUESTIONS_TAB)}
          isQuestionAnswersTab={true}
          showCommunityInfo={true}
          isCommunityDetail={false}
          handleJoinCommunityClick={handleJoinCommunityClick}
          isExpert={true}
          handleCommunityClick={handleCommunityClick}
        />
      ),
    },
  ];

  const tabsEnum: any = getCommunitiesTabs(isKolUser);

  const filterTabs = tabsEnum?.length
    ? defaultTabs?.filter((item) =>
        tabsEnum?.includes(item?.enumId as CommunitiesTabEnumType)
      )
    : defaultTabs;

  const tabs = filterTabs.sort((a: any, b: any) => {
    return tabsEnum?.indexOf(a?.enumId) - tabsEnum?.indexOf(b?.enumId);
  });

  const communityListing =
    viewAllCategory === COMMUNITIES_API_CATEGORY_TYPES.MY_COMMUNITY
      ? myCommunitiesList
      : viewAllCategory === COMMUNITIES_API_CATEGORY_TYPES.RECOMMENDED_COMMUNITY
      ? recommendedCommunitiesList
      : [];

  const myCommunityViewAllVisible =
    myCommunitiesListCount > SHOW_MY_COMMUNITIES_COUNT ? true : false;
  const recommendedViewAllVisible =
    recommendedCommunitiesListCount > SHOW_RECOMMENDED_COUNT ? true : false;
  return (
    <>
      <div className={styles.communityPage}>
        <div className={styles.listingTop}>
          <div className='cust-container'>
            <h1 className={styles.pageTitle}>
              {t(AppConstant.HEADER.COMMUNITIES_MENU)}
            </h1>
          </div>
        </div>
        {isCommunityDetailLoading && <BarLoader />}
        <div className='cust-container'>
          <div className={styles.listingRow}>
            <div className={styles.listingCol3}>
              <div className={styles.tabCol}>
                <div className={styles.tabWrapper}>
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label='Detail Tabs'
                    className={styles.custTabs}
                  >
                    {tabs?.map((tab) => (
                      <Tab
                        {...tabProps(tab?.id)}
                        label={tab?.label}
                        // onClick={() => setSideTab(1)}
                        icon={tab?.icon}
                      />
                    ))}
                  </Tabs>
                </div>
              </div>
              {!hideMyCommunitySection && (
                <div className={styles.myCommunities}>
                  <MyCommunities
                    communitiesList={myCommunitiesList}
                    handleCommunityClick={handleCommunityClick}
                    handleCommunityViewAllClick={handleCommunityViewAllClick}
                    viewAllVisible={myCommunityViewAllVisible}
                    handleJoinCommunityClick={handleJoinCommunityClick}
                  />
                </div>
              )}
            </div>
            <div className={styles.listingCol6}>
              <div className={styles.communityBody}>
                {!isListing && !isDetail ? (
                  tabs?.map((tab) => (
                    <CustomTabPanel value={tabValue} index={tab?.id}>
                      {tab?.component}
                    </CustomTabPanel>
                  ))
                ) : isListing ? (
                  <CommunityListing
                    viewAllCategory={viewAllCategory}
                    communitiesList={communityListing}
                    handleCommunityClick={handleCommunityClick}
                    tabChange={tabChange}
                    handleMainApiCall={handleMainApiCall}
                  />
                ) : isDetail ? (
                  <CommunityDetail
                    communityId={communityIdParam || communityId}
                    handleCommunityClick={handleCommunityClick}
                    handleMainApiCall={handleMainApiCall}
                  />
                ) : (
                  <></>
                )}
              </div>

              {!hideMyCommunitySection && (
                <div className={`${styles.myCommunities} ${styles.mobile}`}>
                  <MyCommunities
                    communitiesList={myCommunitiesList}
                    handleCommunityClick={handleCommunityClick}
                    handleCommunityViewAllClick={handleCommunityViewAllClick}
                    viewAllVisible={myCommunityViewAllVisible}
                    handleJoinCommunityClick={handleJoinCommunityClick}
                  />
                </div>
              )}
            </div>

            <div className={styles.listingCol3}>
              {isLoggedIn ? (
                !hideRecommendedSection &&
                recommendedCommunitiesList?.length > 0 && (
                  <RecommendedSection
                    viewAllCategory={
                      COMMUNITIES_API_CATEGORY_TYPES.RECOMMENDED_COMMUNITY
                    }
                    communitiesList={recommendedCommunitiesList}
                    handleCommunityClick={handleCommunityClick}
                    handleCommunityViewAllClick={handleCommunityViewAllClick}
                    viewAllVisible={recommendedViewAllVisible}
                    tabChange={tabChange}
                    handleMainApiCall={handleMainApiCall}
                  />
                )
              ) : (
                <SignUpSection
                  mainText={t(
                    AppConstant.AUTHENTICATION_PAGE.HOME_SIGN_UP_HEADER_TEXT
                  )}
                  subText={t(AppConstant.AUTHENTICATION_PAGE.HOME_SIGN_UP_TEXT)}
                />
              )}
              <div className={styles.calcQuicklink}>
                <QuickLinkSection />
              </div>
            </div>
          </div>
        </div>
      </div>
      <SignUpPopup open={showSignUp} handleClose={handleSignUpClose} />
    </>
  );
};
