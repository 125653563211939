"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SurveyIcon = void 0;
const React = __importStar(require("react"));
const SurveyIcon = (props) => (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: 19, height: 22, fill: "none" }, props),
    React.createElement("path", { fill: "#E84E1B", stroke: "#E84E1B", strokeWidth: 0.5, d: "M14.199 10.855h-2.894a.775.775 0 0 0-.774.77c0 .43.343.77.774.77h2.894c.418 0 .774-.34.774-.77 0-.429-.343-.77-.774-.77ZM14.199 6.385h-2.894a.775.775 0 0 0-.774.77c0 .43.343.77.774.77h2.894c.418 0 .774-.34.774-.77s-.343-.77-.774-.77Z" }),
    React.createElement("path", { fill: "#E84E1B", stroke: "#E84E1B", strokeWidth: 0.3, d: "M5.488 8.291c.14.14.33.215.545.215h.038a.699.699 0 0 0 .558-.265L8.67 5.804a.795.795 0 0 0 .114-.833.786.786 0 0 0-.697-.442H7.986a.784.784 0 0 0-.508.278L5.982 6.6l-.621-.619a.796.796 0 0 0-.558-.252.776.776 0 0 0-.57.214.76.76 0 0 0-.216.568.75.75 0 0 0 .24.543l1.218 1.212.013.025Z" }),
    React.createElement("path", { fill: "#E84E1B", stroke: "#E84E1B", strokeWidth: 0.2, d: "M16.204 1H2.796C1.805 1 1 1.744 1 2.661V19.34C1 20.256 1.805 21 2.796 21h13.408c.991 0 1.796-.744 1.796-1.661V2.66C18 1.744 17.195 1 16.204 1Zm.057 18.339s0 .053-.057.053H2.796s-.057 0-.057-.053V2.66s0-.053.057-.053h13.408s.057 0 .057.053V19.34Z" }),
    React.createElement("path", { fill: "#E84E1B", stroke: "#E84E1B", strokeWidth: 0.5, d: "M14.199 15.324h-2.894a.775.775 0 0 0-.774.77c0 .43.343.77.774.77h2.894c.418 0 .774-.34.774-.77 0-.429-.343-.77-.774-.77Z" }),
    React.createElement("path", { fill: "#E84E1B", stroke: "#E84E1B", strokeWidth: 0.3, d: "M5.488 12.76c.14.139.33.215.545.215h.038a.699.699 0 0 0 .558-.265l2.041-2.437a.795.795 0 0 0 .114-.833.786.786 0 0 0-.697-.442h-.101a.784.784 0 0 0-.508.278l-1.496 1.793-.621-.62a.796.796 0 0 0-.558-.252c-.24 0-.431.064-.57.215a.76.76 0 0 0-.216.568.803.803 0 0 0 .24.543l1.218 1.212.013.025ZM8.784 13.922a.786.786 0 0 0-.697-.442h-.101a.784.784 0 0 0-.508.278l-1.496 1.793-.621-.619a.796.796 0 0 0-.558-.252c-.24 0-.431.063-.57.214a.76.76 0 0 0-.216.569.75.75 0 0 0 .24.542l1.218 1.212c.14.14.33.215.545.215h.038a.699.699 0 0 0 .558-.265l2.042-2.437a.795.795 0 0 0 .114-.833l.012.025Z" })));
exports.SurveyIcon = SurveyIcon;
