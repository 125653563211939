import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import { AppConstant, ImageConstant, translate } from '@shalina-app/shared';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import { BannerSliderProps } from '.';
import styles from './slider.module.scss';

export const BannerSlider: React.FC<BannerSliderProps> = ({
  children,
  slidesToShow,
  sliderHeading,
  isDataExist,
  isFeaturedLoading,
  dataLength,
  isViewAllExist,
  handleViewAllButtonClick
}) => {
  const t = translate();
  const sliderRef: any = useRef(null);

  const [slideIndex, setSlideIndex] = useState<any>(0);

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
    beforeChange: (current: any, next: any) => setSlideIndex(next)
  };
  const nextArrowClick = () => {
    sliderRef?.current?.slickNext();
  };
  const previousArrowClick = () => {
    sliderRef?.current?.slickPrev();
  };

  const isLastSlide = dataLength - slidesToShow === slideIndex;
  const isFirstSlide = slideIndex === 0;
  const { SliderArrowIcon } = ImageConstant.SVG_ICONS;
  return (
    <div
      className={`${styles.sliderContainer} ${styles.bannerContainer} ${styles.sliderDark}`}
    >
      <div className={styles.headCta}>
        <h2 className={styles.title}>{sliderHeading}</h2>
        {!isFeaturedLoading && isDataExist && (
          <div className={styles.ctaWrapper}>
            {isViewAllExist && (
            <button
              className={styles.viewAll}
              onClick={handleViewAllButtonClick}
            >
              {t(AppConstant.COMMON.VIEW_ALL_CTA)}
            </button>
            )}
            <div className={styles.sliderArrow}>
              <button
                className={styles.prev}
                onClick={previousArrowClick}
                disabled={isFirstSlide ? true : false}
              >
                <SliderArrowIcon />
              </button>
              <button
                className={styles.next}
                onClick={nextArrowClick}
                disabled={isLastSlide ? true : false}
              >
                <SliderArrowIcon />
              </button>
            </div>
          </div>
        )}
      </div>
      {isFeaturedLoading ? (
        <div className={styles.emptyLoader}>
          <Box sx={{ display: 'flex' }} className={styles.loaderWrapper}>
            <CircularProgress
              size={50}
              thickness={4}
              sx={{
                color: 'var(--secondaryOrange)'
              }}
            />
          </Box>
        </div>
      ) : isDataExist ? (
        <div className={styles.sliderWrapper}>
          <Slider ref={sliderRef} {...sliderSettings}>
            {children}
          </Slider>
        </div>
      ) : (
        <div className={`${styles.contentNotFound} ${styles.dark}`}>
          <div className={styles.notFoundImg}>
            <img
              src={ImageConstant.SOURCE.contentNotFoundImg}
              alt='not found'
            />
          </div>
          <div className={styles.notFoundText}>
            <h3>{`${t(AppConstant.HOME_PAGE.NO_TEXT)} ${t(
              AppConstant.HOME_PAGE.DATA_TEXT
            )} ${t(AppConstant.HOME_PAGE.FOUND_TEXT)}`}</h3>
            <p>{t(AppConstant.HOME_PAGE.NO_DATA_FOUND_MESSAGE)}</p>
          </div>
        </div>
      )}
    </div>
  );
};
