import { CircularProgress, Dialog, DialogContent } from '@mui/material';
import {
  AppConstant,
  ImageConstant,
  fetchCommunityResources,
  formatBytes,
  getCommunityResources,
  getCommunityResourcesTotalCount,
  getOnlyDate,
  translate,
  updateResourcesList,
  useAppDispatch,
  useAppSelector,
  getFileNameFromUrl,
  getBlobFilePath
} from '@shalina-app/shared';
import { useEffect, useRef, useState } from 'react';

import { CommunityResourcesProps } from '..';
import {
  DEFAULT_COMMUNITY_SKIP,
  DEFAULT_COMMUNITY_TAKE
} from '../../../../../app.constants';
import {
  BarLoader,
  InfiniteScrollComponent,
  ShalinaPDFViewer,
  Toast
} from '../../../../../components';
import styles from '../CommunityDetail.module.scss';

export const CommunityResources: React.FC<CommunityResourcesProps> = ({
  communityId
}) => {
  const t = translate();
  const dispatch = useAppDispatch();
  const prevProps: any = useRef();

  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(DEFAULT_COMMUNITY_TAKE);
  const [hasMore, setHasMore] = useState(true);
  const [reload, setReload] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [fileType, setFileType] = useState('');
  const [file, setFile] = useState('');
  const [fileName, setFileName] = useState('');
  const [initialLoader, setInitialLoader] = useState(true);
  const [resourceLoader, setResourceLoader] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const handleToastClose = () => {
    setShowToast(false);
  };

  const fileTypeEnum = {
    PDF: 'pdf',
    IMAGE: 'image',
    PPT: 'ppt'
  };

  useEffect(() => {
    if (reload) {
      loadApi();
      setReload(false);
    }
  }, [reload]);

  useEffect(() => {
    setReload(true);
    setInitialLoader(true);
  }, []);

  const communityResources = useAppSelector(getCommunityResources);
  const communityResourcesTotalCount = useAppSelector(
    getCommunityResourcesTotalCount
  );

  useEffect(() => {
    // if (prevProps?.current && prevProps?.current?.communityId !== communityId) {
    //   dispatch(updateResourcesList([]));
    // }
    dispatch(updateResourcesList([]));
    prevProps.current = { communityId };
  }, [communityId]);

  // useEffect(() => {
  //   if (communityResources?.length === 0) {
  //     setReload(true);
  //   } else {
  //     if (communityResources?.length < communityResourcesTotalCount) {
  //       setSkip(communityResources?.length);
  //     }
  //   }
  // }, [communityResources]);

  const loadApi = async () => {
    const payload = {
      communityId: communityId,
      skip: skip,
      take: take
    };
    const response: any = await dispatch(
      fetchCommunityResources(payload)
    ).unwrap();
    if (
      response &&
      response?.status === 'SUCCESS' &&
      response?.statusCode === 200 &&
      response?.data
    ) {
      dispatch(updateResourcesList([...communityResources, ...response?.data]));
    }
    setInitialLoader(false);
    setSkip((prevSkip) => prevSkip + DEFAULT_COMMUNITY_SKIP);
  };

  const fetchMoreData = () => {
    if (communityResources?.length >= communityResourcesTotalCount) {
      setHasMore(false);
      return;
    }
    setReload(true);
  };

  const handleResourcesClick = async (resource: any) => {
    setResourceLoader(true);
    setFile(getBlobFilePath(resource?.file_path));
    setFileName(resource?.file_name);
    try {
      setModalOpen(true);
      const response = await fetch(getBlobFilePath(resource?.file_path));
      const blob = await response.blob();
      if (response.ok && response.status === 200) {
        switch (blob?.type) {
          case 'application/pdf':
            setFileType(fileTypeEnum?.PDF);
            break;
          case 'image/png':
            setFileType(fileTypeEnum?.IMAGE);
            break;
          case 'image/jpg':
            setFileType(fileTypeEnum?.IMAGE);
            break;
          case 'image/jpeg':
            setFileType(fileTypeEnum?.IMAGE);
            break;
          default:
            setFileType('');
        }
      } else {
        setModalOpen(false);
        setShowToast(true);
        setIsSuccess(false);
        setToastMessage(
          t(AppConstant.COMMUNITY.FAILED_TO_LOAD_COMMUNITY_RESOURCE_FILE)
        );
      }
      setResourceLoader(false);
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      setModalOpen(false);
      setShowToast(true);
      setIsSuccess(false);
      setToastMessage(
        t(AppConstant.COMMUNITY.FAILED_TO_LOAD_COMMUNITY_RESOURCE_FILE)
      );
      setResourceLoader(false);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      {initialLoader ? (
        <div className={styles.initialLoader}>
          <BarLoader />
        </div>
      ) : communityResources?.length > 0 ? (
        <div className={styles.resourcesWrapper}>
          <div className={`${styles.resourcItem} ${styles.head}`}>
            <div className={styles.name}>
              <svg
                width='12'
                height='14'
                viewBox='0 0 12 14'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M8.08365 0.263591L8.08367 0.263613L11.7358 3.89322C11.873 4.0297 11.95 4.21464 11.95 4.40741V12.7037C11.95 13.0341 11.8179 13.3511 11.5827 13.5849C11.3475 13.8186 11.0284 13.95 10.6957 13.95H1.30435C0.971575 13.95 0.652493 13.8186 0.41728 13.5849C0.182078 13.3511 0.05 13.0341 0.05 12.7037V1.2963C0.05 0.965858 0.182078 0.648891 0.41728 0.415141C0.652493 0.181381 0.971574 0.05 1.30435 0.05H7.56522C7.66154 0.0500045 7.75691 0.068878 7.84587 0.105534C7.93483 0.142189 8.01562 0.195904 8.08365 0.263591ZM8.1222 2.36268L8.03696 2.27796V2.39815V3.88889V3.93889H8.08696H9.58696H9.7082L9.6222 3.85342L8.1222 2.36268ZM1.51522 12.4444V12.4944H1.56522H10.4348H10.4848V12.4444V5.44444V5.39444H10.4348H7.30435C7.10995 5.39444 6.92357 5.31769 6.78621 5.18117C6.64885 5.04466 6.57174 4.85959 6.57174 4.66667V1.55556V1.50556H6.52174H1.56522H1.51522V1.55556V12.4444Z'
                  fill='black'
                  stroke='white'
                  stroke-width='0.1'
                />
              </svg>
              {t(AppConstant.COMMUNITY.COMMUNITY_RESOURCES_FILE_NAME)}
            </div>
            <div className={styles.size}>
              {t(AppConstant.COMMUNITY.COMMUNITY_RESOURCES_SIZE)}
            </div>
            <div className={styles.date}>{t(AppConstant.COMMON.DATE)}</div>
          </div>
          <InfiniteScrollComponent
            dataLength={communityResources.length}
            fetchMoreData={fetchMoreData}
            hasMore={hasMore}
            loader={true}
          >
            {communityResources?.map((resource: any) => {
              // Extract the file extension using a regular expression
              const extensionMatch = resource?.file_path?.match(/\.([^.]+)$/);
              const extension = extensionMatch
                ? extensionMatch?.[1]?.toLowerCase()
                : '';
              return (
                <div className={styles.resourcItem}>
                  <div
                    className={styles.name}
                    onClick={() => handleResourcesClick(resource)}
                  >
                    {extension === 'pdf' ? (
                      <img src={ImageConstant.SOURCE.PdfIcon} />
                    ) : (
                      <svg
                        width='13'
                        height='13'
                        viewBox='0 0 13 13'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M12.75 11.3333V1.41667C12.75 0.6375 12.1125 0 11.3333 0H1.41667C0.6375 0 0 0.6375 0 1.41667V11.3333C0 12.1125 0.6375 12.75 1.41667 12.75H11.3333C12.1125 12.75 12.75 12.1125 12.75 11.3333ZM3.89583 7.4375L5.66667 9.56958L8.14583 6.375L11.3333 10.625H1.41667L3.89583 7.4375Z'
                          fill='#E84E1B'
                        />
                      </svg>
                    )}

                    {resource?.file_name}
                  </div>
                  <div className={styles.size}>
                    {formatBytes(resource?.file_size)}
                  </div>
                  <div className={styles.date}>
                    {getOnlyDate(resource?.created_date)}
                  </div>
                </div>
              );
            })}
          </InfiniteScrollComponent>
        </div>
      ) : (
        <div className={styles.noData}>
          <img
            className={styles.noDataImg}
            src={ImageConstant.SOURCE.emptyContent}
            alt='img'
          />
          <p className={styles.noDataMsg}>
            {t(AppConstant.COMMUNITY.NO_RESOURCES_FOUND_FOR_COMMUNITY)}
          </p>
        </div>
      )}

      <Dialog
        open={modalOpen}
        onClose={handleModalClose}
        className={styles.resourceModal}
      >
        <DialogContent>
          <div className={styles.modalHeader}>
            <span className={styles.fileName}>
              {getFileNameFromUrl(fileName)}
            </span>
            <button onClick={handleModalClose} className={styles.closeCta}>
              &times;
            </button>
          </div>
          {resourceLoader ? (
            <span className={styles.resourceLoader}>
              <CircularProgress
                size={40}
                thickness={4}
                sx={{
                  color: 'var(--secondaryOrange)'
                }}
              />
            </span>
          ) : fileType === fileTypeEnum?.PDF ? (
            <div className={styles.pdfWrapper}>
              <ShalinaPDFViewer
                fileUrl={file}
                pageNumber={0}
                id={communityId}
              />
            </div>
          ) : fileType === fileTypeEnum?.IMAGE ? (
            <div className={styles.imgWrapper}>
              <img src={file} alt='Image' />
            </div>
          ) : (
            <></>
          )}
        </DialogContent>
      </Dialog>

      <Toast
        showToast={showToast}
        isSuccess={isSuccess}
        message={toastMessage}
        handleToastClose={handleToastClose}
      />
    </>
  );
};
