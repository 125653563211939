import {
  AppConstant,
  translate,
  ImageConstant,
  SOCIAL_MEDIA_LINKS
} from '@shalina-app/shared';

import styles from './footerComponent.module.scss';

export const AboutSection = () => {
  const t = translate();
  return (
    <div className={styles.aboutShalina}>
      <div className={styles.aboutShalinaText}>
        {t(AppConstant.FOOTER.FOOTER_ABOUT_SHALINA_TEXT)}
      </div>
      <div className={styles.footerSocial}>
        <p className={styles.footerTitle}>
          {t(AppConstant.FOOTER.FOOTER_FOLLOW_US_TEXT)}
        </p>
        <ul>
          <li key={1}>
            <a href={SOCIAL_MEDIA_LINKS.FACEBOOK} target='_blank'>
              <img src={ImageConstant.SOURCE.facebook} alt='facebook' />
            </a>
          </li>
          <li key={2}>
            <a href={SOCIAL_MEDIA_LINKS.TWITTER} target='_blank'>
              <img src={ImageConstant.SOURCE.twitter} alt='logo' />
            </a>
          </li>
          <li key={3}>
            <a href={SOCIAL_MEDIA_LINKS.LINKEDIN} target='_blank'>
              <img src={ImageConstant.SOURCE.linkedin} alt='logo' />
            </a>
          </li>
          <li key={4}>
            <a href={SOCIAL_MEDIA_LINKS.YOUTUBE} target='_blank'>
              <img src={ImageConstant.SOURCE.youtube} alt='logo' />
            </a>
          </li>
          <li key={5}>
            <a href={SOCIAL_MEDIA_LINKS.INSTAGRAM} target='_blank'>
              <img src={ImageConstant.SOURCE.instagram} alt='logo' />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
