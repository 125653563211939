import React, { useEffect, useRef, useState } from 'react';
import parse from 'html-react-parser';
import {
  getIconForContentType,
  ImageConstant,
  translate,
  useAppSelector,
  useAppDispatch,
  azureFile,
  AppConstant,
  getOnlyDate,
  getDefaultImage,
  getResourceFileType,
  RESOURCES_TYPE,
  savePageNoLeftWhileReading,
  getBlobFilePath,
  getDefaultValue,
  getFileNameFromUrl,
  DETAIL_TABS_ENUM,
  getTabsEnum,
  DetailTabEnumType,
  listGamificationPointDistribution,
  GAMIFICATION_ENABLED,
  GAMIFICATION_TYPE_UNIQUE_CODE,
  addOrUpdateUserGamificationPoints,
  updateUserRecognition,
  RECOGNITION_MESSAGE_TEXT,
  handleRecognitionPopup,
  GAMIFICATION_ACTION_ID,
  sendNotification,
  getNotificationActionList,
} from '@shalina-app/shared';
import {
  Box,
  Dialog,
  DialogContent,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import ReactPlayer from 'react-player';
import { Link, useLocation, useParams } from 'react-router-dom';

import styles from './resourceDetails.module.scss';
import {
  ButtonComponent,
  CircularLoader,
  ResourceDetailsProps,
  ShareSection,
  Speciality,
  Toast,
} from '../../../..';
import {
  STORAGE_CONSTANTS,
  convertToSpecialties,
  getImageSrc,
  removedFromStore,
  retrieveData,
  storeData,
} from '../../../../../utils';
import { URLConstant } from '../../../../../routes';
import { ImageShowDialogComponent } from '../../../../imageShowDialog';
import { SignUpPopup } from '../../../../../features';

export const ResourceDetails: React.FC<ResourceDetailsProps> = ({
  contentData,
  handleNextStep,
}) => {
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const t = translate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const params = useParams();
  const pathName = location?.pathname;
  const { urls } = URLConstant;
  const isLoggedIn: boolean = retrieveData(STORAGE_CONSTANTS.IS_LOGGED_IN);

  const gamificationPointDistributionData = useAppSelector(
    listGamificationPointDistribution
  );
  const notificationList = useAppSelector(getNotificationActionList);


  const contentId = params?.contentId || '';
  const contentDetails: any = contentData ? contentData : null;

  const [showToast, setShowToast] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastSubMessage, setToastSubMessage] = useState('');
  const [loader, setLoader] = useState(false);
  const [accessUrlLoader, setAccessUrlLoader] = useState(false);
  const [accessUrl, setAccessUrl] = useState('');
  const [toggleImageDialog, setToggleImageDialog] = useState(false);
  // const [toggleVideoDialog, setToggleVideoDialog] = useState(false);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [videoPlaybackTime, setVideoPlaybackTime] = useState(
    contentDetails?.last_read_at ? contentDetails?.last_read_at : 0
  );
  const [isReady, setIsReady] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [showSignUp, setShowSignUp] = useState(false);

  const playerRef = useRef<any>(null);

  const handleVideoModalClose = () => {
    setIsVideoModalOpen(false);
    checkRecognition();
    // onClose(videoPlaybackTime);
  };

  const checkRecognition = async () => {
    if (contentId) {
      const response = await dispatch(
        updateUserRecognition({
          view: 1,
          contentId: contentId,
        })
      ).unwrap();
      if (
        response &&
        response?.status === 'SUCCESS' &&
        response?.statusCode === 200 &&
        response?.message === RECOGNITION_MESSAGE_TEXT.AVID_READER &&
        response?.data
      ) {
        const obj = {
          showRecognitionPopup: true,
          recognitionData: response?.data,
        };
        dispatch(handleRecognitionPopup(obj));
      } else {
        const obj = {
          showRecognitionPopup: false,
          recognitionData: {},
        };
        dispatch(handleRecognitionPopup(obj));
      }
    }
  };

  useEffect(() => {
    setAccessUrlLoader(true);

    return () => {
      const totalTime = retrieveData(STORAGE_CONSTANTS.TOTAL_VIDEO_TIME);
      const time = retrieveData(STORAGE_CONSTANTS.VIDEO_TIME);

      if (totalTime && time) {
        removedFromStore(STORAGE_CONSTANTS.TOTAL_VIDEO_TIME);
        removedFromStore(STORAGE_CONSTANTS.VIDEO_TIME);
      }
    };
  }, []);

  useEffect(() => {
    if (accessUrlLoader) {
      // loadAccessUrl();
    }
  }, [accessUrlLoader, contentDetails]);

  const loadAccessUrl = async () => {
    if (
      contentDetails &&
      contentDetails?.field_supporting_docs &&
      !contentDetails?.field_is_ebsco
    ) {
      const response = await dispatch(
        azureFile({ fileUrl: contentDetails?.field_supporting_docs }) as any
      ).unwrap();
      if (
        response &&
        response?.statusCode === 200 &&
        response?.status === 'SUCCESS' &&
        response?.data
      ) {
        setAccessUrl(response?.data);
      } else {
        setAccessUrl('');
      }
      setAccessUrlLoader(false);
    }
  };

  const handleDownloadPPT = async () => {
    setLoader(true);
    if (contentData && getBlobFilePath(contentDetails?.field_supporting_docs)) {
      try {
        const response = await fetch(accessUrl);
        const blob = await response.blob();

        let fileExtension;
        switch (blob?.type) {
          case 'application/pdf':
            fileExtension = '.pdf';
            break;
          case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            fileExtension = '.pptx';
            break;
          case 'application/vnd.ms-powerpoint':
            fileExtension = '.ppt'; // Use .ppt for older PowerPoint formats
            break;
          default:
            fileExtension = '.download';
        }
        // Create a temporary anchor element
        const anchor = document.createElement('a');
        anchor.href = window.URL.createObjectURL(blob);

        // Set the Content-Disposition header to force download
        anchor.setAttribute(
          'download',
          `${contentDetails?.title}${fileExtension}`
        ); // You can customize the file name

        document.body.appendChild(anchor);
        anchor.click();
        setLoader(false);
        setShowToast(true);
        setIsSuccess(true);
        setToastMessage(
          t(AppConstant.KNOWLEDGE_HUB_PAGE.DOWNLOAD_SUCCESSFULLY)
        );
        document.body.removeChild(anchor);
      } catch (error) {
        setLoader(false);
        setShowToast(true);
        setIsSuccess(false);
        setToastMessage(t(AppConstant.KNOWLEDGE_HUB_PAGE.DOWNLOAD_FAILED));
        setToastSubMessage(
          t(AppConstant.KNOWLEDGE_HUB_PAGE.DOWNLOAD_FAILED_SUB_MESSAGE)
        );
      }
    } else {
      setLoader(false);
      setShowToast(true);
      setIsSuccess(false);
      setToastMessage(t(AppConstant.KNOWLEDGE_HUB_PAGE.DOWNLOAD_FAILED));
      setToastSubMessage(
        t(AppConstant.KNOWLEDGE_HUB_PAGE.DOWNLOAD_NO_FILE_AVAILABLE)
      );
    }
  };

  const handleToastClose = () => {
    setShowToast(false);
  };

  const sendNotificationForMilestoneUpdate = async () => {
    const action = notificationList?.find(
      (item: any) =>
        item?.field_unique_code === GAMIFICATION_ACTION_ID.UPDATE_MILESTONE
    )?.title;

    const req = {
      action: action,
      device: 'web',
    };
    // Hide Continue where you left feature
    await dispatch(sendNotification(req));
  };

  const updateGamificationPoints = async () => {
    if (isLoggedIn && GAMIFICATION_ENABLED) {
      const gamificationDataItem = gamificationPointDistributionData?.find(
        (item: any) =>
          item.field_unique_code === GAMIFICATION_TYPE_UNIQUE_CODE.READ_CONTENT
      );
      if (gamificationDataItem && gamificationDataItem?.field_unique_code) {
        const payload = {
          field_unique_code: gamificationDataItem?.field_unique_code,
          entityTypeId: contentId,
        };
        const response: any = await dispatch(addOrUpdateUserGamificationPoints(payload)).unwrap();
        if(response && response?.info) {
          sendNotificationForMilestoneUpdate()
        }
      }
    }
  };

  const handleStartReading = async () => {
    // await saveTimeStamp(0);
    if(isLoggedIn) {
      handleNextStep();
    } else {
      setShowSignUp(true);
    }
  };

  const handleImageDialog = () => {
    if (isLoggedIn) {
      setToggleImageDialog((prev) => !prev);
      updateGamificationPoints();
    } else {
      setShowSignUp(true);
    }
  };

  const onCloseImage = () => {
    setToggleImageDialog(false);
    checkRecognition();
  };

  const handleVideoDialog = (time?: number) => {
    if (isLoggedIn) {
      setIsVideoModalOpen(true);
      // setToggleVideoDialog((prev) => !prev);
      if (typeof time === 'number') {
        saveTimeStamp(time);
      }
      updateGamificationPoints();
    } else {
      setShowSignUp(true);
    }
  };

  const saveTimeStamp = (lastReadAt: any) => {
    const payload = {
      id: contentDetails?.id,
      lastreadat: lastReadAt,
    };
    dispatch(savePageNoLeftWhileReading(payload));
  };

  const contentIcon: any = useAppSelector(
    getIconForContentType(contentDetails?.field_unique_code)
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleSignUpClose = () => {
    setShowSignUp(false);
  };

  const handleImgClick = () => {
    if (!isLoggedIn) {
      setShowSignUp(true);
    } else {
      setShowSignUp(false);
    }
  };

  const shortDesc = contentDetails?.field_description?.substring(0, 340);
  const guestUserDescription = shortDesc ? parse(shortDesc) : '';
  const description = contentDetails?.field_description
    ? parse(contentDetails?.field_description)
    : '';

  const docLink = contentDetails?.field_supporting_docs;
  const typeOfResource = getResourceFileType(docLink);

  const additionalInfoData = [
    {
      title: t(AppConstant.KNOWLEDGE_HUB_PAGE.PUBLISHER),
      value: contentDetails?.field_book_detail?.field_publisher || 'NA',
    },
    {
      title: t(AppConstant.KNOWLEDGE_HUB_PAGE.VOLUME),
      value: contentDetails?.field_book_detail?.field_volume || 'NA',
    },
    {
      title: t(AppConstant.KNOWLEDGE_HUB_PAGE.NO_OF_PAGES),
      value: contentDetails?.field_book_detail?.field_no_of_pages || 'NA',
    },
    {
      title: t(AppConstant.KNOWLEDGE_HUB_PAGE.ISSN),
      value: contentDetails?.field_book_detail?.field_issn || 'NA',
    },
    {
      title: t(AppConstant.KNOWLEDGE_HUB_PAGE.PUBLICATION_YEAR),
      value: contentDetails?.field_book_detail?.field_publication_date || 'NA',
    },
  ];

  const defaultTabs = [
    {
      id: 0,
      enumId: DETAIL_TABS_ENUM.DESCRIPTION,
      label: t(AppConstant.KNOWLEDGE_HUB_PAGE.DESCRIPTION_TAB_TEXT),
      component: (
        <>
          {isLoggedIn ? (
            <div className={styles.articalContent}>
              {description
                ? description
                : t(AppConstant.KNOWLEDGE_HUB_PAGE.NO_DESCRIPTION_AVAILABLE)}
            </div>
          ) : (
            <div
              className={`${styles.articalContent} ${styles.guestDescWrapper}`}
            >
              {guestUserDescription
                ? guestUserDescription
                : t(AppConstant.KNOWLEDGE_HUB_PAGE.NO_DESCRIPTION_AVAILABLE)}
              {'...'}
              <div className={styles.guestDesc}>
                {/* <Link to={urls.signUpPage} state={{ pathName: pathName }}>
                  <button>
                    {t(AppConstant.AUTHENTICATION_PAGE.SIGN_UP_TO_CONTINUE)}
                  </button>
                </Link> */}
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      id: 1,
      enumId: DETAIL_TABS_ENUM.ADDITIONAL_INFO,
      label: t(AppConstant.COMMON.ADDITIONAL_INFO),
      component: (
        <div
          className={`${styles.bookDetail} ${
            !isLoggedIn && styles.guestDescWrapper
          }`}
        >
          {!isLoggedIn && (
            <div className={styles.guestDesc}>
              {/* <Link to={urls.signUpPage} state={{ pathName: pathName }}>
                <button>
                  {t(AppConstant.AUTHENTICATION_PAGE.SIGN_UP_TO_CONTINUE)}
                </button>
              </Link> */}
            </div>
          )}
          {additionalInfoData && additionalInfoData?.length > 0 ? (
            additionalInfoData?.map((item: any) => (
              <p>
                <label>{item?.title}</label>
                <span>{item?.value}</span>
              </p>
            ))
          ) : (
            <div className={styles.notFoundText}>
              <p>{t(AppConstant.COMMON.NO_ADDITIONAL_INFO_AVALIABLE)}</p>
            </div>
          )}
        </div>
      ),
    },
  ];

  const tabsEnum: any = getTabsEnum(
    contentDetails?.field_unique_code,
    contentDetails?.field_module_type
  );

  const tabs = tabsEnum?.length
    ? defaultTabs?.filter((item) =>
        tabsEnum?.includes(item.enumId as DetailTabEnumType)
      )
    : defaultTabs;

  const { DateIcon, ViewsIcon } = ImageConstant.SVG_ICONS;
  const isBookmarked: boolean = contentDetails?.is_bookmarked || false;
  const isLiked: boolean = contentDetails?.is_liked || false;

  return (
    <>
      <div className={styles.detailTop}>
        <div className={styles.detailImg}>
          <img
            className={styles.thumbnailImageGuest}
            src={
              contentDetails?.field_detail_image
                ? getImageSrc(contentDetails?.field_detail_image)
                : getDefaultImage(
                    true,
                    contentDetails?.field_module_type,
                    contentDetails?.field_unique_code
                  )
            }
            alt='card img'
            onError={(e) =>
              ((e.target as HTMLInputElement).src =
                ImageConstant.SOURCE.pdfDetailThumbnail)
            }
            onClick={handleImgClick}
          />

          <img
            src={
              contentDetails?.field_thumbnail_image
                ? getImageSrc(contentDetails?.field_thumbnail_image)
                : getDefaultImage(
                    true,
                    contentDetails?.field_module_type,
                    contentDetails?.field_unique_code
                  )
            }
            alt='card img'
            onError={(e) =>
              ((e.target as HTMLInputElement).src =
                ImageConstant.SOURCE.pdfDetailThumbnail)
            }
            className={isLoggedIn ? styles.mobileImg : `${styles.mobileImg} ${styles.thumbnailImageGuest}`}
            onClick={handleImgClick}
          />
          <div className={styles.datViewWrapper}>
            {contentDetails?.field_authored_on && (
              <div className={`${styles.dateViews}`}>
                <span>
                  <DateIcon />
                  <strong>{t(AppConstant.COMMON.DATE)}</strong>
                  {contentDetails?.field_authored_on
                    ? getOnlyDate(contentDetails?.field_authored_on)
                    : getDefaultValue()}
                </span>
              </div>
            )}
            <div className={styles.views}>
              <span className={styles.icon}>
                <ViewsIcon />
              </span>
              <span className={styles.text}>{t(AppConstant.COMMON.VIEWS)}</span>
              {contentDetails?.view_count ? contentDetails?.view_count : 0}
            </div>
          </div>
        </div>
        <div className={styles.detailInfo}>
          <div className={styles.tag}>
            {contentIcon ? (
              <img src={getImageSrc(contentIcon)} alt='content icon' />
            ) : null}
            <span>{contentDetails?.field_select_article_type}</span>
          </div>
          <h1 className={styles.detailTitle}>{contentDetails?.title}</h1>

          {contentDetails?.field_area_of_interest && (
            <div className={styles.specialityWrap}>
              <Speciality
                speciality={convertToSpecialties(
                  contentDetails?.field_area_of_interest || ''
                )}
                showAll={false}
              />
            </div>
          )}

          {/* <div className={styles.downloadPPT}>
            <ButtonComponent
              variant="outlined"
              buttonText={t(AppConstant.KNOWLEDGE_HUB_PAGE.DOWNLOAD_RESOURCE)}
              onPress={handleDownloadPPT}
              disabled={docLink && isLoggedIn ? false : true}
            />
          </div> */}
          <div className={styles.shareAndCta}>
            <div className={styles.downloadPPT}>
              <ButtonComponent
                variant='outlined'
                buttonText={
                  typeOfResource === RESOURCES_TYPE.IMAGE
                    ? t(AppConstant.KNOWLEDGE_HUB_PAGE.VIEW_ATTACHMENT)
                    : typeOfResource === RESOURCES_TYPE.VIDEO
                    ? t(AppConstant.COMMON.LAUNCH_VIDEO)
                    : t(AppConstant.KNOWLEDGE_HUB_PAGE.START_READING)
                }
                onPress={
                  typeOfResource === RESOURCES_TYPE.IMAGE
                    ? handleImageDialog
                    : typeOfResource === RESOURCES_TYPE.VIDEO
                    ? handleVideoDialog
                    : handleStartReading
                }
                disabled={docLink ? false : true}
              />
            </div>
            <div className={styles.detailActions}>
              <ShareSection
                isBookmarked={isBookmarked}
                isLiked={isLiked}
                fieldActions={contentDetails?.field_actions}
                fileDownloadUrl={getBlobFilePath(
                  contentDetails?.field_supporting_docs
                )}
                title={contentDetails?.title}
                contentId={contentDetails?.nid}
                fileUrl={contentDetails?.field_supporting_docs}
                content={contentDetails}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.shalinaTabs}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label='Detail Tabs'
          className={styles.custTabs}
        >
          {tabs?.map((tab, index) => (
            <Tab label={tab?.label} {...a11yProps(index)} />
          ))}
        </Tabs>
        <div className={styles.custTabContent}>
          {tabs?.map((tab: any, index: number) => (
            <CustomTabPanel value={tabValue} index={index}>
              {tab?.component}
            </CustomTabPanel>
          ))}
        </div>
      </div>

      {/* <div className={styles.headerTitle}>
        <span>{t(AppConstant.KNOWLEDGE_HUB_PAGE.DESCRIPTION_TAB_TEXT)}</span>
      </div> */}
      {/* {isLoggedIn ? (
        <div className={styles.articalContent}>
          {description
            ? description
            : t(AppConstant.KNOWLEDGE_HUB_PAGE.NO_DESCRIPTION_AVAILABLE)}
        </div>
      ) : (
        <div className={`${styles.articalContent} ${styles.guestDesc}`}>
          {guestUserDescription
            ? guestUserDescription
            : t(AppConstant.KNOWLEDGE_HUB_PAGE.NO_DESCRIPTION_AVAILABLE)}
          {'...'}
          <div className={styles.guestDesc}>
            <Link to={urls.signUpPage} state={{ pathName: pathName }}>
              <button>
                {t(AppConstant.AUTHENTICATION_PAGE.SIGN_UP_TO_CONTINUE)}
              </button>
            </Link>
          </div>
        </div>
      )} */}

      {loader && <CircularLoader size={50} thickness={4} />}
      <Toast
        showToast={showToast}
        isSuccess={isSuccess}
        message={toastMessage}
        subMessage={toastSubMessage}
        handleToastClose={handleToastClose}
      />
      {toggleImageDialog && (
        <ImageShowDialogComponent
          key={`${Math.random() * 1000}`}
          imageUrl={getBlobFilePath(contentDetails?.field_supporting_docs)}
          onClose={onCloseImage}
        />
      )}
      <Dialog
        open={isVideoModalOpen}
        onClose={handleVideoModalClose}
        className={styles.customDialog}
        fullScreen
      >
        <DialogContent style={{ overflow: 'hidden' }}>
          <div className={styles.dialogContent}>
            <div className={styles.modalHeader}>
              <span className={styles.fileName}>
                {getFileNameFromUrl(contentDetails?.field_supporting_docs)}
              </span>
              <button
                className={styles.closeCta}
                onClick={handleVideoModalClose}
              >
                &times;
              </button>
            </div>
            <ReactPlayer
              ref={playerRef}
              url={getBlobFilePath(contentDetails?.field_supporting_docs)}
              controls
              playing={true}
              onProgress={(progress) => {
                setVideoPlaybackTime(progress?.playedSeconds);
                storeData(
                  STORAGE_CONSTANTS.VIDEO_TIME,
                  progress?.playedSeconds
                );
                // saveVideoTimeStamp(false);
              }}
              onDuration={(time) => {
                storeData(STORAGE_CONSTANTS.TOTAL_VIDEO_TIME, time);
              }}
              onPause={() => {
                // saveVideoTimeStamp(false);
                // setWatchingFullVideo(false);
              }}
              onReady={() => {
                if (!isReady) {
                  if (playerRef?.current && videoPlaybackTime) {
                    playerRef?.current?.seekTo(videoPlaybackTime);
                  }
                  setIsReady(true);
                }
              }}
              height={'auto'}
              width={'auto'}
              // onEnded={() => {
              //   saveVideoTimeStamp(true);
              // }}
            />
          </div>
        </DialogContent>
      </Dialog>
      <SignUpPopup open={showSignUp} handleClose={handleSignUpClose} />
    </>
  );
};

export default ResourceDetails;
