import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import {
  addOrUpdateUserGamificationPoints,
  AppConstant,
  DetailTabEnumType,
  DETAIL_TABS_ENUM,
  GAMIFICATION_ACTION_ID,
  GAMIFICATION_ENABLED,
  GAMIFICATION_TYPE_UNIQUE_CODE,
  getAdditionalInfoDetails,
  getBlobFilePath,
  getDefaultImage,
  getDefaultValue,
  getHideAuthor,
  getIconForContentType,
  getNotificationActionList,
  getOnlyDate,
  getOnlyTime,
  getTabsEnum,
  handleRecognitionPopup,
  hideDate,
  hideTime,
  ImageConstant,
  listGamificationPointDistribution,
  RECOGNITION_MESSAGE_TEXT,
  SAML_REDIRECTOR_API_CALL,
  savePageNoLeftWhileReading,
  sendNotification,
  translate,
  updateUserRecognition,
  useAppDispatch,
  useAppSelector,
} from '@shalina-app/shared';
import parse from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { Link, useLocation, useParams } from 'react-router-dom';

import {
  ButtonComponent,
  CircularLoader,
  ShareSection,
  Speciality,
  Toast,
  VideoeDetailsInterface,
} from '../../../..';
import { SignUpPopup } from '../../../../../features';
import { URLConstant } from '../../../../../routes';
import {
  convertToSpecialties,
  getImageSrc,
  retrieveData,
  STORAGE_CONSTANTS,
} from '../../../../../utils';
import styles from './videoDetails.module.scss';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export const VideoDetails: React.FC<VideoeDetailsInterface> = ({
  ctaAction,
  contentData,
  handleNextStep,
}) => {
  const t = translate();
  const playerRef = useRef<any>(null);
  const location = useLocation();
  const pathName = location?.pathname;

  const [reload, setReload] = useState(false);
  const [showFullVideo, setShowFullVideo] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [trailerVideoLink, setTrailerVideoLink] = useState('');
  const [fullVideoLink, setFullVideoLink] = useState('');
  const [videoPlaybackTime, setVideoPlaybackTime] = useState(0);
  const [watchingFullVideo, setWatchingFullVideo] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastSubMessage, setToastSubMessage] = useState('');
  const [loader, setLoader] = useState(false);
  const [showViews, setShowViews] = useState(true);
  const [loadTrailerVideoApi, setLoadTrailerVideoApi] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [showSignUp, setShowSignUp] = useState(false);

  const dispatch = useAppDispatch();
  const { contentId } = useParams();

  const gamificationPointDistributionData = useAppSelector(
    listGamificationPointDistribution
  );
  const notificationList = useAppSelector(getNotificationActionList);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const { ViewsIcon, DurationIcon, DateIcon, TimeIcon, AuthorIcon, TagIcon } =
    ImageConstant.SVG_ICONS;

  const contentDetails = contentData ? contentData : null;
  const fullVideoISFlag = contentDetails?.field_supporting_docs;
  const trailerVideoISFlag = contentDetails?.field_upload_video_trailer;

  const handleTrailerVideoClick = () => {
    if (isLoggedIn) {
      setLoadTrailerVideoApi(true);
    } else {
      setShowSignUp(true);
    }
  };

  useEffect(() => {
    const trailerVideoUrlLink =
      contentDetails?.field_upload_video_trailer as string;
    const fieldEbesco = contentDetails?.field_is_ebsco || false;
    if (loadTrailerVideoApi) {
      if(!fieldEbesco) {
        updateGamificationPoints();
      }
      loadTrailerVideo(trailerVideoUrlLink);
      setLoadTrailerVideoApi(false);
      // Update state to indicate watching full video
      setWatchingFullVideo(false);
      // Show the full video
      setShowFullVideo(false);
    }
  }, [loadTrailerVideoApi]);

  const sendNotificationForMilestoneUpdate = async () => {
    const action = notificationList?.find(
      (item: any) =>
        item?.field_unique_code === GAMIFICATION_ACTION_ID.UPDATE_MILESTONE
    )?.title;

    const req = {
      action: action,
      device: 'web',
    };
    // Hide Continue where you left feature
    await dispatch(sendNotification(req));
  };

  const updateGamificationPoints = async () => {
    if (isLoggedIn && GAMIFICATION_ENABLED) {
      checkRecognition();
      const gamificationDataItem = gamificationPointDistributionData?.find(
        (item: any) =>
          item.field_unique_code === GAMIFICATION_TYPE_UNIQUE_CODE.READ_CONTENT
      );
      if (gamificationDataItem && gamificationDataItem?.field_unique_code) {
        const payload = {
          field_unique_code: gamificationDataItem?.field_unique_code,
          entityTypeId: contentId,
        };
        const response: any = await dispatch(addOrUpdateUserGamificationPoints(payload)).unwrap();
        if(response && response?.info) {
          sendNotificationForMilestoneUpdate()
        }
      }
    }
  };

  const checkRecognition = async () => {
    if (contentId) {
      const response = await dispatch(
        updateUserRecognition({
          view: 1,
          contentId: contentId,
        })
      ).unwrap();
      if (
        response &&
        response?.status === 'SUCCESS' &&
        response?.statusCode === 200 &&
        response?.message === RECOGNITION_MESSAGE_TEXT.AVID_READER &&
        response?.data
      ) {
        const obj = {
          showRecognitionPopup: true,
          recognitionData: response?.data,
        };
        dispatch(handleRecognitionPopup(obj));
      } else {
        const obj = {
          showRecognitionPopup: false,
          recognitionData: {},
        };
        dispatch(handleRecognitionPopup(obj));
      }
    }
  };

  const handleFullVideo = async () => {
    const fullVideoUrl = contentDetails?.field_supporting_docs as string;
    const fieldEbesco = contentDetails?.field_is_ebsco || false;
    if(isLoggedIn) {
      if (fullVideoUrl) {
        // Load the full video
        await loadFullVideoApi(fullVideoUrl);
        // Update state to indicate watching full video
        setWatchingFullVideo(true);
        // Show the full video
        setShowFullVideo(true);
        if(!fieldEbesco) {
          updateGamificationPoints();
        }
      }
    } else {
      setShowSignUp(true);
    }
  };

  const saveVideoTimeStamp = () => {
    const payload = {
      id: Number(contentId),
      lastreadat: `${videoPlaybackTime}`,
    };
    dispatch(savePageNoLeftWhileReading(payload));
  };

  useEffect(() => {
    setLoadTrailerVideoApi(true);
  }, []);

  useEffect(() => {
    if (contentDetails?.last_read_at) {
      setVideoPlaybackTime(contentDetails?.last_read_at); // convert to seconds
    }
  }, [contentDetails?.last_read_at]);

  const loadFullVideoApi = async (url: string) => {
    if (url && !fullVideoLink) {
      // const response = await dispatch(
      //   azureFile({ fileUrl: url }) as any
      // ).unwrap();
      // if (response?.statusCode === 200 && response?.status === "SUCCESS") {
      //   setFullVideoLink(response?.data);
      // }

      setFullVideoLink(getBlobFilePath(url));
    }
  };

  const loadTrailerVideo = async (url: string) => {
    if (url && !trailerVideoLink) {
      // const response = await dispatch(
      //   azureFile({ fileUrl: url }) as any
      // ).unwrap();
      // if (response?.statusCode === 200 && response?.status === "SUCCESS") {
      //   setTrailerVideoLink(response?.data);
      // }
      setTrailerVideoLink(getBlobFilePath(url));
    }
  };

  const handleLaunchVideo = () => {
    if (isLoggedIn) {
      handleNextStep();
    } else {
      setShowSignUp(true);
    }
  };

  const handleImgClick = () => {
    if (!isLoggedIn) {
      setShowSignUp(true);
    } else {
      setShowSignUp(false);
    }
  };

  const handleSignUpClose = () => {
    setShowSignUp(false);
  };

  const { field_speciality } = contentDetails;
  // const specialities = field_speciality.split(", ");
  const isBookmarked: boolean = contentDetails?.is_bookmarked || false;
  const isLiked: boolean = contentDetails?.is_liked || false;

  const contentIcon: any = useAppSelector(
    getIconForContentType(contentDetails?.field_unique_code)
  );

  const handleToastClose = () => {
    setShowToast(false);
  };
  const { urls } = URLConstant;
  const isLoggedIn: boolean = retrieveData(STORAGE_CONSTANTS.IS_LOGGED_IN);
  const shortDesc = contentDetails?.field_description?.substring(0, 340);
  const guestUserDescription = shortDesc ? parse(shortDesc) : '';
  const description = contentDetails?.field_description
    ? parse(contentDetails?.field_description)
    : '';

  const timeHide = hideTime(
    contentDetails?.field_module_type,
    contentDetails?.field_unique_code
  );
  const dateHide = hideDate(
    contentDetails?.field_module_type,
    contentDetails?.field_unique_code
  );

  // const tempVideoPlayerCondition =
  //   contentDetails && isLoggedIn &&
  //   contentDetails?.event_data?.field_supporting_docs &&
  //   contentDetails?.nid == "778";

  const tempVideoPlayerCondition = false;
  const data = getAdditionalInfoDetails(contentDetails?.event_data);
  const hideAuthor = getHideAuthor(
    contentDetails?.field_module_type,
    contentDetails?.field_unique_code
  );

  const defaultTabs = [
    {
      id: 0,
      enumId: DETAIL_TABS_ENUM.DESCRIPTION,
      label: t(AppConstant.KNOWLEDGE_HUB_PAGE.DESCRIPTION_TAB_TEXT),
      component: (
        <>
          {isLoggedIn ? (
            <div className={styles.videoDesc}>
              {description
                ? description
                : t(AppConstant.KNOWLEDGE_HUB_PAGE.NO_DESCRIPTION_AVAILABLE)}
            </div>
          ) : (
            <div className={`${styles.videoDesc} ${styles.guestDescWrapper}`}>
              {guestUserDescription
                ? guestUserDescription
                : t(AppConstant.KNOWLEDGE_HUB_PAGE.NO_DESCRIPTION_AVAILABLE)}
              {'...'}
              <div className={styles.guestDesc}>
                {/* <Link to={urls.signUpPage} state={{ pathName: pathName }}>
                  <button>
                    {t(AppConstant.AUTHENTICATION_PAGE.SIGN_UP_TO_CONTINUE)}
                  </button>
                </Link> */}
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      id: 1,
      enumId: DETAIL_TABS_ENUM.ADDITIONAL_INFO,
      label: t(AppConstant.COMMON.ADDITIONAL_INFO),
      component: (
        <div
          className={`${styles.bookDetail} ${
            !isLoggedIn && styles.guestDescWrapper
          }`}
        >
          {!isLoggedIn && (
            <div className={styles.guestDesc}>
              {/* <Link to={urls.signUpPage} state={{ pathName: pathName }}>
                <button>
                  {t(AppConstant.AUTHENTICATION_PAGE.SIGN_UP_TO_CONTINUE)}
                </button>
              </Link> */}
            </div>
          )}
          {data && data?.length > 0 ? (
            data?.map((item: any) => (
              <p>
                <label>{item?.title}</label>
                <span>{item?.value}</span>
              </p>
            ))
          ) : (
            <div className={styles.notFoundText}>
              <p>{t(AppConstant.COMMON.NO_ADDITIONAL_INFO_AVALIABLE)}</p>
            </div>
          )}
        </div>
      ),
    },
  ];

  const tabsEnum: any = getTabsEnum(
    contentDetails?.field_unique_code,
    contentDetails?.field_module_type
  );

  const tabs = tabsEnum?.length
    ? defaultTabs?.filter((item) =>
        tabsEnum?.includes(item.enumId as DetailTabEnumType)
      )
    : defaultTabs;

  return (
    <>
      <div className={styles.videoWrapper}>
        {contentDetails?.field_is_ebsco && !tempVideoPlayerCondition ? (
          <div className={styles.imgWrapper}>
            {SAML_REDIRECTOR_API_CALL ? (
              <img
                className={styles.playIcon}
                src={ImageConstant.SOURCE.playCircle}
                alt=''
                onClick={handleNextStep}
              />
            ) : (
              <img
                className={`${styles.playIcon} ${styles.playIconGuest}`}
                src={ImageConstant.SOURCE.playCircle}
                alt=''
              />
            )}
            <img
              className={isLoggedIn ? styles.thumbnailImage : `${styles.thumbnailImage} ${styles.thumbnailImageGuest}`}
              src={
                contentDetails?.field_thumbnail_image
                  ? getImageSrc(contentDetails?.field_thumbnail_image)
                  : getDefaultImage(
                      true,
                      contentDetails?.field_module_type,
                      contentDetails?.field_unique_code
                    )
              }
              alt='detail_image'
              onError={(e) =>
                ((e.target as HTMLInputElement).src =
                  ImageConstant.SOURCE.videoDetailThumbnail)
              }
              onClick={handleImgClick}
            />
          </div>
        ) : (
          !showFullVideo &&
          trailerVideoLink && (
            <ReactPlayer
              url={trailerVideoLink}
              controls
              playing
              height={'auto'}
              width={'auto'}
            />
          )
        )}
        {/* Full Video */}
        {(showFullVideo && fullVideoLink) || tempVideoPlayerCondition ? (
          <ReactPlayer
            ref={playerRef}
            url={
              tempVideoPlayerCondition
                ? contentDetails?.event_data?.field_supporting_docs
                : fullVideoLink
            }
            controls
            playing={tempVideoPlayerCondition ? true : watchingFullVideo}
            onProgress={(progress) => {
              setVideoPlaybackTime(progress?.playedSeconds);
              saveVideoTimeStamp();
            }}
            onPause={() => {
              saveVideoTimeStamp();
              setWatchingFullVideo(false);
            }}
            onReady={() => {
              if (!isReady) {
                if (playerRef?.current && videoPlaybackTime) {
                  playerRef?.current?.seekTo(videoPlaybackTime);
                }
                setIsReady(true);
              }
            }}
            height={'auto'}
            width={'auto'}
          />
        ) : (
          ''
        )}
      </div>
      <div className={styles.videoInfo}>
        <div className={styles.tag}>
          {contentIcon ? (
            <img src={getImageSrc(contentIcon)} alt='content icon' />
          ) : null}
          <span>{contentDetails?.field_select_article_type}</span>
        </div>
        <h1 className={styles.title}>{contentDetails?.title}</h1>
        <div className={styles.specialityWrap}>
          {contentDetails?.field_area_of_interest && (
            <Speciality
              speciality={convertToSpecialties(
                contentDetails?.field_area_of_interest || ''
              )}
              showAll={false}
            />
          )}
        </div>
        {contentDetails?.field_tags && (
          <div className={styles.publisher}>
            <TagIcon />
            <span title={contentDetails?.field_tags}>
              {contentDetails?.field_tags}
            </span>
          </div>
        )}
        {!hideAuthor && contentDetails?.field_authored_by && (
          <div className={styles.publisher}>
            <AuthorIcon />
            <span className={styles.authorLabel}>
              {t(AppConstant.COMMON.AUTHORED_BY)}
            </span>
            <span
              className={styles.authorText}
              title={contentDetails?.field_authored_by}
            >
              {contentDetails?.field_authored_by || getDefaultValue()}
            </span>
          </div>
        )}
        <div className={styles.viewsDate}>
          <div className={styles.dateViews}>
            {/* {contentDetails?.field_authored_on && ( */}
            {!dateHide && (
              <span>
                <DateIcon />
                <strong>{t(AppConstant.COMMON.DATE)}</strong>
                {getOnlyDate(contentDetails?.field_authored_on)}
              </span>
            )}
            {/* )} */}
            {/* {contentDetails?.field_authored_on && ( */}
            {!timeHide && (
              <span>
                <TimeIcon />
                <strong>{t(AppConstant.COMMON.TIME)}</strong>
                {getOnlyTime(contentDetails?.field_authored_on)}
              </span>
            )}
            {/* )} */}
            <span>
              <ViewsIcon />
              <strong>{t(AppConstant.COMMON.VIEWS)}</strong>
              {contentDetails?.view_count ? contentDetails?.view_count : 0}
            </span>
            {/* {contentDetails?.field_duration && ( */}
            {/* <span>
              <DurationIcon />
              <strong>{t(AppConstant.COMMON.DURATION)}</strong>
              {viewDurationInHours(contentDetails?.field_duration)}
            </span> */}
            {/* )} */}
          </div>
        </div>
        <div className={styles.ctaShare}>
          <div className={styles.fullVideoCta}>
            {ctaAction && !tempVideoPlayerCondition ? (
              <ButtonComponent
                disabled={SAML_REDIRECTOR_API_CALL ? false : true}
                variant='outlined'
                buttonText={t(AppConstant.COMMON.LAUNCH_VIDEO)}
                onPress={handleLaunchVideo}
              />
            ) : showFullVideo && !tempVideoPlayerCondition ? (
              <ButtonComponent
                disabled={trailerVideoISFlag ? false : true}
                variant='outlined'
                buttonText={t(AppConstant.COMMON.PLAY_TRAILER_VIDEO_CTA)}
                onPress={handleTrailerVideoClick}
              />
            ) : !tempVideoPlayerCondition ? (
              <ButtonComponent
                disabled={fullVideoISFlag ? false : true}
                variant='outlined'
                buttonText={t(AppConstant.COMMON.PLAY_FULL_VIDEO_CTA)}
                onPress={handleFullVideo}
              />
            ) : (
              ''
            )}
          </div>
          <div className={styles.shareWrap}>
            <ShareSection
              isBookmarked={isBookmarked}
              isLiked={isLiked}
              fieldActions={contentDetails?.field_actions}
              fileDownloadUrl={fullVideoLink ? fullVideoLink : trailerVideoLink}
              title={contentDetails?.title}
              contentId={contentDetails?.nid}
              fileUrl={contentDetails?.field_supporting_docs}
              content={contentDetails}
            />
          </div>
        </div>
      </div>

      <div className={styles.shalinaTabs}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label='Detail Tabs'
          className={styles.custTabs}
        >
          {tabs?.map((tab, index: number) => (
            <Tab label={tab?.label} {...a11yProps(index)} />
          ))}
          {/* <Tab
            label={t(AppConstant.KNOWLEDGE_HUB_PAGE.DESCRIPTION_TAB_TEXT)}
            {...a11yProps(0)}
          />
          <Tab
            label={t(AppConstant.COMMON.ADDITIONAL_INFO)}
            {...a11yProps(1)}
          /> */}
        </Tabs>
        <div className={styles.custTabContent}>
          {tabs?.map((tab: any, index: number) => (
            <CustomTabPanel value={tabValue} index={index}>
              {tab?.component}
            </CustomTabPanel>
          ))}
        </div>
        {loader && <CircularLoader size={50} thickness={4} />}
        <Toast
          showToast={showToast}
          isSuccess={isSuccess}
          message={toastMessage}
          subMessage={toastSubMessage}
          handleToastClose={handleToastClose}
        />
      </div>
      <SignUpPopup open={showSignUp} handleClose={handleSignUpClose} />
    </>
  );
};

export default VideoDetails;
