export const HeaderConstants = {
  LANGUAGE_SELECTOR: 'Language',
};

export const menuSequence = [
  'home',
  'knowledgeHub',
  'shalinaTv',
  'learningCenter',
  'community',
  'medicalNews',
  'survey',
  'medicalCalculator',
  'aboutUs',
  'helpSupport',
];
