import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { storeAccessToken } from '../../../utils';

export const SamlRedirector = () => {
  const location = useLocation();
  const params = location?.search;
  const urlParams = new URLSearchParams(params);
  const tokenParam: any = urlParams.get('token');
  const urlParam: any = urlParams.get('url');

  const decodedTokenParam = decodeURIComponent(tokenParam);
  storeAccessToken(decodedTokenParam);
  const decodedUrlParam = decodeURIComponent(urlParam);

  useEffect(() => {
    if (decodedUrlParam) {
      window.location.href = decodedUrlParam;
      // window.location.href = 'http://localhost:3000/sign-in?returnData="cgdf"'
    }
  }, [decodedUrlParam]);

  return <></>;
};
