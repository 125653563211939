import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import styles from './loader.module.scss';
import { LoaderProps } from '.';

export const CircularLoader: React.FC<LoaderProps> = ({ size, thickness }) => {
  return (
    <Box sx={{ display: 'flex' }} className={styles.loaderWrapper}>
      <CircularProgress
        size={size}
        thickness={thickness}
        sx={{
          color: 'var(--secondaryOrange)'
        }}
      />
    </Box>
  );
};
