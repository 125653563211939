import { useState } from 'react';
import { AppConstant, COMMUNITY_ENABLED, ImageConstant, translate } from '@shalina-app/shared';
import { Link } from 'react-router-dom';

import { ABOUT_US_PAGE_TABS } from '../../../app.constants';
import { URLConstant } from '../../../routes';
import styles from './footerComponent.module.scss';

export const NavItems = () => {
  const t = translate();
  const { urls } = URLConstant;

  const commonMenuItems = [
    {
      name: t(AppConstant.HEADER.KNOWLEDGE_HUB_MENU),
      route: urls.knowledgeHubPage
    },
    {
      name: t(AppConstant.HEADER.SHALINA_TV_MENU),
      route: urls.shalinaTv
    },
    {
      name: t(AppConstant.HEADER.LEARNING_CENTER_MENU),
      route: urls.learningCenterPage
    }
  ];

  const navList1 = COMMUNITY_ENABLED
  ? [...commonMenuItems, {
    name: t(AppConstant.HEADER.COMMUNITIES_MENU),
    route: urls.commiunitiesPage
  }]
  : commonMenuItems;

  const navList2 = [
    {
      name: t(AppConstant.COMMON.ABOUT_US_TAB_TEXT),
      route: urls.aboutUsPage,
      state: { tab: ABOUT_US_PAGE_TABS.WHO_WE_ARE }
    },
    {
      name: t(AppConstant.COMMON.JOURNEY_TAB_TEXT),
      route: urls.aboutUsPage,
      state: { tab: ABOUT_US_PAGE_TABS.OUR_JOURNEY }
    },
    {
      name: t(AppConstant.COMMON.EXPERTS_TAB_TEXT),
      route: urls.aboutUsPage,
      state: { tab: ABOUT_US_PAGE_TABS.EXPERTS }
    },
    {
      name: t(AppConstant.COMMON.TERMS_AND_CONDITIONS_MENU),
      route: urls.termsAndConditionsPage,
      state: null
    },
    {
      name: t(AppConstant.COMMON.HELP_AND_SUPPORT_MENU),
      route: urls.helpSupport,
      state: null
    }
  ];
  
  const { DropDownArrowIcon } = ImageConstant.SVG_ICONS;

  const [isExploreListActive, setExploreListActive] = useState<boolean>(false);
  const toggleExploreList = () => {
    setExploreListActive(!isExploreListActive);
  };
  const [isAboutListActive, setAboutListActive] = useState<boolean>(false);
  const toggleAboutList = () => {
    setAboutListActive(!isAboutListActive);
  };

  return (
    <>
      <div className={styles.footerItem}>
        <p className={styles.footerTitle} onClick={toggleExploreList}>
          {t(AppConstant.COMMON.EXPLORE_TEXT)}
          <span className={styles.icon}>
            <DropDownArrowIcon />
          </span>
        </p>
        <ul
          className={`${styles.footerNavList} ${
            isExploreListActive && styles.open
          }`}
        >
          {navList1?.map((nav, index) => (
            <li key={index}>
              <Link to={nav?.route}>{nav?.name}</Link>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.footerItem}>
        <p className={styles.footerTitle} onClick={toggleAboutList}>
          {t(AppConstant.HEADER.ABOUT_US_MENU)}
          <span className={styles.icon}>
            <DropDownArrowIcon />
          </span>
        </p>
        <ul
          className={`${styles.footerNavList} ${
            isAboutListActive && styles.open
          }`}
        >
          {navList2?.map((nav, index) => (
            <li key={index}>
              <Link to={nav?.route} state={nav?.state}>
                {nav?.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
