"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EVENT_TYPES = exports.OTP_VERIFICATION_MEDIUM = exports.SEND_OTP_ACTION_TYPE = exports.CONTENT_CATEGORY_FIELD_TYPES = void 0;
exports.CONTENT_CATEGORY_FIELD_TYPES = {
    KNOWLEDGE_HUB: "knowledge_hub",
    SHALINA_TV: "shalina_tv",
    LEARNING_CENTER: "learning_center",
};
exports.SEND_OTP_ACTION_TYPE = {
    SIGN_UP_ACTION: "signup",
    SIGN_IN_ACTION: "signin",
    PROFILE_ACTION: "verify",
    BLANK_ACTION: "",
};
exports.OTP_VERIFICATION_MEDIUM = {
    EMAIL_VERIFICATION_MEDIUM: "email",
    SMS_VERIFICATION_MEDIUM: "sms",
    WHATSAPP_VERIFICATION_MEDIUM: "whatsapp",
};
exports.EVENT_TYPES = {
    ALL: "all",
    PAST: "past",
    UPCOMING: "publish",
    ACTIVE: "active",
};
