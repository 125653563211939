"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnswerQuestionsIcon = void 0;
const React = __importStar(require("react"));
const AnswerQuestionsIcon = (props) => (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: 18, height: 16, fill: "none" }, props),
    React.createElement("path", { fill: "#E84E1B", d: "M6.546 9.263c1.808 0 3.273-1.507 3.273-3.368S8.354 2.526 6.546 2.526 3.273 4.034 3.273 5.895c0 1.86 1.465 3.368 3.273 3.368Zm0-5.052c.9 0 1.636.757 1.636 1.684 0 .926-.736 1.684-1.636 1.684-.9 0-1.637-.758-1.637-1.684 0-.927.737-1.684 1.637-1.684Zm0 6.736C4.36 10.947 0 12.076 0 14.316 0 15.246.754 16 1.684 16h9.723c.93 0 1.685-.754 1.685-1.684 0-2.24-4.362-3.369-6.546-3.369Zm-4.91 3.369c.18-.607 2.709-1.684 4.91-1.684 2.209 0 4.746 1.086 4.91 1.684h-9.82ZM12.115 3.638c-.346.357-.369.905-.216 1.378.18.563.183 1.165.006 1.729-.153.486-.13 1.047.224 1.413.42.434 1.13.44 1.432-.082.77-1.326.765-2.94-.013-4.334-.296-.53-1.012-.54-1.433-.104Zm4.14-2.862c-.33-.442-.973-.445-1.358-.05-.353.365-.358.936-.074 1.357 1.534 2.268 1.53 5.26-.016 7.615-.28.426-.267.997.088 1.362.388.4 1.035.403 1.374-.039 2.31-3.006 2.31-7.146-.013-10.245Z" })));
exports.AnswerQuestionsIcon = AnswerQuestionsIcon;
