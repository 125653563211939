import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {
  AppConstant,
  submitRequest,
  translate,
  useAppDispatch
} from '@shalina-app/shared';
import { CircularProgress } from '@mui/material';

import { RequestRevokeDialogItemProps } from './requestRevokeDialog.interface';
import styles from './requestRevokeDialog.module.scss';

export const RequestRevokeDialog: React.FC<RequestRevokeDialogItemProps> = ({
  closeRevokeDialog,
  openRevokeDialog,
  handleRevoke,
  nid
}) => {
  const t = translate();
  const dispatch = useAppDispatch();
  //states
  const [revokeDialog, setRevokeDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // useEffects
  useEffect(() => {
    if (openRevokeDialog) {
      handleOpenDialog();
    }
  }, []);
  const handleOpenDialog = () => {
    setRevokeDialog(true);
  };

  const closeDialog = () => {
    setRevokeDialog(false);
    closeRevokeDialog();
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const response = await dispatch(
      submitRequest({
        nid: nid,
        revoke: 1
      })
    ).unwrap();
    if (response.statusCode === 200 && response.data) {
      setRevokeDialog(false);
      handleRevoke({
        showToast: true,
        isSuccess: true,
        toastMainMessage: t(AppConstant.COMMON.REVOKE_MSG)
      });
    } else {
      handleRevoke({
        showToast: true,
        isSuccess: true,
        toastMainMessage: t(AppConstant.ERROR.SOMETHING_WENT_WRONG)
      });
    }
    setIsLoading(false);
  };

  return (
    <>
      <Dialog
        open={revokeDialog}
        // onClose={handleRevokeClose}
        className={styles.modifyModal}
      >
        {/* {isLoading && <CircularLoader size={50} thickness={4} />} */}
        <DialogContent>
          <div className={styles.revokeWrapper}>
            <h4 className={styles.revokeTitle}>
              {t(AppConstant.HOME_PAGE.REVOKE_REQUEST)}
            </h4>
            <p>{t(AppConstant.HOME_PAGE.REVOKE_REQUEST_QUESTION)}</p>
            <div className={styles.revokeCtas}>
              <button onClick={closeDialog} disabled={isLoading}>
                {t(AppConstant.PROFILE.CANCEL_CTA)}
              </button>
              <button
                onClick={handleSubmit}
                className={styles.sureCta}
                disabled={isLoading}
              >
                {t(AppConstant.HOME_PAGE.SURE)}
                {isLoading && (
                  <span className={styles.downloadLoader}>
                    <CircularProgress
                      size={20}
                      thickness={4}
                      sx={{
                        color: 'var(--secondaryOrange)'
                      }}
                    />
                  </span>
                )}
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
