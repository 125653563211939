"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThumbIcon = void 0;
const React = __importStar(require("react"));
const ThumbIcon = (props) => (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: 20, height: 20, fill: "none" }, props),
    React.createElement("path", { fill: "#E84E1B", d: "m12.981 7.884-.754-.125a.766.766 0 0 0 .754.89v-.765Zm-11.216 0v-.765A.765.765 0 0 0 1 7.884h.765Zm2.04 10.962h11.583v-1.53H3.804v1.53ZM16.61 7.119h-3.63v1.53h3.63v-1.53Zm-2.875.89.822-4.93-1.51-.252-.821 4.932 1.509.25Zm-.938-7.008h-.218v1.53h.217V1Zm-3.4 1.703L6.832 6.55l1.272.849 2.565-3.848-1.273-.848ZM5.771 7.119H1.765v1.53h4.007v-1.53ZM1 7.884v8.157h1.53V7.884H1Zm17.138 8.708 1.224-6.118-1.5-.3-1.223 6.117 1.499.3v.001ZM6.833 6.552a1.272 1.272 0 0 1-1.06.566v1.53c.937 0 1.812-.468 2.332-1.248L6.833 6.55Zm7.725-3.473a1.79 1.79 0 0 0-.399-1.447A1.784 1.784 0 0 0 12.798 1l-.001 1.53a.26.26 0 0 1 .195.09.254.254 0 0 1 .057.206l1.509.252Zm2.053 5.569c.806 0 1.408.736 1.25 1.524l1.5.3a2.81 2.81 0 0 0-.581-2.327 2.804 2.804 0 0 0-2.169-1.026v1.53-.001Zm-1.223 10.198a2.804 2.804 0 0 0 2.75-2.255l-1.499-.3a1.274 1.274 0 0 1-1.251 1.025v1.53ZM12.58 1a3.823 3.823 0 0 0-3.182 1.702l1.272.85a2.297 2.297 0 0 1 1.91-1.022V1ZM3.804 17.316a1.275 1.275 0 0 1-1.274-1.274H1a2.804 2.804 0 0 0 2.804 2.804v-1.53Z" }),
    React.createElement("path", { stroke: "#E84E1B", strokeWidth: 0.5, d: "m12.227 7.759.754.125v.765m-.754-.89a.766.766 0 0 0 .754.89m-.754-.89.822-4.932m-.822 4.932 1.509.25.822-4.93M12.98 8.65v-1.53h3.63m-3.63 1.53h3.63M1.765 7.119v.765H1m.765-.765A.765.765 0 0 0 1 7.884m.765-.765h4.007v1.53H1.765v-1.53ZM1 7.884v8.157m0-8.157h1.53v8.157m16.082-5.718.75.15m0 0-1.5-.3a1.275 1.275 0 0 0-1.25-1.525m2.75 1.825a2.81 2.81 0 0 0-.582-2.328 2.804 2.804 0 0 0-2.169-1.026M3.804 18.846h11.584m-11.584 0v-1.53m0 1.53A2.804 2.804 0 0 1 1 16.04m14.388 2.805v-1.53m0 1.53a2.804 2.804 0 0 0 2.75-2.255m-2.75.725H3.804m11.584 0a1.275 1.275 0 0 0 1.251-1.026l1.499.301m-14.334.725a1.275 1.275 0 0 1-1.274-1.274M16.61 7.118v1.53m-2.053-5.57-1.51-.252m1.51.252a1.79 1.79 0 0 0-.399-1.447A1.784 1.784 0 0 0 12.798 1m.25 1.826a.256.256 0 0 0-.251-.296m0-1.53h-.217m.218 0-.001 1.53M12.58 1v1.53m0-1.53a3.823 3.823 0 0 0-3.182 1.702l1.272.85m1.91-1.022h.217m-.217 0a2.294 2.294 0 0 0-1.91 1.021m-3.837 3 2.564-3.848 1.273.848m-3.837 3 1.272.848M6.833 6.55a1.272 1.272 0 0 1-1.06.567v1.53c.937 0 1.812-.468 2.332-1.248m0 0 2.565-3.848M1 16.042h1.53m15.608.55-1.499-.3 1.224-6.119 1.499.301-1.224 6.118Z" }),
    React.createElement("path", { fill: "#E84E1B", d: "M5.84 7.884V18.08 7.884Z" }),
    React.createElement("path", { stroke: "#E84E1B", strokeWidth: 1.7, d: "M5.84 7.884V18.08" })));
exports.ThumbIcon = ThumbIcon;
