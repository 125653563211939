import React, { useEffect, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import {
  ImageConstant,
  useAppDispatch,
  quizDetails,
  getQuizDetails,
  useAppSelector,
  submitQuizResponse,
  translate,
  AppConstant,
  SURVEY_QUESTION_FIELD_TYPE,
  logEvent,
  CUSTOM_ANALYTICS_EVENT_NAMES,
  PlatformNames,
  getAnalyticsUserDetails,
  getLoggedInUserInfo,
} from '@shalina-app/shared';
import { useLocation, useParams } from 'react-router-dom';
import {
  Box,
  MenuItem,
  Rating,
  Select,
  Slider,
  TextareaAutosize,
} from '@mui/material';

import {
  AutoComplete,
  BarLoader,
  BreadCrumb,
  Input,
} from '../../../../components';
import { SurveyQuizPropsItems } from './quiz.interface';
import styles from './quiz.module.scss';
import { URLConstant } from '../../../../routes';
import {
  retrieveData,
  STORAGE_CONSTANTS,
  useNavigatePageUtils,
} from '../../../../utils';
import { ButtonLoader } from '../../../communities';
import { appInsights } from '../../../../applicationInsight';
import moment from 'moment';

export const SurveyQuiz: React.FC<SurveyQuizPropsItems> = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const params = useParams();
  const { urls } = URLConstant;
  const t = translate();
  const navigatePage = useNavigatePageUtils();

  const { UploadIcon } = ImageConstant.SVG_ICONS;

  const user = retrieveData(STORAGE_CONSTANTS.USER);

  const [activeStep, setActiveStep] = useState(1);
  const [open, setOpen] = useState(false);
  const [responses, setResponses] = useState<{ [key: string]: any }>({});
  const [analyticResponse, setAnalyticResponse] = useState<any>([]);
  const [selectedValues, setSelectedValues] = useState<{ [key: string]: any }>(
    {}
  );
  const [submitLoader, setSubmitLoader] = useState(false);
  const [apiCall, setApiCall] = useState(false);
  const [starValue, setStarValue] = useState<number>(0);
  const [rangeValue, setRangeValue] = useState<number>(0);
  const [fileName, setFileName] = useState<string>('');
  const [file, setFile] = useState<File | any>(null);
  const [fileErrorMessage, setFileErrorMessage] = useState('');

  const userDetails = useAppSelector(getLoggedInUserInfo);

  const surveyUniqueId = location?.state?.surveyUniqueId;
  const surveyId = params?.surveyId;

  useEffect(() => {
    window.scrollTo(0, 0);
    setApiCall(true);
  }, []);

  useEffect(() => {
    if (apiCall && surveyUniqueId) {
      dispatch(quizDetails(surveyUniqueId));
      setApiCall(false);
    }
  }, [apiCall, surveyUniqueId]);

  const quizData = useAppSelector(getQuizDetails);
  const isQuizDetailsLoading = useAppSelector(
    (state) => state?.survey?.isLoading
  );

  const questionsPerPage = 3;
  const totalSteps = Math.ceil(quizData?.length / questionsPerPage);

  useEffect(() => {
    const initialSelectedValues: { [key: string]: any } = {};
    quizData?.forEach((data: any) => {
      if (data.fieldType === 'multi-select') {
        initialSelectedValues[data.titleKey] = [];
      }
      setSelectedValues(initialSelectedValues);
    });
  }, [quizData]);
  const handleNext = () => {
    setActiveStep((prevStep) => Math.min(prevStep + 1, totalSteps));
  };

  const handleBack = () => {
    setActiveStep((prevStep) => Math.max(prevStep - 1, 1));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    // setOpen(true);
  };
  const handleOptionChange = (
    questionKey: string,
    value: any,
    questionTitle: string
  ) => {
    setResponses({ ...responses, [questionKey]: value });

    const existingIndex = analyticResponse?.findIndex(
      (obj: any) => obj?.questionKey === questionKey
    );

    if (existingIndex !== -1) {
      analyticResponse[existingIndex] = {
        ...analyticResponse[existingIndex],
        answer: value,
      };
      setAnalyticResponse(analyticResponse);
    } else {
      const obj = {
        questionKey: questionKey,
        question: questionTitle,
        answer: value,
      };
      setAnalyticResponse([...analyticResponse, obj]);
    }
  };

  const handleCheckboxChange = (
    questionKey: string,
    optionKey: string,
    isChecked: boolean,
    questionTitle: string
  ) => {
    // Clone the current state of responses
    const updatedResponses = { ...responses };

    // If the option is checked, add it to the responses array, otherwise remove it
    if (isChecked) {
      // If updatedResponses[questionKey] is undefined, initialize it as an empty array
      updatedResponses[questionKey] = updatedResponses[questionKey] || [];

      // Add the selected option to the array
      updatedResponses[questionKey].push(optionKey);
    } else {
      // If the option is unchecked, remove it from the responses array
      updatedResponses[questionKey] = (
        updatedResponses[questionKey] || []
      ).filter((item: any) => item !== optionKey);
    }

    // Update the state with the modified responses
    setResponses(updatedResponses);

    const existingIndex = analyticResponse?.findIndex(
      (obj: any) => obj?.questionKey === questionKey
    );

    if (existingIndex !== -1) {
      const updatedQuestions = [...analyticResponse];

      if (isChecked) {
        // Add value to answer
        updatedQuestions[existingIndex].answer = updatedQuestions[existingIndex]
          .answer
          ? `${updatedQuestions[existingIndex].answer}, ${optionKey}`
          : optionKey;
      } else {
        // Remove value from answer
        updatedQuestions[existingIndex].answer = updatedQuestions[
          existingIndex
        ].answer
          .split(', ')
          .filter((val: any) => val !== optionKey)
          .join(', ');
      }

      // Remove object if answer is empty
      if (updatedQuestions[existingIndex].answer === '') {
        updatedQuestions.splice(existingIndex, 1);
      }

      setAnalyticResponse(updatedQuestions);
    } else {
      const obj = {
        questionKey: questionKey,
        question: questionTitle,
        answer: optionKey,
      };
      setAnalyticResponse([...analyticResponse, obj]);
    }
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    questionKey: any,
    questionTitle: string
  ) => {
    const file = event?.target?.files?.[0];

    if (
      file &&
      file.type.startsWith('image/') &&
      file.size <= 3 * 1024 * 1024
    ) {
      const reader = new FileReader();
      reader.readAsDataURL(file); // Start reading the file asynchronously
      reader.onloadend = () => {
        const base64String = reader.result as string; // Type assertion to string
        // setErrorMessage('');

        if (base64String) {
          setFileName(file.name);
          setFile(base64String);
          const filePayload = {
            name: file.name,
            image_base64: base64String,
            error: '',
          };
          setResponses({ ...responses, [questionKey]: filePayload });

          const existingIndex = analyticResponse?.findIndex(
            (obj: any) => obj?.questionKey === questionKey
          );

          if (existingIndex !== -1) {
            analyticResponse[existingIndex] = {
              ...analyticResponse[existingIndex],
              answer: file.name,
            };
            setAnalyticResponse(analyticResponse);
          } else {
            const obj = {
              questionKey: questionKey,
              question: questionTitle,
              answer: file?.name,
            };
            setAnalyticResponse([...analyticResponse, obj]);
          }
        }
      };

      reader.onerror = (error) => {
        const response = {
          error: t(AppConstant.HOME_PAGE.IMAGE_MAX_MB_ERROR_TEXT),
        };
        setResponses({
          ...responses,
          [questionKey]: `Error reading the file: ${error}`,
        });
        // setFileErrorMessage(`Error reading the file: ${error}`);
      };
    } else {
      const response = {
        error: t(AppConstant.HOME_PAGE.IMAGE_MAX_MB_ERROR_TEXT),
      };
      setResponses({ ...responses, [questionKey]: response });
      // setFileErrorMessage(`${t(AppConstant.HOME_PAGE.IMAGE_MAX_MB_ERROR_TEXT)}`);
    }
  };

  const handleFileRemove = (questionKey: any, questionTitle: string) => {
    setFile(null);
    setFileErrorMessage('');
    setFileName('');
    setResponses({ ...responses, [questionKey]: '' });

    const existingIndex = analyticResponse?.findIndex(
      (obj: any) => obj?.questionKey === questionKey
    );

    if (existingIndex !== -1) {
      const filterArr = analyticResponse.filter(
        (obj: any) => obj?.questionKey !== questionKey
      );
      setAnalyticResponse(filterArr);
    } else {
      const obj = {
        questionKey: questionKey,
        question: questionTitle,
        answer: '',
      };
      setAnalyticResponse([...analyticResponse, obj]);
    }
  };

  const renderOptions = (question: any) => {
    const questionKey = question?.titleKey;
    const questionTitle = question?.tilte;

    switch (question?.fieldType) {
      case SURVEY_QUESTION_FIELD_TYPE.CHECKBOXES:
        return (
          <FormControl>
            <div>
              {Object.entries(question.options)?.map(([key, value]: any) => (
                <FormControlLabel
                  key={key}
                  control={
                    <Checkbox
                      checked={responses[questionKey]?.includes(key) || false}
                      onChange={(e) =>
                        handleCheckboxChange(
                          questionKey,
                          key,
                          e.target.checked,
                          questionTitle
                        )
                      }
                    />
                  }
                  label={value}
                />
              ))}
            </div>
          </FormControl>
        );
      case SURVEY_QUESTION_FIELD_TYPE.RADIO:
        return (
          <FormControl>
            <RadioGroup
              aria-labelledby={`radio-group-label-${questionKey}`}
              value={responses[questionKey] || ''}
              onChange={(e) =>
                handleOptionChange(questionKey, e.target.value, questionTitle)
              }
            >
              {Object.entries(question.options).map(([key, value]: any) => (
                <FormControlLabel
                  key={key}
                  value={key}
                  control={<Radio />}
                  label={value}
                />
              ))}
            </RadioGroup>
          </FormControl>
        );
      case SURVEY_QUESTION_FIELD_TYPE.TEXT_AREA:
        return (
          <div className={styles.surveyTextArea}>
            <TextareaAutosize
              placeholder={t(AppConstant.COMMON.ENTER_DESCRIPTION)}
              aria-labelledby={`textarea-label-${questionKey}`}
              value={responses[questionKey] || ''}
              onChange={(e) =>
                handleOptionChange(questionKey, e.target.value, questionTitle)
              }
              className={styles.textArea}
            />
          </div>
        );
      case SURVEY_QUESTION_FIELD_TYPE.SELECT:
        return (
          <FormControl>
            <Select
              displayEmpty
              value={responses[questionKey] || ''}
              fullWidth
              variant={`outlined`}
              onChange={(e) =>
                handleOptionChange(questionKey, e.target.value, questionTitle)
              }
            >
              <MenuItem disabled value={``}>
                <span className={styles.placeHolderText}>
                  {t(AppConstant.COMMON.SELECT)}
                </span>
              </MenuItem>
              {Object.entries(question.options).map(([key, value]: any) => (
                <MenuItem value={key}>{value}</MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      case SURVEY_QUESTION_FIELD_TYPE.INPUT:
        return (
          <FormControl>
            <Input
              placeHolderText={t(AppConstant.COMMON.ENTER_INPUT)}
              inputId={questionKey}
              isError={false}
              errorMessage=''
              value={responses[questionKey] || ''}
              maxLength={255}
              handleChange={(e) =>
                handleOptionChange(questionKey, e.target.value, questionTitle)
              }
            />
          </FormControl>
        );
      case SURVEY_QUESTION_FIELD_TYPE.MULTI_SELECT:
        const outputArray = Object.entries(question?.options).map(
          ([label, id]) => ({
            label,
            id,
          })
        );
        return (
          <FormControl>
            <AutoComplete
              placeholder={t(AppConstant.COMMON.SELECT_OPTIONS)}
              options={outputArray}
              id={questionKey}
              labelText={''}
              isRequired={false}
              isError={false}
              errorMessage={''}
              selectedOptions={selectedValues[questionKey]}
              onSelectChange={(e) =>
                handleMultiselectChange(questionKey, e, questionTitle)
              }
              // handleChange={handleSpecializedOptions}
            />
          </FormControl>
        );

      case SURVEY_QUESTION_FIELD_TYPE.STAR_RATING:
        return (
          <div className={styles.starRatingWrap}>
            <FormControl>
              <Rating
                name='size-large'
                size='large'
                value={responses[questionKey]}
                max={question?.options?.max}
                onChange={(event, newValue: any) => {
                  setStarValue(newValue);
                  handleOptionChange(questionKey, newValue, questionTitle);
                }}
              />
            </FormControl>
          </div>
        );

      case SURVEY_QUESTION_FIELD_TYPE.RANGE_SLIDER:
        const max = question?.options?.max;
        const min = question?.options?.min;

        const marks = [
          {
            value: min,
            label: min,
          },
          {
            value: max,
            label: max,
          },
        ];
        return (
          <div className={styles.rangeWrap}>
            <FormControl>
              <Box sx={{ width: 300 }}>
                <Slider
                  value={responses[questionKey]}
                  aria-label='default'
                  valueLabelDisplay='auto'
                  min={min}
                  max={max}
                  defaultValue={min}
                  marks={marks}
                  onChange={(_: Event, newValue: number | number[]) => {
                    setRangeValue(newValue as number);
                    handleOptionChange(questionKey, newValue, questionTitle);
                  }}
                />
              </Box>
            </FormControl>
          </div>
        );

      case SURVEY_QUESTION_FIELD_TYPE.FILE_UPLOAD:
        return (
          <div className={styles.shalinaUploadCta}>
            {responses[questionKey]?.name &&
            responses[questionKey]?.image_base64 ? (
              <div className={styles.uploadedImageWrapper}>
                <span>{responses[questionKey]?.name}</span>
                <button
                  onClick={() => handleFileRemove(questionKey, questionTitle)}
                >
                  &times;
                </button>
              </div>
            ) : (
              <>
                <label className={styles.uploadWrapper}>
                  <UploadIcon /> {t(AppConstant.HOME_PAGE.UPLOAD_IMAGE_TEXT)}
                  <input
                    type='file'
                    accept='image/jpg, image/jpeg, image/png'
                    id='uploadFile'
                    onChange={(e) =>
                      handleFileChange(e, questionKey, questionTitle)
                    }
                  />
                </label>
                {responses[questionKey]?.error && (
                  <p>{responses[questionKey]?.error}</p>
                )}
                <span className={styles.fileNote}>
                  {t(AppConstant.COMMON.SUPPORT_UPLOAD_IMAGE_HELP_TEXT)}
                </span>
              </>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  const handleMultiselectChange = (
    key: string,
    value: any,
    questionTitle: string
  ) => {
    setSelectedValues((prev) => ({
      ...prev,
      [key]: value,
    }));

    const options = value?.map((val: any) => val.id);
    
    setResponses((prev) => ({
      ...prev,
      [key]: options,
    }));

    const existingIndex = analyticResponse?.findIndex(
      (obj: any) => obj?.questionKey === key
    );

    if (existingIndex !== -1) {
      analyticResponse[existingIndex] = {
        ...analyticResponse[existingIndex],
        answer: options?.toString(),
      };
      setAnalyticResponse(analyticResponse);
    } else {
      const obj = {
        questionKey: key,
        question: questionTitle,
        answer: options?.toString(),
      };
      setAnalyticResponse([...analyticResponse, obj]);
    }
  };

  const renderQuestions = (startIdx: number, endIdx: number) => {
    return (
      <div className={styles.quizList}>
        {quizData?.slice(startIdx, endIdx).map((question: any, index: any) => (
          <div className={styles.quizItem} key={index}>
            <h3 className={styles.question}>
              <span className={styles.questionNo}>{startIdx + index + 1}.</span>
              {question.tilte}
              {question?.fieldRequired ? (
                <span className={styles.astry}>*</span>
              ) : (
                <></>
              )}
            </h3>
            <div className={styles.ansOptions}>{renderOptions(question)}</div>
          </div>
        ))}
      </div>
    );
  };

  const handleSubmit = async () => {
    setSubmitLoader(true);
    const payload = {
      webform_id: surveyUniqueId,
      nid: surveyId,
      values: addDefaultsForQuestions(responses),
    };
    const response = await dispatch(submitQuizResponse(payload)).unwrap();
    if (
      response &&
      response?.status === 'SUCCESS' &&
      response?.statusCode === 200
    ) {
      if (surveyId && location?.state?.title && user) {
        const userObj = userDetails || user;
        const userInfo = getAnalyticsUserDetails(userObj);
        analyticResponse?.map((item: any) => {
          logEvent(
            appInsights,
            'custom',
            CUSTOM_ANALYTICS_EVENT_NAMES.USER_SURVEY_SUBMIT,
            {
              surveyTitle: location?.state?.title,
              surveyId: surveyId,
              surveyStartDateTime: moment(location?.state?.startDate).format(
                'DD MMM, YYYY hh:mm A'
              ),
              surveyEndDateTime: moment(location?.state?.endDate).format(
                'DD MMM, YYYY hh:mm A'
              ),
              question: item?.question,
              answer: item?.answer,
              userEmail: userInfo?.emailId,
              userMobile: userInfo?.mobile,
              userName: userInfo?.name,
              userQualification: userInfo?.qualification,
              userInterest: userInfo?.areaOfInterest,
              userSpecialization: userInfo?.specialization,
              userCountryOfPractice: userInfo?.countryOfPractice,
              userPreferredLanguage: userInfo?.preferredLanguage,
              platform: PlatformNames.WEB,
            }
          );
        });
      }
      setOpen(true);
    }
    setSubmitLoader(false);
  };

  const onClickToHome = () => {
    navigatePage(urls.homePage, {
      isDynamicUrl: false,
    });
  };

  const addDefaultsForQuestions = (res: any) => {
    var finalResponse = {};
    finalResponse = { ...responses };
    const obj = quizData?.map((item: any) => {
      if (item?.fieldType === SURVEY_QUESTION_FIELD_TYPE.MULTI_SELECT) {
        const questionKey = item.titleKey;

        finalResponse = {
          ...finalResponse,
          [questionKey]: responses[questionKey] ? responses[questionKey] : [],
        };
      } else if (item?.fieldType === SURVEY_QUESTION_FIELD_TYPE.CHECKBOXES) {
        const questionKey = item.titleKey;
        finalResponse = {
          ...finalResponse,
          [questionKey]: responses[questionKey] ? responses[questionKey] : [],
        };
      } else {
        const questionKey = item.titleKey;
        finalResponse = {
          ...finalResponse,
          [questionKey]: responses[questionKey] ? responses[questionKey] : '',
        };
      }
    });

    return finalResponse;
  };

  const allRequiredQuestionsAnswered = () => {
    if (quizData?.length > 0) {
      const splitQuizData =
        activeStep === 1
          ? quizData?.slice(0, questionsPerPage)
          : quizData?.slice(
              questionsPerPage * (activeStep - 1),
              questionsPerPage * activeStep
            );
      // Assuming quizData contains the questions and each question has a 'fieldRequired' property
      return splitQuizData?.every((question: any) => {
        // Check if the question is required and if it has been answered
        return question?.fieldRequired && !responses[question?.titleKey]
          ? false
          : true;
      });
    } else {
      return false;
    }
  };

  return (
    <>
      <div className={styles.quizPage}>
        {isQuizDetailsLoading && <BarLoader />}
        {!isQuizDetailsLoading && (
          <div className='cust-container'>
            <BreadCrumb isSurvey={true} surveyTitle={location?.state?.title} />
          </div>
        )}
        {isQuizDetailsLoading ? (
          // <CircularLoader size={50} thickness={4} />
          ''
        ) : (
          <div className='cust-container'>
            <div className={styles.quizWrapper}>
              <div className={styles.quizNav}>
                {Array.from({ length: totalSteps }, (_, index) => (
                  <span
                    key={index}
                    className={`${
                      activeStep >= index + 1 && `${styles.active}`
                    }`}
                  ></span>
                ))}
              </div>
              <h2 className={styles.quizTitle}>{location?.state?.title}</h2>
              {renderQuestions(
                (activeStep - 1) * questionsPerPage,
                activeStep * questionsPerPage
              )}
              <div className={styles.ctaWrapper}>
                {activeStep > 1 && (
                  <div className={styles.ctaCol}>
                    <button className={styles.back} onClick={handleBack}>
                      {t(AppConstant.HOME_PAGE.BACK_CTA)}
                    </button>
                  </div>
                )}
                {activeStep < totalSteps && (
                  <div className={styles.ctaCol}>
                    <button
                      onClick={handleNext}
                      disabled={!allRequiredQuestionsAnswered()}
                    >
                      {t(AppConstant.AUTHENTICATION_PAGE.NEXT_CTA)}
                    </button>
                  </div>
                )}
                {activeStep === totalSteps && (
                  <div className={styles.ctaCol}>
                    <button
                      onClick={handleSubmit}
                      disabled={!allRequiredQuestionsAnswered()}
                    >
                      {t(AppConstant.HOME_PAGE.APPLY_CTA)}
                      <ButtonLoader loader={submitLoader} />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <div className={styles.thanksModal}>
          <img src={ImageConstant.SOURCE.successStar} alt='thank you img' />
          <h4 className={styles.title}>{t(AppConstant.COMMON.THANK_YOU)}</h4>
          <p>{t(AppConstant.COMMON.SURVEY_SUCCESS)}</p>
          <button onClick={onClickToHome}>
            {t(AppConstant.COMMON.RETURN_HOME_CTA)}
          </button>
        </div>
      </Dialog>
    </>
  );
};
