export const isWhiteSpaceExist = (text: string) => {
  const whiteSpaceExist = /\s/g.test(text);
  // if text = '' -> return false, text = ' ' -> true
  return whiteSpaceExist;
};

// For fist time error should not be shown
export const commonFirstEmptyValidation = (text: string) => {
  // if text = '' -> return true, text = ' ' -> false
  return !isWhiteSpaceExist(text) && text?.length === 0;
};

export const alphabetsOnly = (text: string) => {
  const regMatch = text && /^[a-zA-Z]*$/.test(text);
  return regMatch;
};

export const numberOnly = (text: string) => {
  const regMatch = text && /^[0-9]*$/.test(text);
  return regMatch;
};

export const isStringEmpty = (text: string) => {
  const inputText = isWhiteSpaceExist(text) ? text?.trim() : text;
  return inputText.length === 0;
};

export const isArrayEmpty = (array: []) => {
  const arrayLength = array?.length;
  return arrayLength === 0 || false;
};

export const numberValidation = (text: string) => {
  const regMatch = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(
    text
  );
  return regMatch;
};

export const nameValidation = (text: string) => {
  const regMatch = /^[^\d]*$/.test(text);
  return regMatch;
};

export const inputTextValidation = (text: string) => {
  // if (commonFirstEmptyValidation(text)) {
  //     return false
  // }
  if (!nameValidation(text)) {
    return true;
  } else {
    return false;
  }
};

export const otpValidation = (text: string) => {
  if (commonFirstEmptyValidation(text)) {
    return false;
  }
  if (text && !/^[0-9]{6}$/.test(text)) {
    return true;
  }
  return false;
};

export const mobileValidation = (text: string, mobileLength?: string) => {
  if (commonFirstEmptyValidation(text)) {
    return false;
  }
  if (
    (mobileLength && parseInt(mobileLength) !== text.length) ||
    !numberOnly(text) ||
    isStringEmpty(text)
  ) {
    return true;
  }
  return false;
};

export const mobileStartZeroValidation = (text: string) => {
  if (text.startsWith('0')) {
    return true;
  }
  return false;
};

export const countryCodeValidation = (text: string) => {
  if (commonFirstEmptyValidation(text)) {
    return false;
  }
  if (isStringEmpty(text)) {
    return true;
  }
  return false;
};

export const emailValidation = (text: string) => {
  if (commonFirstEmptyValidation(text)) {
    return false;
  }
  const regMatch = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(text); // eslint-disable-line no-useless-escape
  if (!regMatch || isStringEmpty(text)) {
    return true;
  }
  return false;
};

export const dropDownValidation = (text: string) => {
  if (commonFirstEmptyValidation(text)) {
    return false;
  }
  if (isStringEmpty(text)) {
    return true;
  }
  return false;
};

export const radioGroupValidation = (text: string) => {
  if (commonFirstEmptyValidation(text)) {
    return false;
  }
  if (isStringEmpty(text)) {
    return true;
  }
  return false;
};

export const registrationCodeValidation = (text: string) => {
  if (commonFirstEmptyValidation(text)) {
    return false;
  }
  if (isStringEmpty(text)) {
    return true;
  }
  return false;
};
