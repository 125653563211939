import { useState, useEffect } from 'react';
import {
  useAppDispatch,
  updateUserDetails,
  AppConstant,
  translate,
  useAppSelector,
  getDeactivateReasons
} from '@shalina-app/shared';
import { Dialog, DialogContent } from '@mui/material';

import { Dropdown } from '../../../../components';
import { AccountSettingsProps } from './accountSettings.interface';
import { useNavigatePageUtils } from '../../../../utils';
import { URLConstant } from '../../../../routes';
import { userSignOut } from '../../../../utils';
import styles from './accountSettings.module.scss';
import { ButtonLoader } from '../../../communities';

export const AccountSettings: React.FC<AccountSettingsProps> = (
  accountSettingProps: AccountSettingsProps
) => {
  const [selectedReason, setSelectedReason] = useState('');
  const dispatch = useAppDispatch();
  const navigatePageUtils = useNavigatePageUtils();
  const { urls } = URLConstant;
  const t = translate();

  const deactivateReason = useAppSelector(getDeactivateReasons);

  const [reload, setReload] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setReload(true);
  }, []);

  useEffect(() => {
    if (reload) {
      // dispatch(getDeactivateReason());
      setReload(false);
    }
  }, [reload]);

  const handleReasonChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedReason(e?.target?.value);
  };

  const onDeactivate = async () => {
    setModalOpen(true);
  };

  const onCancelDeactivate = () => {
    const navigateDetails = {
      isDynamicUrl: false
    };
    navigatePageUtils(urls.homePage, navigateDetails);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const payload = {
      isArchive: true,
      archiveReason: selectedReason || null
    };

    await dispatch(updateUserDetails(payload));
    setIsLoading(false);
    setModalOpen(false);
    await userSignOut();
    const navigateDetails = {
      isDynamicUrl: false
    };
    navigatePageUtils(urls.homePage, navigateDetails);
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div>
          <h4 className={styles.title}>
            {t(AppConstant.HEADER.DEACTIVATE_ACCOUNT_TEXT)}
          </h4>
          <div className={styles.infoText}>
            <svg
              width='16'
              height='13'
              viewBox='0 0 16 13'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M0.667969 13.0007L8.0013 0.333984L15.3346 13.0007H0.667969ZM2.96797 11.6673H13.0346L8.0013 3.00065L2.96797 11.6673ZM8.0013 11.0007C8.19019 11.0007 8.34852 10.9368 8.4763 10.809C8.60408 10.6812 8.66797 10.5229 8.66797 10.334C8.66797 10.1451 8.60408 9.98676 8.4763 9.85898C8.34852 9.73121 8.19019 9.66732 8.0013 9.66732C7.81241 9.66732 7.65408 9.73121 7.5263 9.85898C7.39852 9.98676 7.33464 10.1451 7.33464 10.334C7.33464 10.5229 7.39852 10.6812 7.5263 10.809C7.65408 10.9368 7.81241 11.0007 8.0013 11.0007ZM7.33464 9.00065H8.66797V5.66732H7.33464V9.00065Z'
                fill='#202020'
              />
            </svg>
            {t(AppConstant.PROFILE.DELETE_WARN_TEXT)}
          </div>
          <div>
            <Dropdown
              defaultValue=''
              value={selectedReason}
              dropdownId='grouped-select'
              dropdownVariant='outlined'
              labelText={t(AppConstant.PROFILE.REASON_TEXT)}
              dropdownOptions={deactivateReason}
              handleDropDownChange={(e: any) => handleReasonChange(e)}
              isError={false}
              errorMessage={''}
              placeholder={t(AppConstant.PROFILE.REASON_TEXT)}
            />
          </div>
        </div>
        <div className={styles.accountCtas}>
          <button onClick={onCancelDeactivate}>
            {t(AppConstant.PROFILE.KEEP_ACTIVE_TEXT)}
          </button>
          <button className={styles.deactivate} onClick={onDeactivate}>
            {t(AppConstant.PROFILE.DEACTIVATE_TEXT)}
          </button>
        </div>
      </div>

      <Dialog
        open={modalOpen}
        onClose={handleModalClose}
        className={styles.cautionModal}
      >
        <DialogContent>
          <div className={styles.modalWrapper}>
            <button onClick={handleModalClose} className={styles.closeCta}>
              &times;
            </button>
            <h4 className={styles.modalTitle}>
              {t(AppConstant.PROFILE.CAUTION_TEXT)}
            </h4>
            <p>{t(AppConstant.PROFILE.ACCOUNT_WARN)}</p>
            <p>{t(AppConstant.PROFILE.DELETE_MSG_TEXT)}</p>
            <p>{t(AppConstant.PROFILE.DELETE_MSG_CONTINUE)}</p>
            <div className={styles.modalCta}>
              <button onClick={handleModalClose}>
                {t(AppConstant.PROFILE.CANCEL_CTA)}
              </button>
              <button
                onClick={handleSubmit}
                className={styles.sureCta}
                disabled={isLoading}
              >
                {t(AppConstant.PROFILE.YES_DEACTIVATE_TEXT)}
                <ButtonLoader loader={isLoading} />
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
