import { ImageConstant } from '@shalina-app/shared';
import { useLocation } from 'react-router-dom';

import { URLConstant } from '../../../../routes';
import { useNavigatePageUtils } from '../../../../utils';
import { NotificationPropsType } from './notification.interface';
import styles from './notification.module.scss';

export const Notification: React.FC<NotificationPropsType> = ({
  isLoggedIn,
  totalCount,
  handleSignUpFlow,
  isUnreadNotification
}) => {
  const { BellIcon, BellUnReadIcon } = ImageConstant.SVG_ICONS;
  const { urls } = URLConstant;
  const navigatePageUtils = useNavigatePageUtils();
  const location = useLocation();
  const pathName = location?.pathname;

  const handleNotificationClick = () => {
    if (isLoggedIn) {
      const navigateUrl = urls.notificationPage;
      navigatePageUtils(navigateUrl, {
        isDynamicUrl: false,
        stateParams: { totalCount: totalCount }
      });
    } else {
      handleSignUpFlow();
    }
  };

  return (
    <div
      className={`${styles.notification} ${
        pathName === urls.notificationPage && styles.active
      }`}
      onClick={handleNotificationClick}
    >
      {isUnreadNotification ? <BellUnReadIcon /> : <BellIcon />}
    </div>
  );
};
