import React, { useState } from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { AppConstant, translate } from '@shalina-app/shared';

import { LearningCenterTabsItemsProps } from './learningCenterTab.interface';
import styles from './learningCenterTab.module.scss';

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
};

export const LearningCenterTabs: React.FC<LearningCenterTabsItemsProps> = ({
  handleTabChange
}) => {
  const t = translate();
  const [value, setValue] = useState('');
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    if (handleTabChange) {
      handleTabChange(newValue);
    }
  };

  return (
    <>
      <div className={styles.shalinaTabs}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='Detail Tabs'
          className={styles.custTabs}
        >
          <Tab
            label={t(AppConstant.KNOWLEDGE_HUB_PAGE.ALL_TAB_TEXT)}
            value={''}
            {...a11yProps(0)}
          />
          <Tab
            label={t(AppConstant.KNOWLEDGE_HUB_PAGE.UPCOMING_TAB_TEXT)}
            value={'publish'}
            {...a11yProps(1)}
          />
          <Tab
            label={t(AppConstant.KNOWLEDGE_HUB_PAGE.ACTIVE_TAB_TEXT)}
            value={'active'}
            {...a11yProps(2)}
          />
          <Tab
            label={t(AppConstant.KNOWLEDGE_HUB_PAGE.PAST_TAB_TEXT)}
            value={'past'}
            {...a11yProps(3)}
          />
        </Tabs>
      </div>
    </>
  );
};
