import { useEffect, useState } from 'react';
import {
  ANALYTICS_PAGE_NAMES,
  API_CATEGORY_TYPES,
  AppConstant,
  CONTENT_TYPE_ID,
  continueWhereLeft,
  convertUTCDate,
  convertUTCTime,
  CUSTOM_ANALYTICS_EVENT_NAMES,
  EVENTS_TAB_API_PAYLOAD_ENUM,
  fetchContentListByType,
  FILTER_BY_TABS_ENUM,
  getAnalyticPageNameByCategory,
  getAnalyticsUserDetails,
  getContinueWhereLeft,
  getFilterCategoriesOptionsValues,
  getLoggedInUserInfo,
  getNewlyAddedContent,
  getOnlyDate,
  getOnlyTime,
  getRecommendedForYou,
  getTrendingNow,
  getViewAllContentById,
  getViewAllListData,
  ImageConstant,
  logEvent,
  medicalNews,
  newlyAdded,
  PageNames,
  PlatformNames,
  recommendedForYou,
  SortFilterOptions,
  translate,
  trendingNow,
  updateLearningCenterViewAllList,
  useAppDispatch,
  useAppSelector,
  viewDurationInHours,
} from '@shalina-app/shared';
import { useLocation, useParams } from 'react-router-dom';

import {
  BarLoader,
  BreadCrumb,
  ButtonComponent,
  Card,
  ContentListingProps,
  InfiniteScrollComponent,
  LearningCenterTabs,
  QuickLinkSection,
  SortFilter,
} from '../..';
import {
  DEFAULT_SKIP,
  DEFAULT_TAKE,
  homeListingRoutes,
  pageNames,
} from '../../../app.constants';
import { appInsights } from '../../../applicationInsight';
import { URLConstant } from '../../../routes';
import {
  convertToSpecialties,
  getCategoryByPage,
  getImageSrc,
  getPageNameByCategory,
  retrieveData,
  STORAGE_CONSTANTS,
  useNavigatePageUtils,
} from '../../../utils';
import styles from './contentListing.module.scss';

export const ListingPage: React.FC<ContentListingProps> = () => {
  const t = translate();
  const { urls } = URLConstant;
  const navigatePage = useNavigatePageUtils();
  const params = useParams();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const isLoggedIn: boolean = retrieveData(STORAGE_CONSTANTS.IS_LOGGED_IN);
  const userDetails = useAppSelector(getLoggedInUserInfo);

  const sectionTitle = params?.sectionTitle;
  const contentTypeId: any = params?.contentTypeId;
  const pageName: any = params?.pageName;
  const category = getCategoryByPage(pageName);

  const parentSelectedAreaOfInterest =
    location?.state?.parentSelectedAreaOfInterest;

  const userSelectedAreaOfInterest =
    location?.state?.userSelectedAreaOfInterest;
  const parentCategory = location?.state?.parentCategory;
  const filterRetain = location?.state?.filterRetain;

  const user = retrieveData(STORAGE_CONSTANTS.USER);

  const masterContentTypeData = useAppSelector(
    getFilterCategoriesOptionsValues
  );

  const [resetFilterAndSort, setResetFilterAndSort] = useState(false);
  const [skip, setSkip] = useState(0);
  const [dataFromStore, setDataFromStore] = useState(true);
  const [tabChangeDone, setTabChangeDone] = useState(false);
  const [isLoader, setIsLoader] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [scrollLoader, setScrollLoader] = useState(false);
  const [contentData, setContentData] = useState<any>([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [reload, setReload] = useState(false);
  const [filterCall, setFilterCall] = useState(false);
  const [contentType, setContentType] = useState(false);
  const [listOfDiscoverMore, setListOfDiscoverMore] = useState<any>(null);
  const [eventType, setEventType] = useState('');
  const [filterOptions, setFilterOptions] = useState<SortFilterOptions>({
    category: category ? category : '',
    contentType: '',
    interest: '',
    sort: '',
    speciality: '',
    skip: 0,
    take: DEFAULT_TAKE,
    event_type: eventType,
  });
  const [uniqueContentTypeId, setUniqueContentTypeId] = useState('');
  const [eventDataForUpcoming, setEventDataForUpcoming] = useState([]);
  const [eventDataForPast, setEventDataForPast] = useState([]);
  const [eventDataForActive, setEventDataForActive] = useState([]);
  const [eventDataForPublish, setEventDataForPublish] = useState([]);
  const [selectedAreaOfInterest, setSelectedAreaOfInterest] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    setReload(true);
  }, []);

  useEffect(() => {
    if (parentSelectedAreaOfInterest) {
      setFilterOptions((prevOptions) => ({
        ...prevOptions,
        interest: parentSelectedAreaOfInterest,
      }));
      setSelectedAreaOfInterest(parentSelectedAreaOfInterest);
    }
  }, [parentSelectedAreaOfInterest, parentCategory]);

  useEffect(() => {
    if (userSelectedAreaOfInterest) {
      setSelectedAreaOfInterest(userSelectedAreaOfInterest);
    }
  }, [userSelectedAreaOfInterest]);

  useEffect(() => {
    if (reload) {
      loadApi();
    }
  }, [reload]);

  useEffect(() => {
    if (contentType) {
      logEvent(
        appInsights,
        'pageView',
        `${contentType} ${ANALYTICS_PAGE_NAMES.LISTING}`
      );
      if (isLoggedIn && contentType && user) {
        const userObj = userDetails || user;
        const userInfo = getAnalyticsUserDetails(userObj);
        logEvent(
          appInsights,
          'custom',
          CUSTOM_ANALYTICS_EVENT_NAMES.USER_MOST_PAGE_VIEWED,
          {
            userEmail: userInfo?.emailId,
            userMobile: userInfo?.mobile,
            userName: userInfo?.name,
            userQualification: userInfo?.qualification,
            userInterest: userInfo?.areaOfInterest,
            userSpecialization: userInfo?.specialization,
            userCountryOfPractice: userInfo?.countryOfPractice,
            userPreferredLanguage: userInfo?.preferredLanguage,
            category: contentType || '',
            moduleName: getAnalyticPageNameByCategory(category) || '',
            pageName: `${contentType} ${PageNames?.LISTING}`,
            platform: PlatformNames.WEB,
          }
        );
      }
    }
  }, [contentType]);

  const continueWhereLeftContent = useAppSelector(getContinueWhereLeft);
  const recommendedForYouContent = useAppSelector(getRecommendedForYou);
  const trendingNowContent = useAppSelector(getTrendingNow);
  const newlyAddedContent = useAppSelector(getNewlyAddedContent);
  const viewAllListData = useAppSelector(getViewAllListData);

  const eventTypeId = filterOptions?.event_type || '';
  const data = useAppSelector(getViewAllContentById(contentTypeId, category));

  useEffect(() => {
    // setContentData([]);
    setSkip(0);
    setFilterOptions((prev) => ({
      ...prev,
      skip: 0,
      take: DEFAULT_TAKE,
      event_type: eventType,
    }));
    // setIsLoader(true);
    setReload(true);
  }, [eventType]);

  useEffect(() => {
    if (masterContentTypeData && masterContentTypeData?.length > 0) {
      fetchDataWithMasterContent();
    }
  }, [masterContentTypeData, reload]);

  const fetchDataWithMasterContent = () => {
    const contentTypeName: any = masterContentTypeData?.find(
      (item: any) => item?.value === contentTypeId
    )?.label;
    const categoryByName = masterContentTypeData?.filter(
      (value: any) => value?.category === category
    );
    const discoverList = categoryByName?.filter(
      (item: any) => item?.value !== contentTypeId
    );

    const uniqueContentTypeId: any = masterContentTypeData?.find(
      (item: any) => item?.value === contentTypeId
    )?.unique_id;

    if (uniqueContentTypeId) {
      setUniqueContentTypeId(uniqueContentTypeId);
    }

    if (contentTypeName) {
      setContentType(contentTypeName);
    } else {
      setHeading();
    }

    if (category === '' || !category) {
      setListOfDiscoverMore(masterContentTypeData);
    } else {
      setListOfDiscoverMore(discoverList);
    }
  };

  const loadApi = () => {
    if (sectionTitle) {
      fetchData(sectionTitle);
    } else if (contentTypeId) {
      fetchDataWithId(contentTypeId);
    }
  };

  const fetchDataWithId = async (contentTypeId: string) => {
    const paramter = {
      contentId: contentTypeId,
      filterOptions:
        uniqueContentTypeId === CONTENT_TYPE_ID.WEBINARS
          ? {
              ...filterOptions,
              event_type: eventType,
            }
          : filterOptions,
    };
    const response = await dispatch(
      fetchContentListByType(paramter as any)
    ).unwrap();

    if (
      response &&
      response?.status === 'SUCCESS' &&
      response?.statusCode === 200 &&
      response?.data
    ) {
      if (category === API_CATEGORY_TYPES.LEARNING_CENTER && eventType === '') {
        dispatch(updateLearningCenterViewAllList(response?.data));
      }

      setPagination(response);
    } else {
      setIsLoader(false);
      setTabChangeDone(false);
      setDataFromStore(false);
      setReload(false);
    }
  };

  const fetchRemainingData = async (contentTypeId: string) => {
    if (uniqueContentTypeId === CONTENT_TYPE_ID.WEBINARS) {
      // Map through the array and make multiple Axios requests asynchronously
      const requests = EVENTS_TAB_API_PAYLOAD_ENUM?.map(async (item: any) => {
        const paramter = {
          contentId: contentTypeId,
          filterOptions: {
            ...filterOptions,
            event_type: item?.value,
          },
        };
        const response = await dispatch(
          fetchContentListByType(paramter as any)
        ).unwrap();
        return {
          label: item?.label,
          value: item?.value,
          data: response.data,
        };
      });
      // Wait for all requests to finish using Promise.all
      // const responses = await Promise.all(requests);
      // dispatch(updateLearningCenterViewAllList(responses));
      // setDataFromStore(false);
    }
  };

  const fetchData = async (sectionTitle: string) => {
    let response: any;

    switch (sectionTitle) {
      case homeListingRoutes.CONTINUE_WHERE_YOU_LEFT_OFF:
        response = await dispatch(continueWhereLeft(filterOptions)).unwrap();
        break;
      case homeListingRoutes.RECOMMENDED_FOR_YOU:
        response = await dispatch(recommendedForYou(filterOptions)).unwrap();
        break;
      case homeListingRoutes.TRENDING_NOW:
        response = await dispatch(trendingNow(filterOptions)).unwrap();
        break;
      case homeListingRoutes.NEWLY_ADDED:
        response = await dispatch(newlyAdded(filterOptions)).unwrap();
        break;
      case homeListingRoutes.MEDICAL_NEWS:
        response = await dispatch(medicalNews(filterOptions)).unwrap();
        break;
    }

    if (
      response &&
      response?.status === 'SUCCESS' &&
      response?.statusCode === 200 &&
      response?.data
    ) {
      setPagination(response);
    } else {
      setIsLoader(false);
      setDataFromStore(false);
      setReload(false);
    }
  };

  const setPagination = (response: any) => {
    setIsLoader(false);
    setDataFromStore(false);
    // if (uniqueContentTypeId === CONTENT_TYPE_ID.WEBINARS || filterCall) {
    //   setDataFromStore(false);
    // }
    setTabChangeDone(false);
    const data = response?.data;
    const totalCount = response?.count;
    setTotalDataCount(totalCount);

    if (reload) {
      if (contentData?.length >= response?.count) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      setReload(false);
    }
    if (filterCall || tabChangeDone) {
      setFilterCall(false);
      setContentData(data);
    } else {
      setContentData((prevOptions: any) => [...prevOptions, ...data]);
    }
  };

  const setHeading = () => {
    let heading: any = '';
    switch (sectionTitle) {
      case homeListingRoutes.CONTINUE_WHERE_YOU_LEFT_OFF:
        heading = t(AppConstant.HOME_PAGE.CONTINUE_WHERE_LEFT_OFF);
        break;
      case homeListingRoutes.RECOMMENDED_FOR_YOU:
        heading = t(AppConstant.HOME_PAGE.RECOMMENDED_FOR_YOU);
        break;
      case homeListingRoutes.TRENDING_NOW:
        heading = t(AppConstant.HOME_PAGE.TRENDING_NOW);
        break;
      case homeListingRoutes.NEWLY_ADDED:
        heading = t(AppConstant.HOME_PAGE.NEWLY_ADDED);
        break;
      case homeListingRoutes.MEDICAL_NEWS:
        heading = t(AppConstant.HOME_PAGE.MEDICAL_NEWS);
        break;
      case homeListingRoutes.EXPLORE_COMMUNITIES:
        heading = t(AppConstant.HOME_PAGE.EXPLORE_COMMUNITIES);
        break;
    }

    setContentType(heading);
  };

  const onApplyButtonClick = (
    selectedContentType: Array<string>,
    selectedSpecialization: Array<string>
  ) => {
    setSkip(0);
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      skip: 0,
      speciality: selectedSpecialization.toString(),
      contentType: selectedContentType.toString(),
    }));
    setFilterCall(true);
    setIsLoader(true);
    setReload(true);
  };

  const onSortChange = (value: string) => {
    setSkip(0);
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      sort: value,
      skip: 0,
    }));
    setFilterCall(true);
    setIsLoader(true);
    setReload(true);
  };

  const onAreaOfInterest = (value: string[]) => {
    setSkip(0);
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      interest: value.toString(),
      skip: 0,
    }));
    setIsLoader(true);
    setFilterCall(true);
    setReload(true);
  };

  const resetFilter = () => {
    setSkip(0);
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      contentType: '',
      speciality: '',
      skip: 0,
      take: DEFAULT_TAKE,
    }));
    setFilterCall(true);
    setIsLoader(true);
    setReload(true);
  };

  const fetchMoreData = () => {
    setScrollLoader(true);
    if (contentData?.length >= totalDataCount) {
      setHasMore(false);
      return;
    }
    setSkip(skip + DEFAULT_SKIP);
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      skip: skip + DEFAULT_SKIP,
      take: DEFAULT_TAKE,
    }));
    setReload(true);
  };

  const handleBrowseButton = (item: any) => {
    const page = getPageNameByCategory(item?.category);
    if (item?.value && page) {
      setContentData([]);
      setSkip(0);
      setFilterOptions((prev) => ({
        ...prev,
        skip: 0,
        take: DEFAULT_TAKE,
      }));
      setDataFromStore(true);
      // setIsLoader(true);
      const navigateDetails = {
        stateParams: {
          parentSelectedAreaOfInterest: filterOptions?.interest,
          parentCategory: item?.category,
          filterRetain: sectionTitle ? false : true,
        },
        isDynamicUrl: true,
        dynamicUrlOptions: {
          contentTypeId: item?.value,
          pageName: page,
        },
      };
      navigatePage(urls.listingPageWithModule, navigateDetails);
      setReload(true);
    }
  };

  const handleTabChange = (tabValue: string) => {
    setDataFromStore(true);
    setTabChangeDone(true);
    setEventType(tabValue);
    // setResetFilterAndSort(true);
  };

  useEffect(() => {
    if (contentData?.length >= totalDataCount) {
      setHasMore(false);
    }
  }, [contentData]);

  useEffect(() => {
    if (data && uniqueContentTypeId === CONTENT_TYPE_ID.WEBINARS) {
      const initialDataForEventForPast = data?.filter(
        (item: any) => item?.event_data?.event_status === 'past'
      );
      const initialDataForEventForActive = data?.filter(
        (item: any) => item?.event_data?.event_status === 'active'
      );
      const initialDataForEventForPublish = data?.filter(
        (item: any) => item?.event_data?.event_status === 'publish'
      );
      setEventDataForPast(initialDataForEventForPast);
      setEventDataForActive(initialDataForEventForActive);
      setEventDataForPublish(initialDataForEventForPublish);
    }
  }, [data]);

  const getInitialDataFromStore = () => {
    let initialData = [];
    const initialDataForEventForPast = data?.filter(
      (item: any) => item?.event_data?.event_status === 'past'
    );
    const initialDataForEventForActive = data?.filter(
      (item: any) => item?.event_data?.event_status === 'active'
    );
    const initialDataForEventForPublish = data?.filter(
      (item: any) => item?.event_data?.event_status === 'publish'
    );

    if (sectionTitle) {
      switch (sectionTitle) {
        case homeListingRoutes.CONTINUE_WHERE_YOU_LEFT_OFF:
          initialData = continueWhereLeftContent;
          return initialData;
        case homeListingRoutes.RECOMMENDED_FOR_YOU:
          initialData = recommendedForYouContent;
          return initialData;
        case homeListingRoutes.TRENDING_NOW:
          initialData = trendingNowContent;
          return initialData;
        case homeListingRoutes.NEWLY_ADDED:
          initialData = newlyAddedContent;
          return initialData;
        default: {
          initialData = [];
          return null;
        }
      }
    } else if (contentTypeId) {
      if (uniqueContentTypeId === CONTENT_TYPE_ID.WEBINARS) {
        switch (eventType) {
          case 'past':
            return eventDataForPast;
          case 'active':
            return eventDataForActive;
          case 'publish':
            return eventDataForPublish;
          default:
            return data;
        }
      } else {
        return data;
      }
    }
  };

  const content = dataFromStore ? getInitialDataFromStore() : contentData;
  const loader = !filterCall ? isLoader : false;

  return (
    <>
      <div className={styles.listingPage}>
        <div className={styles.listingTop}>
          <div className='cust-container'>
            {contentType ? (
              <BreadCrumb hasDark={true} />
            ) : (
              <div className={`${styles.BreadCrumbHeight}`}></div>
            )}
            <h1 className={styles.pageTitle}>
              {contentType}
              {totalDataCount && totalDataCount !== 0 && content?.length > 0 ? (
                <span>
                  {' '}
                  - {t(AppConstant.COMMON.SHOWING)}{' '}
                  <strong>{content.length} </strong>
                  {t(AppConstant.COMMON.OF_OVER)} {totalDataCount} {contentType}
                </span>
              ) : null}
            </h1>

            {uniqueContentTypeId === CONTENT_TYPE_ID.WEBINARS && (
              <LearningCenterTabs handleTabChange={handleTabChange} />
            )}

            <div className={styles.listingFilter}>
              <SortFilter
                isDark={false}
                categoryType={category}
                handleApplyFilter={onApplyButtonClick}
                handleSortChange={onSortChange}
                handleAreaOfInterest={onAreaOfInterest}
                handleResetFilter={resetFilter}
                resetSortAndFilter={resetFilterAndSort}
                noContentType={!sectionTitle && contentTypeId ? true : false}
                parentSelectedAreaOfInterest={selectedAreaOfInterest}
                parentCategory={parentCategory}
                filterRetain={filterRetain}
                tabsEnum={[
                  FILTER_BY_TABS_ENUM.SPECIALITY,
                  FILTER_BY_TABS_ENUM.CONTENT_TYPE,
                ]}
              />
            </div>
          </div>
        </div>
        {(filterCall || reload) && <BarLoader />}
        <div className='cust-container'>
          <div className={styles.listingRow}>
            <div className={styles.listingCol8}>
              {/* {loader ? (
                <>
                  <CircularLoader size={50} thickness={4} />
                  <div className={styles.listHeight}></div>
                </>
              ) :  */}
              {content && content.length > 0 ? (
                <InfiniteScrollComponent
                  dataLength={content.length}
                  fetchMoreData={fetchMoreData}
                  hasMore={hasMore}
                  loader={scrollLoader && content?.length >= DEFAULT_TAKE}
                  scrollThreshold={'1000px'}
                >
                  <div className={styles.listingCards}>
                    {content?.map((item: any) => (
                      <div className={styles.listingCard}>
                        <Card
                          id={item?.nid}
                          contentType={item?.field_select_article_type || ''}
                          contentTypeId={
                            item?.field_select_article_type_id || ''
                          }
                          title={item?.title || ''}
                          speciality={convertToSpecialties(
                            item?.field_area_of_interest || ''
                          )}
                          date={
                            item?.field_unique_code ===
                            CONTENT_TYPE_ID?.WEBINARS
                              ? convertUTCDate(item?.field_starts_on)
                              : getOnlyDate(item?.field_authored_on)
                          }
                          time={
                            item?.field_unique_code ===
                            CONTENT_TYPE_ID?.WEBINARS
                              ? convertUTCTime(item?.field_starts_on)
                              : getOnlyTime(item?.field_authored_on)
                          }
                          publishedName={item?.field_authored_by || ''}
                          viewsCount={item?.view_count || ''}
                          duration={viewDurationInHours(
                            item?.field_duration || ''
                          )}
                          thumbnailImage={getImageSrc(
                            item?.field_thumbnail_image
                          )}
                          pageName={pageName}
                          pageRoute={''}
                          middleRoute={''}
                          isBookmarked={item?.is_bookmarked}
                          mostViewed={item?.most_viewed}
                          // handleBookmark={() => handleBookmark(sectionTitle)}
                          hasFullWidth={true}
                          eventId={item?.event_id}
                          uniqueContentTypeId={item?.field_unique_code}
                          category={item?.type}
                          tags={item?.field_tags}
                        />
                      </div>
                    ))}
                  </div>
                </InfiniteScrollComponent>
              ) : !reload && eventType === 'active' ? (
                <div className={styles.contentNotFound}>
                  <div className={styles.notFoundImg}>
                    <img
                      src={ImageConstant.SOURCE.contentNotFoundImg}
                      alt='not found'
                    />
                  </div>
                  <div className={styles.notFoundText}>
                    <h3>{t(AppConstant.COMMON.NO_ACTIVE_WEBINARS_MESSAGE)}</h3>
                    <p>
                      {t(AppConstant.COMMON.NO_ACTIVE_WEBINARS_SUB_MESSAGE)}
                    </p>
                  </div>
                </div>
              ) : !reload && eventType === 'publish' ? (
                <div className={styles.contentNotFound}>
                  <div className={styles.notFoundImg}>
                    <img
                      src={ImageConstant.SOURCE.contentNotFoundImg}
                      alt='not found'
                    />
                  </div>
                  <div className={styles.notFoundText}>
                    <h3>
                      {t(AppConstant.COMMON.NO_UPCOMING_WEBINARS_MESSAGE)}
                    </h3>
                    <p>
                      {t(AppConstant.COMMON.NO_UPCOMING_WEBINARS_SUB_MESSAGE)}
                    </p>
                  </div>
                </div>
              ) : (
                !reload && (
                  <div className={styles.contentNotFound}>
                    <div className={styles.notFoundImg}>
                      <img
                        src={ImageConstant.SOURCE.contentNotFoundImg}
                        alt='not found'
                      />
                    </div>
                    <div className={styles.notFoundText}>
                      <h3>{`${t(AppConstant.HOME_PAGE.NO_TEXT)} ${t(
                        AppConstant.HOME_PAGE.DATA_TEXT
                      )} ${t(AppConstant.HOME_PAGE.FOUND_TEXT)}`}</h3>
                      <p>{t(AppConstant.HOME_PAGE.NO_DATA_FOUND_MESSAGE)}</p>
                    </div>
                  </div>
                )
              )}
            </div>

            <div className={styles.listingCol4}>
              <QuickLinkSection />
              {listOfDiscoverMore && listOfDiscoverMore?.length > 0 && (
                <div className={styles.readingSec}>
                  <h4 className={styles.title}>{`${t(
                    AppConstant.KNOWLEDGE_HUB_PAGE.DISCOVER_MORE
                  )}`}</h4>
                  {listOfDiscoverMore &&
                    listOfDiscoverMore?.map((item: any, index: number) => (
                      <>
                        <div className={styles.readingCard}>
                          <div className={styles.readingCardTitle}>
                            <span className={styles.icon}>
                              {item?.icon ? (
                                <img
                                  src={getImageSrc(item?.icon)}
                                  alt='content icon'
                                />
                              ) : null}
                            </span>
                            <div className={styles.msg}>
                              {`${t(
                                AppConstant.KNOWLEDGE_HUB_PAGE
                                  .DYNAMIC_KH_LISTING_TITLE_PART_1
                              )} ${item?.label} ${t(
                                AppConstant.KNOWLEDGE_HUB_PAGE
                                  .DYNAMIC_KH_LISTING_TITLE_PART_2
                              )}`}

                              <span>
                                {getPageNameByCategory(item?.category) ===
                                pageNames.LearningCenter
                                  ? t(
                                      AppConstant.KNOWLEDGE_HUB_PAGE
                                        .DISCOVER_LEARNING_CENTER_SUB_SENTENCE
                                    )
                                  : getPageNameByCategory(item?.category) ===
                                    pageNames.ShalinaTv
                                  ? t(
                                      AppConstant.KNOWLEDGE_HUB_PAGE
                                        .DISCOVER_SHALINA_TV_SUB_SENTENCE
                                    )
                                  : getPageNameByCategory(item?.category) ===
                                    pageNames.KnowledgeHub
                                  ? t(
                                      AppConstant.KNOWLEDGE_HUB_PAGE
                                        .DISCOVER_TEXT_FOR_KNOWLEDGE_HUB_MENU_SUB_SENTENCE
                                    )
                                  : ''}
                              </span>
                            </div>
                          </div>
                          {/* <div className={styles.msg}></div> */}
                          <div className={styles.discoverBtn}>
                            <ButtonComponent
                              variant='outlined'
                              buttonText={`${t(
                                AppConstant.KNOWLEDGE_HUB_PAGE.BROWSE_TEXT
                              )} ${item?.label}`}
                              onPress={() => handleBrowseButton(item)}
                            />
                          </div>
                        </div>
                      </>
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
