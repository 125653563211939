import {
  AppConstant,
  translate,
  ImageConstant,
  PLAY_STORE_LINK,
  APP_STORE_LINK,
  APP_STORE_WEB_LINK,
  PLAY_STORE_WEB_LINK,
} from '@shalina-app/shared';
import { detectAndroidDevice, detectIosDevice } from '../../../utils';

import styles from './footerComponent.module.scss';

export const DownloadOptions = () => {
  const t = translate();

  const handleAppleStoreClick = () => {
    if (detectIosDevice()) {
      window.open(APP_STORE_LINK, '_blank');
    } else {
      window.open(APP_STORE_WEB_LINK, '_blank');
    }
  };

  const handlePlayStoreClick = () => {
    if (detectAndroidDevice()) {
      window.open(PLAY_STORE_LINK, '_blank');
    } else {
      window.open(PLAY_STORE_WEB_LINK, '_blank');
    }
  };

  return (
    <div className={styles.download}>
      <p className={styles.footerTitle}>
        {t(AppConstant.FOOTER.DOWNLOAD_TEXT)}
      </p>
      <p className={styles.downloadText}>
        {t(AppConstant.FOOTER.DOWNLOAD_SUB_TEXT)}
      </p>
      <div className={styles.appWrapper}>
        <img
          src={ImageConstant.SOURCE.appleStore}
          alt='logo'
          onClick={handleAppleStoreClick}
        />
        <img
          src={ImageConstant.SOURCE.googlePlay}
          alt='logo'
          onClick={handlePlayStoreClick}
        />
      </div>
    </div>
  );
};
