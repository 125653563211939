import React, { useState } from 'react';
import {
  AppConstant,
  COMMUNITIES_TABS,
  COMMUNITY_REQUEST_STATUS,
  ImageConstant,
  translate,
  updateCommunity,
  useAppDispatch
} from '@shalina-app/shared';
import { Dialog, DialogContent } from '@mui/material';

import styles from './exploreCommunitySection.module.scss';
import { ExploreCommunityCardContentType } from '.';
import { convertToSpecialties, useNavigatePageUtils } from '../../../../utils';
import { SignUpPopup } from '../../../Authentication';
import {
  ButtonLoader,
  CommunityGuideline,
  Members
} from '../../../communities';
import { URLConstant } from '../../../../routes';
import { Speciality, Toast } from '../../../../components';

export const ExploreCommunityCard: React.FC<
  ExploreCommunityCardContentType
> = ({
  id,
  name,
  interests,
  memberCount,
  icon,
  members,
  isLoggedIn,
  guidelines,
  iscommunityjoined
}) => {
  const dispatch = useAppDispatch();
  const navigatePageUtils = useNavigatePageUtils();
  const t = translate();
  const { urls } = URLConstant;

  const [showSignUp, setShowSignUp] = useState(false);
  const [communityId, setCommunityId] = useState('');
  const [openGuidelinePopup, setOpenGuidelinePopup] = useState(false);
  const [loader, setLoader] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const handleToastClose = () => {
    setShowToast(false);
  };

  const handleSignUpClose = () => {
    setShowSignUp(false);
  };

  const handleSignUpFlow = () => {
    setShowSignUp(true);
  };

  const handleAskToJoin = async (id: any) => {
    // commented below as mentioned on > #220
    if (isLoggedIn) {
      setLoader(true);
      setCommunityId(id);
      setOpenGuidelinePopup(true);
    } else {
      handleSignUpFlow();
    }
  };

  const handleJoinRequestSubmit = async () => {
    const req = {
      communityId: communityId,
      requestStatus: COMMUNITY_REQUEST_STATUS.JOIN
    };
    const response = await dispatch(updateCommunity(req)).unwrap();

    if (
      response &&
      response?.status === 'SUCCESS' &&
      response?.statusCode === 200
    ) {
      const navigateDetails = {
        stateParams: {
          selectedTab: COMMUNITIES_TABS.REQUESTS
        },
        isDynamicUrl: false
      };
      navigatePageUtils(urls.commiunitiesPage, navigateDetails);
    } else {
      setShowToast(true);
      setIsSuccess(false);
      setToastMessage(t(AppConstant.COMMUNITY.JOIN_COMMUNITY_REQUEST_FAILED));
    }
    setOpenGuidelinePopup(false);
    setLoader(false);
  };

  const handleGuidelinePopupClose = () => {
    setOpenGuidelinePopup(false);
    setLoader(false);
  };

  const handleCommunityClick = (id: any) => {
    const navigateDetails = {
      isDynamicUrl: false,
      stateParams: {
        communityId: id,
        isDetailPage: true
      }
    };
    navigatePageUtils(urls.commiunitiesPage, navigateDetails);
  };

  return (
    <>
      <div className={styles.exploreCardWrap}>
        <div className={styles.exploreCard}>
          <div className={styles.exploreCardImgWrapper}>
            <img
              src={icon}
              alt='community logo'
              className={styles.exploreCardImg}
              onError={(e) =>
                ((e.target as HTMLInputElement).src =
                  ImageConstant.SOURCE.communityIcon)
              }
            />
          </div>
          <div className={styles.experCardTitle}>
            {/* {isLoggedIn ? ( */}
            <h4 onClick={() => handleCommunityClick(id)}>{name}</h4>
            {/* ) : (
              <h4>{name}</h4>
            )} */}
            <div className={styles.speciality}>
              <Speciality
                speciality={convertToSpecialties(interests || '')}
                showAll={false}
              />
              {/* {specialityArr?.map((item: string, index: number) =>
                index < 1 ? (
                  <div key={index} className={styles.specialityItem}>
                    <span title={item}>{item}</span>
                  </div>
                ) : (
                  ""
                )
              )}
              {specialityArr?.length - 1 > 0 && (
                <div className={styles.moreSpeciality}>
                  <span className={styles.count}>{`+${
                    specialityArr?.length - 1
                  }`}</span>
                  <div className={styles.specialityTooltipWrapper}>
                  <div className={styles.specialityTooltip}>
                    {specialityArr
                      ?.slice(1)
                      .map((item: string, index: number) => (
                        <span className={`${styles.tooltiptext}`} key={index}>
                          {item}
                        </span>
                      ))}
                  </div>
                  </div>
                </div>
              )} */}
            </div>
          </div>
          <div className={styles.members}>
            <Members memberCount={memberCount} memberImages={members} />
          </div>
          {!iscommunityjoined ? <button
            className={styles.experCardCta}
            onClick={() => handleAskToJoin(id)}
          >
            {t(AppConstant.HOME_PAGE.ASK_TO_JOIN_TEXT)}
            {/* <ButtonLoader loader={loader} /> */}
          </button> : <div style={{height: 30, minWidth: 132}}/>}
        </div>
        <SignUpPopup open={showSignUp} handleClose={handleSignUpClose} />
      </div>

      <Dialog
        open={openGuidelinePopup}
        onClose={handleGuidelinePopupClose}
        className={styles.joinModal}
      >
        <DialogContent>
          <button
            className={styles.closeCta}
            onClick={handleGuidelinePopupClose}
          >
            &times;
          </button>
          <CommunityGuideline
            communityGuidelines={guidelines}
            handleJoinRequestSubmit={handleJoinRequestSubmit}
          />
        </DialogContent>
      </Dialog>
      <Toast
        showToast={showToast}
        isSuccess={isSuccess}
        message={toastMessage}
        handleToastClose={handleToastClose}
      />
    </>
  );
};
