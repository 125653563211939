"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UploadIcon = void 0;
const React = __importStar(require("react"));
const UploadIcon = (props) => (React.createElement("svg", Object.assign({}, props, { xmlns: "http://www.w3.org/2000/svg", width: 17, height: 17, fill: "none" }),
    React.createElement("path", { fill: "#002E5B", d: "M2.123 16.107A2.122 2.122 0 0 1 0 13.985v-3.032a.91.91 0 0 1 1.82 0v3.032c0 .167.135.303.303.303h12.735a.304.304 0 0 0 .303-.303v-3.032a.91.91 0 1 1 1.82 0v3.032a2.122 2.122 0 0 1-2.123 2.122H2.123Z" }),
    React.createElement("path", { fill: "#002E5B", d: "M9.399 3.749v6.9a.91.91 0 1 1-1.82 0v-6.9L5.19 6.137a.91.91 0 0 1-1.285-1.286L7.846.91a.909.909 0 0 1 1.286 0l3.942 3.942a.908.908 0 0 1-.991 1.483.91.91 0 0 1-.295-.197l-2.39-2.388Z" })));
exports.UploadIcon = UploadIcon;
