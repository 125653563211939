import React, { useRef, useState } from 'react';
import { AppConstant, translate, ImageConstant } from '@shalina-app/shared';
import Slider from 'react-slick';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import { SliderProps } from '.';
import styles from './slider.module.scss';

export const CardSlider: React.FC<SliderProps> = ({
  children,
  slidesToShow,
  isViewAllExist,
  showControl,
  sliderHeading,
  isDarkBg,
  isDataExist,
  handleViewAllButtonClick,
  ebookClassName,
  dataLength,
  loading
}) => {
  const [slideIndex, setSlideIndex] = useState<any>(0);
  const t = translate();
  const sliderRef: any = useRef(null);
  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '28px'
        }
      }
    ],
    beforeChange: (current: any, next: any) => setSlideIndex(next)
  };

  const nextArrowClick = () => {
    sliderRef?.current?.slickNext();
  };
  const previousArrowClick = () => {
    sliderRef?.current?.slickPrev();
  };

  const isLastSlide = dataLength - slidesToShow === slideIndex;
  const isFirstSlide = slideIndex === 0;
  const { SliderArrowIcon } = ImageConstant.SVG_ICONS;
  return (
    <div
      className={`${styles.sliderContainer} ${
        isDarkBg ? styles.sliderDark : ''
      } ${ebookClassName && styles[ebookClassName]}`}
    >
      <div className={styles.headCta}>
        <h2 className={styles.title}>{t(sliderHeading)}</h2>
        <div className={styles.ctaWrapper}>
          {isViewAllExist && (
            <button
              className={styles.viewAll}
              onClick={handleViewAllButtonClick}
            >
              {t(AppConstant.COMMON.VIEW_ALL_CTA)}
            </button>
          )}
          {showControl && (
            <div className={styles.sliderArrow}>
              <button
                className={styles.prev}
                onClick={previousArrowClick}
                disabled={isFirstSlide ? true : false}
              >
                <SliderArrowIcon />
                {/* <svg
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.13281 1.89938L7.80531 0.571876L0.380312 7.99688L7.80531 15.4219L9.13281 14.0944L3.03531 7.99688L9.13281 1.89938Z"
                    fill="black"
                  />
                </svg> */}
              </button>
              <button
                className={styles.next}
                onClick={nextArrowClick}
                disabled={isLastSlide ? true : false}
              >
                <SliderArrowIcon />
                {/* <svg
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.867188 14.1006L2.19469 15.4281L9.61969 8.00312L2.19469 0.578125L0.867188 1.90562L6.96469 8.00312L0.867188 14.1006Z"
                    fill="black"
                  />
                </svg> */}
              </button>
            </div>
          )}
        </div>
      </div>
      {loading ? (
        <div className={styles.emptyLoader}>
          <Box sx={{ display: 'flex' }} className={styles.loaderWrapper}>
            <CircularProgress
              size={50}
              thickness={4}
              sx={{
                color: 'var(--secondaryOrange)'
              }}
            />
          </Box>
        </div>
      ) : isDataExist ? (
        <div className={styles.cardContainer}>
          <Slider ref={sliderRef} {...sliderSettings}>
            {children}
          </Slider>
        </div>
      ) : (
        <div className={styles.contentNotFound}>
          <div className={styles.notFoundImg}>
            <img
              src={ImageConstant.SOURCE.contentNotFoundImg}
              alt='not found'
            />
          </div>
          <div className={styles.notFoundText}>
            <h3>{`${t(AppConstant.HOME_PAGE.NO_TEXT)} ${t(
              AppConstant.HOME_PAGE.DATA_TEXT
            )} ${t(AppConstant.HOME_PAGE.FOUND_TEXT)}`}</h3>
            <p>{t(AppConstant.HOME_PAGE.NO_DATA_FOUND_MESSAGE)}</p>
          </div>
        </div>
      )}
    </div>
  );
};
