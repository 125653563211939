import parse from 'html-react-parser';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import {
  AppConstant,
  DiscussionQuestionsProps,
  DISCUSSION_CATEGORY,
  fetchCommunityMembers,
  fetchDiscussionQuestions,
  getCommunityMembers,
  getOnlyDate,
  ImageConstant,
  isBeforeCurrentDateTime,
  submitQuestion,
  translate,
  useAppDispatch,
  useAppSelector,
} from '@shalina-app/shared';
import { useEffect, useRef, useState } from 'react';

import { DiscussionCommentsPopupProps } from '.';
import { ButtonLoader, CommentCard } from '..';
import {
  DEFAULT_COMMUNITY_SKIP,
  DEFAULT_COMMUNITY_TAKE,
} from '../../../../app.constants';
import { InfiniteScrollComponent } from '../../../../components';
import {
  getImageSrc,
  retrieveData,
  STORAGE_CONSTANTS,
  UserInterface,
} from '../../../../utils';
import styles from './CommunitiesContentCard.module.scss';

export const DiscussionCommentsPopup: React.FC<
  DiscussionCommentsPopupProps
> = ({
  discussion,
  handleViewAllClose,
  discussionId,
  isQuestionAnswersTab,
  isQAEvent,
  isExpert,
  handleMainApiCall,
  isTopicEvent
}) => {
  const editorRef = useRef(null);
  const t = translate();
  const dispatch = useAppDispatch();
  const { DateIcon, MessageIcon, SendIcon } = ImageConstant.SVG_ICONS;

  const userDetail: UserInterface | any = retrieveData(STORAGE_CONSTANTS.USER);

  const [hasMore, setHasMore] = useState(true);
  const [loader, setLoader] = useState(false);
  const [apiCall, setApiCall] = useState(false);
  const [refreshQuestions, setRefreshQuestions] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    skip: 0,
    take: DEFAULT_COMMUNITY_TAKE,
    discussion_id: discussionId,
  });
  const [questionsList, setQuestionsList] = useState<any>([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [skip, setSkip] = useState(0);
  const [questionText, setQuestionText] = useState('');
  const [submitQueLoader, setSubmitQueLoader] = useState(false);
  const [viewMoreDesc, setViewMoreDesc] = useState(false);
  const [submitQuestionDisabled, setSubmitQuestionDisabled] = useState(true);
  const [selectedTagId, setSelectedTagId] = useState<Array<string>>([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorHtmlValue, setEditorHtmlValue] = useState('');
  const [memberApiCall, setMemberApiCall] = useState(false);
  const [membersList, setMembersList] = useState<any>([]);

  const communityMembers = useAppSelector(getCommunityMembers);

  const onEditorStateChange = (editorState: any) => {
    const htmlString = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setEditorState(editorState);
    setEditorHtmlValue(htmlString);

    const content = editorState?.getCurrentContent();
    const plainText = content?.getPlainText(); // Get plain text content

    const entityMap = convertToRaw(content)?.entityMap;
    const mentions: any = [];

    Object?.values(entityMap)?.forEach((entity) => {
      if (entity?.type === 'MENTION') {
        mentions?.push(entity?.data);
      }
    });
    const selectedTags = mentions
      ?.map((mention: any) => mention?.url)
      .join(', ');
    setSelectedTagId(selectedTags);
    if (htmlString) {
      setSubmitQuestionDisabled(false);
    } else {
      setSubmitQuestionDisabled(true);
    }
  };

  useEffect(() => {
    setApiCall(true);
    setMemberApiCall(true);
  }, []);

  useEffect(() => {
    if (memberApiCall) {
      loadMembers();
    }
  }, [memberApiCall]);

  useEffect(() => {
    if (communityMembers && communityMembers?.length > 0) {
      const suggestions = communityMembers?.map((item) => ({
        text: `${item?.member_first_name} ${item?.member_last_name}`, // Combine first and last names
        value: `${item?.member_first_name} ${item?.member_last_name}`, // Use member_id as the value
        url: `${item?.member_id}`
      }));
      const filteredData = suggestions?.filter(
        (item: any) => item?.url !== userDetail?.uid
      );

      setMembersList(filteredData);
    }
  }, [communityMembers]);

  useEffect(() => {
    if (apiCall) {
      loadApi();
    }
  }, [apiCall]);

  const loadMembers = () => {
    const payload = {
      communityId: discussion?.community_id,
    };
    dispatch(fetchCommunityMembers(payload));
  };

  const loadApi = async () => {
    const response = await dispatch(
      fetchDiscussionQuestions(filterOptions)
    ).unwrap();
    if (
      response &&
      response?.status === 'SUCCESS' &&
      response?.statusCode === 200
    ) {
      setPagination(response);
    } else {
      setLoader(false);
      setApiCall(false);
    }
  };

  const setPagination = (response: any) => {
    setLoader(false);
    const data = response?.data;
    const totalCount = response?.count;

    setTotalDataCount(totalCount);
    if (apiCall) {
      if (questionsList?.length >= response?.count) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      setApiCall(false);
      setSubmitQueLoader(false);
      setEditorState(EditorState.createEmpty());
      setQuestionText('');
      // setEditorState(RichTextEditor.createEmptyValue());
      setSubmitQuestionDisabled(true);
    }
    if (data) {
      if (refreshQuestions) {
        setQuestionsList(data);
        setRefreshQuestions(false);
      } else {
        setQuestionsList((prevOptions: any) => [...prevOptions, ...data]);
      }
    }
  };

  const fetchMoreData = () => {
    if (questionsList?.length >= totalDataCount) {
      setHasMore(false);
      return;
    }
    setSkip(skip + DEFAULT_COMMUNITY_SKIP);
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      skip: skip + DEFAULT_COMMUNITY_SKIP,
      take: DEFAULT_COMMUNITY_TAKE,
    }));
    setApiCall(true);
  };

  const handleQuestion = (value: any) => {
    const text = value?.toString('html');
    // setEditorState(value);
    if (text) {
      setSubmitQuestionDisabled(false);
    } else {
      setSubmitQuestionDisabled(true);
    }
    setQuestionText(text);
  };

  const handleSubmitQuestion = async () => {
    // const text = questionText?.trim();
    const text = editorHtmlValue?.trim();
    const taggedId = selectedTagId?.toString();
    if (text) {
      setSubmitQueLoader(true);
      const payload = {
        discussion_id: discussionId || discussion?.discussion_id,
        question: text,
        taggedId: taggedId,
      };
      const response = await dispatch(submitQuestion(payload)).unwrap();

      if (
        response &&
        response?.statusCode === 200 &&
        response?.status === 'SUCCESS'
      ) {
        setSkip(0);
        setFilterOptions((prevOptions) => ({
          ...prevOptions,
          skip: 0,
          take: DEFAULT_COMMUNITY_TAKE,
        }));
        setApiCall(true);
        setRefreshQuestions(true);
      } else {
        setSubmitQueLoader(false);
      }
    }
  };

  const handleRefreshPage = () => {
    setSkip(0);
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      skip: 0,
      take: DEFAULT_COMMUNITY_TAKE,
    }));
    setRefreshQuestions(true);
    setApiCall(true);
  };

  const isQuestionSubmissionDateEnd = isBeforeCurrentDateTime(
    discussion?.field_question_submission_closed
  );

  const isEventStarted = isBeforeCurrentDateTime(discussion?.field_from_date);
  const isEventEnded = isBeforeCurrentDateTime(discussion?.field_to_date);
  const description = discussion?.description
    ? parse(discussion?.description)
    : '';

  return (
    <>
      <div className={styles.head}>
        <div className={styles.title}>
          <div className={styles.exploreIconWrapper}>
            <img
              className={styles.exploreIcon}
              src={
                getImageSrc(discussion?.field_community_icon) ||
                ImageConstant.SOURCE.communityIcon
              }
              alt='img'
              onError={(e) =>
                ((e.target as HTMLInputElement).src =
                  ImageConstant.SOURCE.communityIcon)
              }
            />
          </div>
          <div className={styles.popupTitle}>{discussion?.community_title}</div>
        </div>
        <span className={styles.closeCTA} onClick={handleViewAllClose}>
          <svg
            width='12'
            height='12'
            viewBox='0 0 14 14'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M1 0.999999L7 7L0.999998 13'
              stroke='white'
              stroke-width='2'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
            <path
              d='M13 13L7 7L13 1'
              stroke='white'
              stroke-width='2'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
          </svg>
        </span>
      </div>
      <div className={styles.viewAllTop}>
        <div className={styles.topDetails}>
          <p className={styles.descTitle}>{discussion?.title}</p>
        </div>
        <div className={styles.topLabel}>{discussion?.field_category_name}</div>
      </div>
      <div className={styles.descriptionDiv}>
        <p className={styles.title}>
          <>
            {description}
            {/* <strong onClick={handleSeeMore}>
                  {t(AppConstant.COMMUNITY.SEE_LESS_TEXT)}...
                </strong> */}
          </>
        </p>
        {discussion?.field_category === DISCUSSION_CATEGORY?.QA && (
          <div className={styles.dateWrapper}>
            <div className={styles.date}>
              <DateIcon />
              <strong>{t(AppConstant.COMMUNITY.EVENT_TEXT)}</strong>
              <span>{getOnlyDate(discussion?.field_from_date)}</span>

              <strong>{t(AppConstant.COMMUNITY.EVENT_ENDS)}</strong>
              <span>{getOnlyDate(discussion?.field_to_date)}</span>
            </div>
            <div className={styles.date}>
              <MessageIcon />
              <strong>
                {t(AppConstant.COMMUNITY.QUESTION_SUBMISSION_CLOSES_ON_TEXT)}
              </strong>
              <span>
                {getOnlyDate(discussion?.field_question_submission_closed)}
              </span>
            </div>
          </div>
        )}
      </div>
      <div className={styles.divider}></div>
      <div id='scrollableDiv' className={styles.viewAllBody}>
        <div className={styles.bodyTitle}>
          {discussion?.field_category === DISCUSSION_CATEGORY?.TOPIC
            ? t(AppConstant.COMMUNITY.VIEW_ALL_POPUP_COMMENTS_TEXT)
            : t(AppConstant.COMMUNITY.VIEW_ALL_POPUP_QUESTIONS_TEXT)}
        </div>

        {discussion?.field_category === DISCUSSION_CATEGORY?.QA && isExpert ? (
          <></>
        ) : (
          !isQuestionSubmissionDateEnd && (
            <div className={styles.enterPostWrapper}>
              <img
                className={styles.userImg}
                src={
                  userDetail?.userPicture
                    ? getImageSrc(userDetail?.userPicture)
                    : ImageConstant.SOURCE.defaultUserPic
                }
                alt='img'
                onError={(e) =>
                  ((e.target as HTMLInputElement).src =
                    ImageConstant.SOURCE.defaultUserPic)
                }
              />
              <div className={styles.postInput}>
                <Editor
                  toolbarHidden
                  wrapperClassName='demo-wrapper'
                  placeholder={
                    discussion?.field_category === DISCUSSION_CATEGORY?.TOPIC
                      ? t(AppConstant.COMMUNITY.TOPIC_COMMENT_PLACEHOLDER_TEXT)
                      : t(AppConstant.COMMUNITY.QA_COMMENT_PLACEHOLDER_TEXT)
                  }
                  editorState={editorState}
                  onEditorStateChange={onEditorStateChange}
                  // editorClassName='demo-editor'
                  mention={{
                    separator: ' ',
                    trigger: '@',
                    suggestions: membersList,
                  }}
                />

                {/* <textarea
                  placeholder={
                    discussion?.field_category === DISCUSSION_CATEGORY?.TOPIC
                      ? t(AppConstant.COMMUNITY.TOPIC_COMMENT_PLACEHOLDER_TEXT)
                      : t(AppConstant.COMMUNITY.QA_COMMENT_PLACEHOLDER_TEXT)
                  }
                  value={questionText}
                  onChange={handleQuestion}
                  rows={5}
                ></textarea> */}
                <button
                  className={`${styles.submitCta} ${
                    submitQuestionDisabled && styles.disabled
                  }`}
                  disabled={submitQuestionDisabled}
                  onClick={handleSubmitQuestion}
                >
                  <SendIcon />
                  <ButtonLoader loader={submitQueLoader} />
                </button>
              </div>
            </div>
          )
        )}
        <InfiniteScrollComponent
          dataLength={questionsList?.length}
          fetchMoreData={fetchMoreData}
          hasMore={hasMore}
          loader={true}
          scrollableTarget={'scrollableDiv'}
          // scrollThreshold={"200px"}
        >
          {questionsList?.map((comment: DiscussionQuestionsProps) => (
            <CommentCard
              discussionId={discussion?.discussion_id}
              comment={comment}
              isQAEvent={isQAEvent}
              isTopicEvent={isTopicEvent}
              isExpert={isExpert}
              handleMainApiCall={handleRefreshPage}
              isEventStarted={isEventStarted}
              isEventEnded={isEventEnded}
            />
          ))}
        </InfiniteScrollComponent>
      </div>
    </>
  );
};
