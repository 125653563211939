import {
  AppConstant,
  getFilterCategoriesOptionsValues,
  ImageConstant,
  searchContent,
  translate,
  useAppDispatch,
  useAppSelector,
} from '@shalina-app/shared';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Slider from 'react-slick';
import Highlighter from 'react-highlight-words';

import {
  DEFAULT_SKIP,
  DEFAULT_TAKE,
  pageNames,
} from '../../../../../app.constants';
import {
  ButtonComponent,
  BarLoader,
  InfiniteScrollComponent,
  QuickLinkSection,
} from '../../../../../components';
import { URLConstant } from '../../../../../routes';
import {
  getImageSrc,
  getPageNameByCategory,
  removedFromStore,
  retrieveData,
  STORAGE_CONSTANTS,
  storeData,
  useNavigatePageUtils,
} from '../../../../../utils';
import styles from './searchList.module.scss';
import { SignUpPopup } from '../../../../Authentication';

export const SearchListPage: React.FC<any> = () => {
  const t = translate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const searchParam = location?.state?.searchWord;
  const path = location?.state?.pathName;
  const isLoggedIn: boolean = retrieveData(STORAGE_CONSTANTS.IS_LOGGED_IN);

  const sliderRef: any = useRef(null);
  const sliderSettings = {
    infinite: true,
    variableWidth: true, // slidesToShow: 4, // slidesToScroll: 7,
    swipeToSlide: true,
    swipe: true,
    arrows: false,
  };
  const nextArrowClick = () => {
    sliderRef?.current?.slickNext();
  };
  const previousArrowClick = () => {
    sliderRef?.current?.slickPrev();
  };
  const { SliderArrowIcon } = ImageConstant.SVG_ICONS;

  const [skip, setSkip] = useState(0);
  const [isLoader, setIsLoader] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  // const [contentData, setContentData] = useState<any>([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [showSignUp, setShowSignUp] = useState(false);
  const [searchResults, setSearchResults] = useState<any>([]);
  const [selectedContentTypes, setSelectedContentTypes] = useState<any>([]);
  const [category, setCategory] = useState('');
  const [serachResponse, setSerachResponse] = useState<any>({});
  const [reload, setReload] = useState(false);
  const [contentCount, setContentCount] = useState(0);
  const [filterCall, setFilterCall] = useState(false);
  const [updatedList, setUpdatedList] = useState([]);
  const [listingResult, setListingResult] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    searchText: '',
    skip: 0,
    take: DEFAULT_TAKE,
    filter: '',
    category: '',
  });
  const [highlightWords, setHighlightWords] = useState([]);

  const isLoadingSearch = useAppSelector(
    (state) => state?.home?.searchContent?.isSearchContentLoading
  );

  const masterContentTypeData = useAppSelector(
    getFilterCategoriesOptionsValues
  );
  const navigatePage = useNavigatePageUtils();
  const { urls } = URLConstant;

  const handleSignUpClose = () => {
    setShowSignUp(false);
  };

  const handleSignUpFlow = () => {
    setShowSignUp(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setReload(true);
  }, []);

  useEffect(() => {
    if (reload) {
      loadSearchApi();
      // setReload(false);
    }
  }, [reload]);

  // useEffect(() => {
  //   if (filterCall) {
  //     loadSearchApi();
  //     setFilterCall(false);
  //   }
  // }, [filterCall]);

  useEffect(() => {
    setReload(true);
    setSearchResults([]);
    setFilterOptions((prevOptions: any) => ({
      ...prevOptions,
      skip: 0,
      take: DEFAULT_TAKE,
      filter: '',
      category: '',
    }));
    setSkip(0);
    getPageCameFrom();
    setIsLoader(true);
  }, [searchParam]);

  const loadSearchApi = async () => {
    try {
      if (searchParam) {
        // setIsLoader(true);
        const keyWord = searchParam?.trim();
        if (keyWord) {
          const payload: any = {
            ...filterOptions,
            searchText: keyWord,
          };
          const response = await dispatch(
            searchContent(payload) as any
          ).unwrap();
          setSerachResponse(response);
        }
      } else {
        setReload(false);
      }
    } finally {
      // setIsLoader(false);
    }
  };

  useEffect(() => {
    if (
      masterContentTypeData &&
      masterContentTypeData?.length > 0 &&
      serachResponse &&
      serachResponse?.data
    ) {
      const responseKeys: any = Object.keys(serachResponse?.data);

      let finalArray: any = [];
      serachResponse?.data?.map((id: any) => {
        const data = masterContentTypeData?.find(
          (item: any) => item?.value === id.field_select_article_type_id
        );
        if (data) {
          finalArray.push({
            ...id,
            contentTypeIcon: data?.icon,
          });
        }
      });
      const filterResponse = {
        totalCount: serachResponse?.count,
        data: [...finalArray],
      };

      const totalCount = serachResponse?.count;
      setHighlightWords(serachResponse?.words);
      setTotalDataCount(totalCount);
      setPagination(filterResponse);
    }
  }, [serachResponse, masterContentTypeData]);

  useEffect(() => {
    if (masterContentTypeData) {
      setUpdatedList(updatedList);
    }
  }, [masterContentTypeData, selectedContentTypes]);

  const handleCardCllick = (data: any) => {
    const contentTypeId = masterContentTypeData?.find(
      (item: any) => item?.unique_id === data?.field_unique_code
    )?.value;
    const category = masterContentTypeData?.find(
      (item: any) => item?.unique_id === data?.field_unique_code
    )?.category;

    const page = getPageNameByCategory(category);
    // const eventId = data?.event_id;

    // if (eventId) {
    //   storeData(STORAGE_CONSTANTS.EVENT_ID, eventId);
    // } else if (retrieveData(STORAGE_CONSTANTS.EVENT_ID)) {
    //   removedFromStore(STORAGE_CONSTANTS.EVENT_ID);
    // }

    if (page && data?.nid && contentTypeId) {
      const navigateDetails = {
        stateParams: {
          eventID: data?.event_id || ''
        },
        isDynamicUrl: true,
        dynamicUrlOptions: {
          pageName: page,
          contentId: data?.nid,
          contentTypeId: contentTypeId,
        },
      };
      navigatePage(urls?.detailPageWithModule, navigateDetails);
    }
  };

  const handleChipSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;

    let temp: any = selectedContentTypes;

    if (checked) {
      // Add the id to the array if checked
      temp?.push(id);
    } else {
      // Remove the id from the array if unchecked
      const index = temp?.indexOf(id);
      if (index !== -1) {
        temp?.splice(index, 1);
      }
    }
    setSkip(0);
    // setSearchResults([]);
    setSelectedContentTypes(temp);
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      filter: temp?.join(','),
      skip: 0,
      take: DEFAULT_TAKE,
    }));
    setFilterCall(true);
    // setIsLoader(true);
    setReload(true);
  };

  const getPageCameFrom = () => {
    const pathValues = path?.split('/');
    const pageName = pathValues?.[1] || '';
    const contentType = pathValues?.[2] || '';

    const pageNameMapping = {
      [pageNames.KnowledgeHub]: t(AppConstant.HEADER.KNOWLEDGE_HUB_MENU),
      [pageNames.ShalinaTv]: t(AppConstant.HEADER.SHALINA_TV_MENU),
      [pageNames.LearningCenter]: t(AppConstant.HEADER.LEARNING_CENTER_MENU),
    };

    const menu = pageNameMapping[pageName];
    if (menu) {
      setCategory(menu || '');
    }
    if (contentType) {
      setSelectedContentTypes([contentType]);
      setFilterOptions((prevOptions) => ({
        ...prevOptions,
        filter: [contentType]?.join(','),
      }));
      setListingResult(true);
    } else {
      setSelectedContentTypes([]);
      setFilterOptions((prevOptions) => ({
        ...prevOptions,
        filter: '',
      }));
      setListingResult(false);
    }
  };

  const calculateTotalContentCount = (data: any) => {
    let totalContentCount = 0;

    data?.forEach((item: any) => {
      totalContentCount += item?.data?.length || 0; // Sum up the lengths
    });

    return totalContentCount;
  };

  const setPagination = (response: any) => {
    setIsLoader(false);
    const data = response?.data;
    const temp =
      searchResults?.length > 0 && !filterCall ? searchResults : data;
    const tempPrevResult = searchResults?.length > 0 ? temp.concat(data) : data;
    // data?.map((obj1: any) => {
    //   const matchingObject = tempPrevResult?.find(
    //     (obj2: any) => obj2?.contentTypeId === obj1?.contentTypeId
    //   );

    //   if (matchingObject) {
    //     const newArr = matchingObject?.data?.concat(...obj1?.data);
    //     const tempObj = { ...matchingObject, data: [...newArr] };

    //     tempPrevResult = tempPrevResult?.map((obj: any) =>
    //       obj?.contentTypeId === tempObj?.contentTypeId ? tempObj : obj
    //     );
    //   } else {
    //     const tempObj = { ...obj1 };
    //     tempPrevResult?.push(tempObj);
    //   }
    // });

    if (filterCall) {
      setSearchResults(data);
      setFilterCall(false);
    } else {
      setSearchResults((prevOptions: any) => [...prevOptions, ...data]);
    }

    const contentLength = tempPrevResult.length; // calculateTotalContentCount(tempPrevResult);
    setContentCount(contentLength);

    if (reload) {
      if (contentCount >= response?.totalCount) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      setReload(false);
    }
  };

  const fetchMoreData = () => {
    if (contentCount >= totalDataCount) {
      setHasMore(false);
      return;
    }
    setSkip(skip + DEFAULT_SKIP);
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      skip: skip + DEFAULT_SKIP,
      take: DEFAULT_TAKE,
    }));
    setReload(true);
  };
  const handleRequestContent = async () => {
    if (isLoggedIn) {
      const navigateDetails = {
        stateParams: {
          isNavigatedFromSearch: true,
        },
        isDynamicUrl: false,
      };
      navigatePage(urls.helpSupport, navigateDetails);
      // setShowHelpDesk(true);
    } else {
      handleSignUpFlow();
    }
  };

  return (
    <>
      <div className={styles.resultTop}>
        <div className='cust-container'>
          <h1 className={styles.resultHead}>
            {searchParam &&
              `${t(
                AppConstant.COMMON.SHOWING_RESULTS_FOR_TEXT
              )} ${`"${searchParam}"`}`}
          </h1>
          {!listingResult && masterContentTypeData?.length && (
            <ul className={styles.searchTag}>
              <li key={'all'}>
                <label>
                  <input type='checkbox' checked />
                  <span className={styles.allText}>
                    {t(AppConstant.PROFILE.ALL_TEXT)} ({totalDataCount})
                  </span>
                </label>
              </li>
              <div className={styles.filterSlider}>
                <Slider ref={sliderRef} {...sliderSettings}>
                  {masterContentTypeData?.map((item: any, index: number) => (
                    <li key={index}>
                      <label>
                        <input
                          type='checkbox'
                          id={item?.value}
                          onChange={handleChipSelect}
                          checked={selectedContentTypes?.includes(item?.value)}
                        />
                        <span>{item?.label}</span>
                      </label>
                    </li>
                  ))}
                </Slider>

                <div className={styles.sliderArrow}>
                  <button className={styles.prev} onClick={previousArrowClick}>
                    <SliderArrowIcon />
                  </button>
                  <button className={styles.next} onClick={nextArrowClick}>
                    <SliderArrowIcon />
                  </button>
                </div>
              </div>
            </ul>
          )}
        </div>
      </div>
      {(filterCall || reload) && <BarLoader />}

      <div className={styles.searchDiv}>
        {/* {isLoader ? (
				<>
					<CircularLoader size={50} thickness={4} />
					<div className={styles.loadingHeight}></div>
				</>
			) :  */}
        {searchResults && searchResults?.length > 0 ? (
          <div className='cust-container'>
            <div className={styles.searchRow}>
              <div className={styles.searchCol8}>
                <div className={styles.searchContentWrapper}>
                  <InfiniteScrollComponent
                    dataLength={searchResults?.length}
                    fetchMoreData={fetchMoreData}
                    hasMore={hasMore}
                    loader={true}
                  >
                    {/* {`searchResult Length ${
                searchResults?.length
              } < Total ${totalDataCount} = ${
                searchResults?.length < totalDataCount
              }`} */}
                    {searchResults?.map((result: any, index: number) => (
                      <div key={index} className={styles.searchSec}>
                        {/* <div className={styles.heading}>
                        {result?.contentTypeName}
                      </div> */}
                        <div className={styles.searchCard} key={result?.nid}>
                          <div className={styles.tag}>
                            <img
                              src={getImageSrc(result?.contentTypeIcon)}
                              alt='content type icon'
                            />
                            {result?.field_select_article_type}
                          </div>
                          <div
                            className={styles.searchCardTitle}
                            onClick={() => handleCardCllick(result)}
                          >
                            <Highlighter
                              searchWords={highlightWords}
                              autoEscape={true}
                              textToHighlight={result?.title}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </InfiniteScrollComponent>
                </div>
              </div>
              <div className={styles.searchCol4}>
                <div className={styles.aboutQuicklink}>
                  <QuickLinkSection />
                </div>
              </div>
            </div>
          </div>
        ) : !filterCall && !reload && searchParam ? (
          <div className={styles.searchResult}>
            <div className='cust-container'>
              <h1 className={styles.pageHead}>
                {t(AppConstant.COMMON.NO_RESULTS_FOUND_TEXT)}
              </h1>
              <div className={`${styles.searchWrapper} ${styles.notFound}`}>
                <img
                  src={ImageConstant.SOURCE.emptyContent}
                  alt='not found img'
                  className={styles.notFoundImg}
                />
                <h4>{t(AppConstant.COMMON.OOPS_TEXT)}</h4>
                <p>
                  {t(AppConstant.COMMON.SEARCH_NEW_SUBMESSAGE)}{' '}
                  {`"${searchParam}"`}
                </p>
                <div className={styles.unlockWrapper}>
                  {/* <img
                  src={ImageConstant.SOURCE.goldMemberBadgeImg}
                  alt="not found img"
                  className={styles.goldImg}
                /> */}
                  {/* <h2 className={styles.unlockHead}>{t(AppConstant.HOME_PAGE.GOLD_MEMBER_CONTENT_TEXT)}</h2> */}
                  <p className={styles.unlockDesc}>
                    {t(AppConstant.HOME_PAGE.RAISE_REQUEST_TEXT)}
                  </p>
                  <div className={styles.unlockCta}>
                    <ButtonComponent
                      variant='outlined'
                      buttonText={t(AppConstant.HOME_PAGE.REQUEST_CONTENT_CTA)}
                      onPress={handleRequestContent}
                      // disabled={!nextCtaEnable}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <SignUpPopup open={showSignUp} handleClose={handleSignUpClose} />
      </div>
    </>
  );
};
