"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BannerBg = void 0;
const React = __importStar(require("react"));
const BannerBg = (props) => (React.createElement("svg", { width: "184", height: "286", viewBox: "0 0 184 286", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M150.986 14.1409C151.271 13.8149 151.578 13.7935 151.905 14.0766L153.38 15.351C153.707 15.6341 153.729 15.9387 153.444 16.2647L152.161 17.7321C151.876 18.058 151.57 18.0795 151.242 17.7964L149.768 16.5219C149.44 16.2388 149.419 15.9342 149.704 15.6083L150.986 14.1409Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M125.225 15.2091C125.624 14.7527 126.053 14.7227 126.512 15.1191L128.576 16.9031C129.035 17.2996 129.064 17.726 128.665 18.1825L126.87 20.2366C126.471 20.693 126.042 20.723 125.583 20.3267L123.519 18.5426C123.061 18.1461 123.031 17.7198 123.43 17.2633L125.225 15.2091Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M99.464 16.2773C99.977 15.6904 100.528 15.6519 101.118 16.1615L103.772 18.4552C104.362 18.965 104.4 19.5133 103.887 20.1002L101.579 22.7412C101.066 23.328 100.514 23.3666 99.9244 22.857L97.2706 20.5633C96.6809 20.0535 96.6425 19.5052 97.1555 18.9183L99.464 16.2773Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M73.7026 17.3457C74.3296 16.6283 75.0035 16.5811 75.7243 17.2041L78.9679 20.0075C79.6887 20.6306 79.7356 21.3007 79.1086 22.018L76.2871 25.2458C75.6601 25.9632 74.9862 26.0104 74.2654 25.3873L71.0219 22.584C70.3011 21.9609 70.2542 21.2908 70.8812 20.5734L73.7026 17.3457Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M47.9413 18.4136C48.6823 17.566 49.4787 17.5103 50.3305 18.2464L54.1638 21.5596C55.0157 22.2959 55.0711 23.088 54.3301 23.9357L50.9957 27.7505C50.2547 28.5981 49.4583 28.6538 48.6064 27.9177L44.7731 24.6045C43.9213 23.8683 43.8658 23.0762 44.6068 22.2285L47.9413 18.4136Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M22.1803 19.4821C23.0353 18.5038 23.9542 18.4395 24.9371 19.2891L29.3602 23.112C30.3431 23.9616 30.407 24.8752 29.552 25.8535L25.7046 30.2552C24.8496 31.2334 23.9307 31.2977 22.9477 30.4481L18.5247 26.6252C17.5418 25.7756 17.4779 24.862 18.3328 23.8837L22.1803 19.4821Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-3.58104 20.5501C-2.61206 19.4415 -1.5706 19.3686 -0.456649 20.3314L4.55613 24.6641C5.67008 25.6269 5.74258 26.6625 4.77359 27.7711L0.413137 32.7597C-0.555843 33.8683 -1.59733 33.9412 -2.71128 32.9784L-7.72407 28.6457C-8.83802 27.6829 -8.91048 26.6473 -7.9415 25.5388L-3.58104 20.5501Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-29.342 21.6184C-28.2591 20.3794 -27.0951 20.2979 -25.8501 21.374L-20.2475 26.2163C-19.0025 27.2924 -18.9215 28.4498 -20.0045 29.6888L-24.878 35.2642C-25.961 36.5032 -27.125 36.5847 -28.37 35.5086L-33.9725 30.6663C-35.2175 29.5902 -35.2985 28.4328 -34.2155 27.1938L-29.342 21.6184Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-55.1034 22.6864C-53.9064 21.3171 -52.6199 21.2271 -51.2438 22.4163L-45.0516 27.7683C-43.6755 28.9577 -43.586 30.2371 -44.783 31.6065L-50.1694 37.7689C-51.3664 39.1382 -52.6529 39.2282 -54.0289 38.039L-60.2212 32.687C-61.5973 31.4975 -61.6868 30.2182 -60.4898 28.8487L-55.1034 22.6864Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-80.8645 23.7548C-79.5534 22.2549 -78.1444 22.1563 -76.6373 23.459L-69.8553 29.3208C-68.3481 30.6233 -68.2501 32.0245 -69.5612 33.5243L-75.4605 40.2735C-76.7715 41.7734 -78.1805 41.8721 -79.6877 40.5694L-86.4697 34.7076C-87.9767 33.405 -88.0748 32.0039 -86.7638 30.5041L-80.8645 23.7548Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M152.763 39.5253C153.048 39.1992 153.354 39.1778 153.682 39.461L155.156 40.7353C155.484 41.0185 155.505 41.3231 155.22 41.6491L153.938 43.1163C153.653 43.4424 153.346 43.4638 153.019 43.1806L151.545 41.9063C151.217 41.6232 151.196 41.3186 151.481 40.9925L152.763 39.5253Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M127.002 40.5934C127.401 40.137 127.83 40.107 128.288 40.5034L130.352 42.2874C130.811 42.6838 130.841 43.1103 130.442 43.5668L128.646 45.6209C128.247 46.0774 127.819 46.1074 127.36 45.7109L125.296 43.9269C124.837 43.5305 124.807 43.104 125.206 42.6475L127.002 40.5934Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M101.241 41.6617C101.754 41.0748 102.305 41.0362 102.895 41.5459L105.549 43.8396C106.138 44.3493 106.177 44.8977 105.664 45.4845L103.355 48.1255C102.842 48.7125 102.291 48.751 101.701 48.2413L99.0473 45.9476C98.4576 45.4379 98.4192 44.8895 98.9322 44.3027L101.241 41.6617Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M75.479 42.7298C76.106 42.0125 76.7799 41.9654 77.5007 42.5883L80.7442 45.3918C81.465 46.0148 81.5119 46.6849 80.885 47.4022L78.0635 50.6301C77.4365 51.3475 76.7626 51.3946 76.0418 50.7716L72.7982 47.9682C72.0774 47.3452 72.0306 46.675 72.6575 45.9577L75.479 42.7298Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M49.718 43.7981C50.459 42.9504 51.2554 42.8946 52.1073 43.6308L55.9406 46.944C56.7924 47.6803 56.8478 48.4723 56.1069 49.32L52.7724 53.1348C52.0314 53.9825 51.235 54.0382 50.3831 53.302L46.5498 49.9889C45.698 49.2526 45.6425 48.4606 46.3836 47.6129L49.718 43.7981Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M23.9567 44.8662C24.8117 43.8881 25.7306 43.8238 26.7135 44.6733L31.1365 48.4962C32.1194 49.3457 32.1834 50.2595 31.3284 51.2377L27.4809 55.6394C26.626 56.6175 25.707 56.6818 24.7241 55.8323L20.3011 52.0094C19.3182 51.1599 19.2542 50.246 20.1092 49.2679L23.9567 44.8662Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-1.80433 45.9345C-0.835344 44.8259 0.206117 44.753 1.32007 45.7158L6.33286 50.0484C7.44681 51.0112 7.5193 52.0469 6.5503 53.1555L2.18985 58.144C1.22086 59.2526 0.179402 59.3255 -0.934553 58.3627L-5.94734 54.0301C-7.0613 53.0673 -7.13378 52.0316 -6.16478 50.923L-1.80433 45.9345Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-27.5657 47.0026C-26.4827 45.7636 -25.3187 45.6822 -24.0737 46.7582L-18.4712 51.6005C-17.2262 52.6766 -17.1452 53.8341 -18.2281 55.0731L-23.1016 60.6486C-24.1846 61.8876 -25.3486 61.969 -26.5936 60.893L-32.1961 56.0507C-33.4411 54.9746 -33.5221 53.8171 -32.4391 52.5781L-27.5657 47.0026Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-53.3267 48.0708C-52.1297 46.7014 -50.8432 46.6114 -49.4671 47.8007L-43.2749 53.1528C-41.8988 54.3421 -41.8093 55.6215 -43.0063 56.9909L-48.3927 63.1532C-49.5897 64.5227 -50.8762 64.6127 -52.2523 63.4233L-58.4445 58.0713C-59.8206 56.882 -59.9101 55.6026 -58.7131 54.2332L-53.3267 48.0708Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-79.0878 49.1391C-77.7767 47.6393 -76.3676 47.5406 -74.8605 48.8433L-68.0786 54.705C-66.5714 56.0076 -66.4734 57.4088 -67.7845 58.9087L-73.6838 65.6579C-74.9948 67.1577 -76.4038 67.2563 -77.911 65.9537L-84.6929 60.092C-86.2 58.7894 -86.2981 57.3882 -84.9871 55.8883L-79.0878 49.1391Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M154.539 64.9095C154.824 64.5835 155.131 64.5621 155.458 64.8452L156.933 66.1195C157.26 66.4027 157.282 66.7073 156.997 67.0334L155.714 68.5006C155.429 68.8266 155.123 68.8481 154.795 68.5649L153.321 67.2906C152.993 67.0074 152.972 66.7028 153.257 66.3768L154.539 64.9095Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M128.778 65.9778C129.177 65.5213 129.606 65.4913 130.065 65.8878L132.129 67.6717C132.588 68.0682 132.618 68.4947 132.219 68.9511L130.423 71.0052C130.024 71.4617 129.595 71.4917 129.137 71.0953L127.072 69.3113C126.614 68.9148 126.584 68.4884 126.983 68.0319L128.778 65.9778Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M103.017 67.0459C103.53 66.459 104.081 66.4205 104.671 66.9302L107.325 69.2239C107.915 69.7336 107.953 70.2819 107.44 70.8688L105.132 73.5098C104.619 74.0967 104.067 74.1353 103.478 73.6256L100.824 71.3319C100.234 70.8221 100.196 70.2738 100.709 69.6869L103.017 67.0459Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M77.2557 68.1142C77.8827 67.3969 78.5566 67.3497 79.2774 67.9727L82.521 70.7761C83.2418 71.3991 83.2887 72.0693 82.6617 72.7866L79.8402 76.0145C79.2132 76.7318 78.5393 76.7789 77.8185 76.156L74.575 73.3525C73.8542 72.7295 73.8073 72.0594 74.4343 71.3421L77.2557 68.1142Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M51.4944 69.1824C52.2354 68.3346 53.0318 68.2789 53.8836 69.0151L57.717 72.3283C58.5688 73.0645 58.6242 73.8565 57.8832 74.7043L54.5488 78.519C53.8078 79.3668 53.0114 79.4225 52.1595 78.6863L48.3262 75.3731C47.4744 74.6368 47.4189 73.8448 48.1599 72.9971L51.4944 69.1824Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M25.7334 70.2506C26.5884 69.2724 27.5073 69.2081 28.4902 70.0577L32.9132 73.8805C33.8961 74.73 33.9601 75.6439 33.1051 76.622L29.2577 81.0237C28.4027 82.0019 27.4837 82.0662 26.5008 81.2166L22.0778 77.3938C21.0949 76.5443 21.031 75.6304 21.8859 74.6523L25.7334 70.2506Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-0.0279468 71.3187C0.941048 70.2102 1.9825 70.1373 3.09646 71.1001L8.10923 75.4327C9.22318 76.3955 9.29565 77.4311 8.32667 78.5397L3.96623 83.5283C2.99723 84.6368 1.95577 84.7097 0.841819 83.7469L-4.17095 79.4143C-5.28491 78.4515 -5.35738 77.4159 -4.3884 76.3073L-0.0279468 71.3187Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-25.789 72.387C-24.706 71.148 -23.542 71.0665 -22.297 72.1426L-16.6945 76.9849C-15.4495 78.061 -15.3684 79.2185 -16.4514 80.4575L-21.3249 86.0329C-22.4079 87.2719 -23.5719 87.3534 -24.8169 86.2773L-30.4194 81.435C-31.6644 80.3589 -31.7454 79.2014 -30.6624 77.9624L-25.789 72.387Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-51.5503 73.4551C-50.3533 72.0857 -49.0668 71.9957 -47.6907 73.185L-41.4985 78.537C-40.1224 79.7264 -40.0329 81.0058 -41.2299 82.3752L-46.6163 88.5375C-47.8133 89.9069 -49.0998 89.9969 -50.4759 88.8076L-56.6681 83.4556C-58.0442 82.2662 -58.1337 80.9869 -56.9367 79.6175L-51.5503 73.4551Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-77.3114 74.5234C-76.0003 73.0235 -74.5913 72.9249 -73.0842 74.2275L-66.3022 80.0893C-64.7951 81.3919 -64.697 82.7931 -66.008 84.2929L-71.9074 91.0422C-73.2184 92.542 -74.6275 92.6406 -76.1346 91.338L-82.9166 85.4763C-84.4237 84.1737 -84.5217 82.7724 -83.2107 81.2726L-77.3114 74.5234Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M156.316 90.2938C156.601 89.9677 156.907 89.9463 157.235 90.2295L158.709 91.5038C159.037 91.787 159.058 92.0916 158.773 92.4176L157.491 93.8848C157.206 94.2109 156.899 94.2323 156.572 93.9491L155.097 92.6749C154.77 92.3917 154.748 92.0871 155.033 91.761L156.316 90.2938Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M130.555 91.362C130.954 90.9056 131.383 90.8756 131.841 91.272L133.905 93.056C134.364 93.4525 134.394 93.8789 133.995 94.3354L132.199 96.3895C131.8 96.846 131.372 96.876 130.913 96.4795L128.849 94.6955C128.39 94.2991 128.36 93.8726 128.759 93.4162L130.555 91.362Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M104.793 92.4302C105.306 91.8433 105.858 91.8047 106.448 92.3144L109.101 94.6082C109.691 95.1179 109.729 95.6662 109.216 96.2531L106.908 98.8941C106.395 99.481 105.844 99.5195 105.254 99.0098L102.6 96.7161C102.01 96.2064 101.972 95.6581 102.485 95.0712L104.793 92.4302Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M79.0321 93.4984C79.6591 92.7811 80.333 92.734 81.0538 93.357L84.2973 96.1604C85.0181 96.7834 85.065 97.4535 84.438 98.1708L81.6166 101.399C80.9896 102.116 80.3157 102.163 79.5949 101.54L76.3513 98.7368C75.6305 98.1138 75.5836 97.4437 76.2106 96.7263L79.0321 93.4984Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M53.2711 94.5667C54.0121 93.719 54.8085 93.6632 55.6604 94.3995L59.4937 97.7126C60.3455 98.4489 60.4009 99.2409 59.66 100.089L56.3255 103.903C55.5845 104.751 54.7881 104.807 53.9362 104.071L50.1029 100.757C49.2511 100.021 49.1957 99.2292 49.9367 98.3815L53.2711 94.5667Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M27.5098 95.6348C28.3647 94.6567 29.2837 94.5924 30.2666 95.4419L34.6896 99.2648C35.6725 100.114 35.7365 101.028 34.8815 102.006L31.034 106.408C30.1791 107.386 29.2601 107.45 28.2772 106.601L23.8542 102.778C22.8713 101.929 22.8073 101.015 23.6623 100.037L27.5098 95.6348Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M1.74875 96.7031C2.71774 95.5945 3.75923 95.5216 4.87317 96.4844L9.88595 100.817C10.9999 101.78 11.0724 102.815 10.1034 103.924L5.74293 108.913C4.77395 110.021 3.73248 110.094 2.61854 109.131L-2.39425 104.799C-3.5082 103.836 -3.58068 102.8 -2.61171 101.692L1.74875 96.7031Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-24.0126 97.7712C-22.9296 96.5322 -21.7656 96.4508 -20.5206 97.5268L-14.9181 102.369C-13.6731 103.445 -13.5921 104.603 -14.6751 105.842L-19.5485 111.417C-20.6315 112.656 -21.7955 112.738 -23.0405 111.662L-28.643 106.819C-29.888 105.743 -29.969 104.586 -28.886 103.347L-24.0126 97.7712Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-49.7736 98.8395C-48.5766 97.4701 -47.2901 97.38 -45.914 98.5694L-39.7218 103.921C-38.3457 105.111 -38.2562 106.39 -39.4532 107.76L-44.8396 113.922C-46.0366 115.291 -47.3231 115.381 -48.6992 114.192L-54.8914 108.84C-56.2675 107.651 -56.357 106.371 -55.16 105.002L-49.7736 98.8395Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-75.535 99.9076C-74.2239 98.4078 -72.8149 98.3092 -71.3078 99.6118L-64.5259 105.474C-63.0187 106.776 -62.9206 108.177 -64.2317 109.677L-70.1311 116.426C-71.442 117.926 -72.8511 118.025 -74.3582 116.722L-81.1402 110.861C-82.6473 109.558 -82.7453 108.157 -81.4344 106.657L-75.535 99.9076Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M158.093 115.678C158.378 115.352 158.684 115.331 159.011 115.614L160.486 116.888C160.813 117.171 160.835 117.476 160.55 117.802L159.267 119.269C158.982 119.595 158.676 119.617 158.348 119.334L156.874 118.059C156.546 117.776 156.525 117.471 156.81 117.145L158.093 115.678Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M132.331 116.747C132.73 116.29 133.159 116.26 133.618 116.657L135.682 118.441C136.14 118.837 136.17 119.264 135.771 119.72L133.976 121.774C133.577 122.231 133.148 122.261 132.689 121.864L130.625 120.08C130.166 119.684 130.137 119.258 130.536 118.801L132.331 116.747Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M106.57 117.815C107.083 117.228 107.634 117.19 108.224 117.699L110.878 119.993C111.468 120.503 111.506 121.051 110.993 121.638L108.685 124.279C108.172 124.866 107.62 124.905 107.03 124.395L104.377 122.101C103.787 121.591 103.749 121.043 104.262 120.456L106.57 117.815Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M80.8083 118.883C81.4353 118.166 82.1092 118.119 82.83 118.742L86.0735 121.545C86.7943 122.168 86.8412 122.838 86.2142 123.556L83.3928 126.784C82.7658 127.501 82.0919 127.548 81.3711 126.925L78.1275 124.122C77.4067 123.499 77.3598 122.829 77.9868 122.111L80.8083 118.883Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M55.0473 119.952C55.7883 119.104 56.5847 119.048 57.4366 119.784L61.2699 123.098C62.1217 123.834 62.1771 124.626 61.4361 125.474L58.1017 129.288C57.3607 130.136 56.5643 130.192 55.7124 129.456L51.8791 126.142C51.0273 125.406 50.9719 124.614 51.7128 123.766L55.0473 119.952Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M29.2863 121.02C30.1413 120.042 31.0602 119.977 32.0431 120.827L36.4661 124.65C37.4491 125.499 37.513 126.413 36.658 127.391L32.8106 131.793C31.9556 132.771 31.0366 132.835 30.0537 131.986L25.6307 128.163C24.6478 127.314 24.5838 126.4 25.4388 125.422L29.2863 121.02Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M3.52494 122.088C4.49394 120.979 5.53543 120.907 6.64937 121.869L11.6621 126.202C12.7761 127.165 12.8486 128.2 11.8796 129.309L7.51913 134.298C6.55015 135.406 5.50868 135.479 4.39474 134.516L-0.618046 130.184C-1.73199 129.221 -1.80448 128.185 -0.835491 127.077L3.52494 122.088Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-22.236 123.156C-21.1531 121.917 -19.9891 121.836 -18.7441 122.912L-13.1416 127.754C-11.8966 128.83 -11.8155 129.988 -12.8985 131.227L-17.772 136.802C-18.855 138.041 -20.0189 138.123 -21.264 137.047L-26.8665 132.204C-28.1115 131.128 -28.1925 129.971 -27.1095 128.732L-22.236 123.156Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-47.9974 124.224C-46.8004 122.855 -45.5139 122.765 -44.1378 123.954L-37.9456 129.306C-36.5695 130.496 -36.48 131.775 -37.677 133.144L-43.0634 139.307C-44.2604 140.676 -45.5469 140.766 -46.923 139.577L-53.1152 134.225C-54.4913 133.036 -54.5808 131.756 -53.3838 130.387L-47.9974 124.224Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-73.7585 125.293C-72.4474 123.793 -71.0384 123.694 -69.5313 124.997L-62.7494 130.859C-61.2422 132.161 -61.1441 133.562 -62.4552 135.062L-68.3545 141.811C-69.6655 143.311 -71.0745 143.41 -72.5817 142.107L-79.3637 136.246C-80.8707 134.943 -80.9688 133.542 -79.6579 132.042L-73.7585 125.293Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M159.869 141.063C160.154 140.737 160.46 140.716 160.788 140.999L162.262 142.273C162.59 142.556 162.611 142.861 162.326 143.187L161.043 144.654C160.758 144.98 160.452 145.002 160.125 144.718L158.65 143.444C158.323 143.161 158.301 142.856 158.586 142.53L159.869 141.063Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M134.108 142.131C134.507 141.675 134.936 141.645 135.394 142.041L137.458 143.825C137.917 144.222 137.947 144.648 137.548 145.105L135.752 147.159C135.353 147.615 134.925 147.645 134.466 147.249L132.402 145.465C131.943 145.068 131.913 144.642 132.312 144.185L134.108 142.131Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M108.346 143.199C108.859 142.613 109.411 142.574 110 143.084L112.654 145.377C113.244 145.887 113.282 146.435 112.769 147.022L110.461 149.663C109.948 150.25 109.397 150.289 108.807 149.779L106.153 147.485C105.563 146.976 105.525 146.427 106.038 145.84L108.346 143.199Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M82.585 144.268C83.212 143.55 83.8859 143.503 84.6067 144.126L87.8503 146.93C88.571 147.553 88.6179 148.223 87.9909 148.94L85.1695 152.168C84.5425 152.885 83.8686 152.932 83.1478 152.31L79.9043 149.506C79.1835 148.883 79.1366 148.213 79.7636 147.496L82.585 144.268Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M56.8237 145.336C57.5647 144.488 58.3611 144.432 59.2129 145.169L63.0462 148.482C63.8981 149.218 63.9535 150.01 63.2125 150.858L59.8781 154.673C59.1371 155.52 58.3406 155.576 57.4888 154.84L53.6555 151.527C52.8037 150.79 52.7482 149.998 53.4892 149.151L56.8237 145.336Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M31.0627 146.404C31.9177 145.426 32.8366 145.362 33.8195 146.211L38.2425 150.034C39.2254 150.884 39.2894 151.797 38.4344 152.776L34.5869 157.177C33.732 158.155 32.813 158.22 31.8301 157.37L27.4071 153.547C26.4242 152.698 26.3602 151.784 27.2152 150.806L31.0627 146.404Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M5.30135 147.472C6.27032 146.364 7.3118 146.291 8.42574 147.254L13.4385 151.586C14.5525 152.549 14.6249 153.585 13.656 154.693L9.29551 159.682C8.32653 160.79 7.28506 160.863 6.17111 159.9L1.15833 155.568C0.0443712 154.605 -0.0281092 153.569 0.940871 152.461L5.30135 147.472Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-20.4597 148.541C-19.3767 147.302 -18.2127 147.22 -16.9677 148.296L-11.3652 153.138C-10.1202 154.215 -10.0392 155.372 -11.1221 156.611L-15.9956 162.186C-17.0786 163.425 -18.2426 163.507 -19.4876 162.431L-25.0901 157.589C-26.3351 156.512 -26.4161 155.355 -25.3331 154.116L-20.4597 148.541Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-46.2207 149.609C-45.0237 148.239 -43.7372 148.149 -42.3611 149.339L-36.1688 154.691C-34.7928 155.88 -34.7033 157.159 -35.9003 158.529L-41.2867 164.691C-42.4837 166.061 -43.7702 166.151 -45.1463 164.961L-51.3385 159.609C-52.7146 158.42 -52.8041 157.14 -51.6071 155.771L-46.2207 149.609Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-71.9821 150.677C-70.671 149.177 -69.262 149.078 -67.7549 150.381L-60.973 156.243C-59.4658 157.545 -59.3677 158.947 -60.6788 160.446L-66.5781 167.196C-67.8891 168.696 -69.2981 168.794 -70.8053 167.492L-77.5873 161.63C-79.0944 160.327 -79.1924 158.926 -77.8815 157.426L-71.9821 150.677Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M161.645 166.447C161.93 166.121 162.237 166.1 162.564 166.383L164.039 167.657C164.366 167.941 164.388 168.245 164.103 168.571L162.82 170.038C162.535 170.365 162.229 170.386 161.901 170.103L160.427 168.828C160.099 168.545 160.078 168.241 160.363 167.915L161.645 166.447Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M135.884 167.516C136.283 167.059 136.712 167.029 137.171 167.426L139.235 169.21C139.693 169.606 139.723 170.032 139.324 170.489L137.529 172.543C137.13 173 136.701 173.03 136.242 172.633L134.178 170.849C133.719 170.453 133.69 170.026 134.089 169.57L135.884 167.516Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M110.123 168.584C110.636 167.997 111.187 167.958 111.777 168.468L114.431 170.762C115.021 171.272 115.059 171.82 114.546 172.407L112.238 175.048C111.725 175.635 111.173 175.673 110.584 175.163L107.93 172.87C107.34 172.36 107.302 171.812 107.815 171.225L110.123 168.584Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M84.3614 169.652C84.9884 168.935 85.6623 168.888 86.3831 169.51L89.6266 172.314C90.3474 172.937 90.3943 173.607 89.7673 174.324L86.9459 177.552C86.3189 178.27 85.645 178.317 84.9242 177.694L81.6806 174.89C80.9598 174.267 80.9129 173.597 81.5399 172.88L84.3614 169.652Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M58.6004 170.72C59.3414 169.873 60.1378 169.817 60.9897 170.553L64.823 173.866C65.6748 174.602 65.7302 175.394 64.9892 176.242L61.6548 180.057C60.9138 180.905 60.1174 180.96 59.2655 180.224L55.4322 176.911C54.5804 176.175 54.5249 175.383 55.2659 174.535L58.6004 170.72Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M32.839 171.788C33.6941 170.81 34.613 170.746 35.5959 171.595L40.0189 175.418C41.0018 176.268 41.0658 177.182 40.2108 178.16L36.3633 182.562C35.5083 183.54 34.5894 183.604 33.6065 182.754L29.1835 178.932C28.2006 178.082 28.1366 177.168 28.9916 176.19L32.839 171.788Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M7.07806 172.857C8.04705 171.748 9.08851 171.675 10.2025 172.638L15.2152 176.971C16.3292 177.933 16.4017 178.969 15.4327 180.078L11.0722 185.066C10.1032 186.175 9.06178 186.248 7.94782 185.285L2.93505 180.952C1.8211 179.989 1.74862 178.954 2.71761 177.845L7.07806 172.857Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-18.6833 173.925C-17.6003 172.686 -16.4363 172.604 -15.1913 173.68L-9.58878 178.523C-8.34377 179.599 -8.26278 180.756 -9.34578 181.995L-14.2192 187.571C-15.3022 188.81 -16.4662 188.891 -17.7112 187.815L-23.3137 182.973C-24.5587 181.897 -24.6397 180.739 -23.5567 179.5L-18.6833 173.925Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-44.4443 174.993C-43.2473 173.624 -41.9608 173.534 -40.5847 174.723L-34.3925 180.075C-33.0164 181.264 -32.9269 182.544 -34.1239 183.913L-39.5103 190.075C-40.7073 191.445 -41.9938 191.535 -43.3699 190.345L-49.5621 184.993C-50.9382 183.804 -51.0277 182.525 -49.8307 181.155L-44.4443 174.993Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-70.2054 176.061C-68.8943 174.561 -67.4853 174.463 -65.9782 175.765L-59.1962 181.627C-57.6891 182.93 -57.591 184.331 -58.902 185.831L-64.8014 192.58C-66.1124 194.08 -67.5214 194.179 -69.0286 192.876L-75.8105 187.014C-77.3176 185.712 -77.4157 184.31 -76.1047 182.81L-70.2054 176.061Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M163.422 191.832C163.707 191.506 164.013 191.484 164.341 191.767L165.815 193.042C166.143 193.325 166.164 193.629 165.879 193.956L164.597 195.423C164.312 195.749 164.005 195.77 163.678 195.487L162.203 194.213C161.876 193.93 161.854 193.625 162.139 193.299L163.422 191.832Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M137.661 192.9C138.06 192.443 138.489 192.413 138.947 192.81L141.011 194.594C141.47 194.99 141.5 195.417 141.101 195.873L139.305 197.927C138.906 198.384 138.478 198.414 138.019 198.017L135.955 196.233C135.496 195.837 135.466 195.411 135.865 194.954L137.661 192.9Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M111.899 193.968C112.412 193.381 112.964 193.343 113.554 193.852L116.207 196.146C116.797 196.656 116.835 197.204 116.322 197.791L114.014 200.432C113.501 201.019 112.95 201.057 112.36 200.548L109.706 198.254C109.116 197.744 109.078 197.196 109.591 196.609L111.899 193.968Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M86.1381 195.036C86.7651 194.319 87.439 194.272 88.1598 194.895L91.4033 197.698C92.1241 198.321 92.171 198.991 91.544 199.709L88.7226 202.937C88.0956 203.654 87.4217 203.701 86.7009 203.078L83.4573 200.275C82.7366 199.652 82.6897 198.982 83.3166 198.264L86.1381 195.036Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M60.3768 196.104C61.1178 195.257 61.9142 195.201 62.766 195.937L66.5993 199.25C67.4512 199.987 67.5066 200.779 66.7656 201.626L63.4311 205.441C62.6901 206.289 61.8937 206.345 61.0419 205.608L57.2086 202.295C56.3567 201.559 56.3013 200.767 57.0423 199.919L60.3768 196.104Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M34.6158 197.173C35.4708 196.195 36.3897 196.13 37.3726 196.98L41.7956 200.803C42.7785 201.652 42.8425 202.566 41.9875 203.544L38.14 207.946C37.2851 208.924 36.3661 208.988 35.3832 208.139L30.9602 204.316C29.9773 203.466 29.9133 202.553 30.7683 201.574L34.6158 197.173Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M8.85443 198.241C9.82341 197.132 10.8649 197.059 11.9788 198.022L16.9916 202.355C18.1056 203.318 18.178 204.353 17.2091 205.462L12.8486 210.45C11.8796 211.559 10.8381 211.632 9.72419 210.669L4.71143 206.337C3.59748 205.374 3.52499 204.338 4.49398 203.229L8.85443 198.241Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-16.9066 199.309C-15.8236 198.07 -14.6596 197.989 -13.4146 199.065L-7.81207 203.907C-6.56706 204.983 -6.48607 206.141 -7.56905 207.38L-12.4425 212.955C-13.5255 214.194 -14.6895 214.276 -15.9345 213.199L-21.537 208.357C-22.782 207.281 -22.863 206.124 -21.78 204.885L-16.9066 199.309Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-42.6679 200.377C-41.4709 199.008 -40.1844 198.918 -38.8083 200.107L-32.6161 205.459C-31.24 206.649 -31.1505 207.928 -32.3475 209.297L-37.7339 215.46C-38.9309 216.829 -40.2174 216.919 -41.5935 215.73L-47.7857 210.378C-49.1618 209.188 -49.2513 207.909 -48.0544 206.54L-42.6679 200.377Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-68.429 201.446C-67.1179 199.946 -65.7089 199.847 -64.2018 201.15L-57.4199 207.011C-55.9127 208.314 -55.8146 209.715 -57.1257 211.215L-63.0251 217.964C-64.336 219.464 -65.7451 219.563 -67.2522 218.26L-74.0342 212.398C-75.5413 211.096 -75.6393 209.695 -74.3284 208.195L-68.429 201.446Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M165.199 217.216C165.484 216.89 165.79 216.869 166.117 217.152L167.592 218.426C167.919 218.709 167.941 219.014 167.656 219.34L166.373 220.807C166.088 221.133 165.782 221.155 165.454 220.871L163.98 219.597C163.652 219.314 163.631 219.009 163.916 218.683L165.199 217.216Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M139.437 218.284C139.836 217.828 140.265 217.798 140.724 218.194L142.788 219.978C143.246 220.375 143.276 220.801 142.877 221.258L141.082 223.312C140.683 223.768 140.254 223.798 139.795 223.402L137.731 221.618C137.273 221.221 137.243 220.795 137.642 220.338L139.437 218.284Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M113.676 219.352C114.189 218.766 114.741 218.727 115.33 219.237L117.984 221.53C118.574 222.04 118.612 222.588 118.099 223.175L115.791 225.816C115.278 226.403 114.726 226.442 114.137 225.932L111.483 223.638C110.893 223.129 110.855 222.58 111.368 221.993L113.676 219.352Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M87.9145 220.421C88.5415 219.703 89.2154 219.656 89.9362 220.279L93.1797 223.083C93.9005 223.706 93.9474 224.376 93.3204 225.093L90.499 228.321C89.872 229.038 89.1981 229.085 88.4773 228.462L85.2337 225.659C84.5129 225.036 84.4661 224.366 85.093 223.649L87.9145 220.421Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M62.1535 221.489C62.8945 220.641 63.6909 220.585 64.5427 221.322L68.3761 224.635C69.2279 225.371 69.2833 226.163 68.5423 227.011L65.2079 230.826C64.4669 231.673 63.6705 231.729 62.8186 230.993L58.9853 227.68C58.1335 226.943 58.078 226.151 58.819 225.304L62.1535 221.489Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M36.3921 222.557C37.2471 221.579 38.1661 221.515 39.149 222.364L43.572 226.187C44.5549 227.036 44.6189 227.95 43.7639 228.928L39.9164 233.33C39.0614 234.308 38.1425 234.373 37.1596 233.523L32.7366 229.7C31.7537 228.851 31.6897 227.937 32.5447 226.959L36.3921 222.557Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M10.6312 223.625C11.6001 222.517 12.6416 222.444 13.7555 223.407L18.7683 227.739C19.8823 228.702 19.9548 229.738 18.9858 230.846L14.6253 235.835C13.6563 236.943 12.6149 237.016 11.5009 236.053L6.48813 231.721C5.37419 230.758 5.30171 229.722 6.27069 228.614L10.6312 223.625Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-15.1302 224.693C-14.0472 223.454 -12.8832 223.373 -11.6382 224.449L-6.03569 229.291C-4.79068 230.367 -4.70968 231.525 -5.79267 232.764L-10.6661 238.339C-11.7491 239.578 -12.9131 239.66 -14.1581 238.584L-19.7606 233.741C-21.0056 232.665 -21.0866 231.508 -20.0036 230.269L-15.1302 224.693Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-40.8912 225.762C-39.6942 224.392 -38.4077 224.302 -37.0316 225.492L-30.8394 230.844C-29.4633 232.033 -29.3738 233.312 -30.5708 234.682L-35.9572 240.844C-37.1542 242.213 -38.4407 242.303 -39.8168 241.114L-46.009 235.762C-47.3851 234.573 -47.4746 233.293 -46.2776 231.924L-40.8912 225.762Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-66.6526 226.83C-65.3416 225.33 -63.9325 225.231 -62.4254 226.534L-55.6435 232.396C-54.1363 233.698 -54.0382 235.1 -55.3493 236.599L-61.2487 243.349C-62.5596 244.848 -63.9687 244.947 -65.4758 243.644L-72.2578 237.783C-73.7649 236.48 -73.8629 235.079 -72.552 233.579L-66.6526 226.83Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M166.975 242.6C167.26 242.274 167.566 242.253 167.894 242.536L169.368 243.81C169.696 244.093 169.717 244.398 169.432 244.724L168.15 246.191C167.865 246.517 167.558 246.539 167.231 246.256L165.756 244.981C165.429 244.698 165.407 244.394 165.692 244.068L166.975 242.6Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M141.214 243.668C141.613 243.212 142.041 243.182 142.5 243.578L144.564 245.362C145.023 245.759 145.053 246.185 144.654 246.642L142.858 248.696C142.459 249.152 142.03 249.182 141.572 248.786L139.508 247.002C139.049 246.605 139.019 246.179 139.418 245.723L141.214 243.668Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M115.453 244.737C115.966 244.15 116.517 244.111 117.107 244.621L119.76 246.915C120.35 247.424 120.389 247.973 119.876 248.56L117.567 251.201C117.054 251.787 116.503 251.826 115.913 251.316L113.259 249.023C112.669 248.513 112.631 247.965 113.144 247.378L115.453 244.737Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M89.6912 245.805C90.3182 245.088 90.9921 245.04 91.7129 245.663L94.9564 248.467C95.6772 249.09 95.7241 249.76 95.0971 250.477L92.2757 253.705C91.6487 254.423 90.9748 254.47 90.254 253.847L87.0104 251.043C86.2897 250.42 86.2428 249.75 86.8697 249.033L89.6912 245.805Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M63.9299 246.873C64.6709 246.025 65.4673 245.97 66.3191 246.706L70.1524 250.019C71.0043 250.755 71.0597 251.547 70.3187 252.395L66.9842 256.21C66.2433 257.058 65.4468 257.113 64.595 256.377L60.7617 253.064C59.9098 252.328 59.8544 251.536 60.5954 250.688L63.9299 246.873Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M38.1689 247.941C39.0239 246.963 39.9428 246.899 40.9257 247.748L45.3487 251.571C46.3316 252.421 46.3956 253.335 45.5406 254.313L41.6932 258.714C40.8381 259.693 39.9192 259.757 38.9363 258.907L34.5133 255.085C33.5304 254.235 33.4664 253.321 34.3214 252.343L38.1689 247.941Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M12.4075 249.009C13.3765 247.901 14.418 247.828 15.5319 248.791L20.5447 253.123C21.6587 254.086 21.7311 255.122 20.7622 256.23L16.4017 261.219C15.4327 262.328 14.3912 262.4 13.2773 261.438L8.26452 257.105C7.15056 256.142 7.07808 255.107 8.04708 253.998L12.4075 249.009Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-13.3535 250.078C-12.2705 248.839 -11.1065 248.757 -9.86148 249.833L-4.25898 254.676C-3.01398 255.752 -2.93296 256.909 -4.01596 258.148L-8.8894 263.724C-9.97238 264.963 -11.1364 265.044 -12.3814 263.968L-17.9839 259.126C-19.2289 258.05 -19.3099 256.892 -18.2269 255.653L-13.3535 250.078Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-39.1148 251.146C-37.9178 249.776 -36.6313 249.686 -35.2553 250.876L-29.063 256.228C-27.6869 257.417 -27.5974 258.697 -28.7944 260.066L-34.1808 266.228C-35.3778 267.598 -36.6643 267.688 -38.0404 266.498L-44.2326 261.146C-45.6087 259.957 -45.6982 258.678 -44.5012 257.308L-39.1148 251.146Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-64.8759 252.214C-63.5648 250.714 -62.1558 250.616 -60.6487 251.918L-53.8668 257.78C-52.3596 259.083 -52.2615 260.484 -53.5726 261.984L-59.472 268.733C-60.7829 270.233 -62.192 270.331 -63.6991 269.029L-70.4811 263.167C-71.9882 261.864 -72.0862 260.463 -70.7753 258.963L-64.8759 252.214Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M162.927 0.754711C163.18 0.464833 163.452 0.445835 163.744 0.697519L165.054 1.83021C165.346 2.0819 165.365 2.35262 165.111 2.64253L163.971 3.94672C163.717 4.23663 163.445 4.25563 163.154 4.00391L161.844 2.87118C161.552 2.61946 161.533 2.34877 161.787 2.05889L162.927 0.754711Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M137.139 1.74363C137.519 1.30878 137.928 1.28021 138.365 1.65787L140.33 3.35691C140.767 3.73448 140.796 4.14064 140.416 4.57537L138.706 6.53165C138.326 6.96651 137.917 6.99507 137.48 6.61741L135.515 4.91837C135.078 4.54081 135.049 4.13465 135.429 3.69989L137.139 1.74363Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M111.352 2.7317C111.859 2.15197 112.403 2.11387 112.986 2.61735L115.607 4.88254C116.189 5.38602 116.227 5.92762 115.72 6.50735L113.44 9.11555C112.934 9.69528 112.389 9.73335 111.807 9.22987L109.186 6.96468C108.603 6.4612 108.565 5.9196 109.072 5.33987L111.352 2.7317Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M85.5646 3.72046C86.198 2.99595 86.8785 2.94832 87.6067 3.57754L90.883 6.4093C91.6112 7.03849 91.6586 7.71554 91.0251 8.44005L88.1752 11.7005C87.5417 12.425 86.8612 12.4727 86.1331 11.8435L82.8567 9.0117C82.1286 8.38249 82.0812 7.70544 82.7146 6.98093L85.5646 3.72046Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M59.777 4.70864C60.537 3.83928 61.3539 3.78209 62.2276 4.53715L66.1592 7.93546C67.0329 8.69049 67.0897 9.50278 66.3297 10.3721L62.9097 14.2849C62.1497 15.1543 61.3329 15.2115 60.4592 14.4565L56.5276 11.0581C55.6539 10.3031 55.5971 9.4908 56.3571 8.62144L59.777 4.70864Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M33.99 5.69688C34.8769 4.68252 35.8295 4.61585 36.849 5.49679L41.4358 9.46125C42.4553 10.3422 42.5216 11.2899 41.6348 12.3043L37.6449 16.869C36.758 17.8834 35.8054 17.95 34.7859 17.0691L30.1991 13.1046C29.1796 12.2237 29.1133 11.2759 30.0001 10.2616L33.99 5.69688Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M8.20265 6.68514C9.21579 5.52583 10.3052 5.44957 11.47 6.45648L16.7121 10.9873C17.8769 11.9942 17.9527 13.0772 16.9395 14.2365L12.3796 19.4533C11.3664 20.6126 10.277 20.6888 9.11221 19.682L3.87009 15.1512C2.70534 14.1442 2.62955 13.0612 3.6427 11.9019L8.20265 6.68514Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-17.5845 7.67405C-16.4446 6.36973 -15.2193 6.28398 -13.9088 7.4168L-8.01142 12.5138C-6.70089 13.6466 -6.61561 14.865 -7.75559 16.1693L-12.8855 22.038C-14.0255 23.3423 -15.2508 23.4281 -16.5613 22.2953L-22.4587 17.1983C-23.7692 16.0655 -23.8545 14.847 -22.7145 13.5427L-17.5845 7.67405Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-43.3721 8.66213C-42.1055 7.21294 -40.744 7.11765 -39.2879 8.37629L-32.7353 14.0398C-31.2792 15.2984 -31.1845 16.6521 -32.451 18.1013L-38.151 24.6223C-39.4175 26.0715 -40.7791 26.1668 -42.2352 24.9081L-48.7878 19.2446C-50.2439 17.986 -50.3386 16.6323 -49.072 15.1831L-43.3721 8.66213Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-69.1593 9.65022C-67.7662 8.05627 -66.2683 7.95146 -64.6667 9.3358L-57.4588 15.5659C-55.8572 16.9502 -55.753 18.4394 -57.1461 20.0333L-63.4161 27.2066C-64.8092 28.8006 -66.3071 28.9054 -67.9087 27.521L-75.1166 21.291C-76.7182 19.9066 -76.8224 18.4175 -75.4292 16.8235L-69.1593 9.65022Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-94.9462 10.6388C-93.4262 8.89995 -91.7925 8.7856 -90.0452 10.2958L-82.182 17.092C-80.4346 18.6022 -80.3209 20.227 -81.8409 21.9658L-88.6808 29.791C-90.2008 31.5298 -91.8345 31.6442 -93.5819 30.134L-101.445 23.3378C-103.192 21.8276 -103.306 20.2028 -101.786 18.464L-94.9462 10.6388Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M164.703 26.1396C164.956 25.8498 165.228 25.8308 165.52 26.0825L166.83 27.2152C167.122 27.4669 167.141 27.7375 166.887 28.0275L165.747 29.3316C165.493 29.6216 165.222 29.6406 164.93 29.3888L163.62 28.2561C163.328 28.0044 163.31 27.7337 163.563 27.4438L164.703 26.1396Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M138.916 27.1279C139.296 26.693 139.704 26.6645 140.141 27.0421L142.107 28.7412C142.544 29.1189 142.572 29.5249 142.192 29.9597L140.482 31.9159C140.102 32.3507 139.694 32.3792 139.257 32.0017L137.291 30.3026C136.854 29.9251 136.826 29.519 137.206 29.0842L138.916 27.1279Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M113.129 28.1161C113.636 27.5363 114.18 27.4982 114.762 28.0017L117.383 30.2669C117.966 30.7704 118.004 31.312 117.497 31.8916L115.217 34.5C114.71 35.0796 114.166 35.1177 113.583 34.6144L110.962 32.3489C110.38 31.8456 110.342 31.304 110.849 30.7242L113.129 28.1161Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M87.341 29.1047C87.9744 28.3802 88.6549 28.3326 89.3831 28.9618L92.6594 31.7936C93.3876 32.4228 93.4349 33.0997 92.8015 33.8243L89.9516 37.0848C89.3181 37.8094 88.6376 37.8571 87.9095 37.2277L84.6331 34.396C83.905 33.7666 83.8576 33.0897 84.491 32.3652L87.341 29.1047Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M61.5534 30.0929C62.3134 29.2235 63.1303 29.1664 64.0039 29.9214L67.9355 33.3197C68.8092 34.0748 68.8661 34.887 68.1061 35.7565L64.6861 39.6691C63.9261 40.5386 63.1093 40.5957 62.2356 39.8406L58.304 36.4425C57.4303 35.6874 57.3735 34.8751 58.1335 34.0057L61.5534 30.0929Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M35.7664 31.0811C36.6533 30.0668 37.6059 30.0001 38.6254 30.8811L43.2122 34.8455C44.2317 35.7265 44.298 36.6743 43.4112 37.6886L39.4212 42.2533C38.5344 43.2676 37.5817 43.3342 36.5623 42.4533L31.9754 38.4889C30.956 37.608 30.8896 36.6602 31.7765 35.6459L35.7664 31.0811Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M9.97883 32.0701C10.992 30.9108 12.0814 30.8345 13.2462 31.8414L18.4883 36.3722C19.653 37.3791 19.7288 38.4621 18.7157 39.6214L14.1557 44.8382C13.1426 45.9976 12.0531 46.0738 10.8884 45.0669L5.64627 40.5361C4.48151 39.5291 4.40573 38.4462 5.41888 37.2869L9.97883 32.0701Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-15.8082 33.0583C-14.6682 31.754 -13.4429 31.6683 -12.1324 32.8011L-6.23503 37.898C-4.9245 39.0308 -4.83924 40.2493 -5.97922 41.5535L-11.1092 47.4224C-12.2492 48.7266 -13.4744 48.8123 -14.7849 47.6796L-20.6823 42.5825C-21.9929 41.4497 -22.0781 40.2313 -20.9381 38.927L-15.8082 33.0583Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-41.5954 34.0465C-40.3288 32.5973 -38.9672 32.502 -37.5112 33.7607L-30.9586 39.4242C-29.5025 40.6828 -29.4077 42.0367 -30.6743 43.4857L-36.3743 50.0067C-37.6408 51.4557 -39.0024 51.551 -40.4584 50.2925L-47.0111 44.629C-48.4671 43.3705 -48.5619 42.0167 -47.2953 40.5675L-41.5954 34.0465Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-67.3828 35.0352C-65.9896 33.4413 -64.4918 33.3365 -62.8902 34.7208L-55.6822 40.9509C-54.0807 42.3353 -53.9764 43.8244 -55.3696 45.4185L-61.6395 52.5916C-63.0327 54.1857 -64.5306 54.2905 -66.1321 52.906L-73.34 46.6761C-74.9416 45.2916 -75.0459 43.8025 -73.6527 42.2085L-67.3828 35.0352Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-93.1701 36.0233C-91.6502 34.2845 -90.0165 34.1701 -88.2691 35.6803L-80.4059 42.4765C-78.6586 43.9867 -78.5449 45.6114 -80.0648 47.3503L-86.9048 55.1756C-88.4248 56.9145 -90.0584 57.0288 -91.8058 55.5185L-99.669 48.7223C-101.416 47.212 -101.53 45.5873 -100.01 43.8485L-93.1701 36.0233Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M166.479 51.5239C166.733 51.234 167.004 51.215 167.296 51.4668L168.607 52.5995C168.899 52.8512 168.918 53.1218 168.664 53.4118L167.524 54.716C167.269 55.0059 166.998 55.0249 166.707 54.7731L165.396 53.6404C165.105 53.3886 165.086 53.118 165.339 52.8281L166.479 51.5239Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M140.692 52.5121C141.072 52.0773 141.481 52.0488 141.917 52.4263L143.883 54.1254C144.32 54.5029 144.348 54.9091 143.968 55.3438L142.258 57.3001C141.878 57.7348 141.47 57.7634 141.033 57.3859L139.067 55.6868C138.631 55.3093 138.602 54.9031 138.982 54.4684L140.692 52.5121Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M114.905 53.5009C115.412 52.9213 115.956 52.8832 116.539 53.3865L119.16 55.6519C119.742 56.1552 119.78 56.697 119.273 57.2765L116.993 59.8849C116.487 60.4645 115.942 60.5026 115.36 59.9993L112.739 57.7338C112.156 57.2305 112.118 56.6888 112.625 56.1093L114.905 53.5009Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M89.1177 54.4892C89.7511 53.7645 90.4316 53.7169 91.1598 54.3463L94.4361 57.178C95.1643 57.8074 95.2116 58.4841 94.5782 59.2088L91.7283 62.4693C91.0949 63.1939 90.4143 63.2415 89.6862 62.6122L86.4098 59.7804C85.6817 59.1511 85.6343 58.4743 86.2677 57.7497L89.1177 54.4892Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M63.3301 55.4773C64.0902 54.6079 64.907 54.5507 65.7807 55.3058L69.7123 58.7039C70.5859 59.4591 70.6428 60.2714 69.8828 61.1408L66.4628 65.0534C65.7029 65.9229 64.886 65.9801 64.0123 65.225L60.0807 61.8268C59.2071 61.0717 59.1502 60.2594 59.9102 59.3899L63.3301 55.4773Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M37.5426 56.466C38.4295 55.4517 39.3821 55.385 40.4016 56.2659L44.9884 60.2304C46.0079 61.1113 46.0742 62.0592 45.1874 63.0735L41.1974 67.6381C40.3106 68.6525 39.3579 68.7191 38.3385 67.8382L33.7516 63.8738C32.7322 62.9929 32.6658 62.045 33.5527 61.0307L37.5426 56.466Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M11.7555 57.4544C12.7687 56.2951 13.8581 56.2189 15.0229 57.2258L20.265 61.7566C21.4298 62.7636 21.5056 63.8465 20.4924 65.0058L15.9325 70.2226C14.9193 71.382 13.8299 71.4582 12.6651 70.4513L7.423 65.9205C6.25825 64.9135 6.18246 63.8306 7.19561 62.6713L11.7555 57.4544Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-14.0318 58.4425C-12.8918 57.1383 -11.6666 57.0526 -10.356 58.1852L-4.45865 63.2824C-3.14812 64.4151 -3.06286 65.6336 -4.20284 66.9377L-9.33277 72.8067C-10.4728 74.1109 -11.698 74.1966 -13.0085 73.0639L-18.9059 67.9667C-20.2165 66.834 -20.3017 65.6156 -19.1618 64.3114L-14.0318 58.4425Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-39.8192 59.4313C-38.5526 57.9823 -37.1911 57.887 -35.735 59.1455L-29.1824 64.809C-27.7263 66.0675 -27.6316 67.4215 -28.8981 68.8705L-34.5981 75.3915C-35.8646 76.8405 -37.2262 76.9358 -38.6822 75.6773L-45.2349 70.0138C-46.6909 68.7553 -46.7857 67.4013 -45.5191 65.9523L-39.8192 59.4313Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-65.6064 60.4196C-64.2133 58.8255 -62.7154 58.7207 -61.1138 60.1052L-53.9059 66.3351C-52.3043 67.7196 -52.2001 69.2086 -53.5932 70.8027L-59.8631 77.9758C-61.2563 79.5699 -62.7542 79.6747 -64.3558 78.2902L-71.5637 72.0603C-73.1653 70.6758 -73.2695 69.1868 -71.8763 67.5927L-65.6064 60.4196Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-91.3934 61.4077C-89.8735 59.6688 -88.2398 59.5545 -86.4924 61.0647L-78.6292 67.861C-76.8819 69.3712 -76.7682 70.9958 -78.2881 72.7348L-85.1281 80.56C-86.648 82.2989 -88.2817 82.4132 -90.0291 80.903L-97.8923 74.1067C-99.6396 72.5965 -99.7533 70.9719 -98.2334 69.2329L-91.3934 61.4077Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M168.256 76.9083C168.509 76.6184 168.781 76.5994 169.073 76.8511L170.383 77.9838C170.676 78.2356 170.694 78.5062 170.44 78.7961L169.3 80.1003C169.046 80.3903 168.775 80.4093 168.483 80.1575L167.173 79.0248C166.882 78.773 166.863 78.5024 167.116 78.2125L168.256 76.9083Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M142.469 77.8964C142.849 77.4617 143.257 77.4331 143.694 77.8107L145.66 79.5097C146.097 79.8873 146.125 80.2934 145.745 80.7282L144.035 82.6845C143.655 83.1192 143.247 83.1478 142.81 82.7702L140.844 81.0712C140.407 80.6936 140.379 80.2874 140.759 79.8527L142.469 77.8964Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M116.681 78.8851C117.188 78.3055 117.732 78.2675 118.315 78.7708L120.936 81.0362C121.519 81.5395 121.557 82.0812 121.05 82.6608L118.77 85.2692C118.263 85.849 117.719 85.8871 117.136 85.3835L114.515 83.1181C113.932 82.6148 113.894 82.0731 114.401 81.4935L116.681 78.8851Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M90.8941 79.8734C91.5275 79.1488 92.208 79.1012 92.9362 79.7305L96.2125 82.5623C96.9407 83.1917 96.988 83.8684 96.3546 84.593L93.5047 87.8535C92.8713 88.5782 92.1907 88.6258 91.4626 87.9964L88.1862 85.1647C87.4581 84.5353 87.4107 83.8586 88.0441 83.1339L90.8941 79.8734Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M65.1065 80.8616C65.8665 79.9921 66.6834 79.935 67.5571 80.6901L71.4886 84.0882C72.3623 84.8434 72.4192 85.6556 71.6592 86.5251L68.2392 90.4377C67.4792 91.3072 66.6624 91.3643 65.7887 90.6092L61.8571 87.2111C60.9834 86.456 60.9266 85.6437 61.6866 84.7742L65.1065 80.8616Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M39.3193 81.8504C40.2062 80.8361 41.1588 80.7694 42.1783 81.6503L46.7651 85.6148C47.7846 86.4957 47.8509 87.4435 46.9641 88.4578L42.9741 93.0225C42.0873 94.0371 41.1347 94.1037 40.1152 93.2226L35.5283 89.2581C34.5089 88.3772 34.4425 87.4294 35.3294 86.4151L39.3193 81.8504Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M13.5319 82.8387C14.5451 81.6793 15.6345 81.6031 16.7993 82.6101L22.0414 87.1409C23.2062 88.1478 23.2819 89.2307 22.2688 90.3901L17.7088 95.6069C16.6957 96.766 15.6062 96.8422 14.4415 95.8355L9.19936 91.3047C8.03462 90.2978 7.95883 89.2149 8.97198 88.0555L13.5319 82.8387Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-12.2551 83.8268C-11.1151 82.5226 -9.88985 82.4369 -8.57931 83.5696L-2.68194 88.6668C-1.37141 89.7994 -1.28614 91.0179 -2.42612 92.3221L-7.55606 98.191C-8.69605 99.4952 -9.9213 99.581 -11.2318 98.4483L-17.1292 93.3511C-18.4398 92.2184 -18.525 90.9999 -17.385 89.6957L-12.2551 83.8268Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-38.0425 84.8156C-36.7759 83.3666 -35.4143 83.2713 -33.9583 84.5298L-27.4057 90.1933C-25.9496 91.4518 -25.8548 92.8058 -27.1214 94.2548L-32.8214 100.776C-34.0879 102.225 -35.4495 102.32 -36.9055 101.062L-43.4582 95.3981C-44.9142 94.1397 -45.009 92.7857 -43.7424 91.3366L-38.0425 84.8156Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-63.8297 85.804C-62.4366 84.2099 -60.9387 84.1051 -59.3371 85.4896L-52.1292 91.7194C-50.5276 93.1039 -50.4234 94.593 -51.8165 96.1871L-58.0864 103.36C-59.4796 104.954 -60.9775 105.059 -62.579 103.675L-69.787 97.4447C-71.3886 96.0602 -71.4928 94.5712 -70.0996 92.9771L-63.8297 85.804Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-89.617 86.792C-88.0971 85.0531 -86.4634 84.9387 -84.716 86.449L-76.8528 93.2452C-75.1055 94.7555 -74.9918 96.3801 -76.5118 98.119L-83.3517 105.944C-84.8717 107.683 -86.5053 107.797 -88.2527 106.287L-96.1159 99.491C-97.8633 97.9807 -97.9769 96.3561 -96.4569 94.6172L-89.617 86.792Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M170.032 102.293C170.286 102.003 170.557 101.984 170.849 102.235L172.16 103.368C172.452 103.62 172.471 103.89 172.217 104.18L171.077 105.485C170.822 105.775 170.551 105.794 170.26 105.542L168.949 104.409C168.658 104.157 168.639 103.887 168.892 103.597L170.032 102.293Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M144.245 103.281C144.625 102.846 145.034 102.817 145.47 103.195L147.436 104.894C147.873 105.272 147.902 105.678 147.522 106.112L145.812 108.069C145.432 108.503 145.023 108.532 144.586 108.154L142.621 106.455C142.184 106.078 142.155 105.672 142.535 105.237L144.245 103.281Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M118.458 104.27C118.965 103.69 119.509 103.652 120.092 104.155L122.713 106.421C123.295 106.924 123.333 107.466 122.826 108.045L120.546 110.654C120.04 111.234 119.495 111.272 118.913 110.768L116.292 108.503C115.709 107.999 115.671 107.458 116.178 106.878L118.458 104.27Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M92.6708 105.258C93.3042 104.533 93.9847 104.486 94.7129 105.115L97.9892 107.947C98.7174 108.576 98.7647 109.253 98.1313 109.977L95.2814 113.238C94.648 113.963 93.9674 114.01 93.2393 113.381L89.9629 110.549C89.2348 109.92 89.1874 109.243 89.8208 108.518L92.6708 105.258Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M66.8832 106.246C67.6432 105.376 68.4601 105.319 69.3338 106.074L73.2654 109.473C74.139 110.228 74.1959 111.04 73.4359 111.909L70.0159 115.822C69.2559 116.692 68.4391 116.749 67.5654 115.994L63.6338 112.595C62.7601 111.84 62.7033 111.028 63.4633 110.159L66.8832 106.246Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M41.0957 107.235C41.9825 106.22 42.9352 106.154 43.9547 107.035L48.5415 110.999C49.561 111.88 49.6273 112.828 48.7405 113.842L44.7505 118.407C43.8637 119.422 42.9111 119.488 41.8916 118.607L37.3047 114.643C36.2852 113.761 36.2189 112.814 37.1058 111.8L41.0957 107.235Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M15.3086 108.223C16.3218 107.064 17.4113 106.988 18.576 107.994L23.8181 112.525C24.9828 113.532 25.0587 114.615 24.0455 115.774L19.4856 120.991C18.4724 122.15 17.3829 122.226 16.2182 121.22L10.9761 116.689C9.81135 115.682 9.73554 114.599 10.7487 113.44L15.3086 108.223Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-10.4787 109.211C-9.33873 107.907 -8.11347 107.821 -6.80294 108.954L-0.905556 114.051C0.404977 115.184 0.49025 116.402 -0.649734 117.706L-5.77968 123.575C-6.91966 124.879 -8.14492 124.965 -9.45545 123.833L-15.3528 118.735C-16.6634 117.603 -16.7486 116.384 -15.6087 115.08L-10.4787 109.211Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-36.2661 110.2C-34.9995 108.751 -33.638 108.656 -32.1819 109.914L-25.6293 115.578C-24.1732 116.836 -24.0785 118.19 -25.345 119.639L-31.045 126.16C-32.3115 127.609 -33.6731 127.704 -35.1292 126.446L-41.6818 120.782C-43.1378 119.524 -43.2326 118.17 -41.9661 116.721L-36.2661 110.2Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-62.0533 111.188C-60.6602 109.594 -59.1623 109.489 -57.5607 110.874L-50.3528 117.104C-48.7512 118.488 -48.647 119.977 -50.0401 121.571L-56.3101 128.744C-57.7032 130.338 -59.2011 130.443 -60.8027 129.059L-68.0106 122.829C-69.6122 121.445 -69.7164 119.955 -68.3233 118.361L-62.0533 111.188Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-87.8405 112.177C-86.3206 110.438 -84.6869 110.324 -82.9395 111.834L-75.0763 118.63C-73.3289 120.141 -73.2153 121.765 -74.7352 123.504L-81.5752 131.329C-83.0951 133.068 -84.7288 133.183 -86.4762 131.672L-94.3394 124.876C-96.0867 123.366 -96.2004 121.741 -94.6804 120.002L-87.8405 112.177Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M171.809 127.677C172.063 127.387 172.334 127.368 172.626 127.62L173.937 128.752C174.229 129.004 174.248 129.275 173.993 129.565L172.853 130.869C172.599 131.159 172.328 131.178 172.037 130.926L170.726 129.793C170.435 129.542 170.416 129.271 170.669 128.981L171.809 127.677Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M146.022 128.666C146.402 128.231 146.81 128.202 147.247 128.58L149.213 130.279C149.65 130.657 149.678 131.063 149.298 131.497L147.588 133.454C147.208 133.888 146.8 133.917 146.363 133.54L144.397 131.84C143.96 131.463 143.932 131.057 144.312 130.622L146.022 128.666Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M120.234 129.654C120.741 129.074 121.285 129.036 121.868 129.54L124.489 131.805C125.072 132.309 125.11 132.85 124.603 133.43L122.323 136.038C121.816 136.618 121.272 136.656 120.689 136.152L118.068 133.887C117.485 133.383 117.448 132.842 117.954 132.262L120.234 129.654Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M94.4472 130.642C95.0806 129.917 95.7611 129.87 96.4893 130.499L99.7656 133.331C100.494 133.96 100.541 134.637 99.9077 135.362L97.0578 138.622C96.4244 139.347 95.7438 139.394 95.0157 138.765L91.7393 135.933C91.0112 135.304 90.9638 134.627 91.5972 133.903L94.4472 130.642Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M68.6594 131.631C69.4194 130.761 70.2363 130.704 71.11 131.459L75.0416 134.857C75.9152 135.613 75.9721 136.425 75.2121 137.294L71.7921 141.207C71.0321 142.076 70.2153 142.134 69.3416 141.378L65.41 137.98C64.5363 137.225 64.4795 136.413 65.2395 135.543L68.6594 131.631Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M42.8721 132.619C43.7589 131.605 44.7115 131.538 45.731 132.419L50.3179 136.383C51.3374 137.265 51.4037 138.212 50.5169 139.227L46.5269 143.791C45.6401 144.806 44.6875 144.872 43.668 143.991L39.0811 140.027C38.0616 139.146 37.9953 138.198 38.8821 137.184L42.8721 132.619Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M17.085 133.607C18.0982 132.448 19.1876 132.372 20.3524 133.378L25.5945 137.909C26.7592 138.916 26.835 139.999 25.8219 141.158L21.2619 146.375C20.2488 147.534 19.1593 147.611 17.9946 146.604L12.7525 142.073C11.5877 141.066 11.5119 139.983 12.5251 138.824L17.085 133.607Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-8.70219 134.596C-7.56221 133.292 -6.33692 133.206 -5.0264 134.339L0.870984 139.436C2.18151 140.569 2.26677 141.787 1.12679 143.091L-4.00314 148.96C-5.14313 150.265 -6.36838 150.35 -7.67891 149.218L-13.5763 144.12C-14.8868 142.988 -14.9721 141.769 -13.8321 140.465L-8.70219 134.596Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-34.4894 135.584C-33.2228 134.135 -31.8613 134.04 -30.4052 135.298L-23.8526 140.962C-22.3965 142.22 -22.3017 143.574 -23.5683 145.023L-29.2682 151.544C-30.5348 152.993 -31.8964 153.089 -33.3525 151.83L-39.9051 146.167C-41.3612 144.908 -41.4559 143.554 -40.1893 142.105L-34.4894 135.584Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-60.2766 136.572C-58.8835 134.979 -57.3856 134.874 -55.784 136.258L-48.5761 142.488C-46.9745 143.872 -46.8703 145.362 -48.2634 146.955L-54.5333 154.129C-55.9265 155.722 -57.4244 155.827 -59.026 154.443L-66.2339 148.213C-67.8355 146.829 -67.9397 145.339 -66.5465 143.745L-60.2766 136.572Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-86.0642 137.561C-84.5442 135.822 -82.9105 135.708 -81.1631 137.218L-73.2999 144.014C-71.5526 145.525 -71.4389 147.149 -72.9589 148.888L-79.7988 156.714C-81.3187 158.452 -82.9524 158.567 -84.6998 157.057L-92.563 150.26C-94.3103 148.75 -94.424 147.125 -92.9041 145.386L-86.0642 137.561Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M173.586 153.061C173.839 152.771 174.11 152.752 174.402 153.004L175.713 154.137C176.005 154.388 176.024 154.659 175.77 154.949L174.63 156.253C174.376 156.543 174.104 156.562 173.813 156.31L172.502 155.178C172.211 154.926 172.192 154.655 172.446 154.365L173.586 153.061Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M147.798 154.05C148.178 153.615 148.587 153.587 149.023 153.964L150.989 155.663C151.426 156.041 151.454 156.447 151.074 156.882L149.364 158.838C148.984 159.273 148.576 159.301 148.139 158.924L146.173 157.225C145.737 156.847 145.708 156.441 146.088 156.006L147.798 154.05Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M122.011 155.038C122.518 154.458 123.062 154.42 123.645 154.924L126.266 157.189C126.848 157.693 126.886 158.234 126.38 158.814L124.1 161.422C123.593 162.002 123.049 162.04 122.466 161.537L119.845 159.271C119.262 158.768 119.224 158.226 119.731 157.647L122.011 155.038Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M96.2236 156.026C96.857 155.302 97.5375 155.254 98.2657 155.883L101.542 158.715C102.27 159.345 102.318 160.021 101.684 160.746L98.8341 164.006C98.2007 164.731 97.5202 164.779 96.792 164.149L93.5157 161.318C92.7876 160.688 92.7402 160.011 93.3736 159.287L96.2236 156.026Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M70.4362 157.015C71.1961 156.146 72.013 156.089 72.8867 156.844L76.8183 160.242C77.6919 160.997 77.7488 161.809 76.9888 162.679L73.5688 166.591C72.8089 167.461 71.992 167.518 71.1183 166.763L67.1867 163.365C66.3131 162.61 66.2562 161.797 67.0162 160.928L70.4362 157.015Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M44.6488 158.003C45.5356 156.989 46.4883 156.922 47.5077 157.803L52.0946 161.768C53.1141 162.649 53.1804 163.596 52.2936 164.611L48.3036 169.176C47.4168 170.19 46.4642 170.257 45.4447 169.376L40.8578 165.411C39.8383 164.53 39.772 163.583 40.6589 162.568L44.6488 158.003Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M18.8615 158.992C19.8747 157.833 20.9642 157.757 22.1289 158.763L27.371 163.294C28.5357 164.301 28.6116 165.384 27.5984 166.544L23.0385 171.76C22.0253 172.919 20.9358 172.996 19.7711 171.989L14.529 167.458C13.3642 166.451 13.2884 165.368 14.3016 164.209L18.8615 158.992Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-6.9258 159.98C-5.78581 158.676 -4.56056 158.59 -3.25003 159.723L2.64734 164.82C3.95787 165.953 4.04317 167.171 2.90317 168.476L-2.22677 174.345C-3.36677 175.649 -4.59201 175.734 -5.90254 174.602L-11.7999 169.505C-13.1104 168.372 -13.1957 167.153 -12.0557 165.849L-6.9258 159.98Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-32.713 160.969C-31.4465 159.519 -30.0849 159.424 -28.6288 160.683L-22.0762 166.346C-20.6201 167.605 -20.5254 168.959 -21.792 170.408L-27.4919 176.929C-28.7584 178.378 -30.12 178.473 -31.5761 177.215L-38.1287 171.551C-39.5848 170.293 -39.6795 168.939 -38.4129 167.49L-32.713 160.969Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-58.5004 161.957C-57.1072 160.363 -55.6094 160.259 -54.0078 161.643L-46.7999 167.873C-45.1983 169.257 -45.0941 170.747 -46.4872 172.34L-52.7572 179.514C-54.1503 181.107 -55.6482 181.212 -57.2498 179.828L-64.4577 173.598C-66.0593 172.214 -66.1635 170.724 -64.7704 169.13L-58.5004 161.957Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-84.2877 162.946C-82.7678 161.207 -81.1341 161.092 -79.3867 162.603L-71.5236 169.399C-69.7762 170.909 -69.6625 172.534 -71.1824 174.273L-78.0224 182.098C-79.5424 183.837 -81.176 183.951 -82.9234 182.441L-90.7866 175.645C-92.534 174.134 -92.6477 172.51 -91.1277 170.771L-84.2877 162.946Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M175.362 178.446C175.615 178.156 175.886 178.137 176.179 178.389L177.489 179.522C177.781 179.773 177.8 180.044 177.546 180.334L176.406 181.638C176.152 181.928 175.88 181.947 175.589 181.695L174.279 180.563C173.987 180.311 173.968 180.04 174.222 179.75L175.362 178.446Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M149.575 179.434C149.955 179 150.363 178.971 150.8 179.349L152.766 181.048C153.203 181.425 153.231 181.831 152.851 182.266L151.141 184.222C150.761 184.657 150.353 184.686 149.916 184.308L147.95 182.609C147.513 182.231 147.485 181.825 147.865 181.391L149.575 179.434Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M123.787 180.423C124.294 179.843 124.839 179.805 125.421 180.308L128.042 182.574C128.625 183.077 128.663 183.618 128.156 184.198L125.876 186.807C125.369 187.386 124.825 187.425 124.242 186.921L121.621 184.656C121.039 184.152 121.001 183.611 121.508 183.031L123.787 180.423Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M98.0001 181.411C98.6335 180.687 99.314 180.639 100.042 181.268L103.319 184.1C104.047 184.73 104.094 185.406 103.461 186.131L100.611 189.391C99.9773 190.116 99.2967 190.164 98.5686 189.534L95.2923 186.703C94.5641 186.073 94.5167 185.396 95.1501 184.672L98.0001 181.411Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M72.2125 182.399C72.9725 181.53 73.7894 181.473 74.663 182.228L78.5946 185.626C79.4683 186.381 79.5252 187.194 78.7652 188.063L75.3452 191.976C74.5852 192.845 73.7684 192.902 72.8947 192.147L68.9631 188.749C68.0894 187.994 68.0326 187.182 68.7926 186.312L72.2125 182.399Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M46.4252 183.388C47.312 182.373 48.2647 182.306 49.2841 183.188L53.871 187.152C54.8905 188.033 54.9568 188.981 54.0699 189.995L50.08 194.56C49.1932 195.574 48.2405 195.641 47.2211 194.76L42.6342 190.795C41.6147 189.914 41.5484 188.967 42.4353 187.952L46.4252 183.388Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M20.6379 184.376C21.6511 183.217 22.7405 183.141 23.9053 184.148L29.1474 188.679C30.3121 189.685 30.3879 190.769 29.3748 191.928L24.8148 197.145C23.8017 198.304 22.7122 198.38 21.5475 197.373L16.3054 192.842C15.1406 191.836 15.0648 190.752 16.078 189.593L20.6379 184.376Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-5.14941 185.365C-4.00942 184.06 -2.78417 183.975 -1.47365 185.107L4.42374 190.205C5.73427 191.337 5.81953 192.556 4.67954 193.86L-0.450385 199.729C-1.59037 201.033 -2.81562 201.119 -4.12616 199.986L-10.0235 194.889C-11.3341 193.756 -11.4194 192.538 -10.2794 191.234L-5.14941 185.365Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-30.9363 186.353C-29.6697 184.904 -28.3082 184.809 -26.8521 186.067L-20.2995 191.731C-18.8434 192.989 -18.7487 194.343 -20.0152 195.792L-25.7152 202.313C-26.9817 203.762 -28.3433 203.857 -29.7993 202.599L-36.352 196.935C-37.8081 195.677 -37.9028 194.323 -36.6362 192.874L-30.9363 186.353Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-56.7237 187.342C-55.3306 185.748 -53.8327 185.643 -52.2311 187.027L-45.0232 193.257C-43.4216 194.641 -43.3174 196.131 -44.7105 197.725L-50.9804 204.898C-52.3736 206.492 -53.8715 206.597 -55.4731 205.212L-62.681 198.982C-64.2825 197.598 -64.3868 196.109 -62.9936 194.515L-56.7237 187.342Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-82.511 188.33C-80.9911 186.591 -79.3574 186.477 -77.61 187.987L-69.7468 194.783C-67.9995 196.293 -67.8858 197.918 -69.4057 199.657L-76.2457 207.482C-77.7657 209.221 -79.3993 209.335 -81.1467 207.825L-89.0099 201.029C-90.7572 199.519 -90.8709 197.894 -89.351 196.155L-82.511 188.33Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M177.138 203.83C177.392 203.541 177.663 203.522 177.955 203.773L179.266 204.906C179.558 205.158 179.577 205.428 179.323 205.718L178.183 207.022C177.928 207.312 177.657 207.331 177.366 207.08L176.055 205.947C175.764 205.695 175.745 205.425 175.998 205.135L177.138 203.83Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M151.351 204.819C151.731 204.384 152.14 204.355 152.576 204.733L154.542 206.432C154.979 206.809 155.008 207.216 154.628 207.65L152.918 209.607C152.538 210.041 152.129 210.07 151.692 209.692L149.727 207.993C149.29 207.616 149.261 207.21 149.641 206.775L151.351 204.819Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M125.564 205.807C126.071 205.227 126.615 205.189 127.198 205.692L129.819 207.958C130.401 208.461 130.439 209.003 129.932 209.582L127.652 212.191C127.146 212.771 126.601 212.809 126.019 212.305L123.398 210.04C122.815 209.536 122.777 208.995 123.284 208.415L125.564 205.807Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M99.7765 206.796C100.41 206.071 101.09 206.023 101.819 206.653L105.095 209.484C105.823 210.114 105.87 210.791 105.237 211.515L102.387 214.776C101.754 215.5 101.073 215.548 100.345 214.919L97.0686 212.087C96.3405 211.457 96.2931 210.781 96.9265 210.056L99.7765 206.796Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M73.9889 207.784C74.7489 206.914 75.5657 206.857 76.4394 207.612L80.371 211.01C81.2447 211.765 81.3016 212.578 80.5416 213.447L77.1216 217.36C76.3616 218.229 75.5448 218.286 74.6711 217.531L70.7395 214.133C69.8658 213.378 69.809 212.566 70.569 211.696L73.9889 207.784Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M48.2019 208.772C49.0887 207.757 50.0414 207.691 51.0608 208.572L55.6477 212.536C56.6672 213.418 56.7335 214.365 55.8467 215.379L51.8567 219.944C50.9699 220.959 50.0173 221.025 48.9978 220.144L44.4109 216.18C43.3914 215.299 43.3251 214.351 44.2119 213.337L48.2019 208.772Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M22.4146 209.761C23.4278 208.602 24.5172 208.525 25.682 209.532L30.9241 214.063C32.0888 215.07 32.1647 216.153 31.1515 217.312L26.5915 222.529C25.5784 223.688 24.4889 223.764 23.3242 222.758L18.0821 218.227C16.9173 217.22 16.8415 216.137 17.8547 214.978L22.4146 209.761Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-3.37269 210.749C-2.23271 209.445 -1.00745 209.359 0.303076 210.492L6.20045 215.589C7.51098 216.722 7.59625 217.94 6.45627 219.244L1.32633 225.113C0.186342 226.417 -1.03891 226.503 -2.34945 225.37L-8.24683 220.273C-9.55736 219.141 -9.64264 217.922 -8.50265 216.618L-3.37269 210.749Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-29.1601 211.738C-27.8935 210.289 -26.532 210.193 -25.0759 211.452L-18.5233 217.115C-17.0672 218.374 -16.9725 219.728 -18.239 221.177L-23.939 227.698C-25.2055 229.147 -26.5671 229.242 -28.0231 227.984L-34.5758 222.32C-36.0318 221.062 -36.1266 219.708 -34.86 218.259L-29.1601 211.738Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-54.9473 212.726C-53.5542 211.132 -52.0563 211.027 -50.4547 212.412L-43.2468 218.641C-41.6452 220.026 -41.541 221.515 -42.9341 223.109L-49.2041 230.282C-50.5972 231.876 -52.0951 231.981 -53.6967 230.597L-60.9046 224.367C-62.5062 222.982 -62.6104 221.493 -61.2172 219.899L-54.9473 212.726Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-80.7347 213.714C-79.2147 211.975 -77.581 211.861 -75.8336 213.371L-67.9705 220.167C-66.2231 221.678 -66.1094 223.302 -67.6294 225.041L-74.4693 232.866C-75.9893 234.605 -77.6229 234.72 -79.3703 233.209L-87.2335 226.413C-88.9809 224.903 -89.0946 223.278 -87.5746 221.539L-80.7347 213.714Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M178.915 229.215C179.168 228.925 179.44 228.906 179.732 229.158L181.042 230.29C181.334 230.542 181.353 230.813 181.099 231.103L179.959 232.407C179.705 232.697 179.434 232.716 179.142 232.464L177.832 231.331C177.54 231.079 177.521 230.809 177.775 230.519L178.915 229.215Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M153.128 230.203C153.508 229.768 153.916 229.74 154.353 230.117L156.319 231.816C156.755 232.194 156.784 232.6 156.404 233.035L154.694 234.991C154.314 235.426 153.906 235.454 153.469 235.077L151.503 233.378C151.066 233 151.038 232.594 151.418 232.159L153.128 230.203Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M127.341 231.191C127.847 230.611 128.392 230.573 128.974 231.077L131.595 233.342C132.178 233.846 132.216 234.387 131.709 234.967L129.429 237.575C128.922 238.155 128.378 238.193 127.795 237.69L125.174 235.424C124.592 234.921 124.554 234.379 125.061 233.8L127.341 231.191Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M101.553 232.18C102.187 231.455 102.867 231.408 103.595 232.037L106.872 234.869C107.6 235.498 107.647 236.175 107.014 236.9L104.164 240.16C103.53 240.885 102.85 240.932 102.122 240.303L98.8453 237.471C98.1172 236.842 98.0698 236.165 98.7032 235.44L101.553 232.18Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M75.7656 233.168C76.5256 232.299 77.3425 232.241 78.2161 232.997L82.1477 236.395C83.0214 237.15 83.0783 237.962 82.3183 238.832L78.8983 242.744C78.1383 243.614 77.3215 243.671 76.4478 242.916L72.5162 239.518C71.6425 238.762 71.5857 237.95 72.3457 237.081L75.7656 233.168Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M49.9783 234.156C50.8651 233.142 51.8178 233.075 52.8372 233.956L57.4241 237.921C58.4436 238.802 58.5099 239.749 57.6231 240.764L53.6331 245.328C52.7463 246.343 51.7936 246.41 50.7742 245.529L46.1873 241.564C45.1678 240.683 45.1015 239.736 45.9883 238.721L49.9783 234.156Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M24.191 235.145C25.2042 233.986 26.2936 233.91 27.4584 234.916L32.7005 239.447C33.8652 240.454 33.941 241.537 32.9279 242.696L28.3679 247.913C27.3548 249.072 26.2653 249.149 25.1006 248.142L19.8585 243.611C18.6937 242.604 18.6179 241.521 19.6311 240.362L24.191 235.145Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-1.59632 236.133C-0.456339 234.829 0.768918 234.743 2.07945 235.876L7.97684 240.973C9.28737 242.106 9.37264 243.324 8.23266 244.629L3.10271 250.497C1.96271 251.802 0.737456 251.887 -0.573074 250.755L-6.47045 245.658C-7.78098 244.525 -7.86625 243.306 -6.72627 242.002L-1.59632 236.133Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-27.3837 237.122C-26.1172 235.673 -24.7556 235.578 -23.2995 236.836L-16.7469 242.5C-15.2908 243.758 -15.1961 245.112 -16.4627 246.561L-22.1626 253.082C-23.4292 254.531 -24.7907 254.627 -26.2468 253.368L-32.7994 247.705C-34.2555 246.446 -34.3502 245.092 -33.0837 243.643L-27.3837 237.122Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-53.1709 238.11C-51.7778 236.516 -50.2799 236.412 -48.6783 237.796L-41.4704 244.026C-39.8689 245.41 -39.7646 246.899 -41.1577 248.493L-47.4277 255.666C-48.8208 257.26 -50.3187 257.365 -51.9203 255.981L-59.1282 249.751C-60.7298 248.367 -60.834 246.877 -59.4409 245.283L-53.1709 238.11Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-78.9579 239.099C-77.438 237.36 -75.8043 237.245 -74.0569 238.756L-66.1937 245.552C-64.4464 247.062 -64.3327 248.687 -65.8527 250.426L-72.6926 258.251C-74.2126 259.99 -75.8462 260.104 -77.5936 258.594L-85.4568 251.798C-87.2042 250.287 -87.3179 248.663 -85.7979 246.924L-78.9579 239.099Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M180.692 254.599C180.945 254.309 181.216 254.29 181.508 254.542L182.819 255.675C183.111 255.926 183.13 256.196 182.876 256.487L181.736 257.791C181.482 258.082 181.21 258.101 180.919 257.848L179.608 256.716C179.317 256.463 179.298 256.193 179.552 255.903L180.692 254.599Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M154.904 255.587C155.284 255.152 155.693 255.124 156.13 255.501L158.095 257.201C158.532 257.578 158.561 257.984 158.181 258.419L156.471 260.375C156.091 260.81 155.682 260.839 155.245 260.461L153.28 258.762C152.843 258.384 152.814 257.978 153.194 257.543L154.904 255.587Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M129.117 256.576C129.624 255.996 130.168 255.958 130.75 256.462L133.372 258.727C133.954 259.231 133.992 259.773 133.485 260.352L131.205 262.96C130.698 263.539 130.154 263.577 129.572 263.074L126.951 260.809C126.368 260.307 126.33 259.764 126.837 259.184L129.117 256.576Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M103.33 257.564C103.963 256.84 104.644 256.792 105.372 257.421L108.648 260.253C109.376 260.882 109.423 261.558 108.79 262.284L105.94 265.544C105.307 266.27 104.626 266.317 103.898 265.687L100.622 262.855C99.8935 262.225 99.8462 261.549 100.48 260.825L103.33 257.564Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M77.542 258.552C78.302 257.683 79.1188 257.626 79.9925 258.381L83.9241 261.779C84.7978 262.534 84.8547 263.346 84.0947 264.216L80.6747 268.128C79.9147 268.998 79.0979 269.055 78.2242 268.3L74.2926 264.902C73.4189 264.147 73.3621 263.334 74.1221 262.465L77.542 258.552Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M51.7548 259.541C52.6416 258.527 53.5943 258.46 54.6137 259.341L59.2006 263.306C60.2201 264.187 60.2865 265.135 59.3996 266.149L55.4096 270.713C54.5227 271.727 53.5701 271.794 52.5507 270.914L47.9638 266.949C46.9444 266.069 46.878 265.121 47.7649 264.106L51.7548 259.541Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M25.9674 260.529C26.9806 259.37 28.07 259.294 29.2348 260.301L34.4769 264.831C35.6416 265.838 35.7173 266.921 34.7043 268.081L30.1443 273.297C29.1312 274.458 28.0418 274.534 26.877 273.526L21.6348 268.995C20.47 267.988 20.3943 266.905 21.4075 265.746L25.9674 260.529Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M0.180387 261.518C1.32037 260.213 2.54563 260.128 3.85616 261.26L9.75355 266.358C11.0641 267.49 11.1494 268.709 10.0094 270.013L4.87942 275.882C3.73944 277.186 2.51417 277.272 1.20364 276.139L-4.69374 271.042C-6.00427 269.909 -6.08954 268.691 -4.94954 267.387L0.180387 261.518Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-25.607 262.506C-24.3404 261.057 -22.9789 260.962 -21.5228 262.221L-14.9702 267.884C-13.5141 269.143 -13.4193 270.497 -14.6859 271.946L-20.3859 278.467C-21.6525 279.915 -23.0141 280.01 -24.4701 278.752L-31.0227 273.089C-32.4787 271.831 -32.5735 270.476 -31.3069 269.027L-25.607 262.506Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-51.3942 263.495C-50.0011 261.901 -48.5032 261.796 -46.9016 263.18L-39.6937 269.41C-38.0921 270.794 -37.9879 272.283 -39.381 273.878L-45.651 281.051C-47.0441 282.646 -48.5419 282.75 -50.1436 281.365L-57.3515 275.135C-58.9531 273.75 -59.0573 272.261 -57.6642 270.668L-51.3942 263.495Z", fill: "#D6EDFA", "fill-opacity": "0.1" }),
    React.createElement("path", { d: "M-77.1818 264.483C-75.6618 262.744 -74.0281 262.63 -72.2807 264.14L-64.4176 270.937C-62.6702 272.447 -62.5565 274.072 -64.0765 275.81L-70.9164 283.636C-72.4364 285.375 -74.07 285.489 -75.8174 283.979L-83.6806 277.182C-85.428 275.672 -85.5417 274.048 -84.0217 272.309L-77.1818 264.483Z", fill: "#D6EDFA", "fill-opacity": "0.1" })));
exports.BannerBg = BannerBg;
