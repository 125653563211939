import {
  API_CATEGORY_TYPES,
  AppConstant,
  COMMUNITIES_API_CATEGORY_TYPES,
  FILTER_BY_TABS_ENUM,
  ImageConstant,
  fetchCommunityList,
  translate,
  useAppDispatch,
  useAppSelector,
  getLoggedInUserInfo,
  COMMUNITY_SORT_SCREENS,
  fetchCombinedExploreCommunity,
  resetSkipCountForMyCommunity
} from '@shalina-app/shared';
import { useEffect, useState } from 'react';

import { CommunityListingProps } from '.';
import { CommunitiesShortCard } from '../..';
import {
  DEFAULT_COMMUNITY_SKIP,
  DEFAULT_COMMUNITY_TAKE
} from '../../../../app.constants';
import {
  BarLoader,
  InfiniteScrollComponent,
  SortFilter
} from '../../../../components';
import {
  retrieveData,
  STORAGE_CONSTANTS,
  UserInterface
} from '../../../../utils';
import styles from './communityListing.module.scss';

export const CommunityListing: React.FC<CommunityListingProps> = ({
  viewAllCategory,
  communitiesList,
  handleCommunityClick,
  tabChange,
  handleMainApiCall
}) => {
  const isExplore = viewAllCategory === COMMUNITIES_API_CATEGORY_TYPES.EXPLORE_COMMUNITY
  const dispatch = useAppDispatch();
  const t = translate();

  const userInfo = useAppSelector(getLoggedInUserInfo);
  const user: UserInterface | any = retrieveData(STORAGE_CONSTANTS.USER);
  const isLoggedIn: boolean = retrieveData(STORAGE_CONSTANTS.IS_LOGGED_IN);

  const [hasMore, setHasMore] = useState(true);
  const [scrollLoader, setScrollLoader] = useState(false);
  const [communityData, setCommunityData] = useState<any>([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [skip, setSkip] = useState(0);
  const [dataFromStore, setDataFromStore] = useState(true);
  const [heading, setHeading] = useState<any>('');
  const [selectedAreaOfInterest, setSelectedAreaOfInterest] = useState([]);
  const [initialLoader, setInitialLoader] = useState(true);

  const [apiCall, setApiCall] = useState(false);
  const [loader, setLoader] = useState(false);
  const [filterCall, setFilterCall] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    skip: 0,
    take: DEFAULT_COMMUNITY_TAKE,
    countries: '',
    interest: ''
    // speciality: "",
    // sort: "",
  });

  useEffect(() => {
    if (apiCall) {
      loadApi();
    }
  }, [apiCall]);

  useEffect(() => {
    if (viewAllCategory) {
      window.scrollTo(0, 0);
      setInitialValue();
      setDataFromStore(true);
      setCommunityData([]);
      setApiCall(true);
      setInitialLoader(true);
      dispatch(resetSkipCountForMyCommunity())
    }
  }, [viewAllCategory]);

  const setInitialValue = () => {
    if (
      (![null, undefined, []].includes(userInfo?.areaOfInterest) ||
        ![null, undefined, []].includes(user?.areaOfInterest)) &&
      isLoggedIn
    ) {
      const userDetails = userInfo?.areaOfInterest ?? user?.areaOfInterest;
      const areaOfInterestIds = userDetails?.map(
        (item: any) => item?.tid && String(item?.tid)
      );
      setSelectedAreaOfInterest(areaOfInterestIds);
    }
  };

  const onApplyButtonClick = (
    selectedContentType: Array<string>,
    selectedSpecialization: Array<string>,
    selectedCommunityCategory: Array<string>,
    selectedCommunityCountry: Array<string>
  ) => {
    dispatch(resetSkipCountForMyCommunity())
    setSkip(0);
    setFilterOptions((prevOptions: any) => ({
      ...prevOptions,
      skip: 0,
      speciality: selectedSpecialization?.toString(),
      countries: selectedCommunityCountry?.toString()
    }));
    setFilterCall(true);
    setApiCall(true);
    setHasMore(true);
  };

  const onSortChange = (value: string) => {
    dispatch(resetSkipCountForMyCommunity())
    setSkip(0);
    setFilterOptions((prevOptions: any) => ({
      ...prevOptions,
      skip: 0,
      sort: value
    }));
    setFilterCall(true);
    setApiCall(true);
    setHasMore(true);
  };

  const onAreaOfInterest = (value: string[]) => {
    dispatch(resetSkipCountForMyCommunity())
    setSkip(0);
    setFilterOptions((prevOptions: any) => ({
      ...prevOptions,
      skip: 0,
      interest: value?.toString()
    }));
    setFilterCall(true);
    setApiCall(true);
    setHasMore(true);
  };

  const onResetFilter = () => {
    dispatch(resetSkipCountForMyCommunity())
    setSkip(0);
    setFilterOptions((prevValue: any) => ({
      ...prevValue,
      skip: 0,
      speciality: '',
      countries: ''
    }));
    setFilterCall(true);
    setApiCall(true);
    setHasMore(true);
  };

  const loadApi = async () => {
    let payload = {
      ...filterOptions,
      category: viewAllCategory,
      type: ''
    };

    if (viewAllCategory !== COMMUNITIES_API_CATEGORY_TYPES.MY_COMMUNITY) {
      payload = {
        ...filterOptions,
        category: viewAllCategory,
        type: 'open'
      };
    }
    if(isExplore) {
      const response = (await dispatch(
        fetchCombinedExploreCommunity({...payload, isLoggedIn: isLoggedIn})
      ).unwrap()) as any;
  
      if (
        response &&
        response?.data?.status === 'SUCCESS' &&
        response?.data?.statusCode === 200
      ) {
        setPagination(response, true);
      } else {
        setLoader(false);
        setDataFromStore(false);
        setFilterCall(false);
        setApiCall(false);
        setInitialLoader(false);
      }
    }else{
      const response = (await dispatch(
        fetchCommunityList(payload)
      ).unwrap()) as any;
  
      if (
        response &&
        response?.status === 'SUCCESS' &&
        response?.statusCode === 200
      ) {
        setPagination(response);
      } else {
        setLoader(false);
        setDataFromStore(false);
        setFilterCall(false);
        setApiCall(false);
        setInitialLoader(false);
      }
    }
  };

  const setPagination = (response: any, isExplore: boolean = false) => {
    setDataFromStore(false);
    setLoader(false);
    const data = isExplore ? response?.data?.data : response?.data;
    const totalCount = isExplore ? response?.data?.count : response?.count;

    setTotalDataCount(totalCount);
    if (apiCall) {            
      // if (communityData?.length >= response?.count) {
      //   console.log('apiCall true');
      //   setHasMore(false);
      // } else {
      //   console.log('apiCall false');
      //   setHasMore(true);
      // }
      setApiCall(false);
    }
    if (data) {
      if (filterCall) {
        setFilterCall(false);
        setDataFromStore(false);
        setCommunityData(data);
      } else {
        setCommunityData((prevOptions: any) => [...prevOptions, ...data]);
      }
      setInitialLoader(false);
    }
  };

  const fetchMoreData = () => {
    setScrollLoader(true);
    if (communityData?.length >= totalDataCount) {
      setHasMore(false);
      return;
    }
    setSkip(skip + DEFAULT_COMMUNITY_SKIP);
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      skip: skip + DEFAULT_COMMUNITY_SKIP,
      take: DEFAULT_COMMUNITY_TAKE
    }));
    setApiCall(true);
  };

  useEffect(() => {
    if (viewAllCategory) {
      const heading = getHeading();
      setHeading(heading);
    }
  }, [viewAllCategory]);

  const getHeading = () => {
    if (viewAllCategory) {
      switch (viewAllCategory) {
        case COMMUNITIES_API_CATEGORY_TYPES.EXPLORE_COMMUNITY:
          return t(AppConstant.COMMUNITY.EXPLORE_COMMUNITIES_TAB);
        case COMMUNITIES_API_CATEGORY_TYPES.RECOMMENDED_COMMUNITY:
          return t(AppConstant.COMMUNITY.RECOMMENDED_HEADING);
        case COMMUNITIES_API_CATEGORY_TYPES.MY_COMMUNITY:
          return t(AppConstant.COMMUNITY.MY_COMMUNITIES_HEADING);
        default:
          return '';
      }
    }
  };

  const getSortModuleName = () => {
    if (viewAllCategory) {
      switch (viewAllCategory) {
        case COMMUNITIES_API_CATEGORY_TYPES.EXPLORE_COMMUNITY:
          return COMMUNITY_SORT_SCREENS.EXPLORE;
        case COMMUNITIES_API_CATEGORY_TYPES.RECOMMENDED_COMMUNITY:
          return COMMUNITY_SORT_SCREENS.RECOMMENDED;
        case COMMUNITIES_API_CATEGORY_TYPES.MY_COMMUNITY:
          return COMMUNITY_SORT_SCREENS.MY_COMMUNITY;
        default:
          return '';
      }
    }
  };

  const content = dataFromStore ? communitiesList : communityData;
  return (
    <>
      <h1 className={styles.pageTitle}>{heading}</h1>
      <div className={styles.landingFilter}>
        <SortFilter
          tabsEnum={[FILTER_BY_TABS_ENUM.SPECIALITY]}
          isCommunity={true}
          isDark={true}
          categoryType={API_CATEGORY_TYPES.COMMUNITIES}
          handleApplyFilter={onApplyButtonClick}
          handleSortChange={onSortChange}
          handleAreaOfInterest={onAreaOfInterest}
          handleResetFilter={onResetFilter}
          selectedAreaOfInterest={selectedAreaOfInterest}
          communityModule={getSortModuleName()}
        />
        {(filterCall || initialLoader) && (
          <div className={styles.loaderDiv}>
            <BarLoader />
          </div>
        )}
      </div>

      {content?.length > 0 ? (
        <div className={styles.listingWrapper}>
          <InfiniteScrollComponent
            dataLength={content?.length || 0}
            fetchMoreData={fetchMoreData}
            hasMore={hasMore}
            loader={scrollLoader && content?.length >= DEFAULT_COMMUNITY_TAKE}
            // scrollThreshold={"1000px"}
          >
            {content?.map((community: any) => (
              <CommunitiesShortCard
                isRequest={false}
                categoryType={viewAllCategory}
                isFullWidth={true}
                content={community}
                handleCommunityClick={handleCommunityClick}
                tabChange={tabChange}
                handleMainApiCall={handleMainApiCall}
              />
            ))}
          </InfiniteScrollComponent>
        </div>
      ) : (
        <div className={styles.notFoundSec}>
          <div className={styles.notFoundWrapper}>
            <img src={ImageConstant.SOURCE.welcomeToCommunities} alt='img' />
            <h2>{t(AppConstant.COMMUNITY.NO_COMMUNITIES_FOUND)}</h2>
            <p>{t(AppConstant.COMMUNITY.JOIN_COMMUNITY_TEXT)} </p>
          </div>
        </div>
      )}
    </>
  );
};
