import { useEffect, useState } from 'react';
import {
  AppConstant,
  ImageConstant,
  listRssNewsFeed,
  rssFeedNews,
  translate,
  useAppDispatch,
  useAppSelector
} from '@shalina-app/shared';
import { useLocation } from 'react-router-dom';

import { URLConstant } from '../../../../../routes';
import { useNavigatePageUtils } from '../../../../../utils';
import { BarLoader } from '../../../../loader';
import styles from './rssListing.module.scss';
import { DEFAULT_SKIP, DEFAULT_TAKE } from '../../../../../app.constants';
import { RssNewsFeedCard } from '../../../rssNewsFeedCard';
import { InfiniteScrollComponent } from '../../../../infiniteScroll';
import { AutoComplete } from '../../../../autoComplete';
import { QuickLinkSection } from '../../../quickLinkSection';

const RssLisitngPage = () => {
  const t = translate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const contentId = location?.state?.contentId;
  const [reload, setReload] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [rssFeedNewsData, setRSSFeedNewsData] = useState<any>([]);
  const [publishers, setPublishers] = useState([]);
  const [skip, setSkip] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [publisherOptions, setPublisherOptions] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [isSubmit, setIsSubmit] = useState(true);
  const navigatePage = useNavigatePageUtils();

  const { urls } = URLConstant;
  const defaultFilterOptions = {
    search: '',
    skip: 0,
    take: DEFAULT_TAKE,
    req: {}
  };

  const publishersList = publishers.map((publisher: any) => ({
    id: publisher?.field_unique_code,
    label: publisher?.name
  }));

  const [filterOptions, setFilterOptions] = useState<any>(defaultFilterOptions);
  const [isLoader, setIsLoader] = useState(true);
  const [filterApiCall, setFilterApiCall] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setReload(true);
  }, []);

  useEffect(() => {
    if (reload) {
      loadApi();
      //   setReload(false);
    }
  }, [reload]);

  const loadApi = async (itemId?: string) => {
    const response: any = await dispatch(
      rssFeedNews(filterOptions as any)
    ).unwrap();

    if (
      response &&
      response?.status === 'SUCCESS' &&
      response?.statusCode === 200 &&
      response?.data &&
      response?.data?.items?.length > 0
    ) {
      setFilterApiCall(false);
      setPagination(response?.data);
      // setPaginationData(response?.data);
      setPublishers(response?.data?.publishers);
    } else {
      setFilterApiCall(false);
      setIsLoader(false);
      setPagination(response?.data);
    }
    setReload(false);
  };

  const setPagination = (response: any) => {
    setIsLoader(false);
    const data = response?.items;

    const totalCount = response?.count;
    setTotalCount(totalCount);

    if (reload) {
      if (rssFeedNewsData?.length >= response?.count) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      setReload(false);
    }

    setRSSFeedNewsData((prevOptions: any) => [...prevOptions, ...data]);
  };

  const fetchMoreData = () => {
    if (rssFeedNewsData?.length >= totalCount) {
      setHasMore(false);
      return;
    }
    setSkip(skip + DEFAULT_SKIP);
    setFilterOptions((prevOptions: any) => ({
      ...prevOptions,
      skip: skip + DEFAULT_SKIP,
      take: DEFAULT_TAKE
    }));
    setReload(true);
  };

  const isContentLoading = useAppSelector(
    (state) => state?.home?.listRssNewsFeed?.isListRssNewsFeedLoading
  );

  const rssData: any = useAppSelector(listRssNewsFeed);

  const handleClick = (id: any) => {
    const rssData = rssFeedNewsData?.length
      ? rssFeedNewsData?.find((rssItem: any) => rssItem.id === id)
      : [];

    const navigateDetails = {
      stateParams: {
        rssContentData: rssData
      },
      isDynamicUrl: false
    };
    navigatePage(urls.rssDetail, navigateDetails);
  };

  const handleOptions = (options: any) => {
    setPublisherOptions(options);
  };

  const handleFilterClick = () => {
    setFilterApiCall(true);
    const keyWord = searchKeyword?.trim();

    const selectedPublisher = publisherOptions?.map((item: any) => {
      return item?.id;
    });

    const updatedReq = {
      startDate: startDate,
      endDate: endDate,
      publisher: selectedPublisher
    };
    setFilterOptions({
      search: keyWord ? searchKeyword?.trim() : '',
      skip: 0,
      take: DEFAULT_TAKE,
      req: updatedReq
    });

    setRSSFeedNewsData([]);
    setSkip(0);
    setReload(true);
  };

  const onFilterReset = () => {
    setStartDate('');
    setEndDate('');
    setPublisherOptions([]);
  };

  const onSearchChange = (event: React.BaseSyntheticEvent) => {
    const value = event?.target?.value;
    setSearchKeyword(value);
  };

  const handleSearch = () => {
    const keyWord = searchKeyword?.trim();
    if (keyWord) {
      setFilterOptions((prevOptions: any) => ({
        ...prevOptions,
        req: {},
        search: keyWord,
        skip: 0,
        take: DEFAULT_TAKE
      }));
      setRSSFeedNewsData([]);
      setSkip(0);
      setReload(true);
      setFilterApiCall(true);
      // setIsLoader(true);
      // onFilterReset();
    }
  };

  //CONDITIONS FOR SUBMIT BUTTON
  const isStartDateValid: boolean = startDate && endDate ? false : true;
  const isSubmitDisabled: boolean =
    (searchKeyword.trim() === '' && publisherOptions.length === 0) ||
    (publisherOptions.length !== 0 && (startDate || endDate) ? true : false);

  const data =
    !rssFeedNewsData || rssFeedNewsData?.length === 0
      ? rssData
      : rssFeedNewsData;
  const loading = !data || data?.length === 0 ? isLoader : false;
  return (
    <>
      <div className={styles.rssListing}>
        <div className={styles.listingTop}>
          <div className='cust-container'>
            {/* <BreadCrumb hasDark={true} /> */}
            <h1 className={styles.pageTitle}>
              {t(AppConstant.HOME_PAGE.MEDICAL_NEWS)}
            </h1>
            {/* SEARCH BAR */}

            <div className={styles.rssFilterWrapper}>
              <h2 className={styles.searchTitle}>
                {t(AppConstant.COMMON.SEARCH)}
              </h2>
              <div className={styles.rssSearch}>
                <label>Keywords</label>
                <svg
                  width='18'
                  height='17'
                  viewBox='0 0 18 17'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M2 7.88607C2 4.63477 4.63413 2 7.88235 2C11.1306 2 13.7647 4.63477 13.7647 7.88607C13.7647 11.1374 11.1306 13.7721 7.88235 13.7721C4.63413 13.7721 2 11.1374 2 7.88607ZM7.88235 0C3.52854 0 0 3.53122 0 7.88607C0 12.2409 3.52854 15.7721 7.88235 15.7721C9.96855 15.7721 11.8653 14.9614 13.2751 13.6377L16.1743 16.5384C16.5647 16.9291 17.1978 16.9292 17.5885 16.5388C17.9791 16.1484 17.9793 15.5152 17.5888 15.1246L14.5538 12.0879C15.3209 10.8714 15.7647 9.43054 15.7647 7.88607C15.7647 3.53122 12.2362 0 7.88235 0Z'
                    fill='#002E5B'
                  />
                </svg>
                <input
                  type='text'
                  name='search'
                  placeholder={t(AppConstant.COMMON.SEARCH)}
                  value={searchKeyword}
                  // placeholder={t(AppConstant.COMMON.SEARCH)}
                  onChange={onSearchChange}
                  // onKeyDown={(e) => {
                  //   if (e.key === "Enter") {
                  //     handleSearch();
                  //   }
                  // }}
                />
              </div>
              {/* Dropdown */}
              <div className={styles.qualification}>
                <AutoComplete
                  placeholder={t(
                    AppConstant.KNOWLEDGE_HUB_PAGE.SELECT_PUBLISHER
                  )}
                  options={publishersList}
                  id='publisher'
                  labelText={t(AppConstant.KNOWLEDGE_HUB_PAGE.PUBLISHER)}
                  isRequired={false}
                  isError={false}
                  errorMessage={t(AppConstant.ERROR.INCORRECT)}
                  // handleChange={handleQualificationOptions}
                  // defaultValue={selectedQualification}
                  selectedOptions={publisherOptions}
                  onSelectChange={handleOptions}
                />
              </div>

              {/* Start Date */}
              <div className={styles.startEndDate}>
                <label htmlFor='startDate'>
                  {t(AppConstant.HOME_PAGE.START_DATE)}
                </label>
                <input
                  type='date'
                  id='startDate'
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>

              {/*  End Date */}
              <div className={styles.startEndDate}>
                <label htmlFor='endDate'>
                  {t(AppConstant.HOME_PAGE.END_DATE)}
                </label>
                <input
                  type='date'
                  id='endDate'
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>

              <button
                onClick={handleFilterClick}
                className={styles.submit}
                disabled={isSubmitDisabled && isStartDateValid}
                // disabled={
                //   (startDate &&
                //     endDate === "" &&
                //     publisherOptions.length === 0) ||
                //   searchKeyword.trim() === ""
                //     ? true
                //     : false
                // }
              >
                {t(AppConstant.AUTHENTICATION_PAGE.SEARCH_TEXT)}
              </button>
            </div>
          </div>
        </div>
        {(filterApiCall || reload) && (
          <div className={styles.loaderDiv}>
            <BarLoader />
          </div>
        )}
        <div className='cust-container'>
          <div className={styles.rssRow}>
            <div className={styles.listingCol8}>
              {/* {loading ? (
                <>
                  <CircularLoader size={50} thickness={4} />
                  <div className={styles.listHeight}></div>
                </>
              ) :  */}
              {data?.length > 0 ? (
                <InfiniteScrollComponent
                  dataLength={data?.length}
                  fetchMoreData={fetchMoreData}
                  hasMore={hasMore}
                  loader={true}
                >
                  {data.map((item: any) => (
                    <div className={styles.rssCol}>
                      <RssNewsFeedCard
                        isDescription={false}
                        rssData={item}
                        handleRssCardTitleClick={() => handleClick(item?.id)}
                      />
                    </div>
                  ))}
                </InfiniteScrollComponent>
              ) : (
                !filterApiCall &&
                !reload && (
                  <div className={styles.contentNotFound}>
                    <div className={styles.notFoundImg}>
                      <img
                        src={ImageConstant.SOURCE.contentNotFoundImg}
                        alt='not found'
                      />
                    </div>
                    <div className={styles.notFoundText}>
                      <h3>{`${t(AppConstant.HOME_PAGE.NO_TEXT)} ${t(
                        AppConstant.HOME_PAGE.DATA_TEXT
                      )} ${t(AppConstant.HOME_PAGE.FOUND_TEXT)}`}</h3>
                      <p>{t(AppConstant.HOME_PAGE.NO_DATA_FOUND_MESSAGE)}</p>
                    </div>
                  </div>
                )
              )}
            </div>
            <div className={styles.listingCol4}>
              <div className={styles.rssQuicklink}>
                <QuickLinkSection />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RssLisitngPage;
