import React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { ButtonComponentPropsType } from '.';
import styles from './button.module.scss';

export const ButtonComponent: React.FC<ButtonComponentPropsType> = (props) => {
  const { buttonText, variant, onPress, disabled, loader } = props;
  return (
    <div className={styles['shalina-primaryBtn']}>
      <Button
        fullWidth
        variant={variant}
        onClick={onPress}
        disabled={disabled || loader}
      >
        {buttonText}
        {loader && (
          <span className={styles.downloadLoader}>
            <CircularProgress
              size={20}
              thickness={4}
              sx={{
                color: 'var(--secondaryOrange)'
              }}
            />
          </span>
        )}
      </Button>
    </div>
  );
};
