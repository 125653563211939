import { useState } from 'react';
import { AppConstant, ImageConstant, translate } from '@shalina-app/shared';
import { Dialog, DialogContent } from '@mui/material';

import { SuccessDialogItemProps } from './successDialog.interface';
import { ButtonComponent } from '../button';
import styles from './successDialog.module.scss';
import { useNavigatePageUtils } from '../../utils';
import { URLConstant } from '../../routes';

export const SuccessDialogComponent: React.FC<SuccessDialogItemProps> = ({
  successMessage,
  buttonText,
  closeSuccessDialog
}) => {
  const t = translate();
  const { urls } = URLConstant;
  const [successPopUp, setSuccessPopUp] = useState(true);
  const navigate = useNavigatePageUtils();

  const successPopUpClose = (value: boolean) => {
    setSuccessPopUp(false);
    closeSuccessDialog();
    if (value) {
      navigate(urls.homePage, { isDynamicUrl: false });
    } else {
      navigate(urls.profilePage, {
        isDynamicUrl: false,
        stateParams: {
          navigateFromHelpSupport: true
        }
      });
    }
  };

  const handlePopUpClose = () => {
    setSuccessPopUp(false);
    closeSuccessDialog();
  };
  return (
    <>
      <Dialog
        open={successPopUp}
        onClose={handlePopUpClose}
        className={styles.modifyModal}
      >
        <DialogContent>
          <div className={styles.helpdeskSubmit}>
            <img src={ImageConstant.SOURCE.successStar} alt='success img' />
            <h4 className={styles.title}>
              {t(AppConstant.HOME_PAGE.THANK_YOU)}
            </h4>
            <p className={styles.msg}>{successMessage}</p>
            <div className={styles.modalButtons}>
              <div className={`${styles.modalButton} ${styles.returnHome}`}>
                <ButtonComponent
                  variant='outlined'
                  // buttonText={buttonText}
                  buttonText={t(AppConstant.COMMON.RETURN_HOME_CTA)}
                  onPress={() => successPopUpClose(true)}
                />
              </div>
              <div className={styles.modalButton}>
                <ButtonComponent
                  variant='outlined'
                  buttonText={t(AppConstant.COMMON.VIEW_REQUESTS)}
                  onPress={() => successPopUpClose(false)}
                />
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
