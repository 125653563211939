"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Environment = exports.currentEnvironment = void 0;
// Enum for environments
const Environment = {
    DEV: 'dev',
    QA: 'qa',
    PREPROD: 'preProd',
    PRODUCTION: 'production'
};
exports.Environment = Environment;
// Specify environment
const currentEnvironment = Environment === null || Environment === void 0 ? void 0 : Environment.PREPROD;
exports.currentEnvironment = currentEnvironment;
