"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TreatmentIcon = void 0;
const React = __importStar(require("react"));
const TreatmentIcon = (props) => (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: 20, height: 20, fill: "none" }, props),
    React.createElement("path", { fill: "#E84E1B", d: "M19.695 4.44 15.558.304a1.036 1.036 0 1 0-1.466 1.466l1.337 1.335-1.982 1.982-2.716-2.714a1.036 1.036 0 1 0-1.465 1.465l.302.301-6.993 6.996a1.711 1.711 0 0 0-.505 1.219v4.11L.303 18.232a1.036 1.036 0 1 0 1.466 1.465l1.766-1.767h4.113a1.71 1.71 0 0 0 1.219-.505l6.993-6.994.303.303a1.036 1.036 0 1 0 1.465-1.465l-2.716-2.717 1.982-1.982 1.337 1.338a1.036 1.036 0 1 0 1.465-1.465l-.001-.001ZM7.5 15.86H4.138V12.5l1.207-1.207 1.509 1.51a1.036 1.036 0 0 0 1.465-1.465L6.81 9.827l.948-.948 1.51 1.51a1.036 1.036 0 1 0 1.465-1.466l-1.51-1.51 1.81-1.81 3.362 3.362L7.5 15.86Z" })));
exports.TreatmentIcon = TreatmentIcon;
