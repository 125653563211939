"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentIcon = void 0;
const React = __importStar(require("react"));
const ContentIcon = (props) => (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: 17, height: 14, fill: "none" }, props),
    React.createElement("path", { fill: "#E84E1B", fillRule: "evenodd", d: "M10.046 3.09a.773.773 0 0 0-.773.774v6.181a.773.773 0 0 0 .773.773h3.091a.773.773 0 0 0 .773-.773V3.864a.773.773 0 0 0-.773-.773h-3.09Zm2.318 1.546H10.82v4.637h1.545V4.636Z", clipRule: "evenodd" }),
    React.createElement("path", { fill: "#E84E1B", d: "M3.866 3.09a.773.773 0 1 0 0 1.546h3.091a.773.773 0 0 0 0-1.545h-3.09Zm0 3.092a.773.773 0 1 0 0 1.545h3.091a.773.773 0 0 0 0-1.545h-3.09Zm-.772 3.863a.773.773 0 0 1 .772-.772h3.091a.773.773 0 1 1 0 1.545h-3.09a.773.773 0 0 1-.773-.773Z" }),
    React.createElement("path", { fill: "#E84E1B", fillRule: "evenodd", d: "M2.318 0A2.318 2.318 0 0 0 0 2.318v9.273a2.318 2.318 0 0 0 2.318 2.318h12.364A2.318 2.318 0 0 0 17 11.591V2.318A2.318 2.318 0 0 0 14.682 0H2.318Zm12.364 1.545H2.318a.773.773 0 0 0-.773.773v9.273a.773.773 0 0 0 .773.773h12.364a.773.773 0 0 0 .772-.773V2.318a.773.773 0 0 0-.772-.773Z", clipRule: "evenodd" })));
exports.ContentIcon = ContentIcon;
