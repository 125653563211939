"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LanguageIcon = void 0;
const React = __importStar(require("react"));
const LanguageIcon = (props) => (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: 18, height: 18, fill: "none" }, props),
    React.createElement("path", { fill: "#E84E1B", stroke: "#fff", strokeWidth: 0.1, d: "M15.228 5.45h.087l-.044-.075a7.277 7.277 0 0 0-3.924-3.226l-.12-.042.06.113c.541 1.01.957 2.082 1.237 3.192l.01.038h2.694ZM9.041 1.808 9 1.748l-.041.06a12.728 12.728 0 0 0-1.726 3.578l-.02.064H10.787l-.02-.064a12.73 12.73 0 0 0-1.725-3.578Zm-7.056 9.004.01.038h3.137l-.006-.056A14.815 14.815 0 0 1 5 9c0-.61.054-1.2.126-1.794l.006-.056H1.995l-.01.038A7.468 7.468 0 0 0 1.75 9c0 .626.09 1.233.235 1.812Zm.787 1.738h-.087l.044.075a7.238 7.238 0 0 0 3.924 3.226l.12.042-.06-.113a14.033 14.033 0 0 1-1.238-3.192l-.01-.038H2.773Zm2.655-7.1h.039l.01-.038c.28-1.11.696-2.183 1.237-3.192l.06-.112-.12.04a7.238 7.238 0 0 0-3.924 3.227l-.044.075h2.742Zm3.532 10.742.041.06.041-.06a12.729 12.729 0 0 0 1.726-3.578l.02-.064H7.213l.02.064a12.727 12.727 0 0 0 1.725 3.578Zm2.147-5.342h.044l.005-.043c.082-.595.145-1.192.145-1.807s-.063-1.22-.145-1.807l-.005-.043H6.85l-.006.043A13.175 13.175 0 0 0 6.7 9c0 .615.063 1.212.144 1.807l.006.043H11.106Zm.181 4.93-.061.113.121-.042a7.277 7.277 0 0 0 3.924-3.226l.044-.075h-2.781l-.01.038a14.037 14.037 0 0 1-1.237 3.192Zm1.587-4.986-.006.056h3.137l.01-.038c.144-.58.235-1.186.235-1.812 0-.626-.09-1.233-.235-1.812l-.01-.038h-3.137l.006.056C12.946 7.799 13 8.391 13 9c0 .61-.054 1.2-.126 1.794ZM.05 9c0-4.94 4-8.95 8.941-8.95 4.95 0 8.959 4.01 8.959 8.95 0 4.94-4.01 8.95-8.959 8.95C4.051 17.95.05 13.94.05 9Z" })));
exports.LanguageIcon = LanguageIcon;
