import React, { useEffect, useState } from 'react';
import {
  AppConstant,
  getExpertList,
  listExpert,
  translate,
  useAppDispatch,
  useAppSelector
} from '@shalina-app/shared';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useLocation } from 'react-router-dom';

import styles from './aboutUs.module.scss';
import { WhoWeAre } from './whoWeAre';
import { ExpertItemProps, Experts } from './experts';
import { Journey } from './journey';
import { AboutUsProps } from '.';
import { QuickLinkSection } from '../../../../components';
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export const AboutUsPage: React.FC<AboutUsProps> = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const t = translate();

  const tab = location?.state?.tab ? location?.state?.tab : 0;

  const [tabValue, setTabValue] = useState(0);
  const [reload, setReload] = useState(false);

  const listExperts: ExpertItemProps[] = useAppSelector(getExpertList);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setReload(true);
  }, []);

  useEffect(() => {
    if (reload && listExperts && listExperts?.length === 0) {
      loadApi();
      setReload(false);
    }
  }, [reload]);

  const loadApi = async () => {
    await dispatch(listExpert({}) as any);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setTabValue(tab);
  }, [tab]);

  const tabs = [
    {
      id: 0,
      label: t(AppConstant.COMMON.ABOUT_US_SHALINA_MEDSPACE_TAB),
      component: <WhoWeAre />
    },
    {
      id: 1,
      label: t(AppConstant.COMMON.ABOUT_US_SHALINA_HEALTHCARE_TAB),
      component: <Journey />
    },
    {
      id: 2,
      label: t(AppConstant.COMMON.EXPERTS_TAB_TEXT),
      component: <Experts listExperts={listExperts} />
    }
  ];

  return (
    <>
      {/* about us */}
      <div className={styles.aboutPage}>
        <div className={styles.aboutTop}>
          <div className='cust-container'>
            <h1 className={styles.head}>
              {t(AppConstant.HEADER.ABOUT_US_MENU)}
            </h1>
          </div>
        </div>
        <div className='cust-container'>
          <div className={styles.aboutRow}>
            <div className={styles.aboutCol8}>
              <div className={styles.aboutPageWrapper}>
                <div className={styles.shalinaTabs}>
                  <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    aria-label='Detail Tabs'
                    className={styles.custTabs}
                  >
                    {tabs?.map((tab) => (
                      <Tab label={tab?.label} {...a11yProps(tab?.id)} />
                    ))}
                  </Tabs>
                  <div className={styles.custTabContent}>
                    {tabs?.map((tab) => (
                      <CustomTabPanel value={tabValue} index={tab?.id}>
                        {tab?.component}
                      </CustomTabPanel>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.aboutCol4}>
              <div className={styles.aboutQuicklink}>
                <QuickLinkSection />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
