"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBookmarkData = exports.updateProfilePictureViaFormData = exports.updateProfilePicture = exports.updateLikeContent = exports.updateBookmark = exports.fetchBookmark = void 0;
const api_1 = require("../../../api");
const toolkit_1 = require("@reduxjs/toolkit");
const constants_1 = require("../../constants");
const utils_1 = require("../../utils");
const initialState = {
    isLoading: false,
    bookmark: {
        isError: false,
        isBookmarkLoading: false,
        bookmarks: {}
    },
    updateBookmarks: {
        isError: false,
        isUpdateBookmarkLoading: false,
        updateBookmark: {}
    },
    updateLikeContents: {
        isError: false,
        isUpdateLikeContentLoading: false,
        updateLikeContent: {}
    },
    updateProfilePic: {
        isError: false,
        isUpdateProfilePicLoading: false,
        profilePic: {}
    }
};
const profileSlice = (0, toolkit_1.createSlice)({
    name: constants_1.PROFILE,
    initialState,
    reducers: {},
    extraReducers(builder) {
        /**
         * Bookmark Content API
        //  */
        builder.addCase(exports.fetchBookmark.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.bookmark.isBookmarkLoading = true;
        });
        builder.addCase(exports.fetchBookmark.fulfilled, (state, action) => {
            // Add logic if any when API state is fulfilled or data is received from API
            state.bookmark.isBookmarkLoading = false;
            state.bookmark.bookmarks = action === null || action === void 0 ? void 0 : action.payload;
        });
        builder.addCase(exports.fetchBookmark.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.bookmark.isBookmarkLoading = false;
        });
        builder.addCase(exports.updateBookmark.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.updateBookmarks.isUpdateBookmarkLoading = true;
        });
        builder.addCase(exports.updateBookmark.fulfilled, (state, action) => {
            // Add logic if any when API state is fulfilled or data is received from API
            state.updateBookmarks.isUpdateBookmarkLoading = false;
            state.updateBookmarks.updateBookmark = action === null || action === void 0 ? void 0 : action.payload;
        });
        builder.addCase(exports.updateBookmark.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.updateBookmarks.isUpdateBookmarkLoading = false;
        });
        builder.addCase(exports.updateLikeContent.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.updateLikeContents.isUpdateLikeContentLoading = true;
        });
        builder.addCase(exports.updateLikeContent.fulfilled, (state, action) => {
            // Add logic if any when API state is fulfilled or data is received from API
            state.updateLikeContents.isUpdateLikeContentLoading = false;
            state.updateLikeContents.updateLikeContent = action === null || action === void 0 ? void 0 : action.payload;
        });
        builder.addCase(exports.updateLikeContent.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.updateLikeContents.isUpdateLikeContentLoading = false;
        });
        builder.addCase(exports.updateProfilePicture.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.updateProfilePic.isUpdateProfilePicLoading = true;
        });
        builder.addCase(exports.updateProfilePicture.fulfilled, (state, action) => {
            // Add logic if any when API state is fulfilled or data is received from API
            state.updateProfilePic.isUpdateProfilePicLoading = false;
            state.updateProfilePic.profilePic = action === null || action === void 0 ? void 0 : action.payload;
        });
        builder.addCase(exports.updateProfilePicture.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.updateProfilePic.isUpdateProfilePicLoading = false;
        });
        /**
         * Via Formdata
         */
        builder.addCase(exports.updateProfilePictureViaFormData.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
        });
        builder.addCase(exports.updateProfilePictureViaFormData.fulfilled, (state, action) => {
            // Add logic if any when API state is fulfilled or data is received from API		
        });
        builder.addCase(exports.updateProfilePictureViaFormData.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
        });
    },
});
exports.fetchBookmark = (0, toolkit_1.createAsyncThunk)(`${constants_1.PROFILE}/${constants_1.LIST_BOOKMARKS}`, (filterOptions) => __awaiter(void 0, void 0, void 0, function* () {
    const params = (0, utils_1.filterOptionsParams)(filterOptions);
    const bookmarkContent = yield (0, api_1.get)(`${constants_1.LIST_BOOKMARK_ENDPOINT}${params}`);
    return bookmarkContent;
}));
exports.updateBookmark = (0, toolkit_1.createAsyncThunk)(`${constants_1.PROFILE}/${constants_1.ADD_REMOVE_BOOKMARK}`, (req) => __awaiter(void 0, void 0, void 0, function* () {
    const bookmarkContent = yield (0, api_1.put)(constants_1.UPDATE_BOOKMARK_ENDPOINT, req);
    return bookmarkContent;
}));
exports.updateLikeContent = (0, toolkit_1.createAsyncThunk)(`${constants_1.PROFILE}/${constants_1.UPDATE_LIKE_CONTENT}`, (req) => __awaiter(void 0, void 0, void 0, function* () {
    const likeContent = yield (0, api_1.put)(constants_1.GET_LIKE_CONTENT_ENDPOINT, req);
    return likeContent;
}));
exports.updateProfilePicture = (0, toolkit_1.createAsyncThunk)(`${constants_1.PROFILE}/${constants_1.UPDATE_PROFILE_PICTURE}`, (req) => __awaiter(void 0, void 0, void 0, function* () {
    const updateProfilePic = yield (0, api_1.post)(constants_1.UPDATE_PROFILE_PICTURE_ENDPOINT, req);
    return updateProfilePic;
}));
exports.updateProfilePictureViaFormData = (0, toolkit_1.createAsyncThunk)(`${constants_1.PROFILE}/${constants_1.UPDATE_PROFILE_PICTURE_ENDPOINT_VIA_FORM_DATA}`, (req) => __awaiter(void 0, void 0, void 0, function* () {
    const updateProfilePic = yield (0, api_1.post)(constants_1.UPDATE_PROFILE_PICTURE_ENDPOINT_VIA_FORM_DATA, req, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return updateProfilePic;
}));
exports.getBookmarkData = (0, toolkit_1.createSelector)((state) => state.profile, (profile) => { var _a, _b; return (_b = (_a = profile === null || profile === void 0 ? void 0 : profile.bookmark) === null || _a === void 0 ? void 0 : _a.bookmarks) === null || _b === void 0 ? void 0 : _b.data; });
exports.default = profileSlice.reducer;
