import { Dialog, DialogContent } from '@mui/material';
import {
  AppConstant,
  COMMUNITIES_API_CATEGORY_TYPES,
  COMMUNITIES_TABS,
  CommunityStatus,
  COMMUNITY_ENABLED,
  COMMUNITY_REQUEST_STATUS,
  CUSTOM_ANALYTICS_EVENT_NAMES,
  ImageConstant,
  logEvent,
  PlatformNames,
  translate,
  updateCommunity,
  useAppDispatch,
} from '@shalina-app/shared';
import { useState } from 'react';

import {
  ButtonLoader,
  CommunitiesShortCardProps,
  CommunityGuideline,
  Members,
} from '..';
import { appInsights } from '../../../../applicationInsight';
import { Speciality, Toast } from '../../../../components';
import { URLConstant } from '../../../../routes';
import {
  convertToSpecialties,
  getImageSrc,
  retrieveData,
  STORAGE_CONSTANTS,
  useNavigatePageUtils,
} from '../../../../utils';
import { SignUpPopup } from '../../../Authentication';
import styles from './CommunitiesShortCard.module.scss';

export const CommunitiesShortCard: React.FC<CommunitiesShortCardProps> = ({
  isRequest,
  categoryType,
  content,
  isFullWidth,
  handleRevokeRequest,
  handleRejectRequest,
  handleAcceptRequest,
  handleCommunityClick,
  isSentTabActive,
  tabChange,
  handleMainApiCall,
}) => {
  const t = translate();
  const dispatch = useAppDispatch();
  const navigatePage = useNavigatePageUtils();
  const { urls } = URLConstant;

  const specialityArr = convertToSpecialties(content?.field_interests || '');
  const isLoggedIn: boolean = retrieveData(STORAGE_CONSTANTS.IS_LOGGED_IN);
  const user = retrieveData(STORAGE_CONSTANTS.USER);

  // const [askToJoinLoader, setAskToJoinLoader] = useState(false);
  const [rejectLoader, setRejectLoader] = useState(false);
  const [acceptLoader, setAcceptLoader] = useState(false);
  const [revokeLoader, setRevokeLoader] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [openGuidelinePopup, setOpenGuidelinePopup] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const handleToastClose = () => {
    setShowToast(false);
  };

  const askToJoinClick = async (content: any) => {
    if (isLoggedIn) {
      // setAskToJoinLoader(true);
      setOpenGuidelinePopup(true);
    } else {
      setShowSignUp(true);
    }
  };

  const handleSignUpClose = () => {
    setShowSignUp(false);
  };

  const revokeClick = async (id: string, communityName: string) => {
    setRevokeLoader(true);
    await handleRevokeRequest!(id, communityName);
    setRevokeLoader(false);
  };

  const acceptClick = async (id: string, communityName: string) => {
      setAcceptLoader(true);
      await handleAcceptRequest!(id, communityName);
      setAcceptLoader(false);
  };

  const rejectClick = async (id: string, communityName: string) => {
      setRejectLoader(true);
      await handleRejectRequest!(id, communityName);
      setRejectLoader(false);
  };

  const handleGuidelinePopupClose = () => {
    setOpenGuidelinePopup(false);
    // setAskToJoinLoader(false);
  };

  const handleJoinRequestSubmit = async () => {
    const req = {
      communityId: content?.id,
      requestStatus: COMMUNITY_REQUEST_STATUS.JOIN,
    };
    const response = await dispatch(updateCommunity(req)).unwrap();
    if (
      response &&
      response?.status === 'SUCCESS' &&
      response?.statusCode === 200
    ) {
      setShowToast(true);
      setIsSuccess(true);
      setToastMessage(t(AppConstant.COMMUNITY.JOIN_COMMUNITY_REQUEST_SUCCESS));
      handleMainApiCall();
      // setAskToJoinLoader(false);
      tabChange!(COMMUNITIES_TABS.REQUESTS);
    } else {
      setShowToast(true);
      setIsSuccess(false);
      setToastMessage(t(AppConstant.COMMUNITY.JOIN_COMMUNITY_REQUEST_FAILED));
      // setAskToJoinLoader(false);
    }
    setOpenGuidelinePopup(false);
  };

  const showAskToJoinCta =
    (categoryType === COMMUNITIES_API_CATEGORY_TYPES.EXPLORE_COMMUNITY && [undefined, false, '']?.includes(content?.iscommunityjoined)) ||
    categoryType === COMMUNITIES_API_CATEGORY_TYPES.RECOMMENDED_COMMUNITY
      ? true
      : false;

  const { CrownIcon } = ImageConstant.SVG_ICONS;
  return (
    <>
      <div
        className={`${styles.exploreCard} ${
          isFullWidth ? styles.fullWidth : ''
        }`}
      >
        <div className={styles.exploreIconWrapper}>
          <img
            className={styles.exploreIcon}
            src={
              getImageSrc(content?.field_community_icon) ||
              ImageConstant.SOURCE.communityIcon
            }
            alt='img'
            onError={(e) =>
              ((e.target as HTMLInputElement).src =
                ImageConstant.SOURCE.communityIcon)
            }
          />
        </div>
        {/* showAskToJoinCta && styles.isAskToJoin */}
        <div
          className={`${styles.details} ${styles.isAskToJoin} ${
            content?.isKol && styles.crownWrap
          }`}
        >
          <div className={styles.insideDatail}>
            <h4
              className={styles.title}
              onClick={() => handleCommunityClick(content?.id)}
            >
              {content?.title}
            </h4>
            <div className={styles.speciality}>
              <Speciality speciality={specialityArr} showAll={false} />
            </div>
            <Members
              memberCount={content?.member_count}
              memberImages={content?.member_image}
            />
          </div>
          <div className={styles.ctaWrapper}>
            {showAskToJoinCta ? (
              <button
                className={styles.joinCta}
                onClick={() => askToJoinClick(content)}
              >
                {t(AppConstant.COMMUNITY.COMMUNITY_ASK_TO_JOIN_CTA)}
                {/* <ButtonLoader loader={askToJoinLoader} /> */}
              </button>
            ) : isRequest && !isSentTabActive ? (
              <>
                <button
                  className={styles.reqCta}
                  onClick={() => rejectClick(content?.id, content?.title)}
                >
                  {t(AppConstant.COMMUNITY.COMMUNITY_REJECT_CTA)}
                  <ButtonLoader loader={rejectLoader} />
                </button>
                <button
                  className={`${styles.reqCta} ${styles.accept}`}
                  onClick={() => acceptClick(content?.id, content?.title)}
                >
                  {t(AppConstant.COMMUNITY.COMMUNITY_ACCEPT_CTA)}
                  <ButtonLoader loader={acceptLoader} />
                </button>
              </>
            ) : isRequest && isSentTabActive ? (
              <>
                <button
                  className={styles.reqCta}
                  onClick={() => revokeClick(content?.id, content?.title)}
                >
                  {t(AppConstant.COMMUNITY.COMMUNITY_REVOKE_CTA)}
                  <ButtonLoader loader={revokeLoader} />
                </button>
              </>
            ) : null}

            {content?.isKol && (
              <div className={styles.crownIcon}>
                <CrownIcon />
              </div>
            )}
          </div>
        </div>
      </div>
      <SignUpPopup open={showSignUp} handleClose={handleSignUpClose} />
      <Dialog
        open={openGuidelinePopup}
        onClose={handleGuidelinePopupClose}
        className={styles.joinModal}
      >
        <DialogContent>
          <button
            className={styles.closeCta}
            onClick={handleGuidelinePopupClose}
          >
            <svg
              width='12'
              height='12'
              viewBox='0 0 14 14'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M1 0.999999L7 7L0.999998 13'
                stroke='white'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
              <path
                d='M13 13L7 7L13 1'
                stroke='white'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          </button>
          <CommunityGuideline
            communityGuidelines={content?.field_community_guidelines}
            handleJoinRequestSubmit={handleJoinRequestSubmit}
          />
        </DialogContent>
      </Dialog>
      <Toast
        showToast={showToast}
        isSuccess={isSuccess}
        message={toastMessage}
        handleToastClose={handleToastClose}
      />
    </>
  );
};
