import {
  AppConstant,
  CONTENT_TYPE_ID,
  getFilterCategoriesOptionsValues,
  ImageConstant,
  translate,
  useAppSelector
} from '@shalina-app/shared';

import { QuickMenuProps } from '.';
import { pageNames } from '../../../../app.constants';
import { URLConstant } from '../../../../routes';
import { useNavigatePageUtils } from '../../../../utils';
import styles from './quickMenu.module.scss';

export const QuickMenu: React.FC<QuickMenuProps> = ({
  selectedAreaOfInterest,
  filterOptionsInterest
}) => {
  const navigatePage = useNavigatePageUtils();
  const { urls } = URLConstant;
  const t = translate();

  const masterContentTypeData = useAppSelector(
    getFilterCategoriesOptionsValues
  );

  const HOME_QUICK_MENU_OPTIONS = [
    {
      id: 1,
      label: t(AppConstant.COMMON.JOURNALS_MENU),
      icon: ImageConstant.SOURCE.newsIcon,
      redirectUrl: urls.listingPageWithModule,
      pageName: pageNames.KnowledgeHub,
      isDynamicUrl: true,
      uniqueId: CONTENT_TYPE_ID.JOURNALS_ARTICLES
    },
    {
      id: 2,
      label: t(AppConstant.COMMON.EBOOKS_MENU),
      icon: ImageConstant.SOURCE.EBookIcon,
      redirectUrl: urls.listingPageWithModule,
      pageName: pageNames.KnowledgeHub,
      isDynamicUrl: true,
      uniqueId: CONTENT_TYPE_ID.E_BOOKS
    },
    {
      id: 3,
      label: t(AppConstant.COMMON.CLINICAL_GUIDELINES_MENU),
      icon: ImageConstant.SOURCE.ClinicalGuidelinesIcon,
      redirectUrl: urls.listingPageWithModule,
      pageName: pageNames.KnowledgeHub,
      isDynamicUrl: true,
      uniqueId: CONTENT_TYPE_ID.CLINICAL_GUIDELINES
    },
    {
      id: 4,
      label: t(AppConstant.HEADER.MEDICAL_CALCULATOR_MENU),
      icon: ImageConstant.SOURCE.MedicalCalculatorsIcon,
      redirectUrl: urls.medicalCalculator,
      pageName: '',
      isDynamicUrl: false,
      uniqueId: ''
    },
    {
      id: 5,
      label: t(AppConstant.COMMON.WEBINARS_MENU),
      icon: ImageConstant.SOURCE.WebinarIcon,
      redirectUrl: urls.learningCenterPage,
      pageName: pageNames.LearningCenter,
      isDynamicUrl: true,
      uniqueId: CONTENT_TYPE_ID.WEBINARS
    },
    {
      id: 6,
      label: t(AppConstant.COMMON.MAGZINES_MENU),
      icon: ImageConstant.SOURCE.MagazineIcon,
      redirectUrl: urls.listingPageWithModule,
      pageName: pageNames.KnowledgeHub,
      isDynamicUrl: true,
      uniqueId: CONTENT_TYPE_ID.MAGZINES
    },
    {
      id: 7,
      label: t(AppConstant.HOME_PAGE.MEDICAL_NEWS),
      icon: ImageConstant.SOURCE.Medical_NewsIcon,
      redirectUrl: urls.rssListing,
      pageName: '',
      isDynamicUrl: false,
      uniqueId: ''
    },
    {
      id: 8,
      label: t(AppConstant.COMMON.VIDEOS_MENU),
      icon: ImageConstant.SOURCE.VideoIcon,
      redirectUrl: urls.shalinaTv,
      pageName: pageNames.ShalinaTv,
      isDynamicUrl: false,
      uniqueId: ''
    }
  ];

  const handleMenuClick = (item: any) => {
    if (item?.isDynamicUrl) {
      const contentTypeId: any = masterContentTypeData?.find(
        (obj: any) => obj?.unique_id === item?.uniqueId
      )?.value;

      if (contentTypeId && item?.pageName) {
        const navigateDetails = {
          stateParams: {
            filterRetain: true,
            userSelectedAreaOfInterest:
              filterOptionsInterest || selectedAreaOfInterest?.toString()
          },
          isDynamicUrl: true,
          dynamicUrlOptions: {
            contentTypeId: contentTypeId,
            pageName: item?.pageName
          }
        };
        navigatePage(item?.redirectUrl, navigateDetails);
      }
    } else {
      const navigateDetails = {
        // stateParams: {
        //   parentSelectedAreaOfInterest: selectedAreaOfInterest,
        //   parentCategory: null,
        // },
        isDynamicUrl: false
      };
      navigatePage(item?.redirectUrl, navigateDetails);
    }
  };

  return (
    <div className={styles.quickGoList}>
      {HOME_QUICK_MENU_OPTIONS?.map((item: any) => (
        <div
          className={styles.quickGoItem}
          onClick={() => handleMenuClick(item)}
        >
          <div className={styles.icon}>
            <img src={item?.icon} alt='' />
          </div>
          <div className={styles.name}>{item?.label}</div>
        </div>
      ))}
    </div>
  );
};
