import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import styles from './modal.module.scss';

export const Modal: React.FC<any> = ({
  modalOpen,
  handleModalClose,
  children,
  className
}) => {
  return (
    <Dialog
      open={modalOpen}
      onClose={handleModalClose}
      className={`${styles.helpdeskModal} ${styles[className]}`}
    >
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};
