"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonitorIcon = void 0;
const React = __importStar(require("react"));
const MonitorIcon = (props) => (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: 19, height: 19, fill: "none" }, props),
    React.createElement("path", { fill: "#E84E1B", d: "M6.8 15.8a.9.9 0 0 0 .342-.072c.11-.046.21-.11.297-.189a.901.901 0 0 0 .108-.144.503.503 0 0 0 .081-.153.576.576 0 0 0 .072-.162c.004-.06.004-.12 0-.18a.9.9 0 0 0-.072-.342.81.81 0 0 0-.486-.486A.9.9 0 0 0 6.62 14l-.162.054a.505.505 0 0 0-.153.081.901.901 0 0 0-.144.108.9.9 0 0 0-.189.297.9.9 0 0 0-.072.36.9.9 0 0 0 .9.9Zm-3.339-.261c.087.08.188.143.297.189a.9.9 0 0 0 .342.072h.171a.539.539 0 0 0 .171-.054.683.683 0 0 0 .162-.081l.135-.108c.08-.088.143-.188.189-.297A.756.756 0 0 0 5 14.9c.004-.06.004-.12 0-.18a.576.576 0 0 0-.054-.162.503.503 0 0 0-.081-.153.901.901 0 0 0-.108-.144.9.9 0 0 0-.981-.189.9.9 0 0 0-.297.189.901.901 0 0 0-.108.144.503.503 0 0 0-.081.153.576.576 0 0 0-.09.162c-.004.06-.004.12 0 .18a.9.9 0 0 0 .072.342c.046.11.11.21.189.297ZM15.8.5H3.2A2.7 2.7 0 0 0 .5 3.2v12.6a2.7 2.7 0 0 0 2.7 2.7h12.6a2.7 2.7 0 0 0 2.7-2.7V3.2A2.7 2.7 0 0 0 15.8.5Zm.9 15.3a.9.9 0 0 1-.9.9H3.2a.9.9 0 0 1-.9-.9v-2.7h14.4v2.7Zm0-4.5H2.3V7.7h3.6a.9.9 0 0 0 .639-.261L7.7 6.314l2.52 2.88a.9.9 0 0 0 .648.306.9.9 0 0 0 .639-.261L13.019 7.7H16.7v3.6Zm0-5.4h-4.05a.9.9 0 0 0-.639.261l-1.116 1.125-2.52-2.88a.9.9 0 0 0-1.314 0L5.531 5.9H2.3V3.2a.9.9 0 0 1 .9-.9h12.6a.9.9 0 0 1 .9.9v2.7Z" })));
exports.MonitorIcon = MonitorIcon;
