import { useRef, useState } from 'react';
import Slider from 'react-slick';
import {
  AppConstant,
  getAchievedRecognitionBadges,
  getRecognitionBadgesData,
  ImageConstant,
  translate,
  useAppSelector,
} from '@shalina-app/shared';

import styles from './recognition.module.scss';
import { RecognitionInterface } from '.';
import { getImageSrc } from '../../../../../utils';

export const Recognition: React.FC<RecognitionInterface> = () => {
  const t = translate();
  const earnedBadgeSliderRef: any = useRef(null);
  const badgeInfoSliderRef: any = useRef(null);

  const [earnedBadgeSlideIndex, setBadgeSlideIndex] = useState<any>(0);
  const [badgeInfoSlideIndex, setBadgeInfoSlideIndex] = useState<any>(0);

  const { SliderArrowIcon } = ImageConstant.SVG_ICONS;

  const recognitionBadgesList = useAppSelector(getRecognitionBadgesData);
  const achievedBadgesList = useAppSelector(getAchievedRecognitionBadges);
  const badgeToShow = 6;
  const badgeInfoToShow = 3;

  const badgeSliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: badgeToShow,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '28px',
        },
      },
    ],
    beforeChange: (current: any, next: any) => setBadgeSlideIndex(next),
  };

  const badgeNextArrowClick = () => {
    earnedBadgeSliderRef?.current?.slickNext();
  };
  const badgePrevArrowClick = () => {
    earnedBadgeSliderRef?.current?.slickPrev();
  };

  const sliderSettings2 = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: badgeInfoToShow,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '28px',
        },
      },
    ],
    beforeChange: (current: any, next: any) => setBadgeInfoSlideIndex(next),
  };

  const badgeInfoNextArrowClick = () => {
    badgeInfoSliderRef?.current?.slickNext();
  };
  const badgeInfoPrevArrowClick = () => {
    badgeInfoSliderRef?.current?.slickPrev();
  };

  const isEarnedBadgeLastSlide =
    achievedBadgesList?.length - badgeToShow === earnedBadgeSlideIndex;
  const isEarnedBadgeFirstSlide = earnedBadgeSlideIndex === 0;
  const showEarnedBadgeSliderArrow =
    achievedBadgesList?.length > badgeToShow ? true : false;

  const isBadgeInfoLastSlide =
    recognitionBadgesList?.length - badgeInfoToShow === badgeInfoSlideIndex;
  const isBadgeInfoFirstSlide = badgeInfoSlideIndex === 0;
  const showBadgeInfoSliderArrow =
    recognitionBadgesList?.length > badgeInfoToShow ? true : false;
  return (
    <>
      <div className={styles.BadgeSec}>
        <div className={styles.titleArrow}>
          <h4>{t(AppConstant.COMMON.BADGES_EARNED)}</h4>
          {showEarnedBadgeSliderArrow && (
            <div className={styles.sliderArrow}>
              <button
                className={styles.prev}
                onClick={badgePrevArrowClick}
                disabled={isEarnedBadgeFirstSlide ? true : false}
              >
                <SliderArrowIcon />
              </button>
              <button
                className={styles.next}
                onClick={badgeNextArrowClick}
                disabled={isEarnedBadgeLastSlide ? true : false}
              >
                <SliderArrowIcon />
              </button>
            </div>
          )}
        </div>
        {achievedBadgesList?.length > 0 ? (
          <Slider ref={earnedBadgeSliderRef} {...badgeSliderSettings}>
            {achievedBadgesList?.map((badge: any) => (
              <div className={styles.earnItem}>
                <img src={getImageSrc(badge?.uri)} alt='img' />
                <h4>{badge?.name}</h4>
              </div>
            ))}
          </Slider>
        ) : (
          <div className={`${styles.earnItem} ${styles.noBadge}`}>
            <img src={ImageConstant.SOURCE.BadgeNA} alt='img' />
            <h4>{t(AppConstant.COMMON.NO_BADGES)}</h4>
          </div>
        )}
      </div>

      <div className={styles.earnBadge}>
        <div className={styles.titleArrow}>
          <h4>{t(AppConstant.COMMON.HOW_TO_EARN_BADGES)}</h4>
          {showBadgeInfoSliderArrow && (
            <div className={styles.sliderArrow}>
              <button
                className={styles.prev}
                onClick={badgeInfoPrevArrowClick}
                disabled={isBadgeInfoFirstSlide ? true : false}
              >
                <SliderArrowIcon />
              </button>
              <button
                className={styles.next}
                onClick={badgeInfoNextArrowClick}
                disabled={isBadgeInfoLastSlide ? true : false}
              >
                <SliderArrowIcon />
              </button>
            </div>
          )}
        </div>
        <Slider ref={badgeInfoSliderRef} {...sliderSettings2}>
          {recognitionBadgesList?.map((info: any) => (
            <div className={styles.earnBoxWrap}>
              <div className={styles.earnBox}>
                <img src={getImageSrc(info?.field_icon)} alt='img' />
                <h4>{info?.name}</h4>
                <p>{info?.field_criteria}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};
