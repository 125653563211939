import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  ImageConstant,
  AppConstant,
  translate,
  useAppSelector,
  getProfilePicture,
  fetchAllMasterData,
  listGamificationPointDistribution,
  logEvent,
  CUSTOM_ANALYTICS_EVENT_NAMES,
  GAMIFICATION_TYPE_UNIQUE_CODE,
  GAMIFICATION_ENABLED,
  getLoggedInUserInfo,
  useAppDispatch,
  PlatformNames,
  getAnalyticsUserDetails,
  isEmptyCheck,
  differenceBetweenTimestamp,
} from '@shalina-app/shared';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from 'react-share';

import { UserProfilePropsType } from '.';
import styles from './userProfile.module.scss';
import { URLConstant } from '../../../../routes';
import {
  getImageSrc,
  removedFromStore,
  retrieveData,
  STORAGE_CONSTANTS,
  storeData,
  useNavigatePageUtils,
  userSignOut,
} from '../../../../utils';
import { CircularLoader, Toast } from '../../../../components';
import { PROFILE_PAGE_TABS } from '../../../../app.constants';
import { appInsights } from '../../../../applicationInsight';

export const UserProfile: React.FC<UserProfilePropsType> = ({
  isLoggedIn,
  handleSignUpFlow,
}) => {
  const currentUrl = window.location.origin;
  const t = translate();
  const dispatch = useAppDispatch();
  const navigatePageUtils = useNavigatePageUtils();
  const { urls } = URLConstant;
  const user = retrieveData(STORAGE_CONSTANTS.USER);

  const userDetails: any = useAppSelector(getLoggedInUserInfo);
  const gamificationPointsList = useAppSelector(
    listGamificationPointDistribution
  );

  // const linkToShare = `${t(AppConstant.PROFILE.SHARE_REFERRAL_CODE_LINK_MSG)} ${
  //   userDetails?.firstName
  // } ${userDetails?.lastName}.${t(
  //   AppConstant.PROFILE.SHARE_REFERRAL_CODE_LINK_TEXT_CONTINUE
  // )} ${currentUrl}/referral/${userDetails?.referralCode}`;

  const linkToShare = `${t(AppConstant.PROFILE.SHARE_REFERRAL_CODE_LINK_MSG)} ${
    userDetails?.firstName
  } ${userDetails?.lastName}.${t(
    AppConstant.PROFILE.SHARE_REFERRAL_CODE_LINK_TEXT_CONTINUE
  )} ${currentUrl}/share?referralCode=${userDetails?.referralCode}`;

  const {
    AccountIcon,
    BellOrangeIcon,
    SettingIcon,
    StarIcon,
    SignOutIcon,
    CopyClipboardIcon,
  } = ImageConstant.SVG_ICONS;
  const ref: any = useRef(null);
  const location = useLocation();

  const [isActive, setActive] = useState<boolean>(false);
  const [loader, setLoader] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastSubMessage, setToastSubMessage] = useState('');
  const [showShare, setShowShare] = useState(false);

  const ToggleClass = () => {
    setActive(!isActive);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref?.current && !ref?.current?.contains(event?.target)) {
        setActive(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const logUserActivity = async () => {
    if (isLoggedIn) {
      const date = new Date();
      const currentDateTime = date?.toISOString();
      const sessionLog = retrieveData(
        STORAGE_CONSTANTS.USER_SESSION_FIRST_TIME
      );
      if (isEmptyCheck(sessionLog)) {        
        storeData(STORAGE_CONSTANTS.USER_SESSION_FIRST_TIME, currentDateTime);
      }
      const sessionStart = sessionLog;
      const miliseconds = date?.getTime();
      const dt = new Date(miliseconds);
      const sessionEnd = dt?.toISOString();
      const averageTime = differenceBetweenTimestamp(sessionEnd, sessionStart);
      if (sessionStart && sessionEnd && averageTime && !['Invalid Date', NaN, null, 'null', '']?.includes(sessionStart)) {
        const userInfo: any = getAnalyticsUserDetails(userDetails);
        const sessionStartDate = new Date(sessionStart);
        const sessionEndDate = new Date(sessionEnd);
        logEvent(
          appInsights,
          'custom',
          CUSTOM_ANALYTICS_EVENT_NAMES.USER_SESSION,
          {
            userEmail: userInfo?.emailId,
            userMobile: userInfo?.mobile,
            userName: userInfo?.name,
            userQualification: userInfo?.qualification,
            userInterest: userInfo?.areaOfInterest,
            userSpecialization: userInfo?.specialization,
            userCountryOfPractice: userInfo?.countryOfPractice,
            userPreferredLanguage: userInfo?.preferredLanguage,
            platform: PlatformNames.WEB,
            sessionDateTime: sessionStartDate?.toLocaleString(),
            sessionEnd: sessionEndDate?.toLocaleString(),
            averageTime: averageTime,
          }
        );
        await removedFromStore(STORAGE_CONSTANTS.USER_SESSION_FIRST_TIME);
      }
    }
  };

  const handleSignOut = async () => {
    setLoader(true);
    await logUserActivity()
    await userSignOut();
    await dispatch(fetchAllMasterData());
    const token = JSON.parse(retrieveData(STORAGE_CONSTANTS.ACCESS_TOEKN));

    const navigateUrl = urls.homePage;
    navigatePageUtils(navigateUrl, {
      isDynamicUrl: false,
      stateParams: { reload: true },
      replace: true,
    });

    if (!token) {
      setLoader(false);
    }
  };

  /* edit profile modal start */
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  /* edit profile modal end */

  const handleToastClose = () => {
    setShowToast(false);
  };

  const handleShareClick = () => {
    setShowShare(false);
    setOpen(false);
  };

  const handleCopyLink = () => {
    const link = `${currentUrl}/share?referralCode=${userDetails?.referralCode}`;

    navigator.clipboard.writeText(link);
    setShowToast(true);
    setIsSuccess(true);
    setToastMessage(t(AppConstant.COMMON.LINK_COPY_MESSAGE));
  };

  const handleShareIconClick = () => {
    setShowShare((prev) => !prev);
    if (isLoggedIn) {
      const userObj = userDetails || user;
      const userInfo = getAnalyticsUserDetails(userObj);
      logEvent(
        appInsights,
        'custom',
        CUSTOM_ANALYTICS_EVENT_NAMES.USER_REFER_A_FRIEND,
        {
          userEmail: userInfo?.emailId,
          userMobile: userInfo?.mobile,
          userName: userInfo?.name,
          userQualification: userInfo?.qualification,
          userInterest: userInfo?.areaOfInterest,
          userSpecialization: userInfo?.specialization,
          userCountryOfPractice: userInfo?.countryOfPractice,
          userPreferredLanguage: userInfo?.preferredLanguage,
          platform: PlatformNames.WEB,
        }
      );
    }
  };

  const title = t(AppConstant.COMMON.SHARE_TITLE);
  const profileImage = useAppSelector(getProfilePicture);
  const isProfileActive =
    location?.pathname === urls.profilePage ? true : false;

  const referralPointData = gamificationPointsList?.filter(
    (item: any) =>
      item?.field_unique_code === GAMIFICATION_TYPE_UNIQUE_CODE.REFERRAL
  );
  const referralPoints = referralPointData?.[0]?.field_points || 0;

  return (
    <>
      <div
        className={`${styles.userSecWrapper} ${
          isProfileActive ? styles.active : ''
        }`}
      >
        <div className={`${styles.userSec}`} ref={ref} onClick={ToggleClass}>
          {loader && <CircularLoader size={50} thickness={4} />}
          {isLoggedIn ? (
            <>
              {profileImage ? (
                <img
                  src={`${getImageSrc(profileImage)}?timestamp=${
                    Math.random() * 1000
                  }`}
                  alt={'profile_picture'}
                  onError={(e) =>
                    ((e.target as HTMLInputElement).src =
                      ImageConstant.SOURCE.defaultUserPic)
                  }
                />
              ) : (
                <img
                  src={ImageConstant.SOURCE.defaultUserPic}
                  alt={'default_user_pic'}
                />
              )}
              <span className={`${styles.arrow} ${styles.userLogIn}`}>
                <svg
                  width='9'
                  height='5'
                  viewBox='0 0 9 5'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M1.63125 0.369141L4.5 3.23164L7.36875 0.369141L8.25 1.25039L4.5 5.00039L0.75 1.25039L1.63125 0.369141Z'
                    fill='black'
                  />
                </svg>
              </span>
              <div
                className={`${styles.userDropdown} ${
                  isActive ? styles.open : ''
                }`}
              >
                <ul>
                  <li key={1}>
                    <Link
                      to={urls.profilePage}
                      state={{ tab: PROFILE_PAGE_TABS.UPDATE_PROFILE }}
                    >
                      <span className={styles.icon}>
                        <AccountIcon />
                      </span>
                      {t(AppConstant.HEADER.MY_PROFILE)}
                    </Link>
                  </li>
                  <li key={2}>
                    <Link
                      to={urls.profilePage}
                      state={{ tab: PROFILE_PAGE_TABS.NOTIFICATION_SETTING }}
                    >
                      <span className={styles.icon}>
                        <BellOrangeIcon />
                      </span>
                      {t(AppConstant.PROFILE.NOTIFICATION_TAB_TEXT)}
                    </Link>
                  </li>
                  <li key={3}>
                    <Link
                      to={urls.profilePage}
                      state={{ tab: PROFILE_PAGE_TABS.ACCOUNT_SETTING }}
                    >
                      <span className={styles.icon}>
                        <SettingIcon />
                      </span>
                      {t(AppConstant.PROFILE.ACCOUNT_SETTINGS_TAB_TEXT)}
                    </Link>
                  </li>
                  <li key={4}>
                    <a href='javascript:void(0)' onClick={handleClickOpen}>
                      <span className={styles.icon}>
                        <StarIcon />
                      </span>
                      {t(AppConstant.HEADER.REFER_A_FRIEND)}
                    </a>
                  </li>
                  <li key={5}>
                    <div
                      onClick={()=>handleSignOut()}
                      className={styles.userDropdownItem}
                    >
                      <span className={styles.icon}>
                        <SignOutIcon />
                      </span>
                      {t(AppConstant.PROFILE.SIGN_OUT_TAB_TEXT)}
                    </div>
                  </li>
                </ul>
              </div>
            </>
          ) : (
            <div className={styles.guestUserIcon} onClick={handleSignUpFlow}>
              {/* <ProfileIcon /> */}
              <img
                src={ImageConstant.SOURCE.logoutUserImg}
                alt={'default_user_pic'}
              />
            </div>
          )}
        </div>
      </div>

      <Dialog open={open} onClose={handleClose} className={styles.referModal}>
        <DialogContent>
          <div className={styles.title}>
            {t(AppConstant.PROFILE.REFER_DOCTOR)}
            <span onClick={handleClose}>&times;</span>
          </div>
          <div className={styles.referText}>
            {GAMIFICATION_ENABLED ? (
              <>
                {t(AppConstant.PROFILE.REFERAL_MSG)}
                <strong>{`${referralPoints} ${t(
                  AppConstant.COMMON.EXTRA_POINTS_TEXT
                )}`}</strong>
              </>
            ) : (
              t(AppConstant.PROFILE.REFERAL_MSG_WITHOUT_GAMIFICATION)
            )}
          </div>
          <button onClick={handleShareIconClick}>
            {t(AppConstant.PROFILE.SHARE_LINK)}
          </button>
          {showShare && (
            <div className={styles.shareWrap}>
              <div className={styles.shareListIcon}>
                <span className={styles.copyIcon}>
                  <CopyClipboardIcon onClick={handleCopyLink} />
                </span>
              </div>
              <div className={styles.shareListIcon}>
                <FacebookShareButton
                  url={linkToShare}
                  className='Demo__some-network__share-button'
                  onClick={handleShareClick}
                >
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
              </div>
              <div className={styles.shareListIcon}>
                <TwitterShareButton
                  url={linkToShare}
                  className='Demo__some-network__share-button'
                  onClick={handleShareClick}
                >
                  <XIcon size={32} round />
                </TwitterShareButton>
              </div>
              <div className={styles.shareListIcon}>
                <WhatsappShareButton
                  url={linkToShare}
                  separator=''
                  className='Demo__some-network__share-button'
                  onClick={handleShareClick}
                >
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
              </div>
              <div className={styles.shareListIcon}>
                <LinkedinShareButton
                  url={linkToShare}
                  className='Demo__some-network__share-button'
                  onClick={handleShareClick}
                >
                  <LinkedinIcon size={32} round />
                </LinkedinShareButton>
              </div>
              <div className={styles.shareListIcon}>
                <EmailShareButton
                  url={linkToShare}
                  subject={title}
                  className='Demo__some-network__share-button'
                  onClick={(_, link) => {
                    window.location.href = link; // does the same as the default onClick
                    handleShareClick(); // your function
                  }}
                >
                  <EmailIcon size={32} round />
                </EmailShareButton>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
      <Toast
        showToast={showToast}
        isSuccess={isSuccess}
        message={toastMessage}
        subMessage={toastSubMessage}
        handleToastClose={handleToastClose}
      />
    </>
  );
};
