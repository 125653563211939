"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COMMUNITY_SORT_SCREENS = exports.getFileNameFromUrl = exports.formatBytes = exports.isBeforeCurrentDateTime = exports.COMMUNITY_REQUEST_STATUS = exports.SHOW_MY_COMMUNITIES_COUNT = exports.SHOW_RECOMMENDED_COUNT = exports.SHOW_COMMENTS_INITIAL_COUNT = exports.DISCUSSION_CATEGORY = exports.getCommentStatus = exports.QuestionStatusEnum = exports.QUESTION_STATUS = exports.getPostedBy = exports.COMMUNITY_COMPONENT_RENDER_ENUM = exports.getCommunityDetailTabs = exports.COMMUNITY_DETAIL_TABS_ENUM = exports.COMMUNITY_DETAIL_TABS = exports.getCommunitiesTabs = exports.COMMUNITIES_TABS_ENUM = exports.COMMUNITIES_TABS = void 0;
exports.COMMUNITIES_TABS = {
    DISCUSSIONS: 0,
    EXPLORE_COMMUNITIES: 1,
    REQUESTS: 2,
    ANSWER_QUESTIONS: 3
};
exports.COMMUNITIES_TABS_ENUM = {
    DISCUSSIONS: 'discussions',
    EXPLORE_COMMUNITIES: 'explore_community',
    REQUESTS: 'requests',
    ANSWER_QUESTIONS: 'answer_questions'
};
const getCommunitiesTabs = (isKol) => {
    if (isKol) {
        return [exports.COMMUNITIES_TABS_ENUM.DISCUSSIONS, exports.COMMUNITIES_TABS_ENUM.EXPLORE_COMMUNITIES, exports.COMMUNITIES_TABS_ENUM.REQUESTS, exports.COMMUNITIES_TABS_ENUM.ANSWER_QUESTIONS];
    }
    else {
        return [exports.COMMUNITIES_TABS_ENUM.DISCUSSIONS, exports.COMMUNITIES_TABS_ENUM.EXPLORE_COMMUNITIES, exports.COMMUNITIES_TABS_ENUM.REQUESTS];
    }
};
exports.getCommunitiesTabs = getCommunitiesTabs;
exports.COMMUNITY_DETAIL_TABS = {
    DISCUSSIONS: 0,
    ABOUT: 1,
    MEMBERS: 2,
    RESOURCES: 3
};
exports.COMMUNITY_DETAIL_TABS_ENUM = {
    DISCUSSIONS: "discussions",
    ABOUT: "about",
    MEMBERS: "members",
    RESOURCES: 'resources'
};
const getCommunityDetailTabs = (isLoggedIn, isJoined) => {
    if (isLoggedIn && isJoined) {
        return [exports.COMMUNITY_DETAIL_TABS_ENUM.DISCUSSIONS, exports.COMMUNITY_DETAIL_TABS_ENUM.ABOUT, exports.COMMUNITY_DETAIL_TABS_ENUM.MEMBERS, exports.COMMUNITY_DETAIL_TABS_ENUM.RESOURCES];
    }
    else {
        return [exports.COMMUNITY_DETAIL_TABS_ENUM.ABOUT, exports.COMMUNITY_DETAIL_TABS_ENUM.MEMBERS];
    }
};
exports.getCommunityDetailTabs = getCommunityDetailTabs;
exports.COMMUNITY_COMPONENT_RENDER_ENUM = {
    TABS_COMPONENT: "tabsComponent",
    COMMUNITY_DETAIL_COMPONENT: "communityDetailComponent",
    COMMUNITY_LISTING_COMPONENT: "communityListingComponent"
};
const getPostedBy = (date) => {
    // Provide the specific date in ISO 8601 format
    const specificDate = new Date(date);
    // Get the current date
    const currentDate = new Date();
    // Calculate the time difference in milliseconds
    const timeDifference = currentDate - specificDate;
    // Calculate the time difference in hours, minutes, and seconds
    const secondsDifference = Math.floor(timeDifference / 1000);
    const minutesDifference = Math.floor(secondsDifference / 60);
    const hoursDifference = Math.floor(minutesDifference / 60);
    const daysDifference = Math.floor(hoursDifference / 24);
    // Determine the format based on the time difference
    let relativeTime;
    if (daysDifference >= 1) {
        relativeTime = daysDifference + "d ago";
    }
    else if (hoursDifference >= 1) {
        relativeTime = hoursDifference + "h ago";
    }
    else if (minutesDifference >= 1) {
        relativeTime = minutesDifference + "m ago";
    }
    else {
        relativeTime = secondsDifference < 0 ? 0 + "s ago" : secondsDifference + "s ago";
    }
    return relativeTime;
};
exports.getPostedBy = getPostedBy;
exports.QUESTION_STATUS = {
    APPROVED: "10001",
    PENDING: "10002",
    REJECTED: "10003"
};
exports.QuestionStatusEnum = {
    APPROVED: "approved",
    PENDING: "rejected",
    REJECTED: "pending"
};
const getCommentStatus = (uniqueId) => {
    if (uniqueId === (exports.QUESTION_STATUS === null || exports.QUESTION_STATUS === void 0 ? void 0 : exports.QUESTION_STATUS.APPROVED)) {
        return exports.QuestionStatusEnum === null || exports.QuestionStatusEnum === void 0 ? void 0 : exports.QuestionStatusEnum.APPROVED;
    }
    else if (uniqueId === (exports.QUESTION_STATUS === null || exports.QUESTION_STATUS === void 0 ? void 0 : exports.QUESTION_STATUS.REJECTED)) {
        return exports.QuestionStatusEnum === null || exports.QuestionStatusEnum === void 0 ? void 0 : exports.QuestionStatusEnum.REJECTED;
    }
    else {
        return exports.QuestionStatusEnum === null || exports.QuestionStatusEnum === void 0 ? void 0 : exports.QuestionStatusEnum.PENDING;
    }
};
exports.getCommentStatus = getCommentStatus;
exports.DISCUSSION_CATEGORY = {
    TOPIC: "topic",
    QA: "q_a"
};
exports.SHOW_COMMENTS_INITIAL_COUNT = 2;
exports.SHOW_RECOMMENDED_COUNT = 1;
exports.SHOW_MY_COMMUNITIES_COUNT = 4;
exports.COMMUNITY_REQUEST_STATUS = {
    JOIN: 'join',
    ACCEPT: 'accepted',
    REJECT: 'rejected',
    REVOKE: 'revoked'
};
const isBeforeCurrentDateTime = (givenDateTime) => {
    // Parse the given date string into a Date object
    const givenDate = new Date(givenDateTime);
    // Get the current date
    const currentDate = new Date();
    // Compare the given date with the current date
    if (givenDate < currentDate) {
        return true; // Given date is backdated
    }
    else {
        return false; // Given date is not backdated
    }
};
exports.isBeforeCurrentDateTime = isBeforeCurrentDateTime;
const formatBytes = (byteStr) => {
    const bytes = parseInt(byteStr);
    if (bytes === 0)
        return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    if (i === 0) {
        return (bytes / k).toFixed(1) + ' KB';
    }
    else {
        return parseFloat((bytes / Math.pow(k, i)).toFixed(1)) + ' ' + sizes[i];
    }
};
exports.formatBytes = formatBytes;
const getFileNameFromUrl = (url) => {
    var _a;
    const parts = url === null || url === void 0 ? void 0 : url.split("/");
    const lastPart = parts[(parts === null || parts === void 0 ? void 0 : parts.length) - 1];
    //to remove query params
    const fileName = (_a = lastPart === null || lastPart === void 0 ? void 0 : lastPart.split("?")[0]) === null || _a === void 0 ? void 0 : _a.split('.')[0];
    const decodeName = fileName ? decodeURIComponent(fileName) : '';
    return decodeName;
};
exports.getFileNameFromUrl = getFileNameFromUrl;
exports.COMMUNITY_SORT_SCREENS = {
    DISCUSSIONS: 'list_discussion',
    EXPLORE: 'explore_community',
    RECOMMENDED: 'recommended_community',
    MY_COMMUNITY: 'my_community',
    REQUEST: 'request_community',
    ANSWER_DISCUSSIONS: 'answer_questions_list'
};
