import React from 'react';
import { SpecialityProps, specialityType } from '.';
import styles from './speciality.module.scss';

export const Speciality: React.FC<SpecialityProps> = ({
  speciality,
  showAll
}) => {
  const displayedSpecialities = showAll ? speciality : speciality?.slice(0, 2);

  return (
    <div className={`${styles.specialityWrapper} ${'globalspecialityWrapper'}`}>
      {displayedSpecialities?.map((item, index: number) => (
        <div
          key={index}
          className={`${styles.specialityItem} ${
            (showAll || displayedSpecialities?.length === 1) && styles.fullWidth
          }`}
        >
          <span>{item?.specialityText}</span>
        </div>
      ))}

      {speciality?.length > 2 && !showAll && (
        <>
          <div className={`${styles.specialityItem} ${styles.more}`}>
            <span>{`+${speciality?.length - 2}`}</span>
            <div className={styles.tooltipWrapper}>
              <div className={`${styles.tooltip}`}>
                {speciality
                  ?.slice(2)
                  .map((item: specialityType, index: number) => (
                    <span className={`${styles.tooltiptext}`} key={index}>
                      {item?.specialityText}
                    </span>
                  ))}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
