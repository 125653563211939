import React, { useEffect, useState } from 'react';

import { ChipListProps, ChipProps } from './chip.interface';
import styles from './chip.module.scss';

const Chip = ({ option, selected, onPress }: ChipProps) => {
  return (
    <label className={styles.interestCheckbox}>
      <input
        type='checkbox'
        onClick={onPress}
        checked={selected ? true : false}
      />
      <span>{option?.label}</span>
    </label>
  );
};

export const ChipList: React.FC<ChipListProps> = (props) => {
  const { options, onSelectOption, selectedOptionsList } = props;
  const [selectedOptions, setSelectedOptions] = useState<number[]>([]);

  useEffect(() => {
    if (selectedOptionsList) {
      setSelectedOptions(selectedOptionsList);
    }
  }, [selectedOptionsList]);

  const toggleOption = (optionId: any) => {
    if (selectedOptions.includes(optionId)) {
      const optionSelected = selectedOptions.filter((id) => id !== optionId);
      setSelectedOptions(optionSelected);
      onSelectOption(optionSelected);
    } else {
      const optionSelected = [...selectedOptions, optionId];
      setSelectedOptions(optionSelected);
      onSelectOption(optionSelected);
    }
  };

  return (
    <div className={styles.interestOptions}>
      {options?.map((option: any) => {
        return (
          <Chip
            key={option.id}
            option={option}
            selected={selectedOptions.includes(option.id)}
            onPress={() => toggleOption(option.id)}
          />
        );
      })}
    </div>
  );
};
