import React, { useEffect, useState } from 'react';
import {
  AppConstant,
  fetchMedicalCalculator,
  getMedicalCalculator,
  ImageConstant,
  translate,
  useAppDispatch,
  useAppSelector
} from '@shalina-app/shared';
import { useLocation } from 'react-router-dom';

import { CalculatorProps } from '.';
import styles from './calculator.module.scss';
import {
  BarLoader,
  CustomTabs,
  QuickLinkSection
} from '../../../../components';
import { CriticalCriteria, DecisionTrees, MedicalEquation } from './components';
import { MEDICAL_CALCULATOR_TABS } from '../../../../app.constants';
import { retrieveData, STORAGE_CONSTANTS } from '../../../../utils';
import { SignUpPopup } from '../../../Authentication';

export const Calculator: React.FC<CalculatorProps> = () => {
  const t = translate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const tab = location?.state?.tab;

  const isLoggedIn: boolean = retrieveData(STORAGE_CONSTANTS.IS_LOGGED_IN);

  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const [openCalcDetail, setopenCalcDetail] = useState(false);
  const [currentActiveItem, setCurrentActiveItem] = useState('');
  const [apiCall, setApiCall] = useState(false);
  const [selectedInterest, setSelectedInterest] = useState<Array<string>>([]);
  const [criticalCriteria, setCriticalCriteria] = useState<any>([]);
  const [decisionTrees, setDecisionTrees] = useState<any>([]);
  const [medicalEquation, setMedicalEquation] = useState<any>([]);
  const [dataFromStore, setDataFromStore] = useState(true);
  const [showSignUp, setShowSignUp] = useState(false);

  const content = useAppSelector(getMedicalCalculator);

  useEffect(() => {
    if (tab) {
      window.scrollTo(0, 0);
      setTabValue(tab);
    }
  }, [tab]);

  useEffect(() => {
    if (apiCall) {
      loadApi();
      setApiCall(false);
    }
  }, [apiCall]);

  useEffect(() => {
    if (content) {
      for (const key in content) {
        if (key === 'Clinical Criteria') {
          setCriticalCriteria(content?.[key]);
        } else if (key === 'Medical Equations') {
          setMedicalEquation(content?.[key]);
        } else if (key === 'Decision Trees') {
          setDecisionTrees(content?.[key]);
        } else {
          setCriticalCriteria([]);
          setMedicalEquation([]);
          setDecisionTrees([]);
        }
      }
      setDataFromStore(false);
    }
  }, [content]);

  const loadApi = async () => {
    const payload = {
      interest: selectedInterest?.toString()
    };

    const response = await dispatch(fetchMedicalCalculator(payload)).unwrap();
    setDataFromStore(false);
    if (
      response?.status === 'SUCCESS' &&
      response?.statusCode === 200 &&
      response?.data
    ) {
      if (response?.data?.length === 0) {
        setCriticalCriteria([]);
        setMedicalEquation([]);
        setDecisionTrees([]);
      } else {
        setData(response);
      }
    }
  };

  const setData = (response: any) => {
    for (const key in response?.data) {
      if (key === 'Clinical Criteria') {
        setCriticalCriteria(response?.data?.[key]);
      } else if (key === 'Medical Equations') {
        setMedicalEquation(response?.data?.[key]);
      } else if (key === 'Decision Trees') {
        setDecisionTrees(response?.data?.[key]);
      } else {
        setCriticalCriteria([]);
        setMedicalEquation([]);
        setDecisionTrees([]);
      }
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, value: number) => {
    setTabValue(value);
    setCurrentActiveItem('');
  };

  const handleArrowClick = (url: string, name: string = '') => {
    if (isLoggedIn) {
      setUrl(url);
      setCurrentActiveItem(name);
      setOpen(true);
      // window.open(url, "_self");
      setopenCalcDetail(true);
    } else {
      setShowSignUp(true);
      setopenCalcDetail(false);
    }
  };

  const handleSignUpClose = () => {
    setShowSignUp(false);
  };

  const handleClose = () => {
    setOpen(false);
    setopenCalcDetail(false);
    setCurrentActiveItem('');
  };

  const tabs = [
    {
      id: MEDICAL_CALCULATOR_TABS.CLINICAL_CRITERIA,
      label: t(AppConstant.COMMON.CLINICAL_CRITERIA_TAB),
      component: (
        <CriticalCriteria
          handleArrowClick={handleArrowClick}
          data={criticalCriteria}
        />
      )
    },
    {
      id: MEDICAL_CALCULATOR_TABS.DECISION_TREES,
      label: t(AppConstant.COMMON.DECISION_TREES_TAB),
      component: (
        <DecisionTrees
          handleArrowClick={handleArrowClick}
          data={decisionTrees}
        />
      )
    },
    {
      id: MEDICAL_CALCULATOR_TABS.MEDICAL_EQUATIONS,
      label: t(AppConstant.COMMON.MEDICAL_EQUATIONS_TAB),
      component: (
        <MedicalEquation
          handleArrowClick={handleArrowClick}
          data={medicalEquation}
        />
      )
    }
  ];

  return (
    <>
      <div
        className={`${styles.calcTop} ${
          currentActiveItem && styles.calcDetail
        }`}
      >
        <div className='cust-container'>
          {/* <BreadCrumb hasDark={true} /> */}
          <nav aria-label='breadcrumb' className={styles.breadWrapper}>
            <ol
              className={`${styles.breadcrumb} ${
                !currentActiveItem && styles.hasDarkBg
              }`}
            >
              <li>
                <a
                  style={{ cursor: 'pointer', borderBottom: '1px' }}
                  onClick={handleClose}
                >
                  {tabs[tabValue]?.label}
                </a>
              </li>
              {currentActiveItem && (
                <li>
                  <span className={styles.bredArrow}>
                    <svg
                      width='7'
                      height='11'
                      viewBox='0 0 7 11'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M1 1L6 5.5L0.999999 10'
                        stroke='#002E5B'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                  </span>
                  <span className={styles.breadcrumbCurrent}>
                    {currentActiveItem}
                  </span>
                </li>
              )}
            </ol>
          </nav>
          {!currentActiveItem && (
            <h1 className={styles.calcHead}>
              {t(AppConstant.HEADER.MEDICAL_CALCULATOR_MENU)}
            </h1>
          )}

          {/* <ul className={styles.calcTag}>
            {areaOfInterestList?.map((interest: AreaOfIntestListProps) => (
              <li>
                <label>
                  <input
                    type="checkbox"
                    id={interest?.id}
                    onChange={() => onAreaOfInterest(interest?.id)}
                    checked={selectedInterest?.includes(interest?.id)}
                  />
                  <span>{interest?.label}</span>
                </label>
              </li>
            ))}
          </ul> */}
        </div>
      </div>
      {dataFromStore && <BarLoader />}
      <div className='cust-container'>
        <div className={styles.calcRow}>
          <div className={styles.calcCol8}>
            {/* {loading ? (
              <>
                <div className={styles.loadingHeight}></div>
                <CircularLoader size={50} thickness={4} />
              </>
            ) :  */}
            {dataFromStore ? (
              <></>
            ) : criticalCriteria?.length === 0 &&
              decisionTrees?.length === 0 &&
              medicalEquation?.length === 0 &&
              !dataFromStore ? (
              <div className={styles.contentNotFound}>
                <div className={styles.notFoundImg}>
                  <img
                    src={ImageConstant.SOURCE.contentNotFoundImg}
                    alt='not found'
                  />
                </div>
                <div className={styles.notFoundText}>
                  <h3>{`${t(AppConstant.HOME_PAGE.NO_TEXT)} ${t(
                    AppConstant.HOME_PAGE.DATA_TEXT
                  )} ${t(AppConstant.HOME_PAGE.FOUND_TEXT)}`}</h3>
                  <p>{t(AppConstant.HOME_PAGE.NO_DATA_FOUND_MESSAGE)}</p>
                </div>
              </div>
            ) : (
              <div className={styles.calcPageWrapper}>
                {!openCalcDetail ? (
                  <div className={styles.shalinaTabs}>
                    <CustomTabs
                      tabs={tabs}
                      tabValue={tabValue}
                      handleTabChange={handleTabChange}
                    />
                  </div>
                ) : (
                  <div className='testDic'>
                    <object data={url} className={styles.iframe}></object>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className={styles.calcCol4}>
            <div className={styles.calcQuicklink}>
              <QuickLinkSection enumIds={['bookmark', 'aboutUs']} />
            </div>
          </div>
        </div>
      </div>

      {/* <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        className={styles.ebescoModal}
      >
        <DialogContent>
          <div>
            <div className={styles.closeDiv}>
              <button className={styles.backBtn} onClick={handleClose}>
                <DropDownArrowIcon />
                {t(AppConstant.COMMON.BACK_CTA)}
              </button>
              //<span onClick={handleClose}>&times;</span>
            </div>
            <object data={url} className={styles.iframe}></object>
            // <iframe src={url} className={styles.iframe}></iframe>
          </div>
        </DialogContent>
      </Dialog> */}

      <SignUpPopup open={showSignUp} handleClose={handleSignUpClose} />
    </>
  );
};
