import {
  AppConstant,
  getContactModes,
  getLoggedInUserInfo,
  getMaxValueToRedeem,
  getMilestoneData,
  getRedemptionConfig,
  ImageConstant,
  RESEND_TIMER,
  submitRedeemPoints,
  translate,
  useAppDispatch,
  useAppSelector,
  getContactModeLabelById,
  OTP_VERIFICATION_MEDIUM,
} from '@shalina-app/shared';
import { useEffect, useState } from 'react';

import { MilestoneSectionProps } from '.';
import {
  getImageSrc,
  getTranslationFormat,
  otpValidation,
  radioGroupValidation,
  STORAGE_CONSTANTS,
  storeData,
} from '../../../../utils';
import styles from './milestoneSection.module.scss';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Box, FormControl, Slider } from '@mui/material';
import {
  ButtonComponent,
  HyperLink,
  Input,
  RadioGroupComponent,
  Toast,
} from '../../../../components';
import { isError } from '@nevware21/ts-utils';

export const MilestoneSection: React.FC<MilestoneSectionProps> = ({
  earnedPoints,
  achieveMilestone,
  milestoneToReach,
  handleDicoverClick,
  isLastMileStone,
  userCallback,
}) => {
  const { RightArrowIcon, ClockIcon } = ImageConstant.SVG_ICONS;
  const t = translate();
  const dispatch = useAppDispatch();

  const redemptionConfig = useAppSelector(getRedemptionConfig);

  const redemptionRate = parseInt(
    redemptionConfig?.find(
      (item: { key: string; value: string }) => item?.key === 'REDEMPTION_RATE'
    )?.value
  );
  const minPointForRedemption = parseInt(
    redemptionConfig?.find(
      (item: { key: string; value: string }) =>
        item?.key === 'MINIMUM_POINT_FOR_REDEMPTION'
    )?.value
  );

  const userInfo = useAppSelector(getLoggedInUserInfo);
  const milestoneData = useAppSelector(getMilestoneData);
  const contactModes = useAppSelector(getContactModes);

  const [achievedMileStone, setAchievedMileStone] = useState<any>();
  const [nextMileStone, setNextMileStone] = useState<any>();
  const [percentage, setPercentage] = useState(0);
  const [redemptionPopup, setRedemptionPopup] = useState(false);
  const [rangeValue, setRangeValue] = useState(0);
  const [verificationOn, setVerificationOn] = useState(false);
  const [redeemClickLoader, setRedeemClickLoader] = useState(false);
  const [otpValue, setOtpValue] = useState('');
  const [nextCtaEnable, setNextCtaEnable] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [timer, setTimer] = useState(0);
  const [resendEnable, setResendEnable] = useState(false);
  const [verifyLoader, setVerifyLoader] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [selectedVerificationMedium, setSelectedVerificationMedium] =
    useState('');

  const handleRedemption = () => {
    setRedemptionPopup(true);
    const whatsAppDefault = contactModes?.find(
      (mode: { label: string; value: string }) =>
        mode?.label?.includes('Whatsapp')
    );
    setSelectedVerificationMedium(whatsAppDefault?.value);
  };
  
  useEffect(() => {
    if (minPointForRedemption) {
      setRangeValue(minPointForRedemption);
    }
  }, [minPointForRedemption]);

  useEffect(() => {
    let interval: any;
    
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
    } else {
      setResendEnable(true);
      // setResendClick(false);
    }

    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    if (otpValue) {
      if (!otpValidation(otpValue)) {
        setIsError(false);
        setErrorMessage('');
        setNextCtaEnable(true);
      } else {
        setIsError(true);
        setErrorMessage(t(AppConstant.COMMON.ERROR_MESSAGE_VALID_OTP));
        setNextCtaEnable(false);
      }
    }
  }, [otpValue]);

  const handleRedemptionClose = () => {
    setRedemptionPopup(false);
    setVerificationOn(false);
    setVerifyLoader(false);
    setOtpValue('');
    setRangeValue(minPointForRedemption);
  };

  useEffect(() => {
    if (milestoneData) {
      if (isLastMileStone) {
        const lastElement = milestoneData?.slice(-1)?.[0];
        const achieveElement = milestoneData?.slice(-2)?.[0];

        setNextMileStone(lastElement);
        setAchievedMileStone(achieveElement);
        setPercentage(100);
      } else {
        const prev = milestoneData?.filter(
          (item: any) => item?.tid === achieveMilestone?.tid
        )?.[0];
        const next = milestoneData?.filter(
          (item: any) => item?.tid === milestoneToReach?.[0]?.tid
        )?.[0];

        const lastAchivedPoints = achieveMilestone?.points;
        const percentage =
          ((earnedPoints - parseInt(lastAchivedPoints)) /
            (milestoneToReach?.[0]?.points - lastAchivedPoints)) *
          100;

        setNextMileStone(next);
        setAchievedMileStone(prev);
        setPercentage(percentage);
      }
    }
  }, [milestoneData, earnedPoints, isLastMileStone]);

  const maxRedeemValue = getMaxValueToRedeem(earnedPoints, redemptionRate);
  // const maxRedeemValue = 20000;
  const minRedemption = minPointForRedemption;
  const marks = [
    {
      value: minRedemption,
      label: minRedemption,
    },
    {
      value: maxRedeemValue,
      label: maxRedeemValue,
    },
  ];
  const onResendOTP = async () => {
    setOtpValue('');
    setIsError(false);
    setErrorMessage('');
    const req = {
      points: rangeValue,
      countryCode: userInfo?.countryCode,
      mobileNumber: userInfo?.mobileNumber,
      emailId: userInfo?.emailId,
      mode: selectedModeLabel?.toLowerCase() === 'email' ? OTP_VERIFICATION_MEDIUM.EMAIL_VERIFICATION_MEDIUM : OTP_VERIFICATION_MEDIUM.WHATSAPP_VERIFICATION_MEDIUM,
    };
    const response = await dispatch(submitRedeemPoints(req)).unwrap();
    if (
      response &&
      response?.status === 'SUCCESS' &&
      response.statusCode === 200
    ) {
      setTimer(RESEND_TIMER);
      setResendEnable(false);
      setNextCtaEnable(false);
      setShowToast(true);
      setIsSuccess(true);
      setToastMessage(t(response?.message));
    } else {
      setTimer(0);
      setResendEnable(true);
      setNextCtaEnable(false);
      setShowToast(true);
      setIsSuccess(false);
      setToastMessage(t(response?.message));
    }
  };

  useEffect(() => {
    if (contactModes && !selectedVerificationMedium) {
      const whatsAppDefault = contactModes?.find(
        (mode: { label: string; value: string }) =>
          mode?.label?.includes('Whatsapp')
      );
      setSelectedVerificationMedium(whatsAppDefault?.value);
    }
  }, [contactModes]);

  const handleRedeem = async () => {
    setRedeemClickLoader(true);
    const req = {
      points: rangeValue,
      countryCode: userInfo?.countryCode,
      mobileNumber: userInfo?.mobileNumber,
      emailId: userInfo?.emailId,
      mode: selectedModeLabel?.toLowerCase() === 'email' ? OTP_VERIFICATION_MEDIUM.EMAIL_VERIFICATION_MEDIUM : OTP_VERIFICATION_MEDIUM.WHATSAPP_VERIFICATION_MEDIUM,
    };
    const response = await dispatch(submitRedeemPoints(req)).unwrap();

    if (
      response &&
      response?.status === 'SUCCESS' &&
      response.statusCode === 200
    ) {
      setVerificationOn(true);
      setRedeemClickLoader(false);
      setShowToast(true);
      setIsSuccess(true);
      setToastMessage(t(response.message));
      setTimer(RESEND_TIMER);
      setResendEnable(false);
    } else {
      setRedeemClickLoader(false);
      setShowToast(true);
      setIsSuccess(false);
      setToastMessage(t(response.message));
      setTimer(0);
      setResendEnable(true);
    }
  };

  const handleVerificationMediumChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const verificationMedium = (event.target as HTMLInputElement).value;
    setSelectedVerificationMedium(verificationMedium);
  };

  const selectedModeLabel = useAppSelector(
    getContactModeLabelById(selectedVerificationMedium)
  );  

  const handleVerify = async () => {
    setVerifyLoader(true);
    const req = {
      points: rangeValue,
      countryCode: userInfo?.countryCode,
      mobileNumber: userInfo?.mobileNumber,
      emailId: userInfo?.emailId,
      mode: selectedModeLabel?.toLowerCase() === 'email' ? OTP_VERIFICATION_MEDIUM.EMAIL_VERIFICATION_MEDIUM : OTP_VERIFICATION_MEDIUM.WHATSAPP_VERIFICATION_MEDIUM,
      otp: otpValue,
    };
    const response = await dispatch(submitRedeemPoints(req)).unwrap();

    if (
      response &&
      response?.status === 'SUCCESS' &&
      response.statusCode === 200
    ) {
      setVerifyLoader(false);
      setShowToast(true);
      setIsSuccess(true);
      setToastMessage(t(response.message));
      handleRedemptionClose();
      userCallback();
    } else {
      setVerifyLoader(false);
      setShowToast(true);
      setIsSuccess(false);
      setToastMessage(t(response.message));
    }
  };

  const handleToastClose = () => {
    setShowToast(false);
  };
  
  return (
    <div className={styles.profilePoints}>
      <div className={styles.pointWrapper}>
        <div className={styles.pointProcessWrap}>
          <img
            src={getImageSrc(achievedMileStone?.field_icon)}
            className={`${styles.pointImg} ${styles.active}`}
            alt='point img'
          />
          <img
            src={getImageSrc(nextMileStone?.field_icon)}
            className={`${styles.pointImg} ${
              isLastMileStone ? styles.active : ''
            }`}
            alt='point img'
          />
          <div className={styles.pointProgressBar}>
            <span
              className={styles.pointProgress}
              style={{ width: `${percentage}%` }}
            ></span>
          </div>
        </div>
        <div className={styles.totalLabel}>
          <div>
            {getTranslationFormat(AppConstant.PROFILE.TOTAL_EARNED_TEXT)}{' '}
            <span>
              {earnedPoints} {getTranslationFormat(AppConstant.COMMON.POINTS)}
            </span>
            {/* <span className={styles.redemptionLabel}><ClockIcon />Redemption Initiated</span> */}
            <button
              onClick={handleRedemption}
              disabled={earnedPoints >= minPointForRedemption ? false : true}
            >
              {t(AppConstant.COMMON.REDEEM_POINTS_CTA)}
            </button>
          </div>
          <div>
            {nextMileStone?.name}{' '}
            <span>
              {nextMileStone?.points}{' '}
              {getTranslationFormat(AppConstant.COMMON.POINTS)}
            </span>
          </div>
        </div>
        <Dialog
          open={redemptionPopup}
          onClose={handleRedemptionClose}
          className={styles.redemptionModal}
        >
          <DialogContent>
            <div className={styles.title}>
              <h4>{t(AppConstant.COMMON.REDEEM_POINTS_CTA)}</h4>
              <span onClick={handleRedemptionClose}>&times;</span>
            </div>
            {verificationOn ? (
              <div className={styles.enterOtp}>
                <div className={styles.enterEmail}>
                  {t(
                    AppConstant.AUTHENTICATION_PAGE
                      .VERIFICATION_CODE_SENT_TO_TEXT
                  )}{' '}
                  <span>{selectedModeLabel?.toLowerCase() === 'email' ? userInfo?.emailId : `${userInfo?.countryCode} ${userInfo?.mobileNumber}`}</span>
                </div>
                <div className={styles.verificationInput}>
                  <Input
                    placeHolderText={t(
                      AppConstant.PROFILE.ENTER_VERIFICATION_CODE
                    )}
                    formControlVariant={'standard'}
                    inputId={'verificationCode'}
                    inputVariant={'outlined'}
                    isError={isError}
                    //handleOnBlur={validateOnBlur}
                    errorMessage={errorMessage}
                    value={otpValue}
                    handleChange={(e: any) => setOtpValue(e.target.value)}
                    maxLength={6}
                  />
                  <div className={styles.timeLink}>
                    {!resendEnable ? (
                      <div>
                        {t(AppConstant.AUTHENTICATION_PAGE.TIME_REMAINING_TEXT)}{' '}
                        <span>{timer}</span>{' '}
                        {t(AppConstant.COMMON.SECONDS_TEXT)}
                      </div>
                    ) : (
                      <span></span>
                    )}
                    <HyperLink
                      handleClick={onResendOTP}
                      hyperText={t(
                        AppConstant.AUTHENTICATION_PAGE.RESEND_OTP_TEXT
                      )}
                      disabled={!resendEnable}
                    />
                  </div>
                </div>
                <div className={styles.verificationCta}>
                  <ButtonComponent
                    variant='outlined'
                    buttonText={t(AppConstant.PROFILE.VERIFY_CTA)}
                    onPress={handleVerify}
                    disabled={!nextCtaEnable}
                    loader={verifyLoader}
                  />
                </div>
              </div>
            ) : (
              <div className={styles.selectPoint}>
                <div className={styles.points}>
                  {t(AppConstant.COMMON.YOUR_CURRENT_POINTS)}{': '}
                  <strong>{earnedPoints}</strong>
                </div>
                <div className={styles.rangeWrapper}>
                  <div className={styles.rangeWrap}>
                    <FormControl>
                      <Box sx={{ width: 300 }}>
                        <Slider
                          value={rangeValue}
                          aria-label='default'
                          valueLabelDisplay='auto'
                          step={redemptionRate}
                          min={minRedemption}
                          max={maxRedeemValue}
                          defaultValue={minRedemption}
                          marks={marks}
                          onChange={(_: Event, newValue: number | number[]) => {
                            setRangeValue(newValue as number);
                          }}
                        />
                      </Box>
                    </FormControl>
                  </div>
                  <div className={styles.signInFormCol}>
                    <RadioGroupComponent
                      formControlVariant={'standard'}
                      labelText={t(
                        AppConstant.AUTHENTICATION_PAGE
                          .GET_VERIFICATION_CODE_ON_TEXT
                      )}
                      inputId={'verificationCode'}
                      isRequired={true}
                      radioGroupObj={contactModes}
                      selectedValue={selectedVerificationMedium}
                      handleRadioChange={handleVerificationMediumChange}
                      isError={radioGroupValidation(
                        selectedModeLabel?.toLowerCase()
                      )}
                      errorMessage={t(AppConstant.ERROR.ENTER_VALID_MODE)}
                    />
                  </div>
                  <div className={styles.redeemCta}>
                    <ButtonComponent
                      variant='outlined'
                      buttonText={`${t(
                        AppConstant.COMMON.REDEEM_CTA
                      )}: ${rangeValue}`}
                      onPress={handleRedeem}
                      disabled={(rangeValue > 0 && selectedVerificationMedium) ? false : true}
                      loader={redeemClickLoader}
                    />
                  </div>
                </div>
              </div>
            )}
          </DialogContent>
        </Dialog>
        <div className={styles.discoverText} onClick={handleDicoverClick}>
          {getTranslationFormat(
            AppConstant.PROFILE.PROFILE_SECTION_DISCOVER_TEXT
          )}

          <span>
            <RightArrowIcon />
          </span>
        </div>
      </div>

      <Toast
        showToast={showToast}
        isSuccess={isSuccess}
        message={toastMessage}
        handleToastClose={handleToastClose}
      />
    </div>
  );
};
