export const URLConstant = {
  urls: {
    homePage: '/',
    signUpPage: '/sign-up',
    signInPage: '/sign-in',
    verificationPage: '/verification',
    profileSetupPage: '/profile-setup',
    notificationPage: '/notifications',
    profilePage: '/my-profile',
    knowledgeHubPage: '/knowledge-hub',
    shalinaTv: '/shalina-tv',
    learningCenterPage: '/learning-center',
    commiunitiesPage: '/communities',
    commiunitiesDetailPage: '/communities/:communityId',
    aboutUsPage: '/about-us',
    termsAndConditionsPage: '/terms-and-conditions',
    searchPage: '/search',
    surveyPage: '/survey',
    surveyQuiz: '/survey/:surveyId/quiz',
    surveyDetails: '/survey/:surveyId',
    listingPage: '/:sectionTitle-listing',
    listingPageWithModule: '/:pageName/:contentTypeId',
    detailPage: '/:contentType/:contentId',
    detailPageEncrypted: '/:encryptedID',
    detailPageWithModule: '/:pageName/:contentTypeId/:contentId',
    rssListing: '/medical-news-feeds-listing',
    rssDetail: '/medical-news-feeds-listing/medical-news-feeds-detail',
    referral: '/referral/:referralId',
    medicalCalculator: '/medical-calculator',
    helpSupport: '/help-support',
    samlRedirectorForMobile: '/saml-redirection',
    appStoreRedirect: '/app',
    universalShare: '/share'
  },
};
