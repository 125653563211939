import { useEffect, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';
import {
  AppConstant,
  fetchArticleDocumentLink,
  fetchArticleList,
  getArticleSearchKeyword,
  getOnlyDate,
  ImageConstant,
  isJournalDetail,
  savePageNoLeftWhileReading,
  translate,
  updateArticleSearchKeyword,
  useAppDispatch,
  useAppSelector
} from '@shalina-app/shared';

import {
  DEFAULT_ARTICLES_SKIP,
  DEFAULT_ARTICLES_TAKE
} from '../../../../../../app.constants';
import { InfiniteScrollComponent } from '../../../../../infiniteScroll';
import { BarLoader, CircularLoader } from '../../../../../loader';
import { ShalinaPDFViewer } from '../../../../../pdfViewer';
import styles from '../articleDetails.module.scss';
import { retrieveData, STORAGE_CONSTANTS } from '../../../../../../utils';
import { SignUpPopup } from '../../../../../../features';

export const JournalArticles: React.FC<any> = ({
  contentDetails,
  handleReload
}) => {
  const dispatch = useAppDispatch();
  const t = translate();
  const inputRef = useRef<HTMLInputElement>(null);

  const [modalOpen, setModalOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState('');
  const [articlesData, setArticlesData] = useState<any>([]);
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(DEFAULT_ARTICLES_TAKE);
  const [hasMore, setHasMore] = useState(true);
  const [reload, setReload] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [showSignUp, setShowSignUp] = useState(false);
  const [searchLoader, setSearchLoader] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [search, setSearch] = useState(false);
  const [searchInProgress, setSearchInProgress] = useState(false);
  const [pdfReaderInfo, setPdfReaderInfo] = useState({
    fileUrl: '',
    pageNumber: 0,
    articleTitle: '',
    dbId: '',
    an: ''
  });
  const [recentArticle, setRecentArticle] = useState({
    articleTitle: '',
    an: '',
    dbId: ''
  });

  const isLoggedIn: boolean = retrieveData(STORAGE_CONSTANTS.IS_LOGGED_IN);
  const showArticle = isJournalDetail(contentDetails?.field_unique_code);

  useEffect(() => {
    setReload(true);
    setLoader(true);
  }, []);

  useEffect(() => {
    if (reload) {
      loadApi();
    }
  }, [reload]);

  const handleModalClose = () => {
    setModalOpen(false);
    handleReload(
      recentArticle?.articleTitle,
      recentArticle?.an,
      recentArticle?.dbId
    );
  };

  const saveTimeStamp = async (
    lastReadAt: any,
    article_title?: string,
    dbid?: string,
    an?: string
  ) => {
    const payload = {
      id: contentDetails?.id,
      lastreadat: lastReadAt,
      article_title: article_title,
      dbid: dbid,
      an: an
    };
    await dispatch(savePageNoLeftWhileReading(payload));
  };

  const handleArticleClick = async (
    an: string,
    dbId: string,
    title: string
  ) => {
    if (isLoggedIn) {
      const obj = {
        articleTitle: title,
        an: an,
        dbId: dbId
      };
      setRecentArticle(obj);
      const lastReadAt = contentDetails?.last_read_at
        ? parseInt(contentDetails?.last_read_at)
        : 0;
      setLoading(true);
      const parameter = { an: an, dbId: dbId };
      const response = await dispatch(
        fetchArticleDocumentLink(parameter as any)
      ).unwrap();

      if (
        response &&
        response?.status === 'SUCCESS' &&
        response?.statusCode === 200
      ) {
        if (response?.data?.isPdfLink) {
          const pdfReaderInfo = {
            fileUrl: response?.data?.url,
            pageNumber: contentDetails?.article?.an === an ? lastReadAt : 0,
            articleTitle: title,
            dbId: dbId,
            an: an
          };
          setPdfReaderInfo(pdfReaderInfo);
          setUrl(response?.data?.url);
          setModalOpen(true);
          setLoading(false);
        } else if (response?.data?.isSAML) {
          await saveTimeStamp(0);
          setLoading(false);
          window.open(response?.data?.url, '_self');
        }
      }
    } else {
      setShowSignUp(true);
    }
  };

  const handleSignUpClose = () => {
    setShowSignUp(false);
  };

  const searchKeywordFromStore = useAppSelector(getArticleSearchKeyword());
  
  const loadApi = async () => {
    try {
      const payload = {
        issn: contentDetails?.event_data?.issn,
        searchKeyword: searchKeywordFromStore ? searchKeywordFromStore : '',
        filterOptions: {
          skip: skip,
          take: take
        }
      };
      if (contentDetails?.event_data?.issn && showArticle) {
        setSearchInProgress(true);
        const response: any = await dispatch(
          fetchArticleList(payload)
        ).unwrap();
        setReload(false);

        if (search) {
          setArticlesData((prevItems: any) => [...response?.data?.items]);
          setSearch(false);
        } else {
          if (response?.data?.items?.length === 0) {
            setSearchInProgress(false);
          }
          setArticlesData((prevItems: any) => [
            ...prevItems,
            ...response?.data?.items
          ]);
        }

        setLoader(false);
        setSearchLoader(false);
        setTotalCount(response?.data?.totalCount);
        // setHasMore(response?.data?.items.length > response?.data?.totalCount);
        setSkip((prevSkip) => prevSkip + DEFAULT_ARTICLES_SKIP);
      } else {
        setSearchInProgress(false);
        setLoader(false);
        setSearchLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setSearchLoader(false);
    }
  };

  useEffect(() => {
    if (articlesData?.length >= totalCount) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  }, [articlesData, totalCount]);

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
    dispatch(updateArticleSearchKeyword(value));
    // if(value === '') {
    //   setSearch(true);
    //   setSearchInProgress(true);
    //   setSearchLoader(true);
    //   setSkip(0);
    // }
  };

  const handleKeyDown = async (event: any) => {
    if (event.key === 'Enter') {
      // Handle the Enter key press here
      setSearchInProgress(true);
      setSearchLoader(true);
      setSkip(0);
      setSearch(true);
      inputRef?.current?.blur();
    }
  };

  const fetchMoreData = () => {
    if (articlesData?.length >= totalCount) {
      setHasMore(false);
      return;
    }
    loadApi();
  };

  useEffect(() => {
    if (search) {
      loadApi();
    }
  }, [search]);

  const { AuthorIcon } = ImageConstant.SVG_ICONS;
  const { DropDownArrowIcon } = ImageConstant.SVG_ICONS;

  const contentId = contentDetails?.id || '';
  return (
    <>
      {loader && (
        <div className={styles.loaderDiv}>
          <BarLoader />
        </div>
      )}
      <div className={styles.articalContainer}>
        {searchInProgress && (
          <div className={styles.articleSearch}>
            <svg
              width='18'
              height='17'
              viewBox='0 0 18 17'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              className={styles.searchIcon}
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M2 7.88607C2 4.63477 4.63413 2 7.88235 2C11.1306 2 13.7647 4.63477 13.7647 7.88607C13.7647 11.1374 11.1306 13.7721 7.88235 13.7721C4.63413 13.7721 2 11.1374 2 7.88607ZM7.88235 0C3.52854 0 0 3.53122 0 7.88607C0 12.2409 3.52854 15.7721 7.88235 15.7721C9.96855 15.7721 11.8653 14.9614 13.2751 13.6377L16.1743 16.5384C16.5647 16.9291 17.1978 16.9292 17.5885 16.5388C17.9791 16.1484 17.9793 15.5152 17.5888 15.1246L14.5538 12.0879C15.3209 10.8714 15.7647 9.43054 15.7647 7.88607C15.7647 3.53122 12.2362 0 7.88235 0Z'
                fill='#757575'
              />
            </svg>

            <input
              type='text'
              ref={inputRef}
              value={searchKeywordFromStore}
              name='article_search'
              placeholder={t(AppConstant.COMMON.SEARCH)}
              onChange={(e) => handleSearchChange(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            {searchLoader && (
              <div className={styles.searchLoader}>
                <CircularProgress
                  size={20}
                  thickness={4}
                  sx={{
                    color: 'var(--secondaryOrange)'
                  }}
                />
              </div>
            )}
          </div>
        )}

        {loading && <CircularLoader size={50} thickness={4} />}
        {articlesData && articlesData.length > 0 ? (
          <>
            <div
              className={`${styles.articalListWrapper}`}
              id='articalListscrollableDiv'
            >
              <InfiniteScrollComponent
                dataLength={articlesData.length}
                fetchMoreData={fetchMoreData}
                hasMore={hasMore}
                loader={true}
              >
                <div className={styles.articleList}>
                  {/* {!isLoggedIn && (
                    <div
                      className={`${styles.ebookContent} ${styles.guestDescWrapper}`}
                    >
                      <div className={styles.guestDesc}>
                        <Link to={urls.signUpPage}>
                          <button>
                            {t(
                              AppConstant.AUTHENTICATION_PAGE
                                .SIGN_UP_TO_CONTINUE
                            )}
                          </button>
                        </Link>
                      </div>
                    </div>
                  )} */}
                  {articlesData?.map((item: any) => (
                    <div className={styles.articleItem}>
                      <img src={ImageConstant.SOURCE.pdfImage} alt='pdf img' />
                      <div className={styles.details}>
                        <h4
                          className={styles.articleTitle}
                          onClick={() =>
                            handleArticleClick(
                              item?.An,
                              item?.DbId,
                              item?.title
                            )
                          }
                        >
                          {item?.title}
                        </h4>
                        {item?.authored_by && (
                          <div className={styles.articleAuthor}>
                            <AuthorIcon />
                            <span title={item?.authored_by}>
                              <strong>{t(AppConstant.COMMON.BY_TEXT)}</strong>
                              {item?.authored_by}
                            </span>
                          </div>
                        )}
                        <div className={styles.articleDate}>
                          {getOnlyDate(item?.publication_date)};{' '}
                          {t(AppConstant.COMMON.VOL_TEXT)}. {item?.volume}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </InfiniteScrollComponent>
            </div>
          </>
        ) : (
          !loader && (
            <>
              <div className={styles.notFoundText}>
                <p>{t(AppConstant.KNOWLEDGE_HUB_PAGE.NO_ARTICLES_FOUND)}</p>
              </div>
            </>
          )
        )}
      </div>
      <Dialog
        fullScreen
        open={modalOpen}
        onClose={handleModalClose}
        className={styles.journalModal}
      >
        <DialogContent>
          <div>
            <div className={styles.closeDiv}>
              <button className={styles.backBtn} onClick={handleModalClose}>
                <DropDownArrowIcon />
                {t(AppConstant.COMMON.BACK_CTA)}
              </button>
              {/* <span onClick={handleModalClose}>&times;</span> */}
            </div>
            <ShalinaPDFViewer
              fileUrl={pdfReaderInfo?.fileUrl}
              pageNumber={pdfReaderInfo?.pageNumber}
              id={contentId}
              articleTitle={pdfReaderInfo?.articleTitle}
              dbId={pdfReaderInfo?.dbId}
              an={pdfReaderInfo?.an}
            />
          </div>
        </DialogContent>
      </Dialog>
      <SignUpPopup open={showSignUp} handleClose={handleSignUpClose} />
    </>
  );
};
