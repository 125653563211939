import { isExternalLink, WEB_URL } from '@shalina-app/shared';
import parse from 'html-react-parser';

import { MarketingCardProps } from '.';
import { URLConstant } from '../../../../routes';
import { getImageSrc, useNavigatePageUtils } from '../../../../utils';
import styles from './homeBanner.module.scss';

export const MarketingCardComponent: React.FC<MarketingCardProps> = ({
  content,
  isMobile
}) => {
  const navigatePage = useNavigatePageUtils();
  const { urls } = URLConstant;
  const extractRemainingPath = (url: string, baseUrl: string) => {
    if (url?.startsWith(baseUrl)) {
      return url?.slice(baseUrl?.length);
    } else {
      return url;
    }
  };

  const handleButtonClick = async (item: any) => {
    if (isExternalLink(item?.link, WEB_URL)) {
      window.open(item?.link, '_blank');
    } else {
      const url = extractRemainingPath(item?.link, WEB_URL);
      const routeUrl = url?.includes('medical-news-feeds-listing') ? urls.rssListing : url;
      const navigateDetails = {
        isDynamicUrl: false
      };
      navigatePage(routeUrl, navigateDetails);
    }
  };

  const description = content?.field_description
    ? parse(content?.field_description)
    : '';
  return (
    <div className={styles.marketingCard}>
      <img
        className={styles.marketingImg}
        src={
          isMobile 
          ? content?.field_thumbnail_image &&
          getImageSrc(content?.field_thumbnail_image) 
          : content?.field_detail_image &&
          getImageSrc(content?.field_detail_image)
        }
        alt='marketing image'
      />
      {/* ImageConstant.SOURCE.MarketingImg */}
      <div className={styles.marketingText}>
        <h2>{content?.title}</h2>
        <p>{description}</p>
        <div className={styles.btnWrapper}>
          {content?.field_buttons && content?.field_buttons?.length > 0
            ? content?.field_buttons?.map((item: any) => {
                return (
                  <button onClick={() => handleButtonClick(item)}>
                    <span>{item?.title}</span>
                  </button>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
};
