import {
  AppConstant,
  getDefaultValue,
  getOnlyDate,
  ImageConstant,
  SLIDE_TO_SHOW_WEB,
  translate
} from '@shalina-app/shared';

import { SurveySectionPropsType } from '.';
import styles from './surveySection.module.scss';
import { getImageSrc, useNavigatePageUtils } from '../../../../utils';
import { URLConstant } from '../../../../routes';
import { BannerSlider } from '../../../../components';

export const SurveySection: React.FC<SurveySectionPropsType> = ({
  data,
  isLoggedIn,
  handleSignUpFlow,
  isFeaturedLoading
}) => {
  const t = translate();
  const navigatePage = useNavigatePageUtils();
  const { urls } = URLConstant;
  // const { data } = props;
  const listOfSurveyData = data?.length > 0 ? data[0] : (null as any);
  const { DateIcon, AuthorIcon, BannerBg } = ImageConstant.SVG_ICONS;
  const isDataExist = data && data?.length > 0 ? true : false;
  const slides = data?.length ?? 0;
  const isViewAllExist = slides ? slides > SLIDE_TO_SHOW_WEB.FEATURED : false
  const handleSurveyClick = (item: any) => {
    const navigateDetails = {
      isDynamicUrl: true,
      dynamicUrlOptions: {
        surveyId: item?.nid
      }
    };
    navigatePage(urls.surveyDetails, navigateDetails);
  };

  const handleSurveyQuizClick = (item: any) => {
    if (isLoggedIn) {
      const navigateDetails = {
        isDynamicUrl: true,
        dynamicUrlOptions: {
          surveyId: item?.nid
        },
        stateParams: {
          surveyUniqueId: item?.survey_id,
          title: item?.title
        }
      };
      navigatePage(urls.surveyQuiz, navigateDetails);
    } else {
      handleSignUpFlow();
    }
  };
  const handleViewAllButtonClick = () => {
    const navigateDetails = {
      isDynamicUrl: false,
    };
    navigatePage(urls.surveyPage, navigateDetails);
  };

  const isSurveyExpired = () => {
    const exp = listOfSurveyData?.field_end_date;
    return new Date().valueOf() > new Date(exp ? exp : '').valueOf();
  };
  return (
      <div className={styles.takeSurvey}>
          <BannerSlider
            slidesToShow={SLIDE_TO_SHOW_WEB.FEATURED}
            sliderHeading={t(AppConstant.COMMON.SURVEY)}
            isDataExist={isDataExist}
            isFeaturedLoading={isFeaturedLoading ?? false}
            dataLength={slides || 0}
            isViewAllExist={isViewAllExist}
            handleViewAllButtonClick={handleViewAllButtonClick}
          >
            {
              data && data?.length > 0 && data.map((item) => {
                return (
                  <div className={styles.homeSlider}>
                  <div className={styles.surveyContent}>
                 <div className={styles.card}>
                   {/* {listOfSurveyData?.title && listOfSurveyData?.description && (
                     <h4 className={styles.title}>
                       {listOfSurveyData?.title}: {listOfSurveyData?.description}
                     </h4>
                   )} */}
                   {item?.title && (
                     <h4 className={styles.title} onClick={()=>handleSurveyClick(item)}>
                       {item?.title}
                     </h4>
                   )}
                   {/* {listOfSurveyData?.field_start_date && ( */}
                   <div className={styles.dateWrapper}>
                     {item?.field_start_date && (
                       <div className={styles.date}>
                         <DateIcon />
                         <span>{t(AppConstant.COMMON.STARTS)}</span>
                         {item?.field_start_date
                           ? getOnlyDate(item?.field_start_date)
                           : getDefaultValue()}
                       </div>
                     )}
                     {item?.field_end_date && (
                       <div className={`${styles.date} ${styles.endDate}`}>
                         <DateIcon />
                         <span>{t(AppConstant.COMMON.EXPIRES)}</span>
                         {item?.field_end_date
                           ? getOnlyDate(item?.field_end_date)
                           : getDefaultValue()}
                       </div>
                     )}
                   </div>
                   {/* // )} */}
                   {/* {listOfSurveyData?.postedByName && ( */}
                   {item?.field_survey_posted_by && (
                     <div className={styles.postedBy}>
                       <AuthorIcon />
                       <span
                         title={
                          item?.field_survey_posted_by ||
                           getDefaultValue()
                         }
                       >
                         <strong>{t(AppConstant.COMMON.BY_TEXT)}</strong>
                         {item?.field_survey_posted_by ||
                           getDefaultValue()}
                       </span>
                     </div>
                   )}
                   {/* )} */}
                   {/* <a href="#" className={styles.cta}> */}
                   <button
                     onClick={()=>handleSurveyQuizClick(item)}
                     className={`${styles.cta}`}
                     disabled={isSurveyExpired() || item?.isSubmitted}
                   >
                     {t(AppConstant.HOME_PAGE.TAKE_SURVEY)}
                   </button>
     
                   {/* </a> */}
                 </div>
                 <div className={styles.surveyImg}>
                   <img
                     src={
                      item?.field_thumbnail_image
                         ? getImageSrc(item?.field_thumbnail_image)
                         : ImageConstant.SOURCE.takeSurvey
                     }
                     alt='surveyImg'
                   />
                 </div>
               </div>
               </div>
                )
              })
            }
        </BannerSlider>
        <div className={styles.surveyBg}>
          <BannerBg />
        </div>
      </div>
    );
};
