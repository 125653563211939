import {
  API_CATEGORY_TYPES,
  AppConstant,
  CommunityStatus,
  COMMUNITY_API_REQUEST_STATUS,
  COMMUNITY_ENABLED,
  COMMUNITY_REQUEST_STATUS,
  COMMUNITY_SORT_SCREENS,
  CUSTOM_ANALYTICS_EVENT_NAMES,
  fetchCommunityReceivedRequests,
  fetchCommunityRequests,
  FILTER_BY_TABS_ENUM,
  getAnalyticsUserDetails,
  getCommunityReceivedRequests,
  getCommunityRequests,
  getCommunityRequestsOnLoad,
  getLoggedInUserInfo,
  ImageConstant,
  logEvent,
  PlatformNames,
  translate,
  updateCommunity,
  useAppDispatch,
  useAppSelector,
} from '@shalina-app/shared';
import { useEffect, useState } from 'react';

import { CommunitiesShortCard } from '../../components';
import styles from './RequestCommunities.module.scss';
import {
  BarLoader,
  InfiniteScrollComponent,
  SortFilter,
  Toast,
} from '../../../../components';
import { RequestCommunitiesProps } from '.';
import {
  DEFAULT_COMMUNITY_SKIP,
  DEFAULT_COMMUNITY_TAKE,
} from '../../../../app.constants';
import {
  retrieveData,
  STORAGE_CONSTANTS,
  UserInterface,
} from '../../../../utils';
import { appInsights } from '../../../../applicationInsight';

export const RequestCommunities: React.FC<RequestCommunitiesProps> = ({
  requestList,
  handleCommunityClick,
  handleMainApiCall,
  refresh,
  setRefresh,
}) => {
  const t = translate();
  const dispatch = useAppDispatch();

  const userInfo = useAppSelector(getLoggedInUserInfo);
  const sentRequestList = useAppSelector(getCommunityRequests);
  const receivedRequestList = useAppSelector(getCommunityReceivedRequests);
  const requestListOnLoad = useAppSelector(getCommunityRequestsOnLoad);

  const user: UserInterface | any = retrieveData(STORAGE_CONSTANTS.USER);
  const isLoggedIn: boolean = retrieveData(STORAGE_CONSTANTS.IS_LOGGED_IN);

  const [dataFromStore, setDataFromStore] = useState(true);
  const [isSentTabActive, setIsSentTabActive] = useState(true);
  const [loader, setLoader] = useState(false);
  const [filterCall, setFilterCall] = useState(false);
  const [selectedAreaOfInterest, setSelectedAreaOfInterest] = useState([]);
  const [initialLoader, setInitialLoader] = useState(false);

  const [hasMore, setHasMore] = useState(true);
  const [scrollLoader, setScrollLoader] = useState(false);
  const [requestData, setRequestData] = useState<any>([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [filterOptions, setFilterOptions] = useState({
    skip: 0,
    take: DEFAULT_COMMUNITY_TAKE,
    countries: '',
    interest: '',
    status: COMMUNITY_API_REQUEST_STATUS.SENT,
    // speciality: "",
    // sort: "",
  });
  const [skip, setSkip] = useState(0);
  const [apiCall, setApiCall] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const handleToastClose = () => {
    setShowToast(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setInitialValue();
    if (isLoggedIn) {
      setApiCall(true);
      setInitialLoader(true);
    }
  }, []);

  useEffect(() => {
    if (refresh) {
      setFilterOptions((prevOptions: any) => ({
        ...prevOptions,
        skip: 0,
      }));
      setSkip(0);
      setApiCall(true);
      setInitialLoader(true);
      setRequestData([]);
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    if (apiCall) {
      loadApi();
    }
  }, [apiCall]);

  const setInitialValue = () => {
    if (
      (![null, undefined, []].includes(userInfo?.areaOfInterest) ||
        ![null, undefined, []].includes(user?.areaOfInterest)) &&
      isLoggedIn
    ) {
      const userDetails = userInfo?.areaOfInterest ?? user?.areaOfInterest;
      const areaOfInterestIds = userDetails?.map(
        (item: any) => item?.tid && String(item?.tid)
      );
      setSelectedAreaOfInterest(areaOfInterestIds);
    }
  };

  const handleToggle = () => {
    setIsSentTabActive(!isSentTabActive);
    if (isLoggedIn) {
      setInitialLoader(true);
      setFilterOptions((prevOptions: any) => ({
        ...prevOptions,
        skip: 0,
        status: !isSentTabActive
          ? COMMUNITY_API_REQUEST_STATUS.SENT
          : COMMUNITY_API_REQUEST_STATUS.RECEIVED,
      }));
      setSkip(0);
      setRequestData([]);
      setApiCall(true);
      setDataFromStore(true);
    }
  };

  const loadApi = async () => {
    if (filterOptions?.status === COMMUNITY_API_REQUEST_STATUS?.SENT) {
      const response = await dispatch(
        fetchCommunityRequests(filterOptions)
      ).unwrap();
      if (
        response &&
        response?.status === 'SUCCESS' &&
        response?.statusCode === 200
      ) {
        setPagination(response);
      } else {
        setLoader(false);
        setDataFromStore(false);
        setFilterCall(false);
        setApiCall(false);
        setInitialLoader(false);
      }
      setApiCall(false);
    } else {
      const response = await dispatch(
        fetchCommunityReceivedRequests(filterOptions)
      ).unwrap();
      if (
        response &&
        response?.status === 'SUCCESS' &&
        response?.statusCode === 200
      ) {
        setPagination(response);
      } else {
        setLoader(false);
        setDataFromStore(false);
        setFilterCall(false);
        setApiCall(false);
        setInitialLoader(false);
      }
      setApiCall(false);
    }
  };

  const setPagination = (response: any) => {
    setDataFromStore(false);
    setLoader(false);
    const data = response?.data;
    const totalCount = response?.count;

    setTotalDataCount(totalCount);
    if (apiCall) {
      // if (requestData?.length >= response?.count) {
      //   setHasMore(false);
      // } else {
      //   setHasMore(true);
      // }
      setApiCall(false);
    }
    if (data) {
      if (filterCall) {
        setFilterCall(false);
        setRequestData(data);
      } else {
        setRequestData((prevOptions: any) => [...prevOptions, ...data]);
      }
      setInitialLoader(false);
    }
  };

  const fetchMoreData = () => {
    setScrollLoader(true);
    if (requestData?.length >= totalDataCount) {
      setHasMore(false);
      return;
    }
    setSkip(skip + DEFAULT_COMMUNITY_SKIP);
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      skip: skip + DEFAULT_COMMUNITY_SKIP,
      take: DEFAULT_COMMUNITY_TAKE,
    }));
    setApiCall(true);
  };

  const onApplyButtonClick = (
    selectedContentType: Array<string>,
    selectedSpecialization: Array<string>,
    selectedCommunityCategory: Array<string>,
    selectedCommunityCountry: Array<string>
  ) => {
    setSkip(0);
    setFilterOptions((prevOptions: any) => ({
      ...prevOptions,
      skip: 0,
      speciality: selectedSpecialization?.toString(),
      countries: selectedCommunityCountry?.toString(),
    }));
    setFilterCall(true);
    setApiCall(true);
    setHasMore(true);
  };

  const onSortChange = (value: string) => {
    setSkip(0);
    setFilterOptions((prevOptions: any) => ({
      ...prevOptions,
      skip: 0,
      sort: value,
    }));
    setFilterCall(true);
    setApiCall(true);
    setHasMore(true);
  };

  const onAreaOfInterest = (value: string[]) => {
    setSkip(0);
    setFilterOptions((prevOptions: any) => ({
      ...prevOptions,
      skip: 0,
      interest: value?.toString(),
    }));
    setFilterCall(true);
    setApiCall(true);
    setHasMore(true);
  };

  const onResetFilter = () => {
    setSkip(0);
    setFilterOptions((prevValue: any) => ({
      ...prevValue,
      skip: 0,
      speciality: '',
      countries: '',
    }));
    setFilterCall(true);
    setApiCall(true);
    setHasMore(true);
  };

  const initiateApiCall = () => {
    setSkip(0);
    setFilterOptions((prevValue: any) => ({
      ...prevValue,
      skip: 0,
    }));
    setFilterCall(true);
    setApiCall(true);
  };

  const handleRevokeRequest = async (
    community_Id: string,
    community_name: string
  ) => {
    const req = {
      communityId: community_Id,
      requestStatus: COMMUNITY_REQUEST_STATUS.REVOKE,
    };
    const response = await dispatch(updateCommunity(req)).unwrap();
    if (
      response &&
      response?.status === 'SUCCESS' &&
      response?.statusCode === 200
    ) {
      setShowToast(true);
      setIsSuccess(true);
      setToastMessage(
        t(AppConstant.COMMUNITY.REVOKE_COMMUNITY_REQUEST_SUCCESS)
      );
      if (COMMUNITY_ENABLED) {
        const userObj = userInfo || user;
        const userData = getAnalyticsUserDetails(userObj);
        logEvent(
          appInsights,
          'custom',
          CUSTOM_ANALYTICS_EVENT_NAMES.USER_COMMUNITY_STATUS,
          {
            userEmail: userData?.emailId,
            userMobile: userData?.mobile,
            userName: userData?.name,
            userQualification: userData?.qualification,
            userInterest: userData?.areaOfInterest,
            userSpecialization: userData?.specialization,
            userCountryOfPractice: userData?.countryOfPractice,
            userPreferredLanguage: userData?.preferredLanguage,
            platform: PlatformNames.WEB,
            communityName: community_name,
            communityId: community_Id,
            status: CommunityStatus.REVOKED,
          }
        );
      }
      initiateApiCall();
      handleMainApiCall();
    } else {
      setShowToast(true);
      setIsSuccess(false);
      setToastMessage(t(AppConstant.COMMUNITY.REVOKE_COMMUNITY_REQUEST_FAILED));
    }
  };

  const handleRejectRequest = async (
    community_Id: string,
    community_Name: string
  ) => {
    const req = {
      communityId: community_Id,
      requestStatus: COMMUNITY_REQUEST_STATUS.REJECT,
    };
    const response = await dispatch(updateCommunity(req)).unwrap();
    if (
      response &&
      response?.status === 'SUCCESS' &&
      response?.statusCode === 200
    ) {
      setShowToast(true);
      setIsSuccess(true);
      setToastMessage(
        t(AppConstant.COMMUNITY.REJECT_COMMUNITY_REQUEST_SUCCESS)
      );
      if (COMMUNITY_ENABLED) {
        const userObj = userInfo || user;
        const userData = getAnalyticsUserDetails(userObj);
        logEvent(
          appInsights,
          'custom',
          CUSTOM_ANALYTICS_EVENT_NAMES.USER_COMMUNITY_STATUS,
          {
            userEmail: userData?.emailId,
            userMobile: userData?.mobile,
            userName: userData?.name,
            userQualification: userData?.qualification,
            userInterest: userData?.areaOfInterest,
            userSpecialization: userData?.specialization,
            userCountryOfPractice: userData?.countryOfPractice,
            userPreferredLanguage: userData?.preferredLanguage,
            platform: PlatformNames.WEB,
            communityName: community_Name,
            communityId: community_Id,
            status: CommunityStatus.REJECTED,
          }
        );
      }
      initiateApiCall();
      handleMainApiCall();
    } else {
      setShowToast(true);
      setIsSuccess(false);
      setToastMessage(t(AppConstant.COMMUNITY.REJECT_COMMUNITY_REQUEST_FAILED));
    }
  };

  const handleAcceptRequest = async (
    community_Id: string,
    community_name: string
  ) => {
    const req = {
      communityId: community_Id,
      requestStatus: COMMUNITY_REQUEST_STATUS.ACCEPT,
    };
    const response = await dispatch(updateCommunity(req)).unwrap();
    if (
      response &&
      response?.status === 'SUCCESS' &&
      response?.statusCode === 200
    ) {
      setShowToast(true);
      setIsSuccess(true);
      setToastMessage(
        t(AppConstant.COMMUNITY.ACCEPT_COMMUNITY_REQUEST_SUCCESS)
      );
      if (COMMUNITY_ENABLED) {
        const userObj = userInfo || user;
        const userData = getAnalyticsUserDetails(userObj);
        logEvent(
          appInsights,
          'custom',
          CUSTOM_ANALYTICS_EVENT_NAMES.USER_COMMUNITY_STATUS,
          {
            userEmail: userData?.emailId,
            userMobile: userData?.mobile,
            userName: userData?.name,
            userQualification: userData?.qualification,
            userInterest: userData?.areaOfInterest,
            userSpecialization: userData?.specialization,
            userCountryOfPractice: userData?.countryOfPractice,
            userPreferredLanguage: userData?.preferredLanguage,
            platform: PlatformNames.WEB,
            communityName: community_name,
            communityId: community_Id,
            status: CommunityStatus.ACCEPTED,
          }
        );
      }
      initiateApiCall();
      handleMainApiCall();
    } else {
      setShowToast(true);
      setIsSuccess(false);
      setToastMessage(t(AppConstant.COMMUNITY.ACCEPT_COMMUNITY_REQUEST_FAILED));
    }
  };

  const content = isLoggedIn
    ? dataFromStore
      ? isSentTabActive
        ? requestListOnLoad
        : receivedRequestList
      : requestData
    : [];

  return (
    <>
      <h1 className={styles.pageTitle}>
        {t(AppConstant.COMMUNITY.COMMUNITY_REQUEST_TAB)}
      </h1>
      <div className={styles.landingFilter}>
        <SortFilter
          tabsEnum={[FILTER_BY_TABS_ENUM.SPECIALITY]}
          isCommunity={true}
          isDark={true}
          categoryType={API_CATEGORY_TYPES.COMMUNITIES}
          handleApplyFilter={onApplyButtonClick}
          handleSortChange={onSortChange}
          handleAreaOfInterest={onAreaOfInterest}
          handleResetFilter={onResetFilter}
          selectedAreaOfInterest={selectedAreaOfInterest}
          communityModule={COMMUNITY_SORT_SCREENS.REQUEST}
        />
        {(filterCall || initialLoader) && (
          <div className={styles.loaderDiv}>
            <BarLoader />
          </div>
        )}
      </div>
      <div className={styles.statusWrapper}>
        <button
          className={
            isSentTabActive
              ? `${styles.statusCta} ${styles.active}`
              : styles.statusCta
          }
          onClick={handleToggle}
        >
          {t(AppConstant.COMMUNITY.REQUEST_FILTER_SENT_TEXT)}
        </button>
        <button
          className={
            isSentTabActive
              ? styles.statusCta
              : `${styles.statusCta} ${styles.active}`
          }
          onClick={handleToggle}
        >
          {t(AppConstant.COMMUNITY.REQUEST_FILTER_RECEIVED_TEXT)}
        </button>
      </div>
      {content?.length > 0 ? (
        <div className={styles.requestWrapper}>
          <InfiniteScrollComponent
            dataLength={content?.length || 0}
            fetchMoreData={fetchMoreData}
            hasMore={hasMore}
            loader={true}
            // scrollThreshold={"1000px"}
          >
            {content?.map((community: any) => (
              <CommunitiesShortCard
                isRequest={true}
                categoryType={''}
                content={community}
                isFullWidth={true}
                handleRevokeRequest={handleRevokeRequest}
                handleRejectRequest={handleRejectRequest}
                handleAcceptRequest={handleAcceptRequest}
                handleCommunityClick={handleCommunityClick}
                isSentTabActive={isSentTabActive}
                handleMainApiCall={handleMainApiCall}
              />
            ))}
          </InfiniteScrollComponent>
        </div>
      ) : !initialLoader && isSentTabActive ? (
        <div className={styles.notFoundSec}>
          <div className={styles.notFoundWrapper}>
            <img src={ImageConstant.SOURCE.welcomeToCommunities} alt='img' />
            <h2>{t(AppConstant.COMMUNITY.NO_SENT_REQUEST)}</h2>
            <p>{t(AppConstant.COMMUNITY.JOIN_COMMUNITY_TEXT)} </p>
          </div>
        </div>
      ) : !initialLoader ? (
        <div className={styles.notFoundSec}>
          <div className={styles.notFoundWrapper}>
            <img src={ImageConstant.SOURCE.welcomeToCommunities} alt='img' />
            <h2>{t(AppConstant.COMMUNITY.NO_RECEIVED_REQUEST)}</h2>
            <p>{t(AppConstant.COMMUNITY.JOIN_COMMUNITY_TEXT)} </p>
          </div>
        </div>
      ) : (
        <></>
      )}
      <Toast
        showToast={showToast}
        isSuccess={isSuccess}
        message={toastMessage}
        handleToastClose={handleToastClose}
      />
    </>
  );
};
