import {
  AppConstant,
  getPreferredLanguagesList,
  ImageConstant,
  translate,
  useAppSelector
} from '@shalina-app/shared';

import { FooterProps } from '.';
import { URLConstant } from '../../routes';
import { useNavigatePageUtils } from '../../utils';
import { LangSelector } from '../Header';
import { AboutSection, DownloadOptions, NavItems } from './components';
import styles from './footer.module.scss';

export const Footer: React.FC<FooterProps> = () => {
  const t = translate();
  const navigatePageUtils = useNavigatePageUtils();
  const { urls } = URLConstant;

  const preferredLanguagesList = useAppSelector(getPreferredLanguagesList);

  const onLogoClick = () => {
    const navigateDetails = {
      isDynamicUrl: false
    };
    navigatePageUtils(urls.homePage, navigateDetails);
  };
  return (
    <div className={styles.footer}>
      <div className='cust-container'>
        <div className={styles.footerLogoWrapper}>
          <div className={styles.footerLogo}>
            <img
              src={ImageConstant.SOURCE.healthcareFooterLogo}
              alt='logo'
              className={styles.logo}
              onClick={onLogoClick}
            />
          </div>
          <LangSelector
            preferredLanguages={preferredLanguagesList}
            isFooter={true}
          />
        </div>
        <div className={styles.footerWrapper}>
          <AboutSection />
          <NavItems />
          <DownloadOptions />
        </div>
        <div className={styles.copyright}>
          {t(AppConstant.FOOTER.FOOTER_LICENCE_TEXT)}
        </div>
      </div>
    </div>
  );
};
