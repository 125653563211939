"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageIcon = void 0;
const React = __importStar(require("react"));
const MessageIcon = (props) => (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: 14, height: 14, fill: "none" }, props),
    React.createElement("path", { fill: "#002E5B", d: "M4.168 8.366a.58.58 0 0 0 .427-.172.58.58 0 0 0 .173-.428.58.58 0 0 0-.173-.427.58.58 0 0 0-.427-.173.58.58 0 0 0-.428.173.58.58 0 0 0-.172.427c0 .17.057.313.172.428a.58.58 0 0 0 .428.172Zm0-1.8a.58.58 0 0 0 .427-.172.58.58 0 0 0 .173-.428.58.58 0 0 0-.173-.427.58.58 0 0 0-.427-.173.58.58 0 0 0-.428.173.58.58 0 0 0-.172.427c0 .17.057.313.172.428a.58.58 0 0 0 .428.172Zm0-1.8a.58.58 0 0 0 .427-.172.58.58 0 0 0 .173-.428.58.58 0 0 0-.173-.427.58.58 0 0 0-.427-.173.58.58 0 0 0-.428.173.58.58 0 0 0-.172.427c0 .17.057.313.172.428a.58.58 0 0 0 .428.172Zm1.8 3.6h3v-1.2h-3v1.2Zm0-1.8h4.8v-1.2h-4.8v1.2Zm0-1.8h4.8v-1.2h-4.8v1.2Zm-4.8 8.4v-10.8c0-.33.117-.612.352-.847s.518-.353.848-.353h9.6c.33 0 .613.118.848.353.234.235.352.517.352.847v7.2c0 .33-.117.612-.352.848a1.156 1.156 0 0 1-.848.352h-8.4l-2.4 2.4Zm1.89-3.6h8.91v-7.2h-9.6v7.875l.69-.675Z" })));
exports.MessageIcon = MessageIcon;
