"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DiscussionsIcon = exports.AnswerQuestionsIcon = exports.MessageIcon = exports.AttachedIcon = exports.SliderArrowIcon = exports.CloseIcon = exports.AuthenticationBgIcon = exports.UploadIcon = exports.ThumbActiveIcon = exports.DownloadIcon = exports.LocationIcon = exports.StarIcon = exports.SettingIcon = exports.LanguageIcon = exports.SignOutIcon = exports.AccountIcon = exports.ContentIcon = exports.RequestIcon = exports.TVIcon = exports.AchievementsSmallIcon = exports.AchievementsIcon = exports.EditIcon = exports.AboutIcon = exports.TreatmentIcon = exports.MonitorIcon = exports.AssessmentIcon = exports.DosageIcon = exports.SurveyIcon = exports.ThumbIcon = exports.ShareIcon = exports.SortIcon = exports.BellOrangeIcon = exports.BellUnReadIcon = exports.BellIcon = exports.FilterIcon = exports.BannerBg = exports.BookmarkSmallIcon = exports.BookmarkIcon = exports.AuthorIcon = exports.DateIcon = exports.TimeIcon = exports.DurationIcon = exports.ViewsIcon = exports.DropDownArrowIcon = exports.ProfileIcon = exports.NotificationIcon = exports.ShalinaLogo = exports.ApprovalIcon = exports.RightArrowIcon = exports.BronzeIcon = void 0;
exports.CopyClipboardIcon = exports.TickIcon = exports.CrownIcon = exports.TagIcon = exports.ClockIcon = exports.SendIcon = exports.CommunityRequestIcon = exports.ExploreCommunityIcon = void 0;
var bronze_icon_1 = require("./bronze.icon");
Object.defineProperty(exports, "BronzeIcon", { enumerable: true, get: function () { return bronze_icon_1.BronzeIcon; } });
var rightArrow_icon_1 = require("./rightArrow.icon");
Object.defineProperty(exports, "RightArrowIcon", { enumerable: true, get: function () { return rightArrow_icon_1.RightArrowIcon; } });
var approval_icon_1 = require("./approval.icon");
Object.defineProperty(exports, "ApprovalIcon", { enumerable: true, get: function () { return approval_icon_1.ApprovalIcon; } });
var logo_icon_1 = require("./logo.icon");
Object.defineProperty(exports, "ShalinaLogo", { enumerable: true, get: function () { return logo_icon_1.ShalinaLogo; } });
var notification_icon_1 = require("./notification.icon");
Object.defineProperty(exports, "NotificationIcon", { enumerable: true, get: function () { return notification_icon_1.NotificationIcon; } });
var profile_icon_1 = require("./profile.icon");
Object.defineProperty(exports, "ProfileIcon", { enumerable: true, get: function () { return profile_icon_1.ProfileIcon; } });
var dropDownArrow_icon_1 = require("./dropDownArrow.icon");
Object.defineProperty(exports, "DropDownArrowIcon", { enumerable: true, get: function () { return dropDownArrow_icon_1.DropDownArrowIcon; } });
var views_icon_1 = require("./views.icon");
Object.defineProperty(exports, "ViewsIcon", { enumerable: true, get: function () { return views_icon_1.ViewsIcon; } });
var duration_icon_1 = require("./duration.icon");
Object.defineProperty(exports, "DurationIcon", { enumerable: true, get: function () { return duration_icon_1.DurationIcon; } });
var time_icon_1 = require("./time.icon");
Object.defineProperty(exports, "TimeIcon", { enumerable: true, get: function () { return time_icon_1.TimeIcon; } });
var date_icon_1 = require("./date.icon");
Object.defineProperty(exports, "DateIcon", { enumerable: true, get: function () { return date_icon_1.DateIcon; } });
var author_icon_1 = require("./author.icon");
Object.defineProperty(exports, "AuthorIcon", { enumerable: true, get: function () { return author_icon_1.AuthorIcon; } });
var bookmark_icon_1 = require("./bookmark.icon");
Object.defineProperty(exports, "BookmarkIcon", { enumerable: true, get: function () { return bookmark_icon_1.BookmarkIcon; } });
var bookmarkSmall_icon_1 = require("./bookmarkSmall.icon");
Object.defineProperty(exports, "BookmarkSmallIcon", { enumerable: true, get: function () { return bookmarkSmall_icon_1.BookmarkSmallIcon; } });
var bannerBg_icon_1 = require("./bannerBg.icon");
Object.defineProperty(exports, "BannerBg", { enumerable: true, get: function () { return bannerBg_icon_1.BannerBg; } });
var filter_icon_1 = require("./filter.icon");
Object.defineProperty(exports, "FilterIcon", { enumerable: true, get: function () { return filter_icon_1.FilterIcon; } });
var bell_icon_1 = require("./bell.icon");
Object.defineProperty(exports, "BellIcon", { enumerable: true, get: function () { return bell_icon_1.BellIcon; } });
var bellUnRead_icon_1 = require("./bellUnRead.icon");
Object.defineProperty(exports, "BellUnReadIcon", { enumerable: true, get: function () { return bellUnRead_icon_1.BellUnReadIcon; } });
var bellOrange_icon_1 = require("./bellOrange.icon");
Object.defineProperty(exports, "BellOrangeIcon", { enumerable: true, get: function () { return bellOrange_icon_1.BellOrangeIcon; } });
var sort_icon_1 = require("./sort.icon");
Object.defineProperty(exports, "SortIcon", { enumerable: true, get: function () { return sort_icon_1.SortIcon; } });
var share_icon_1 = require("./share.icon");
Object.defineProperty(exports, "ShareIcon", { enumerable: true, get: function () { return share_icon_1.ShareIcon; } });
var thumb_icon_1 = require("./thumb.icon");
Object.defineProperty(exports, "ThumbIcon", { enumerable: true, get: function () { return thumb_icon_1.ThumbIcon; } });
var survey_icon_1 = require("./survey.icon");
Object.defineProperty(exports, "SurveyIcon", { enumerable: true, get: function () { return survey_icon_1.SurveyIcon; } });
var dosage_icon_1 = require("./dosage.icon");
Object.defineProperty(exports, "DosageIcon", { enumerable: true, get: function () { return dosage_icon_1.DosageIcon; } });
var assessment_icon_1 = require("./assessment.icon");
Object.defineProperty(exports, "AssessmentIcon", { enumerable: true, get: function () { return assessment_icon_1.AssessmentIcon; } });
var monitor_icon_1 = require("./monitor.icon");
Object.defineProperty(exports, "MonitorIcon", { enumerable: true, get: function () { return monitor_icon_1.MonitorIcon; } });
var treatment_icon_1 = require("./treatment.icon");
Object.defineProperty(exports, "TreatmentIcon", { enumerable: true, get: function () { return treatment_icon_1.TreatmentIcon; } });
var about_icon_1 = require("./about.icon");
Object.defineProperty(exports, "AboutIcon", { enumerable: true, get: function () { return about_icon_1.AboutIcon; } });
var edit_icon_1 = require("./edit.icon");
Object.defineProperty(exports, "EditIcon", { enumerable: true, get: function () { return edit_icon_1.EditIcon; } });
var achievements_icon_1 = require("./achievements.icon");
Object.defineProperty(exports, "AchievementsIcon", { enumerable: true, get: function () { return achievements_icon_1.AchievementsIcon; } });
var achievementsSmall_icon_1 = require("./achievementsSmall.icon");
Object.defineProperty(exports, "AchievementsSmallIcon", { enumerable: true, get: function () { return achievementsSmall_icon_1.AchievementsSmallIcon; } });
var tv_icon_1 = require("./tv.icon");
Object.defineProperty(exports, "TVIcon", { enumerable: true, get: function () { return tv_icon_1.TVIcon; } });
var request_icon_1 = require("./request.icon");
Object.defineProperty(exports, "RequestIcon", { enumerable: true, get: function () { return request_icon_1.RequestIcon; } });
var content_icon_1 = require("./content.icon");
Object.defineProperty(exports, "ContentIcon", { enumerable: true, get: function () { return content_icon_1.ContentIcon; } });
var account_icon_1 = require("./account.icon");
Object.defineProperty(exports, "AccountIcon", { enumerable: true, get: function () { return account_icon_1.AccountIcon; } });
var signOut_icon_1 = require("./signOut.icon");
Object.defineProperty(exports, "SignOutIcon", { enumerable: true, get: function () { return signOut_icon_1.SignOutIcon; } });
var language_icon_1 = require("./language.icon");
Object.defineProperty(exports, "LanguageIcon", { enumerable: true, get: function () { return language_icon_1.LanguageIcon; } });
var setting_icon_1 = require("./setting.icon");
Object.defineProperty(exports, "SettingIcon", { enumerable: true, get: function () { return setting_icon_1.SettingIcon; } });
var star_icon_1 = require("./star.icon");
Object.defineProperty(exports, "StarIcon", { enumerable: true, get: function () { return star_icon_1.StarIcon; } });
var location_icon_1 = require("./location.icon");
Object.defineProperty(exports, "LocationIcon", { enumerable: true, get: function () { return location_icon_1.LocationIcon; } });
var download_icon_1 = require("./download.icon");
Object.defineProperty(exports, "DownloadIcon", { enumerable: true, get: function () { return download_icon_1.DownloadIcon; } });
var thumbActive_icon_1 = require("./thumbActive.icon");
Object.defineProperty(exports, "ThumbActiveIcon", { enumerable: true, get: function () { return thumbActive_icon_1.ThumbActiveIcon; } });
var upload_icon_1 = require("./upload.icon");
Object.defineProperty(exports, "UploadIcon", { enumerable: true, get: function () { return upload_icon_1.UploadIcon; } });
var authenticationBg_icon_1 = require("./authenticationBg.icon");
Object.defineProperty(exports, "AuthenticationBgIcon", { enumerable: true, get: function () { return authenticationBg_icon_1.AuthenticationBgIcon; } });
var close_icon_1 = require("./close.icon");
Object.defineProperty(exports, "CloseIcon", { enumerable: true, get: function () { return close_icon_1.CloseIcon; } });
var sliderArrow_icon_1 = require("./sliderArrow.icon");
Object.defineProperty(exports, "SliderArrowIcon", { enumerable: true, get: function () { return sliderArrow_icon_1.SliderArrowIcon; } });
var attached_icon_1 = require("./attached.icon");
Object.defineProperty(exports, "AttachedIcon", { enumerable: true, get: function () { return attached_icon_1.AttachedIcon; } });
var message_icon_1 = require("./message.icon");
Object.defineProperty(exports, "MessageIcon", { enumerable: true, get: function () { return message_icon_1.MessageIcon; } });
var answerQuestions_icon_1 = require("./answerQuestions.icon");
Object.defineProperty(exports, "AnswerQuestionsIcon", { enumerable: true, get: function () { return answerQuestions_icon_1.AnswerQuestionsIcon; } });
var discussions_icon_1 = require("./discussions.icon");
Object.defineProperty(exports, "DiscussionsIcon", { enumerable: true, get: function () { return discussions_icon_1.DiscussionsIcon; } });
var exploreCommunity_icon_1 = require("./exploreCommunity.icon");
Object.defineProperty(exports, "ExploreCommunityIcon", { enumerable: true, get: function () { return exploreCommunity_icon_1.ExploreCommunityIcon; } });
var communityRequest_icon_1 = require("./communityRequest.icon");
Object.defineProperty(exports, "CommunityRequestIcon", { enumerable: true, get: function () { return communityRequest_icon_1.CommunityRequestIcon; } });
var send_icon_1 = require("./send.icon");
Object.defineProperty(exports, "SendIcon", { enumerable: true, get: function () { return send_icon_1.SendIcon; } });
var clock_icon_1 = require("./clock.icon");
Object.defineProperty(exports, "ClockIcon", { enumerable: true, get: function () { return clock_icon_1.ClockIcon; } });
var tag_icon_1 = require("./tag.icon");
Object.defineProperty(exports, "TagIcon", { enumerable: true, get: function () { return tag_icon_1.TagIcon; } });
var crown_icon_1 = require("./crown.icon");
Object.defineProperty(exports, "CrownIcon", { enumerable: true, get: function () { return crown_icon_1.CrownIcon; } });
var tick_icon_1 = require("./tick.icon");
Object.defineProperty(exports, "TickIcon", { enumerable: true, get: function () { return tick_icon_1.TickIcon; } });
var copyClipboard_icon_1 = require("./copyClipboard.icon");
Object.defineProperty(exports, "CopyClipboardIcon", { enumerable: true, get: function () { return copyClipboard_icon_1.CopyClipboardIcon; } });
