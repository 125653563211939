"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetOtpDetails = exports.resetSignUpPayload = exports.resetSignInPayload = exports.addSignUpPayload = exports.addSignInPayload = exports.getRegisterationCode = exports.getLoggedInUserPoints = exports.getLoggedInUserLastname = exports.getLoggedInUsername = exports.getEnteredSignUpDetails = exports.getEnteredSignInDetails = exports.getLoggedInUserDetail = exports.getOTPDetails = exports.registrationCode = exports.redirectorApi = exports.validateContactDetail = exports.getAreaOfInterests = exports.getQualificationList = exports.getSpecializationList = exports.validateOTP = exports.signInApi = exports.signUpApi = exports.sendOtpApi = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const constants_1 = require("../../constants");
const api_1 = require("../../../api");
const utils_1 = require("../../utils");
const initialState = {
    qualification: {
        qualificationList: [],
        isError: false,
        errorMessage: null,
    },
    specialization: {
        specializationList: [],
        isError: false,
        errorMessage: null,
    },
    areaOfInterest: {
        ineterestList: [],
        isError: false,
        errorMessage: null,
    },
    sendOtp: {
        statusCode: '',
        status: 200,
        message: null,
        data: {},
    },
    user: {
        statusCode: '',
        status: 200,
        message: null,
        data: {},
    },
    validateOTP: {
        statusCode: '',
        status: 200,
        message: null,
        data: {},
    },
    tempSignInPayload: {
        countryCode: '',
        mobileNumber: '',
        emailId: '',
        mode: '',
    },
    tempSignUpPayload: {
        firstName: '',
        lastName: '',
        countryCode: '',
        mobileNumber: '',
        emailId: '',
        language: '',
        registrationCode: '',
        referralCode: '',
        requestForReferralCode: false,
        mode: '',
        isTermsConditionAccepted: false,
    },
    samlAuthentication: {
        isLoading: false,
        samlAuthentication: {},
    },
    registrationCode: {
        isLoading: false,
        registrationCodeData: {},
    },
    isLoading: false,
};
exports.sendOtpApi = (0, toolkit_1.createAsyncThunk)(`${constants_1.AUTHENTICATION}/${constants_1.SEND_OTP}`, (req) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield (0, api_1.post)(`${constants_1.SEND_OTP_ENDPOINT}?action=${req === null || req === void 0 ? void 0 : req.params}`, req === null || req === void 0 ? void 0 : req.payload);
        return response;
    }
    catch (error) {
        return error;
    }
}));
exports.signUpApi = (0, toolkit_1.createAsyncThunk)(`${constants_1.AUTHENTICATION}/${constants_1.SIGN_UP}`, (req) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield (0, api_1.post)(constants_1.SIGN_UP_ENDPOINT, req);
    return response;
}));
exports.signInApi = (0, toolkit_1.createAsyncThunk)(`${constants_1.AUTHENTICATION}/${constants_1.SIGN_IN}`, (req) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield (0, api_1.post)(constants_1.SIGN_IN_ENDPOINT, req);
        return response;
    }
    catch (error) {
        return error;
    }
}));
exports.validateOTP = (0, toolkit_1.createAsyncThunk)(`${constants_1.AUTHENTICATION}/${constants_1.VALIDATE_OTP}`, (req) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield (0, api_1.post)(constants_1.VALIDATE_OTP_ENDPOINT, req);
        return response;
    }
    catch (error) {
        return error;
    }
}));
exports.getSpecializationList = (0, toolkit_1.createAsyncThunk)(`${constants_1.AUTHENTICATION}/${constants_1.SPECIALIZATION}`, () => __awaiter(void 0, void 0, void 0, function* () {
    const specialization = yield (0, api_1.get)(constants_1.SPECIALIZATION_ENDPOINT);
    return specialization;
}));
exports.getQualificationList = (0, toolkit_1.createAsyncThunk)(`${constants_1.AUTHENTICATION}/${constants_1.QUALIFICATION}`, () => __awaiter(void 0, void 0, void 0, function* () {
    const qualification = yield (0, api_1.get)(constants_1.QUALIFICATION_ENDPOINT);
    return qualification;
}));
exports.getAreaOfInterests = (0, toolkit_1.createAsyncThunk)(`${constants_1.AUTHENTICATION}/${constants_1.AREA_OF_INTEREST}`, () => __awaiter(void 0, void 0, void 0, function* () {
    const areaOfInterest = yield (0, api_1.get)(constants_1.AREA_OF_INTEREST_ENDPOINT);
    return areaOfInterest;
}));
exports.validateContactDetail = (0, toolkit_1.createAsyncThunk)(`${constants_1.AUTHENTICATION}/${constants_1.VALIDATE_CONTACT_DETAIL}`, (req) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield (0, api_1.post)(constants_1.VALIDATE_CONTACT_DETAIL_ENDPOINT, req);
        return response;
    }
    catch (error) {
        return error;
    }
}));
exports.redirectorApi = (0, toolkit_1.createAsyncThunk)(`${constants_1.EBESCO}/${constants_1.REDIRECTOR}`, (payload) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield (0, api_1.get)(`${constants_1.EBSCO_SAML_SESSION_INITIATOR_ENDPOINT}?requestData=${payload === null || payload === void 0 ? void 0 : payload.requestData}`, {
        baseURL: api_1.BASE_URL_NODE,
    });
    return response;
}));
exports.registrationCode = (0, toolkit_1.createAsyncThunk)(`${constants_1.AUTHENTICATION}/${constants_1.REGISTERATION_CODE}`, (body) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield (0, api_1.post)(`${constants_1.REGISTRATION_CODE_ENDPOINT}`, body);
    return response;
}));
const authenticationSlice = (0, toolkit_1.createSlice)({
    name: constants_1.AUTHENTICATION,
    initialState,
    reducers: {
        addSignInPayload(state, action) {
            state.tempSignInPayload = action.payload;
        },
        addSignUpPayload(state, action) {
            state.tempSignUpPayload = action.payload;
        },
        resetSignInPayload(state) {
            state.tempSignInPayload = {
                countryCode: '',
                mobileNumber: '',
                emailId: '',
                mode: '',
            };
        },
        resetSignUpPayload(state) {
            state.tempSignUpPayload = {
                firstName: '',
                lastName: '',
                countryCode: '',
                mobileNumber: '',
                emailId: '',
                language: '',
                registrationCode: '',
                referralCode: '',
                requestForReferralCode: false,
                mode: '',
                isTermsConditionAccepted: false,
            };
        },
        resetOtpDetails(state) {
            state.sendOtp = {
                statusCode: '',
                status: 200,
                message: null,
                data: {},
            };
        },
    },
    extraReducers(builder) {
        // QUALIFICATION API
        builder.addCase(exports.getQualificationList.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(exports.getQualificationList.fulfilled, (state, action) => {
            var _a, _b, _c, _d, _e;
            state.isLoading = false;
            const qualificationList = (_b = (_a = state === null || state === void 0 ? void 0 : state.qualification) === null || _a === void 0 ? void 0 : _a.qualificationList) === null || _b === void 0 ? void 0 : _b.concat((_c = action.payload) === null || _c === void 0 ? void 0 : _c.data);
            const uniqueArray = ((_d = action === null || action === void 0 ? void 0 : action.payload) === null || _d === void 0 ? void 0 : _d.data)
                ? (0, utils_1.removeDuplicates)((_e = action === null || action === void 0 ? void 0 : action.payload) === null || _e === void 0 ? void 0 : _e.data, 'tid')
                : [];
            state.qualification.qualificationList = uniqueArray;
        });
        builder.addCase(exports.getQualificationList.rejected, (state, action) => {
            state.isLoading = false;
        });
        // SPECIALIZATION API
        builder.addCase(exports.getSpecializationList.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(exports.getSpecializationList.fulfilled, (state, action) => {
            var _a, _b, _c, _d, _e;
            state.isLoading = false;
            const specializeList = (_b = (_a = state === null || state === void 0 ? void 0 : state.specialization) === null || _a === void 0 ? void 0 : _a.specializationList) === null || _b === void 0 ? void 0 : _b.concat((_c = action === null || action === void 0 ? void 0 : action.payload) === null || _c === void 0 ? void 0 : _c.data);
            const uniqueArray = ((_d = action === null || action === void 0 ? void 0 : action.payload) === null || _d === void 0 ? void 0 : _d.data)
                ? (0, utils_1.removeDuplicates)((_e = action === null || action === void 0 ? void 0 : action.payload) === null || _e === void 0 ? void 0 : _e.data, 'tid')
                : [];
            state.specialization.specializationList = uniqueArray;
        });
        builder.addCase(exports.getSpecializationList.rejected, (state, action) => {
            state.isLoading = false;
        });
        // AREA OF INTEREST API
        builder.addCase(exports.getAreaOfInterests.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(exports.getAreaOfInterests.fulfilled, (state, action) => {
            var _a, _b, _c, _d, _e;
            state.isLoading = false;
            const interests = (_b = (_a = state.areaOfInterest) === null || _a === void 0 ? void 0 : _a.ineterestList) === null || _b === void 0 ? void 0 : _b.concat((_c = action === null || action === void 0 ? void 0 : action.payload) === null || _c === void 0 ? void 0 : _c.data);
            const uniqueArray = ((_d = action === null || action === void 0 ? void 0 : action.payload) === null || _d === void 0 ? void 0 : _d.data)
                ? (0, utils_1.removeDuplicates)((_e = action.payload) === null || _e === void 0 ? void 0 : _e.data, 'tid')
                : [];
            state.areaOfInterest.ineterestList = uniqueArray;
        });
        builder.addCase(exports.getAreaOfInterests.rejected, (state, action) => {
            state.isLoading = false;
        });
        // Send OTP API
        builder.addCase(exports.sendOtpApi.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(exports.sendOtpApi.fulfilled, (state, action) => {
            state.isLoading = false;
            state.sendOtp = action === null || action === void 0 ? void 0 : action.payload;
        });
        builder.addCase(exports.sendOtpApi.rejected, (state, action) => {
            state.isLoading = false;
        });
        // SIGN UP API
        builder.addCase(exports.signUpApi.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(exports.signUpApi.fulfilled, (state, action) => {
            state.isLoading = false;
            state.user = action === null || action === void 0 ? void 0 : action.payload;
        });
        builder.addCase(exports.signUpApi.rejected, (state, action) => {
            state.isLoading = false;
        });
        // SIGN IN API
        builder.addCase(exports.signInApi.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(exports.signInApi.fulfilled, (state, action) => {
            state.isLoading = false;
            state.user = action === null || action === void 0 ? void 0 : action.payload;
        });
        builder.addCase(exports.signInApi.rejected, (state, action) => {
            state.isLoading = false;
        });
        // VALIDATE OTP API
        builder.addCase(exports.validateOTP.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(exports.validateOTP.fulfilled, (state, action) => {
            state.isLoading = false;
            state.validateOTP = action === null || action === void 0 ? void 0 : action.payload;
        });
        builder.addCase(exports.validateOTP.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(exports.validateContactDetail.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(exports.validateContactDetail.fulfilled, (state, action) => {
            state.isLoading = false;
            state.validateOTP = action === null || action === void 0 ? void 0 : action.payload;
        });
        builder.addCase(exports.validateContactDetail.rejected, (state, action) => {
            state.isLoading = false;
        });
        // Registration Code
        builder.addCase(exports.registrationCode.pending, (state, action) => {
            state.registrationCode.isLoading = true;
        });
        builder.addCase(exports.registrationCode.fulfilled, (state, action) => {
            state.registrationCode.isLoading = false;
            state.registrationCode.registrationCodeData = action === null || action === void 0 ? void 0 : action.payload;
        });
        builder.addCase(exports.registrationCode.rejected, (state, action) => {
            state.registrationCode.isLoading = false;
        });
        //Saml authentication
        builder.addCase(exports.redirectorApi.pending, (state, action) => {
            state.samlAuthentication.isLoading = true;
        });
        builder.addCase(exports.redirectorApi.fulfilled, (state, action) => {
            state.samlAuthentication.isLoading = false;
            state.samlAuthentication.samlAuthentication = action === null || action === void 0 ? void 0 : action.payload;
        });
        builder.addCase(exports.redirectorApi.rejected, (state, action) => {
            state.samlAuthentication.isLoading = false;
        });
    },
});
exports.getOTPDetails = (0, toolkit_1.createSelector)((state) => state.authentication, (authentication) => { var _a; return (_a = authentication === null || authentication === void 0 ? void 0 : authentication.sendOtp) === null || _a === void 0 ? void 0 : _a.data; });
exports.getLoggedInUserDetail = (0, toolkit_1.createSelector)((state) => state.authentication, (authentication) => { var _a; return (_a = authentication === null || authentication === void 0 ? void 0 : authentication.user) === null || _a === void 0 ? void 0 : _a.data; });
exports.getEnteredSignInDetails = (0, toolkit_1.createSelector)((state) => state.authentication, (authentication) => authentication === null || authentication === void 0 ? void 0 : authentication.tempSignInPayload);
exports.getEnteredSignUpDetails = (0, toolkit_1.createSelector)((state) => state.authentication, (authentication) => authentication === null || authentication === void 0 ? void 0 : authentication.tempSignUpPayload);
exports.getLoggedInUsername = (0, toolkit_1.createSelector)((state) => state.authentication, (authentication) => {
    var _a, _b, _c, _d;
    return `${(_b = (_a = authentication === null || authentication === void 0 ? void 0 : authentication.user) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.firstName} ${(_d = (_c = authentication === null || authentication === void 0 ? void 0 : authentication.user) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.lastName}`;
});
exports.getLoggedInUserLastname = (0, toolkit_1.createSelector)((state) => state.authentication, (authentication) => {
    var _a, _b;
    return `${(_b = (_a = authentication === null || authentication === void 0 ? void 0 : authentication.user) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.lastName}`;
});
exports.getLoggedInUserPoints = (0, toolkit_1.createSelector)((state) => state.authentication, (authentication) => {
    var _a, _b;
    return (_b = (_a = authentication === null || authentication === void 0 ? void 0 : authentication.user) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.points;
});
exports.getRegisterationCode = (0, toolkit_1.createSelector)((state) => state.authentication, (authentication) => {
    var _a;
    return (_a = authentication === null || authentication === void 0 ? void 0 : authentication.registrationCode) === null || _a === void 0 ? void 0 : _a.registrationCodeData;
});
_a = authenticationSlice.actions, exports.addSignInPayload = _a.addSignInPayload, exports.addSignUpPayload = _a.addSignUpPayload, exports.resetSignInPayload = _a.resetSignInPayload, exports.resetSignUpPayload = _a.resetSignUpPayload, exports.resetOtpDetails = _a.resetOtpDetails;
exports.default = authenticationSlice.reducer;
