import {
  AppConstant,
  COMMUNITIES_API_CATEGORY_TYPES,
  ImageConstant,
  SHOW_MY_COMMUNITIES_COUNT,
  translate
} from '@shalina-app/shared';

import { MyCommunitiesProps } from '..';
import { ButtonComponent } from '../../../../components';
import {
  getImageSrc,
  retrieveData,
  STORAGE_CONSTANTS
} from '../../../../utils';
import styles from './MyCommunities.module.scss';

export const MyCommunities: React.FC<MyCommunitiesProps> = ({
  communitiesList,
  handleCommunityClick,
  handleCommunityViewAllClick,
  viewAllVisible,
  handleJoinCommunityClick
}) => {
  const t = translate();
  const isLoggedIn: boolean = retrieveData(STORAGE_CONSTANTS.IS_LOGGED_IN);

  const { CrownIcon } = ImageConstant.SVG_ICONS;
  const isExpert = false;
  return (
    <>
      <div className={styles.Wrapper}>
        <div className={styles.head}>
          <h4>{t(AppConstant.COMMUNITY.MY_COMMUNITIES_HEADING)}</h4>
          {communitiesList && viewAllVisible ? (
            <button
              onClick={() =>
                handleCommunityViewAllClick(
                  COMMUNITIES_API_CATEGORY_TYPES.MY_COMMUNITY
                )
              }
            >
              {t(AppConstant.COMMUNITY.COMMUNITIES_VIEW_ALL_CTA)}
            </button>
          ) : null}
        </div>
        {isLoggedIn && communitiesList?.length > 0 ? (
          <div className={styles.list}>
            {communitiesList?.map(
              (community: any, index: number) =>
                index < SHOW_MY_COMMUNITIES_COUNT && (
                  <div className={`${styles.item}`}>
                    <div className={styles.iconTitleWrap}>
                      <div className={styles.iconWrapper}>
                        <img
                          className={styles.icon}
                          src={
                            getImageSrc(community?.field_community_icon) ||
                            ImageConstant.SOURCE.communityIcon
                          }
                          alt='community img'
                          onError={(e) =>
                            ((e.target as HTMLInputElement).src =
                              ImageConstant.SOURCE.communityIcon)
                          }
                        />
                      </div>
                      <h5
                        className={styles.title}
                        onClick={() => handleCommunityClick(community?.id)}
                      >
                        {community?.title}
                      </h5>
                    </div>
                    {community?.isKol && (
                      <div className={styles.crownIcon}>
                        <CrownIcon />
                      </div>
                    )}
                  </div>
                )
            )}
          </div>
        ) : (
          <div className={styles.welcomePage}>
            <div className={styles.welcomeWrapper}>
              <img src={ImageConstant.SOURCE.welcomeToCommunities} alt='img' />
              <p>{t(AppConstant.COMMUNITY.JOIN_COMMUNITY_TEXT)} </p>
              <div className={styles.cta}>
                <ButtonComponent
                  variant='outlined'
                  buttonText={t(AppConstant.COMMUNITY.EXPLORE_COMMUNITIES_CTA)}
                  onPress={handleJoinCommunityClick}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
