"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNotifications = exports.updatePointsWithNotification = exports.markAsReadNotification = exports.getNotificationCount = exports.getNotificationList = exports.sendNotification = exports.registerNotification = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const constants_1 = require("../../constants");
const api_1 = require("../../../api");
const common_1 = require("../common");
const initialState = {
    id: '',
    data: { totalCount: 0, data: [] },
    status: common_1.ApiStatusValues.idle,
    error: null,
    totalCount: 0,
    isLoading: false,
    notificationList: {},
    sendNotification: [],
    updatePoints: {},
};
const notificationSlice = (0, toolkit_1.createSlice)({
    name: constants_1.REGISTER_NOTIFICATION,
    initialState,
    reducers: {},
    extraReducers(builder) {
        /**
         * Notification API
         */
        builder.addCase(exports.registerNotification.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.status = common_1.ApiStatusValues.loading;
            state.isLoading = true;
        });
        builder.addCase(exports.registerNotification.fulfilled, (state, action) => {
            // Add logic if any when API state is fulfilled or data is received from API
            state.status = common_1.ApiStatusValues.success;
            state.isLoading = false;
        });
        builder.addCase(exports.registerNotification.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.status = common_1.ApiStatusValues.error;
            state.isLoading = false;
        });
        /**
         * Get Notification List
         */
        builder.addCase(exports.getNotificationList.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.isLoading = true;
        });
        builder.addCase(exports.getNotificationList.fulfilled, (state, action) => {
            state.isLoading = false;
            state.notificationList = action === null || action === void 0 ? void 0 : action.payload;
        });
        builder.addCase(exports.getNotificationList.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.isLoading = false;
            state.status = common_1.ApiStatusValues.error;
        });
        /**
         * Get Notification Count
         */
        builder.addCase(exports.getNotificationCount.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.status = common_1.ApiStatusValues.loading;
            state.isLoading = true;
        });
        builder.addCase(exports.getNotificationCount.fulfilled, (state, action) => {
            // Add logic if any when API state is fulfilled or data is received from API
            state.status = common_1.ApiStatusValues.success;
            // Add any fetched posts to the array
            state.totalCount = action.payload;
            state.isLoading = false;
        });
        builder.addCase(exports.getNotificationCount.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.status = common_1.ApiStatusValues.error;
            state.isLoading = false;
        });
        /**
         * Mark as Read Notification
         */
        builder.addCase(exports.markAsReadNotification.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.status = common_1.ApiStatusValues.loading;
            state.isLoading = true;
        });
        builder.addCase(exports.markAsReadNotification.fulfilled, (state, action) => {
            // Add logic if any when API state is fulfilled or data is received from API
            state.status = common_1.ApiStatusValues.success;
            state.isLoading = false;
            // Add any fetched posts to the array
            // state.totalCount = action.payload;
        });
        builder.addCase(exports.markAsReadNotification.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.status = common_1.ApiStatusValues.error;
            state.isLoading = false;
        });
        /**
         * Send Notification
         */
        builder.addCase(exports.sendNotification.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(exports.sendNotification.fulfilled, (state, action) => {
            state.isLoading = false;
            state.sendNotification = action.payload;
        });
        builder.addCase(exports.sendNotification.rejected, (state, action) => {
            state.isLoading = false;
        });
        /**
         * Update points with Notification
         */
        builder.addCase(exports.updatePointsWithNotification.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(exports.updatePointsWithNotification.fulfilled, (state, action) => {
            state.isLoading = false;
            state.updatePoints = action.payload;
        });
        builder.addCase(exports.updatePointsWithNotification.rejected, (state, action) => {
            state.isLoading = false;
        });
    },
});
exports.registerNotification = (0, toolkit_1.createAsyncThunk)(`${constants_1.NOTIFICATION}/${constants_1.REGISTER_NOTIFICATION}`, (notification) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const notificationRegister = yield (0, api_1.post)(`${constants_1.REGISTER_NOTIFICATION_ENDPOINT}`, notification, {
            baseURL: api_1.BASE_URL_NODE,
        });
        return notificationRegister;
    }
    catch (error) {
        return error;
    }
}));
exports.sendNotification = (0, toolkit_1.createAsyncThunk)(`${constants_1.NOTIFICATION}/${constants_1.SEND_NOTIFICATION}`, (sendNotificationProps) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const sendNotification = yield (0, api_1.post)(constants_1.SEND_NOTIFICATION_ENDPOINT, sendNotificationProps);
        return sendNotification;
    }
    catch (error) {
        return error;
    }
}));
exports.getNotificationList = (0, toolkit_1.createAsyncThunk)(`${constants_1.NOTIFICATION}/${constants_1.GET_NOTIFICATION}`, (getNotificationArgs) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const notificationList = yield (0, api_1.get)(`${constants_1.LIST_NOTIFICATION_ENDPOINT}/?take=${getNotificationArgs.take}&skip=${getNotificationArgs.skip}`, {
            baseURL: api_1.BASE_URL_NODE,
        });
        return notificationList;
    }
    catch (error) {
        return error;
    }
}));
exports.getNotificationCount = (0, toolkit_1.createAsyncThunk)(`${constants_1.NOTIFICATION}/${constants_1.GET_COUNT_NOTIFICATION}`, () => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const notificationCount = yield (0, api_1.get)(`${constants_1.GET_NOTIFICATION_COUNT}`, {
        baseURL: api_1.BASE_URL_NODE,
    });
    return (_a = notificationCount === null || notificationCount === void 0 ? void 0 : notificationCount.data) === null || _a === void 0 ? void 0 : _a.totalCount;
}));
exports.markAsReadNotification = (0, toolkit_1.createAsyncThunk)(`${constants_1.NOTIFICATION}/${constants_1.MARK_NOTIFICATION}`, (notificationID) => __awaiter(void 0, void 0, void 0, function* () {
    const markNotification = yield (0, api_1.put)(`${api_1.BASE_URL_NODE}${constants_1.MARK_READ_NOTIFICATION_ENDPOINT}${notificationID}`, {});
    return markNotification;
}));
exports.updatePointsWithNotification = (0, toolkit_1.createAsyncThunk)(`${constants_1.NOTIFICATION}/${constants_1.UPDATE_POINTS}`, (updateNotificationProps) => __awaiter(void 0, void 0, void 0, function* () {
    const updatePoints = yield (0, api_1.post)(constants_1.UPDATE_POINTS_ENDPOINT, updateNotificationProps);
    return updatePoints;
}));
exports.getNotifications = (0, toolkit_1.createSelector)((state) => state.notification, (notification) => { var _a; return (_a = notification === null || notification === void 0 ? void 0 : notification.notificationList) === null || _a === void 0 ? void 0 : _a.data; });
exports.default = notificationSlice.reducer;
