import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  ImageConstant,
  useAppDispatch,
  fetchRequestList,
  useAppSelector,
  AppConstant,
  translate,
  RequestStatusType,
  fetchRequestDetails,
  REQUEST_STATUS,
  updateRequestListByType,
  getRequestListTabContentByType,
} from '@shalina-app/shared';

import {
  RequestCardsComponent,
  RequestDetailsComponent,
} from '../../components';
import styles from './requests.module.scss';
import {
  BarLoader,
  InfiniteScrollComponent,
  Toast,
} from '../../../../components';
import {
  IRequestFilterOptions,
  IRequestStatus,
  RequestsPagePropsType,
} from './requests.interface';
import { DEFAULT_SKIP, DEFAULT_TAKE } from '../../../../app.constants';
import { IToastBody } from '../../../Header';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const RequestsPage: React.FC<RequestsPagePropsType> = ({ getState }) => {
  const dispatch = useAppDispatch();
  const t = translate();

  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  /* modify and revoke popup start */
  const [modifyPopup, setModifyPopup] = useState(false);
  const [activeFilter, setActiveFilter] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [skip, setSkip] = useState(0);
  const [toggleRequests, setToggleRequests] = useState(
    getState?.showDetailsPage ? false : true
  );
  const [reqStatusName, setReqStatusName] = useState('');
  const [requestStatusOptions, setRequestStatusOptions] = useState<
    IRequestStatus[]
  >([]);
  const [filterOptions, setFilterOptions] = useState<IRequestFilterOptions>({
    status: activeFilter,
    skip: skip,
    take: DEFAULT_TAKE,
  });
  const [details, setDetails] = useState<any>(null);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [requestDetailsId, setRequestDetailsId] = useState('');
  const [dataList, setDataList] = useState<any>([]);
  const [updateDataList, setUpdateDataList] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastSubMessage, setToastSubMessage] = useState('');
  const [requestStatusUniqueId, setRequestStatusUniqueId] = useState('');
  const [dataFromStore, setDataFromStore] = useState(true);
  const [initialApiCall, setInitialApiCall] = useState(true);

  const requestStatusSelector = useAppSelector(RequestStatusType);
  const isRequestListLoading = useAppSelector(
    (state) => state?.request?.requestlistContent?.isRequestListLoading
  );
  const requestListByType = useAppSelector(
    getRequestListTabContentByType(activeFilter)
  );

  // effects
  useEffect(() => {
    setRefresh(true);
    setDataFromStore(true);
  }, []);

  useEffect(() => {
    if (refresh) {
      toggleRequests ? loadAPI() : loadApiRequestDetails(getState?.nid);
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    if (requestStatusOptions?.length && !activeFilter) {
      const open = requestStatusOptions.find(
        (e: any) => e?.field_unique_code === REQUEST_STATUS.OPEN
      );
      setRequestStatusUniqueId(open ? open.field_unique_code : '');
      setReqStatusName(open?.label ? open?.label : '');
      setActiveFilter(open?.value ? open?.value : '');
      setFilterOptions((prev) => ({
        ...prev,
        status: open?.value ? open?.value : '',
      }));
      // setRefresh(true);
    }
  }, [requestStatusOptions]);

  useEffect(() => {
    if (requestStatusSelector?.length) {
      setRequestStatusOptions(requestStatusSelector);
    }
  }, [requestStatusSelector]);

  useEffect(() => {
    filterReset();
  }, [activeFilter]);

  useEffect(() => {
    if (updateDataList) {
      filterReset();
    }
  }, [updateDataList]);

  // API Calls
  const loadAPI = async () => {
    setIsLoading(true);
    if (activeFilter) {
      const response = (await dispatch(
        fetchRequestList(filterOptions)
      ).unwrap()) as any;
      if (
        response &&
        response?.status === 'SUCCESS' &&
        response?.statusCode === 200 &&
        response?.data
      ) {
        paginationSet(response);
      }
      setIsLoading(false);
      setDataFromStore(false);
      setInitialApiCall(false);
    }
  };

  const loadRequestStatus = async () => {
    try {
      setIsLoading(true);
      // if (!requestStatusOptions?.length) {
      setRefresh(false);
      // }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchMoreData = () => {
    if (dataList?.length >= totalDataCount) {
      setHasMore(false);
      return;
    }
    setSkip(skip + DEFAULT_SKIP);
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      skip: skip + DEFAULT_SKIP,
      take: DEFAULT_TAKE,
    }));
    setRefresh(true);
  };

  const paginationSet = (response: any) => {
    const data = response?.data;
    const totalCount = response?.count;
    setTotalDataCount(totalCount);
    if (refresh) {
      // if (totalCount <= dataList?.length + response?.data?.length) {
      //   setHasMore(false);
      // } else {
      //   setHasMore(true);
      // }
      if (updateDataList) {
        setDataList(data);
        setUpdateDataList(false);
        const payload = {
          key: activeFilter,
          dataToStore: data,
        };
        dispatch(updateRequestListByType(payload));
      } else if (data) {
        setDataList((prevOptions: any) => [...prevOptions, ...data]);
        const payload = {
          key: activeFilter,
          dataToStore: [...dataList, ...data],
        };
        dispatch(updateRequestListByType(payload));
      }
      setRefresh(false);
    }
  };

  const loadApiRequestDetails = async (id: string) => {
    try {
      setIsLoading(true);
      const response = (await dispatch(
        fetchRequestDetails(id)
      ).unwrap()) as any;
      if (
        response &&
        response?.status === 'SUCCESS' &&
        response?.statusCode === 200 &&
        response?.data
      ) {
        setToggleRequests(false);
        setDetails(response?.data[0]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const filterReset = () => {
    setHasMore(true);
    setSkip(0);
    setFilterOptions({
      status: activeFilter,
      skip: 0,
      take: DEFAULT_TAKE,
    });
    setRefresh(true);
  };

  const handleSubmit = () => {
    setRefresh(true);
  };

  const handleModifyClose = () => {
    setModifyPopup(false);
  };

  const handleRevoke = () => {
    // setUpdateDataList(true);
  };

  const handleTitleClick = (id: string) => {
    loadApiRequestDetails(id);
  };

  const handleBackClick = (toast?: IToastBody) => {
    setToggleRequests(true);
    setDataList([]);
    filterReset();
    if (toast) {
      handleToast(toast);
    }
    setDataFromStore(true);
  };

  const handleFilterClick = (e: any) => {
    setActiveFilter(e.value);
    setReqStatusName(e.label);
    setRequestStatusUniqueId(e.field_unique_code);
    setDataList([]);
    setDataFromStore(true);
  };

  const handleSuccessClose = () => {
    setUpdateDataList(true);
    setHasMore(true);
  };

  const handleToast = (body: IToastBody) => {
    setShowToast(body.showToast);
    setIsSuccess(body.isSuccess);
    setToastMessage(body.toastMainMessage);
    setUpdateDataList((prev) => !prev);
    setHasMore(true);
  };

  const handleToastClose = () => {
    setShowToast(false);
    setToastMessage('');
  };
  /* modify and revoke popup end */

  const prefetchedData = dataFromStore ? requestListByType : dataList;
  return (
    <>
      <div className={styles.requestTabs}>
        <div className={styles.loaderContainer}>
          {isLoading && (
            <div className={styles.loaderWrapper}>
              <BarLoader />
            </div>
          )}
        </div>
        <div className={styles.custTabContent}>
          {toggleRequests ? (
            <>
              {requestStatusOptions?.length > 0 && (
                <div className={styles.statusCtas}>
                  {requestStatusOptions?.length &&
                    requestStatusOptions.map((elements) => (
                      <button
                        key={elements.value}
                        className={
                          elements.value === activeFilter ? styles.active : ''
                        }
                        onClick={() => handleFilterClick(elements)}
                      >
                        {`${elements.label}`}
                      </button>
                    ))}
                </div>
              )}
              {
                prefetchedData?.length > 0 ? (
                  <>
                    <div className={styles.requestListWrapper}>
                      <InfiniteScrollComponent
                        dataLength={prefetchedData?.length}
                        fetchMoreData={fetchMoreData}
                        hasMore={hasMore}
                        loader={prefetchedData?.length >= DEFAULT_TAKE}
                        // scrollThreshold={'1000px'}
                      >
                        <div className={styles.requestList}>
                          {prefetchedData?.length &&
                            prefetchedData?.map((item: any) => (
                              <RequestCardsComponent
                                requestStatusUniqueId={requestStatusUniqueId}
                                dataItem={item}
                                handleTitleClick={handleTitleClick}
                                handleSubmit={handleSubmit}
                                handleModify={handleModifyClose}
                                handleCloseRevoke={handleRevoke}
                                hadleSuccessClose={handleSuccessClose}
                                handleToast={handleToast}
                              />
                            ))}
                        </div>
                      </InfiniteScrollComponent>
                    </div>
                  </>
                ) : isRequestListLoading ? (
                  <></>
                ) : (
                  <div className={styles.emptyBookmark}>
                    <img
                      src={ImageConstant.SOURCE.emptyContent}
                      alt='empty bookmark'
                    />
                    {requestStatusUniqueId &&
                      (requestStatusUniqueId === REQUEST_STATUS.OPEN ? (
                        <h4 className={styles.title}>
                          {t(AppConstant.COMMON.OPEN_EMPTY_MSG)}
                        </h4>
                      ) : requestStatusUniqueId === REQUEST_STATUS.REVOKE ? (
                        <h4 className={styles.title}>
                          {t(AppConstant.COMMON.REVOKE_EMPTY_MSG)}
                        </h4>
                      ) : requestStatusUniqueId === REQUEST_STATUS.INPROGRESS ? (
                        <h4 className={styles.title}>
                          {t(AppConstant.COMMON.REQUEST_INPROGRESS_EMPTY_MSG)}
                        </h4>
                      ) : (
                        <h4 className={styles.title}>
                          {t(AppConstant.COMMON.CLOSE_EMPTY_MSG)}
                        </h4>
                      ))}
                  </div>
                )
              }
            </>
          ) : (
            <RequestDetailsComponent
              id={requestDetailsId}
              details={details}
              handleBackClick={handleBackClick}
            />
          )}
          {/* </CustomTabPanel> */}

          {/* <CustomTabPanel value={value} index={1}>
						<RequestedContentPage />
					</CustomTabPanel> */}
        </div>
      </div>
      <Toast
        showToast={showToast}
        isSuccess={isSuccess}
        message={toastMessage}
        subMessage={toastSubMessage}
        handleToastClose={handleToastClose}
      />
    </>
  );
};
