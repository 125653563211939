import React from 'react';
import ReactDOM from 'react-dom/client';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import {
  initLocalization,
  LOCALIZATION_BACKEND_ENABLED,
  BLOB_BASE_URL,
  BLOB_FILES_CONTAINER_PARAMS,
  store
} from '@shalina-app/shared';
import { Provider } from 'react-redux';

import { reactPlugin } from './applicationInsight';
import './index.scss';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

initLocalization(
  LOCALIZATION_BACKEND_ENABLED,
  BLOB_BASE_URL,
  BLOB_FILES_CONTAINER_PARAMS
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AppInsightsContext.Provider value={reactPlugin}>
        <App />
      </AppInsightsContext.Provider>
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
