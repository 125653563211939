import { useEffect, useMemo, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {
  addOrUpdateUserGamificationPoints,
  AppConstant,
  GAMIFICATION_TYPE_UNIQUE_CODE,
  FIELD_ACTIONS_MASTER,
  getAzureFile,
  ImageConstant,
  isDownloadDisable,
  translate,
  updateBookmark,
  useAppDispatch,
  useAppSelector,
  logEvent,
  CUSTOM_ANALYTICS_EVENT_NAMES,
  GAMIFICATION_ENABLED,
  PlatformNames,
  updateLikeContent,
  updateUserRecognition,
  handleRecognitionPopup,
  RECOGNITION_MESSAGE_TEXT,
  ShareModules,
  getAnalyticsUserDetails,
  getLoggedInUserInfo,
  GAMIFICATION_ACTION_ID,
  sendNotification,
  getNotificationActionList,
} from '@shalina-app/shared';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from 'react-share';

import { Toast } from '../../../..';
import { appInsights } from '../../../../../applicationInsight';
import {
  extractEncodeAndCreateLink,
  retrieveData,
  STORAGE_CONSTANTS,
} from '../../../../../utils';
import { ShareSectionInterfaceProps } from './shareSection.interface';
import styles from './shareSection.module.scss';
import { useParams } from 'react-router-dom';

const {
  BookmarkIcon,
  ThumbIcon,
  ShareIcon,
  DownloadIcon,
  ThumbActiveIcon,
  CopyClipboardIcon,
} = ImageConstant.SVG_ICONS;

export const ShareSection: React.FC<ShareSectionInterfaceProps> = ({
  isBookmarked,
  isLiked,
  fieldActions,
  fileDownloadUrl,
  title,
  contentId,
  fileUrl,
  content,
}) => {
  const t = translate();
  const dispatch = useAppDispatch();
  const params = useParams();
  const userDetails = useAppSelector(getLoggedInUserInfo);
  const user = retrieveData(STORAGE_CONSTANTS.USER);
  const isLoggedIn: boolean = retrieveData(STORAGE_CONSTANTS.IS_LOGGED_IN);
  const notificationList = useAppSelector(getNotificationActionList);

  const [showToast, setShowToast] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastSubMessage, setToastSubMessage] = useState('');
  const [loader, setLoader] = useState(false);
  const [isShareDisabled, setIsShareDisabled] = useState(false);
  const [isDownloadDisabled, setIsDownloadDisabled] = useState(false);
  const [isLikeDisabled, setIsLikeDisabled] = useState(false);
  const [bookmarked, setBookmarked] = useState(false);
  const [liked, setLiked] = useState(false);
  const [reload, setReload] = useState(false);
  const [showShare, setShowShare] = useState(false);
  // const [accessUrl, setAccessUrl] = useState(fileDownloadUrl);

  const userObj = userDetails || user;
  const userInfo = getAnalyticsUserDetails(userObj);

  useEffect(() => {
    setBookmarked(isBookmarked);
  }, [isBookmarked]);

  useEffect(() => {
    setLiked(isLiked);
  }, [isLiked]);

  const getFileNameFromUrl = (url: string) => {
    const parts = url?.split('/');
    const lastPart = parts[parts?.length - 1];

    //to remove query params
    const fileName = lastPart?.split('?')[0];

    return fileName?.replace(/%20/g, ' ') || '';
  };

  const azureReadFileResponse: any = useAppSelector(getAzureFile);

  const handleDownload = async (e: any) => {
    if (!isDownloadDisabled) {
      setLoader(true);
      if (fileDownloadUrl) {
        try {
          const response = await fetch(fileDownloadUrl);
          const blob = await response.blob();
          let fileExtension;
          switch (blob?.type) {
            case 'application/pdf':
              fileExtension = '.pdf';
              break;
            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
              fileExtension = '.pptx';
              break;
            case 'application/vnd.ms-powerpoint':
              fileExtension = '.ppt'; // Use .ppt for older PowerPoint formats
              break;
            case 'video/mp4':
              fileExtension = '.mp4';
              break;
            default:
              fileExtension = '.download';
          }
          // Create a temporary anchor element
          const anchor = document.createElement('a');
          anchor.href = window.URL.createObjectURL(blob);

          const fileName =
            getFileNameFromUrl(fileUrl) || `${title}${fileExtension}`;

          // Set the Content-Disposition header to force download
          anchor.setAttribute('download', fileName); // You can customize the file name

          document.body.appendChild(anchor);
          anchor.click();
          setLoader(false);
          setShowToast(true);
          setIsSuccess(true);
          setToastMessage(
            t(AppConstant.KNOWLEDGE_HUB_PAGE.DOWNLOAD_SUCCESSFULLY)
          );

          if (isLoggedIn && contentId && title && user) {
            logEvent(
              appInsights,
              'custom',
              CUSTOM_ANALYTICS_EVENT_NAMES.USER_DOWNLOAD_CONTENT,
              {
                contentId: contentId,
                contentTitle: title,
                userEmail: userInfo?.emailId,
                userMobile: userInfo?.mobile,
                userName: userInfo?.name,
                userQualification: userInfo?.qualification,
                userInterest: userInfo?.areaOfInterest,
                userSpecialization: userInfo?.specialization,
                userCountryOfPractice: userInfo?.countryOfPractice,
                userPreferredLanguage: userInfo?.preferredLanguage,
                platform: PlatformNames.WEB,
              }
            );
          }
          document.body.removeChild(anchor);
        } catch (error) {
          setLoader(false);
          setShowToast(true);
          setIsSuccess(false);
          setToastMessage(t(AppConstant.KNOWLEDGE_HUB_PAGE.DOWNLOAD_FAILED));
          setToastSubMessage(
            t(AppConstant.KNOWLEDGE_HUB_PAGE.DOWNLOAD_FAILED_SUB_MESSAGE)
          );
        }
      } else {
        setLoader(false);
        setShowToast(true);
        setIsSuccess(false);
        setToastMessage(t(AppConstant.KNOWLEDGE_HUB_PAGE.DOWNLOAD_FAILED));
        setToastSubMessage(
          t(AppConstant.KNOWLEDGE_HUB_PAGE.DOWNLOAD_NO_FILE_AVAILABLE)
        );
      }
    }
  };

  const sendNotificationForMilestoneUpdate = async () => {
    const action = notificationList?.find(
      (item: any) =>
        item?.field_unique_code === GAMIFICATION_ACTION_ID.UPDATE_MILESTONE
    )?.title;

    const req = {
      action: action,
      device: 'web',
    };
    // Hide Continue where you left feature
    await dispatch(sendNotification(req));
  };

  const onShareSuccess = async () => {
    if (contentId && isLoggedIn && GAMIFICATION_ENABLED) {
      const payload = {
        field_unique_code: GAMIFICATION_TYPE_UNIQUE_CODE.SHARE_CONTENT,
      };
      checkRecognition();
      const response: any = await dispatch(addOrUpdateUserGamificationPoints(payload)).unwrap();
      if(response && response?.info) {
        sendNotificationForMilestoneUpdate()
      }
    }
  };

  const checkRecognition = async () => {
    if (contentId) {
      const response = await dispatch(
        updateUserRecognition({
          contentId: contentId,
          share: 1,
        })
      ).unwrap();
      if (
        response &&
        response?.status === 'SUCCESS' &&
        response?.statusCode === 200 &&
        response?.message === RECOGNITION_MESSAGE_TEXT.SHARE_MASTER &&
        response?.data
      ) {
        const obj = {
          showRecognitionPopup: true,
          recognitionData: response?.data,
        };
        dispatch(handleRecognitionPopup(obj));
      } else {
        const obj = {
          showRecognitionPopup: false,
          recognitionData: {},
        };
        dispatch(handleRecognitionPopup(obj));
      }
    }
  };

  const handleBookmarkClick = () => {
    if (isLoggedIn && contentId) {
      if (title && user) {
        logEvent(
          appInsights,
          'custom',
          CUSTOM_ANALYTICS_EVENT_NAMES.USER_BOOKMARK_CONTENT,
          {
            contentId: contentId,
            contentTitle: title,
            userEmail: userInfo?.emailId,
            userMobile: userInfo?.mobile,
            userName: userInfo?.name,
            userQualification: userInfo?.qualification,
            userInterest: userInfo?.areaOfInterest,
            userSpecialization: userInfo?.specialization,
            userCountryOfPractice: userInfo?.countryOfPractice,
            userPreferredLanguage: userInfo?.preferredLanguage,
            platform: PlatformNames.WEB,
          }
        );
      }
      const payload = {
        id: contentId,
      };
      dispatch(updateBookmark(payload) as any);
      setBookmarked(!bookmarked);
    }
  };

  const handleToastClose = () => {
    setShowToast(false);
  };

  const handleLikeContent = () => {
    if (isLoggedIn && contentId) {
      if (title && user) {
        logEvent(
          appInsights,
          'custom',
          CUSTOM_ANALYTICS_EVENT_NAMES.USER_LIKED_CONTENT,
          {
            contentId: contentId,
            contentTitle: title,
            userEmail: userInfo?.emailId,
            userMobile: userInfo?.mobile,
            userName: userInfo?.name,
            userQualification: userInfo?.qualification,
            userInterest: userInfo?.areaOfInterest,
            userSpecialization: userInfo?.specialization,
            userCountryOfPractice: userInfo?.countryOfPractice,
            userPreferredLanguage: userInfo?.preferredLanguage,
            platform: PlatformNames.WEB,
          }
        );
      }
      const payload = {
        id: contentId,
      };
      dispatch(updateLikeContent(payload as any) as any);
      setLiked(!liked);
    }
  };

  useEffect(() => {
    if (!isLoggedIn || !fileDownloadUrl) {
      setIsDownloadDisabled(true);
    } else {
      setIsDownloadDisabled(false);
    }
    if (!isLoggedIn) {
      setIsShareDisabled(true);
      setIsLikeDisabled(true);
    } else {
      setIsShareDisabled(false);
      setIsLikeDisabled(false);
    }
  }, [isLoggedIn, fileDownloadUrl]);

  const getShareableLink = useMemo(() => {
    const { pageName, contentTypeId, contentId } = params;
    const baseUrl = window.location.origin;
    const link = `${baseUrl}/share?moduleName=${pageName}&contentType=${contentTypeId}&contentId=${contentId}`;

    const userName = user?.firstName + ' ' + user?.lastName;

    const contentMsg = `${t(AppConstant.PROFILE.HI_TEXT)}, ${userName} ${t(
      AppConstant.PROFILE.SHARE_CONTENT_MSG
    )} ${content?.field_select_article_type} ${t(
      AppConstant.PROFILE.CONTENT_TEXT
    )} "${content?.title}" ${t(AppConstant.PROFILE.CLICK_TEXT)} ${link} ${t(
      AppConstant.PROFILE.SHARE_CONTENT_REMAINING_MSG
    )}`;
    return `${contentMsg}`;
  }, [content, user]);

  const handleCopyButton = () => {
    const { pageName, contentTypeId, contentId } = params;
    const baseUrl = window.location.origin;
    const link = `${baseUrl}/share?moduleName=${pageName}&contentType=${contentTypeId}&contentId=${contentId}`;

    navigator.clipboard.writeText(link);
    setShowToast(true);
    setIsSuccess(true);
    setToastMessage(t(AppConstant.COMMON.LINK_COPY_MESSAGE));
  };

  const handleShareClick = () => {
    setShowShare((prev) => !prev);
    if (isLoggedIn && contentId && title && user) {
      logEvent(
        appInsights,
        'custom',
        CUSTOM_ANALYTICS_EVENT_NAMES.USER_SHARE_CONTENT,
        {
          contentId: contentId,
          contentTitle: title,
          userEmail: userInfo?.emailId,
          userMobile: userInfo?.mobile,
          userName: userInfo?.name,
          userQualification: userInfo?.qualification,
          userInterest: userInfo?.areaOfInterest,
          userSpecialization: userInfo?.specialization,
          userCountryOfPractice: userInfo?.countryOfPractice,
          userPreferredLanguage: userInfo?.preferredLanguage,
          platform: PlatformNames.WEB,
          moduleName: ShareModules.CONTENT,
        }
      );
    }
  };

  const isDownloadActionDisable = fieldActions
    ? isDownloadDisable(content, FIELD_ACTIONS_MASTER.WEB_DOWNLOAD)
    : true;

  return (
    <div className={styles.shareWrapper}>
      <div className={styles.shareWrap}>
        {!liked ? (
          <button
            className={`${
              isLikeDisabled || !isLoggedIn ? styles.disabled : styles.active
            }`}
            disabled={isLikeDisabled || !isLoggedIn}
          >
            <ThumbIcon onClick={handleLikeContent} />
          </button>
        ) : (
          <button
            className={styles.likeActive}
            disabled={isLikeDisabled || !isLoggedIn}
          >
            <ThumbActiveIcon />
          </button>
        )}
        <button
          className={`${isShareDisabled || !isLoggedIn ? styles.disabled : ''}`}
          disabled={isShareDisabled || !isLoggedIn}
        >
          <ShareIcon onClick={handleShareClick} />
        </button>
        {
          <button
            className={`${
              isDownloadActionDisable || isDownloadDisabled || !isLoggedIn
                ? styles.disabled
                : styles.active
            }`}
            disabled={
              isDownloadActionDisable || isDownloadDisabled || !isLoggedIn
            }
          >
            <DownloadIcon onClick={handleDownload} />
            {loader && (
              <span className={styles.downloadLoader}>
                <CircularProgress
                  size={20}
                  thickness={4}
                  sx={{
                    color: 'var(--secondaryOrange)',
                  }}
                />
              </span>
            )}
          </button>
        }
        <button
          className={`${!isLoggedIn ? styles.disabled : ''} ${
            bookmarked && styles.active
          }`}
        >
          <BookmarkIcon onClick={handleBookmarkClick} />
        </button>
      </div>
      {showShare && (
        <div className={styles.shareList}>
          <div className={styles.shareListIcon}>
            <span className={styles.copyIcon}>
              <CopyClipboardIcon onClick={handleCopyButton} />
            </span>
          </div>
          <div className={styles.shareListIcon}>
            <FacebookShareButton
              url={getShareableLink}
              className='Demo__some-network__share-button'
              onShareWindowClose={onShareSuccess}
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
          </div>
          <div className={styles.shareListIcon}>
            <TwitterShareButton
              url={getShareableLink}
              className='Demo__some-network__share-button'
              onShareWindowClose={onShareSuccess}
            >
              <XIcon size={32} round />
            </TwitterShareButton>
          </div>
          <div className={styles.shareListIcon}>
            <WhatsappShareButton
              url={getShareableLink}
              separator=''
              className='Demo__some-network__share-button'
              onShareWindowClose={onShareSuccess}
            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </div>
          <div className={styles.shareListIcon}>
            <LinkedinShareButton
              url={getShareableLink}
              className='Demo__some-network__share-button'
              onShareWindowClose={onShareSuccess}
            >
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
          </div>
          <div className={styles.shareListIcon}>
            <EmailShareButton
              url={getShareableLink}
              subject={title}
              className='Demo__some-network__share-button'
              onShareWindowClose={onShareSuccess}
            >
              <EmailIcon size={32} round />
            </EmailShareButton>
          </div>
        </div>
      )}
      {/* {loader && <CircularLoader size={50} thickness={4} />} */}
      <Toast
        showToast={showToast}
        isSuccess={isSuccess}
        message={toastMessage}
        subMessage={toastSubMessage}
        handleToastClose={handleToastClose}
      />
    </div>
  );
};
