import { ImageConstant } from '@shalina-app/shared';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { CookiesSectionPropsType } from '.';
import styles from './cookiesSection.module.scss';

export const CookiesSection: React.FC<CookiesSectionPropsType> = (
  props: CookiesSectionPropsType
) => {
  const [cookiesPopup, setcookiesPopup] = useState(false);
  const handleCookiesClose = () => {
    setcookiesPopup(false);
  };

  return (
    <>
      <Dialog
        open={cookiesPopup}
        onClose={handleCookiesClose}
        className={styles.cookiesModal}
      >
        <DialogContent>
          <div className={styles.cookiesBoday}>
            <img
              src={ImageConstant.SOURCE.ShalinaMedspaceLogo}
              alt='logo'
              className={styles.logo}
            />
            <h2 className={styles.title}>We value your privacy</h2>
            <div className={styles.content}>
              <p>
                We appreciate your trust in our Health Care Learning Web App.
                Your privacy and learning experience are of utmost importance to
                us. We are committed to providing you with high-quality health
                care education while respecting your privacy. Rest assured, we
                take your privacy seriously, and the information gathered
                through cookies is used exclusively to enhance your learning
                experience. We will never share this data with third parties for
                marketing or advertising purposes.
              </p>
              <p>
                By choosing i accept below you are also helping to support our
                site and improve your browsing experience.
              </p>
              <div className={styles.ctas}>
                <button className={styles.cancel} onClick={handleCookiesClose}>
                  Cancel
                </button>
                <button>I’m Sure</button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
