import { Checkbox, FormControlLabel } from '@mui/material';
import {
  AppConstant,
  GuidelinesDataType,
  ImageConstant,
  translate,
  useAppSelector,
} from '@shalina-app/shared';
import { useState } from 'react';
import parse from 'html-react-parser';

import { ButtonComponent } from '../../../../components';
import styles from './guideline.module.scss';
import { GuidelineComponentProps, GuidelineProps } from '.';

export const CommunityGuideline: React.FC<GuidelineComponentProps> = ({
  communityGuidelines,
  handleJoinRequestSubmit,
}) => {
  const t = translate();

  const [guildeLineTermsCheck, setGuildeLineTermsCheck] = useState(false);

  const communityUpdateLoading = useAppSelector(
    (state) => state?.community?.updateCommunity?.isCommunityUpdating
  );

  const guidelines = [
    {
      title: t(AppConstant.COMMUNITY.GUIDELINE_ACCURACY_OF_INFORMATION),
      description: t(AppConstant.COMMUNITY.GUIDELINE_ACCURACY_OF_INFORMATION_DESCRIPTION),
    },
    {
      title: t(AppConstant.COMMUNITY.GUIDELINE_PRIVACY),
      description: t(AppConstant.COMMUNITY.GUIDELINE_PRIVACY_DESCRIPTION)
    },
    {
      title: t(AppConstant.COMMUNITY.GUIDELINE_INTELLECTUAL_PROPERTY),
      description: t(AppConstant.COMMUNITY.GUIDELINE_INTELLECTUAL_PROPERTY_DESCRIPTION)
    },
    {
      title: t(AppConstant.COMMUNITY.GUIDELINE_MODERATION),
      description: t(AppConstant.COMMUNITY.GUIDELINE_MODERATION_DESCRIPTION)
    },
    {
      title: t(AppConstant.COMMUNITY.GUIDELINE_CONDUCT),
      description: t(AppConstant.COMMUNITY.GUIDELINE_CONDUCT_DESCRIPTION)
    },
    {
      title: t(AppConstant.COMMUNITY.GUIDELINE_CHANGES_TO_COMMUNITY_POLICY),
      description: t(AppConstant.COMMUNITY.GUIDELINE_CHANGES_TO_COMMUNITY_POLICY_DESCRIPTION)
    },
  ];

  return (
    <>
      <div className={styles.head}>
        {t(AppConstant.COMMUNITY.COMMUNITY_GUIDELINE_HEADING)}
      </div>
      <div className={styles.joinBody}>
        <div className={styles.joinDetailsWrapper}>
          <div className={styles.joinDetails}>
            <img
              src={ImageConstant.SOURCE.guidelineIcon}
              alt='img'
              className={styles.joinIcon}
            />
            <span className={styles.staticGuideline}>
              {t(AppConstant.COMMUNITY.GUIDELINE_TEXT)}
            </span>
            <ul className={styles.detailList}>
              {guidelines?.map((guideline: GuidelineProps) => (
                <li>
                  <svg
                    width='20'
                    height='20'
                    viewBox='0 0 20 20'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <circle cx='10' cy='10' r='10' fill='#E3FBDB' />
                    <path
                      d='M14.2852 7.14621L7.88516 14.2891L4.28516 11.0423'
                      stroke='#55B844'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </svg>
                  <p>
                    <strong>{guideline?.title}: </strong>
                    {guideline?.description
                      ? parse(guideline?.description)
                      : null}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={styles.termCheck}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(event: any) =>
                  setGuildeLineTermsCheck(event.target?.checked)
                }
              />
            }
            label=''
          />
          <div className={styles.termText}>
            {t(AppConstant.COMMUNITY.COMMUNITY_GUIDELINE_ACKNOWLEDGEMENT)}
          </div>
        </div>
        <div className={styles.joinCta}>
          <ButtonComponent
            variant='outlined'
            buttonText={t(
              AppConstant.COMMUNITY.COMMUNITY_GUIDELINE_CONTINUE_CTA
            )}
            onPress={handleJoinRequestSubmit}
            disabled={guildeLineTermsCheck ? false : true}
            loader={communityUpdateLoading}
          />
        </div>
      </div>
    </>
  );
};
