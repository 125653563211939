import {
  SHARE_MOB_BASE_URL,
  SHARE_MODULE_NAMES,
  WEB_URL,
} from '@shalina-app/shared';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { detectAndroidDevice, detectIosDevice } from '../../../../utils';

export const UniversalShareComponent = () => {
  const location = useLocation();
  const params = location?.search;
  const urlParams = new URLSearchParams(params);

  const referralCode: any = urlParams.get('referralCode');
  const moduleName: any = urlParams.get('moduleName');
  const contentType: any = urlParams.get('contentType');
  const contentId: any = urlParams.get('contentId');
  const communityId: any = urlParams.get('communityId');
  const surveyId: any = urlParams.get('surveyId');

  useEffect(() => {
    if (referralCode) {
      if (detectIosDevice() || detectAndroidDevice()) {
        window.location.href = `${SHARE_MOB_BASE_URL}referralCode=${referralCode}`;
      } else {
        window.location.href = `${WEB_URL}referral/${referralCode}`;
      }
    } else if (moduleName && contentType && contentId) {
      if (moduleName === SHARE_MODULE_NAMES.SHALINA_TV) {
        if (detectIosDevice() || detectAndroidDevice()) {
          window.location.href = `${SHARE_MOB_BASE_URL}content_id=${contentId}&content_type=shalina`;
        } else {
          window.location.href = `${WEB_URL}shalina-tv/${contentType}/${contentId}`;
        }
      } else if (moduleName === SHARE_MODULE_NAMES.KHUB) {
        if (detectIosDevice() || detectAndroidDevice()) {
          window.location.href = `${SHARE_MOB_BASE_URL}content_id=${contentId}&content_type=k_hub`;
        } else {
          window.location.href = `${WEB_URL}knowledge-hub/${contentType}/${contentId}`;
        }
      } else if (moduleName === SHARE_MODULE_NAMES.LEARNING_CENTER) {
        if (detectIosDevice() || detectAndroidDevice()) {
          window.location.href = `${SHARE_MOB_BASE_URL}content_id=${contentId}&content_type=learning_center`;
        } else {
          window.location.href = `${WEB_URL}learning-center/${contentType}/${contentId}`;
        }
      }
    } else if (moduleName && communityId) {
      if (detectIosDevice() || detectAndroidDevice()) {
        window.location.href = `${SHARE_MOB_BASE_URL}content_id=${communityId}&content_type=community`;
      } else {
        window.location.href = `${WEB_URL}communities/${communityId}`;
      }
    } else if (moduleName && surveyId) {
      if (detectIosDevice() || detectAndroidDevice()) {
        window.location.href = `${SHARE_MOB_BASE_URL}content_id=${surveyId}&content_type=survey`;
      } else {
        window.location.href = `${WEB_URL}survey/${surveyId}`;
      }
    }
  }, []);

  return <></>;
};

// mobile
// https://urlgeni.us/GTTr/?referralCode=RF-MW2565
// https://urlgeni.us/GTTr/?content_id=2726&content_type=survey
// https://urlgeni.us/GTTr/?content_id=2188&content_type=shalina
// https://urlgeni.us/GTTr/?content_id=2108&content_type=learning_center
// https://urlgeni.us/GTTr/?content_id=789&content_type=k_hub
// https://urlgeni.us/GTTr/?content_id=13&content_type=community

// web
// http://localhost:3000/referral/RF-NDF054
// http://localhost:3000/knowledge-hub/329/789
// http://localhost:3000/shalina-tv/323/107
// http://localhost:3000/learning-center/330/2079
// http://localhost:3000/communities/8
// http://localhost:3000/survey/2446


// http://localhost:3000/share?referralCode=RF-NDF054
// http://localhost:3000/share?moduleName=knowledge-hub&contentType=329&contentId=789
// http://localhost:3000/share?moduleName=shalina-tv&contentType=323&contentId=107
// http://localhost:3000/share?moduleName=learning-center&contentType=330&contentId=2079
// http://localhost:3000/share?moduleName=communities&communityId=8
// http://localhost:3000/share?moduleName=survey&surveyId=2446
