"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRequestContentType = exports.getRequestType = exports.getFiltersData = exports.getLoggedInUser = exports.getContactModeLabelById = exports.getContactModes = exports.getNotificationActionList = exports.getGamification = exports.getSortOptionsValues = exports.getContentTypeId = exports.getImageForContentType = exports.getIconForContentType = exports.getFilterCategoriesOptionsValuesForKnowledgeHub = exports.getFilterCategoriesOptionsList = exports.getFilterCategoriesOptionsValues = exports.getSpecialization = exports.getQualification = exports.getAreaOfInterest = exports.getCountries = exports.getCountryCode = exports.getPreferredLanguagesList = exports.getPreferredLanguagesForLimitedTranslation = exports.getPreferredLanguages = exports.masterDataLoading = exports.getMasterContent = exports.getOfflineStatus = exports.getAllContentTypes = exports.getMaxMobileLength = exports.getProfilePicture = exports.getCurrentLanguageForWeb = exports.getCurrentLanguage = exports.getSaveTimeStampLastReadLoading = exports.getCommonLoading = exports.fetchAllContentTypes = exports.getAzureFile = exports.getLikeContent = exports.getRequestStatusList = exports.getRequestContentTypeList = exports.getRequestTypeList = exports.fetchGamificaton = exports.increaseCountOfContent = exports.fetchContactMode = exports.savePageNoLeftWhileReading = exports.azureFile = exports.likeContent = exports.getFilterCategoriesOptions = exports.getSortOptions = exports.getPreferredLanguageList = exports.getCountryCodeList = exports.fetchAllMasterData = void 0;
exports.resetIsCommunityJoinedFromHome = exports.setIsCommunityJoinedFromHome = exports.handleRecognitionPopup = exports.updateLanguageForWeb = exports.resetFiltersData = exports.setFiltersData = exports.updateProfileImage = exports.setIsLoggedIn = exports.setIsOffline = exports.getMobileLength = exports.updateLanguage = exports.getIsCommunityJoinedFromHome = exports.getRedemptionConfig = exports.getRecognitionBadgesData = exports.getContentTypesListForRequest = exports.getMedicalCalculator = exports.getDeactivateReasons = exports.listGamificationPointDistribution = exports.getAllDiscussionTypes = exports.getMilestoneData = exports.RequestStatusType = exports.getRequestStatusType = void 0;
const constants_1 = require("../../constants");
const toolkit_1 = require("@reduxjs/toolkit");
const _1 = require(".");
const api_1 = require("../../../api");
const utils_1 = require("../../utils");
const types_1 = require("../../utils/types");
const reducer_constants_1 = require("../reducer.constants");
const utils_2 = require("../../../utils");
const utils_3 = require("../../../utils");
const initialState = {
    isLoading: false,
    currentLanguage: "",
    currentLanguageForWeb: "",
    profilePicture: "",
    mobileNumberLength: _1.DEFAULT_MOBILE_LENGTH,
    isLoggedIn: false,
    filtersData: [],
    preferredLanguage: {
        preferredLanguages: [],
        isError: false,
    },
    countryList: {
        countryCodeList: [],
        isError: false,
    },
    sortOptions: {
        sortOptionsList: [],
        isError: false,
    },
    filterCategoriesOptions: {
        filterCategoriesOptionsList: [],
        isError: false,
    },
    likeContent: {
        likeContentData: [],
        isError: false,
        isLikeContentLoading: false,
    },
    azureFileUrl: {
        azureFileUrlData: "",
        isAzureFileUrlLoading: false,
        isError: false,
    },
    saveTimeStampLastReat: {
        saveTimeStampLastReatData: [],
        isSaveTimeStampLastReatLoading: false,
        isError: false,
    },
    contactModes: {
        isContactModeLoading: false,
        contactModes: {},
    },
    allContentTypes: {
        isAllContentLoading: false,
        contentTypeList: [],
    },
    isOffline: false,
    gamification: {
        isGamificationLoading: false,
        gamificationList: [],
    },
    increaseViews: {
        increaseViewLoading: false,
        increaseView: {},
    },
    masterData: {
        masterDataLoading: false,
        masterContent: {},
    },
    requestTypeData: {
        requestTypeList: {
            data: [],
            status: "",
            statusCode: 200,
            message: "",
        },
        isError: false,
        isLoading: false,
    },
    requestContentTypeData: {
        requestContentTypeList: {
            data: [],
            status: "",
            statusCode: 200,
            message: "",
        },
        isError: false,
        isLoading: false,
    },
    requestStatusTypeData: {
        requestStatusTypeList: {
            data: [],
            status: "",
            statusCode: 200,
            message: "",
        },
        isError: false,
    },
    showRecognitionPopup: false,
    recognitionData: {},
    isCommunityJoinedFromHome: false,
};
const commonSlice = (0, toolkit_1.createSlice)({
    name: constants_1.COMMON,
    initialState,
    reducers: {
        updateLanguage: (state, action) => {
            const lang = action === null || action === void 0 ? void 0 : action.payload;
            state.currentLanguage = lang === null || lang === void 0 ? void 0 : lang.toLowerCase();
            api_1.api.defaults.params = {};
            api_1.api.defaults.params["lang_code"] = lang === null || lang === void 0 ? void 0 : lang.toLowerCase();
        },
        updateLanguageForWeb: (state, action) => {
            const lang = action === null || action === void 0 ? void 0 : action.payload;
            state.currentLanguageForWeb = lang === null || lang === void 0 ? void 0 : lang.toLowerCase();
        },
        updateProfileImage: (state, action) => {
            state.profilePicture = action === null || action === void 0 ? void 0 : action.payload;
        },
        getMobileLength: (state, action) => {
            var _a, _b, _c, _d;
            const countryList = (_d = (_c = (_b = (_a = state === null || state === void 0 ? void 0 : state.masterData) === null || _a === void 0 ? void 0 : _a.masterContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.key) === (reducer_constants_1.MASTER_DATA_KEY === null || reducer_constants_1.MASTER_DATA_KEY === void 0 ? void 0 : reducer_constants_1.MASTER_DATA_KEY.COUNTRIES))) === null || _d === void 0 ? void 0 : _d.result;
            if (countryList && (countryList === null || countryList === void 0 ? void 0 : countryList.length) > 0) {
                const mobileLength = countryList.find((item) => (item === null || item === void 0 ? void 0 : item.field_country_code) === (action === null || action === void 0 ? void 0 : action.payload));
                if (mobileLength) {
                    state.mobileNumberLength =
                        (mobileLength === null || mobileLength === void 0 ? void 0 : mobileLength.field_mobile_length) === "" ||
                            (mobileLength === null || mobileLength === void 0 ? void 0 : mobileLength.field_mobile_length) === null
                            ? _1.DEFAULT_MOBILE_LENGTH
                            : mobileLength === null || mobileLength === void 0 ? void 0 : mobileLength.field_mobile_length;
                }
            }
        },
        setIsOffline: (state, action) => {
            state.isOffline = action.payload;
        },
        setIsLoggedIn: (state, action) => {
            state.isLoggedIn = action.payload;
        },
        setFiltersData: (state, action) => {
            state.filtersData = action.payload;
        },
        resetFiltersData: (state, action) => {
            state.filtersData = [];
        },
        handleRecognitionPopup: (state, action) => {
            state.showRecognitionPopup = action.payload.showRecognitionPopup;
            state.recognitionData = action.payload.recognitionData;
        },
        setIsCommunityJoinedFromHome: (state, action) => {
            state.isCommunityJoinedFromHome = action.payload;
        },
        resetIsCommunityJoinedFromHome: (state, action) => {
            state.isCommunityJoinedFromHome = false;
        },
    },
    extraReducers(builder) {
        builder.addCase(exports.getCountryCodeList.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.isLoading = true;
        });
        builder.addCase(exports.getCountryCodeList.fulfilled, (state, action) => {
            var _a, _b, _c, _d, _e;
            // Add logic if any when API state is fulfilled or data is received from API
            state.isLoading = false;
            // Add any fetched posts to the array
            const countryList = (_b = (_a = state === null || state === void 0 ? void 0 : state.countryList) === null || _a === void 0 ? void 0 : _a.countryCodeList) === null || _b === void 0 ? void 0 : _b.concat((_c = action === null || action === void 0 ? void 0 : action.payload) === null || _c === void 0 ? void 0 : _c.data);
            const uniqueArray = ((_d = action === null || action === void 0 ? void 0 : action.payload) === null || _d === void 0 ? void 0 : _d.data) ? (0, utils_1.removeDuplicates)((_e = action.payload) === null || _e === void 0 ? void 0 : _e.data, "tid") : [];
            state.countryList.countryCodeList = uniqueArray;
        });
        builder.addCase(exports.getCountryCodeList.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.isLoading = false;
            state.countryList.isError = true;
        });
        /**
         * Get Preffered Languages
         */
        builder.addCase(exports.getPreferredLanguageList.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(exports.getPreferredLanguageList.fulfilled, (state, action) => {
            var _a, _b, _c, _d, _e;
            const languages = (_b = (_a = state === null || state === void 0 ? void 0 : state.preferredLanguage) === null || _a === void 0 ? void 0 : _a.preferredLanguages) === null || _b === void 0 ? void 0 : _b.concat((_c = action === null || action === void 0 ? void 0 : action.payload) === null || _c === void 0 ? void 0 : _c.data);
            const uniqueArray = ((_d = action === null || action === void 0 ? void 0 : action.payload) === null || _d === void 0 ? void 0 : _d.data) ? (0, utils_1.removeDuplicates)((_e = action.payload) === null || _e === void 0 ? void 0 : _e.data, "tid") : [];
            state.isLoading = false;
            state.preferredLanguage.preferredLanguages = uniqueArray;
        });
        builder.addCase(exports.getPreferredLanguageList.rejected, (state, action) => {
            state.isLoading = false;
            state.preferredLanguage.isError = true;
        });
        /**
         * Get Sort Options
         */
        builder.addCase(exports.getSortOptions.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(exports.getSortOptions.fulfilled, (state, action) => {
            var _a, _b, _c, _d, _e;
            const filterOptions = (_b = (_a = state === null || state === void 0 ? void 0 : state.sortOptions) === null || _a === void 0 ? void 0 : _a.sortOptionsList) === null || _b === void 0 ? void 0 : _b.concat((_c = action === null || action === void 0 ? void 0 : action.payload) === null || _c === void 0 ? void 0 : _c.data);
            const uniqueArray = ((_d = action === null || action === void 0 ? void 0 : action.payload) === null || _d === void 0 ? void 0 : _d.data) ? (0, utils_1.removeDuplicates)((_e = action === null || action === void 0 ? void 0 : action.payload) === null || _e === void 0 ? void 0 : _e.data, "tid") : [];
            state.sortOptions.sortOptionsList = uniqueArray;
            state.isLoading = false;
        });
        builder.addCase(exports.getSortOptions.rejected, (state, action) => {
            state.isLoading = false;
            state.sortOptions.isError = true;
        });
        /**
         * Get Filter Categories Options
         */
        builder.addCase(exports.getFilterCategoriesOptions.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(exports.getFilterCategoriesOptions.fulfilled, (state, action) => {
            var _a, _b, _c, _d, _e;
            const filterOp = (_b = (_a = state === null || state === void 0 ? void 0 : state.filterCategoriesOptions) === null || _a === void 0 ? void 0 : _a.filterCategoriesOptionsList) === null || _b === void 0 ? void 0 : _b.concat((_c = action === null || action === void 0 ? void 0 : action.payload) === null || _c === void 0 ? void 0 : _c.data);
            const uniqueArray = ((_d = action === null || action === void 0 ? void 0 : action.payload) === null || _d === void 0 ? void 0 : _d.data) ? (0, utils_1.removeDuplicates)((_e = action === null || action === void 0 ? void 0 : action.payload) === null || _e === void 0 ? void 0 : _e.data, "tid") : [];
            state.filterCategoriesOptions.filterCategoriesOptionsList = uniqueArray;
            state.isLoading = false;
        });
        builder.addCase(exports.getFilterCategoriesOptions.rejected, (state, action) => {
            state.isLoading = false;
            state.filterCategoriesOptions.isError = true;
        });
        /**
         * Like Content Options
         */
        builder.addCase(exports.likeContent.pending, (state, action) => {
            state.likeContent.isLikeContentLoading = true;
        });
        builder.addCase(exports.likeContent.fulfilled, (state, action) => {
            var _a;
            const likeContentResponse = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data;
            state.likeContent.likeContentData = likeContentResponse;
            state.likeContent.isLikeContentLoading = false;
        });
        builder.addCase(exports.likeContent.rejected, (state, action) => {
            state.likeContent.isLikeContentLoading = false;
            state.likeContent.isError = true;
        });
        /**
         * Azure File Options
         */
        builder.addCase(exports.azureFile.pending, (state, action) => {
            state.azureFileUrl.isAzureFileUrlLoading = true;
        });
        builder.addCase(exports.azureFile.fulfilled, (state, action) => {
            var _a;
            const azureFileData = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data;
            state.azureFileUrl.azureFileUrlData = azureFileData;
            state.azureFileUrl.isAzureFileUrlLoading = false;
        });
        builder.addCase(exports.azureFile.rejected, (state, action) => {
            state.azureFileUrl.isAzureFileUrlLoading = false;
            state.azureFileUrl.isError = true;
        });
        /**
         * Save Timestamp File Read Options
         */
        builder.addCase(exports.savePageNoLeftWhileReading.pending, (state, action) => {
            state.saveTimeStampLastReat.isSaveTimeStampLastReatLoading = true;
        });
        builder.addCase(exports.savePageNoLeftWhileReading.fulfilled, (state, action) => {
            var _a;
            const saveLastReadData = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data;
            state.saveTimeStampLastReat.saveTimeStampLastReatData = saveLastReadData;
            state.saveTimeStampLastReat.isSaveTimeStampLastReatLoading = false;
        });
        builder.addCase(exports.savePageNoLeftWhileReading.rejected, (state, action) => {
            state.saveTimeStampLastReat.isSaveTimeStampLastReatLoading = false;
            state.azureFileUrl.isError = true;
        });
        builder.addCase(exports.fetchContactMode.pending, (state, action) => {
            state.contactModes.isContactModeLoading = true;
        });
        builder.addCase(exports.fetchContactMode.fulfilled, (state, action) => {
            state.contactModes.isContactModeLoading = false;
            state.contactModes.contactModes = action === null || action === void 0 ? void 0 : action.payload;
        });
        builder.addCase(exports.fetchContactMode.rejected, (state, action) => {
            state.contactModes.isContactModeLoading = false;
        });
        builder.addCase(exports.fetchAllContentTypes.pending, (state, action) => {
            state.allContentTypes.isAllContentLoading = true;
        });
        builder.addCase(exports.fetchAllContentTypes.fulfilled, (state, action) => {
            state.allContentTypes.isAllContentLoading = false;
            state.allContentTypes.contentTypeList = action === null || action === void 0 ? void 0 : action.payload;
        });
        builder.addCase(exports.fetchAllContentTypes.rejected, (state, action) => {
            state.allContentTypes.contentTypeList = false;
        });
        builder.addCase(exports.increaseCountOfContent.pending, (state, action) => {
            state.increaseViews.increaseViewLoading = true;
        });
        builder.addCase(exports.increaseCountOfContent.fulfilled, (state, action) => {
            state.increaseViews.increaseViewLoading = false;
            state.increaseViews.increaseView = action === null || action === void 0 ? void 0 : action.payload;
        });
        builder.addCase(exports.increaseCountOfContent.rejected, (state, action) => {
            state.increaseViews.increaseViewLoading = false;
        });
        builder.addCase(exports.fetchGamificaton.pending, (state, action) => {
            state.gamification.isGamificationLoading = true;
        });
        builder.addCase(exports.fetchGamificaton.fulfilled, (state, action) => {
            state.gamification.isGamificationLoading = false;
            state.gamification.gamificationList = action === null || action === void 0 ? void 0 : action.payload;
        });
        builder.addCase(exports.fetchGamificaton.rejected, (state, action) => {
            state.gamification.isGamificationLoading = false;
        });
        builder.addCase(exports.fetchAllMasterData.pending, (state, action) => {
            state.masterData.masterDataLoading = true;
        });
        builder.addCase(exports.fetchAllMasterData.fulfilled, (state, action) => {
            state.masterData.masterDataLoading = false;
            state.masterData.masterContent = action === null || action === void 0 ? void 0 : action.payload;
        });
        builder.addCase(exports.fetchAllMasterData.rejected, (state, action) => {
            state.masterData.masterDataLoading = false;
        });
        /**
         * Get Request Type
         */
        builder.addCase(exports.getRequestTypeList.pending, (state, action) => {
            state.requestTypeData.isLoading = true;
        });
        builder.addCase(exports.getRequestTypeList.fulfilled, (state, action) => {
            var _a;
            state.requestTypeData.isLoading = false;
            state.requestTypeData.requestTypeList.data = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.data;
        });
        builder.addCase(exports.getRequestTypeList.rejected, (state, action) => {
            state.requestTypeData.isLoading = false;
            state.requestTypeData.isError = true;
        });
        /**
         * Get Request Content Type
         */
        builder.addCase(exports.getRequestContentTypeList.pending, (state, action) => {
            state.requestContentTypeData.isLoading = true;
        });
        builder.addCase(exports.getRequestContentTypeList.fulfilled, (state, action) => {
            var _a;
            state.requestContentTypeData.isLoading = false;
            state.requestContentTypeData.requestContentTypeList.data =
                (_a = action.payload) === null || _a === void 0 ? void 0 : _a.data;
        });
        builder.addCase(exports.getRequestContentTypeList.rejected, (state, action) => {
            state.requestContentTypeData.isLoading = false;
            state.requestContentTypeData.isError = true;
        });
        /**
         * Get Request Status Type
         */
        builder.addCase(exports.getRequestStatusList.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(exports.getRequestStatusList.fulfilled, (state, action) => {
            var _a;
            state.isLoading = false;
            state.requestStatusTypeData.requestStatusTypeList.data =
                (_a = action.payload) === null || _a === void 0 ? void 0 : _a.data;
        });
        builder.addCase(exports.getRequestStatusList.rejected, (state, action) => {
            state.isLoading = false;
            state.requestStatusTypeData.isError = true;
        });
    },
});
exports.fetchAllMasterData = (0, toolkit_1.createAsyncThunk)(`${constants_1.COMMON}/${constants_1.GET_ALL_MASTER_DATA}`, () => __awaiter(void 0, void 0, void 0, function* () {
    const masterData = yield (0, api_1.get)(`${api_1.ENDPOINTS.MASTER_API_ENDPOINT}`, {
        baseURL: api_1.BASE_URL_NODE,
        timeout: 10000
    });
    return masterData;
}));
exports.getCountryCodeList = (0, toolkit_1.createAsyncThunk)(`${constants_1.COMMON}/${constants_1.GET_COUNTRY_CODE_LIST}`, () => __awaiter(void 0, void 0, void 0, function* () {
    const countryCodeList = yield (0, api_1.get)(constants_1.GET_COUNTRIES_ENDPOINT);
    return countryCodeList;
}));
exports.getPreferredLanguageList = (0, toolkit_1.createAsyncThunk)(`${constants_1.COMMON}/${constants_1.PREFERRED_LANGUAGE}`, () => __awaiter(void 0, void 0, void 0, function* () {
    const languageList = yield (0, api_1.get)(constants_1.GET_PREFERRED_LANGUAGE_ENDPOINT);
    return languageList;
}));
exports.getSortOptions = (0, toolkit_1.createAsyncThunk)(`${constants_1.COMMON}/${constants_1.GET_SORT_OPTIONS}`, (req) => __awaiter(void 0, void 0, void 0, function* () {
    const params = (0, utils_1.generateQueryString)(req);
    const sortOptionsList = yield (0, api_1.get)(`${constants_1.GET_SORT_OPTIONS_ENDPOINT}${params}`);
    return sortOptionsList;
}));
exports.getFilterCategoriesOptions = (0, toolkit_1.createAsyncThunk)(`${constants_1.COMMON}/${constants_1.GET_CONTENT_CATEGORIES_ENDPOINT}`, (req) => __awaiter(void 0, void 0, void 0, function* () {
    const field_type = (req === null || req === void 0 ? void 0 : req.type) ? req.type : undefined;
    let endpointUrl = "";
    if (field_type &&
        (field_type === types_1.CONTENT_CATEGORY_FIELD_TYPES.KNOWLEDGE_HUB ||
            field_type === types_1.CONTENT_CATEGORY_FIELD_TYPES.SHALINA_TV ||
            field_type === types_1.CONTENT_CATEGORY_FIELD_TYPES.LEARNING_CENTER)) {
        endpointUrl = `${constants_1.GET_CONTENT_CATEGORIES_ENDPOINT}?field_type=${field_type}`;
    }
    else {
        endpointUrl = `${constants_1.GET_CONTENT_CATEGORIES_ENDPOINT}`;
    }
    const filterCategoriesOptionsList = yield (0, api_1.get)(endpointUrl);
    return filterCategoriesOptionsList;
}));
exports.likeContent = (0, toolkit_1.createAsyncThunk)(`${constants_1.COMMON}/${constants_1.LIKE_CONTENT}`, (req) => __awaiter(void 0, void 0, void 0, function* () {
    const likeContent = yield (0, api_1.put)(constants_1.GET_LIKE_CONTENT_ENDPOINT, req);
    return likeContent;
}));
exports.azureFile = (0, toolkit_1.createAsyncThunk)(`${constants_1.COMMON}/${constants_1.AZURE_FILE_ACCESS}`, (req) => __awaiter(void 0, void 0, void 0, function* () {
    const azureFile = yield (0, api_1.post)(`${constants_1.GET_AZURE_STORAGE_ENDPOINT}`, req, {
        baseURL: api_1.BASE_URL_NODE,
    });
    return azureFile;
}));
exports.savePageNoLeftWhileReading = (0, toolkit_1.createAsyncThunk)(`${constants_1.COMMON}/${constants_1.SAVE_TIMESTAMP}`, (req) => __awaiter(void 0, void 0, void 0, function* () {
    const savePageNoLeftWhileReading = yield (0, api_1.put)(constants_1.SAVE_TIMESTAMP_PAGE_READ_ENDPOINT, req);
    return savePageNoLeftWhileReading;
}));
exports.fetchContactMode = (0, toolkit_1.createAsyncThunk)(`${constants_1.COMMON}/${constants_1.CONTACT_MODE}`, () => __awaiter(void 0, void 0, void 0, function* () {
    const contactModes = yield (0, api_1.get)(constants_1.GET_CONTACT_MODE_ENDPOINT);
    return contactModes;
}));
exports.increaseCountOfContent = (0, toolkit_1.createAsyncThunk)(`${constants_1.COMMON}/${constants_1.COUNT_INCREASE}`, (body) => __awaiter(void 0, void 0, void 0, function* () {
    const increaseCount = yield (0, api_1.put)(constants_1.INCREASE_COUNT_ENDPOINT, body);
    return increaseCount;
}));
exports.fetchGamificaton = (0, toolkit_1.createAsyncThunk)(`${constants_1.COMMON}/${constants_1.GAMIFICATION}`, () => __awaiter(void 0, void 0, void 0, function* () {
    const gamification = yield (0, api_1.get)(constants_1.GAMIFICATION_MASTER_ENPOINT);
    return gamification;
}));
exports.getRequestTypeList = (0, toolkit_1.createAsyncThunk)(`${constants_1.COMMON}/${constants_1.REQUEST_TYPE}`, () => __awaiter(void 0, void 0, void 0, function* () {
    const requestTypeList = yield (0, api_1.get)(constants_1.GET_REQUEST_TYPE_ENDPOINT);
    return requestTypeList;
}));
exports.getRequestContentTypeList = (0, toolkit_1.createAsyncThunk)(`${constants_1.COMMON}/${constants_1.REQUEST_CONTENT_TYPE}`, () => __awaiter(void 0, void 0, void 0, function* () {
    const requestContentTypeList = yield (0, api_1.get)(constants_1.GET_REQUEST_CONTENT_TYPE_ENDPOINT);
    return requestContentTypeList;
}));
exports.getRequestStatusList = (0, toolkit_1.createAsyncThunk)(`${constants_1.COMMON}/${constants_1.REQUEST_STATUS_TYPE}`, () => __awaiter(void 0, void 0, void 0, function* () {
    const requestStatusTypeList = yield (0, api_1.get)(constants_1.GET_REQUEST_STATUS_TYPE_ENDPOINT);
    return requestStatusTypeList;
}));
// export const getCountryCode = createSelector(
//   (state: RootState) => state.common,
//   (common: any) => {
//     return common?.countryList?.countryCodeList?.map((item: CountryCodeListItemProps) => {
//       let unicodeString = item?.field_flag_hax_code?.replace(/\\\\(?!u\d{4})/g, '\\');
//       let hexValues = unicodeString?.split('\\u').filter(Boolean);
//       // Convert hex values to decimal and then to Unicode characters
//       if (hexValues && hexValues.length > 0) {
//         // Convert hex values to decimal and then to Unicode characters
//         let flagEmoji = String.fromCodePoint(...hexValues.map((hex) => parseInt(hex, 16)));
//         return {
//           label: '' + flagEmoji + ' ' + item?.field_country_code,
//           value: item?.field_country_code || '',
//           mobileLength: item?.field_mobile_length || '',
//           icons: item?.field_flag_icon || '',
//           countryCode: item?.field_country_code || '',
//         };
//       } else {
//         return {
//           label: '' + item?.field_country_code,
//           value: item?.field_country_code || '',
//           mobileLength: item?.field_mobile_length || '',
//           countryCode: item?.field_country_code || '',
//           icons: item?.field_flag_icon || '',
//         };
//       }
//     });
//   }
// );
// export const getPreferredLanguages = createSelector(
//   (state: RootState) => state.common,
//   (common) =>
//     common?.preferredLanguage?.preferredLanguages?.map((item: PreferredLanguageItemProps) => {
//       return {
//         label: item?.name,
//         value: (item?.tid && item?.tid) || '',
//         code: item?.field_language_code,
//       };
//     })
// );
// export const getCountries = createSelector(
//   (state: RootState) => state.common,
//   (common) =>
//     common?.countryList?.countryCodeList?.map((item: CountryCodeListItemProps) => {
//       return {
//         label: item?.name,
//         value: (item?.tid && item?.tid) || '',
//       };
//     })
// );
// export const getSortOptionsValues = createSelector(
//   (state: RootState) => state.common,
//   (common) =>
//     common?.sortOptions?.sortOptionsList?.map((item: SortOptionsItemProps) => {
//       return {
//         label: item?.name,
//         value: (item?.tid && item?.tid) || '',
//         key: item?.field_content_sort_label || '',
//       };
//     })
// );
exports.getLikeContent = (0, toolkit_1.createSelector)((state) => state.common, (common) => { var _a; return (_a = common === null || common === void 0 ? void 0 : common.likeContent) === null || _a === void 0 ? void 0 : _a.likeContentData; });
exports.getAzureFile = (0, toolkit_1.createSelector)((state) => state.common, (common) => { var _a; return (_a = common === null || common === void 0 ? void 0 : common.azureFileUrl) === null || _a === void 0 ? void 0 : _a.azureFileUrlData; });
// export const getFilterCategoriesOptionsValues = createSelector(
//   (state: RootState) => state.common,
//   (common) =>
//     common?.filterCategoriesOptions?.filterCategoriesOptionsList?.map((item: FilterCategoriesOptionsItemProps) => {
//       return {
//         label: item?.name,
//         value: (item?.tid && item?.tid) || '',
//         category: (item?.field_type && item?.field_type) || '',
//         icon: (item?.field_content_type_icon && item?.field_content_type_icon) || '',
//       };
//     })
// );
// export const getFilterCategoriesOptionsList = createSelector(
//   (state: RootState) => state.common,
//   (common) =>
//     common?.filterCategoriesOptions?.filterCategoriesOptionsList?.map((item: FilterCategoriesOptionsItemProps) => {
//       return {
//         label: item?.name,
//         id: (item?.tid && item?.tid) || '',
//       };
//     })
// );
exports.fetchAllContentTypes = (0, toolkit_1.createAsyncThunk)(`${constants_1.COMMON}/${constants_1.GET_ALL_CONTENT_ENDPOINT}`, () => __awaiter(void 0, void 0, void 0, function* () {
    const allContentTypes = yield (0, api_1.get)(constants_1.GET_CONTENT_CATEGORIES_ENDPOINT);
    return allContentTypes;
}));
// export const getFilterCategoriesOptionsValuesForKnowledgeHub = createSelector(
//   (state: RootState) => state.common,
//   (common) =>
//     common?.filterCategoriesOptions?.filterCategoriesOptionsList?.filter((item: FilterCategoriesOptionsItemProps) => {
//       if (item.field_type === CONTENT_CATEGORY_FIELD_TYPES.KNOWLEDGE_HUB) {
//         return {
//           label: item?.name,
//           value: (item?.tid && item?.tid) || '',
//           category: (item?.field_type && item?.field_type) || '',
//           icon: (item?.field_content_type_icon && item?.field_content_type_icon) || '',
//         };
//       }
//     })
// );
// export const getIconForContentType = (contentTypeId: string): any =>
//   createSelector(
//     (state: RootState) => state.common,
//     (common) => {
//       const response: any = common?.filterCategoriesOptions?.filterCategoriesOptionsList?.find((item: any) => item?.tid === contentTypeId);
//       return response?.field_content_type_icon ? response?.field_content_type_icon : '';
//     }
//   );
// export const getImageForContentType = (contentTypeId: string): any =>
//   createSelector(
//     (state: RootState) => state.common,
//     (common) => {
//       const response: any = common?.filterCategoriesOptions?.filterCategoriesOptionsList?.find((item: any) => item?.tid === contentTypeId);
//       return response?.field_icon ? response?.field_icon : '';
//     }
//   );
const getCommonLoading = () => (0, toolkit_1.createSelector)((state) => state.common, (common) => common === null || common === void 0 ? void 0 : common.isLoading);
exports.getCommonLoading = getCommonLoading;
exports.getSaveTimeStampLastReadLoading = (0, toolkit_1.createSelector)((state) => state.common, (common) => { var _a; return (_a = common === null || common === void 0 ? void 0 : common.saveTimeStampLastReat) === null || _a === void 0 ? void 0 : _a.isSaveTimeStampLastReatLoading; });
exports.getCurrentLanguage = (0, toolkit_1.createSelector)((state) => state.common, (common) => common === null || common === void 0 ? void 0 : common.currentLanguage);
exports.getCurrentLanguageForWeb = (0, toolkit_1.createSelector)((state) => state.common, (common) => common === null || common === void 0 ? void 0 : common.currentLanguageForWeb);
exports.getProfilePicture = (0, toolkit_1.createSelector)((state) => state.common, (common) => common === null || common === void 0 ? void 0 : common.profilePicture);
// export const getContactModes = createSelector(
//   (state: RootState) => state.common,
//   (common) =>
//     common?.contactModes?.contactModes?.data?.map((item: ContactModeProps) => {
//       return {
//         label: item?.title,
//         value: (item?.tid && item?.tid) || '',
//       };
//     })
// );
// export const getContactModeLabelById = (id: string) =>
//   createSelector(
//     (state: RootState) => state.common,
//     (common) => {
//       const response: any = common?.contactModes?.contactModes?.data?.find((item: ContactModeProps) => item?.tid === id);
//       return response?.title ? response?.title : '';
//     }
//   );
exports.getMaxMobileLength = (0, toolkit_1.createSelector)((state) => state.common, (common) => common === null || common === void 0 ? void 0 : common.mobileNumberLength);
exports.getAllContentTypes = (0, toolkit_1.createSelector)((state) => state.common, (common) => common === null || common === void 0 ? void 0 : common.allContentTypes);
exports.getOfflineStatus = (0, toolkit_1.createSelector)((state) => state.common, (common) => common === null || common === void 0 ? void 0 : common.isOffline);
// export const getGamification = createSelector(
//   (state: RootState) => state.common,
//   (common) => common?.gamification?.gamificationList?.data
// );
// export const getContentTypeId = (contentType: string): any =>
//   createSelector(
//     (state: RootState) => state.common,
//     (common) => {
//       const response: any = common?.filterCategoriesOptions?.filterCategoriesOptionsList?.find((item: any) => item?.name === contentType);
//       return response?.tid ? response?.tid : '';
//     }
//   );
exports.getMasterContent = (0, toolkit_1.createSelector)((state) => state.common, (common) => { var _a; return (_a = common === null || common === void 0 ? void 0 : common.masterData) === null || _a === void 0 ? void 0 : _a.masterContent; });
const masterDataLoading = () => (0, toolkit_1.createSelector)((state) => state.common, (common) => { var _a; return (_a = common === null || common === void 0 ? void 0 : common.masterData) === null || _a === void 0 ? void 0 : _a.masterDataLoading; });
exports.masterDataLoading = masterDataLoading;
exports.getPreferredLanguages = (0, toolkit_1.createSelector)((state) => state.common, (common) => {
    var _a, _b, _c, _d;
    const languages = (_d = (_c = (_b = (_a = common === null || common === void 0 ? void 0 : common.masterData) === null || _a === void 0 ? void 0 : _a.masterContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.key) === (reducer_constants_1.MASTER_DATA_KEY === null || reducer_constants_1.MASTER_DATA_KEY === void 0 ? void 0 : reducer_constants_1.MASTER_DATA_KEY.LANGUAGES))) === null || _d === void 0 ? void 0 : _d.result;
    return languages === null || languages === void 0 ? void 0 : languages.map((language) => {
        return {
            label: language === null || language === void 0 ? void 0 : language.name,
            value: ((language === null || language === void 0 ? void 0 : language.tid) && (language === null || language === void 0 ? void 0 : language.tid)) || "",
            code: language === null || language === void 0 ? void 0 : language.field_language_code,
            field_unique_code: language === null || language === void 0 ? void 0 : language.field_unique_code,
        };
    });
});
exports.getPreferredLanguagesForLimitedTranslation = (0, toolkit_1.createSelector)((state) => state.common, (common) => {
    var _a, _b, _c, _d;
    const languages = (_d = (_c = (_b = (_a = common === null || common === void 0 ? void 0 : common.masterData) === null || _a === void 0 ? void 0 : _a.masterContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.key) === (reducer_constants_1.MASTER_DATA_KEY === null || reducer_constants_1.MASTER_DATA_KEY === void 0 ? void 0 : reducer_constants_1.MASTER_DATA_KEY.LANGUAGES))) === null || _d === void 0 ? void 0 : _d.result;
    return languages === null || languages === void 0 ? void 0 : languages.map((language) => {
        return {
            label: language === null || language === void 0 ? void 0 : language.name,
            value: ((language === null || language === void 0 ? void 0 : language.tid) && (language === null || language === void 0 ? void 0 : language.tid)) || "",
            code: language === null || language === void 0 ? void 0 : language.field_language_code,
            field_unique_code: language === null || language === void 0 ? void 0 : language.field_unique_code,
            color: (language === null || language === void 0 ? void 0 : language.field_unique_code) === utils_3.LANGUAGE_UNIQUE_ID.ENGLISH ? '#202020' : 'rgba(128,128,128,0.5)'
        };
    });
});
exports.getPreferredLanguagesList = (0, toolkit_1.createSelector)((state) => state.common, (common) => {
    var _a, _b, _c, _d;
    const languages = (_d = (_c = (_b = (_a = common === null || common === void 0 ? void 0 : common.masterData) === null || _a === void 0 ? void 0 : _a.masterContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.key) === (reducer_constants_1.MASTER_DATA_KEY === null || reducer_constants_1.MASTER_DATA_KEY === void 0 ? void 0 : reducer_constants_1.MASTER_DATA_KEY.LANGUAGES))) === null || _d === void 0 ? void 0 : _d.result;
    return languages ? languages : [];
});
exports.getCountryCode = (0, toolkit_1.createSelector)((state) => state.common, (common) => {
    var _a, _b, _c, _d;
    const countriesList = (_d = (_c = (_b = (_a = common === null || common === void 0 ? void 0 : common.masterData) === null || _a === void 0 ? void 0 : _a.masterContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.key) === (reducer_constants_1.MASTER_DATA_KEY === null || reducer_constants_1.MASTER_DATA_KEY === void 0 ? void 0 : reducer_constants_1.MASTER_DATA_KEY.COUNTRIES))) === null || _d === void 0 ? void 0 : _d.result;
    return countriesList === null || countriesList === void 0 ? void 0 : countriesList.map((item) => {
        var _a;
        let unicodeString = (_a = item === null || item === void 0 ? void 0 : item.field_flag_hax_code) === null || _a === void 0 ? void 0 : _a.replace(/\\\\(?!u\d{4})/g, "\\");
        let hexValues = unicodeString === null || unicodeString === void 0 ? void 0 : unicodeString.split("\\u").filter(Boolean);
        // Convert hex values to decimal and then to Unicode characters
        if (hexValues && hexValues.length > 0) {
            // Convert hex values to decimal and then to Unicode characters
            let flagEmoji = String.fromCodePoint(...hexValues.map((hex) => parseInt(hex, 16)));
            return {
                label: "" + flagEmoji + " " + (item === null || item === void 0 ? void 0 : item.field_country_code) + " " + (item === null || item === void 0 ? void 0 : item.name),
                value: (item === null || item === void 0 ? void 0 : item.field_country_code) || "",
                mobileLength: (item === null || item === void 0 ? void 0 : item.field_mobile_length) || "",
                icons: (item === null || item === void 0 ? void 0 : item.field_flag_icon) || "",
                countryCode: (item === null || item === void 0 ? void 0 : item.field_country_code) || "",
                countryName: (item === null || item === void 0 ? void 0 : item.name) || "",
                inputLabel: "" + flagEmoji + " " + (item === null || item === void 0 ? void 0 : item.field_country_code),
            };
        }
        else {
            return {
                label: "" + (item === null || item === void 0 ? void 0 : item.field_country_code),
                value: (item === null || item === void 0 ? void 0 : item.field_country_code) || "",
                mobileLength: (item === null || item === void 0 ? void 0 : item.field_mobile_length) || "",
                countryCode: (item === null || item === void 0 ? void 0 : item.field_country_code) || "",
                icons: (item === null || item === void 0 ? void 0 : item.field_flag_icon) || "",
                countryName: (item === null || item === void 0 ? void 0 : item.name) || "",
            };
        }
    });
});
exports.getCountries = (0, toolkit_1.createSelector)((state) => state.common, (common) => {
    var _a, _b, _c, _d;
    const countriesList = (_d = (_c = (_b = (_a = common === null || common === void 0 ? void 0 : common.masterData) === null || _a === void 0 ? void 0 : _a.masterContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.key) === (reducer_constants_1.MASTER_DATA_KEY === null || reducer_constants_1.MASTER_DATA_KEY === void 0 ? void 0 : reducer_constants_1.MASTER_DATA_KEY.COUNTRIES))) === null || _d === void 0 ? void 0 : _d.result;
    return countriesList === null || countriesList === void 0 ? void 0 : countriesList.map((item) => {
        return {
            label: item === null || item === void 0 ? void 0 : item.name,
            value: ((item === null || item === void 0 ? void 0 : item.tid) && (item === null || item === void 0 ? void 0 : item.tid)) || "",
        };
    });
});
exports.getAreaOfInterest = (0, toolkit_1.createSelector)((state) => state.common, (common) => {
    var _a, _b, _c, _d;
    const areOfInterestList = (_d = (_c = (_b = (_a = common === null || common === void 0 ? void 0 : common.masterData) === null || _a === void 0 ? void 0 : _a.masterContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.key) === (reducer_constants_1.MASTER_DATA_KEY === null || reducer_constants_1.MASTER_DATA_KEY === void 0 ? void 0 : reducer_constants_1.MASTER_DATA_KEY.AREA_OF_INTEREST))) === null || _d === void 0 ? void 0 : _d.result;
    return areOfInterestList === null || areOfInterestList === void 0 ? void 0 : areOfInterestList.map((item) => {
        return {
            id: ((item === null || item === void 0 ? void 0 : item.tid) && (item === null || item === void 0 ? void 0 : item.tid)) || "",
            label: (item === null || item === void 0 ? void 0 : item.title) || "",
        };
    });
});
exports.getQualification = (0, toolkit_1.createSelector)((state) => state.common, (common) => {
    var _a, _b, _c, _d;
    const qualificationList = (_d = (_c = (_b = (_a = common === null || common === void 0 ? void 0 : common.masterData) === null || _a === void 0 ? void 0 : _a.masterContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.key) === (reducer_constants_1.MASTER_DATA_KEY === null || reducer_constants_1.MASTER_DATA_KEY === void 0 ? void 0 : reducer_constants_1.MASTER_DATA_KEY.QUALIFICATION))) === null || _d === void 0 ? void 0 : _d.result;
    return qualificationList === null || qualificationList === void 0 ? void 0 : qualificationList.map((item) => {
        return {
            id: ((item === null || item === void 0 ? void 0 : item.tid) && (item === null || item === void 0 ? void 0 : item.tid)) || "",
            label: (item === null || item === void 0 ? void 0 : item.title) || "",
        };
    });
});
exports.getSpecialization = (0, toolkit_1.createSelector)((state) => state.common, (common) => {
    var _a, _b, _c, _d;
    const qualificationList = (_d = (_c = (_b = (_a = common === null || common === void 0 ? void 0 : common.masterData) === null || _a === void 0 ? void 0 : _a.masterContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.key) === (reducer_constants_1.MASTER_DATA_KEY === null || reducer_constants_1.MASTER_DATA_KEY === void 0 ? void 0 : reducer_constants_1.MASTER_DATA_KEY.SPECIALIZATION))) === null || _d === void 0 ? void 0 : _d.result;
    return qualificationList === null || qualificationList === void 0 ? void 0 : qualificationList.map((item) => {
        return {
            id: ((item === null || item === void 0 ? void 0 : item.tid) && (item === null || item === void 0 ? void 0 : item.tid)) || "",
            label: (item === null || item === void 0 ? void 0 : item.name) || "",
        };
    });
});
exports.getFilterCategoriesOptionsValues = (0, toolkit_1.createSelector)((state) => state.common, (common) => {
    var _a, _b, _c, _d;
    const contentTypeList = (_d = (_c = (_b = (_a = common === null || common === void 0 ? void 0 : common.masterData) === null || _a === void 0 ? void 0 : _a.masterContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.key) === (reducer_constants_1.MASTER_DATA_KEY === null || reducer_constants_1.MASTER_DATA_KEY === void 0 ? void 0 : reducer_constants_1.MASTER_DATA_KEY.CONTENT_TYPE))) === null || _d === void 0 ? void 0 : _d.result;
    return contentTypeList === null || contentTypeList === void 0 ? void 0 : contentTypeList.map((item) => {
        return {
            label: item === null || item === void 0 ? void 0 : item.name,
            value: ((item === null || item === void 0 ? void 0 : item.tid) && (item === null || item === void 0 ? void 0 : item.tid)) || "",
            category: ((item === null || item === void 0 ? void 0 : item.field_type) && (item === null || item === void 0 ? void 0 : item.field_type)) || "",
            icon: ((item === null || item === void 0 ? void 0 : item.field_content_type_icon) && (item === null || item === void 0 ? void 0 : item.field_content_type_icon)) ||
                "",
            unique_id: ((item === null || item === void 0 ? void 0 : item.field_unique_code) && (item === null || item === void 0 ? void 0 : item.field_unique_code)) || "",
        };
    });
});
exports.getFilterCategoriesOptionsList = (0, toolkit_1.createSelector)((state) => state.common, (common) => {
    var _a, _b, _c, _d;
    const contentTypeList = (_d = (_c = (_b = (_a = common === null || common === void 0 ? void 0 : common.masterData) === null || _a === void 0 ? void 0 : _a.masterContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.key) === (reducer_constants_1.MASTER_DATA_KEY === null || reducer_constants_1.MASTER_DATA_KEY === void 0 ? void 0 : reducer_constants_1.MASTER_DATA_KEY.CONTENT_TYPE))) === null || _d === void 0 ? void 0 : _d.result;
    return contentTypeList === null || contentTypeList === void 0 ? void 0 : contentTypeList.map((item) => {
        return {
            label: item === null || item === void 0 ? void 0 : item.name,
            id: ((item === null || item === void 0 ? void 0 : item.tid) && (item === null || item === void 0 ? void 0 : item.tid)) || "",
        };
    });
});
exports.getFilterCategoriesOptionsValuesForKnowledgeHub = (0, toolkit_1.createSelector)((state) => state.common, (common) => {
    var _a, _b, _c, _d;
    const contentTypeList = (_d = (_c = (_b = (_a = common === null || common === void 0 ? void 0 : common.masterData) === null || _a === void 0 ? void 0 : _a.masterContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.key) === (reducer_constants_1.MASTER_DATA_KEY === null || reducer_constants_1.MASTER_DATA_KEY === void 0 ? void 0 : reducer_constants_1.MASTER_DATA_KEY.CONTENT_TYPE))) === null || _d === void 0 ? void 0 : _d.result;
    return contentTypeList === null || contentTypeList === void 0 ? void 0 : contentTypeList.filter((item) => {
        if (item.field_type === types_1.CONTENT_CATEGORY_FIELD_TYPES.KNOWLEDGE_HUB) {
            return {
                label: item === null || item === void 0 ? void 0 : item.name,
                value: ((item === null || item === void 0 ? void 0 : item.tid) && (item === null || item === void 0 ? void 0 : item.tid)) || "",
                category: ((item === null || item === void 0 ? void 0 : item.field_type) && (item === null || item === void 0 ? void 0 : item.field_type)) || "",
                icon: ((item === null || item === void 0 ? void 0 : item.field_content_type_icon) && (item === null || item === void 0 ? void 0 : item.field_content_type_icon)) ||
                    "",
                unique_id: ((item === null || item === void 0 ? void 0 : item.field_unique_code) && (item === null || item === void 0 ? void 0 : item.field_unique_code)) || "",
            };
        }
    });
});
const getIconForContentType = (contentTypeId) => (0, toolkit_1.createSelector)((state) => state.common, (common) => {
    var _a, _b, _c, _d;
    const contentTypeList = (_d = (_c = (_b = (_a = common === null || common === void 0 ? void 0 : common.masterData) === null || _a === void 0 ? void 0 : _a.masterContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.key) === (reducer_constants_1.MASTER_DATA_KEY === null || reducer_constants_1.MASTER_DATA_KEY === void 0 ? void 0 : reducer_constants_1.MASTER_DATA_KEY.CONTENT_TYPE))) === null || _d === void 0 ? void 0 : _d.result;
    const response = contentTypeList === null || contentTypeList === void 0 ? void 0 : contentTypeList.find((item) => (item === null || item === void 0 ? void 0 : item.field_unique_code) === contentTypeId);
    return (response === null || response === void 0 ? void 0 : response.field_content_type_icon)
        ? response === null || response === void 0 ? void 0 : response.field_content_type_icon
        : "";
});
exports.getIconForContentType = getIconForContentType;
const getImageForContentType = (contentTypeId) => (0, toolkit_1.createSelector)((state) => state.common, (common) => {
    var _a, _b, _c, _d;
    const contentTypeList = (_d = (_c = (_b = (_a = common === null || common === void 0 ? void 0 : common.masterData) === null || _a === void 0 ? void 0 : _a.masterContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.key) === (reducer_constants_1.MASTER_DATA_KEY === null || reducer_constants_1.MASTER_DATA_KEY === void 0 ? void 0 : reducer_constants_1.MASTER_DATA_KEY.CONTENT_TYPE))) === null || _d === void 0 ? void 0 : _d.result;
    const response = contentTypeList === null || contentTypeList === void 0 ? void 0 : contentTypeList.find((item) => (item === null || item === void 0 ? void 0 : item.field_unique_code) === contentTypeId);
    return (response === null || response === void 0 ? void 0 : response.field_icon) ? response === null || response === void 0 ? void 0 : response.field_icon : "";
});
exports.getImageForContentType = getImageForContentType;
const getContentTypeId = (contentType) => (0, toolkit_1.createSelector)((state) => state.common, (common) => {
    var _a, _b, _c, _d;
    const contentTypeList = (_d = (_c = (_b = (_a = common === null || common === void 0 ? void 0 : common.masterData) === null || _a === void 0 ? void 0 : _a.masterContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.key) === (reducer_constants_1.MASTER_DATA_KEY === null || reducer_constants_1.MASTER_DATA_KEY === void 0 ? void 0 : reducer_constants_1.MASTER_DATA_KEY.CONTENT_TYPE))) === null || _d === void 0 ? void 0 : _d.result;
    const response = contentTypeList === null || contentTypeList === void 0 ? void 0 : contentTypeList.find((item) => (item === null || item === void 0 ? void 0 : item.name) === contentType);
    return (response === null || response === void 0 ? void 0 : response.tid) ? response === null || response === void 0 ? void 0 : response.tid : "";
});
exports.getContentTypeId = getContentTypeId;
const getSortOptionsValues = (isCommunity = false, module) => (0, toolkit_1.createSelector)((state) => state.common, (common) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    if (isCommunity) {
        const sortOptionsCommunityList = (_d = (_c = (_b = (_a = common === null || common === void 0 ? void 0 : common.masterData) === null || _a === void 0 ? void 0 : _a.masterContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.key) === (reducer_constants_1.MASTER_DATA_KEY === null || reducer_constants_1.MASTER_DATA_KEY === void 0 ? void 0 : reducer_constants_1.MASTER_DATA_KEY.SORT_OPTION_COMMUNITY))) === null || _d === void 0 ? void 0 : _d.result;
        const arr = sortOptionsCommunityList === null || sortOptionsCommunityList === void 0 ? void 0 : sortOptionsCommunityList.filter((item) => {
            var _a, _b;
            if (module) {
                const arr = (_b = (_a = item === null || item === void 0 ? void 0 : item.field_available_on) === null || _a === void 0 ? void 0 : _a.split(',')) === null || _b === void 0 ? void 0 : _b.map((item) => item === null || item === void 0 ? void 0 : item.trim());
                const applicable = arr === null || arr === void 0 ? void 0 : arr.includes(module);
                if (applicable) {
                    return item;
                }
            }
            else {
                return item;
            }
        });
        return arr === null || arr === void 0 ? void 0 : arr.map((item) => {
            return {
                label: item === null || item === void 0 ? void 0 : item.name,
                value: ((item === null || item === void 0 ? void 0 : item.tid) && (item === null || item === void 0 ? void 0 : item.tid)) || "",
                key: (item === null || item === void 0 ? void 0 : item.field_content_sort_label) || "",
            };
        });
    }
    const sortOptionsList = (_h = (_g = (_f = (_e = common === null || common === void 0 ? void 0 : common.masterData) === null || _e === void 0 ? void 0 : _e.masterContent) === null || _f === void 0 ? void 0 : _f.data) === null || _g === void 0 ? void 0 : _g.find((item) => (item === null || item === void 0 ? void 0 : item.key) === (reducer_constants_1.MASTER_DATA_KEY === null || reducer_constants_1.MASTER_DATA_KEY === void 0 ? void 0 : reducer_constants_1.MASTER_DATA_KEY.SORT_OPTION))) === null || _h === void 0 ? void 0 : _h.result;
    return sortOptionsList === null || sortOptionsList === void 0 ? void 0 : sortOptionsList.map((item) => {
        return {
            label: item === null || item === void 0 ? void 0 : item.name,
            value: ((item === null || item === void 0 ? void 0 : item.tid) && (item === null || item === void 0 ? void 0 : item.tid)) || "",
            key: (item === null || item === void 0 ? void 0 : item.field_content_sort_label) || "",
        };
    });
});
exports.getSortOptionsValues = getSortOptionsValues;
exports.getGamification = (0, toolkit_1.createSelector)((state) => state.common, (common) => {
    var _a, _b, _c, _d;
    const gamificationList = (_d = (_c = (_b = (_a = common === null || common === void 0 ? void 0 : common.masterData) === null || _a === void 0 ? void 0 : _a.masterContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.key) === (reducer_constants_1.MASTER_DATA_KEY === null || reducer_constants_1.MASTER_DATA_KEY === void 0 ? void 0 : reducer_constants_1.MASTER_DATA_KEY.GAMIFICATION_LIST))) === null || _d === void 0 ? void 0 : _d.result;
    return gamificationList ? gamificationList : [];
});
exports.getNotificationActionList = (0, toolkit_1.createSelector)((state) => state.common, (common) => {
    var _a, _b, _c, _d;
    const notificationActionList = (_d = (_c = (_b = (_a = common === null || common === void 0 ? void 0 : common.masterData) === null || _a === void 0 ? void 0 : _a.masterContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.key) === (reducer_constants_1.MASTER_DATA_KEY === null || reducer_constants_1.MASTER_DATA_KEY === void 0 ? void 0 : reducer_constants_1.MASTER_DATA_KEY.NOTIFICATION_LIST))) === null || _d === void 0 ? void 0 : _d.result;
    return notificationActionList ? notificationActionList : [];
});
exports.getContactModes = (0, toolkit_1.createSelector)((state) => state.common, (common) => {
    var _a, _b, _c, _d;
    const contactModesList = (_d = (_c = (_b = (_a = common === null || common === void 0 ? void 0 : common.masterData) === null || _a === void 0 ? void 0 : _a.masterContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.key) === (reducer_constants_1.MASTER_DATA_KEY === null || reducer_constants_1.MASTER_DATA_KEY === void 0 ? void 0 : reducer_constants_1.MASTER_DATA_KEY.CONTACT_MODE))) === null || _d === void 0 ? void 0 : _d.result;
    return contactModesList === null || contactModesList === void 0 ? void 0 : contactModesList.map((item) => {
        return {
            label: item === null || item === void 0 ? void 0 : item.title,
            value: ((item === null || item === void 0 ? void 0 : item.tid) && (item === null || item === void 0 ? void 0 : item.tid)) || "",
        };
    });
});
const getContactModeLabelById = (id) => (0, toolkit_1.createSelector)((state) => state.common, (common) => {
    var _a, _b, _c, _d;
    const contactModesList = (_d = (_c = (_b = (_a = common === null || common === void 0 ? void 0 : common.masterData) === null || _a === void 0 ? void 0 : _a.masterContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.key) === (reducer_constants_1.MASTER_DATA_KEY === null || reducer_constants_1.MASTER_DATA_KEY === void 0 ? void 0 : reducer_constants_1.MASTER_DATA_KEY.CONTACT_MODE))) === null || _d === void 0 ? void 0 : _d.result;
    const response = contactModesList === null || contactModesList === void 0 ? void 0 : contactModesList.find((item) => (item === null || item === void 0 ? void 0 : item.tid) === id);
    return (response === null || response === void 0 ? void 0 : response.title) ? response === null || response === void 0 ? void 0 : response.title : "";
});
exports.getContactModeLabelById = getContactModeLabelById;
exports.getLoggedInUser = (0, toolkit_1.createSelector)((state) => state.common, (common) => common === null || common === void 0 ? void 0 : common.isLoggedIn);
exports.getFiltersData = (0, toolkit_1.createSelector)((state) => state.common, (common) => common === null || common === void 0 ? void 0 : common.filtersData);
exports.getRequestType = (0, toolkit_1.createSelector)((state) => state.common, (common) => {
    var _a, _b, _c, _d;
    const requestTypeList = (_d = (_c = (_b = (_a = common === null || common === void 0 ? void 0 : common.masterData) === null || _a === void 0 ? void 0 : _a.masterContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.key) === (reducer_constants_1.MASTER_DATA_KEY === null || reducer_constants_1.MASTER_DATA_KEY === void 0 ? void 0 : reducer_constants_1.MASTER_DATA_KEY.REQUEST_TYPE))) === null || _d === void 0 ? void 0 : _d.result;
    // const requestType: any = common?.requestTypeData?.requestTypeList?.data;
    return requestTypeList === null || requestTypeList === void 0 ? void 0 : requestTypeList.map((requestItem) => {
        return {
            label: requestItem === null || requestItem === void 0 ? void 0 : requestItem.name,
            value: ((requestItem === null || requestItem === void 0 ? void 0 : requestItem.tid) && (requestItem === null || requestItem === void 0 ? void 0 : requestItem.tid)) || "",
            field_unique_code: requestItem === null || requestItem === void 0 ? void 0 : requestItem.field_unique_code,
        };
    });
});
exports.getRequestContentType = (0, toolkit_1.createSelector)((state) => state.common, (common) => {
    var _a, _b;
    const requestContentType = (_b = (_a = common === null || common === void 0 ? void 0 : common.requestContentTypeData) === null || _a === void 0 ? void 0 : _a.requestContentTypeList) === null || _b === void 0 ? void 0 : _b.data;
    return requestContentType === null || requestContentType === void 0 ? void 0 : requestContentType.map((requestContentItem) => {
        return {
            label: requestContentItem === null || requestContentItem === void 0 ? void 0 : requestContentItem.name,
            value: ((requestContentItem === null || requestContentItem === void 0 ? void 0 : requestContentItem.tid) && (requestContentItem === null || requestContentItem === void 0 ? void 0 : requestContentItem.tid)) || "",
            field_unique_code: requestContentItem === null || requestContentItem === void 0 ? void 0 : requestContentItem.field_unique_code,
        };
    });
});
exports.getRequestStatusType = (0, toolkit_1.createSelector)((state) => state.common, (common) => {
    var _a, _b, _c, _d;
    const requestStatusType = (_d = (_c = (_b = (_a = common === null || common === void 0 ? void 0 : common.masterData) === null || _a === void 0 ? void 0 : _a.masterContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.key) === (reducer_constants_1.MASTER_DATA_KEY === null || reducer_constants_1.MASTER_DATA_KEY === void 0 ? void 0 : reducer_constants_1.MASTER_DATA_KEY.REQUEST_STATUS))) === null || _d === void 0 ? void 0 : _d.result;
    // const requestStatusType: any =
    //   common?.requestStatusTypeData?.requestStatusTypeList?.data;
    const filterStatusType = requestStatusType === null || requestStatusType === void 0 ? void 0 : requestStatusType.map((requestStatusItem) => {
        return {
            label: requestStatusItem === null || requestStatusItem === void 0 ? void 0 : requestStatusItem.name,
            value: ((requestStatusItem === null || requestStatusItem === void 0 ? void 0 : requestStatusItem.tid) && (requestStatusItem === null || requestStatusItem === void 0 ? void 0 : requestStatusItem.tid)) || "",
            field_unique_code: requestStatusItem === null || requestStatusItem === void 0 ? void 0 : requestStatusItem.field_unique_code,
        };
    });
    const removedStatusType = filterStatusType === null || filterStatusType === void 0 ? void 0 : filterStatusType.filter((item) => { var _a; return ((_a = item === null || item === void 0 ? void 0 : item.label) === null || _a === void 0 ? void 0 : _a.trim()) !== utils_2.REQUEST_MODULE_VARIABLE.REVOKED; });
    return removedStatusType === null || removedStatusType === void 0 ? void 0 : removedStatusType.sort((a, b) => a.value - b.value);
});
exports.RequestStatusType = (0, toolkit_1.createSelector)((state) => state.common, (common) => {
    var _a, _b, _c, _d;
    const requestStatusType = (_d = (_c = (_b = (_a = common === null || common === void 0 ? void 0 : common.masterData) === null || _a === void 0 ? void 0 : _a.masterContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.key) === (reducer_constants_1.MASTER_DATA_KEY === null || reducer_constants_1.MASTER_DATA_KEY === void 0 ? void 0 : reducer_constants_1.MASTER_DATA_KEY.REQUEST_STATUS))) === null || _d === void 0 ? void 0 : _d.result;
    // const requestStatusType: any =
    //   common?.requestStatusTypeData?.requestStatusTypeList?.data;
    const filterStatusType = requestStatusType === null || requestStatusType === void 0 ? void 0 : requestStatusType.map((requestStatusItem) => {
        return {
            label: requestStatusItem === null || requestStatusItem === void 0 ? void 0 : requestStatusItem.name,
            id: ((requestStatusItem === null || requestStatusItem === void 0 ? void 0 : requestStatusItem.tid) && (requestStatusItem === null || requestStatusItem === void 0 ? void 0 : requestStatusItem.tid)) || "",
            field_unique_code: requestStatusItem === null || requestStatusItem === void 0 ? void 0 : requestStatusItem.field_unique_code,
            value: ((requestStatusItem === null || requestStatusItem === void 0 ? void 0 : requestStatusItem.tid) && (requestStatusItem === null || requestStatusItem === void 0 ? void 0 : requestStatusItem.tid)) || "",
        };
    });
    return filterStatusType;
});
exports.getMilestoneData = (0, toolkit_1.createSelector)((state) => state.common, (common) => {
    var _a, _b, _c, _d;
    const milestones = (_d = (_c = (_b = (_a = common === null || common === void 0 ? void 0 : common.masterData) === null || _a === void 0 ? void 0 : _a.masterContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.key) === (reducer_constants_1.MASTER_DATA_KEY === null || reducer_constants_1.MASTER_DATA_KEY === void 0 ? void 0 : reducer_constants_1.MASTER_DATA_KEY.MILESTONES))) === null || _d === void 0 ? void 0 : _d.result;
    const data = milestones === null || milestones === void 0 ? void 0 : milestones.map((obj) => {
        return Object.assign(Object.assign({}, obj), { points: parseInt(obj.field_points) });
    });
    return data;
});
exports.getAllDiscussionTypes = (0, toolkit_1.createSelector)((state) => state.common, (common) => {
    var _a, _b, _c, _d;
    const communityCategoryList = (_d = (_c = (_b = (_a = common === null || common === void 0 ? void 0 : common.masterData) === null || _a === void 0 ? void 0 : _a.masterContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.key) === (reducer_constants_1.MASTER_DATA_KEY === null || reducer_constants_1.MASTER_DATA_KEY === void 0 ? void 0 : reducer_constants_1.MASTER_DATA_KEY.COMMUNITY_CATEGORY))) === null || _d === void 0 ? void 0 : _d.result;
    return communityCategoryList === null || communityCategoryList === void 0 ? void 0 : communityCategoryList.map((item) => {
        return {
            label: item === null || item === void 0 ? void 0 : item.name,
            value: ((item === null || item === void 0 ? void 0 : item.tid) && (item === null || item === void 0 ? void 0 : item.tid)) || "",
            field_unique_code: item === null || item === void 0 ? void 0 : item.field_unique_code
        };
    });
});
exports.listGamificationPointDistribution = (0, toolkit_1.createSelector)((state) => state.common, (common) => {
    var _a, _b, _c, _d;
    const gamificationList = (_d = (_c = (_b = (_a = common === null || common === void 0 ? void 0 : common.masterData) === null || _a === void 0 ? void 0 : _a.masterContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.key) === (reducer_constants_1.MASTER_DATA_KEY === null || reducer_constants_1.MASTER_DATA_KEY === void 0 ? void 0 : reducer_constants_1.MASTER_DATA_KEY.USER_GAMIFICATION))) === null || _d === void 0 ? void 0 : _d.result;
    if (gamificationList && (gamificationList === null || gamificationList === void 0 ? void 0 : gamificationList.length) > 0) {
        return gamificationList === null || gamificationList === void 0 ? void 0 : gamificationList.filter((item) => (item === null || item === void 0 ? void 0 : item.field_unique_code) !== utils_2.GAMIFICATION_TYPE_UNIQUE_CODE.REDEEM_POINTS);
    }
    else {
        return gamificationList;
    }
});
exports.getDeactivateReasons = (0, toolkit_1.createSelector)((state) => state.common, (common) => {
    var _a, _b, _c, _d;
    const deactivateReasonList = (_d = (_c = (_b = (_a = common === null || common === void 0 ? void 0 : common.masterData) === null || _a === void 0 ? void 0 : _a.masterContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.key) === (reducer_constants_1.MASTER_DATA_KEY === null || reducer_constants_1.MASTER_DATA_KEY === void 0 ? void 0 : reducer_constants_1.MASTER_DATA_KEY.DEACTIVATE_REASON))) === null || _d === void 0 ? void 0 : _d.result;
    return deactivateReasonList === null || deactivateReasonList === void 0 ? void 0 : deactivateReasonList.map((item) => {
        return {
            value: ((item === null || item === void 0 ? void 0 : item.tid) && (item === null || item === void 0 ? void 0 : item.tid)) || "",
            label: (item === null || item === void 0 ? void 0 : item.title) || "",
        };
    });
});
exports.getMedicalCalculator = (0, toolkit_1.createSelector)((state) => state.common, (common) => {
    var _a, _b, _c, _d;
    const deactivateReasonList = (_d = (_c = (_b = (_a = common === null || common === void 0 ? void 0 : common.masterData) === null || _a === void 0 ? void 0 : _a.masterContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.key) === (reducer_constants_1.MASTER_DATA_KEY === null || reducer_constants_1.MASTER_DATA_KEY === void 0 ? void 0 : reducer_constants_1.MASTER_DATA_KEY.LIST_OF_CALCULATOR))) === null || _d === void 0 ? void 0 : _d.result;
    return deactivateReasonList;
});
exports.getContentTypesListForRequest = (0, toolkit_1.createSelector)((state) => state.common, (common) => {
    var _a, _b, _c, _d;
    const contentTypeList = (_d = (_c = (_b = (_a = common === null || common === void 0 ? void 0 : common.masterData) === null || _a === void 0 ? void 0 : _a.masterContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.key) === (reducer_constants_1.MASTER_DATA_KEY === null || reducer_constants_1.MASTER_DATA_KEY === void 0 ? void 0 : reducer_constants_1.MASTER_DATA_KEY.REQUEST_CONTENT_TYPES))) === null || _d === void 0 ? void 0 : _d.result;
    return contentTypeList === null || contentTypeList === void 0 ? void 0 : contentTypeList.map((requestItem) => {
        return {
            label: requestItem === null || requestItem === void 0 ? void 0 : requestItem.name,
            value: ((requestItem === null || requestItem === void 0 ? void 0 : requestItem.tid) && (requestItem === null || requestItem === void 0 ? void 0 : requestItem.tid)) || "",
            field_unique_code: requestItem === null || requestItem === void 0 ? void 0 : requestItem.field_unique_code,
        };
    });
});
exports.getRecognitionBadgesData = (0, toolkit_1.createSelector)((state) => state.common, (common) => {
    var _a, _b, _c, _d;
    const recognitionBadgesList = (_d = (_c = (_b = (_a = common === null || common === void 0 ? void 0 : common.masterData) === null || _a === void 0 ? void 0 : _a.masterContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.key) === (reducer_constants_1.MASTER_DATA_KEY === null || reducer_constants_1.MASTER_DATA_KEY === void 0 ? void 0 : reducer_constants_1.MASTER_DATA_KEY.GAMIFICATION_RECOGNITION_BADGES_LIST))) === null || _d === void 0 ? void 0 : _d.result;
    return recognitionBadgesList;
});
exports.getRedemptionConfig = (0, toolkit_1.createSelector)((state) => state.common, (common) => {
    var _a, _b, _c, _d;
    const configList = (_d = (_c = (_b = (_a = common === null || common === void 0 ? void 0 : common.masterData) === null || _a === void 0 ? void 0 : _a.masterContent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find((item) => (item === null || item === void 0 ? void 0 : item.key) === (reducer_constants_1.MASTER_DATA_KEY === null || reducer_constants_1.MASTER_DATA_KEY === void 0 ? void 0 : reducer_constants_1.MASTER_DATA_KEY.GAMIFICATION_MASTER_KEYS))) === null || _d === void 0 ? void 0 : _d.result;
    return configList;
});
exports.getIsCommunityJoinedFromHome = (0, toolkit_1.createSelector)((state) => state.common, (common) => common === null || common === void 0 ? void 0 : common.isCommunityJoinedFromHome);
_a = commonSlice.actions, exports.updateLanguage = _a.updateLanguage, exports.getMobileLength = _a.getMobileLength, exports.setIsOffline = _a.setIsOffline, exports.setIsLoggedIn = _a.setIsLoggedIn, exports.updateProfileImage = _a.updateProfileImage, exports.setFiltersData = _a.setFiltersData, exports.resetFiltersData = _a.resetFiltersData, exports.updateLanguageForWeb = _a.updateLanguageForWeb, exports.handleRecognitionPopup = _a.handleRecognitionPopup, exports.setIsCommunityJoinedFromHome = _a.setIsCommunityJoinedFromHome, exports.resetIsCommunityJoinedFromHome = _a.resetIsCommunityJoinedFromHome;
exports.default = commonSlice.reducer;
