import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  ImageConstant,
  useAppSelector,
  AppConstant,
  translate,
  getPreferredLanguagesList
} from '@shalina-app/shared';

import { MobileMenuProps } from '.';
import styles from './mobileMenu.module.scss';
import { SearchBar } from '../searchBar';
import {
  retrieveData,
  STORAGE_CONSTANTS,
  useNavigatePageUtils,
  userSignOut
} from '../../../../utils';
import { Notification } from '../notification';
import { NavBar } from '../navBar';
import { URLConstant } from '../../../../routes';
import {
  ABOUT_US_PAGE_TABS,
  pageNames,
  PROFILE_PAGE_TABS
} from '../../../../app.constants';
import { LangSelector } from '../languageSelector';
import { SignUpPopup } from '../../../Authentication';

export const MobileMenuPage: React.FC<MobileMenuProps> = ({
  isUnreadNotification
}) => {
  const t = translate();
  const { urls } = URLConstant;
  const location = useLocation();
  const pathName = location?.pathname;
  const pathNameSplit = pathName?.split('/');
  const navigatePageUtils = useNavigatePageUtils();

  const isLoggedIn: boolean = retrieveData(STORAGE_CONSTANTS.IS_LOGGED_IN);

  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isActive, setActive] = useState<boolean>(false);
  const [showSignUp, setShowSignUp] = useState(false);

  /* Language start */
  const preferredLanguagesList = useAppSelector(getPreferredLanguagesList);
  /* Language end */

  /* hamburger click start */
  const hamburgerClick = () => {
    setActive(!isActive);
  };
  /* hamburger click end */

  /* log in start */
  const handleSignUpFlow = () => {
    setShowSignUp(true);
    setActive(false);
  };
  const handleSignUpClose = () => {
    setShowSignUp(false);
  };
  /* log in end */

  /* notification start */
  const notificationCount =
    useAppSelector((state) => state?.notification?.totalCount) || 0;
  /* notification end */

  /* navigation start */
  const menuList = [
    {
      menuName: `${t(AppConstant.HEADER.HOME_MENU)}`,
      route: urls.homePage,
      isActive:
        pathName === urls.homePage ||
        pathName === urls.termsAndConditionsPage ||
        pathName === urls.searchPage
          ? true
          : false,
      state: null,
      allowGuestUser: true
    },
    {
      menuName: `${t(AppConstant.HEADER.KNOWLEDGE_HUB_MENU)}`,
      route: urls.knowledgeHubPage,
      isActive:
        pathName === urls.knowledgeHubPage ||
        pathNameSplit?.includes(pageNames.KnowledgeHub)
          ? true
          : false,
      state: null,
      allowGuestUser: true
    },
    {
      menuName: `${t(AppConstant.HEADER.SHALINA_TV_MENU)}`,
      route: urls.shalinaTv,
      isActive:
        pathName === urls.shalinaTv ||
        pathNameSplit?.includes(pageNames.ShalinaTv)
          ? true
          : false,
      state: null,
      allowGuestUser: true
    },
    {
      menuName: `${t(AppConstant.HEADER.LEARNING_CENTER_MENU)}`,
      route: urls.learningCenterPage,
      isActive:
        pathName === urls.learningCenterPage ||
        pathNameSplit?.includes(pageNames.LearningCenter)
          ? true
          : false,
      state: null,
      allowGuestUser: true
    },
    {
      menuName: `${t(AppConstant.HEADER.COMMUNITIES_MENU)}`,
      route: urls.commiunitiesPage,
      isActive: pathName === urls.commiunitiesPage ? true : false,
      state: null,
      allowGuestUser: true
    },
    {
      menuName: `${t(AppConstant.HOME_PAGE.MEDICAL_NEWS)}`,
      route: urls.rssListing,
      isActive:
        pathName === urls.rssListing || pathName === urls.rssDetail
          ? true
          : false,
      state: null,
      allowGuestUser: true
    },
    {
      menuName: `${t(AppConstant.COMMON.SURVEY)}`,
      route: urls.surveyPage,
      isActive:
        pathName === urls.surveyPage ||
        pathNameSplit?.includes(pageNames.surveyPage)
          ? true
          : false,
      state: null,
      allowGuestUser: true
    },
    {
      menuName: `${t(AppConstant.HEADER.MEDICAL_CALCULATOR_MENU)}`,
      route: urls.medicalCalculator,
      isActive: pathName === urls.medicalCalculator ? true : false,
      state: null,
      allowGuestUser: true
    },
    {
      menuName: `${t(AppConstant.HEADER.ABOUT_US_MENU)}`,
      route: urls.aboutUsPage,
      isActive: pathName === urls.aboutUsPage ? true : false,
      state: { tab: ABOUT_US_PAGE_TABS.WHO_WE_ARE },
      allowGuestUser: true
    },
    {
      menuName: `${t(AppConstant.HEADER.HELP_SUPPORT_MENU)}`,
      route: urls.helpSupport,
      isActive: pathName === urls.helpSupport ? true : false,
      state: null,
      allowGuestUser: true
    }
  ];

  const onLogoClick = () => {
    setActive(false);
    const navigateDetails = {
      isDynamicUrl: false
    };
    navigatePageUtils(urls.homePage, navigateDetails);
  };
  /* navigation end*/

  /* my profile start */
  const { AccountIcon, BellOrangeIcon, SettingIcon, StarIcon, SignOutIcon } =
    ImageConstant.SVG_ICONS;

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSignOut = async () => {
    setLoader(true);
    await userSignOut();
    const token = JSON.parse(retrieveData(STORAGE_CONSTANTS.ACCESS_TOEKN));

    const navigateUrl = urls.homePage;
    navigatePageUtils(navigateUrl, { isDynamicUrl: false });

    if (!token) {
      setLoader(false);
    }
  };
  /* my profile end */

  return (
    <>
      <div className={styles.mobileWrapper}>
        <div className={styles.logNavSec}>
          <img
            src={ImageConstant.SOURCE.ShalinaMedspaceLogo}
            alt='logo'
            className={styles.logo}
            onClick={onLogoClick}
          />
        </div>
        <div className={styles.moibileNav}>
          <SearchBar />
          <Notification
            isUnreadNotification={isUnreadNotification}
            totalCount={notificationCount}
            isLoggedIn={isLoggedIn}
            handleSignUpFlow={handleSignUpFlow}
          />
          <button className={styles.hamburgerIcon} onClick={hamburgerClick}>
            {isActive ? (
              <svg
                width='18'
                height='16'
                viewBox='0 0 18 18'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M17.75 2.0125L15.9875 0.25L9 7.2375L2.0125 0.25L0.25 2.0125L7.2375 9L0.25 15.9875L2.0125 17.75L9 10.7625L15.9875 17.75L17.75 15.9875L10.7625 9L17.75 2.0125Z'
                  fill='#002E5B'
                />
              </svg>
            ) : (
              <svg
                width='18'
                height='12'
                viewBox='0 0 18 12'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M0 12H18V10H0V12ZM0 7H18V5H0V7ZM0 0V2H18V0H0Z'
                  fill='#002E5B'
                />
              </svg>
            )}
          </button>
        </div>
      </div>

      <div className={`${styles.mobileDrower} ${isActive && styles.open}`}>
        <NavBar menuList={menuList} hamburgerClick={hamburgerClick} />
        {isLoggedIn ? (
          <div className={styles.userMenuList}>
            <ul>
              <li key={1} onClick={hamburgerClick}>
                <Link
                  to={urls.profilePage}
                  state={{ tab: PROFILE_PAGE_TABS.UPDATE_PROFILE }}
                >
                  <span className={styles.icon}>
                    <AccountIcon />
                  </span>
                  {t(AppConstant.HEADER.MY_PROFILE)}
                </Link>
              </li>
              <li key={2} onClick={hamburgerClick}>
                <Link
                  to={urls.profilePage}
                  state={{ tab: PROFILE_PAGE_TABS.NOTIFICATION_SETTING }}
                >
                  <span className={styles.icon}>
                    <BellOrangeIcon />
                  </span>
                  {t(AppConstant.PROFILE.NOTIFICATION_TAB_TEXT)}
                </Link>
              </li>
              <li key={3} onClick={hamburgerClick}>
                <Link
                  to={urls.profilePage}
                  state={{ tab: PROFILE_PAGE_TABS.ACCOUNT_SETTING }}
                >
                  <span className={styles.icon}>
                    <SettingIcon />
                  </span>
                  {t(AppConstant.PROFILE.ACCOUNT_SETTINGS_TAB_TEXT)}
                </Link>
              </li>
              <li key={4} onClick={hamburgerClick}>
                <a href='javascript:void(0)' onClick={handleClickOpen}>
                  <span className={styles.icon}>
                    <StarIcon />
                  </span>
                  {t(AppConstant.HEADER.REFER_A_FRIEND)}
                </a>
              </li>
              <li key={5} onClick={hamburgerClick}>
                <div
                  onClick={handleSignOut}
                  className={styles.userDropdownItem}
                >
                  <span className={styles.icon}>
                    <SignOutIcon />
                  </span>
                  {t(AppConstant.PROFILE.SIGN_OUT_TAB_TEXT)}
                </div>
              </li>
            </ul>
          </div>
        ) : (
          <div className={styles.userMenuList}>
            <ul>
              <li>
                <Link
                  to={urls.signUpPage}
                  className={styles.cta}
                  state={{ pathName: pathName }}
                >
                  {t(AppConstant.AUTHENTICATION_PAGE.SIGN_UP_CTA)}
                </Link>
              </li>
              <li>
                <Link
                  to={urls.signInPage}
                  className={styles.cta}
                  state={{ pathName: pathName }}
                >
                  {t(AppConstant.AUTHENTICATION_PAGE.SIGN_IN_CTA)}
                </Link>
              </li>
            </ul>
          </div>
        )}
        <div className={styles.menuLang}>
          <LangSelector
            preferredLanguages={preferredLanguagesList}
            isFooter={false}
          />
        </div>
      </div>

      <SignUpPopup open={showSignUp} handleClose={handleSignUpClose} />
    </>
  );
};
