"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimeIcon = void 0;
const React = __importStar(require("react"));
const TimeIcon = (props) => (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: 13, height: 15, fill: "none" }, props),
    React.createElement("g", { fill: "#1D3256", clipPath: "url(#a)" },
        React.createElement("path", { d: "M7.098 5.52a.706.706 0 0 0-.707-.705.706.706 0 0 0-.707.704v2.694c0 .39.316.704.707.704.39 0 .707-.315.707-.704V5.52Z" }),
        React.createElement("path", { d: "m10.85 4.083.99-.985a.706.706 0 0 0 0-.995.713.713 0 0 0-.999 0L9.73 3.21a6.395 6.395 0 0 0-3.337-.938C2.866 2.272 0 5.135 0 8.636s2.866 6.355 6.392 6.355c3.525.009 6.391-2.845 6.391-6.355a6.338 6.338 0 0 0-1.932-4.553Zm-4.458 9.509c-2.744 0-4.978-2.225-4.978-4.956 0-2.732 2.234-4.956 4.978-4.956 2.743 0 4.977 2.224 4.977 4.956 0 2.731-2.234 4.956-4.977 4.956ZM8.21 1.408c.391 0 .708-.315.708-.704A.706.706 0 0 0 8.211 0H4.563a.706.706 0 0 0-.707.704c0 .389.316.704.707.704H8.21Z" })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "a" },
            React.createElement("path", { fill: "#fff", d: "M0 0h13v15H0z" })))));
exports.TimeIcon = TimeIcon;
