"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterIcon = void 0;
const React = __importStar(require("react"));
const FilterIcon = (props) => (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: 20, height: 20, fill: "none" }, props),
    React.createElement("path", { fill: "#fff", d: "M9.167 16.667a.806.806 0 0 1-.594-.24.806.806 0 0 1-.24-.593v-5L3.5 4.667c-.208-.278-.24-.57-.094-.875.146-.306.4-.458.76-.458h11.668c.36 0 .614.152.76.458.146.305.115.597-.094.875l-4.833 6.167v5c0 .236-.08.433-.24.593a.806.806 0 0 1-.593.24H9.167ZM10 10.25 14.125 5h-8.25L10 10.25Z" })));
exports.FilterIcon = FilterIcon;
