import { useEffect, useState } from 'react';
import {
  AppConstant,
  ImageConstant,
  getOnlyDate,
  getOnlyTime,
  translate,
  useAppDispatch,
  getFileNameFromUrl
} from '@shalina-app/shared';
import parse from 'html-react-parser';

import { Toast } from '../../../../components';
import { RequestDetailsItemProps } from './requestDetails.interface';
import styles from './requestDetails.module.scss';
import { IToastBody, RequestFormComponent } from '../../../Header';
import { RequestRevokeDialog } from '../requestRevoke';
import { ImageShowDialogComponent } from '../../../../components/imageShowDialog';
import { getImageSrc } from '../../../../utils';

export const RequestDetailsComponent: React.FC<RequestDetailsItemProps> = ({
  handleBackClick,
  id,
  details,
}) => {
  const { TimeIcon, DateIcon, DropDownArrowIcon, UploadIcon } =
    ImageConstant.SVG_ICONS;
  const dispatch = useAppDispatch();
  const t = translate();
  // states
  const [modifiedDialog, setModifiedDialog] = useState(false);
  const [revokePopup, setRevokePopup] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [imageName, setImageName] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastSubMessage, setToastSubMessage] = useState('');
  const [showImagePopUp, setShowImagePopUp] = useState(false);

  // useEffects
  useEffect(() => {
    setRefresh(true);
    return () => {
      details = {};
    };
  }, []);

  useEffect(() => {
    if (refresh) {
      // loadApi();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    if (details?.nid) {
      const imageName = details?.field_attachment
        ? getFileNameFromUrl(details?.field_attachment)
        : '';
      setImageName(imageName);
    }
  }, [details]);
  // functions
  // const loadApi = async () => {
  // 	try {
  // 		setIsLoading(true);
  // 		const response = await dispatch(fetchRequestDetails(id)).unwrap() as any;
  // 		if (response &&
  // 			response?.status === "SUCCESS" &&
  // 			response?.statusCode === 200 &&
  // 			response?.data) {
  // 			setDetails(response?.data[0]);
  // 			const urlParts = response?.data[0]?.field_attachment?.split('/');
  // 			// Extract the last part of the array, which is the image name
  // 			const imageName = urlParts[urlParts.length - 1];
  // 			setImageName(imageName);
  // 		}
  // 	} finally {
  // 		setIsLoading(false);
  // 	}
  // }

  const handleModifiedDialogOpen = () => {
    setModifiedDialog(true);
  };

  const handleModifiedDialogClose = () => {
    setModifiedDialog(false);
    setRefresh(true);
  };

  const handleRevokeDialog = () => {
    setRevokePopup(true);
  };

  const handleRevoke = () => {
    setRevokePopup(false);
  };

  const handleSuccessClose = () => {
    setRefresh(true);
  };

  const handleToast = (body: IToastBody) => {
    handleBackSuccessRevoke({
      isSuccess: body.isSuccess,
      showToast: body.showToast,
      toastMainMessage: body.toastMainMessage,
    });
    // setShowToast(body.showToast);
    // setIsSuccess(body.isSuccess);
    // setToastMessage(body.toastMainMessage);
  };

  const handleBackSuccessRevoke = (toast: IToastBody) => {
    handleBackClick(toast);
  };

  const handleToastClose = () => {
    setShowToast(false);
    setToastMessage('');
  };

  const navigateToContent = (content: {
    id: string;
    content_type: string;
    module_type: string;
  }) => {
    const moduleTypePage = content?.module_type?.replace(/_/g, '-');
    window.location.href = `/${moduleTypePage}/${content?.content_type}/${content.id}`;
  };

  return (
    <div>
      {/* <div className={styles.reqDetaiLoader}>{isLoading && <BarLoader />}</div> */}
      <div className={styles.reqDetail}>
        {/* {isLoading && <CircularLoader size={50} thickness={4} />} */}
        {/* <DropDownArrowIcon /> */}
        <div className={styles.backToDetail}>
          <a onClick={() => handleBackClick()}>
            {t(AppConstant.HOME_PAGE.REQUESTS)}
          </a>
          <span>{`>`}</span>
          <strong>{t(AppConstant.HOME_PAGE.REQUEST_DETAILS)}</strong>
        </div>
        {/* <h2 className={styles.title}>{t(AppConstant.HOME_PAGE.REQUEST_DETAILS)}</h2> */}
        {details?.title && (
          <h2 className={styles.title}>{details?.field_request_type}</h2>
        )}
        {details?.title && (
          <>
            {/* <div className={styles.subTitle}>{details?.title}</div> */}

            <div className={styles.detailList}>
              {/* <div className={styles.detailItem}>
								<h4 className={styles.detailListtitle}>{t(AppConstant.HOME_PAGE.REQUEST_TYPE)}</h4>
								<div className={styles.detailListsubText}>{details?.field_request_type}</div>
							</div> */}

              {details?.field_content_type && (
                <div className={styles.detailItem}>
                  <h4 className={styles.detailListtitle}>
                    {t(AppConstant.HOME_PAGE.CONTENT_TYPE)}
                  </h4>
                  <div className={styles.detailListsubText}>
                    {details?.field_content_type}
                  </div>
                </div>
              )}

              <div className={styles.detailItem}>
                <h4 className={styles.detailListtitle}>
                  {t(AppConstant.HOME_PAGE.DESCRIPTION_TEXT)}
                </h4>
                <div className={styles.detailListsubText}>
                  {details?.field_description}
                </div>
              </div>

              <div className={styles.detailItem}>
                <div className={styles.reqStatus}>
                  {details?.field_update_request_status?.toLowerCase() ===
                  'open' ? (
                    <>
                      <div className={styles.reqStep}>
                        <svg
                          width='16'
                          height='16'
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle cx='8' cy='8' r='8' fill='#55B844' />
                          <path
                            d='M4 8.2L6.66667 11L12 5'
                            stroke='white'
                            stroke-width='2'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                          />
                        </svg>
                        <div className={styles.text}>
                          {t(AppConstant.HOME_PAGE.REQUEST_RAISED)}
                        </div>
                        <span>
                          {getOnlyDate(details?.field_request_raised)} |{' '}
                          {getOnlyTime(details?.field_request_raised)}
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={styles.reqStep}>
                        <svg
                          width='16'
                          height='16'
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle cx='8' cy='8' r='8' fill='#55B844' />
                          <path
                            d='M4 8.2L6.66667 11L12 5'
                            stroke='white'
                            stroke-width='2'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                          />
                        </svg>
                        <div className={styles.text}>
                          {t(AppConstant.HOME_PAGE.REQUEST_RAISED)}
                          <span>
                            {getOnlyDate(details?.field_request_raised)} |{' '}
                            {getOnlyTime(details?.field_request_raised)}
                          </span>
                        </div>
                      </div>
                      <div className={styles.reqStep}>
                        <svg
                          width='16'
                          height='16'
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle cx='8' cy='8' r='8' fill='#55B844' />
                          <path
                            d='M4 8.2L6.66667 11L12 5'
                            stroke='white'
                            stroke-width='2'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                          />
                        </svg>
                        <div className={styles.text}>
                          {details?.field_update_request_status?.toLowerCase() ===
                          'in progress'
                            ? t(AppConstant.COMMON.REQUEST_INPROGRESS)
                            : details?.field_update_request_status?.toLowerCase() ===
                              'revoked'
                            ? t(AppConstant.COMMON.REQUEST_REVOKED)
                            : t(AppConstant.HOME_PAGE.REQUEST_CLOSED)}
                          <span>
                            {details?.field_request_closed && (
                              <>
                                {getOnlyDate(details?.field_request_closed)} |{' '}
                                {getOnlyTime(details?.field_request_closed)}
                              </>
                            )}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>

              {((details?.field_requested_content_link &&
                details?.field_requested_content_link?.length > 0) ||
                details?.field_comments) && (
                <div className={styles.detailItem}>
                  {details?.field_requested_content_link &&
                    details?.field_requested_content_link?.length > 0 && (
                      <>
                        <h4 className={styles.detailListtitle}>
                          {t(AppConstant.COMMON.REQUESTED_CONTENT_DETAILS)}
                        </h4>
                        {details?.field_requested_content_link?.map(
                          (itemValue, indx) => (
                            <div className={styles.uploadedImageWrapper}>
                              <p
                                onClick={() => navigateToContent(itemValue)}
                              >{`${itemValue.content_type_name}: ${itemValue.title}`}</p>
                            </div>
                          )
                        )}
                      </>
                    )}
                  {details?.field_comments && (
                    <div className={styles.noteMsg}>
                      <p className={styles.detailListtitle}>
                        {t(AppConstant.COMMON.NOTES)}
                      </p>
                      <span>{parse(details?.field_comments)}</span>
                    </div>
                  )}
                </div>
              )}

              {details?.field_attachment && (
                <div className={styles.detailItem}>
                  <h4 className={styles.detailListtitle}>
                    {t(AppConstant.HOME_PAGE.ATTACHMENT)}
                  </h4>
                  <div
                    className={styles.uploadedImageWrapper}
                    onClick={() => setShowImagePopUp(true)}
                  >
                    <p>{imageName}</p>
                  </div>
                </div>
              )}
            </div>
            {details?.field_update_request_status === 'Open' && (
              <div className={styles.detailCtas}>
                <button onClick={handleRevokeDialog}>
                  {t(AppConstant.HOME_PAGE.REVOKE_CTA)}
                </button>
                <button
                  className={styles.modify}
                  onClick={handleModifiedDialogOpen}
                >
                  {t(AppConstant.HOME_PAGE.MODIFY_CTA)}
                </button>
              </div>
            )}
          </>
        )}
      </div>
      {modifiedDialog && (
        <RequestFormComponent
          handleSubmit={() => {}}
          modifiedDialogClose={handleModifiedDialogClose}
          showForm={false}
          isModifyRequest={modifiedDialog}
          data={details}
          hadleSuccessClose={handleSuccessClose}
          handleToast={handleToast}
        />
      )}

      {revokePopup && (
        <RequestRevokeDialog
          closeRevokeDialog={handleRevoke}
          handleRevoke={handleBackSuccessRevoke}
          openRevokeDialog={revokePopup}
          nid={details?.nid as string}
        />
      )}
      <Toast
        showToast={showToast}
        isSuccess={isSuccess}
        message={toastMessage}
        subMessage={toastSubMessage}
        handleToastClose={handleToastClose}
      />

      {showImagePopUp && (
        <ImageShowDialogComponent
          imageUrl={
            details?.field_attachment
              ? getImageSrc(details?.field_attachment)
              : ''
          }
          onClose={() => setShowImagePopUp(false)}
        />
      )}
    </div>
  );
};
