import { Dialog, DialogContent } from '@mui/material';
import {
  AppConstant,
  CommunityStatus,
  COMMUNITY_ENABLED,
  CUSTOM_ANALYTICS_EVENT_NAMES,
  getAnalyticsUserDetails,
  getLoggedInUserInfo,
  leaveCommunity,
  logEvent,
  PlatformNames,
  translate,
  useAppDispatch,
  useAppSelector,
} from '@shalina-app/shared';
import { useRef, useState } from 'react';
import { appInsights } from '../../../../../applicationInsight';

import { ButtonComponent, Toast } from '../../../../../components';
import { retrieveData, STORAGE_CONSTANTS } from '../../../../../utils';
import styles from '../CommunityDetail.module.scss';

export const LeaveComponent: React.FC<any> = ({
  communityId,
  handleOnLeave,
  communityName,
}) => {
  const user = retrieveData(STORAGE_CONSTANTS.USER);
  const ref: any = useRef(null);
  const t = translate();
  const dispatch = useAppDispatch();

  const userDetails = useAppSelector(getLoggedInUserInfo);
  
  const [leaveDropdown, setLeaveDropdown] = useState(false);
  const [leaveCommunityPopup, setLeaveCommunityPopup] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const handleToastClose = () => {
    setShowToast(false);
  };

  // useEffect(() => {
  //   const handleClickOutside = (event: any) => {
  //     if (ref?.current && !ref?.current?.contains(event?.target)) {
  //       setLeaveDropdown(false);
  //     }
  //   };
  //   document.addEventListener("click", handleClickOutside, true);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside, true);
  //   };
  // }, []);

  const handleLeaveCommunity = () => {
    setLeaveDropdown(false);
    setLeaveCommunityPopup(true);
  };

  const handleDropdownClick = () => {
    setLeaveDropdown(!leaveDropdown);
  };

  const handleLeaveCommunityClose = () => {
    setLeaveCommunityPopup(false);
  };

  const handleNext = async () => {
    let payload = {
      community_id: parseInt(communityId),
    };
    const response = await dispatch(leaveCommunity(payload)).unwrap();
    if (
      response &&
      response?.status === 'SUCCESS' &&
      response?.statusCode === 200
    ) {
      setShowToast(true);
      setIsSuccess(true);
      setToastMessage(t(AppConstant.COMMUNITY.LEAVE_COMMUNITY_SUCCESSFULLY));
      // USER_COMMUNITY_STATUS

      if (COMMUNITY_ENABLED) {
        const userObj = userDetails || user;
        const userInfo = getAnalyticsUserDetails(userObj);
        logEvent(
          appInsights,
          'custom',
          CUSTOM_ANALYTICS_EVENT_NAMES.USER_COMMUNITY_STATUS,
          {
            userEmail: userInfo?.emailId,
            userMobile: userInfo?.mobile,
            userName: userInfo?.name,
            userQualification: userInfo?.qualification,
            userInterest: userInfo?.areaOfInterest,
            userSpecialization: userInfo?.specialization,
            userCountryOfPractice: userInfo?.countryOfPractice,
            userPreferredLanguage: userInfo?.preferredLanguage,
            platform: PlatformNames.WEB,
            communityName: communityName,
            communityId: communityId,
            status: CommunityStatus.LEFT,
          }
        );
      }
      handleOnLeave();
    }
    setLeaveCommunityPopup(false);
  };

  const leaveLoader = useAppSelector(
    (state) => state?.community?.leaveCommunity?.isLeaveCommunityUpdating
  );

  return (
    <>
      <div className={styles.detailTopDropdown}>
        <span
          className={styles.menuIcon}
          onClick={handleDropdownClick}
          ref={ref}
        >
          <svg
            width='20'
            height='4'
            viewBox='0 0 20 4'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <circle cx='2' cy='2' r='2' fill='#002E5B' />
            <circle cx='10' cy='2' r='2' fill='#002E5B' />
            <circle cx='18' cy='2' r='2' fill='#002E5B' />
          </svg>
        </span>
        {leaveDropdown && (
          <ul className={styles.dropdownItem} onClick={handleLeaveCommunity}>
            <li>
              <span>{t(AppConstant.COMMUNITY.LEAVE_COMMUNITY)}</span>
            </li>
          </ul>
        )}
      </div>
      <Dialog
        open={leaveCommunityPopup}
        onClose={handleLeaveCommunityClose}
        className={styles.leaveDilog}
      >
        <DialogContent>
          <div className={styles.leaveWrapper}>
            <h2 className={styles.title}>
              {t(AppConstant.COMMUNITY.LEAVE_COMMUNITY)}
            </h2>
            <p className={styles.msg}>
              {t(AppConstant.COMMUNITY.LEAVE_COMMUNITY_CONFIRMATION_TEXT)}
            </p>
            <div className={styles.ctaWrapper}>
              <div className={`${styles.ctaItem} ${styles.cancel}`}>
                <ButtonComponent
                  variant='outlined'
                  buttonText={t(AppConstant.PROFILE.CANCEL_CTA)}
                  onPress={handleLeaveCommunityClose}
                />
              </div>
              <div className={styles.ctaItem}>
                <ButtonComponent
                  variant='outlined'
                  buttonText={t(AppConstant.HOME_PAGE.SURE)}
                  onPress={handleNext}
                  loader={leaveLoader}
                />
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Toast
        showToast={showToast}
        isSuccess={isSuccess}
        message={toastMessage}
        handleToastClose={handleToastClose}
      />
    </>
  );
};
