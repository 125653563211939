import { useEffect, useState } from 'react';
import {
  AppConstant,
  getIconForContentType,
  ImageConstant,
  translate,
  updateBookmark,
  useAppDispatch,
  useAppSelector,
  getHideViewsFlag,
  getShowDurationFlag,
  getHideInterestFlag,
  getFilterCategoriesOptionsValues,
  getHideAuthor,
  hideDate,
  hideTime,
  getDefaultImage,
  CONTENT_TYPE_ID,
  getExtraCardContent,
  EXTRA_CARD_CONTENT_STRING,
  hideImage,
  logEvent,
  CUSTOM_ANALYTICS_EVENT_NAMES,
  getDefaultValue,
  PlatformNames,
  getAnalyticsUserDetails,
  getLoggedInUserInfo,
  updateArticleSearchKeyword
} from '@shalina-app/shared';

import { CardProps } from '.';
import { Speciality } from '..';
import { pageNames } from '../../../app.constants';
import { appInsights } from '../../../applicationInsight';
import { SignUpPopup } from '../../../features';
import { URLConstant } from '../../../routes';
import {
  getImageSrc,
  getPageNameByCategory,
  removedFromStore,
  retrieveData,
  STORAGE_CONSTANTS,
  storeData,
  useNavigatePageUtils,
  UserInterface
} from '../../../utils';
import styles from './card.module.scss';

export const Card: React.FC<CardProps> = ({
  id,
  contentType,
  contentTypeId,
  title,
  speciality,
  date,
  time,
  publishedName,
  viewsCount,
  duration,
  pageName,
  pageRoute,
  middleRoute,
  thumbnailImage,
  isBookmarked,
  mostViewed,
  invokeCardClick,
  hasFullWidth,
  bookmarkRemove,
  eventId,
  uniqueContentTypeId,
  category,
  tags
}) => {
  const t = translate();
  const dispatch = useAppDispatch();
  const navigatePageUtils = useNavigatePageUtils();
  const { urls } = URLConstant;
  const isLoggedIn: boolean = retrieveData(STORAGE_CONSTANTS.IS_LOGGED_IN);
  const user: UserInterface | any = retrieveData(STORAGE_CONSTANTS.USER);
  const userDetails = useAppSelector(getLoggedInUserInfo);

  const [showSignUp, setShowSignUp] = useState(false);
  const [bookmarked, setBookmarked] = useState(isBookmarked);
  const [contentCategory, setContentCategory] = useState('');

  const contentIcon: any = useAppSelector(
    getIconForContentType(uniqueContentTypeId)
  );
  const masterContentTypeData = useAppSelector(
    getFilterCategoriesOptionsValues
  );

  useEffect(() => {
    setBookmarked(isBookmarked);
  }, [isBookmarked]);

  const handleSignUpClose = () => {
    setShowSignUp(false);
  };

  const handleSignUpFlow = () => {
    setShowSignUp(true);
  };

  useEffect(() => {
    const category = masterContentTypeData?.find(
      (item: any) => item?.unique_id === uniqueContentTypeId
    )?.category;

    setContentCategory(category);
  }, [masterContentTypeData]);

  const handleCardClick = () => {
    const category = masterContentTypeData?.find(
      (item: any) => item?.value === contentTypeId
    )?.category;

    const page = getPageNameByCategory(category);
    
    dispatch(updateArticleSearchKeyword(''));

    // if (eventId) {
    //   storeData(STORAGE_CONSTANTS.EVENT_ID, eventId);
    // } else if (retrieveData(STORAGE_CONSTANTS.EVENT_ID)) {
    //   removedFromStore(STORAGE_CONSTANTS.EVENT_ID);
    // }
    if (page !== pageNames.Home && page) {
      if (id && contentTypeId) {
        const navigateDetails = {
          stateParams: {
            eventID: eventId || ''
          },
          isDynamicUrl: true,
          dynamicUrlOptions: {
            pageName: page,
            contentId: id,
            contentTypeId: contentTypeId
          }
        };
        navigatePageUtils(urls.detailPageWithModule, navigateDetails);
      }
    } else {
      if (id && contentTypeId) {
        const navigateDetails = {
          isDynamicUrl: true,
          dynamicUrlOptions: {
            contentId: id,
            contentTypeId: contentTypeId
          }
        };
        navigatePageUtils(urls.detailPage, navigateDetails);
      }
    }
    if (invokeCardClick) {
      invokeCardClick();
    }
  };

  const handleBookmarkClick = (id: number | string) => {
    if (!isLoggedIn) {
      handleSignUpFlow();
    } else if(id && title && user) {
      const userObj = userDetails || user;
      const userInfo = getAnalyticsUserDetails(userObj);
      logEvent(
        appInsights,
        'custom',
        CUSTOM_ANALYTICS_EVENT_NAMES.USER_BOOKMARK_CONTENT,
        {
          contentId: id,
          contentTitle: title,
          userEmail: userInfo?.emailId,
          userMobile: userInfo?.mobile,
          userName: userInfo?.name,
          userQualification: userInfo?.qualification,
          userInterest: userInfo?.areaOfInterest,
          userSpecialization: userInfo?.specialization,
          userCountryOfPractice: userInfo?.countryOfPractice,
          userPreferredLanguage: userInfo?.preferredLanguage,
          platform: PlatformNames.WEB
        }
      );
      const req = {
        id: String(id)
      };
      dispatch(updateBookmark(req));

      if (pageName === pageNames.Profile) {
        setBookmarked(!bookmarked);
        if (bookmarkRemove) {
          bookmarkRemove(id);
        }
      } else {
        setBookmarked(!bookmarked);
      }
    }
  };

  const {
    ViewsIcon,
    DurationIcon,
    TimeIcon,
    DateIcon,
    AuthorIcon,
    BookmarkIcon,
    TagIcon
  } = ImageConstant.SVG_ICONS;

  const dateHide = hideDate(category, uniqueContentTypeId);
  const timeHide = hideTime(category, uniqueContentTypeId);
  const hideViews = getHideViewsFlag(category, uniqueContentTypeId);
  const hideInterest = getHideInterestFlag(category, uniqueContentTypeId);
  const showDuration = getShowDurationFlag(category, uniqueContentTypeId);
  const hideAuthor = getHideAuthor(category, uniqueContentTypeId);

  const extraContent = getExtraCardContent(
    contentCategory,
    uniqueContentTypeId
  );

  const renderImage = hideImage(category, uniqueContentTypeId);

  return (
    <div
      className={`${styles.cardContainer} ${hasFullWidth && styles.fullWidth}`}
    >
      <div className={styles.cardWrapper}>
        {mostViewed && (
          <span className={styles.mostViewed}>
            {t(AppConstant.COMMON.MOST_VIEWED_TEXT)}
          </span>
        )}

        <div className={styles.contentType}>
          {contentType && (
            <>
              {contentIcon ? (
                <img src={getImageSrc(contentIcon)} alt='content icon' />
              ) : null}
              <span className={`${mostViewed && styles.ismostView}`}>
                {contentType}
              </span>
            </>
          )}
        </div>

        <div className={styles.titleImg}>
          {title && (
            <h4
              className={`${styles.cardTitle} ${
                renderImage ? styles.noImg : ''
              }`}
              onClick={handleCardClick}
            >
              {title}
            </h4>
          )}
          {!renderImage ? (
            thumbnailImage ? (
              <img
                src={thumbnailImage}
                alt='card img'
                onClick={handleCardClick}
                onError={(e) =>
                  ((e.target as HTMLInputElement).src = getDefaultImage(
                    false,
                    contentCategory,
                    uniqueContentTypeId
                  ))
                }
              />
            ) : (
              <img
                src={getDefaultImage(
                  false,
                  contentCategory,
                  uniqueContentTypeId
                )}
                alt='card img'
                onClick={handleCardClick}
              />
            )
          ) : (
            ''
          )}
        </div>
        <div className={styles.heightWrapper}>
          <div className={styles.speciality}>
            {!hideInterest && speciality && (
              <Speciality speciality={speciality} showAll={false} />
            )}
          </div>

          {extraContent === EXTRA_CARD_CONTENT_STRING.DURATION ? (
            <div className={styles.publishedName}>
              <DurationIcon />
              <span>{t(AppConstant.COMMON.DURATION)}</span>
              {duration}
            </div>
          ) : // : extraContent === EXTRA_CARD_CONTENT_STRING.DATE ? (
          //   <div className={styles.dateTime}>
          //     <div className={styles.date}>
          //     <DateIcon />
          //     <span>{t(AppConstant.COMMON.DATE)} </span>
          //     {date}
          //     </div>
          //   </div>
          // )
          extraContent === EXTRA_CARD_CONTENT_STRING.AUTHOR ? (
            <div className={styles.publishedName}>
              {!hideAuthor && publishedName && (
                <>
                  <AuthorIcon />
                  <span>
                    <strong>{t(AppConstant.COMMON.BY_TEXT)}</strong>
                    {publishedName || getDefaultValue()}
                  </span>
                </>
              )}
            </div>
          ) : extraContent === EXTRA_CARD_CONTENT_STRING.TAGS && tags ? (
            <div className={styles.publishedName}>
              <>
                <TagIcon />
                <span>{tags}</span>
              </>
            </div>
          ) : (
            ''
          )}

          <div className={styles.dateTime}>
            <>
              {!dateHide && date && (
                <div className={styles.date}>
                  <DateIcon />
                  <span>{t(AppConstant.COMMON.DATE)} </span>
                  {date}
                </div>
              )}
              {!timeHide &&
                time &&
                (uniqueContentTypeId === CONTENT_TYPE_ID?.WEBINARS ? (
                  <div className={styles.time} title={time}>
                    <TimeIcon />
                    <span>{t(AppConstant.COMMON.TIME)} </span>
                    <span className={styles.utcTime}>{time}</span>
                  </div>
                ) : (
                  <div className={styles.time}>
                    <TimeIcon />
                    <span>{t(AppConstant.COMMON.TIME)} </span>
                    {time}
                  </div>
                ))}
            </>
          </div>
        </div>

        <div className={styles.viewBookmark}>
          {
            // uniqueContentTypeId === CONTENT_TYPE_ID.RESOURCES ? (
            //   <div className={styles.bottomAuthor}>
            //     {!hideAuthor && (
            //       <>
            //         <AuthorIcon />
            //         <span>
            //           <strong>{t(AppConstant.COMMON.BY_TEXT)}</strong>
            //           {publishedName || NOT_AVAILABLE_STRING}
            //         </span>
            //       </>
            //     )}
            //   </div>
            // ) :
            <>
              <div className={styles.viewsDuration}>
                {!hideViews && (
                  <div className={styles.views}>
                    <ViewsIcon />
                    <span>{t(AppConstant.COMMON.VIEWS)}</span>
                    {viewsCount ? viewsCount : 0}
                  </div>
                )}
                {uniqueContentTypeId === CONTENT_TYPE_ID.WEBINARS &&
                  showDuration &&
                  duration && (
                    <div className={styles.duration}>
                      <DurationIcon />
                      <span>{t(AppConstant.COMMON.DURATION)}</span>
                      {duration}
                    </div>
                  )}
              </div>
              <div
                className={`${styles.bookmark} ${
                  bookmarked && isLoggedIn && styles.activeBookmark
                }`}
                onClick={() => {
                  handleBookmarkClick(id);
                }} // handleBookmark
              >
                <BookmarkIcon />
              </div>
            </>
          }
        </div>
      </div>
      <SignUpPopup open={showSignUp} handleClose={handleSignUpClose} />
    </div>
  );
};
