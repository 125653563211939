import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';

import { CustomTabProps, TabPanelProps } from '.';
import styles from './tabs.module.scss';

export const CustomTabs: React.FC<CustomTabProps> = ({
  tabs,
  tabValue,
  handleTabChange
}) => {
  const CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  const tabProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  };

  return (
    <>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        className={styles.custTabs}
      >
        {tabs?.map((tab: any) => (
          <Tab label={tab?.label} {...tabProps(tab?.id)} />
        ))}
      </Tabs>
      <div className={styles.custTabContent}>
        {tabs?.map((tab: any) => (
          <CustomTabPanel value={tabValue} index={tab?.id}>
            {tab?.component}
          </CustomTabPanel>
        ))}
      </div>
    </>
  );
};
