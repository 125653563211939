import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  api,
  useAppDispatch,
  fetchAllMasterData,
  LANGUAGE_PREFERENCE,
  getUserDetails,
  updateProfileImage,
  updateLanguageForWeb,
  changeLanguage,
  logEvent,
  CUSTOM_ANALYTICS_EVENT_NAMES,
  PlatformNames,
  SESSION_INTERVAL_TIME,
  GuestString,
  getAnalyticsUserDetails,
  getLoggedInUserInfo,
  useAppSelector,
  differenceBetweenTimestamp,
  isEmptyCheck,
  useI18n
} from '@shalina-app/shared';
import secureLocalStorage from 'react-secure-storage';

import { RoutesComponent, URLConstant } from './routes';
import './App.scss';
import {
  removedFromStore,
  retrieveData,
  STORAGE_CONSTANTS,
  storeData,
  UserInterface,
} from './utils';
import { appInsights } from './applicationInsight';
import { RecognitionPopup } from './components';
import moment from 'moment';
import { useIdle } from "react-use";

function App() {
  const i18n = useI18n();
  const dispatch = useAppDispatch();
  const isIdle: any = useIdle(SESSION_INTERVAL_TIME);
  const [callMasterData, setCallMasterData] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const userDetails = useAppSelector(getLoggedInUserInfo);
  const isLoggedIn: boolean = retrieveData(STORAGE_CONSTANTS.IS_LOGGED_IN);
  const user: UserInterface | any = retrieveData(STORAGE_CONSTANTS.USER);
  const localStorageLang = retrieveData(STORAGE_CONSTANTS.SELECTED_LANGUAGE);

  useEffect(() => {
    setCallMasterData(true);
  }, []);

  useEffect(() => {
    let activityTimer: NodeJS.Timeout | undefined;
    const date = new Date();
    const currentDateTime = date?.toISOString();
    if (isLoggedIn) {
      const sessionLog = retrieveData(
        STORAGE_CONSTANTS.USER_SESSION_FIRST_TIME
      );
      if (isEmptyCheck(sessionLog)) {        
        storeData(STORAGE_CONSTANTS.USER_SESSION_FIRST_TIME, currentDateTime);
      }
      if (!isIdle) {
        // Do Nothing
      } else {
        const sessionStart = sessionLog;
        const miliseconds = date?.getTime() - SESSION_INTERVAL_TIME;
        const dt = new Date(miliseconds);
        const sessionEnd = dt?.toISOString();
        const averageTime = differenceBetweenTimestamp(sessionEnd, sessionStart)        
        if (sessionStart && sessionEnd && averageTime && !['Invalid Date', NaN, null, 'null', '']?.includes(sessionStart)) {
          const userInfo = getAnalyticsUserDetails(user);
          const sessionStartDate = new Date(sessionStart)
          const sessionEndDate = new Date(sessionEnd) 
          logEvent(
            appInsights,
            'custom',
            CUSTOM_ANALYTICS_EVENT_NAMES.USER_SESSION,
            {
              userEmail: userInfo?.emailId,
              userMobile: userInfo?.mobile,
              userName: userInfo?.name,
              userQualification: userInfo?.qualification,
              userInterest: userInfo?.areaOfInterest,
              userSpecialization: userInfo?.specialization,
              userCountryOfPractice: userInfo?.countryOfPractice,
              userPreferredLanguage: userInfo?.preferredLanguage,
              platform: PlatformNames.WEB,
              sessionDateTime: sessionStartDate?.toLocaleString(),
              sessionEnd: sessionEndDate?.toLocaleString(),
              averageTime: averageTime,
            }
          );
          removedFromStore(STORAGE_CONSTANTS.USER_SESSION_FIRST_TIME);
        }
      }
    }
  }, [isIdle]);

  useEffect(() => {
    if (localStorageLang && localStorageLang?.field_unique_code) {
      setCallMasterData(true);
    }
  }, [localStorageLang, localStorageLang?.field_unique_code]);

  const token = JSON.parse(retrieveData('access_token'));

  useEffect(() => {
    if (token) {
      api.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    } else {
      api.defaults.headers.common['Authorization'] = '';
    }
  }, [token]);

  useEffect(() => {
    if (callMasterData) {
      loadMasterData();
      setCallMasterData(false);
    }
  }, [callMasterData]);

  const loadMasterData = () => {
    dispatch(fetchAllMasterData());
    if (isLoggedIn) {
      loadUser();
    }
  };

  const loadUser = async () => {
    const response = await dispatch(getUserDetails()).unwrap();
    if (
      response &&
      response?.status === 'SUCCESS' &&
      response?.statusCode === 200 &&
      response?.data
    ) {
      dispatch(updateProfileImage(response?.data?.userPicture));
      storeData(STORAGE_CONSTANTS.USER, response?.data);
      await dispatch(
        updateLanguageForWeb(response?.data?.language?.field_unique_code)
      );
      changeLanguage(response?.data?.language?.field_unique_code);
    }
  };

  api.interceptors.request.use(async (config) => {
    // web
    if (typeof window.document !== 'undefined') {
      const webLocalStorageLang: any = secureLocalStorage?.getItem('language');
      config.params = {
        // add your default ones
        lang_code: webLocalStorageLang?.field_language_code,
        // spread the request's params
        ...config.params,
      };
    }
    return config;
  });

  api.interceptors.response.use(
    function (response) {
      // Extracting required fields
      const baseURL = response.config.baseURL;
      const endPoint = response.config.url;
      const message = response.data.message;
      const status = response.data.status;
      const statusCode = response.data.statusCode;
      const data = response.data.data;
      const currentDateTime = new Date().toISOString();

      if (!(statusCode === 200 && status === 'SUCCESS')) {
        const userObj = userDetails || user;
        const userInfo = getAnalyticsUserDetails(userObj);

        const isEmpty = Object.keys(userObj)?.length === 0 ? true : false;

        if (!isEmpty || isLoggedIn) {
          logEvent(
            appInsights,
            'custom',
            CUSTOM_ANALYTICS_EVENT_NAMES.API_RESPONSE_LOGS,
            {
              baseURL: baseURL,
              endPoint: endPoint,
              status: status,
              statusCode: statusCode ? statusCode?.toString() : statusCode,
              message: message,
              platform: PlatformNames.WEB,
              currentDateTime: currentDateTime,
              data: data,
              userEmail: userInfo?.emailId,
              userMobile: userInfo?.mobile,
              userName: userInfo?.name,
              userQualification: userInfo?.qualification,
              userInterest: userInfo?.areaOfInterest,
              userSpecialization: userInfo?.specialization,
              userCountryOfPractice: userInfo?.countryOfPractice,
              userPreferredLanguage: userInfo?.preferredLanguage,
            }
          );
        } else {
          logEvent(
            appInsights,
            'custom',
            CUSTOM_ANALYTICS_EVENT_NAMES.API_RESPONSE_LOGS,
            {
              baseURL: baseURL,
              endPoint: endPoint,
              status: status,
              statusCode: statusCode ? statusCode?.toString() : statusCode,
              message: message,
              platform: PlatformNames.WEB,
              currentDateTime: currentDateTime,
              data: data,
              userEmail: GuestString,
              userMobile: GuestString,
              userName: GuestString,
              userQualification: GuestString,
              userInterest: GuestString,
              userSpecialization: GuestString,
              userCountryOfPractice: GuestString,
              userPreferredLanguage: GuestString,
            }
          );
        }
      }
      // Return response to continue the chain
      return response;
    },
    function (error) {
      // Log response error
      // Extracting required fields for errors
      const baseURL = error.config.baseURL;
      const endPoint = error.config.url;
      const message = error.response.data.message || 'Unknown error occurred';
      const status = error.response.data.status || 'Unknown status';
      const statusCode = error.response.data.statusCode;
      const data = error.response.data.data;
      const currentDateTime = new Date().toISOString();

      const userObj = userDetails || user;
      const userInfo = getAnalyticsUserDetails(userObj);

      const isEmpty = Object.keys(userObj)?.length === 0 ? true : false;
      if (!isEmpty || isLoggedIn) {
        logEvent(
          appInsights,
          'custom',
          CUSTOM_ANALYTICS_EVENT_NAMES.API_RESPONSE_LOGS,
          {
            baseURL: baseURL,
            endPoint: endPoint,
            status: status,
            statusCode: statusCode ? statusCode?.toString() : statusCode,
            message: message,
            platform: PlatformNames.WEB,
            currentDateTime: currentDateTime,
            data: data,
            userEmail: userInfo?.emailId,
            userMobile: userInfo?.mobile,
            userName: userInfo?.name,
            userQualification: userInfo?.qualification,
            userInterest: userInfo?.areaOfInterest,
            userSpecialization: userInfo?.specialization,
            userCountryOfPractice: userInfo?.countryOfPractice,
            userPreferredLanguage: userInfo?.preferredLanguage,
          }
        );
      } else {
        logEvent(
          appInsights,
          'custom',
          CUSTOM_ANALYTICS_EVENT_NAMES.API_RESPONSE_LOGS,
          {
            baseURL: baseURL,
            endPoint: endPoint,
            status: status,
            statusCode: statusCode ? statusCode?.toString() : statusCode,
            message: message,
            platform: PlatformNames.WEB,
            currentDateTime: currentDateTime,
            data: data,
            userEmail: GuestString,
            userMobile: GuestString,
            userName: GuestString,
            userQualification: GuestString,
            userInterest: GuestString,
            userSpecialization: GuestString,
            userCountryOfPractice: GuestString,
            userPreferredLanguage: GuestString,
          }
        );
      }

      // Return rejected promise to continue the chain
      return Promise.reject(error);
    }
  );

  if (
    !(
      i18n.hasResourceBundle(LANGUAGE_PREFERENCE?.ENGLISH, 'translation') ||
      i18n.hasResourceBundle(LANGUAGE_PREFERENCE?.FRENCH, 'translation') ||
      i18n.hasResourceBundle(LANGUAGE_PREFERENCE?.PORTUGUESE, 'translation')
    )
  ) {
    // Wait for translations to be loaded
    return null;
  }

  return (
    <Router>
      <RoutesComponent />
      <RecognitionPopup />
    </Router>
  );
}

export default App;
