"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isKol = exports.getUserCountryOfPractice = exports.getUserQualification = exports.getUserSpecialization = exports.getLoggedInUserInfo = exports.getDeactivateReason = exports.updateUserDetails = exports.getUserDetails = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const toolkit_2 = require("@reduxjs/toolkit");
const api_1 = require("../../../api");
const constants_1 = require("../../constants");
const initialState = {
    user: {
        statusCode: 200,
        status: "",
        message: "",
        data: {},
    },
    updateUser: {
        updatedUser: {},
        isError: false,
        errorMessage: null,
    },
    deactivateReason: {
        data: [],
        isError: false,
    },
    isLoading: false,
};
const userSlice = (0, toolkit_1.createSlice)({
    name: constants_1.USER,
    initialState,
    reducers: {},
    extraReducers(builder) {
        /**
         * Get User Details
         */
        builder.addCase(exports.getUserDetails.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(exports.getUserDetails.fulfilled, (state, action) => {
            state.isLoading = false;
            state.user = action === null || action === void 0 ? void 0 : action.payload;
        });
        builder.addCase(exports.getUserDetails.rejected, (state, action) => {
            state.isLoading = false;
        });
        /**
         * Update User Details
         */
        builder.addCase(exports.updateUserDetails.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(exports.updateUserDetails.fulfilled, (state, action) => {
            state.isLoading = false;
            state.user = action === null || action === void 0 ? void 0 : action.payload;
        });
        builder.addCase(exports.updateUserDetails.rejected, (state, action) => {
            state.isLoading = false;
        });
        /**
         * Get Deactivate Reason
         */
        builder.addCase(exports.getDeactivateReason.pending, (state, action) => {
            // state.isLoading = true;
        });
        builder.addCase(exports.getDeactivateReason.fulfilled, (state, action) => {
            // state.isLoading = false;
            state.deactivateReason = action === null || action === void 0 ? void 0 : action.payload;
        });
        builder.addCase(exports.getDeactivateReason.rejected, (state, action) => {
            // state.isLoading = false;
        });
    },
});
exports.getUserDetails = (0, toolkit_2.createAsyncThunk)(`${constants_1.USER}/${constants_1.USER_DETAILS}`, () => __awaiter(void 0, void 0, void 0, function* () {
    const user = yield (0, api_1.get)(constants_1.GET_USER_DETAILS);
    return user;
}));
exports.updateUserDetails = (0, toolkit_2.createAsyncThunk)(`${constants_1.USER}/${constants_1.UPDATE_USER_DETAILS}`, (req) => __awaiter(void 0, void 0, void 0, function* () {
    const updatedUser = yield (0, api_1.put)(constants_1.UPDATE_USER_DETAILS, req);
    return updatedUser;
}));
exports.getDeactivateReason = (0, toolkit_2.createAsyncThunk)(`${constants_1.USER}/${constants_1.GET_DEACTIVATE_REASON}`, () => __awaiter(void 0, void 0, void 0, function* () {
    const reason = yield (0, api_1.get)(constants_1.GET_ACCOUNT_DEACTIVATE_REASON);
    return reason;
}));
exports.getLoggedInUserInfo = (0, toolkit_2.createSelector)((state) => state.user, (user) => {
    var _a;
    return (_a = user === null || user === void 0 ? void 0 : user.user) === null || _a === void 0 ? void 0 : _a.data;
});
exports.getUserSpecialization = (0, toolkit_2.createSelector)((state) => state.user, (user) => {
    var _a, _b, _c;
    return (_c = (_b = (_a = user === null || user === void 0 ? void 0 : user.user) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.specialization) === null || _c === void 0 ? void 0 : _c.map((item) => {
        return {
            id: ((item === null || item === void 0 ? void 0 : item.tid) && (item === null || item === void 0 ? void 0 : item.tid)) || "",
            label: (item === null || item === void 0 ? void 0 : item.title) || "",
        };
    });
});
exports.getUserQualification = (0, toolkit_2.createSelector)((state) => state.user, (user) => {
    var _a, _b, _c;
    return (_c = (_b = (_a = user === null || user === void 0 ? void 0 : user.user) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.qualification) === null || _c === void 0 ? void 0 : _c.map((item) => {
        return {
            id: ((item === null || item === void 0 ? void 0 : item.tid) && (item === null || item === void 0 ? void 0 : item.tid)) || "",
            label: (item === null || item === void 0 ? void 0 : item.title) || "",
        };
    });
});
exports.getUserCountryOfPractice = (0, toolkit_2.createSelector)((state) => state.user, (user) => {
    var _a, _b, _c;
    return (_c = (_b = (_a = user === null || user === void 0 ? void 0 : user.user) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.country) === null || _c === void 0 ? void 0 : _c.map((item) => {
        return {
            id: ((item === null || item === void 0 ? void 0 : item.tid) && (item === null || item === void 0 ? void 0 : item.tid)) || "",
            label: (item === null || item === void 0 ? void 0 : item.title) || "",
        };
    });
});
// export const getDeactivateReasons = createSelector(
//   (state: RootState) => state.user,
//   (user: any) => {
//     return user?.deactivateReason?.data?.map((item: any) => {
//       return {
//         value: (item?.tid && item?.tid) || "",
//         label: item?.title || "",
//       };
//     });
//   }
// );
exports.isKol = (0, toolkit_2.createSelector)((state) => state.user, (user) => {
    var _a, _b, _c;
    return ((_c = (_b = (_a = user === null || user === void 0 ? void 0 : user.user) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.roles) === null || _c === void 0 ? void 0 : _c[0]) === 'kol';
});
// export const updateUserDetails = createAsyncThunk(
//     `${USER}/${UPDATE_USER_DETAILS}`,
//     async (req: any) => {
//         const updatedUser: any = await put(
//             UPDATE_USER_DETAILS,
//             req
//         );
//         return updatedUser as any;
//     }
// );
exports.default = userSlice.reducer;
