import React, { ChangeEvent, useRef, useState } from 'react';
import parse from 'html-react-parser';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import {
  AppConstant,
  getBlobFilePath,
  getCommentStatus,
  getFileNameFromUrl,
  getPostedBy,
  ImageConstant,
  isKol,
  QuestionStatusEnum,
  revokeQuestion,
  submitAnswer,
  translate,
  useAppDispatch,
  useAppSelector,
} from '@shalina-app/shared';
import { CircularProgress, Dialog, DialogContent } from '@mui/material';

import styles from './CommentCard.module.scss';
// @ts-ignore: Unreachable code error
import { CommentCardProps } from './CommentCard.interface';
import {
  getImageSrc,
  retrieveData,
  STORAGE_CONSTANTS,
  UserInterface,
} from '../../../../utils';
import { ButtonLoader } from '..';
import { ShalinaPDFViewer, Toast } from '../../../../components';

export const CommentCard: React.FC<CommentCardProps> = ({
  discussionId,
  comment,
  isQAEvent,
  isExpert,
  handleMainApiCall,
  isEventStarted,
  isEventEnded,
  isTopicEvent
}) => {
  const t = translate();
  const dispatch = useAppDispatch();

  const inputRef = useRef<HTMLInputElement>(null);

  const userDetail: UserInterface | any = retrieveData(STORAGE_CONSTANTS.USER);

  const [openReplyInput, setOpenReplyInput] = useState<boolean>(false);
  const [replyText, setReplyText] = useState('');
  const [file, setFile] = useState<any>(null);
  const [filename, setFilename] = useState<string>('');
  const [loader, setLoader] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [fileType, setFileType] = useState('');
  const [fileToOpen, setFileToOpen] = useState('');
  const [submitAnswerLoading, setSubmitAnswerLoading] = useState(false);
  const [hideReply, setHideReply] = useState(false);
  const [revokeQuestionLoader, setRevokeQuestionLoader] = useState(false);
  const [uploadError, setUploadError] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [resourceLoader, setResourceLoader] = useState(false);
  const [submitAnswerDisabled, setSubmitAnswerDisabled] = useState(true);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorHtmlValue, setEditorHtmlValue] = useState('');
  // const [editorState, setEditorState] = useState(
  //   RichTextEditor.createEmptyValue()
  // );

  const onEditorStateChange = (editorState: any) => {
    const htmlString = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setEditorState(editorState);
    setEditorHtmlValue(htmlString);
    if (htmlString) {
      setSubmitAnswerDisabled(false);
    } else if (!file) {
      setSubmitAnswerDisabled(true);
    }
  };

  const handleToastClose = () => {
    setShowToast(false);
  };

  const fileTypeEnum = {
    PDF: 'pdf',
    IMAGE: 'image',
    PPT: 'ppt',
  };

  const handleReplyToggle = () => {
    if (openReplyInput) {
      removeAttachment();
      setSubmitAnswerDisabled(true);
    }
    setReplyText('');
    setEditorState(EditorState.createEmpty());
    // setEditorState(RichTextEditor.createEmptyValue());
    setOpenReplyInput(!openReplyInput);
  };

  const handleReplyInput = (value: any) => {
    const text = value?.toString('html');
    setEditorState(value);
    if (text) {
      setSubmitAnswerDisabled(false);
    } else if (!file) {
      setSubmitAnswerDisabled(true);
    }
    setReplyText(text);
  };

  const handleSubmitAnswer = async () => {
    if (editorHtmlValue || file) {
      setSubmitAnswerLoading(true);
      const formDataObj: any = new FormData();

      formDataObj?.append('discussion_id', discussionId);
      formDataObj?.append('question_id', comment?.question_id);
      formDataObj?.append('answer', editorHtmlValue);
      formDataObj?.append('file', file);
      const response = await dispatch(submitAnswer(formDataObj)).unwrap();

      if (
        response &&
        response?.statusCode === 200 &&
        response?.status === 'SUCCESS'
      ) {
        handleMainApiCall();
        setSubmitAnswerLoading(false);
        setHideReply(true);
        setFile('');
        setFilename('');
        setOpenReplyInput(false);
        setSubmitAnswerDisabled(true);
        setEditorState(EditorState.createEmpty());
        // setEditorState(RichTextEditor.createEmptyValue());
      } else {
        setHideReply(false);
        setSubmitAnswerLoading(false);
        setFile('');
        setFilename('');
        setShowToast(true);
        setIsSuccess(false);
        setToastMessage(t(response.message));
      }
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e?.target?.files[0];
      setFile(selectedFile);
      setFilename(selectedFile?.name);
      setSubmitAnswerDisabled(false);
    } else {
      setSubmitAnswerDisabled(true);
    }
  };

  const removeAttachment = () => {
    setFile('');
    setFilename('');
    if (!editorHtmlValue && !file) {
      setSubmitAnswerDisabled(true);
    }
  };

  const handleAttachmentClick = async (filePath: string) => {
    setFileToOpen(getBlobFilePath(filePath));
    setResourceLoader(true);
    try {
      setModalOpen(true);
      const response = await fetch(getBlobFilePath(filePath));
      const blob = await response.blob();
      if (response.ok && response.status === 200) {
        switch (blob?.type) {
          case 'application/pdf':
            setFileType(fileTypeEnum?.PDF);
            break;
          case 'image/png':
            setFileType(fileTypeEnum?.IMAGE);
            break;
          case 'image/jpg':
            setFileType(fileTypeEnum?.IMAGE);
            break;
          case 'image/jpeg':
            setFileType(fileTypeEnum?.IMAGE);
            break;
          default:
            setFileType('');
        }
      } else {
        setModalOpen(false);
        setShowToast(true);
        setIsSuccess(false);
        setToastMessage(
          t(AppConstant.COMMUNITY.FAILED_TO_LOAD_COMMUNITY_RESOURCE_FILE)
        );
      }
      setResourceLoader(false);
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      setModalOpen(false);
      setShowToast(true);
      setIsSuccess(false);
      setToastMessage(
        t(AppConstant.COMMUNITY.FAILED_TO_LOAD_COMMUNITY_RESOURCE_FILE)
      );
      setResourceLoader(false);
    }
  };

  const handleRevokeQuestion = async (questionId: string) => {
    setRevokeQuestionLoader(true);
    const req = {
      questionId: questionId,
      status: 'revoke',
    };
    const response = await dispatch(revokeQuestion(req)).unwrap();
    if (
      response &&
      response?.statusCode === 200 &&
      response?.status === 'SUCCESS'
    ) {
      await handleMainApiCall();
      setRevokeQuestionLoader(false);
      setShowToast(true);
      setIsSuccess(true);
      setToastMessage(t(AppConstant.COMMUNITY.QUESTION_REVOKE_SUCCESSFULLY));
    } else {
      setRevokeQuestionLoader(false);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const { AttachedIcon, SendIcon, ClockIcon } = ImageConstant.SVG_ICONS;

  const isKolUser = useAppSelector(isKol);

  const answer = comment?.answer ? comment?.answer : {};
  const isReplied = Object.keys(answer).length !== 0 ? true : false;

  const commentStatus = getCommentStatus(comment?.field_question_status);

  const isPending = commentStatus === QuestionStatusEnum.PENDING ? true : false;
  const isRejected =
    commentStatus === QuestionStatusEnum.REJECTED ? true : false;

  const currentUserComment = userDetail?.uid === comment?.author_id || false;
  let isReplyVisible = false;

  if (isQAEvent) {
    isReplyVisible =
      !currentUserComment &&
      isEventStarted &&
      !isEventEnded &&
      isQAEvent &&
      isExpert &&
      isKolUser &&
      !isReplied &&
      !isRejected &&
      !isPending
        ? true
        : false;
  } else {
    isReplyVisible =
      !currentUserComment && !isReplied && !isRejected && !isPending && !isTopicEvent
        ? true
        : false;
  }

  const currentUserPic = userDetail?.userPicture
    ? getImageSrc(userDetail?.userPicture)
    : ImageConstant.SOURCE.defaultUserPic;

  const toolbarConfig: any = {
    // Optionally specify the groups to display (displayed in the order listed).
    // display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
    display: [
      'INLINE_STYLE_BUTTONS',
      'BLOCK_TYPE_BUTTONS',
      'BLOCK_TYPE_DROPDOWN',
      'HISTORY_BUTTONS',
    ],

    INLINE_STYLE_BUTTONS: [
      { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
      { label: 'Italic', style: 'ITALIC' },
      { label: 'Underline', style: 'UNDERLINE' },
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: 'Normal', style: 'unstyled' },
      { label: 'Heading Large', style: 'header-one' },
      { label: 'Heading Medium', style: 'header-two' },
      { label: 'Heading Small', style: 'header-three' },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: 'UL', style: 'unordered-list-item' },
      { label: 'OL', style: 'ordered-list-item' },
    ],
  };
  return (
    <>
      <div
        className={`${styles.commnetItem} ${isPending ? styles.awaiting : ''} ${
          isRejected ? styles.rejected : ''
        } ${openReplyInput ? styles.openReply : ''} ${
          isReplied ? styles.commentReplay : ''
        }`}
      >
        <img
          className={styles.userImg}
          src={
            comment?.author_picture
              ? getImageSrc(comment?.author_picture)
              : ImageConstant.SOURCE.defaultUserPic
          }
          alt='img'
          onError={(e) =>
            ((e.target as HTMLInputElement).src =
              ImageConstant.SOURCE.defaultUserPic)
          }
        />
        <div className={styles.msgWrapper}>
          <div className={styles.userName}>{comment?.author_name}</div>
          <p className={styles.commentMsg}>{parse(comment?.question)}</p>
          <div className={styles.commentDate}>
            {isPending ? (
              <div className={styles.pendingWrapper}>
                <span className={styles.awaitLabel}>
                  <ClockIcon />
                  {t(AppConstant.COMMUNITY.AWAITING_FOR_APPROVAL_TEXT)}
                </span>
                <button
                  className={styles.revokeCta}
                  onClick={() => handleRevokeQuestion(comment?.question_id)}
                >
                  {t(AppConstant.COMMUNITY.QUESTION_REVOKE_CTA)}
                  <ButtonLoader loader={revokeQuestionLoader} />
                </button>
              </div>
            ) : isRejected ? (
              <span className={styles.rejectLabel}>
                <ClockIcon />
                {t(AppConstant.COMMUNITY.REJECTED_TEXT)}
              </span>
            ) : (
              ''
            )}
            <div>
              {isReplyVisible && (
                <button
                  className={`${styles.replyCta} ${
                    submitAnswerLoading && styles.disabled
                  }`}
                  disabled={submitAnswerLoading}
                  onClick={handleReplyToggle}
                >
                  {!openReplyInput
                    ? t(AppConstant.COMMUNITY.COMMUNITY_QUESTION_REPLY_CTA)
                    : t(AppConstant.COMMUNITY.COMMUNITY_QUESTION_CANCEL_CTA)}
                </button>
              )}
              {comment?.created_date ? getPostedBy(comment?.created_date) : ''}
            </div>
          </div>
        </div>
        <div className={styles.replyLine}></div>
      </div>

      {openReplyInput && (
        <div className={styles.replyInputWrapper}>
          <img
            className={styles.userImg}
            src={currentUserPic}
            alt='img'
            onError={(e) =>
              ((e.target as HTMLInputElement).src =
                ImageConstant.SOURCE.defaultUserPic)
            }
          />
          <div className={styles.replyBox}>
            <div className={styles.replyInput}>
              <Editor
                toolbarHidden
                wrapperClassName='demo-wrapper'
                placeholder={t(
                  AppConstant.COMMUNITY.REPLY_INPUT_PLACEHOLDER_TEXT
                )}
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                // editorClassName='demo-editor'
                // mention={{
                //   separator: ' ',
                //   trigger: '@',
                //   suggestions: membersList,
                // }}
              />
              {/* <RichTextEditor
                className={styles.richTextEditor}
                placeholder={t(
                  AppConstant.COMMUNITY.REPLY_INPUT_PLACEHOLDER_TEXT
                )}
                toolbarConfig={toolbarConfig}
                value={editorState}
                onChange={handleReplyInput}
              /> */}
              {/* <textarea
                placeholder={t(
                  AppConstant.COMMUNITY.REPLY_INPUT_PLACEHOLDER_TEXT
                )}
                // value={questionText}
                onChange={handleReplyInput}
                rows={5}
                // ref={inputRef}
              ></textarea> */}
              <div className={styles.actionCtas}>
                {!filename && (
                  <label
                    className={`${styles.attachedButton} ${
                      submitAnswerLoading && styles.disabled
                    }`}
                  >
                    <AttachedIcon />
                    <input
                      type='file'
                      id='fileInput'
                      accept='image/jpg, image/jpeg, image/png, application/pdf'
                      onChange={handleFileChange}
                      disabled={submitAnswerLoading}
                    />
                  </label>
                )}
                <button
                  className={`${styles.submitCta} ${
                    submitAnswerDisabled && styles.disabled
                  }`}
                  disabled={submitAnswerDisabled}
                  onClick={handleSubmitAnswer}
                >
                  <SendIcon />
                  <ButtonLoader loader={submitAnswerLoading} />
                </button>
              </div>
            </div>
            {!filename ? (
              <span className={styles.fileNote}>
                {t(AppConstant.COMMUNITY.REPLY_ATTACHMENT_HELP_TEXT)}
              </span>
            ) : (
              ''
            )}
            {filename && (
              <div className={styles.selectedFile}>
                <span>{filename}</span>
                <button
                  onClick={removeAttachment}
                  className={`${submitAnswerLoading && styles.disabled}`}
                  disabled={submitAnswerLoading}
                >
                  &times;
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      {isReplied && (
        <div className={styles.replyCommnent}>
          <div className={`${styles.commnetItem} `}>
            <img
              className={styles.userImg}
              src={
                comment?.answer?.author_picture
                  ? getImageSrc(comment?.answer?.author_picture)
                  : ImageConstant.SOURCE.defaultUserPic
              }
              alt='img'
              onError={(e) =>
                ((e.target as HTMLInputElement).src =
                  ImageConstant.SOURCE.defaultUserPic)
              }
            />
            <div className={styles.msgWrapper}>
              <div className={styles.userName}>
                {comment?.answer?.author_name}
              </div>
              <p className={styles.commentMsg}>
                {comment?.answer?.answer ? parse(comment?.answer?.answer) : ''}
              </p>
              {comment?.answer?.attachments &&
                comment?.answer?.attachments?.length > 0 &&
                comment?.answer?.attachments?.map((attachment: string) => (
                  <p className={styles.attachedFile}>
                    <AttachedIcon />
                    <span onClick={() => handleAttachmentClick(attachment)}>
                      {getFileNameFromUrl(attachment)}
                    </span>
                  </p>
                ))}
              <div className={styles.commentDate}>
                <div>
                  {comment?.answer?.posted_date
                    ? getPostedBy(comment?.answer?.posted_date)
                    : 'NA'}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Dialog
        open={modalOpen}
        onClose={handleModalClose}
        className={styles.resourceModal}
      >
        <DialogContent>
          <div className={styles.modalHeader}>
            <span className={styles.fileName}>
              {getFileNameFromUrl(fileToOpen)}
            </span>
            <button onClick={handleModalClose} className={styles.closeCta}>
              &times;
            </button>
          </div>
          {resourceLoader ? (
            <span className={styles.resourceLoader}>
              <CircularProgress
                size={40}
                thickness={4}
                sx={{
                  color: 'var(--secondaryOrange)',
                }}
              />
            </span>
          ) : fileType === fileTypeEnum?.PDF ? (
            <div className={styles.pdfWrapper}>
              <ShalinaPDFViewer
                fileUrl={fileToOpen}
                pageNumber={0}
                id={discussionId}
              />
            </div>
          ) : fileType === fileTypeEnum?.IMAGE ? (
            <div className={styles.imgWrapper}>
              <img id='image' src={fileToOpen} alt='Image' />
            </div>
          ) : (
            <></>
          )}
        </DialogContent>
      </Dialog>
      <Toast
        showToast={showToast}
        isSuccess={isSuccess}
        message={toastMessage}
        handleToastClose={handleToastClose}
      />
    </>
  );
};
