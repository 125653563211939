import {
  API_CATEGORY_TYPES,
  AppConstant,
  COMMUNITY_SORT_SCREENS,
  fetchCommunityDetailDiscussions,
  fetchCommunityDiscussions,
  fetchCommunityDiscussionsAnswerTab,
  FILTER_BY_TABS_ENUM,
  getCommunityDiscussionAnswerTabList,
  getCommunityDiscussionList,
  getLoggedInUserInfo,
  ImageConstant,
  translate,
  useAppDispatch,
  useAppSelector,
} from '@shalina-app/shared';
import { useEffect, useState } from 'react';

import { CommunitiesDiscussionProps } from '.';
import {
  DEFAULT_COMMUNITY_SKIP,
  DEFAULT_COMMUNITY_TAKE,
} from '../../../../app.constants';
import {
  BarLoader,
  ButtonComponent,
  InfiniteScrollComponent,
  SortFilter,
} from '../../../../components';
import {
  retrieveData,
  STORAGE_CONSTANTS,
  UserInterface,
} from '../../../../utils';
import { CommunitiesContentCard } from '../../components';
import styles from './CommunitiesDiscussion.module.scss';

export const CommunitiesDiscussion: React.FC<CommunitiesDiscussionProps> = ({
  heading,
  showCommunityInfo,
  isCommunityDetail,
  communityId,
  isQuestionAnswersTab,
  handleJoinCommunityClick,
  isExpert,
  handleCommunityClick,
}) => {
  const t = translate();
  const dispatch = useAppDispatch();

  const userInfo = useAppSelector(getLoggedInUserInfo);
  const communityDiscussionList = useAppSelector(getCommunityDiscussionList);
  const communityDiscussionListAnswerTab = useAppSelector(
    getCommunityDiscussionAnswerTabList
  );

  const user: UserInterface | any = retrieveData(STORAGE_CONSTANTS.USER);
  const isLoggedIn: boolean = retrieveData(STORAGE_CONSTANTS.IS_LOGGED_IN);

  const [hasMore, setHasMore] = useState(true);
  const [scrollLoader, setScrollLoader] = useState(false);
  const [discussionList, setDiscussionList] = useState<any>([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [skip, setSkip] = useState(0);
  const [initialLoader, setInitialLoader] = useState(false);
  const [selectedAreaOfInterest, setSelectedAreaOfInterest] = useState([]);

  const [apiCall, setApiCall] = useState(false);
  const [filterCall, setFilterCall] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    skip: 0,
    take: DEFAULT_COMMUNITY_TAKE,
    countries: '',
    interest: '',
    community_id: '',
    category: '',
    speciality: '',
    // isKol: isQuestionAnswersTab ? true : false
    sort: '',
  });

  const [dataFromStore, setDataFromStore] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    setInitialValue();
    if (isLoggedIn) {
      setApiCall(true);
      setInitialLoader(true);
    }
  }, []);

  useEffect(() => {
    if (filterCall || apiCall) {
      loadApi();
    }
  }, [filterCall, apiCall]);

  const setInitialValue = () => {
    if (
      (![null, undefined, []].includes(userInfo?.areaOfInterest) ||
        ![null, undefined, []].includes(user?.areaOfInterest)) &&
      isLoggedIn
    ) {
      const userDetails = userInfo?.areaOfInterest ?? user?.areaOfInterest;
      const areaOfInterestIds = userDetails?.map(
        (item: any) => item?.tid && String(item?.tid)
      );
      setSelectedAreaOfInterest(areaOfInterestIds);
    }
  };

  const loadApi = async () => {
    let payload: any = filterOptions;
    if (isCommunityDetail) {
      payload = {
        ...filterOptions,
        community_id: communityId,
      };
      const response = await dispatch(
        fetchCommunityDetailDiscussions(payload)
      ).unwrap();
      if (
        response &&
        response?.status === 'SUCCESS' &&
        response?.statusCode === 200
      ) {
        setPagination(response);
      } else {
        setApiCall(false);
        setFilterCall(false);
        setInitialLoader(false);
        setDataFromStore(false);
      }
    } else if (isQuestionAnswersTab) {
      payload = {
        ...filterOptions,
        isKol: true,
      };
      const response = await dispatch(
        fetchCommunityDiscussionsAnswerTab(payload)
      ).unwrap();
      if (
        response &&
        response?.status === 'SUCCESS' &&
        response?.statusCode === 200
      ) {
        setPagination(response);
      } else {
        setApiCall(false);
        setFilterCall(false);
        setInitialLoader(false);
        setDataFromStore(false);
      }
    } else {
      const response = await dispatch(
        fetchCommunityDiscussions(payload)
      ).unwrap();
      if (
        response &&
        response?.status === 'SUCCESS' &&
        response?.statusCode === 200
      ) {
        setPagination(response);
      } else {
        setApiCall(false);
        setFilterCall(false);
        setInitialLoader(false);
        setDataFromStore(false);
      }
    }
  };

  const setPagination = (response: any) => {
    const data = response?.data;
    const totalCount = response?.count;

    setTotalDataCount(totalCount);
    setDataFromStore(false);
    if (apiCall) {
      // if (discussionList?.length >= response?.count) {
      //   setHasMore(false);
      // } else {
      //   setHasMore(true);
      // }
      setApiCall(false);
    }
    if (data) {
      if (filterCall) {
        setFilterCall(false);
        setDiscussionList(data);
      } else {
        setDiscussionList((prevOptions: any) => [...prevOptions, ...data]);
      }
    }
    setInitialLoader(false);
  };

  const fetchMoreData = () => {
    setScrollLoader(true);
    if (discussionList?.length >= totalDataCount) {
      setHasMore(false);
      return;
    }
    setSkip(skip + DEFAULT_COMMUNITY_SKIP);
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      skip: skip + DEFAULT_COMMUNITY_SKIP,
      take: DEFAULT_COMMUNITY_TAKE,
    }));
    setApiCall(true);
  };

  const handleExplore = async () => {
    handleJoinCommunityClick!();
  };

  const onApplyButtonClick = (
    selectedContentType: Array<string>,
    selectedSpecialization: Array<string>,
    selectedCommunityCategory: Array<string>,
    selectedCommunityCountry: Array<string>
  ) => {
    setSkip(0);
    setFilterOptions((prevOptions: any) => ({
      ...prevOptions,
      skip: 0,
      speciality: selectedSpecialization?.toString(),
      countries: selectedCommunityCountry?.toString(),
      category: selectedCommunityCategory?.toString(),
    }));
    setFilterCall(true);
    setHasMore(true);
  };

  const onSortChange = (value: string) => {
    setSkip(0);
    setFilterOptions((prevOptions: any) => ({
      ...prevOptions,
      skip: 0,
      sort: value,
    }));
    setFilterCall(true);
    setHasMore(true);
  };

  const onAreaOfInterest = (value: string[]) => {
    setSkip(0);
    setFilterOptions((prevOptions: any) => ({
      ...prevOptions,
      skip: 0,
      interest: value?.toString(),
    }));
    setFilterCall(true);
    setHasMore(true);
  };

  const onResetFilter = () => {
    setSkip(0);
    setFilterOptions((prevValue: any) => ({
      ...prevValue,
      skip: 0,
      speciality: '',
      countries: '',
      category: '',
    }));
    setFilterCall(true);
    setHasMore(true);
  };

  const content = isLoggedIn
    ? dataFromStore
      ? isQuestionAnswersTab
        ? communityDiscussionListAnswerTab
        : communityDiscussionList
      : discussionList
    : [];

  return (
    <>
      {!isCommunityDetail && <h1 className={styles.pageTitle}>{heading}</h1>}

      {!isCommunityDetail && (
        <div className={styles.landingFilter}>
          <SortFilter
            tabsEnum={
              isQuestionAnswersTab
                ? [FILTER_BY_TABS_ENUM.SPECIALITY]
                : [
                    FILTER_BY_TABS_ENUM.COMMUNITY_CATEGORY,
                    FILTER_BY_TABS_ENUM.SPECIALITY,
                  ]
            }
            isCommunity={true}
            isDark={true}
            categoryType={API_CATEGORY_TYPES.COMMUNITIES}
            handleApplyFilter={onApplyButtonClick}
            handleSortChange={onSortChange}
            handleAreaOfInterest={onAreaOfInterest}
            handleResetFilter={onResetFilter}
            selectedAreaOfInterest={selectedAreaOfInterest}
            communityModule={
              isQuestionAnswersTab
                ? COMMUNITY_SORT_SCREENS.ANSWER_DISCUSSIONS
                : COMMUNITY_SORT_SCREENS.DISCUSSIONS
            }
          />
          {(filterCall || initialLoader) && (
            <div className={styles.loaderDiv}>
              <BarLoader />
            </div>
          )}
        </div>
      )}
      {isCommunityDetail && initialLoader ? (
        <div className={styles.initialLoader}>
          <BarLoader />
        </div>
      ) : content?.length > 0 ? (
        <div className={styles.discussionList}>
          <InfiniteScrollComponent
            dataLength={content?.length || 0}
            fetchMoreData={fetchMoreData}
            hasMore={hasMore}
            loader={true}
            // scrollThreshold={"1000px"}
          >
            {content?.map((discussion: any) => (
              <CommunitiesContentCard
                discussion={discussion}
                showCommunityInfo={showCommunityInfo}
                isQuestionAnswersTab={isQuestionAnswersTab}
                isExpert={isExpert}
                handleCommunityClick={handleCommunityClick}
              />
            ))}
          </InfiniteScrollComponent>
        </div>
      ) : !initialLoader && !isCommunityDetail ? (
        <div className={styles.welcomePage}>
          <div className={styles.welcomeWrapper}>
            <img src={ImageConstant.SOURCE.welcomeToCommunities} alt='img' />
            <h2>{t(AppConstant.COMMUNITY.WELCOME_TO_COMMUNITIES)}</h2>
            <p>{t(AppConstant.COMMUNITY.JOIN_COMMUNITY_TEXT)} </p>
            <div className={styles.cta}>
              <ButtonComponent
                variant='outlined'
                buttonText={t(AppConstant.COMMUNITY.EXPLORE_COMMUNITIES_CTA)}
                onPress={handleExplore}
              />
            </div>
          </div>
        </div>
      ) : !initialLoader ? (
        <div className={styles.noData}>
          <img
            className={styles.noDataImg}
            src={ImageConstant.SOURCE.emptyContent}
            alt='img'
          />
          <p className={styles.noDataMsg}>
            {t(AppConstant.COMMUNITY.NO_DISCUSSTION_AVAILABLE)}
          </p>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
