import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

import { InputPropsType } from '.';
import styles from './input.module.scss';

export const Input: React.FC<InputPropsType> = (props) => {
  const {
    formControlVariant,
    labelText,
    inputId,
    inputVariant,
    isRequired,
    isError,
    errorMessage,
    placeHolderText,
    handleChange,
    value,
    maxLength,
    handleOnBlur,
    disabled
  } = props;
  return (
    <div className={styles.shalinaInpute}>
      <FormControl fullWidth variant={formControlVariant}>
        {labelText ? (
          <label htmlFor={inputId}>
            {labelText}
            {isRequired && <span>*</span>}
          </label>
        ) : null}
        <TextField
          error={isError}
          id={inputId}
          value={value}
          variant={inputVariant}
          className={styles.test}
          onChange={handleChange}
          placeholder={placeHolderText}
          inputProps={{ maxLength: maxLength }}
          onBlur={handleOnBlur}
          disabled={disabled}
        />
        {isError && <FormHelperText error>{errorMessage}</FormHelperText>}
      </FormControl>
    </div>
  );
};
