import { useEffect, useState } from 'react';
import { SelectChangeEvent } from '@mui/material';
import {
  AppConstant,
  GAMIFICATION_ACTION_ID,
  getNotificationActionList,
  translate,
  useAppDispatch,
  useAppSelector
} from '@shalina-app/shared';

import {
  AutoComplete,
  CircularLoader,
  Dropdown
} from '../../../../../../components';
import {
  getTranslationFormat,
  isDifferenceExist,
  retrieveData,
  STORAGE_CONSTANTS,
  UserInterface
} from '../../../../../../utils';
import { ButtonLoader } from '../../../../../communities';
import styles from './professional.module.scss';

export const Professional: React.FC<any> = ({
  qualificationList,
  specializationList,
  countryOfPracticeList,
  handleSaveProfileClick,
  profileData,
  isSaveLoader
}) => {
  const dispatch = useAppDispatch();
  const t = translate();
  const userUpdateLoading = useAppSelector((state) => state?.user?.isLoading);
  const isUpdatePointLoading = useAppSelector(
    (state) => state?.notification?.isLoading
  );
  const notificationList = useAppSelector(getNotificationActionList);

  const user: UserInterface | any = retrieveData(STORAGE_CONSTANTS.USER);

  const [toastSubMessage, setToastSubMessage] = useState('');
  const [completedProfile, setCompletedProfile] = useState(false);
  const [isCancelEnabled, setCancelEnabled] = useState(false);

  const defaultQualification = user?.qualification?.map((item: any) => {
    return {
      id: item?.tid && item?.tid,
      label: item?.title
    };
  });

  const defaultSpecialization = user?.specialization?.map((item: any) => {
    return {
      id: item?.tid && item?.tid,
      label: item?.title
    };
  });

  const [specializedOptions, setSpecializedOptions] = useState(
    defaultSpecialization
  );
  const [qualificationOptions, setQualificationOptions] =
    useState(defaultQualification);
  const [countryOfPractice, setCountryOfPractice] = useState(
    user?.country?.[0]?.tid
  );
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  const handleSpecializedOptions = (options: any) => {
    setSpecializedOptions(options);
    const isChange = isDifferenceExist(options, defaultSpecialization);
    if (isChange) {
      setCancelEnabled(true);
    } else {
      setCancelEnabled(false);
    }
  };

  useEffect(() => {
    if (
      countryOfPractice &&
      countryOfPractice.length &&
      qualificationOptions &&
      qualificationOptions.length > 0 &&
      specializedOptions &&
      specializedOptions.length > 0
    ) {
      if (
        isDifferenceExist(qualificationOptions, defaultQualification) ||
        isDifferenceExist(specializedOptions, defaultSpecialization) ||
        countryOfPractice !== user?.country?.[0]?.tid
      ) {
        setIsSaveEnabled(true);
      } else {
        setIsSaveEnabled(false);
      }
    } else {
      setIsSaveEnabled(false);
    }
  }, [countryOfPractice, qualificationOptions, specializedOptions]);

  const handleQualificationOptions = async (options: any) => {
    await setQualificationOptions(options);

    const isChange = isDifferenceExist(options, defaultQualification);
    if (isChange) {
      setCancelEnabled(true);
    } else {
      setCancelEnabled(false);
    }
  };

  const handleSaveClick = async () => {
    const selectedSpecialized = specializedOptions.map((item: any) => {
      return item.id;
    });
    const selectedQualification = qualificationOptions.map((item: any) => {
      return item.id;
    });

    const req = {
      qualification: selectedQualification || [],
      specialization: selectedSpecialized || [],
      country: countryOfPractice ? [countryOfPractice] : []
    };

    handleSaveProfileClick(req);
    setIsSaveEnabled(false);
  };

  const handleCountryOfPractice = (e: SelectChangeEvent) => {
    setCountryOfPractice(e?.target?.value);

    if (e?.target?.value !== user?.country?.[0]?.tid) {
      setCancelEnabled(true);
    } else {
      setCancelEnabled(false);
    }
  };

  const resetProfessionalDetails = () => {
    setSpecializedOptions(defaultSpecialization);
    setQualificationOptions(defaultQualification);
    setCountryOfPractice(user?.country?.[0]?.tid);
    setCancelEnabled(false);
    setIsSaveEnabled(false);
  };

  useEffect(() => {
    if (completedProfile) {
      sendNotificationCall();
      setCompletedProfile(false);
    }
  }, [completedProfile]);

  const sendNotificationCall = async () => {
    const action = notificationList?.find(
      (item: any) =>
        item?.field_unique_code === GAMIFICATION_ACTION_ID.PROFILE_COMPLETION
    )?.title;

    const req = {
      action: action
    };
    // await dispatch(updatePointsWithNotification(req));
  };

  return (
    <div className={styles.profileUpdateForm}>
      {isUpdatePointLoading && <CircularLoader size={50} thickness={4} />}
      <div className={styles.profileUpdateFormCol6}>
        <div className={styles.inputCol}>
          <AutoComplete
            placeholder={t(
              AppConstant.PROFILE.SELECT_YOUR_MEDICAL_QUALIFICATION
            )}
            options={qualificationList}
            id='qualification'
            labelText={t(AppConstant.AUTHENTICATION_PAGE.QUALIFICATION_TEXT)}
            isRequired={true}
            isError={
              !qualificationOptions || qualificationOptions?.length === 0
            }
            errorMessage={t(AppConstant.ERROR.PLEASE_SELECT_QUALIFICATION)}
            // handleChange={handleQualificationOptions}
            // defaultValue={selectedQualification}
            selectedOptions={qualificationOptions}
            onSelectChange={handleQualificationOptions}
          />
        </div>
        <div className={styles.inputCol}>
          <Dropdown
            value={countryOfPractice}
            dropdownId='grouped-select'
            dropdownVariant='outlined'
            labelText={t(
              AppConstant.AUTHENTICATION_PAGE.COUNTRY_OF_PRACTICE_TEXT
            )}
            dropdownOptions={countryOfPracticeList}
            isError={!countryOfPractice}
            errorMessage={t(
              AppConstant.ERROR.PLEASE_SELECT_COUNTRY_OF_PRACTICE
            )}
            isRequired={true}
            handleDropDownChange={(e: SelectChangeEvent) =>
              handleCountryOfPractice(e)
            }
            placeholder={t(
              AppConstant.AUTHENTICATION_PAGE.SELECT_COUNTRY_OF_PRACTICE
            )}
          />
        </div>
      </div>
      <div className={styles.profileUpdateFormCol6}>
        <div className={styles.inputCol}>
          <AutoComplete
            placeholder={t(AppConstant.PROFILE.SELECT_YOUR_SPECIALIZATION)}
            options={specializationList}
            id='specialty'
            labelText={t(AppConstant.AUTHENTICATION_PAGE.SPECIALTY)}
            isRequired={true}
            isError={!specializedOptions || specializedOptions?.length === 0}
            errorMessage={t(AppConstant.ERROR.PLEASE_SELECT_SPECIALIZATION)}
            selectedOptions={specializedOptions}
            onSelectChange={handleSpecializedOptions}
          />
        </div>
      </div>
      {/* <div className={styles.profileUpdateFormCol6}>
        
      </div> */}
      <div className={styles.profileUpdateFormCol12}>
        <div className={styles.profileUpdateFormCtas}>
          <button
            disabled={isCancelEnabled ? false : true}
            onClick={resetProfessionalDetails}
          >
            {getTranslationFormat(AppConstant.PROFILE.CANCEL_CTA)}
          </button>
          <button
            className={styles.save}
            disabled={isSaveEnabled ? false : true}
            onClick={handleSaveClick}
          >
            {getTranslationFormat(AppConstant.PROFILE.SAVE_CTA)}
            <ButtonLoader loader={isSaveLoader} />
          </button>
        </div>
      </div>
    </div>
  );
};
