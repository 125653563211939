"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExploreCommunityIcon = void 0;
const React = __importStar(require("react"));
const ExploreCommunityIcon = (props) => (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: 20, height: 21, fill: "none" }, props),
    React.createElement("path", { fill: "#E84E1B", d: "M14.5 6.997c.8 0 1.56-.32 2.12-.88s.88-1.329.88-2.119c0-.79-.32-1.559-.88-2.118-.56-.56-1.33-.88-2.12-.88s-1.56.32-2.12.88-.88 1.329-.88 2.118c0 .79.32 1.56.88 2.12.56.559 1.33.879 2.12.879Zm-1.06-4.058a1.499 1.499 0 1 1 2.121 2.118A1.499 1.499 0 0 1 13.44 2.94ZM5.5 6.997c.8 0 1.56-.32 2.12-.88s.88-1.329.88-2.119c0-.79-.32-1.559-.88-2.118C7.06 1.32 6.29 1 5.5 1s-1.56.32-2.12.88-.88 1.329-.88 2.118c0 .79.32 1.56.88 2.12.56.559 1.33.879 2.12.879ZM4.44 2.939a1.499 1.499 0 1 1 2.121 2.118A1.499 1.499 0 0 1 4.44 2.94Z" }),
    React.createElement("path", { fill: "#E84E1B", d: "M17.23 7.996h-4.42c-.15-.42-.38-.8-.7-1.12-.56-.559-1.33-.879-2.12-.879s-1.56.32-2.12.88c-.32.32-.55.7-.7 1.12h-4.4c-.98 0-1.77.789-1.77 1.768v.36c0 .21 0 1.41.7 2.579.52.87 1.41 1.649 2.8 2.029v.39c0 .21 0 1.409.7 2.578C5.94 18.931 7.39 20 10 20s4.06-1.06 4.8-2.299c.7-1.17.7-2.369.7-2.578v-.39c1.39-.39 2.28-1.16 2.8-2.03.7-1.168.7-2.368.7-2.578v-.36c0-.98-.79-1.769-1.77-1.769Zm-8.62.43c.08-.18.19-.35.33-.49s.3-.25.49-.33c.19-.08.38-.11.57-.11a1.499 1.499 0 0 1 0 2.999 1.462 1.462 0 0 1-1.06-.44c-.14-.14-.25-.3-.33-.49-.08-.19-.11-.38-.11-.57 0-.19.04-.389.11-.569ZM14 15.123c0 .14 0 .999-.49 1.799-.45.74-1.38 1.569-3.51 1.569s-3.07-.83-3.51-1.57c-.48-.799-.49-1.659-.49-1.798v-.36s0-.07.02-.1a.284.284 0 0 1 .15-.15c.03-.01.07-.02.1-.02h7.46a.273.273 0 0 1 .27.27v.36Zm3.5-4.998c0 .14 0 1-.49 1.8-.34.569-.97 1.189-2.2 1.448-.3-.23-.67-.38-1.08-.38H6.27c-.39 0-.75.14-1.05.36-1.12-.29-1.91-.889-2.24-1.429-.48-.81-.49-1.659-.49-1.809v-.36c0-.15.12-.27.27-.27h4.3c.1.61.38 1.18.82 1.62.56.56 1.33.88 2.12.88s1.56-.32 2.12-.88c.44-.44.72-1.01.82-1.62h4.29c.15 0 .27.12.27.27v.37Z" }),
    React.createElement("path", { stroke: "#E84E1B", strokeWidth: 0.12, d: "M14.5 6.997c.8 0 1.56-.32 2.12-.88s.88-1.329.88-2.119c0-.79-.32-1.559-.88-2.118-.56-.56-1.33-.88-2.12-.88s-1.56.32-2.12.88-.88 1.329-.88 2.118c0 .79.32 1.56.88 2.12.56.559 1.33.879 2.12.879Zm-1.06-4.058a1.499 1.499 0 1 1 2.121 2.118A1.499 1.499 0 0 1 13.44 2.94ZM5.5 6.997c.8 0 1.56-.32 2.12-.88s.88-1.329.88-2.119c0-.79-.32-1.559-.88-2.118C7.06 1.32 6.29 1 5.5 1s-1.56.32-2.12.88-.88 1.329-.88 2.118c0 .79.32 1.56.88 2.12.56.559 1.33.879 2.12.879ZM4.44 2.939a1.499 1.499 0 1 1 2.121 2.118A1.499 1.499 0 0 1 4.44 2.94Z" }),
    React.createElement("path", { stroke: "#E84E1B", strokeWidth: 0.12, d: "M17.23 7.996h-4.42c-.15-.42-.38-.8-.7-1.12-.56-.559-1.33-.879-2.12-.879s-1.56.32-2.12.88c-.32.32-.55.7-.7 1.12h-4.4c-.98 0-1.77.789-1.77 1.768v.36c0 .21 0 1.41.7 2.579.52.87 1.41 1.649 2.8 2.029v.39c0 .21 0 1.409.7 2.578C5.94 18.931 7.39 20 10 20s4.06-1.06 4.8-2.299c.7-1.17.7-2.369.7-2.578v-.39c1.39-.39 2.28-1.16 2.8-2.03.7-1.168.7-2.368.7-2.578v-.36c0-.98-.79-1.769-1.77-1.769Zm-8.62.43c.08-.18.19-.35.33-.49s.3-.25.49-.33c.19-.08.38-.11.57-.11a1.499 1.499 0 0 1 0 2.999 1.462 1.462 0 0 1-1.06-.44c-.14-.14-.25-.3-.33-.49-.08-.19-.11-.38-.11-.57 0-.19.04-.389.11-.569ZM14 15.123c0 .14 0 .999-.49 1.799-.45.74-1.38 1.569-3.51 1.569s-3.07-.83-3.51-1.57c-.48-.799-.49-1.659-.49-1.798v-.36s0-.07.02-.1a.284.284 0 0 1 .15-.15c.03-.01.07-.02.1-.02h7.46a.273.273 0 0 1 .27.27v.36Zm3.5-4.998c0 .14 0 1-.49 1.8-.34.569-.97 1.189-2.2 1.448-.3-.23-.67-.38-1.08-.38H6.27c-.39 0-.75.14-1.05.36-1.12-.29-1.91-.889-2.24-1.429-.48-.81-.49-1.659-.49-1.809v-.36c0-.15.12-.27.27-.27h4.3c.1.61.38 1.18.82 1.62.56.56 1.33.88 2.12.88s1.56-.32 2.12-.88c.44-.44.72-1.01.82-1.62h4.29c.15 0 .27.12.27.27v.37Z" })));
exports.ExploreCommunityIcon = ExploreCommunityIcon;
