import { useEffect, useState } from 'react';
import {
  AppConstant,
  CONTENT_TYPE_ID,
  convertUTCDate,
  convertUTCTime,
  fetchBookmark,
  FILTER_BY_TABS_ENUM,
  getBookmarkData,
  getLoggedInUserInfo,
  getOnlyDate,
  getOnlyTime,
  ImageConstant,
  translate,
  useAppDispatch,
  useAppSelector,
  viewDurationInHours
} from '@shalina-app/shared';

import { BookmarkPagePropsType } from '.';
import {
  BarLoader,
  ButtonComponent,
  Card,
  SortFilter
} from '../../../../components';
import {
  convertToSpecialties,
  getImageSrc,
  retrieveData,
  STORAGE_CONSTANTS,
  useNavigatePageUtils,
  UserInterface
} from '../../../../utils';
import styles from './bookmark.module.scss';
import { pageNames } from '../../../../app.constants';
import { URLConstant } from '../../../../routes';

export const BookmarkPage: React.FC<BookmarkPagePropsType> = ({
  pageName,
  pageRoute
}) => {
  const { urls } = URLConstant;
  const navigatePageUtils = useNavigatePageUtils();
  const dispatch = useAppDispatch();
  const t = translate();

  const [filterOptions, setFilterOptions] = useState({
    contentType: '',
    interest: '',
    sort: '',
    speciality: ''
  });
  const [filterCall, setFilterCall] = useState(false);
  const [selectedAreaOfInterest, setSelectedAreaOfInterest] = useState([]);
  const [reload, setReload] = useState(false);
  const [showNoDataFoundSection, setShowNoDataFoundSection] = useState(false);

  const isBookmarkLoading = useAppSelector(
    (state) => state?.profile?.bookmark?.isBookmarkLoading
  ) as any;
  const bookmarksData = useAppSelector(getBookmarkData);
  const userInfo = useAppSelector(getLoggedInUserInfo);

  const user: UserInterface | any = retrieveData(STORAGE_CONSTANTS.USER);
  const isLoggedIn: boolean = retrieveData(STORAGE_CONSTANTS.IS_LOGGED_IN);

  useEffect(() => {
    if (bookmarksData?.length > 0) {
      setShowNoDataFoundSection(true);
    }
  }, [bookmarksData]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setReload(true);
    setInitialValue();
  }, []);

  const setInitialValue = () => {
    if (
      (![null, undefined, []].includes(userInfo?.areaOfInterest) ||
        ![null, undefined, []].includes(user?.areaOfInterest)) &&
      isLoggedIn
    ) {
      const userDetails = userInfo?.areaOfInterest ?? user?.areaOfInterest;
      const areaOfInterestIds = userDetails?.map(
        (item: any) => item?.tid && String(item?.tid)
      );
      setSelectedAreaOfInterest(areaOfInterestIds);
    }
  };

  const handleExploreClick = () => {
    const navigateDetails = {
      isDynamicUrl: false
    };
    navigatePageUtils(urls.homePage, navigateDetails);
  };

  useEffect(() => {
    if (reload) {
      loadBookmarks();
      setReload(false);
    }
  }, [reload]);

  const loadBookmarks = async () => {
    await dispatch(fetchBookmark(filterOptions));
  };

  const onContentTypeChipSelect = (value: any) => {
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      contentType: value.toString()
    }));
    setFilterCall(true);
  };

  const onAreaOfInterest = (value: string[]) => {
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      interest: value.toString()
    }));
    setFilterCall(true);
  };

  const onSortChange = (value: string) => {
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      sort: value
    }));
    setFilterCall(true);
  };

  const onApplyButtonClick = (
    selectedContentType: Array<string>,
    selectedSpecialization: Array<string>
  ) => {
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      speciality: selectedSpecialization.toString(),
      contentType: selectedContentType.toString()
    }));
    setFilterCall(true);
  };

  useEffect(() => {
    if (filterCall) {
      loadBookmarks();
      setFilterCall(false);
    }
  }, [filterCall]);

  const onResetFilter = () => {
    setFilterOptions((prev) => ({
      ...prev,
      contentType: '',
      speciality: ''
    }));
    setFilterCall(true);
  };

  const bookmarkRemove = (id: any) => {};

  return (
    <>
      <div>
        <>
          <div className={styles.bookmarkFilter}>
            {showNoDataFoundSection && (
              <SortFilter
                isDark={true}
                handleApplyFilter={onApplyButtonClick}
                handleSortChange={onSortChange}
                handleAreaOfInterest={onAreaOfInterest}
                handleResetFilter={onResetFilter}
                tabsEnum={[
                  FILTER_BY_TABS_ENUM.CONTENT_TYPE,
                  FILTER_BY_TABS_ENUM.SPECIALITY
                ]}
                selectedAreaOfInterest={selectedAreaOfInterest}
              />
            )}
            {isBookmarkLoading && (
              <div className={styles.loaderDiv}>
                <BarLoader />
              </div>
            )}
          </div>
          {bookmarksData && bookmarksData?.length > 0 ? (
            <div className={styles.bookmarkCards}>
              {bookmarksData?.map((item: any) => (
                <div className={styles.bookmarkCard}>
                  <Card
                    id={item?.nid}
                    contentType={item?.field_select_article_type}
                    contentTypeId={item?.field_select_article_type_id}
                    title={item?.title}
                    speciality={convertToSpecialties(
                      item?.field_area_of_interest
                    )}
                    date={
                      item?.field_unique_code === CONTENT_TYPE_ID?.WEBINARS
                        ? convertUTCDate(item?.field_starts_on)
                        : getOnlyDate(item?.field_authored_on)
                    }
                    time={
                      item?.field_unique_code === CONTENT_TYPE_ID?.WEBINARS
                        ? convertUTCTime(item?.field_starts_on)
                        : getOnlyTime(item?.field_authored_on)
                    }
                    publishedName={item?.field_authored_by}
                    viewsCount={item?.view_count}
                    duration={viewDurationInHours(item?.field_duration || '')}
                    pageName={pageNames.Profile}
                    pageRoute={pageRoute}
                    thumbnailImage={
                      item?.field_thumbnail_image &&
                      getImageSrc(item?.field_thumbnail_image)
                    }
                    isBookmarked={item?.is_bookmarked}
                    mostViewed={item?.most_viewed}
                    bookmarkRemove={bookmarkRemove}
                    eventId={item?.event_id}
                    uniqueContentTypeId={item?.field_unique_code}
                    category={item?.type}
                    tags={item?.field_tags}
                  />
                </div>
              ))}
            </div>
          ) : !isBookmarkLoading && !showNoDataFoundSection ? (
            <div className={styles.emptyBookmark}>
              <img
                src={ImageConstant.SOURCE.emptyBookmark}
                alt='empty bookmark'
              />
              <h4 className={styles.title}>
                {t(AppConstant.PROFILE.ADD_BOOKMARKS_TEXT)}
              </h4>
              <p className={styles.infoText}>
                {t(AppConstant.PROFILE.NO_BOOKMARK_DESCRIPTION)}
              </p>
              <div className={styles.ctaExplore}>
                <ButtonComponent
                  variant='outlined'
                  buttonText={t(AppConstant.PROFILE.START_EXPLORING_CTA)}
                  onPress={handleExploreClick}
                />
              </div>
            </div>
          ) : (
            !isBookmarkLoading && (
              <div className={styles.contentNotFound}>
                <div className={styles.notFoundImg}>
                  <img
                    src={ImageConstant.SOURCE.emptyBookmark}
                    alt='not found'
                  />
                </div>
                <div className={styles.notFoundText}>
                  <h3>{t(AppConstant.COMMON.NO_BOOKMARK_FOUND)}</h3>
                  <p>{t(AppConstant.COMMON.NO_BOOKMARK_FOUND_DESCRIPTION)}</p>
                </div>
              </div>
            )
          )}
        </>
      </div>
    </>
  );
};
