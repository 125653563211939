import {
  ImageConstant,
  useAppDispatch,
  useAppSelector,
  fetchDynamicListSurvey,
  AppConstant,
  translate,
  getOnLoadSurveyList,
  fetchOnLoadSurvey,
  getLoggedInUserInfo,
  FILTER_BY_TABS_ENUM
} from '@shalina-app/shared';
import { useEffect, useState } from 'react';

import { SurveyPropsItems } from './survey.interface';
import { URLConstant } from '../../routes';
import styles from './survey.module.scss';
import {
  BarLoader,
  InfiniteScrollComponent,
  QuickLinkSection,
  SortFilter
} from '../../components';
import { SurveyCardComponent } from './components';
import { DEFAULT_SKIP, DEFAULT_TAKE } from '../../app.constants';
import { retrieveData, STORAGE_CONSTANTS, UserInterface } from '../../utils';

export const Survey: React.FC<SurveyPropsItems> = () => {
  const { urls } = URLConstant;
  const t = translate();
  const dispatch = useAppDispatch();

  const [surveyListData, setSurveyListData] = useState<any[]>([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataFromStore, setDataFromStore] = useState(true);
  const [reload, setReload] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    skip: 0,
    take: DEFAULT_TAKE,
    interest: '',
    speciality: '',
  });
  const [firstLoad, setFirstLoad] = useState(false);
  const [filterCall, setFilterCall] = useState(false);
  const [selectedAreaOfInterest, setSelectedAreaOfInterest] = useState([]);

  const surveyList = useAppSelector(getOnLoadSurveyList);
  const userInfo = useAppSelector(getLoggedInUserInfo);
  const user: UserInterface | any = retrieveData(STORAGE_CONSTANTS.USER);
  const isLoggedIn: boolean = retrieveData(STORAGE_CONSTANTS.IS_LOGGED_IN);

  useEffect(() => {
    window.scrollTo(0, 0);
    setFirstLoad(true);
    setInitialValue();
  }, []);

  useEffect(() => {
    if (reload) {
      loadApi();
    }
  }, [reload]);

  useEffect(() => {
    if (firstLoad) {
      loadFirstApi();
    }
  }, [firstLoad]);

  const setInitialValue = () => {
    if (
      (![null, undefined, []].includes(userInfo?.areaOfInterest) ||
        ![null, undefined, []].includes(user?.areaOfInterest)) &&
      isLoggedIn
    ) {
      const userDetails = userInfo?.areaOfInterest ?? user?.areaOfInterest;
      const areaOfInterestIds = userDetails?.map(
        (item: any) => item?.tid && String(item?.tid)
      );
      setSelectedAreaOfInterest(areaOfInterestIds);
    }
  };

  const loadFirstApi = async () => {
    setIsLoading(true);
    const response = (await dispatch(
      fetchOnLoadSurvey(filterOptions)
    ).unwrap()) as any;
    if (
      response &&
      response?.status === 'SUCCESS' &&
      response?.statusCode === 200 &&
      response?.data
    ) {
      setIsLoading(false);
      setDataFromStore(false);
      const data = response?.data;
      const totalCount = response?.count;
      setTotalDataCount(totalCount);

      if (firstLoad) {
        if (totalCount <= surveyListData?.length + response?.data?.length) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
        setFirstLoad(false);
      }
      if (surveyListData?.length === 0) {
        setSurveyListData(data);
      } else {
        setSurveyListData((prevOptions: any) => [...prevOptions, ...data]);
      }
    } else {
      setIsLoading(false);
      setDataFromStore(false);
    }
  };

  const loadApi = async () => {
    setIsLoading(true);
    const response = (await dispatch(
      fetchDynamicListSurvey(filterOptions)
    ).unwrap()) as any;
    if (
      response &&
      response?.status === 'SUCCESS' &&
      response?.statusCode === 200 &&
      response?.data
    ) {
      callPagination(response);
    } else {
      setIsLoading(false);
      setDataFromStore(false);
    }
  };

  const callPagination = (response: any) => {
    setIsLoading(false);
    const data = response?.data;
    const totalCount = response?.count;
    setTotalDataCount(totalCount);

    if (reload) {
      if (totalCount <= surveyListData?.length + response?.data?.length) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      setReload(false);
    }
    if (filterCall || surveyListData?.length === 0) {
      setSurveyListData(data);
    } else {
      setSurveyListData((prevOptions: any) => [...prevOptions, ...data]);
    }
  };

  const fetchMoreData = () => {
    if (surveyListData?.length >= totalDataCount) {
      setHasMore(false);
      return;
    }
    setSkip(skip + DEFAULT_SKIP);
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      skip: skip + DEFAULT_SKIP,
      take: DEFAULT_TAKE,
    }));
    setReload(true);
  };
  
  const onAreaOfInterest = (value: string[]) => {
    // if (value?.length) {
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      interest: value?.toString(),
      skip: 0,
      take: DEFAULT_TAKE,
    }));
    setFilterCall(true);
    setSkip(0);
    setReload(true);
    // }
  };

  const onResetFilter = () => {
    setSkip(0);
    setFilterOptions((prevValue) => ({
      ...prevValue,
      speciality: '',
      skip: 0,
      take: DEFAULT_TAKE,
    }));
    setReload(true);
    setFilterCall(true);
  };

  const onApplyButtonClick = (
    selectedContentType: Array<string>,
    selectedSpecialization: Array<string>
  ) => {
    // if (selectedContentType?.length || selectedSpecialization?.length) {
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      speciality: selectedSpecialization?.toString(),
      skip: 0,
      take: DEFAULT_TAKE,
    }));
    setSkip(0);
    setReload(true);
    setFilterCall(true);
    // }
  };

  const content = dataFromStore ? surveyList : surveyListData;

  return (
    <>
      <div className={styles.surveyPage}>
        <div className={styles.surveyTop}>
          <div className='cust-container'>
            <h1 className={styles.head}>{t(AppConstant.COMMON.SURVEY)}</h1>
            <div className={styles.landingFilter}>
              <SortFilter
                isDark={false}
                // categoryType={API_CATEGORY_TYPES.KNOWLEDGE_HUB}
                handleApplyFilter={onApplyButtonClick}
                // handleSortChange={onSortChange}
                handleAreaOfInterest={onAreaOfInterest}
                handleResetFilter={onResetFilter}
                tabsEnum={[FILTER_BY_TABS_ENUM.SPECIALITY]}
                selectedAreaOfInterest={selectedAreaOfInterest}
                isSortDisabled={true}
              />
            </div>
          </div>
        </div>

        {isLoading && (
          // <CircularLoader size={50} thickness={4} />
          <BarLoader />
        )}
        <div className='cust-container'>
          <div className={styles.surveyRow}>
            <div className={styles.surveyCol8}>
              <div>
                {/* loop start here.. */}
                {/* card component start */}
                <>
                  {content?.length > 0 ? (
                    <InfiniteScrollComponent
                      dataLength={content?.length}
                      fetchMoreData={fetchMoreData}
                      hasMore={hasMore}
                      loader={hasMore}
                      scrollThreshold={'1000px'}
                    >
                      <div className={styles.surveyList}>
                        {content?.map((item: any) => {
                          return (
                            <div className={styles.surveyCol}>
                              <SurveyCardComponent data={item} />
                            </div>
                          );
                        })}
                      </div>
                    </InfiniteScrollComponent>
                  ) : content?.length === 0 && !isLoading ? (
                    <div className={styles.contentNotFound}>
                      <div className={styles.notFoundImg}>
                        <img
                          src={ImageConstant.SOURCE.emptyContent}
                          alt='not found'
                        />
                      </div>
                      <div className={styles.notFoundText}>
                        <h3>{t(AppConstant.COMMON.EMPTY_SURVEY)}</h3>
                        {/* <h3>{`${t(AppConstant.HOME_PAGE.NO_TEXT)} ${t(
														AppConstant.HOME_PAGE.DATA_TEXT
													)} ${t(AppConstant.HOME_PAGE.FOUND_TEXT)}`}</h3>*/}
                        <p>
                          {t(AppConstant.HOME_PAGE.NO_SURVEY_FOUND_MESSAGE)}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {/* card component end */}
                  {/* loop end here.. */}
                </>
              </div>
            </div>
            <div className={styles.surveyCol4}>
              <div className={styles.surveyQuickLinks}>
                <QuickLinkSection />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
