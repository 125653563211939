import { api } from '@shalina-app/shared';
import secureLocalStorage from 'react-secure-storage';

import { getImageSrc } from './utils';

export const STORAGE_CONSTANTS = {
  ACCESS_TOEKN: 'access_token',
  IS_LOGGED_IN: 'isLoggedIn',
  USER: 'user',
  PROFILE_PIC: 'profilePic',
  COUNTRY_LIST: 'country_list',
  QUALIFICATION_LIST: 'qualification_list',
  SPECIALIZATION_LIST: 'specialization_list',
  AREA_OF_INTEREST_LIST: 'areaOfInterest_list',
  COUNTRY_OF_PRACTICE_LIST: 'countryName_list',
  SELECTED_LANGUAGE: 'language',
  EVENT_ID: 'eventId',
  VIDEO_TIME: 'time',
  TOTAL_VIDEO_TIME: 'total_time',
  USER_SESSION_FIRST_TIME: 'user_session_first_time'
};

export const storeAccessToken = async (token: string) => {
  await secureLocalStorage.setItem(
    STORAGE_CONSTANTS.ACCESS_TOEKN,
    JSON.stringify(token)
  );
  if (token) {
    api.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  } else {
    api.defaults.headers.common['Authorization'] = '';
  }
};

export const storeData = (key: string, value: any) => {
  secureLocalStorage.setItem(key, value);
};

export const storeProfilePic = (pic: string) => {
  secureLocalStorage.setItem(STORAGE_CONSTANTS.PROFILE_PIC, pic);
};

export const retrieveProfilePic = () => {
  const image: any = secureLocalStorage.getItem(STORAGE_CONSTANTS.PROFILE_PIC);
  if (image) {
    return getImageSrc(image);
  } else {
    return '';
  }
};

export const retrieveData = (key: string) => {
  const data: any = secureLocalStorage.getItem(key);
  return data as any;
};

export const removedFromStore = (key: string) => {
  const data = secureLocalStorage.removeItem(key);
  return data;
};

export const userSignOut = async () => {
  api.defaults.headers.common['Authorization'] = '';
  await removedFromStore(STORAGE_CONSTANTS.USER);
  await removedFromStore(STORAGE_CONSTANTS.ACCESS_TOEKN);
  await removedFromStore(STORAGE_CONSTANTS.IS_LOGGED_IN);
  await removedFromStore(STORAGE_CONSTANTS.PROFILE_PIC);
  await removedFromStore(STORAGE_CONSTANTS.SELECTED_LANGUAGE);

  return true;
};
