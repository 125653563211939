"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateRequestListByType = exports.updateRequestList = exports.getRequestListTabContentByType = exports.getRequestDetails = exports.getRequestListByType = exports.getRequestList = exports.submitRequest = exports.fetchRequestDetails = exports.fetchRequestList = void 0;
const api_1 = require("../../../api");
const toolkit_1 = require("@reduxjs/toolkit");
const constants_1 = require("../../constants");
const initialState = {
    isLoading: false,
    requestlistContent: {
        isError: false,
        isRequestListLoading: true,
        RequestList: {},
    },
    requestDetails: {
        requestDetailsData: {},
        isError: false,
        isRequestDetailsLoading: true,
    },
    submitObj: {},
    requestListByType: {}
};
const requestSlice = (0, toolkit_1.createSlice)({
    name: constants_1.REQUEST,
    initialState,
    reducers: {
        updateRequestList: (state, action) => {
            const { nid } = action.payload;
            const listRequest = state.requestlistContent.RequestList;
            if (listRequest && (listRequest === null || listRequest === void 0 ? void 0 : listRequest.length) > 0) {
                const updatedRequestList = listRequest.filter((item) => item.nid !== nid);
                state.requestlistContent.RequestList = updatedRequestList;
            }
        },
        updateRequestListByType: (state, action) => {
            const { key, dataToStore } = action.payload;
            state.requestListByType[key] = dataToStore;
        },
    },
    extraReducers(builder) {
        /**
         * List of Request List API
         */
        builder.addCase(exports.fetchRequestList.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.isLoading = true;
            state.requestlistContent.isRequestListLoading = true;
        });
        builder.addCase(exports.fetchRequestList.fulfilled, (state, action) => {
            var _a;
            // Add logic if any when API state is fulfilled or data is received from API
            state.isLoading = false;
            state.requestlistContent.isRequestListLoading = false;
            const requestList = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data;
            state.requestlistContent.RequestList = requestList;
        });
        builder.addCase(exports.fetchRequestList.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.isLoading = false;
            state.requestlistContent.isRequestListLoading = false;
        });
        /**
         * Request Details API
         */
        builder.addCase(exports.fetchRequestDetails.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.isLoading = true;
            state.requestDetails.isRequestDetailsLoading = true;
        });
        builder.addCase(exports.fetchRequestDetails.fulfilled, (state, action) => {
            // Add logic if any when API state is fulfilled or data is received from API
            state.isLoading = false;
            state.requestDetails.isRequestDetailsLoading = false;
            // const contentDetailsResponse = action?.payload?.data;
            state.requestDetails.requestDetailsData = action === null || action === void 0 ? void 0 : action.payload;
        });
        builder.addCase(exports.fetchRequestDetails.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.isLoading = false;
            state.requestDetails.isRequestDetailsLoading = false;
        });
        /**
         * Submit Request API
         */
        builder.addCase(exports.submitRequest.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(exports.submitRequest.fulfilled, (state, action) => {
            state.isLoading = false;
            state.submitObj = action === null || action === void 0 ? void 0 : action.payload;
        });
        builder.addCase(exports.submitRequest.rejected, (state, action) => {
            state.isLoading = false;
        });
    },
});
exports.fetchRequestList = (0, toolkit_1.createAsyncThunk)(`${constants_1.REQUEST}/${constants_1.REQUEST_LIST}`, (payload) => __awaiter(void 0, void 0, void 0, function* () {
    //Dynamic list
    const requestList = yield (0, api_1.get)(`${constants_1.REQUEST_LIST_ENDPOINT}?status=${payload.status}&skip=${payload.skip}&take=${payload.take}`);
    return requestList;
}));
exports.fetchRequestDetails = (0, toolkit_1.createAsyncThunk)(`${constants_1.REQUEST}/${constants_1.REQUEST_DETAILS}`, (contentId) => __awaiter(void 0, void 0, void 0, function* () {
    const requestDetails = yield (0, api_1.get)(`${constants_1.REQUEST_DETAILS_ENDPOINT}/${contentId}`);
    return requestDetails;
}));
exports.submitRequest = (0, toolkit_1.createAsyncThunk)(`${constants_1.REQUEST}/${constants_1.SUBMIT_REQUEST}`, (req) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield (0, api_1.post)(constants_1.SUBMIT_REQUEST_ENDPOINT, req);
    return response;
}));
exports.getRequestList = (0, toolkit_1.createSelector)((state) => state.request, (request) => { var _a; return (_a = request === null || request === void 0 ? void 0 : request.requestlistContent) === null || _a === void 0 ? void 0 : _a.RequestList; });
exports.getRequestListByType = (0, toolkit_1.createSelector)((state) => state.request, (request) => { var _a; return (_a = request === null || request === void 0 ? void 0 : request.req) === null || _a === void 0 ? void 0 : _a.RequestList; });
exports.getRequestDetails = (0, toolkit_1.createSelector)((state) => state.request, (request) => {
    var _a, _b, _c, _d, _e, _f;
    if (((_c = (_b = (_a = request === null || request === void 0 ? void 0 : request.requestDetails) === null || _a === void 0 ? void 0 : _a.requestDetailsData) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.length) > 0) {
        return (_f = (_e = (_d = request === null || request === void 0 ? void 0 : request.requestDetails) === null || _d === void 0 ? void 0 : _d.requestDetailsData) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f[0];
    }
});
const getRequestListTabContentByType = (type) => (0, toolkit_1.createSelector)((state) => state.request, (request) => {
    var _a;
    let list = [];
    return list = (_a = request === null || request === void 0 ? void 0 : request.requestListByType[type]) !== null && _a !== void 0 ? _a : [];
});
exports.getRequestListTabContentByType = getRequestListTabContentByType;
_a = requestSlice.actions, exports.updateRequestList = _a.updateRequestList, exports.updateRequestListByType = _a.updateRequestListByType;
exports.default = requestSlice.reducer;
