import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  AppConstant,
  CommunityDetailProps,
  CommunityDetailTabEnumType,
  CommunityStatus,
  COMMUNITY_DETAIL_TABS,
  COMMUNITY_DETAIL_TABS_ENUM,
  COMMUNITY_ENABLED,
  COMMUNITY_REQUEST_STATUS,
  COMMUNITY_TYPE,
  CUSTOM_ANALYTICS_EVENT_NAMES,
  fetchCommunityDetail,
  getAnalyticsUserDetails,
  getCommunityDetail,
  getCommunityDetailTabs,
  getLoggedInUserInfo,
  ImageConstant,
  logEvent,
  PlatformNames,
  translate,
  updateCommunity,
  useAppDispatch,
  useAppSelector,
} from '@shalina-app/shared';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { Speciality, Toast } from '../../../../components';
import styles from './CommunityDetail.module.scss';
import { NavTabs } from '../../../../components/navTabs';
import {
  convertToSpecialties,
  extractEncodeAndCreateLink,
  getImageSrc,
  retrieveData,
  STORAGE_CONSTANTS,
} from '../../../../utils';
import { ButtonLoader, CommunityGuideline, Members } from '../../components';
import { CommunitiesDiscussion, CommunityDetailComponentProps } from '..';
import {
  CommunityAbout,
  CommunityMembers,
  CommunityResources,
  CommunityShare,
  LeaveComponent,
} from './components';
import { SignUpPopup } from '../../../Authentication';
import { appInsights } from '../../../../applicationInsight';

export const CommunityDetail: React.FC<CommunityDetailComponentProps> = ({
  communityId,
  handleCommunityClick,
  handleMainApiCall,
}) => {
  const t = translate();
  const dispatch = useAppDispatch();
  const prevProps: any = useRef();

  const isLoggedIn: boolean = retrieveData(STORAGE_CONSTANTS.IS_LOGGED_IN);
  const user = retrieveData(STORAGE_CONSTANTS.USER);
  const userDetails = useAppSelector(getLoggedInUserInfo);

  const leaveLoader = useAppSelector(
    (state) => state?.community?.leaveCommunity?.isLeaveCommunityUpdating
  );
  const detailContent: CommunityDetailProps =
    useAppSelector(getCommunityDetail);

  const [apiCall, setApiCall] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [askToJoinLoader, setAskToJoinLoader] = useState(false);
  const [rejectLoader, setRejectLoader] = useState(false);
  const [acceptLoader, setAcceptLoader] = useState(false);
  const [revokeLoader, setRevokeLoader] = useState(false);
  const [openGuidelinePopup, setOpenGuidelinePopup] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [showSignUp, setShowSignUp] = useState(false);
  const [showShare, setShowShare] = useState(false);

  const handleToastClose = () => {
    setShowToast(false);
  };

  const handleGuidelinePopupClose = () => {
    setOpenGuidelinePopup(false);
    setAskToJoinLoader(false);
  };

  const handleJoinRequestSubmit = async () => {
    const req = {
      communityId: communityId,
      requestStatus: COMMUNITY_REQUEST_STATUS.JOIN,
    };
    const response = await dispatch(updateCommunity(req)).unwrap();
    if (
      response &&
      response?.status === 'SUCCESS' &&
      response?.statusCode === 200
    ) {
      setShowToast(true);
      setIsSuccess(true);
      setToastMessage(t(AppConstant.COMMUNITY.JOIN_COMMUNITY_REQUEST_SUCCESS));
      setApiCall(true);
    } else {
      setShowToast(true);
      setIsSuccess(false);
      setToastMessage(t(AppConstant.COMMUNITY.JOIN_COMMUNITY_REQUEST_FAILED));
    }
    setAskToJoinLoader(false);
    setOpenGuidelinePopup(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setApiCall(true);
  }, []);

  useEffect(() => {
    if (prevProps?.current && prevProps?.current?.communityId !== communityId) {
      setApiCall(true);
      setTabValue(0);
    }
    prevProps.current = { communityId };
  }, [communityId]);

  useEffect(() => {
    if (apiCall) {
      loadApi();
    }
  }, [apiCall]);

  const loadApi = async () => {
    await dispatch(fetchCommunityDetail(communityId));
    setApiCall(false);
  };

  const handleTabChange = (event: React.SyntheticEvent, value: number) => {
    setTabValue(value);
  };

  const handleSignUpClose = () => {
    setShowSignUp(false);
  };

  const askToJoinClick = async () => {
    if (isLoggedIn) {
      setAskToJoinLoader(true);
      setOpenGuidelinePopup(true);
    } else {
      setShowSignUp(true);
    }
  };

  const handleRevokeRequest = async (community_Id: string) => {
    setRevokeLoader(true);
    const req = {
      communityId: community_Id,
      requestStatus: COMMUNITY_REQUEST_STATUS.REVOKE,
    };
    const response = await dispatch(updateCommunity(req)).unwrap();
    if (
      response &&
      response?.status === 'SUCCESS' &&
      response?.statusCode === 200
    ) {
      setShowToast(true);
      setIsSuccess(true);
      setToastMessage(
        t(AppConstant.COMMUNITY.REVOKE_COMMUNITY_REQUEST_SUCCESS)
      );
      if (COMMUNITY_ENABLED) {
        const userObj = userDetails || user;
        const userInfo = getAnalyticsUserDetails(userObj);
        logEvent(
          appInsights,
          'custom',
          CUSTOM_ANALYTICS_EVENT_NAMES.USER_COMMUNITY_STATUS,
          {
            userEmail: userInfo?.emailId,
            userMobile: userInfo?.mobile,
            userName: userInfo?.name,
            userQualification: userInfo?.qualification,
            userInterest: userInfo?.areaOfInterest,
            userSpecialization: userInfo?.specialization,
            userCountryOfPractice: userInfo?.countryOfPractice,
            userPreferredLanguage: userInfo?.preferredLanguage,
            platform: PlatformNames.WEB,
            communityName: detailContent?.label,
            communityId: community_Id,
            status: CommunityStatus.REVOKED,
          }
        );
      }
      setApiCall(true);
    } else {
      setShowToast(true);
      setIsSuccess(false);
      setToastMessage(t(AppConstant.COMMUNITY.REVOKE_COMMUNITY_REQUEST_FAILED));
    }
    setRevokeLoader(false);
  };

  const handleRejectRequest = async (community_Id: string) => {
    setRejectLoader(true);
    const req = {
      communityId: community_Id,
      requestStatus: COMMUNITY_REQUEST_STATUS.REJECT,
    };
    const response = await dispatch(updateCommunity(req)).unwrap();
    if (
      response &&
      response?.status === 'SUCCESS' &&
      response?.statusCode === 200
    ) {
      setShowToast(true);
      setIsSuccess(true);
      setToastMessage(
        t(AppConstant.COMMUNITY.REJECT_COMMUNITY_REQUEST_SUCCESS)
      );
      if (COMMUNITY_ENABLED) {
        const userObj = userDetails || user;
        const userInfo = getAnalyticsUserDetails(userObj);
        logEvent(
          appInsights,
          'custom',
          CUSTOM_ANALYTICS_EVENT_NAMES.USER_COMMUNITY_STATUS,
          {
            userEmail: userInfo?.emailId,
            userMobile: userInfo?.mobile,
            userName: userInfo?.name,
            userQualification: userInfo?.qualification,
            userInterest: userInfo?.areaOfInterest,
            userSpecialization: userInfo?.specialization,
            userCountryOfPractice: userInfo?.countryOfPractice,
            userPreferredLanguage: userInfo?.preferredLanguage,
            platform: PlatformNames.WEB,
            communityName: detailContent?.label,
            communityId: community_Id,
            status: CommunityStatus.REJECTED,
          }
        );
      }
      setApiCall(true);
    } else {
      setShowToast(true);
      setIsSuccess(false);
      setToastMessage(t(AppConstant.COMMUNITY.REJECT_COMMUNITY_REQUEST_FAILED));
    }
    setRejectLoader(false);
  };

  const handleAcceptRequest = async (community_Id: string) => {
    setAcceptLoader(true);
    const req = {
      communityId: community_Id,
      requestStatus: COMMUNITY_REQUEST_STATUS.ACCEPT,
    };
    const response = await dispatch(updateCommunity(req)).unwrap();
    if (
      response &&
      response?.status === 'SUCCESS' &&
      response?.statusCode === 200
    ) {
      setShowToast(true);
      setIsSuccess(true);
      setToastMessage(
        t(AppConstant.COMMUNITY.ACCEPT_COMMUNITY_REQUEST_SUCCESS)
      );
      if (COMMUNITY_ENABLED) {
        const userObj = userDetails || user;
        const userInfo = getAnalyticsUserDetails(userObj);
        logEvent(
          appInsights,
          'custom',
          CUSTOM_ANALYTICS_EVENT_NAMES.USER_COMMUNITY_STATUS,
          {
            userEmail: userInfo?.emailId,
            userMobile: userInfo?.mobile,
            userName: userInfo?.name,
            userQualification: userInfo?.qualification,
            userInterest: userInfo?.areaOfInterest,
            userSpecialization: userInfo?.specialization,
            userCountryOfPractice: userInfo?.countryOfPractice,
            userPreferredLanguage: userInfo?.preferredLanguage,
            platform: PlatformNames.WEB,
            communityName: detailContent?.label,
            communityId: community_Id,
            status: CommunityStatus.ACCEPTED,
          }
        );
      }
      setApiCall(true);
    } else {
      setShowToast(true);
      setIsSuccess(false);
      setToastMessage(t(AppConstant.COMMUNITY.ACCEPT_COMMUNITY_REQUEST_FAILED));
    }
    setAcceptLoader(false);
  };

  const handleOnLeave = () => {
    setApiCall(true);
    handleMainApiCall();
  };

  const handleCopyButton = () => {
    setShowShare((prev) => !prev);
    const linkToCopy = window.location.href;
    // navigator.clipboard.writeText(getShareableLink);
  };

  const getShareableLink = '';

  // const getShareableLink = useMemo(() => {
  //   const userName = user?.firstName + ' ' + user?.lastName;
  //   const contentMsg = `${t(AppConstant.PROFILE.HI_TEXT)}, ${userName} ${t(
  //     AppConstant.PROFILE.SHARE_CONTENT_MSG
  //   )} ${t(AppConstant.COMMON.SURVEY)} ${t(
  //     AppConstant.PROFILE.CONTENT_TEXT
  //   )} "${data?.title}" ${t(AppConstant.PROFILE.CLICK_TEXT)} ${
  //     window.location.href
  //   } ${t(AppConstant.PROFILE.SHARE_CONTENT_REMAINING_MSG)}`;
  //   return `${contentMsg}`;
  // }, [data, user]);

  const defaultTabs = [
    {
      id: COMMUNITY_DETAIL_TABS.DISCUSSIONS,
      enumId: COMMUNITY_DETAIL_TABS_ENUM.DISCUSSIONS,
      label: t(AppConstant.COMMUNITY.COMMUNITY_DETAIL_DISCUSSIONS_TAB),
      component: (
        <CommunitiesDiscussion
          communityId={communityId}
          showCommunityInfo={false}
          isCommunityDetail={true}
          isQuestionAnswersTab={false}
          isExpert={detailContent?.isKol}
          handleCommunityClick={handleCommunityClick}
        />
      ),
    },
    {
      id: COMMUNITY_DETAIL_TABS.ABOUT,
      enumId: COMMUNITY_DETAIL_TABS_ENUM.ABOUT,
      label: t(AppConstant.COMMUNITY.COMMUNITY_DETAIL_ABOUT_TAB),
      component: (
        <CommunityAbout description={detailContent?.field_description} />
      ),
    },
    {
      id: COMMUNITY_DETAIL_TABS.MEMBERS,
      enumId: COMMUNITY_DETAIL_TABS_ENUM.MEMBERS,
      label: t(AppConstant.COMMUNITY.COMMUNITY_DETAIL_MEMBERS_TAB),
      component: <CommunityMembers communityId={communityId} />,
    },
    {
      id: COMMUNITY_DETAIL_TABS.RESOURCES,
      enumId: COMMUNITY_DETAIL_TABS_ENUM.RESOURCES,
      label: t(AppConstant.COMMUNITY.COMMUNITY_DETAIL_RESOURCES_TAB),
      component: <CommunityResources communityId={communityId} />,
    },
  ];

  const tabsEnum: any = getCommunityDetailTabs(
    isLoggedIn,
    detailContent?.isJoined
  );

  const filterTabs = tabsEnum?.length
    ? defaultTabs?.filter((item) =>
        tabsEnum?.includes(item?.enumId as CommunityDetailTabEnumType)
      )
    : defaultTabs;

  const tabs = filterTabs.sort((a: any, b: any) => {
    return tabsEnum?.indexOf(a?.enumId) - tabsEnum?.indexOf(b?.enumId);
  });

  const specialityArr = convertToSpecialties(
    detailContent?.field_interests || ''
  );

  const isEmpty =
    detailContent && Object.keys(detailContent)?.length === 0 ? true : false;

  const { ShareIcon } = ImageConstant.SVG_ICONS;

  return (
    <>
      {!isEmpty && detailContent ? (
        <div className={styles.detailsWrapper}>
          <div className={styles.detailTopWrapper}>
            <div className={`${styles.detailTop}`}>
              <div className={styles.detailIconWrapper}>
                <img
                  className={styles.detailIcon}
                  src={
                    getImageSrc(detailContent?.field_community_icon) ||
                    ImageConstant.SOURCE.communityIcon
                  }
                  alt='img'
                  onError={(e) =>
                    ((e.target as HTMLInputElement).src =
                      ImageConstant.SOURCE.communityIcon)
                  }
                />
              </div>
              <div className={styles.details}>
                <h4 className={styles.title}>{detailContent?.label}</h4>
                <div className={styles.speciality}>
                  <Speciality speciality={specialityArr} showAll={false} />
                </div>
                <Members
                  memberCount={detailContent?.member_count}
                  memberImages={detailContent?.member_image}
                />
              </div>
            </div>
            {isLoggedIn && detailContent?.isJoined && (
              <LeaveComponent
                communityId={communityId}
                handleOnLeave={handleOnLeave}
                communityName={detailContent?.label}
              />
            )}
            {/* <div className={styles.actionCta}>
            {!detailContent?.isRequested &&
            !detailContent?.isJoined &&
            !detailContent?.isInvited ? (
              <button className={styles.darkCta} onClick={handleJoinModalOpen}>
                {t(AppConstant.COMMUNITY.COMMUNITY_ASK_TO_JOIN_CTA)}
              </button>
            ) : detailContent?.isRequested &&
              !detailContent?.isJoined &&
              !detailContent?.isInvited ? (
              <button>{t(AppConstant.COMMUNITY.COMMUNITY_REVOKE_CTA)}</button>
            ) : null}
          </div> */}
          </div>
          <div className={styles.shareAndCta}>
            <div className={styles.actionCta}>
              {detailContent?.isJoined ? null : !detailContent?.isRequested &&
                !detailContent?.isInvited ? (
                <button
                  className={styles.darkCta}
                  onClick={() => askToJoinClick()}
                >
                  {t(AppConstant.COMMUNITY.COMMUNITY_ASK_TO_JOIN_CTA)}
                  <ButtonLoader loader={askToJoinLoader} />
                </button>
              ) : detailContent?.isRequested && !detailContent?.isInvited ? (
                <button onClick={() => handleRevokeRequest(detailContent?.id)}>
                  {t(AppConstant.COMMUNITY.COMMUNITY_REVOKE_CTA)}
                  <ButtonLoader loader={revokeLoader} />
                </button>
              ) : !detailContent?.isRequested && detailContent?.isInvited ? (
                <>
                  <button
                    onClick={() => handleRejectRequest(detailContent?.id)}
                  >
                    {t(AppConstant.COMMUNITY.COMMUNITY_REJECT_CTA)}
                    <ButtonLoader loader={rejectLoader} />
                  </button>
                  <button
                    className={styles.darkCta}
                    onClick={() => handleAcceptRequest(detailContent?.id)}
                  >
                    {t(AppConstant.COMMUNITY.COMMUNITY_ACCEPT_CTA)}
                    <ButtonLoader loader={acceptLoader} />
                  </button>
                </>
              ) : null}
            </div>
            <CommunityShare content={detailContent} />
          </div>
          <div className={styles.communityTabs}>
            <NavTabs
              tabs={tabs}
              tabValue={tabValue}
              handleTabChange={handleTabChange}
            />
          </div>
        </div>
      ) : (
        <div></div>
      )}

      <Dialog
        open={openGuidelinePopup}
        onClose={handleGuidelinePopupClose}
        className={styles.joinModal}
      >
        <DialogContent>
          <button
            className={styles.closeCta}
            onClick={handleGuidelinePopupClose}
          >
            &times;
          </button>
          <CommunityGuideline
            communityGuidelines={detailContent?.field_community_guidelines}
            handleJoinRequestSubmit={handleJoinRequestSubmit}
          />
        </DialogContent>
      </Dialog>
      <SignUpPopup open={showSignUp} handleClose={handleSignUpClose} />
      <Toast
        showToast={showToast}
        isSuccess={isSuccess}
        message={toastMessage}
        handleToastClose={handleToastClose}
      />
    </>
  );
};
