import {
  AppConstant,
  COMMUNITY_ACTION_ID,
  COMMUNITY_ENABLED,
  GAMIFICATION_ACTION_ID,
  getNotificationList,
  getOnlyTime,
  ImageConstant,
  markAsReadNotification,
  REQUEST_NOTIFICATION_CODE,
  translate,
  useAppDispatch,
} from '@shalina-app/shared';
import { useEffect, useState } from 'react';

import {
  DEFAULT_SKIP,
  DEFAULT_TAKE,
  homeListingRoutes,
  pageNames,
  PROFILE_PAGE_TABS,
} from '../../../../app.constants';
import {
  BarLoader,
  ButtonComponent,
  InfiniteScrollComponent,
} from '../../../../components';
import { URLConstant } from '../../../../routes';
import {
  getPageNameByCategory,
  removedFromStore,
  replaceSlashesAndSpaces,
  retrieveData,
  STORAGE_CONSTANTS,
  useNavigatePageUtils,
} from '../../../../utils';
import { NotificationItemProps } from './notification.interface';
import styles from './notification.module.scss';

export const NotificationPage: React.FC = () => {
  const t = translate();
  const { urls } = URLConstant;

  const navigatePageUtils = useNavigatePageUtils();
  const dispatch = useAppDispatch();

  const [refresh, setRefresh] = useState<boolean>(false);
  const [skip, setSkip] = useState(0);
  const [sections, setSections] = useState<any>();
  const [isLoader, setIsLoader] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [contentData, setContentData] = useState<any>([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [filterOptions, setFilterOptions] = useState<any>({
    skip: 0,
    take: DEFAULT_TAKE,
  });

  const handleBackToHome = () => {
    const navigateUrl = urls.homePage;
    navigatePageUtils(navigateUrl, { isDynamicUrl: false });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setRefresh(true);
  }, []);

  useEffect(() => {
    if (refresh) {
      getNotifiocation();
    }
  }, [refresh]);

  const getNotifiocation = async () => {
    const response = await dispatch(
      getNotificationList(filterOptions)
    ).unwrap();

    if (
      response &&
      response?.status === 'SUCCESS' &&
      response?.statusCode === 200
    ) {
      setPagination(response?.data);
    } else {
      setIsLoader(false);
    }
  };

  useEffect(() => {
    if (contentData?.length > 0) {
      const sectionsData = groupNotificationsByDate(contentData);
      setSections(sectionsData);
    } else {
      setSections([]);
    }
  }, [contentData]);

  const groupNotificationsByDate = (notifications: NotificationItemProps[]) => {
    const groupedData = [
      {
        title: t(AppConstant.COMMON.TODAY_TEXT),
        data: [] as NotificationItemProps[],
      },
      {
        title: t(AppConstant.COMMON.YESTERDAY_TEXT),
        data: [] as NotificationItemProps[],
      },
      {
        title: t(AppConstant.COMMON.OLDER_TEXT),
        data: [] as NotificationItemProps[],
      },
    ];

    const today = new Date();
    today?.setHours(0, 0, 0, 0);

    const yesterday = new Date(today);
    yesterday?.setDate(today?.getDate() - 1);

    notifications?.forEach((notification: NotificationItemProps) => {
      const notificationDate = new Date(notification?.createdAt as string);

      if (notificationDate >= today) {
        groupedData[0]?.data?.push(notification);
      } else if (notificationDate >= yesterday) {
        groupedData[1]?.data?.push(notification);
      } else {
        groupedData[2]?.data?.push(notification);
      }
    });

    return groupedData;
  };

  const handleNotificationClick = async (item: any) => {
    const res = await dispatch(markAsReadNotification(item?.id)).unwrap();
    const actionId = item?.data?.field_unique_code;

    if (res && actionId) {
      setIsLoader(true);

      if (actionId === GAMIFICATION_ACTION_ID.CONTENT_PUBLISH) {
        const contentId = item?.data?.contentId;
        const category = item?.data?.category;
        const contentTypeId = item?.data?.contentTypeId;
        const page = getPageNameByCategory(category);

        if (retrieveData(STORAGE_CONSTANTS?.EVENT_ID)) {
          removedFromStore(STORAGE_CONSTANTS?.EVENT_ID);
        }

        if (page && contentId && contentTypeId) {
          const navigateDetails = {
            // stateParams: {
            //   eventID: eventId || ''
            // },
            isDynamicUrl: true,
            dynamicUrlOptions: {
              pageName: page,
              contentId: contentId,
              contentTypeId: contentTypeId,
            },
          };
          setIsLoader(false);
          navigatePageUtils(urls?.detailPageWithModule, navigateDetails);
        }
      } else if (actionId === GAMIFICATION_ACTION_ID.PROFILE_COMPLETION) {
        const navigateDetails = {
          isDynamicUrl: false,
        };
        setIsLoader(false);
        navigatePageUtils(urls.profilePage, navigateDetails);
      } else if (actionId === GAMIFICATION_ACTION_ID.CONTINUE_WHERE_YOU_LEFT) {
        const dynamicUrlEncoded = replaceSlashesAndSpaces(
          homeListingRoutes.CONTINUE_WHERE_YOU_LEFT_OFF
        );

        if (dynamicUrlEncoded) {
          const navigateDetails = {
            stateParams: {
              sectionTitle: t(
                AppConstant.HOME_PAGE.CONTINUE_WHERE_LEFT_OFF
              )?.toLowerCase(),
              sourcePage: pageNames.Notification,
              backRoute: urls.notificationPage,
            },
            isDynamicUrl: true,
            dynamicUrlOptions: {
              // contentType: dynamicUrlEncoded,
              sectionTitle: dynamicUrlEncoded,
            },
          };
          // Hide Continue where you left feature
          setIsLoader(false);
          navigatePageUtils(urls.listingPage, navigateDetails);
        }
      } else if (COMMUNITY_ENABLED && COMMUNITY_ACTION_ID.includes(actionId)) {
        const navigateDetails = {
          isDynamicUrl: false,
          stateParams: {
            id: item?.data?.communityId,
            isNotification: true,
          },
        };
        setIsLoader(false);
        navigatePageUtils(urls.commiunitiesPage, navigateDetails);
      } else if (actionId === REQUEST_NOTIFICATION_CODE) {
        const navigateDetails = {
          isDynamicUrl: false,
          stateParams: {
            navigateFromHelpSupport: true,
            showDetailsPage: true,
            nid: item?.data?.requestId,
          },
        };
        setIsLoader(false);
        navigatePageUtils(urls.profilePage, navigateDetails);
      } else if (actionId === GAMIFICATION_ACTION_ID.UPDATE_MILESTONE) {
        const navigateDetails = {
          isDynamicUrl: false,
          stateParams: {
            tab: PROFILE_PAGE_TABS.ACHIEVEMENTS,
          },
        };
        setIsLoader(false);
        navigatePageUtils(urls.profilePage, navigateDetails);
      } else if (
        actionId === GAMIFICATION_ACTION_ID.WEBINAR_BEFORE_24_HOURS ||
        actionId === GAMIFICATION_ACTION_ID.WEBINAR_BEFORE_2_HOURS
      ) {
        const contentId = item?.data?.contentId;
        const category = item?.data?.category;
        const contentTypeId = item?.data?.contentTypeId;
        const eventId = item?.data?.eventId;
        const page = getPageNameByCategory(category);

        const navigateDetails = {
          stateParams: {
            eventID: eventId || '',
          },
          isDynamicUrl: true,
          dynamicUrlOptions: {
            pageName: page,
            contentId: contentId,
            contentTypeId: contentTypeId,
          },
        };
        setIsLoader(false);
        navigatePageUtils(urls.detailPageWithModule, navigateDetails);
      }
    }
  };

  const fetchMoreData = () => {
    if (contentData?.length >= totalDataCount) {
      setHasMore(false);
      return;
    }
    setSkip(skip + DEFAULT_SKIP);
    setFilterOptions((prevOptions: any) => ({
      ...prevOptions,
      skip: skip + DEFAULT_SKIP,
      take: DEFAULT_TAKE,
    }));
    setRefresh(true);
  };

  const setPagination = (response: any) => {
    setIsLoader(false);
    const data = response?.notifications;
    const totalCount = response?.totalCount;
    setTotalDataCount(totalCount);

    if (refresh) {
      if (contentData?.length >= response?.totalCount) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      setRefresh(false);
    }
    if (data && data?.length > 0) {
      setContentData((prevOptions: any) => [...prevOptions, ...data]);
    }
  };

  return (
    <>
      {isLoader && <BarLoader />}
      <div className={styles.notificationPage}>
        {contentData?.length > 0 ? (
          <div className='cust-container'>
            <h1 className={styles.pageHead}>
              {`${t(
                AppConstant.HEADER.NOTIFICATIONS_TEXT
              )} (${totalDataCount})`}
            </h1>
            <div
              className={`${styles.notificationWrapper} ${styles.listWrapper}`}
            >
              <InfiniteScrollComponent
                dataLength={contentData?.length}
                fetchMoreData={fetchMoreData}
                hasMore={true}
                loader={hasMore}
              >
                <div className={styles.notifSec}>
                  {sections?.map(
                    (section: {
                      title: string;
                      data: NotificationItemProps[];
                    }) => (
                      <section key={section.title} className={styles.notifSec}>
                        {section?.data?.length > 0 && (
                          <h2 className={styles.notifsecTitle}>
                            {section?.title}
                          </h2>
                        )}
                        <div className={styles.notificationList}>
                          {(section?.data as NotificationItemProps[]).map(
                            (item: NotificationItemProps, index: number) => (
                              <div
                                key={index}
                                className={`${styles.notificationCard} ${
                                  !item?.isRead && styles.read
                                }`}
                              >
                                <div
                                  className={`${styles.notificationContent}`}
                                  onClick={() => {
                                    handleNotificationClick(item);
                                  }}
                                >
                                  <h4>{item?.title}</h4>
                                  <p>{item?.description}</p>
                                </div>
                                <div className={styles.notificationTime}>
                                  {getOnlyTime(item?.createdAt as string)}
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </section>
                    )
                  )}
                </div>
              </InfiniteScrollComponent>
            </div>
          </div>
        ) : (
          !isLoader && (
            <>
              <div className='cust-container'>
                <h1 className={styles.pageHead}>
                  {t(AppConstant.HEADER.NOTIFICATIONS_TEXT)}
                </h1>
                <div
                  className={`${styles.notificationWrapper} ${styles.notfound}`}
                >
                  <img
                    src={ImageConstant.SOURCE.emptyNotification}
                    alt='not found img'
                    className={styles.notFoundImg}
                  />
                  <div className={styles.notFoundContent}>
                    <h4> {t(AppConstant.HEADER.NO_NEW_NOTIFICATION_TEXT)}</h4>
                    <p>{t(AppConstant.HEADER.NO_NOTIFICATION_ERROR_TEXT)}</p>
                    <div className={styles.backToHome}>
                      <ButtonComponent
                        variant='outlined'
                        buttonText={t(AppConstant.HEADER.BACK_TO_HOME_CTA)}
                        onPress={handleBackToHome}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
        )}
      </div>
    </>
  );
};
