import {
  AppConstant,
  CUSTOM_ANALYTICS_EVENT_NAMES,
  getAnalyticsUserDetails,
  getDefaultImage,
  getFilterCategoriesOptionsValues,
  getHideInterestFlag,
  getIconForContentType,
  getLoggedInUserInfo,
  hideDate,
  hideTime,
  ImageConstant,
  logEvent,
  PlatformNames,
  translate,
  updateBookmark,
  useAppDispatch,
  useAppSelector,
} from '@shalina-app/shared';
import { useEffect, useState } from 'react';

import { pageNames } from '../../../../app.constants';
import { appInsights } from '../../../../applicationInsight';
import { Speciality } from '../../../../components';
import { URLConstant } from '../../../../routes';
import {
  getImageSrc,
  getPageNameByCategory,
  removedFromStore,
  retrieveData,
  STORAGE_CONSTANTS,
  storeData,
  useNavigatePageUtils,
  UserInterface,
} from '../../../../utils';
import { RegisterEventCardProps } from './registerEventCard.interface';
import styles from './registerEventCard.module.scss';

export const RegisterEventCard: React.FC<RegisterEventCardProps> = ({
  id,
  contentType,
  contentTypeId,
  title,
  speciality,
  date,
  time,
  publishedName,
  viewsCount,
  duration,
  pageName,
  pageRoute,
  middleRoute,
  thumbnailImage,
  isBookmarked,
  mostViewed,
  invokeCardClick,
  hasFullWidth,
  bookmarkRemove,
  eventId,
  uniqueContentTypeId,
}) => {
  const t = translate();
  const dispatch = useAppDispatch();
  const navigatePageUtils = useNavigatePageUtils();
  const { urls } = URLConstant;
  const isLoggedIn: boolean = retrieveData(STORAGE_CONSTANTS.IS_LOGGED_IN);
  const user: UserInterface | any = retrieveData(STORAGE_CONSTANTS.USER);

  const userDetails = useAppSelector(getLoggedInUserInfo);

  const [showSignUp, setShowSignUp] = useState(false);
  const [bookmarked, setBookmarked] = useState(isBookmarked);
  const [contentCategory, setContentCategory] = useState('');

  const contentIcon: any = useAppSelector(
    getIconForContentType(uniqueContentTypeId)
  );
  const masterContentTypeData = useAppSelector(
    getFilterCategoriesOptionsValues
  );

  const handleSignUpClose = () => {
    setShowSignUp(false);
  };

  const handleSignUpFlow = () => {
    setShowSignUp(true);
  };

  useEffect(() => {
    const category = masterContentTypeData?.find(
      (item: any) => item?.unique_id === uniqueContentTypeId
    )?.category;

    setContentCategory(category);
  }, [masterContentTypeData]);

  const handleCardClick = () => {
    // const contentTypeId = masterContentTypeData?.find((item) => item?.label === contentType)?.value;
    const category = masterContentTypeData?.find(
      (item: any) => item?.value === contentTypeId
    )?.category;

    const page = getPageNameByCategory(category);

    // if (eventId) {
    //   storeData(STORAGE_CONSTANTS.EVENT_ID, eventId);
    // } else if (retrieveData(STORAGE_CONSTANTS.EVENT_ID)) {
    //   removedFromStore(STORAGE_CONSTANTS.EVENT_ID);
    // }
    if (page !== pageNames.Home && page) {
      if (id && contentTypeId) {
        const navigateDetails = {
          stateParams: {
            eventID: eventId || '',
          },
          isDynamicUrl: true,
          dynamicUrlOptions: {
            pageName: page,
            contentId: id,
            contentTypeId: contentTypeId,
          },
        };
        navigatePageUtils(urls.detailPageWithModule, navigateDetails);
      }
    } else {
      if (id && contentTypeId) {
        const navigateDetails = {
          isDynamicUrl: true,
          dynamicUrlOptions: {
            contentId: id,
            contentTypeId: contentTypeId,
          },
        };
        navigatePageUtils(urls.detailPage, navigateDetails);
      }
    }
    if (invokeCardClick) {
      invokeCardClick();
    }
  };

  const handleBookmarkClick = (id: number | string) => {
    if (!isLoggedIn) {
      handleSignUpFlow();
    } else if (id && title && user) {
      const userObj = userDetails || user;
      const userInfo = getAnalyticsUserDetails(userObj);
      logEvent(
        appInsights,
        'custom',
        CUSTOM_ANALYTICS_EVENT_NAMES.USER_BOOKMARK_CONTENT,
        {
          contentId: id,
          contentTitle: title,
          userEmail: userInfo?.emailId,
          userMobile: userInfo?.mobile,
          userName: userInfo?.name,
          userQualification: userInfo?.qualification,
          userInterest: userInfo?.areaOfInterest,
          userSpecialization: userInfo?.specialization,
          userCountryOfPractice: userInfo?.countryOfPractice,
          userPreferredLanguage: userInfo?.preferredLanguage,
          platform: PlatformNames.WEB,
        }
      );
      const req = {
        id: String(id),
      };
      dispatch(updateBookmark(req));

      if (pageName === pageNames.Profile) {
        setBookmarked(!bookmarked);
        if (bookmarkRemove) {
          bookmarkRemove(id);
        }
      } else {
        setBookmarked(!bookmarked);
      }
    }
  };

  const { TimeIcon, DateIcon } = ImageConstant.SVG_ICONS;

  const dateHide = hideDate(contentCategory, uniqueContentTypeId);
  const timeHide = hideTime(contentCategory, uniqueContentTypeId);
  const hideInterest = getHideInterestFlag(
    contentCategory,
    uniqueContentTypeId
  );

  return (
    <div
      className={`${styles.cardContainer} ${hasFullWidth && styles.fullWidth}`}
    >
      <div className={styles.cardWrapper}>
        {mostViewed && (
          <span className={styles.mostViewed}>
            {t(AppConstant.COMMON.MOST_VIEWED_TEXT)}
          </span>
        )}

        <div className={styles.contentType}>
          {contentType && (
            <>
              {contentIcon ? (
                <img src={getImageSrc(contentIcon)} alt='content icon' />
              ) : null}
              <span className={`${mostViewed && styles.ismostView}`}>
                {contentType}
              </span>
            </>
          )}
        </div>

        <div className={styles.titleImg}>
          {title && (
            <h4 className={styles.cardTitle} onClick={handleCardClick}>
              {title}
            </h4>
          )}
          {thumbnailImage ? (
            <img
              src={thumbnailImage}
              alt='card img'
              onClick={handleCardClick}
              onError={(e) =>
                ((e.target as HTMLInputElement).src = getDefaultImage(
                  false,
                  contentCategory,
                  uniqueContentTypeId
                ))
              }
            />
          ) : (
            <img
              src={getDefaultImage(false, contentCategory, uniqueContentTypeId)}
              alt='card img'
              onClick={handleCardClick}
            />
          )}
        </div>
        <div className={styles.heightWrapper}>
          <div className={styles.speciality}>
            {!hideInterest && speciality && (
              <Speciality speciality={speciality} showAll={false} />
            )}
          </div>
          <div className={styles.dateTime}>
            <>
              {!dateHide && date && (
                <div className={styles.date}>
                  <DateIcon />
                  <span>{t(AppConstant.COMMON.DATE)} </span>
                  {date}
                </div>
              )}
              {!timeHide && time && (
                <div className={styles.time}>
                  <TimeIcon />
                  <span>{t(AppConstant.COMMON.TIME)} </span>
                  {time}
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};
