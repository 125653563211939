import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { AppConstant, JSONConstant, translate } from '@shalina-app/shared';

import styles from './journey.module.scss';

export const Journey: React.FC<any> = () => {
  const [data, setData] = useState<any>([]);
  const t = translate();
  const jsonResponse = JSONConstant.SOURCE.timelineData?.content;

  useEffect(() => {
    if (jsonResponse && jsonResponse?.length > 0) {
      const content =
        jsonResponse?.[0]?.elements?.[0]?.elements?.[0]?.settings.twae_list;
      setData(content);
    }
  }, [jsonResponse]);

  return (
    <>
      {/* <div className={styles.journeyNav}>
        <div className={styles.navItem}>
          <button>2019</button>
        </div>
        <div className={styles.navItem}>
          <button>2020</button>
        </div>
        <div className={styles.navItem}>
          <button>2021</button>
        </div>
        <div className={styles.navItem}>
          <button>2022</button>
        </div>
        <div className={`${styles.navItem} ${styles.active}`}>
          <button>2023</button>
        </div>
      </div> */}
      <div className={styles.whoWeAre}>
        <h2 className={styles.title}>About Shalina Healthcare</h2>
        <div className={styles.weAreInfo}>
          <div className={styles.infoText}>
            <p>
              Shalina Healthcare is a market leader in making quality healthcare
              products and services available across Africa, ensuring they are
              affordable, reliable, and readily accessible to those who need
              them most. With this commitment, we have grown into a global
              player over the past 4 decades, with presence in Africa, the
              Middle East, and Asia.
            </p>
            <p>
              At its core, Shalina Healthcare is more than a pharmaceutical
              company; we are an organization deeply devoted to transforming the
              healthcare landscape in Africa. Knowledge dissemination remains
              the cornerstone of our mission and we actively empower healthcare
              professionals by providing them with essential tools to enhance
              and update their medical knowledge. This is achieved through
              diverse learning initiatives, including international webinars,
              Continuing Medical Education (CME) programs, and certification
              courses hosted by the Shalina Academy.{' '}
            </p>
            <p>
              Taking a leap forward in this noble direction, we proudly
              introduce Shalina MedSpace— our new digital education platform set
              to make a profound impact on the medical community. Through
              Shalina MedSpace, we aspire to create a more significant and
              lasting influence, underscoring our dedication to advancing
              healthcare excellence in Africa.
            </p>
          </div>
        </div>
      </div>
      <div className={styles.whoWeAre}>
        <h2 className={styles.title}>
          {t(AppConstant.COMMON.JOURNEY_TAB_TEXT)}
        </h2>
      </div>
      <div className={styles.journeyWrapper}>
        {data?.map((item: any) => (
          <div className={styles.journeyItem}>
            <div className={styles.date}>{item?.twae_date_label}</div>
            <div className={styles.imgContent}>
              <img src={item?.twae_image.url} className={styles.contentImg} />
              <div className={styles.journeyContent}>
                {parse(item?.twae_description)}
              </div>
              {/* <div className={styles.journeyImg}>
                <img src={ImageConstant.SOURCE.basketImg} alt="journey img" />
              </div> */}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
