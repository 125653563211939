import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import {
  AppConstant,
  getCountryCode,
  useAppSelector
} from '@shalina-app/shared';

import { UpdateProfilePagePropsType } from '.';
import {
  getTranslationFormat,
  retrieveData,
  STORAGE_CONSTANTS
} from '../../../../utils';
import { Interests, Personal, Professional } from './components';
import styles from './updateProfile.module.scss';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}
export const UpdateProfilePage: React.FC<UpdateProfilePagePropsType> = ({
  verificationFor,
  updatedMobile,
  updatedEmail,
  // refreshPage,
  setTabValue,
  handleSaveProfileClick,
  profileData,
  isSaveLoader
}) => {
  const countryList = useAppSelector(getCountryCode);

  const qualificationList: any = retrieveData(
    STORAGE_CONSTANTS.QUALIFICATION_LIST
  );
  const specializationList: any = retrieveData(
    STORAGE_CONSTANTS.SPECIALIZATION_LIST
  );
  const countryNameList: any = retrieveData(
    STORAGE_CONSTANTS.COUNTRY_OF_PRACTICE_LIST
  );
  const interestList: any = retrieveData(
    STORAGE_CONSTANTS.AREA_OF_INTEREST_LIST
  );

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const tabs = [
    {
      id: 0,
      label: getTranslationFormat(AppConstant.PROFILE.PERSONAL_TAB_TEXT),
      component: (
        <Personal
          countryList={countryList}
          verificationFor={verificationFor}
          updatedMobile={updatedMobile}
          updatedEmail={updatedEmail}
          handleSaveProfileClick={handleSaveProfileClick}
          profileData={profileData}
          isSaveLoader={isSaveLoader}
        />
      )
    },
    {
      id: 1,
      label: getTranslationFormat(AppConstant.PROFILE.PROFESSIONAL_TAB_TEXT),
      component: (
        <Professional
          qualificationList={qualificationList}
          specializationList={specializationList}
          countryOfPracticeList={countryNameList}
          handleSaveProfileClick={handleSaveProfileClick}
          profileData={profileData}
          isSaveLoader={isSaveLoader}
        />
      )
    },
    {
      id: 2,
      label: getTranslationFormat(AppConstant.PROFILE.INTEREST_TAB_TEXT),
      component: (
        <Interests
          areaOfInterestList={interestList}
          // refreshPage={refreshPage}
          setTabValue={setTabValue}
          setInterestTab={setValue}
          handleSaveProfileClick={handleSaveProfileClick}
          profileData={profileData}
          isSaveLoader={isSaveLoader}
        />
      )
    }
  ];

  return (
    <div className={styles.profileTabs}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label='Detail Tabs'
        className={styles.custTabs}
      >
        {tabs &&
          tabs.map((tab) => <Tab label={tab?.label} {...a11yProps(tab?.id)} />)}
      </Tabs>
      <div className={styles.custTabContent}>
        {tabs &&
          tabs.map((tab) => (
            <CustomTabPanel value={value} index={tab?.id}>
              {tab?.component}
            </CustomTabPanel>
          ))}
      </div>
    </div>
  );
};
