"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.baseUrl = exports.ENDPOINTS = exports.CacheEndpoints = exports.Endpoints = void 0;
const constants_1 = require("./constants");
// constants.js
exports.Endpoints = {
    HOME_API_ENDPOINT: 'api/home-api',
    MASTER_API_ENDPOINT: 'api/home/master',
    FEATURED_CONTENT_ENDPOINT: 'api/home/featured-content',
    CONTINUE_WHERE_LEFT_ENDPOINT: 'api/home/continue-where-you-left-content',
    RECOMMENED_FOR_YOU_ENDPOINT: 'api/home/recommended-for-you-content',
    TRENDING_NOW_ENDPOINT: 'api/home/trending-content',
    NEWLY_ADDED_ENDPOINT: 'api/home/recent-content',
    LIST_CONTENT_ENDPOINT: 'api/content',
    FETCH_LIST_BY_TYPE_ENDPOINT: 'api/content-list',
    CONTENT_DETAILS_ENDPOINT: 'api/content',
    LIST_COMMUNITY: 'api/my-communities',
    GET_COMMUNITY_REQUESTS_ENDPOINT: 'api/communities/requests',
};
exports.CacheEndpoints = {
    HOME_API_ENDPOINT: 'api/home/home-api',
    MASTER_API_ENDPOINT: 'api/home/master',
    FEATURED_CONTENT_ENDPOINT: 'api/home/featured-content',
    CONTINUE_WHERE_LEFT_ENDPOINT: 'api/home/continue-where-you-left-content',
    RECOMMENED_FOR_YOU_ENDPOINT: 'api/home/recommended-for-you-content',
    TRENDING_NOW_ENDPOINT: 'api/home/trending-content',
    NEWLY_ADDED_ENDPOINT: 'api/home/recent-content',
    LIST_CONTENT_ENDPOINT: 'api/content',
    FETCH_LIST_BY_TYPE_ENDPOINT: 'api/content-list',
    CONTENT_DETAILS_ENDPOINT: 'api/content',
    LIST_COMMUNITY: 'api/my-communities',
    GET_COMMUNITY_REQUESTS_ENDPOINT: 'api/communities/requests',
};
let ENDPOINTS = '';
exports.ENDPOINTS = ENDPOINTS;
let baseUrl = '';
exports.baseUrl = baseUrl;
if (constants_1.CACHE_ENABLED) {
    exports.ENDPOINTS = ENDPOINTS = exports.CacheEndpoints;
    exports.baseUrl = baseUrl = constants_1.BASE_URL_NODE;
}
else {
    exports.ENDPOINTS = ENDPOINTS = exports.Endpoints;
    exports.baseUrl = baseUrl = constants_1.BASE_URL;
}
