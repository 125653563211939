import { useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { getFileNameFromUrl, ImageConstant } from '@shalina-app/shared';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import styles from './imageShowDialog.module.scss';
import { ImageShowDialogItemProps } from './imageShowDialog.interface';
import { STORAGE_CONSTANTS, storeData } from '../../utils';

export const ImageShowDialogComponent: React.FC<ImageShowDialogItemProps> = ({
  imageUrl,
  showVideoPlayer,
  lastVideoPlayTime,
  onClose
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [videoPlaybackTime, setVideoPlaybackTime] = useState(
    lastVideoPlayTime ? lastVideoPlayTime : 0
  );
  const [isReady, setIsReady] = useState(false);
  const playerRef = useRef<any>(null);

  const handleClose = () => {
    setIsOpen(false);
    onClose(videoPlaybackTime);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} className={styles.customDialog}>
      <DialogContent>
        <div className={styles.dialogContent}>
          <div className={styles.modalHeader}>
            <span className={styles.fileName}>
              {getFileNameFromUrl(imageUrl)}
            </span>
            <button className={styles.closeCta} onClick={handleClose}>
              &times;
            </button>
          </div>
          {showVideoPlayer ? (
            <ReactPlayer
              ref={playerRef}
              url={imageUrl}
              controls
              playing={true}
              onProgress={(progress) => {
                setVideoPlaybackTime(progress?.playedSeconds);
                storeData(
                  STORAGE_CONSTANTS.VIDEO_TIME,
                  progress?.playedSeconds
                );
                // saveVideoTimeStamp(false);
              }}
              onDuration={(time) => {
                storeData(STORAGE_CONSTANTS.TOTAL_VIDEO_TIME, time);
              }}
              onPause={() => {
                // saveVideoTimeStamp(false);
                // setWatchingFullVideo(false);
              }}
              onReady={() => {
                if (!isReady) {
                  if (playerRef?.current && videoPlaybackTime) {
                    playerRef?.current?.seekTo(videoPlaybackTime);
                  }
                  setIsReady(true);
                }
              }}
              height={'auto'}
              width={'auto'}
              // onEnded={() => {
              //   saveVideoTimeStamp(true);
              // }}
            />
          ) : (
            <img
              src={imageUrl}
              alt='Custom Dialog Image'
              className={styles.dialogImage}
              onError={(e) =>
                ((e.target as HTMLInputElement).src =
                  ImageConstant.SOURCE.emptyContent)
              }
            />
          )}
          {/* <ButtonComponent buttonText="Close" onPress={handleClose} /> */}
        </div>
      </DialogContent>
    </Dialog>
  );
};
