import { useEffect, useState } from 'react';
import {
  AppConstant,
  ImageConstant,
  listExpert,
  translate,
  useAppDispatch,
  useAppSelector
} from '@shalina-app/shared';

import styles from './experts.module.scss';
import { ExpertItemProps, ExpertsProps } from '.';
import { getImageSrc } from '../../../../../utils';
import { ExpertCard } from '../../../..';
import { BarLoader } from '../../../../../components';

export const Experts: React.FC<ExpertsProps> = ({ listExperts }) => {
  const t = translate();
  const dispatch = useAppDispatch();

  const [apiCall, setApiCall] = useState(false);

  const isExpertLoading = useAppSelector(
    (state) => state?.home?.listExpert?.isExpertLoading
  );

  useEffect(() => {
    setApiCall(true);
  }, []);

  useEffect(() => {
    if (apiCall) {
      loadExperts();
    }
  }, [apiCall]);

  const loadExperts = () => {
    dispatch(listExpert());
    setApiCall(false);
  };

  return (
    <>
      {isExpertLoading && (
        <div className={styles.loaderDiv}>
          <BarLoader />
        </div>
      )}
      <div className={styles.expertRow}>
        {listExperts && listExperts?.length > 0 ? (
          listExperts?.map((expert: ExpertItemProps, index: number) => (
            <div className={styles.expertCol}>
              <ExpertCard
                id={expert?.uid}
                name={`${expert?.field_first_name} ${expert?.field_last_name}`}
                speciality={expert?.field_user_specialization}
                qualification={expert?.field_qualification}
                country={expert?.field_country}
                userImg={getImageSrc(expert?.user_picture)}
                designation={expert?.field_designation}
                description={expert?.field_description}
                key={index}
              />
            </div>
          ))
        ) : (
          <div className={styles.contentNotFound}>
            <div className={styles.notFoundImg}>
              <img
                src={ImageConstant.SOURCE.contentNotFoundImg}
                alt='not found'
              />
            </div>
            <div className={styles.notFoundText}>
              <h3>{`${t(AppConstant.HOME_PAGE.NO_TEXT)} ${t(
                AppConstant.HOME_PAGE.DATA_TEXT
              )} ${t(AppConstant.HOME_PAGE.FOUND_TEXT)}`}</h3>
              <p>{t(AppConstant.HOME_PAGE.NO_DATA_FOUND_MESSAGE)}</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
