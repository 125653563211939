import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import {
  AppConstant,
  ImageConstant,
  getAreaOfInterest,
  getFilterCategoriesOptionsValues,
  getSortOptionsValues,
  getSpecialization,
  useAppSelector,
  translate,
  SORT_FILTER_BY_TABS,
  FILTER_BY_TABS_ENUM,
  getCountries,
  getAllDiscussionTypes
} from '@shalina-app/shared';
import Slider from 'react-slick';

import { SortFilterPropsType } from '.';
import { CheckboxComponent } from '../../checkbox';
import styles from './sortFilter.module.scss';

/* filter by */
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}
/* filter by */
export const SortFilter: React.FC<SortFilterPropsType> = ({
  isCommunity,
  tabsEnum,
  isDark,
  categoryType,
  handleApplyFilter,
  handleSortChange,
  handleAreaOfInterest,
  handleResetFilter,
  isProfilePage,
  handleContentTypeChips,
  resetSortAndFilter,
  noContentType,
  isFilterByHide,
  isSortByHide,
  parentSelectedAreaOfInterest,
  parentCategory,
  filterRetain,
  selectedAreaOfInterest,
  communityModule,
  isSortDisabled
}) => {
  const ref: any = useRef(null);
  const prevPropsRef: any = useRef({ parentCategory: null });

  const areaOfInterestFilterOptions = useAppSelector(getAreaOfInterest);

  const [value, setValue] = useState(0);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<Array<string>>(
    []
  );
  const [selectContentType, setSelectContentType] = useState<Array<string>>([]);
  const [selectSpecialization, setSelectSpecialization] = useState<
    Array<string>
  >([]);
  const [selectAreaOfInterest, setSelectAreaOfInterest] = useState<
    Array<string>
  >([]);
  const [selectContentTypeChips, setSelectContentTypeChips] = useState<
    Array<string>
  >([]);
  const [selectedCommunityCategory, setSelectedCommunityCategory] = useState<
    Array<string>
  >([]);
  const [selectedCommunityCountry, setSelectedCommunityCountry] = useState<
    Array<string>
  >([]);
  const [selectSort, setSelectSort] = useState<string>('');
  const [sortDisabled, setSortDisabled] = useState<boolean>(false);
  const [toggleFlagFilter, setToggleFlagFilter] = useState<boolean>(false);
  const [toggleSort, setToggleSort] = useState<boolean>(false);
  const [toggleAreaOfInterest, setToggleAreaOfInterest] =
    useState<boolean>(false);
  const [toggleContentTypeChips, setToggleContentTypeChips] =
    useState<boolean>(false);
  const [toggleResetFilter, setToggleResetFilter] = useState<boolean>(false);

  const t = translate();

  const ToggleSortDropdown = () => {
    if (!(sortDisabled || isSortDisabled)) {
      setIsActive(!isActive);
    }
  };

  useEffect(() => {
    if (filterRetain && parentSelectedAreaOfInterest) {
      const tempArray = parentSelectedAreaOfInterest?.split(',');
      setSelectAreaOfInterest(tempArray);
    } else {
      setSelectAreaOfInterest([]);
    }
  }, [parentSelectedAreaOfInterest]);

  useEffect(() => {
    if (selectedAreaOfInterest) {
      // const tempArray = selectedAreaOfInterest?.split(",");
      setSelectAreaOfInterest(selectedAreaOfInterest);
      // handleAreaOfInterest!(selectedAreaOfInterest)
    }
  }, [selectedAreaOfInterest]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref?.current && !ref?.current?.contains(event?.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (toggleFlagFilter) {
      handleApplyFilter!(
        selectContentType,
        selectSpecialization,
        selectedCommunityCategory,
        selectedCommunityCountry
      );
      setToggleFlagFilter(false);
    }
  }, [toggleFlagFilter]);

  useEffect(() => {
    if (toggleSort) {
      handleSortChange!(selectSort);
      setToggleSort(false);
      setIsActive(false);
    }
  }, [selectSort]);

  useEffect(() => {
    if (resetSortAndFilter) {
      setSelectedCheckboxes([]);
      setSelectContentType([]);
      setSelectedCommunityCategory([]);
      setSelectedCommunityCountry([]);
      setSelectSpecialization([]);
      setSelectSort('');
      setSelectAreaOfInterest([]);
    }
  }, [resetSortAndFilter]);

  useEffect(() => {
    if (toggleResetFilter) {
      setSelectedCheckboxes([]);
      setSelectContentType([]);
      setSelectedCommunityCategory([]);
      setSelectedCommunityCountry([]);
      setSelectSpecialization([]);
      setToggleFlagFilter(false);
      handleResetFilter!();
      setToggleResetFilter(false);
    }
  }, [toggleResetFilter]);

  useEffect(() => {
    if (toggleAreaOfInterest) {
      handleAreaOfInterest!(selectAreaOfInterest);
      setToggleAreaOfInterest(false);
    }
  }, [selectAreaOfInterest]);

  useEffect(() => {
    if (toggleContentTypeChips) {
      handleContentTypeChips!(selectContentTypeChips);
      setToggleContentTypeChips(false);
    }
  }, [selectContentTypeChips]);

  const sortOptions = useAppSelector(
    getSortOptionsValues(isCommunity, communityModule)
  );

  const categories = useAppSelector(getFilterCategoriesOptionsValues);
  const filterCategoriesOptions =
    categoryType && categories?.length
      ? categories.filter(
          (itemRecord: {
            value: string;
            label: string;
            category: string;
            icon: string;
          }) => itemRecord.category === categoryType
        )
      : categories;
  const specialization = useAppSelector(getSpecialization);
  const specilizationFilterOptions = specialization?.map(
    (value: { id: string; label: string }) => {
      return { value: value.id, label: value.label };
    }
  );
  const countryOfPracticeList = useAppSelector(getCountries);
  const discussionTypes = useAppSelector(getAllDiscussionTypes);

  const allDiscussionTypes = discussionTypes?.map(
    (value: { field_unique_code: string; label: string }) => {
      return { value: value.field_unique_code, label: value.label };
    }
  );

  useEffect(() => {
    if (sortOptions && sortOptions?.length === 0 && sortDisabled === false) {
      setSortDisabled(true);
    }
  }, [sortOptions]);

  const handleSort = (event: React.SyntheticEvent, value: string) => {
    setSelectSort(value);
    setToggleSort(true);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [open, setFilterOpen] = React.useState(false);
  const handleFilterOpen = () => {
    setFilterOpen(true);
  };

  const handleFilterClose = () => {
    setFilterOpen(false);
  };

  const handleApplyFilterClick = () => {
    setFilterOpen(false);
    setToggleFlagFilter(true);
  };

  const resetFilter = () => {
    setToggleResetFilter(true);
    setFilterOpen(false);
  };

  const handleCheckboxChange = (
    labelText: string,
    isChecked: boolean,
    tabId: number
  ) => {
    if (tabId === SORT_FILTER_BY_TABS.CONTENT_TYPE) {
      setSelectContentType((prevCheckboxes) => {
        if (isChecked) {
          return [...prevCheckboxes, labelText];
        } else {
          return prevCheckboxes.filter((label) => label !== labelText);
        }
      });
    }
    if (tabId === SORT_FILTER_BY_TABS.SPECIALITY) {
      setSelectSpecialization((prevCheckboxes) => {
        if (isChecked) {
          return [...prevCheckboxes, labelText];
        } else {
          return prevCheckboxes.filter((label) => label !== labelText);
        }
      });
    }

    if (tabId === SORT_FILTER_BY_TABS.COMMUNITY_COUNTRY) {
      setSelectedCommunityCountry((prevCheckboxes) => {
        if (isChecked) {
          return [...prevCheckboxes, labelText];
        } else {
          return prevCheckboxes.filter((label) => label !== labelText);
        }
      });
    }
    if (tabId === SORT_FILTER_BY_TABS.COMMUNITY_CATEGORY) {
      setSelectedCommunityCategory((prevCheckboxes) => {
        if (isChecked) {
          return [...prevCheckboxes, labelText];
        } else {
          return prevCheckboxes.filter((label) => label !== labelText);
        }
      });
    }
    setSelectedCheckboxes((prevCheckboxes) => {
      if (isChecked) {
        return [...prevCheckboxes, labelText];
      } else {
        return prevCheckboxes.filter((label) => label !== labelText);
      }
    });
  };

  const onClickAreaOfInterest = (value: any) => {
    if (value) {
      if (selectAreaOfInterest.includes(value)) {
        setSelectAreaOfInterest((prevSelected) =>
          prevSelected.filter((id) => id !== value)
        );
      } else {
        setSelectAreaOfInterest((prevCheckboxes) => {
          return [...prevCheckboxes, value];
        });
      }
      setToggleAreaOfInterest(true);
    }
  };

  const onClickOfContentTypeChips = (value: any) => {
    if (value) {
      if (selectContentTypeChips.includes(value)) {
        setSelectContentTypeChips((prevSelected) =>
          prevSelected.filter((id) => id !== value)
        );
      } else {
        setSelectContentTypeChips((prevCheckboxes) => {
          return [...prevCheckboxes, value];
        });
      }
      setToggleContentTypeChips(true);
    }
  };

  const handleSortReset = () => {
    setSelectSort('');
    setToggleSort(true);
  };

  const defaultTabs = [
    {
      id: SORT_FILTER_BY_TABS.CONTENT_TYPE,
      enumId: FILTER_BY_TABS_ENUM.CONTENT_TYPE,
      name: t(AppConstant.HOME_PAGE.CONTENT_TYPE),
      values: filterCategoriesOptions
    },
    {
      id: SORT_FILTER_BY_TABS.SPECIALITY,
      enumId: FILTER_BY_TABS_ENUM.SPECIALITY,
      name: t(AppConstant.AUTHENTICATION_PAGE.SPECIALTY),
      values: specilizationFilterOptions
    },
    {
      id: SORT_FILTER_BY_TABS.COMMUNITY_CATEGORY,
      enumId: FILTER_BY_TABS_ENUM.COMMUNITY_CATEGORY,
      name: t(AppConstant.COMMUNITY.COMMUNITY_FILTER_CATEGORY_TAB),
      values: allDiscussionTypes
    },
    {
      id: SORT_FILTER_BY_TABS.COMMUNITY_COUNTRY,
      enumId: FILTER_BY_TABS_ENUM.COMMUNITY_COUNTRY,
      name: t(AppConstant.COMMUNITY.COMMUNITY_FILTER_COUNTRY_TAB),
      values: countryOfPracticeList
    }
  ];

  const { FilterIcon, SortIcon } = ImageConstant.SVG_ICONS;

  const tabsArr = tabsEnum?.length
    ? defaultTabs?.filter((item: any) => tabsEnum?.includes(item?.enumId))
    : defaultTabs;

  const filterTabs = noContentType
    ? tabsArr?.filter(
        (item: any) => item.enumId !== FILTER_BY_TABS_ENUM.CONTENT_TYPE
      )
    : tabsArr;

  const tabs = filterTabs.sort((a: any, b: any) => {
    return tabsEnum?.indexOf(a?.enumId) - tabsEnum?.indexOf(b?.enumId);
  });

  const sliderRef: any = useRef(null);
  const sliderSettings = {
    infinite: true,
    variableWidth: true, // slidesToShow: 4, // slidesToScroll: 7,
    swipeToSlide: true,
    swipe: true,
    arrows: false
  };
  const nextArrowClick = () => {
    sliderRef?.current?.slickNext();
  };
  const previousArrowClick = () => {
    sliderRef?.current?.slickPrev();
  };
  const { SliderArrowIcon } = ImageConstant.SVG_ICONS;
  return (
    <div className={`${styles.filterContainer} ${isDark && styles.hasDark}`}>
      <div className={styles.sortFilter}>
        {isFilterByHide ? (
          <></>
        ) : (
          <div className={styles.filterOption} onClick={handleFilterOpen}>
            <FilterIcon />
            <span className={styles.filterText}>
              {t(AppConstant.HOME_PAGE.FILTER_BY)}
            </span>
          </div>
        )}
        <Dialog
          open={open}
          onClose={handleFilterClose}
          className={styles.FilterByModal}
        >
          <DialogContent>
            <div className={styles.FilterHead}>
              <h4 className={styles.title}>
                {t(AppConstant.HOME_PAGE.FILTER)}
              </h4>
              <span className={styles.closeCTA} onClick={handleFilterClose}>
                &times;
              </span>
            </div>
            <div className={styles.filterTab}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label='Detail Tabs'
                className={styles.custTabs}
              >
                {tabs?.map((tab, index) => (
                  <Tab key={tab.id} label={tab.name} {...a11yProps(index)} />
                ))}
              </Tabs>

              <div className={styles.sortByTabContent}>
                {tabs?.map((tab, tabIndex) => (
                  <CustomTabPanel key={tab.id} value={value} index={tabIndex}>
                    {tab.values?.map((data: any) => (
                      <CheckboxComponent
                        key={data.value} // Make sure to provide a unique key
                        labelText={data.label}
                        defaultChecked={
                          !!selectedCheckboxes.includes(data.value)
                        }
                        isError={false}
                        errorMessage={''}
                        onChange={(labelText: string, isChecked: boolean) =>
                          handleCheckboxChange(data.value, isChecked, tab.id)
                        }
                      />
                    ))}
                  </CustomTabPanel>
                ))}
              </div>
            </div>
            <div className={styles.filterModalCTA}>
              <button className={styles.reserCta} onClick={resetFilter}>
                {t(AppConstant.HOME_PAGE.RESET_FILTER)}
              </button>
              <button onClick={handleApplyFilterClick}>
                {t(AppConstant.HOME_PAGE.APPLY_FILTER)}
              </button>
            </div>
          </DialogContent>
        </Dialog>
        {isSortByHide ? (
          <></>
        ) : (
          <div
            className={`${styles.filterOption} ${styles.sort} ${
              (sortDisabled || isSortDisabled) && styles.disabled
            }`}
            onClick={ToggleSortDropdown}
            ref={ref}
          >
            <SortIcon />
            <span className={styles.filterText}>
              {t(AppConstant.HOME_PAGE.SORT_BY)}
            </span>
            <div
              className={`${styles.sortDropdown} ${
                isActive ? styles.open : ''
              }`}
            >
              <RadioGroup
                aria-labelledby='sort-by'
                defaultValue='Popularity'
                name='sort-by-group'
                onChange={handleSort}
                value={selectSort}
              >
                {sortOptions?.map((option: any) => (
                  <FormControlLabel
                    key={option.key}
                    value={option.key}
                    control={<Radio />}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
              {selectSort && (
                <button className={styles.resetCta} onClick={handleSortReset}>
                  {t(AppConstant.HOME_PAGE.RESET_FILTER)}
                </button>
              )}
            </div>
          </div>
        )}
      </div>
      <div
        className={`${styles.quickFilter} ${
          isFilterByHide ? styles.noFilter : ''
        }`}
      >
        <div className={styles.filterSlider}>
          <Slider ref={sliderRef} {...sliderSettings}>
            {areaOfInterestFilterOptions?.map((option: any) => (
              <label key={option.id} className={styles.filterOptionLabel}>
                <input
                  type='checkbox'
                  onChange={() => onClickAreaOfInterest(option.id)}
                  checked={selectAreaOfInterest?.includes(option.id)}
                />
                <span>{option.label}</span>
              </label>
            ))}
          </Slider>
          <div className={styles.sliderArrow}>
            <button className={styles.prev} onClick={previousArrowClick}>
              <SliderArrowIcon />
            </button>
            <button className={styles.next} onClick={nextArrowClick}>
              <SliderArrowIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
