import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import { AppConstant, ImageConstant, translate } from '@shalina-app/shared';

import { CountryDropdownProps } from './countryDropdown.interface';
import styles from './countryDropdown.module.scss';
import { getImageSrc } from '../../utils';

export const CountryDropdown: React.FC<CountryDropdownProps> = (props) => {
  const t = translate();
  const { DropDownArrowIcon } = ImageConstant.SVG_ICONS;
  const {
    defaultValue,
    dropdownId,
    dropdownVariant,
    dropdownOptions,
    labelText,
    className,
    isError,
    errorMessage,
    isRequired,
    handleDropDownChange,
    value,
    countryFlag
  } = props;

  return (
    <div className={`${styles.shalinaSelect} ${className}`}>
      {labelText ? (
        <label htmlFor={labelText}>
          {labelText} {isRequired && <span>*</span>}
        </label>
      ) : (
        ''
      )}
      <Select
        value={value || defaultValue}
        IconComponent={() => <span className={styles.selectIcon}><DropDownArrowIcon /></span>}
        error={isError}
        fullWidth
        id={dropdownId}
        // label="Grouping"
        variant={dropdownVariant}
        onChange={handleDropDownChange}
        renderValue={(value) => (
          <>
            <div className={styles.countyFlag}>
              <img
                src={
                  countryFlag
                    ? getImageSrc(countryFlag)
                    : ImageConstant.SOURCE.languageImg
                }
              />
              {value}
            </div>
          </>
        )}
      >
        <MenuItem disabled value='' className={styles.selectLabel}>
          <div>{t(AppConstant.COMMON.SELECT_COUNTRY_CODE)}</div>
        </MenuItem>
        {dropdownOptions &&
          dropdownOptions?.map((opt: any) => (
            <MenuItem value={opt.value}>
              <div className={styles.countyFlag}>
                <img
                  src={
                    opt?.icons
                      ? getImageSrc(opt?.icons)
                      : ImageConstant.SOURCE.languageImg
                  }
                />
                {opt?.countryCode}
                <span className={styles.countryName}>{opt?.countryName}</span>
              </div>
            </MenuItem>
          ))}
      </Select>
      {isError && <FormHelperText error>{errorMessage}</FormHelperText>}
    </div>
  );
};
