import { useEffect, useState } from 'react';
import {
  AppConstant,
  CONTENT_TYPE_ID,
  convertUTCDate,
  convertUTCTime,
  getOnlyDate,
  getOnlyTime,
  ImageConstant,
  SLIDE_TO_SHOW_WEB,
  translate,
  viewDurationInHours
} from '@shalina-app/shared';

import { BannerCard, HomeBannerProps } from '.';
import styles from './homeBanner.module.scss';
import { BannerSlider } from '../../../../components';
import { convertToSpecialties } from '../../../../utils';
import { MarketingCardComponent } from './marketingCard.component';

export const HomeBanner: React.FC<HomeBannerProps> = ({
  contentData,
  pageName,
  pageRoute,
  isFeaturedLoading
}) => {
  const t = translate();
  const { BannerBg } = ImageConstant.SVG_ICONS;

  const isDataExist = contentData && contentData?.length > 0 ? true : false;
  const featuredCard =
    contentData?.filter((item: any) =>
      ['', null, undefined]?.includes(item?.featured_sequence)
    ) ?? [];
  const marketingCard =
    contentData?.filter(
      (item: any) => !['', null, undefined]?.includes(item?.featured_sequence)
    ) ?? [];

  const chunks = Array.from(
    { length: Math.ceil(featuredCard?.length / 2) },
    (_, i) =>
      featuredCard?.slice(i * 2, Math.min(i * 2 + 2, featuredCard?.length))
  );

  const data = [...marketingCard, ...chunks];

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768); // You can adjust the threshold according to your needs
    }
    // Initial check
    handleResize();
    // Event listener for window resize
    window.addEventListener('resize', handleResize);
    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const slides = marketingCard?.length + chunks?.length;
  return (
    <div className={styles.homeBanner}>
      <div className={styles.homeSlider}>
        <BannerSlider
          slidesToShow={SLIDE_TO_SHOW_WEB.FEATURED}
          sliderHeading={t(AppConstant.HOME_PAGE.FEATURED_TEXT)}
          isDataExist={isDataExist}
          isFeaturedLoading={isFeaturedLoading}
          dataLength={slides || 0}
        >
          {isMobile
            ? contentData?.map((item, index) =>
                item?.featured_sequence ? (
                  <MarketingCardComponent content={item} isMobile={isMobile} />
                ) : (
                  <BannerCard
                    id={item?.nid}
                    contentType={item?.field_select_article_type || ''}
                    contentTypeId={item?.field_select_article_type_id || ''}
                    title={item?.title || ''}
                    speciality={convertToSpecialties(
                      item?.field_area_of_interest || ''
                    )}
                    publishedName={item?.field_authored_by || ''}
                    date={
                      item?.field_unique_code === CONTENT_TYPE_ID?.WEBINARS
                        ? convertUTCDate(item?.field_starts_on)
                        : getOnlyDate(item?.field_authored_on)
                    }
                    time={
                      item?.field_unique_code === CONTENT_TYPE_ID?.WEBINARS
                        ? convertUTCTime(item?.field_starts_on)
                        : getOnlyTime(item?.field_authored_on)
                    }
                    viewsCount={item?.view_count || ''}
                    duration={viewDurationInHours(item?.field_duration || '')}
                    key={index}
                    thumbnailImage={item?.field_thumbnail_image || ''}
                    mostViewed={item?.most_viewed}
                    isBookmarked={item?.is_bookmarked}
                    uniqueContentTypeId={item?.field_unique_code}
                    eventId={item?.event_id}
                  />
                )
              )
            : data?.map((item: any, index) =>
                item?.featured_sequence ? (
                  <MarketingCardComponent content={item} isMobile={isMobile} />
                ) : (
                  <div key={index} className={styles.featureTwoCard}>
                    {item?.map((content: any, index: any) => (
                      <BannerCard
                        id={content?.nid}
                        contentType={content?.field_select_article_type || ''}
                        contentTypeId={
                          content?.field_select_article_type_id || ''
                        }
                        title={content?.title || ''}
                        speciality={convertToSpecialties(
                          content?.field_area_of_interest || ''
                        )}
                        publishedName={content?.field_authored_by || ''}
                        date={
                          content?.field_unique_code ===
                          CONTENT_TYPE_ID?.WEBINARS
                            ? convertUTCDate(content?.field_starts_on)
                            : getOnlyDate(content?.field_authored_on)
                        }
                        time={
                          content?.field_unique_code ===
                          CONTENT_TYPE_ID?.WEBINARS
                            ? convertUTCTime(content?.field_starts_on)
                            : getOnlyTime(content?.field_authored_on)
                        }
                        viewsCount={content?.view_count || ''}
                        duration={viewDurationInHours(
                          content?.field_duration || ''
                        )}
                        key={index}
                        thumbnailImage={content?.field_thumbnail_image || ''}
                        mostViewed={content?.most_viewed}
                        isBookmarked={content?.is_bookmarked}
                        uniqueContentTypeId={content?.field_unique_code}
                        eventId={content?.event_id}
                      />
                    ))}
                  </div>
                )
              )}
        </BannerSlider>
      </div>
      <span className={styles.bannerBg}>
        <BannerBg />
      </span>
    </div>
  );
};
