"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.listUserGamificationPointData = exports.getAchievedRecognitionBadges = exports.submitRedeemPoints = exports.recognitionHideVisibility = exports.updateUserRecognition = exports.fetchUserRecognitionBadges = exports.addOrUpdateUserGamificationPoints = exports.fetchUserGamificationPointHistory = exports.fetchGamificationPointDistribution = void 0;
const constants_1 = require("../../constants");
const toolkit_1 = require("@reduxjs/toolkit");
const api_1 = require("../../../api");
const utils_1 = require("../../../utils");
const initialState = {
    isLoading: false,
    gamificationPointDistribution: {
        message: '',
        status: '',
        statusCode: 200,
        data: [],
    },
    userGamificationPointHistory: {
        message: '',
        status: '',
        statusCode: 200,
        data: {
            count: '0',
            points: '0',
            accumulated: [],
        },
    },
    userRecognitionBadges: {
        isUserRecognitionBadgesLoading: false,
        data: {},
    },
    updateUserRecognition: {
        updateUserRecognitionLoading: false,
        userRecognitionData: {},
    },
    recognitionHideVisibility: {
        recognitionHideVisibilityLoading: false,
        recognitionHideVisibilityData: {},
    },
    submitRedeemPoints: {
        submitRedeemPointsLoading: false,
        submitRedeemPointsData: {},
    },
};
const gamificationSlice = (0, toolkit_1.createSlice)({
    name: constants_1.GAMIFICATION,
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(exports.fetchGamificationPointDistribution.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.isLoading = true;
        });
        builder.addCase(exports.fetchGamificationPointDistribution.fulfilled, (state, action) => {
            // Add logic if any when API state is fulfilled or data is received from API
            state.isLoading = false;
            // Add any fetched posts to the array
            state.gamificationPointDistribution = action === null || action === void 0 ? void 0 : action.payload;
        });
        builder.addCase(exports.fetchGamificationPointDistribution.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.isLoading = false;
        });
        builder.addCase(exports.fetchUserGamificationPointHistory.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.isLoading = true;
        });
        builder.addCase(exports.fetchUserGamificationPointHistory.fulfilled, (state, action) => {
            // Add logic if any when API state is fulfilled or data is received from API
            state.isLoading = false;
            // Add any fetched posts to the array
            state.userGamificationPointHistory = action === null || action === void 0 ? void 0 : action.payload;
        });
        builder.addCase(exports.fetchUserGamificationPointHistory.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.isLoading = false;
        });
        builder.addCase(exports.addOrUpdateUserGamificationPoints.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.isLoading = true;
        });
        builder.addCase(exports.addOrUpdateUserGamificationPoints.fulfilled, (state, action) => {
            // Add logic if any when API state is fulfilled or data is received from API
            state.isLoading = false;
            // Add any fetched posts to the array
        });
        builder.addCase(exports.addOrUpdateUserGamificationPoints.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.isLoading = false;
        });
        builder.addCase(exports.fetchUserRecognitionBadges.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.userRecognitionBadges.isUserRecognitionBadgesLoading = true;
        });
        builder.addCase(exports.fetchUserRecognitionBadges.fulfilled, (state, action) => {
            // Add logic if any when API state is fulfilled or data is received from API
            state.userRecognitionBadges.isUserRecognitionBadgesLoading = false;
            state.userRecognitionBadges.data = action.payload;
        });
        builder.addCase(exports.fetchUserRecognitionBadges.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.userRecognitionBadges.isUserRecognitionBadgesLoading = false;
        });
        builder.addCase(exports.updateUserRecognition.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.updateUserRecognition.updateUserRecognitionLoading = true;
        });
        builder.addCase(exports.updateUserRecognition.fulfilled, (state, action) => {
            // Add logic if any when API state is fulfilled or data is received from API
            state.updateUserRecognition.updateUserRecognitionLoading = false;
            state.updateUserRecognition.userRecognitionData = action.payload;
        });
        builder.addCase(exports.updateUserRecognition.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.updateUserRecognition.updateUserRecognitionLoading = false;
        });
        builder.addCase(exports.recognitionHideVisibility.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.recognitionHideVisibility.recognitionHideVisibilityLoading = true;
        });
        builder.addCase(exports.recognitionHideVisibility.fulfilled, (state, action) => {
            // Add logic if any when API state is fulfilled or data is received from API
            state.recognitionHideVisibility.recognitionHideVisibilityLoading = false;
            state.recognitionHideVisibility.recognitionHideVisibilityData =
                action.payload;
        });
        builder.addCase(exports.recognitionHideVisibility.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.recognitionHideVisibility.recognitionHideVisibilityLoading = false;
        });
        builder.addCase(exports.submitRedeemPoints.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.submitRedeemPoints.submitRedeemPointsLoading = true;
        });
        builder.addCase(exports.submitRedeemPoints.fulfilled, (state, action) => {
            // Add logic if any when API state is fulfilled or data is received from API
            state.submitRedeemPoints.submitRedeemPointsLoading = false;
            state.submitRedeemPoints.submitRedeemPointsData = action.payload;
        });
        builder.addCase(exports.submitRedeemPoints.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.submitRedeemPoints.submitRedeemPointsLoading = false;
        });
    },
});
exports.fetchGamificationPointDistribution = (0, toolkit_1.createAsyncThunk)(`${constants_1.GAMIFICATION}/${constants_1.GAMIFICATION_POINT_DISTRIBUTION}`, () => __awaiter(void 0, void 0, void 0, function* () {
    const masterData = yield (0, api_1.get)(`${constants_1.GAMIFICATION_POINT_DISTRIBUTION_ENDPOINT}`);
    return masterData;
}));
exports.fetchUserGamificationPointHistory = (0, toolkit_1.createAsyncThunk)(`${constants_1.GAMIFICATION}/${constants_1.USER_GAMIFICATION_POINT_HISTORY}`, (params) => __awaiter(void 0, void 0, void 0, function* () {
    const masterData = yield (0, api_1.get)(`${constants_1.USER_GAMIFICATION_POINT_HISTORY_ENDPOINT}?skip=${(params === null || params === void 0 ? void 0 : params.skip) ? params.skip : 0}&take=${(params === null || params === void 0 ? void 0 : params.take) ? params.take : utils_1.DEFAULT_MAX_CARDS_TO_SHOW_FOR_VIEW_ALL}&startDate=${params.startDate ? params.startDate : ''}&endDate=${params.endDate ? params.endDate : ''}`, {
        baseURL: api_1.BASE_URL_NODE,
    });
    return masterData;
}));
exports.addOrUpdateUserGamificationPoints = (0, toolkit_1.createAsyncThunk)(`${constants_1.GAMIFICATION}/${constants_1.ADD_GAMIFICATION_POINT}`, (req) => __awaiter(void 0, void 0, void 0, function* () {
    const masterData = yield (0, api_1.post)(`${constants_1.ADD_GAMIFICATION_ENDPOINT}`, req, {
        baseURL: api_1.BASE_URL_NODE,
    });
    return masterData;
}));
exports.fetchUserRecognitionBadges = (0, toolkit_1.createAsyncThunk)(`${constants_1.GAMIFICATION}/${constants_1.USER_RECOGNITION_BADGES}`, () => __awaiter(void 0, void 0, void 0, function* () {
    const recognitionBadgesList = yield (0, api_1.get)(`${api_1.BASE_URL_NODE}${constants_1.GET_USER_RECOGNITION_BADGES_ENDPOINT}`);
    return recognitionBadgesList;
}));
exports.updateUserRecognition = (0, toolkit_1.createAsyncThunk)(`${constants_1.GAMIFICATION}/${constants_1.UPDATE_USER_RECOGNITION}`, (req) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield (0, api_1.post)(constants_1.UPDATE_USER_RECOGNITION_ENDPOINT, req, {
        baseURL: api_1.BASE_URL_NODE,
    });
    return response;
}));
exports.recognitionHideVisibility = (0, toolkit_1.createAsyncThunk)(`${constants_1.GAMIFICATION}/${constants_1.RECOGNITION_HIDE_VISIBILITY}`, (req) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield (0, api_1.put)(`${constants_1.RECOGNITION_HIDE_VISIBILITY_ENDPOINT}/${req === null || req === void 0 ? void 0 : req.recognitionId}`, {}, {
        baseURL: api_1.BASE_URL_NODE,
    });
    return response;
}));
exports.submitRedeemPoints = (0, toolkit_1.createAsyncThunk)(`${constants_1.GAMIFICATION}/${constants_1.SUBMIT_REDEEM_POINTS}`, (req) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield (0, api_1.post)(constants_1.SUBMIT_REDEEM_POINTS_ENDPOINT, req, {
        baseURL: api_1.BASE_URL_NODE,
    });
    return response;
}));
exports.getAchievedRecognitionBadges = (0, toolkit_1.createSelector)((state) => state.gamification, (gamification) => {
    var _a, _b;
    const data = (_b = (_a = gamification === null || gamification === void 0 ? void 0 : gamification.userRecognitionBadges) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.data;
    const seenIds = new Set();
    const uniqueArray = [];
    if (data && (data === null || data === void 0 ? void 0 : data.length) > 0) {
        for (const item of data) {
            if (!(seenIds === null || seenIds === void 0 ? void 0 : seenIds.has(item === null || item === void 0 ? void 0 : item.recognitionMatrixId))) {
                seenIds === null || seenIds === void 0 ? void 0 : seenIds.add(item === null || item === void 0 ? void 0 : item.recognitionMatrixId);
                uniqueArray === null || uniqueArray === void 0 ? void 0 : uniqueArray.push(item);
            }
        }
    }
    return uniqueArray;
});
exports.listUserGamificationPointData = (0, toolkit_1.createSelector)((state) => state.gamification, (gamification) => { var _a; return (_a = gamification === null || gamification === void 0 ? void 0 : gamification.userGamificationPointHistory) === null || _a === void 0 ? void 0 : _a.data; });
exports.default = gamificationSlice.reducer;
