import { ImageConstant } from '@shalina-app/shared';

import styles from './whoWrAre.module.scss';

export const WhoWeAre: React.FC<any> = () => {
  return (
    <>
      <div className={styles.whoWeAre}>
        <h2 className={styles.title}>About Shalina MedSpace</h2>
        <div className={styles.weAreInfo}>
          <div className={styles.weAreImg}>
            <img src={ImageConstant.SOURCE.aboutImg} alt='who we are' />
          </div>
          <div className={styles.infoText}>
            <p>
              Shalina MedSpace is the one-stop medical education platform
              available exclusively to healthcare professionals. It aims to
              shape the future of healthcare in Africa by cultivating an
              environment that encourages practical learning, knowledge
              building, and practice enhancement.
            </p>
            <p>
              Our platform is meticulously crafted to empower healthcare
              professionals, providing them with indispensable resources and
              tools essential at the point of care. This empowerment is achieved
              through a rich content library, the latest global medical news,
              and active community forums where doctors can engage with experts
              in their respective specialties, effectively bridging the gap
              between theory and practice, and thereby positively impacting
              global healthcare.
            </p>
          </div>
        </div>
        <div className={styles.whyShalina}>
          <h2 className={styles.title}>Why Shalina MedSpace?</h2>

          <div className={styles.whyShalinaSec}>
            <div className={styles.whyShalinacontent}>
              <h4 className={styles.subTitle}>Practical Learning</h4>
              <p>
                Access a rich repository of full text, indexed medical journals,
                Global and African, across 23 therapeutic areas, available in
                real time. Read from over 3000 hand-selected, e-books from
                leading publishers and organizations across the globe. What’s
                more? Gather practical tips that help in real-world practice,
                through expert-written content.{' '}
              </p>
            </div>
            <div className={styles.whyShalinaImg}>
              <img
                src={ImageConstant.SOURCE.Practical_Learning}
                alt='Practical Learning'
              />
            </div>
          </div>
          <div className={styles.whyShalinaSec}>
            <div className={styles.whyShalinacontent}>
              <h4 className={styles.subTitle}>Knowledge Building</h4>
              <p>
                Engage in live webinars to stay abreast of the latest
                advancements, evidence-based and evolving clinical practices in
                your specialty. Read and contribute to building a bank of
                interesting content. Enhance your understanding of various
                diseases and conditions, and reinforce your grasp on essential
                medical practices through a vast collection of medical videos.
              </p>
            </div>
            <div className={styles.whyShalinaImg}>
              <img
                src={ImageConstant.SOURCE.Knowledge_Building}
                alt='Knowledge Building'
              />
            </div>
          </div>
          <div className={styles.whyShalinaSec}>
            <div className={styles.whyShalinacontent}>
              <h4 className={styles.subTitle}>Practice Enhancement</h4>
              <p>
                Make learning interactive and fun through spirited discussions
                on our community forums. Analyse test results and quickly
                interpret clinical data with integrated medical calculators.
                Read medical news that directly affect your practice from global
                news providers.{' '}
              </p>
            </div>
            <div className={styles.whyShalinaImg}>
              <img
                src={ImageConstant.SOURCE.Practice_Enhancement}
                alt='Practice Enhancement'
              />
            </div>
          </div>
        </div>

        {/* <div className={styles.ourMission}>
                    <h2 className={styles.title}>Our Mission</h2>
                    <p className={styles.infoText}>
                        Empowering Healthcare Professionals through Knowledge and Innovation. Our mission is to provide a cutting-edge medical learning platform.
                    </p>
                    <div className={styles.missionCardRow}>
                        <div className={styles.missionCardWrap}>
                            <div className={styles.missionCard}>
                                <img className={styles.omImg} src={ImageConstant.SOURCE.manImg} alt="Empowerment" />
                                <h4 className={styles.title}>Empowerment</h4>
                                <p className={styles.desc}>
                                    Empowering healthcare professionals by providing them with the knowledge and tools they need to excel in their careers.
                                </p>
                            </div>
                        </div>
                        <div className={styles.missionCardWrap}>
                            <div className={styles.missionCard}>
                                <img className={styles.omImg} src={ImageConstant.SOURCE.aeroplaneImg} alt="Improvement" />
                                <h4 className={styles.title}>Improvement</h4>
                                <p className={styles.desc}>
                                    Striving for continuous improvement in the healthcare sector by promoting ongoing professional development.
                                </p>
                            </div>
                        </div>
                        <div className={styles.missionCardWrap}>
                            <div className={styles.missionCard}>
                                <img className={styles.omImg} src={ImageConstant.SOURCE.headImg} alt="Innovation" />
                                <h4 className={styles.title}>Innovation</h4>
                                <p className={styles.desc}>
                                    Fostering innovation in medical education and practice to stay at the forefront of healthcare advancements.
                                </p>
                            </div>
                        </div>
                        <div className={styles.missionCardWrap}>
                            <div className={styles.missionCard}>
                                <img className={styles.omImg} src={ImageConstant.SOURCE.brainImg} alt="Collaboration" />
                                <h4 className={styles.title}>Collaboration</h4>
                                <p className={styles.desc}>
                                Facilitating collaboration and knowledge sharing among healthcare
                                </p>
                            </div>
                        </div>
                    </div>
                </div> */}
        {/* <div className={styles.coreValues}>
                    <h2 className={styles.title}>Core Values</h2>
                    <p className={styles.infoText}>
                        Our Core Values reflect what is truly important to us as individuals and as a company. They guide and inform everything we do and are underpin our company culture. 
                    </p>
                    <div className={styles.coreValueTab}>
                        <div className={styles.shalinaTabs}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="Detail Tabs"
                                className={styles.custTabs}
                            >
                                <Tab label="Excellence " {...a11yProps(0)} />
                                <Tab label="Integrity" {...a11yProps(1)} />
                                <Tab label="Impact" {...a11yProps(2)} />
                            </Tabs>
                            <div className={styles.custTabContent}>
                                <CustomTabPanel value={value} index={0}>
                                    Our Core Values reflect what is truly important to us as individuals and as a company. They guide and inform everything we do and are underpin our company culture. 
                                </CustomTabPanel>
                                <CustomTabPanel value={value} index={1}>Integrity</CustomTabPanel>
                                <CustomTabPanel value={value} index={2}>Impact</CustomTabPanel>
                            </div>
                        </div>
                    </div>
                </div> */}
      </div>
    </>
  );
};
