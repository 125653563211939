"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateInitialSurveyList = exports.updateRecentSurveyList = exports.getInitialPrefetchedSurveyList = exports.getSurveysForHome = exports.getOnLoadSurveyList = exports.getRecentSurvey = exports.getRecentSurveyList = exports.getQuizDetails = exports.getDynamicSurveyDetails = exports.getDynamicSurveyList = exports.submitQuizResponse = exports.quizDetails = exports.surveyDetails = exports.fetchOnLoadSurvey = exports.fetchRecentSurvey = exports.fetchDynamicListSurvey = void 0;
const api_1 = require("../../../api");
const toolkit_1 = require("@reduxjs/toolkit");
const constants_1 = require("../../constants");
const initialState = {
    isLoading: false,
    dynamicSurveylistContent: {
        isError: false,
        isDynamicSurveyListLoading: false,
        onloadSurveyList: {},
        DynamicSurveyList: {},
        initialSurveyList: {},
    },
    recentSurveyList: {
        recentSurveyLoading: false,
        recentSurveyList: {}
    },
    surveyDetails: {
        surveyDetailsData: {},
        isError: false,
        isSurveyDetailsLoading: false,
    },
    quizDetails: {
        quizDetailsData: {},
        isError: false,
        isQuizDetailsLoading: false,
    },
    recentSurvey: {
        isError: false,
        isRecentSurveyListLoading: false,
        RecentSurveyList: {},
    },
};
const surveySlice = (0, toolkit_1.createSlice)({
    name: constants_1.SURVEY,
    initialState,
    reducers: {
        updateRecentSurveyList: (state, action) => {
            var _a;
            state.recentSurvey.RecentSurveyList = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data;
        },
        updateInitialSurveyList: (state, action) => {
            var _a;
            state.dynamicSurveylistContent.initialSurveyList = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data;
        },
    },
    extraReducers(builder) {
        /**
         * List of Dynamic Survey List API
         */
        builder.addCase(exports.fetchDynamicListSurvey.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.isLoading = true;
            state.dynamicSurveylistContent.isDynamicSurveyListLoading = true;
        });
        builder.addCase(exports.fetchDynamicListSurvey.fulfilled, (state, action) => {
            var _a;
            // Add logic if any when API state is fulfilled or data is received from API
            state.isLoading = false;
            state.dynamicSurveylistContent.isDynamicSurveyListLoading = false;
            const surveyDynamicList = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data;
            state.dynamicSurveylistContent.DynamicSurveyList = surveyDynamicList;
        });
        builder.addCase(exports.fetchDynamicListSurvey.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.isLoading = false;
            state.dynamicSurveylistContent.isDynamicSurveyListLoading = false;
        });
        builder.addCase(exports.fetchRecentSurvey.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.recentSurveyList.recentSurveyLoading = true;
        });
        builder.addCase(exports.fetchRecentSurvey.fulfilled, (state, action) => {
            // Add logic if any when API state is fulfilled or data is received from API
            state.recentSurveyList.recentSurveyLoading = false;
            state.recentSurveyList.recentSurveyList = action.payload;
        });
        builder.addCase(exports.fetchRecentSurvey.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.recentSurveyList.recentSurveyLoading = false;
        });
        builder.addCase(exports.fetchOnLoadSurvey.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.dynamicSurveylistContent.isDynamicSurveyListLoading = true;
        });
        builder.addCase(exports.fetchOnLoadSurvey.fulfilled, (state, action) => {
            // Add logic if any when API state is fulfilled or data is received from API
            state.dynamicSurveylistContent.isDynamicSurveyListLoading = false;
            state.dynamicSurveylistContent.onloadSurveyList = action.payload;
        });
        builder.addCase(exports.fetchOnLoadSurvey.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.dynamicSurveylistContent.isDynamicSurveyListLoading = false;
        });
        /**
         * Dynamic Survey Details API
         */
        builder.addCase(exports.surveyDetails.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.isLoading = true;
            state.surveyDetails.isSurveyDetailsLoading = true;
        });
        builder.addCase(exports.surveyDetails.fulfilled, (state, action) => {
            // Add logic if any when API state is fulfilled or data is received from API
            state.isLoading = false;
            state.surveyDetails.isSurveyDetailsLoading = false;
            // const contentDetailsResponse = action?.payload?.data;
            state.surveyDetails.surveyDetailsData = action === null || action === void 0 ? void 0 : action.payload;
        });
        builder.addCase(exports.surveyDetails.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.isLoading = false;
            state.surveyDetails.isSurveyDetailsLoading = false;
        });
        /**
         * Quiz Details API
         */
        builder.addCase(exports.quizDetails.pending, (state, action) => {
            // Add logic if any when API state is loading/pending
            state.isLoading = true;
            state.quizDetails.isQuizDetailsLoading = true;
        });
        builder.addCase(exports.quizDetails.fulfilled, (state, action) => {
            // Add logic if any when API state is fulfilled or data is received from API
            state.isLoading = false;
            state.quizDetails.isQuizDetailsLoading = false;
            // const contentDetailsResponse = action?.payload?.data;
            state.quizDetails.quizDetailsData = action === null || action === void 0 ? void 0 : action.payload;
        });
        builder.addCase(exports.quizDetails.rejected, (state, action) => {
            // Add logic if any when API state is rejected or data is not received from API
            state.isLoading = false;
            state.quizDetails.isQuizDetailsLoading = false;
        });
    },
});
exports.fetchDynamicListSurvey = (0, toolkit_1.createAsyncThunk)(`${constants_1.SURVEY}/${constants_1.DYNAMIC_LIST_SURVEY}`, (payload) => __awaiter(void 0, void 0, void 0, function* () {
    //Dynamic list
    const dynamicListOfSurvey = yield (0, api_1.get)(`${constants_1.DYNAMIC_LIST_SURVEY_ENDPOINT}?recent=false&skip=${payload.skip}&take=${payload.take}&speciality=${payload.speciality}&interest=${payload.interest}`);
    return dynamicListOfSurvey;
}));
exports.fetchRecentSurvey = (0, toolkit_1.createAsyncThunk)(`${constants_1.SURVEY}/${constants_1.FETCH_RECENT_SURVEY}`, (payload) => __awaiter(void 0, void 0, void 0, function* () {
    //Dynamic list
    const dynamicListOfSurvey = yield (0, api_1.get)(`${constants_1.DYNAMIC_LIST_SURVEY_ENDPOINT}?recent=${(payload === null || payload === void 0 ? void 0 : payload.recent) ? payload === null || payload === void 0 ? void 0 : payload.recent : false}&skip=${payload.skip}&take=${payload.take}`);
    return dynamicListOfSurvey;
}));
exports.fetchOnLoadSurvey = (0, toolkit_1.createAsyncThunk)(`${constants_1.SURVEY}/${constants_1.FETCH_LOAD_SURVEY}`, (payload) => __awaiter(void 0, void 0, void 0, function* () {
    //Dynamic list
    const dynamicListOfSurvey = yield (0, api_1.get)(`${constants_1.DYNAMIC_LIST_SURVEY_ENDPOINT}?recent=false&skip=${payload.skip}&take=${payload.take}`);
    return dynamicListOfSurvey;
}));
exports.surveyDetails = (0, toolkit_1.createAsyncThunk)(`${constants_1.SURVEY}/${constants_1.SURVEY_DETAILS}`, (contentId) => __awaiter(void 0, void 0, void 0, function* () {
    const surveyDetails = yield (0, api_1.get)(`${constants_1.DYNAMIC_SURVEY_DETAILS_ENDPOINT}/${contentId}`);
    return surveyDetails;
}));
exports.quizDetails = (0, toolkit_1.createAsyncThunk)(`${constants_1.SURVEY}/${constants_1.QUIZ_DETAILS}`, (surveyId) => __awaiter(void 0, void 0, void 0, function* () {
    const quizDetails = yield (0, api_1.get)(`${constants_1.QUIZ_DETAILS_ENDPOINT}/${surveyId}`);
    return quizDetails;
}));
exports.submitQuizResponse = (0, toolkit_1.createAsyncThunk)(`${constants_1.SURVEY}/${constants_1.SUBMIT_QUIZ_DETAILS}`, (payload) => __awaiter(void 0, void 0, void 0, function* () {
    const submitQuizResponse = yield (0, api_1.post)(`${constants_1.SUBMIT_QUIZ}`, payload);
    return submitQuizResponse;
}));
exports.getDynamicSurveyList = (0, toolkit_1.createSelector)((state) => state.survey, (survey) => { var _a; return (_a = survey === null || survey === void 0 ? void 0 : survey.dynamicSurveylistContent) === null || _a === void 0 ? void 0 : _a.DynamicSurveyList; });
exports.getDynamicSurveyDetails = (0, toolkit_1.createSelector)((state) => state.survey, (survey) => {
    var _a, _b, _c, _d, _e, _f;
    if (((_c = (_b = (_a = survey === null || survey === void 0 ? void 0 : survey.surveyDetails) === null || _a === void 0 ? void 0 : _a.surveyDetailsData) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.length) > 0) {
        return (_f = (_e = (_d = survey === null || survey === void 0 ? void 0 : survey.surveyDetails) === null || _d === void 0 ? void 0 : _d.surveyDetailsData) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f[0];
    }
});
exports.getQuizDetails = (0, toolkit_1.createSelector)((state) => state.survey, (survey) => {
    var _a, _b, _c, _d, _e;
    if (((_c = (_b = (_a = survey === null || survey === void 0 ? void 0 : survey.quizDetails) === null || _a === void 0 ? void 0 : _a.quizDetailsData) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.length) > 0) {
        return (_e = (_d = survey === null || survey === void 0 ? void 0 : survey.quizDetails) === null || _d === void 0 ? void 0 : _d.quizDetailsData) === null || _e === void 0 ? void 0 : _e.data;
    }
});
exports.getRecentSurveyList = (0, toolkit_1.createSelector)((state) => state.survey, (survey) => { var _a; return (_a = survey === null || survey === void 0 ? void 0 : survey.recentSurvey) === null || _a === void 0 ? void 0 : _a.RecentSurveyList; });
exports.getRecentSurvey = (0, toolkit_1.createSelector)((state) => state.survey, (survey) => { var _a, _b; return (_b = (_a = survey === null || survey === void 0 ? void 0 : survey.recentSurveyList) === null || _a === void 0 ? void 0 : _a.recentSurveyList) === null || _b === void 0 ? void 0 : _b.data; });
exports.getOnLoadSurveyList = (0, toolkit_1.createSelector)((state) => state.survey, (survey) => { var _a, _b; return (_b = (_a = survey === null || survey === void 0 ? void 0 : survey.dynamicSurveylistContent) === null || _a === void 0 ? void 0 : _a.onloadSurveyList) === null || _b === void 0 ? void 0 : _b.data; });
exports.getSurveysForHome = (0, toolkit_1.createSelector)((state) => state.survey, (survey) => {
    var _a;
    const surveyList = (_a = survey === null || survey === void 0 ? void 0 : survey.dynamicSurveylistContent) === null || _a === void 0 ? void 0 : _a.DynamicSurveyList;
    if (surveyList && (surveyList === null || surveyList === void 0 ? void 0 : surveyList.length) > 0) {
        return surveyList === null || surveyList === void 0 ? void 0 : surveyList.slice(0, 5);
    }
    else {
        return [];
    }
});
exports.getInitialPrefetchedSurveyList = (0, toolkit_1.createSelector)((state) => state.survey, (survey) => {
    var _a;
    return (_a = survey === null || survey === void 0 ? void 0 : survey.dynamicSurveylistContent) === null || _a === void 0 ? void 0 : _a.initialSurveyList;
});
_a = surveySlice.actions, exports.updateRecentSurveyList = _a.updateRecentSurveyList, exports.updateInitialSurveyList = _a.updateInitialSurveyList;
exports.default = surveySlice.reducer;
