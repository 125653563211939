import { useEffect, useRef, useState } from 'react';
import {
  AppConstant,
  getMilestoneData,
  ImageConstant,
  translate,
  useAppSelector,
} from '@shalina-app/shared';
import parse from 'html-react-parser';

import { MembershipBadgePathProps } from '.';
import { getImageSrc, getTranslationFormat } from '../../../../utils';
import styles from './achievements.module.scss';
import Slider from 'react-slick';

export const MembershipBadgePath: React.FC<MembershipBadgePathProps> = ({
  earnedPoints,
  achieveMilestone,
  milestoneToReach,
  apiCall,
  setApiCall,
  isLastMileStone,
}) => {
  const t = translate();
  const slideToShow = 3;
  const { SliderArrowIcon } = ImageConstant.SVG_ICONS;
  const milestoneData = useAppSelector(getMilestoneData);

  const [slideIndex, setSlideIndex] = useState<any>(0);

  const sliderRef: any = useRef(null);

  useEffect(() => {
    setApiCall(false);
  }, []);

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: slideToShow,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '28px',
        },
      },
    ],
    beforeChange: (current: any, next: any) => setSlideIndex(next),
  };

  const nextArrowClick = () => {
    sliderRef?.current?.slickNext();
  };
  const previousArrowClick = () => {
    sliderRef?.current?.slickPrev();
  };

  const percentage = !isLastMileStone
    ? (earnedPoints / milestoneData?.slice(-1)?.[0]?.points) * 100
    : 100;

  const isLastSlide = milestoneData?.length - slideToShow === slideIndex;
  const isFirstSlide = slideIndex === 0;
  const showSliderArrow = milestoneData?.length > slideToShow ? true : false;

  return (
    <>
      <div className={styles.totalPoint}>
        {getTranslationFormat(AppConstant.PROFILE.TOTAL_EARNED_TEXT)}{' '}
        <span>
          {earnedPoints} {getTranslationFormat(AppConstant.COMMON.POINTS)}
        </span>
      </div>
      <div className={styles.pointProcessWrap}>
        {milestoneData?.map((milestone: any, index: number) => (
          <div className={styles.pointbox}>
            <img
              src={getImageSrc(milestone?.field_icon)}
              className={`${styles.pointImg} ${
                earnedPoints >= milestone?.points ? styles.active : ''
              }`}
              alt='point img'
            />
            <span
              className={earnedPoints >= milestone?.points ? styles.active : ''}
            >
              {earnedPoints >= milestone?.field_points
                ? milestone?.name
                : `${milestone?.points} ${getTranslationFormat(
                    AppConstant.COMMON.POINTS
                  )}`}
            </span>
          </div>
        ))}
        <div className={styles.pointProgressBar}>
          <span
            className={styles.pointProgress}
            style={{ width: `${percentage}%` }}
          ></span>
        </div>
        {/* <div className={styles.pointProgressBar}>
          <span
            className={styles.pointProgress}
            style={{ width: `${percentage}%` }}
          ></span>
        </div> */}
      </div>
      <div className={styles.benefitList}>
        {showSliderArrow && (
          <div className={styles.titleArrow}>
            <div className={styles.sliderArrow}>
              <button
                className={styles.prev}
                onClick={previousArrowClick}
                disabled={isFirstSlide ? true : false}
              >
                <SliderArrowIcon />
              </button>
              <button
                className={styles.next}
                onClick={nextArrowClick}
                disabled={isLastSlide ? true : false}
              >
                <SliderArrowIcon />
              </button>
            </div>
          </div>
        )}

        <Slider ref={sliderRef} {...sliderSettings}>
          {milestoneData &&
            milestoneData?.length > 0 &&
            milestoneData?.map((milestone: any) => {
              const headerStyle =
                milestone.name === t(AppConstant.COMMON.BRONZE)
                  ? styles.bronze
                  : milestone.name === t(AppConstant.COMMON.SILVER)
                  ? styles.silver
                  : milestone.name === t(AppConstant.COMMON.GOLD)
                  ? styles.gold
                  : styles.bronze;

              const toReachHighLight =
                milestone?.tid === milestoneToReach?.[0]?.tid ? true : false;
              return (
                <div
                  className={`${styles.benefitCol} ${
                    toReachHighLight && styles.active
                  }`}
                >
                  <div className={styles.benefitBox}>
                    <div className={`${styles.head} ${headerStyle}`}>
                      <img
                        src={getImageSrc(milestone?.field_icon)}
                        className={styles.pointImg}
                        alt='point img'
                      />
                      <p>{`${milestone?.name} ${t(
                        AppConstant.COMMON.MEMBERSHIP_LABEL_TEXT
                      )}`}</p>
                    </div>
                    <div className={styles.benefitBoxBody}>
                      <p>
                        {(milestone?.description__value ||
                          milestone?.field_description_value) &&
                          parse(
                            (milestone.description__value ||
                              milestone.field_description_value) ??
                              ''
                          )}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
        </Slider>
      </div>
    </>
  );
};
