import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import React, { useEffect, useState } from 'react';
import {
  savePageNoLeftWhileReading,
  translate,
  useAppDispatch
} from '@shalina-app/shared';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Document, Page, pdfjs } from 'react-pdf';

import { PdfViewerProps } from './pdfViewer.interface';
import styles from './pdfViewer.module.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const ShalinaPDFViewer: React.FC<PdfViewerProps> = ({
  fileUrl,
  pageNumber,
  id,
  articleTitle,
  dbId,
  an
}) => {
  const t = translate();
  const dispatch = useAppDispatch();
  const lastReadAt = pageNumber ? pageNumber : 1;
  const [totalPages, setTotalPages] = useState(1);
  const [pageLastLeft, setPageLastLeft] = useState(lastReadAt);
  const [loading, setLoading] = useState(true);
  const [incrementDisable, setIncrementDisable] = useState(false);
  const [decrementDisable, setDecrementDisable] = useState(false);

  useEffect(() => {
    if (lastReadAt <= 1) {
      setDecrementDisable(true);
    }
    return () => {
      if (id && localStorage.getItem('lastReadAt')) {
        const payload = {
          id: parseInt(id as any),
          lastreadat:
            localStorage.getItem('lastReadAt') ===
            localStorage.getItem('totalPages')
              ? null
              : localStorage.getItem('lastReadAt'),
          article_title: articleTitle,
          dbid: dbId,
          an: an
        };
        updatePageRead(payload);
        localStorage.removeItem('lastReadAt');
        localStorage.removeItem('totalPages');
      }
    };
  }, []);

  const updatePageRead = async (payload: any) => {
    await dispatch(savePageNoLeftWhileReading(payload as any)).unwrap();
  };

  const onLoadSuccess = ({ numPages }: any) => {
    setLoading(false);
    setTotalPages(numPages);

    if (lastReadAt === numPages) {
      setIncrementDisable(true);
    }

    if (lastReadAt > numPages) {
      setPageLastLeft(1);
      localStorage.setItem('lastReadAt', '1');
    } else {
      localStorage.setItem('lastReadAt', pageLastLeft.toString());
    }

    localStorage.setItem('totalPages', numPages.toString());
  };

  const handleError = () => {
    setLoading(false);
  };

  const handlePageClick = (event: any) => {
    const { pageIndex } = event;
    if (pageIndex !== undefined) {
      const newPageNumber = pageIndex + 1;
      setPageLastLeft(newPageNumber);
      localStorage.setItem('lastReadAt', newPageNumber.toString());      
      if (newPageNumber === totalPages) {
        setIncrementDisable(true);
      } else {
        setIncrementDisable(false);
      }
      if (newPageNumber <= 1) {
        setDecrementDisable(true);
      } else {
        setDecrementDisable(false);
      }
    }
  };

  const increment = (pageNum?: number) => {
    const currentPage = pageNum ? pageNum : pageLastLeft
    const updatedValue =
    currentPage < totalPages ? currentPage + 1 : currentPage;
    setPageLastLeft(updatedValue);
    if (updatedValue === totalPages) {
      setIncrementDisable(true);
    } else {
      setIncrementDisable(false);
    }
    if (updatedValue <= 1) {
      setDecrementDisable(true);
    } else {
      setDecrementDisable(false);
    }
    localStorage.setItem('lastReadAt', updatedValue.toString());
  };

  const decrement = (pageNum?: number) => {
    const currentPage = pageNum ? pageNum : pageLastLeft
    const updatedValue = currentPage > 1 ? currentPage - 1 : currentPage;
    setPageLastLeft(updatedValue);
    if (updatedValue === totalPages) {
      setIncrementDisable(true);
    } else {
      setIncrementDisable(false);
    }
    if (updatedValue <= 1) {
      setDecrementDisable(true);
    } else {
      setDecrementDisable(false);
    }
    localStorage.setItem('lastReadAt', updatedValue.toString());
  };
  return (
    <>
      {fileUrl && (
        <>
          <div className={styles.pdfWrapper}>
            <Document
              file={fileUrl}
              onLoadSuccess={onLoadSuccess}
              onLoadError={handleError}
              onItemClick={handlePageClick} // Handle link clicks
              externalLinkTarget='_blank'
              loading={
                <Box sx={{ display: 'flex' }} className={styles.loaderWrapper}>
                  <CircularProgress
                    size={50}
                    thickness={4}
                    sx={{
                      color: 'var(--secondaryOrange)'
                    }}
                  />
                </Box>
              }
            >
              <Page pageNumber={pageLastLeft} />
            </Document>
            {!loading && (
              <div className={styles.pdfNavCta}>
                <button onClick={()=>decrement()} disabled={decrementDisable}>
                  &lt;
                </button>
                <span className={styles.pageNav}>
                  {pageLastLeft} of {totalPages}
                </span>
                <button onClick={()=>increment()} disabled={incrementDisable}>
                  &gt;
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};
