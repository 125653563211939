import React from 'react';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import FormHelperText from '@mui/material/FormHelperText';

import { RadioPropsType } from '.';
import styles from './radio.module.scss';

export const RadioGroupComponent: React.FC<RadioPropsType> = (props) => {
  const {
    formControlVariant,
    labelText,
    inputId,
    isRequired,
    radioGroupObj,
    isError,
    errorMessage,
    selectedValue,
    handleRadioChange
  } = props;
  return (
    <div className={styles['shalina-radioGroup']}>
      <FormControl variant={formControlVariant}>
        <label htmlFor={inputId} className={styles.mainLabel}>
          {labelText}
          {isRequired && <span>*</span>}
        </label>
        <RadioGroup
          row
          aria-labelledby={`${inputId}-label`}
          name={inputId}
          id={inputId}
          value={selectedValue}
          onChange={handleRadioChange}
        >
          {radioGroupObj &&
            radioGroupObj?.map((item) => (
              <FormControlLabel
                value={item.value}
                control={<Radio />}
                label={item.label}
              />
            ))}
        </RadioGroup>
        {isError && <FormHelperText error>{errorMessage}</FormHelperText>}
      </FormControl>
    </div>
  );
};
