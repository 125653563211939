import { AppConstant, translate } from '@shalina-app/shared';

import { PROFILE_PAGE_TABS } from '../../../../app.constants';
import { URLConstant } from '../../../../routes';
import { useNavigatePageUtils } from '../../../../utils';
import styles from './profileSummarySection.module.scss';

export const ProfileCompletion: React.FC<any> = ({ completionPercentage }) => {
  const t = translate();
  const navigatePageUtils = useNavigatePageUtils();
  const { urls } = URLConstant;

  const completeProfileClick = () => {
    const navigateDetails = {
      stateParams: {
        tab: PROFILE_PAGE_TABS.UPDATE_PROFILE
      },
      isDynamicUrl: false
    };
    navigatePageUtils(urls.profilePage, navigateDetails);
  };

  return (
    <div className={styles.profileCompletion}>
      <div className={styles.title}>
        {t(AppConstant.PROFILE.PROFILE_COMPLETION_TEXT)}
      </div>
      <div className={styles.profileProgressNumber}>
        {completionPercentage}% {t(AppConstant.HOME_PAGE.COMPLETED_TEXT)}
      </div>
      <div className={styles.profileProgressBar}>
        <span style={{ width: `${completionPercentage}%` }}></span>
      </div>
      {completionPercentage === 100 ? null : (
        <>
          <div className={styles.profileProgressText}>
            {t(AppConstant.HOME_PAGE.COMPLETE_PROFILE_EARN_POINTS_TEXT)}
          </div>
          <div
            onClick={completeProfileClick}
            className={styles.profileCompleteCta}
          >
            {t(AppConstant.HOME_PAGE.COMPLETE_PROFILE_CTA)}
          </div>
        </>
      )}
    </div>
  );
};
