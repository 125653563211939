import { useState } from 'react';
import {
  AppConstant,
  ImageConstant,
  getCurrentLanguageForWeb,
  translate,
  useAppSelector,
  LANGUAGE_UNIQUE_ID
} from '@shalina-app/shared';
import { Link } from 'react-router-dom';

import { NavBarPropsType } from '.';
import styles from './navBar.module.scss';
import { retrieveData, STORAGE_CONSTANTS } from '../../../../utils';
import { SignUpPopup } from '../../../Authentication';

export const NavBar: React.FC<NavBarPropsType> = ({
  menuList,
  hamburgerClick
}) => {
  const t = translate();
  const initialMenu = menuList.slice(0, 5);
  const moreMenu = menuList.slice(5, menuList.length);
  const { DropDownArrowIcon } = ImageConstant.SVG_ICONS;

  const isLoggedIn: boolean = retrieveData(STORAGE_CONSTANTS.IS_LOGGED_IN);

  const isMoreActive = moreMenu?.filter((menu) => menu?.isActive === true);
  const globalSelectedLanguage = useAppSelector(getCurrentLanguageForWeb);

  const [showSignUp, setShowSignUp] = useState(false);

  const handleSignUpClose = () => {
    setShowSignUp(false);
  };

  const handleMobileClick = () => {
    if (hamburgerClick) {
      hamburgerClick();
    }
  };

  const handleSignUpFlow = () => {
    setShowSignUp(true);
  };

  return (
    <>
      <ul
        className={`${styles.mainNav} ${
          globalSelectedLanguage?.toLowerCase() ===
            LANGUAGE_UNIQUE_ID.FRENCH?.toLowerCase() && styles.french
        } ${
          globalSelectedLanguage?.toLowerCase() ===
            LANGUAGE_UNIQUE_ID.PORTUGUESE?.toLowerCase() && styles.portugal
        }`}
      >
        {initialMenu &&
          initialMenu?.map((menuItem, index) =>
            isLoggedIn || menuItem?.allowGuestUser ? (
              <li
                className={styles.mainNavitem}
                key={index}
                onClick={handleMobileClick}
              >
                <Link
                  to={menuItem?.route}
                  className={`${styles.mainNavitemLink} ${
                    menuItem?.isActive && styles.active
                  }`}
                  state={menuItem?.state}
                >
                  {menuItem?.menuName}
                </Link>
              </li>
            ) : (
              <li className={styles.mainNavitem} key={index}>
                <div
                  onClick={handleSignUpFlow}
                  className={`${styles.mainNavitemLink} ${
                    menuItem?.isActive && styles.active
                  }`}
                >
                  {menuItem?.menuName}
                </div>
              </li>
            )
          )}
        <li className={styles.mainNavitem} key={'more'}>
          <span
            className={`${styles.mainNavitemLink} ${styles.more} ${
              isMoreActive?.length > 0 ? styles.active : ''
            }`}
          >
            {t(AppConstant.HEADER.MORE_MENU)}
            <DropDownArrowIcon />
            {/* <svg
            width="14"
            height="8"
            viewBox="0 0 14 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 0.999999L7 7L0.999999 0.999998"
              stroke="#002E5B"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg> */}

            <div className={styles.moreContainer}>
              <div className={styles.moreWrapper}>
                <ul className={styles.moreOptions}>
                  {moreMenu &&
                    moreMenu?.map((moreMenuItem, index) =>
                      isLoggedIn || moreMenuItem?.allowGuestUser ? (
                        <li key={index} onClick={handleMobileClick}>
                          <Link
                            to={moreMenuItem?.route}
                            state={moreMenuItem?.state}
                          >
                            {moreMenuItem.menuName}
                          </Link>
                        </li>
                      ) : (
                        <li key={index}>
                          <div
                            onClick={handleSignUpFlow}
                            className={styles?.moreItemNoRedirect}
                          >
                            {moreMenuItem.menuName}
                          </div>
                        </li>
                      )
                    )}
                </ul>
              </div>
            </div>
          </span>
        </li>
      </ul>
      <SignUpPopup open={showSignUp} handleClose={handleSignUpClose} />
    </>
  );
};
