import { useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FormHelperText from '@mui/material/FormHelperText';
import { ImageConstant } from '@shalina-app/shared';

import { AutoCompleteProps } from '.';
import styles from './autoComplete.module.scss';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

export const AutoComplete: React.FC<AutoCompleteProps> = ({
  options,
  id,
  labelText,
  isRequired,
  isError,
  errorMessage,
  handleChange,
  selectedOptions,
  onSelectChange,
  placeholder
}) => {
  const [sel, setSel] = useState(selectedOptions);

  useEffect(() => {
    if (selectedOptions) {
      setSel(selectedOptions);
    }
  }, [selectedOptions]);

  const selectOption = (option: any) => {
    const updatedSelectedOptions: any[] = [...sel, option];
    setSel(updatedSelectedOptions);
    // @ts-ignore: Unreachable code error
    onSelectChange(updatedSelectedOptions);
  };

  const removeOption = (option: any) => {
    const updatedSelectedOptions = sel?.filter(
      (item: any) => item?.id !== option?.id
    );
    setSel(updatedSelectedOptions);
    // @ts-ignore: Unreachable code error
    onSelectChange(updatedSelectedOptions);
  };

  const renderItem = (props: any, option: any, index: any) => {
    const isSelected = sel?.some(
      (selectedOption: any) => selectedOption.id === option.id
    );

    return (
      <li {...props} key={index} className={styles.autoCompleteLi}>
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8, alignSelf: 'flex-start' }}
          checked={isSelected}
          onChange={() =>
            isSelected ? removeOption(option) : selectOption(option)
          }
          className={styles.acCheck}
        />
        {option.label}
      </li>
    );
  };

  const chipChange = (value: any) => {
    const uniqueValues = value.reduce((acc: any, currentValue: any) => {
      if (!acc.some((item: any) => item.id === currentValue.id)) {
        acc.push(currentValue);
      } else {
        // If it's a duplicate, remove all instances of this id
        // @ts-ignore: Unreachable code error
        acc = acc.filter((item) => item.id !== currentValue.id);
      }
      return acc;
    }, []);
    setSel(uniqueValues);
    // @ts-ignore: Unreachable code error
    onSelectChange(uniqueValues);
  };
  const { CloseIcon } = ImageConstant.SVG_ICONS;
  return (
    <div className={styles.shalinaAutoComplete}>
      {labelText ? (
        <label
          htmlFor={id}
          className={`${styles.autocompleteLabel} ${'globalLabel'}`}
        >
          {labelText}
          {isRequired && <span>*</span>}
        </label>
      ) : null}
      <Autocomplete
        multiple
        freeSolo
        fullWidth
        id={id}
        options={options}
        disableCloseOnSelect
        onChange={(e, value) => chipChange(value)}
        getOptionLabel={(option: any) => option.label}
        value={sel || []}
        renderOption={(props, option, index) =>
          renderItem(props, option, index)
        }
        renderInput={(params: any) => (
          <TextField
            error={isError}
            placeholder={
              sel && sel?.length > 0 ? '' : placeholder ? placeholder : ''
            }
            {...params}
          />
        )}
        ChipProps={{ deleteIcon: <CloseIcon /> }}
      />
      {isError && <FormHelperText error>{errorMessage}</FormHelperText>}
    </div>
  );
};
