import {
  API_CATEGORY_TYPES,
  AppConstant,
  IMAGE_BASE_URL_WITHOUT_SLASH,
  translate,
} from '@shalina-app/shared';
import { UserInterface } from '.';
import { homeListingRoutes, pageNames } from '../app.constants';
import { specialityType } from '../components';

export const storeUser = (user: UserInterface) => {
  localStorage.setItem('user', JSON.stringify(user));
};

export const retrieveUser = (): UserInterface | null => {
  const userData = localStorage.getItem('user');
  return userData ? (JSON.parse(userData) as UserInterface) : null;
};

export const getImageSrc = (imagePath: string) => {
  if (imagePath) {
    if (staticImagePathExists(imagePath)) {
      return imagePath;
    } else {
      return `${IMAGE_BASE_URL_WITHOUT_SLASH}${imagePath}`;
    }
  }
  return '';
};

export const convertToSpecialties = (input: string): specialityType[] => {
  const specialtiesArray = input?.split(',').map((speciality, index) => {
    const id = 1 + index;
    return { specialityText: speciality?.trim(), id };
  });
  return specialtiesArray;
};

export const getTranslationFormat = (value: string, namespace?: string) => {
  const t = translate();
  return t(value);
};

export const isDifferenceExist = (arr1: any, arr2: any) => {
  const getDifference = (array1: any, array2: any) => {
    return array1?.filter((object1: any) => {
      return !array2?.some((object2: any) => {
        return object1?.id === object2?.id;
      });
    });
  };

  const difference = [
    ...getDifference(arr1, arr2),
    ...getDifference(arr2, arr1),
  ];
  if (difference && difference.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const removeDashWithSpaces = (text: any) => {
  let formattedText = '';
  formattedText = text?.replaceAll('-', ' ');
  return formattedText;
};

export const capitalizeEachWord = (string: any) => {
  let finalStr = '';
  const t = translate();
  const pageArr = [
    pageNames.KnowledgeHub,
    pageNames.ShalinaTv,
    pageNames.LearningCenter,
  ];

  if (pageArr?.includes(string)) {
    const pageMapping = {
      [pageNames.KnowledgeHub]: t(AppConstant.HEADER.KNOWLEDGE_HUB_MENU),
      [pageNames.ShalinaTv]: t(AppConstant.HEADER.SHALINA_TV_MENU),
      [pageNames.LearningCenter]: t(AppConstant.HEADER.LEARNING_CENTER_MENU),
    };

    finalStr = pageMapping[string] || '';
  } else {
    let updatedString = '';
    if (string?.includes('-')) {
      updatedString = removeDashWithSpaces(string);
    } else {
      updatedString = string;
    }
    const strArr = updatedString?.split(' ');
    const formatted = strArr?.map((str: string, index: number) => {
      return str?.[0]?.toUpperCase() + str?.slice(1);
    });

    finalStr = formatted?.join(' ') || '';
  }

  return finalStr;
};

const convertHyphenToSpace = (str: string) => {
  return str?.replace(/-/g, ' ');
};

// Function to capitalize the first letter of each word
const capitalizeWords = (str: string) => {
  return str?.replace(/\b\w/g, (char) => char?.toUpperCase());
};

export const transformedStrings = (str: string) => {
  if (str === pageNames.KnowledgeHub) {
    return AppConstant.HEADER.KNOWLEDGE_HUB_MENU;
  } else if (str === pageNames.ShalinaTv) {
    return AppConstant.HEADER.SHALINA_TV_MENU;
  } else if (str === pageNames.LearningCenter) {
    return AppConstant.HEADER.LEARNING_CENTER_MENU;
  } else {
    return capitalizeWords(convertHyphenToSpace(str));
  }
};

export const translatedKeys = (text: string) => {
  // Find the key for the given text
  let result = '';
  for (let iterate of Object.entries(homeListingRoutes)) {
    if (text.includes(iterate[1] as any)) {
      result = iterate[0];
    }
  }
  const values = AppConstant.HOME_PAGE as any;
  return values[result] ? values[result] : '';
};

export const encodeDecodeParams = (params: any, masterCategoryList: any) => {
  let sectionTitleValue = '';
  let contentId = '';
  let category = '';
  const masterCategorySplit = params?.contentType
    ? params?.contentType?.replaceAll('-', '')
    : null;

  const detail = masterCategoryList?.find((element: any) =>
    element?.label
      ?.replaceAll('-', '')
      ?.replaceAll('/', '')
      ?.replaceAll(' ', '')
      ?.toLowerCase()
      .includes(masterCategorySplit)
  );

  if (detail && detail?.value) {
    contentId = detail?.value;
  } else {
    sectionTitleValue = params?.contentType;
  }

  category = params?.category ? params?.category?.replaceAll('-', '_') : null;

  return {
    contentId: contentId,
    category: category,
    sectionTitle: sectionTitleValue,
  };
};

export const extractEncodeAndCreateLink = (url: string) => {
  const pathRegex = /\/(\d+)\/(\d+)$/;
  const match = url.match(pathRegex);
  const extractedPath = match ? `/${match[1]}/${match[2]}` : '';
  const encodedPath = encodeURIComponent(extractedPath);
  const encodedLink = `${url.split(/\/\d+\/\d+$/)[0]}${encodedPath}`;
  return { extractedPath, encodedLink };
};

export const decodeLink = (encodedLink: string) => {
  const decodedPath = decodeURIComponent(
    encodedLink.split('/').slice(-2).join('/')
  );

  return `${decodedPath}`;
};

export const staticImagePathExists = (text: string) => {
  if (text?.includes('https') || text?.includes('http')) {
    return true;
  } else return false;
};

export const extractIdsFromEncryptedId = (encryptedId: any) => {
  const [contentTypeID, contentID] = encryptedId.split('/').slice(1);
  return { contentTypeID, contentID };
};

export const getPageNameByCategory = (category: string) => {
  const pageMapping = {
    [API_CATEGORY_TYPES.KNOWLEDGE_HUB]: pageNames.KnowledgeHub,
    [API_CATEGORY_TYPES.SHALINA_TV]: pageNames.ShalinaTv,
    [API_CATEGORY_TYPES.LEARNING_CENTER]: pageNames.LearningCenter,
  };

  return pageMapping[category] || '';
};

export const getCategoryByPage = (pageName: string) => {
  const categoryMapping = {
    [pageNames.KnowledgeHub]: API_CATEGORY_TYPES.KNOWLEDGE_HUB,
    [pageNames.ShalinaTv]: API_CATEGORY_TYPES.SHALINA_TV,
    [pageNames.LearningCenter]: API_CATEGORY_TYPES.LEARNING_CENTER,
  };

  return categoryMapping[pageName] || '';
};

export const capitalizeFirstLetter = (word: string) => {
  return word?.replace(/^\w/, (c) => c?.toUpperCase());
};

export const detectIosDevice = () => {
  const userAgent = navigator.userAgent || navigator.vendor;
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return true;
  } else {
    return false;
  }
};

export const detectAndroidDevice = () => {
  const userAgent = navigator.userAgent || navigator.vendor;
  if (/android/i.test(userAgent)) {
    return true;
  } else {
    return false;
  }
};
