"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DosageIcon = void 0;
const React = __importStar(require("react"));
const DosageIcon = (props) => (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: 16, height: 21, fill: "none" }, props),
    React.createElement("path", { fill: "#E84E1B", stroke: "#E84E1B", strokeWidth: 0.2, d: "M13.776.5H2.21C1.11.5.222 1.42.222 2.54v15.92c0 1.107.89 2.027 1.989 2.04h11.565c1.099 0 2.002-.92 2.002-2.04V2.54c0-1.12-.903-2.04-2.002-2.04Zm.47 17.96c0 .267-.208.48-.47.48H2.224a.482.482 0 0 1-.471-.48V2.54c0-.267.21-.48.458-.48h11.565c.262 0 .47.213.47.48v15.92Z" }),
    React.createElement("path", { fill: "#E84E1B", stroke: "#E84E1B", strokeWidth: 0.2, d: "M4.369 6.127H11.9a.813.813 0 1 0 0-1.627H4.369a.813.813 0 1 0 0 1.627Z" }),
    React.createElement("path", { stroke: "#E84E1B", strokeLinejoin: "round", strokeWidth: 1.4, d: "M4.272 8.5h7.778v7.778H4.272z" }),
    React.createElement("path", { stroke: "#E84E1B", strokeWidth: 1.4, d: "M6.75 8.5v7.778M12.05 12.2H4.272M9.361 8.5v7.778" })));
exports.DosageIcon = DosageIcon;
