import {
  AppConstant,
  ImageConstant,
  SLIDE_TO_SHOW_WEB,
  translate
} from '@shalina-app/shared';

import { ExpertCard, ExpertCardContentType, ExpertSectionPropsType } from '.';
import { CardSlider } from '../../../../components';
import styles from './expertSection.module.scss';

export const ExpertSection: React.FC<ExpertSectionPropsType> = ({
  expertData,
  handleViewAllClick,
  loading
}) => {
  const t = translate();
  const { BannerBg } = ImageConstant.SVG_ICONS;
  const slidesToShow = SLIDE_TO_SHOW_WEB.OUR_EXPERTS;
  const showControl =
    expertData && expertData.length > slidesToShow ? true : false;
  const length: number = expertData?.length;

  return (
    <div className={styles.expertsSec}>
      <span className={styles.expertBg}>
        <BannerBg />
      </span>
      <CardSlider
        slidesToShow={slidesToShow}
        sliderHeading={AppConstant.HOME_PAGE.EXPERTS}
        isViewAllExist={showControl}
        showControl={showControl}
        isDataExist={expertData && expertData.length > 0 ? true : false}
        handleViewAllButtonClick={() =>
          handleViewAllClick(t(AppConstant.HOME_PAGE.EXPERTS))
        }
        isDarkBg={true}
        dataLength={length}
        loading={loading}
      >
        {expertData?.map((item: ExpertCardContentType, index: number) => (
          <ExpertCard
            id={item?.uid}
            name={`${item?.field_first_name} ${item?.field_last_name}`}
            qualification={item?.field_qualification}
            speciality={item?.field_user_specialization}
            country={item?.field_country}
            userImg={item?.user_picture}
            designation={item?.field_designation}
            description={item?.field_description}
            key={index}
          />
        ))}
      </CardSlider>
    </div>
  );
};
