import { Dialog, DialogContent } from '@mui/material';
import { AppConstant, ImageConstant, translate } from '@shalina-app/shared';
import { Link, useLocation } from 'react-router-dom';

import { SignUpPopupProps } from '.';
import { URLConstant } from '../../../../routes';
import styles from './signUpPopup.module.scss';

export const SignUpPopup: React.FC<SignUpPopupProps> = ({
  open,
  handleClose
}) => {
  const t = translate();
  const { urls } = URLConstant;
  const location = useLocation();
  const pathName = location?.pathname;

  return (
    <Dialog open={open} onClose={handleClose} className={styles.referModal}>
      <DialogContent>
        <div className={styles.signUpSec}>
          <button onClick={handleClose} className={styles.closeCta}>
            &times;
          </button>
          <img src={ImageConstant.SOURCE.guestSignup} alt='user img' />
          <p className={styles.title}>
            {t(AppConstant.AUTHENTICATION_PAGE.HOME_SIGN_UP_HEADER_TEXT)}
          </p>
          <p className={styles.desc}>
            {t(AppConstant.AUTHENTICATION_PAGE.HOME_SIGN_UP_TEXT)}
          </p>
          <Link
            to={urls.signUpPage}
            className={styles.cta}
            state={{ pathName: pathName }}
          >
            {t(AppConstant.AUTHENTICATION_PAGE.SIGN_UP_CTA)}
          </Link>
          <div className={styles.signIn}>
            <p className={styles.text}>
              {t(AppConstant.AUTHENTICATION_PAGE.ALREADY_HAVE_ACCOUNT_TEXT)}
            </p>
            <Link
              to={urls.signInPage}
              className={styles.cta}
              state={{ pathName: pathName }}
            >
              {t(AppConstant.AUTHENTICATION_PAGE.SIGN_IN_CTA)}
            </Link>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
