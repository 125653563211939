import {
  AppConstant,
  convertArrayOfObjectToString,
  featuredContent,
  getContinueWhereLeft,
  getExpertList,
  getFeaturedContent,
  getFilterCategoriesOptionsValues,
  getNewlyAddedContent,
  getProfileCompletionPercentage,
  getRecommendedForYou,
  getTrendingNow,
  getUserDetails,
  listExpert,
  listRssNewsFeed,
  rssFeedNews,
  SLIDE_TO_SHOW_WEB,
  SortFilterOptions,
  translate,
  useAppDispatch,
  useAppSelector,
  fetchHomeContent,
  updateProfileImage,
  ImageConstant,
  updateUserDetails,
  updateProfilePicture,
  getProfilePicture,
  GAMIFICATION_ACTION_ID,
  sendNotification,
  ANALYTICS_PAGE_NAMES,
  FILTER_BY_TABS_ENUM,
  fetchMyCommunityList,
  COMMUNITIES_API_CATEGORY_TYPES,
  GAMIFICATION_TYPE_UNIQUE_CODE,
  addOrUpdateUserGamificationPoints,
  listGamificationPointDistribution,
  getLoggedInUserInfo,
  HIDE_FOR_FIRST_RELEASE,
  COMMUNITIES_TABS,
  getNotificationActionList,
  logEvent,
  CUSTOM_ANALYTICS_EVENT_NAMES,
  fetchRecentSurvey,
  getRecentSurvey,
  getExploreCommunitiesListForHome,
  GAMIFICATION_ENABLED,
  PlatformNames,
  updateUserRecognition,
  handleRecognitionPopup,
  RECOGNITION_MESSAGE_TEXT,
  fetchUserRecognitionBadges,
  getAchievedRecognitionBadges,
  getRecognitionBadgesData,
  RECOGNITION_BADGES,
  COMMUNITY_ENABLED,
  getAnalyticsUserDetails,
  fetchCombinedExploreCommunity,
  fetchDynamicListSurvey,
  getSurveysForHome,
} from '@shalina-app/shared';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { HomePageProps } from '.';
import { SignUpPopup } from '..';
import {
  ABOUT_US_PAGE_TABS,
  DEFAULT_SHORT_TAKE,
  FEATURED_TAKE,
  homeListingRoutes,
  pageNames,
} from '../../app.constants';
import { appInsights } from '../../applicationInsight';
import {
  BarLoader,
  CircularLoader,
  ContentCardType,
  ContentSlider,
  ExpertCardContentProps,
  QuickLinkSection,
  SortFilter,
  Toast,
} from '../../components';
import { URLConstant } from '../../routes';
import {
  retrieveData,
  STORAGE_CONSTANTS,
  storeAccessToken,
  storeData,
  storeProfilePic,
  useNavigatePageUtils,
  UserInterface,
} from '../../utils';
import {
  CookiesSection,
  ExpertSection,
  ExploreCommunitySection,
  ExploreMoreSection,
  HelpDesk,
  HomeBanner,
  ProfileCompletion,
  ProfileSummarySection,
  QuickMenu,
  RssFeedSlider,
  SignUpSection,
  SurveySection,
} from './components';
import styles from './home.module.scss';

export const HomePage: React.FC<HomePageProps> = () => {
  const dispatch = useAppDispatch();
  const t = translate();
  const navigatePageUtils = useNavigatePageUtils();
  const { urls } = URLConstant;
  const location = useLocation();

  const sendNotificationParam = location?.state || false;
  const reloadAfterSignOut = location?.state?.reload;

  const defaultFilterOptions = {
    contentType: '',
    interest: '',
    sort: '',
    speciality: '',
    skip: 0,
    take: DEFAULT_SHORT_TAKE,
  };
  const rssFeedFilterOptions = {
    page: 'home',
  };
  const [filterCall, setFilterCall] = useState(false);
  const [filterOptions, setFilterOptions] =
    useState<SortFilterOptions>(defaultFilterOptions);
  const userInfo = useAppSelector(getLoggedInUserInfo);
  const user: UserInterface | any = retrieveData(STORAGE_CONSTANTS.USER);
  const isLoggedIn: boolean = retrieveData(STORAGE_CONSTANTS.IS_LOGGED_IN);

  const [showHelpDesk, setShowHelpDesk] = useState(false);
  const [toggleRequestHelpDesk, setToggleRequestHelpDesk] = useState(true);
  const [apiCall, setApiCall] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastSubMessage, setToastSubMessage] = useState('');
  const [completedProfile, setCompletedProfile] = useState(false);
  const [sendNotificationState, setSendNotificationState] = useState(false);
  const [selectedAreaOfInterest, setSelectedAreaOfInterest] = useState([]);
  const [peerLearnerObj, setPeerLearnerObj] = useState<any>({});
  const [showPeerLearnerPopup, setShowPeerLearnerPopup] = useState(false);

  const notificationList = useAppSelector(getNotificationActionList);
  const recognitionBadgesList = useAppSelector(getRecognitionBadgesData);
  const achievedRecognitionBadges = useAppSelector(
    getAchievedRecognitionBadges
  );

  const handleSignUpClose = () => {
    setShowSignUp(false);
  };

  const handleSignUpFlow = () => {
    setShowSignUp(true);
  };

  const handleHelpDeskClick = () => {
    const navigateDetails = {
      isDynamicUrl: false,
    };
    navigatePageUtils(urls.helpSupport, navigateDetails);
    // setShowHelpDesk(true);
  };

  const handleHelpDeskClose = () => {
    setShowHelpDesk(false);
  };

  const handleHelpAction = () => {
    setToggleRequestHelpDesk(false);
  };

  useEffect(() => {
    if (reloadAfterSignOut && !isLoggedIn) {
      setApiCall(true);
    }
  }, [reloadAfterSignOut]);

  useEffect(() => {
    window.scrollTo(0, 0);
    logEvent(appInsights, 'pageView', ANALYTICS_PAGE_NAMES.HOME);
    setInitialValue();
    setApiCall(true);
    if (isLoggedIn) {
      setSendNotificationState(true);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    setInitialValue();
  }, [isLoggedIn]);

  const setInitialValue = () => {
    if (
      (![null, undefined, []].includes(userInfo?.areaOfInterest) ||
        ![null, undefined, []].includes(user?.areaOfInterest)) &&
      isLoggedIn
    ) {
      const userDetails = userInfo?.areaOfInterest ?? user?.areaOfInterest;
      const areaOfInterestIds = userDetails?.map(
        (item: any) => item?.tid && String(item?.tid)
      );
      setSelectedAreaOfInterest(areaOfInterestIds);
    } else {
      setSelectedAreaOfInterest([]);
    }
  };

  useEffect(() => {
    if (apiCall) {
      loadApi();
      if (isLoggedIn) {
        loadUser();
        if (GAMIFICATION_ENABLED) {
          loadRecognitionBadges();
        }
      }
      setApiCall(false);
    }
  }, [apiCall]);

  useEffect(() => {
    if (
      notificationList &&
      notificationList?.length > 0 &&
      sendNotificationState &&
      sendNotificationParam
    ) {
      sendNotificationApi();
      setSendNotificationState(false);
    }
  }, [sendNotificationState, notificationList]);

  const loadRecognitionBadges = async () => {
    const res = await dispatch(fetchUserRecognitionBadges()).unwrap();
    const peerLearnerObj = recognitionBadgesList?.find(
      (item: any) => item?.field_unique_code === RECOGNITION_BADGES.PEER_LEARNER
    );

    if (
      res &&
      res?.statusCode === 200 &&
      res?.status === 'SUCCESS' &&
      res?.data
    ) {
      const filterData = res?.data?.filter(
        (item: any) => item?.recognitionMatrixId == peerLearnerObj?.tid
      )?.[0];
      
      if (filterData && !filterData?.isseen) {
        const obj = {
          showRecognitionPopup: true,
          recognitionData: peerLearnerObj,
        };
        dispatch(handleRecognitionPopup(obj));
      }
      // setShowPeerLearnerPopup(true);
      // setPeerLearnerObj(filterData);
    } else {
      setShowPeerLearnerPopup(false);
    }
  };

  useEffect(() => {
    const peerObj = recognitionBadgesList?.find(
      (item: any) => item?.field_unique_code === RECOGNITION_BADGES.PEER_LEARNER
    );

    // console.log('peerObj', peerObj, showPeerLearnerPopup);
    
    if (showPeerLearnerPopup) {
      // if (!peerLearnerObj?.isseen) {
      //   const obj = {
      //     showRecognitionPopup: true,
      //     recognitionData: peerObj,
      //   };
      //   dispatch(handleRecognitionPopup(obj));
      // }
    }
  }, [peerLearnerObj, showPeerLearnerPopup, recognitionBadgesList]);

  const sendNotificationApi = async () => {
    const action = notificationList?.find(
      (item: any) =>
        item?.field_unique_code ===
        GAMIFICATION_ACTION_ID.CONTINUE_WHERE_YOU_LEFT
    )?.title;

    const req = {
      action: action,
      device: 'web',
    };
    await dispatch(sendNotification(req));
  };

  const sendNotificationApiForProfileCompletion = async () => {
    const action = notificationList?.find(
      (item: any) =>
        item?.field_unique_code === GAMIFICATION_ACTION_ID.PROFILE_COMPLETION
    )?.title;

    const req = {
      action: action,
      device: 'web',
    };
    await dispatch(sendNotification(req));
  };

  const loadApi = async () => {
    dispatch(
      featuredContent({
        ...defaultFilterOptions,
        take: FEATURED_TAKE,
      })
    );
    dispatch(fetchHomeContent(filterOptions));
    dispatch(listExpert(defaultFilterOptions));
    dispatch(rssFeedNews(rssFeedFilterOptions as any));
    if (!HIDE_FOR_FIRST_RELEASE) {
      dispatch(
        fetchDynamicListSurvey({
          skip: 0,
          take: DEFAULT_SHORT_TAKE,
          interest: '',
          speciality: '',
          recent: false,
        })
      );
      dispatch(
        fetchCombinedExploreCommunity({
          skip: 0,
          take: DEFAULT_SHORT_TAKE,
          category: COMMUNITIES_API_CATEGORY_TYPES.EXPLORE_COMMUNITY,
          type: 'open',
          isLoggedIn: isLoggedIn
        })
      );
    }
    if (isLoggedIn && GAMIFICATION_ENABLED) {
      checkRecognition();
    }
  };

  const checkRecognition = async () => {
    const response = await dispatch(
      updateUserRecognition({
        visit: 1,
      })
    ).unwrap();
    if (
      response &&
      response?.status === 'SUCCESS' &&
      response?.statusCode === 200 &&
      response?.message === RECOGNITION_MESSAGE_TEXT.CONSISTENT_READER &&
      response?.data
    ) {
      const obj = {
        showRecognitionPopup: true,
        recognitionData: response?.data,
      };
      dispatch(handleRecognitionPopup(obj));
    }
  };

  const loadUser = async () => {
    const userInfo: any = await dispatch(getUserDetails()).unwrap();
    if (
      userInfo &&
      userInfo?.status === 'SUCCESS' &&
      userInfo?.statusCode === 200
    ) {
      storeData(STORAGE_CONSTANTS.USER, userInfo?.data);
    }
  };

  useEffect(() => {
    if (filterCall) {
      loadFilterApi();
    }
  }, [filterCall]);

  const loadFilterApi = async () => {
    const response = await dispatch(fetchHomeContent(filterOptions)).unwrap();

    if (response) {
      setFilterCall(false);
    }
  };
  const isFeaturedLoading = useAppSelector(
    (state) => state?.home?.featuredList?.isFeaturedLoading
  );
  const isHomeContentLoading = useAppSelector(
    (state) => state?.home?.homeContent?.isHomeContentLoading
  );
  const isExpertLoading = useAppSelector(
    (state) => state?.home?.listExpert?.isExpertLoading
  );
  const isCommunitiesListingLoading = useAppSelector(
    (state) => state?.community?.communitiesList?.isCombinedExploreListLoading
  );
  const isMasterDataLoading = useAppSelector(
    (state) => state?.common?.masterData?.masterDataLoading
  );
  const isUpdateProfilePicLoading = useAppSelector(
    (state) => state?.profile?.updateProfilePic?.isUpdateProfilePicLoading
  );
  const isSurveyDataLoading = useAppSelector(
    (state) => state?.survey?.dynamicSurveylistContent?.isDynamicSurveyListLoading
  );

  const trendingData: ContentCardType[] = useAppSelector(getTrendingNow);
  const newlyAddedData: ContentCardType[] =
    useAppSelector(getNewlyAddedContent);
  const listExperts: ExpertCardContentProps[] = useAppSelector(getExpertList);
  const featuredHomeBanner: ContentCardType[] =
    useAppSelector(getFeaturedContent);
  const continueWhereLeftData: ContentCardType[] =
    useAppSelector(getContinueWhereLeft);
  const recommendedForYouData: ContentCardType[] =
    useAppSelector(getRecommendedForYou);
  const exploreOptions: any = useAppSelector(getFilterCategoriesOptionsValues);

  const listOfSurveyData = useAppSelector(getSurveysForHome);
  const communityData = useAppSelector(getExploreCommunitiesListForHome);
  const rssFeedNewsData: any = useAppSelector(listRssNewsFeed);
  const rssLoader = useAppSelector(
    (state) => state?.home?.listRssNewsFeed.isListRssNewsFeedLoading
  );
  const gamificationPointDistributionData = useAppSelector(
    listGamificationPointDistribution
  );

  const featuredLoading =
    !featuredHomeBanner || featuredHomeBanner?.length === 0
      ? isFeaturedLoading
      : false;
  const recommendedLoading =
    !recommendedForYouData || recommendedForYouData?.length === 0
      ? isHomeContentLoading
      : false;
  const continueLeftLoading =
    !continueWhereLeftData || continueWhereLeftData?.length === 0
      ? isHomeContentLoading
      : false;
  const trendingLoading =
    !trendingData || trendingData?.length === 0 ? isHomeContentLoading : false;
  const expertsLoading =
    !listExperts || listExperts?.length === 0 ? isExpertLoading : false;
  const newlyAddedLoading =
    !newlyAddedData || newlyAddedData?.length === 0
      ? isHomeContentLoading
      : false;
  const rssLoading =
    !rssFeedNewsData || rssFeedNewsData?.length === 0 ? rssLoader : false;
  const contentTypeLoading =
    !exploreOptions || exploreOptions?.length === 0
      ? isMasterDataLoading
      : false;
  const communityDataLoading =
    !communityData || communityData?.length === 0
      ? isCommunitiesListingLoading
      : false;

  const handleViewAllClick = (sectionTitle: string) => {
    if (sectionTitle === homeListingRoutes.EXPLORE_COMMUNITIES) {
      const navigateDetails = {
        stateParams: {
          selectedTab: COMMUNITIES_TABS?.EXPLORE_COMMUNITIES,
        },
        isDynamicUrl: false,
      };
      navigatePageUtils(urls.commiunitiesPage, navigateDetails);
    } else if (sectionTitle) {
      const navigateDetails = {
        stateParams: {
          parentSelectedAreaOfInterest: filterOptions?.interest,
          parentCategory: null,
          filterRetain: true,
          userSelectedAreaOfInterest:
            filterOptions?.interest || selectedAreaOfInterest?.toString(),
        },
        isDynamicUrl: true,
        dynamicUrlOptions: {
          sectionTitle: sectionTitle,
        },
      };
      // dispatch(isFilterPersist(true));
      navigatePageUtils(urls.listingPage, navigateDetails);
    }
  };

  const handleExpertsViewAllClick = () => {
    const navigateDetails = {
      stateParams: {
        tab: ABOUT_US_PAGE_TABS.EXPERTS,
      },
      isDynamicUrl: false,
    };
    navigatePageUtils(urls.aboutUsPage, navigateDetails);
  };

  const onApplyButtonClick = (
    selectedContentType: Array<string>,
    selectedSpecialization: Array<string>
  ) => {
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      speciality: selectedSpecialization.toString(),
      contentType: selectedContentType.toString(),
    }));
    setFilterCall(true);
  };

  const onSortChange = (value: string) => {
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      sort: value,
    }));
    setFilterCall(true);
  };

  const onAreaOfInterest = (value: string[]) => {
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      interest: value.toString(),
    }));
    setFilterCall(true);
  };

  const onResetFilter = () => {
    setFilterOptions((prev) => ({
      ...prev,
      contentType: '',
      speciality: '',
    }));
    setFilterCall(true);
  };

  const handleSignUpClick = () => {
    const navigateDetails = {
      isDynamicUrl: false,
    };
    navigatePageUtils(urls.signUpPage, navigateDetails);
  };

  const handleRssViewAll = () => {
    const navigateDetails = {
      isDynamicUrl: false,
    };
    navigatePageUtils(urls.rssListing, navigateDetails);
  };

  const handleRssCardClick = (rssId: string) => {
    if (rssId) {
      const rssData = rssFeedNewsData?.length
        ? rssFeedNewsData?.find((rssItem: any) => rssItem?.id === rssId)
        : [];

      const navigateDetails = {
        stateParams: {
          rssContentData: rssData,
        },
        isDynamicUrl: false,
      };
      navigatePageUtils(urls.rssDetail, navigateDetails);
    }
  };

  const sendNotificationForMilestoneUpdate = async () => {
    const action = notificationList?.find(
      (item: any) =>
        item?.field_unique_code === GAMIFICATION_ACTION_ID.UPDATE_MILESTONE
    )?.title;

    const req = {
      action: action,
      device: 'web',
    };
    // Hide Continue where you left feature
    await dispatch(sendNotification(req));
  };

  const updateGamificationPoints = async () => {
    if (isLoggedIn && GAMIFICATION_ENABLED) {
      const gamificationDataItem = gamificationPointDistributionData?.find(
        (item: any) =>
          item.field_unique_code ===
          GAMIFICATION_TYPE_UNIQUE_CODE.PROFILE_COMPLETION
      );
      if (gamificationDataItem && gamificationDataItem?.field_unique_code) {
        const payload = {
          field_unique_code: gamificationDataItem?.field_unique_code,
        };
        const response: any = await dispatch(addOrUpdateUserGamificationPoints(payload)).unwrap();
        if(response && response?.info) {
          sendNotificationForMilestoneUpdate()
        }
      }
    }
  };

  const handleSaveProfilePic = async (binaryData: any, removePic: boolean) => {
    if (removePic) {
      const req = {
        userPicture: '',
      };
      dispatch(updateProfileImage(ImageConstant.SOURCE.defaultUserPic));
      const response = await dispatch(updateUserDetails(req)).unwrap();
      if (
        response &&
        response?.status === 'SUCCESS' &&
        response?.statusCode === 200
      ) {
        if (response?.data?.token) {
          storeAccessToken(response?.data?.token);
        }
        setShowToast(true);
        setIsSuccess(true);
        setToastMessage(t(response?.message));
        // setToastSubMessage("Your profile photo has been successfully changed.");

        storeProfilePic(response?.data?.userPicture);
        storeData(STORAGE_CONSTANTS.USER, response?.data);
      } else {
        setShowToast(true);
        setIsSuccess(false);
        setToastMessage(t(AppConstant.ERROR.PROFILE_PICTURE_UPLOAD_FAILED));
        setToastSubMessage(t(response?.message));
      }
    } else {
      const response = await dispatch(
        updateProfilePicture(binaryData)
      ).unwrap();
      if (
        response &&
        response?.status === 'SUCCESS' &&
        response?.statusCode === 200
      ) {
        setShowToast(true);
        setIsSuccess(true);
        setToastMessage(t(response?.message));
        // setToastSubMessage("Your profile photo has been successfully changed.");

        dispatch(updateProfileImage(response?.data?.userPicture));
        storeProfilePic(response?.data?.userPicture);

        const res = await dispatch(getUserDetails()).unwrap();
        if (res && res?.status === 'SUCCESS' && res?.statusCode === 200) {
          const storedUserPercentage = getProfileCompletionPercentage(user);
          const percentage = getProfileCompletionPercentage(res?.data);
          const userData = getAnalyticsUserDetails(res?.data);

          if (storedUserPercentage !== 100 && percentage === 100) {
            if (isLoggedIn) {
              logEvent(
                appInsights,
                'custom',
                CUSTOM_ANALYTICS_EVENT_NAMES.USER_PROFILE_COMPLETION,
                {
                  percentage: 100,
                  userEmail: userData?.emailId,
                  userMobile: userData?.mobile,
                  userName: userData?.name,
                  userQualification: userData?.qualification,
                  userInterest: userData?.areaOfInterest,
                  userSpecialization: userData?.specialization,
                  userCountryOfPractice: userData?.countryOfPractice,
                  userPreferredLanguage: userData?.preferredLanguage,
                  platform: PlatformNames.WEB,
                }
              );
            }
            await updateGamificationPoints();
            loadUser();
            sendNotificationApiForProfileCompletion();
            setCompletedProfile(true);
            storeData(STORAGE_CONSTANTS.USER, res?.data);
          } else if (percentage !== 100) {
            if (isLoggedIn) {
              logEvent(
                appInsights,
                'custom',
                CUSTOM_ANALYTICS_EVENT_NAMES.USER_PROFILE_INCOMPLETE,
                {
                  percentage: percentage,
                  userEmail: userData?.emailId,
                  userMobile: userData?.mobile,
                  userName: userData?.name,
                  userQualification: userData?.qualification,
                  userInterest: userData?.areaOfInterest,
                  userSpecialization: userData?.specialization,
                  userCountryOfPractice: userData?.countryOfPractice,
                  userPreferredLanguage: userData?.preferredLanguage,
                  platform: PlatformNames.WEB,
                }
              );
            }
            storeData(STORAGE_CONSTANTS.USER, res?.data);
            // refreshPage();
          }
        }
      } else {
        setShowToast(true);
        setIsSuccess(false);
        setToastMessage(t(AppConstant.ERROR.PROFILE_PICTURE_UPLOAD_FAILED));
        setToastSubMessage(t(response?.message));
      }
    }
  };

  useEffect(() => {
    if (completedProfile) {
      sendNotificationCall();
      setCompletedProfile(false);
    }
  }, [completedProfile]);

  const sendNotificationCall = async () => {
    // const action = gamificationData?.find(
    //   (item: any) =>
    //     item?.field_unique_code === GAMIFICATION_ACTION_ID.PROFILE_COMPLETION
    // )?.title;
    // const req = {
    //   action: action,
    // };
  };

  const handleToastClose = () => {
    setShowToast(false);
  };

  const qualification = convertArrayOfObjectToString(
    user?.qualification,
    'title'
  );
  const specialization = convertArrayOfObjectToString(
    user?.specialization,
    'title'
  );
  let role = '';
  if (qualification && specialization) {
    role = `${qualification} | ${specialization}`;
  } else if (qualification) {
    role = `${qualification}`;
  } else if (specialization) {
    role = `${specialization}`;
  }

  const completionPercentage = getProfileCompletionPercentage(user);
  const isLastMileStone = user?.milestoneToReach?.length === 0;

  const profilePic = useAppSelector(getProfilePicture);
  return (
    <div className={styles.homePage}>
      <div className='cust-container'>
        <div className={styles.homeRow}>
          <div className={styles.homeCol8}>
            <HomeBanner
              contentData={featuredHomeBanner}
              pageName={pageNames.Home}
              pageRoute={urls.homePage}
              isFeaturedLoading={featuredLoading}
            />

            <QuickMenu
              selectedAreaOfInterest={selectedAreaOfInterest}
              filterOptionsInterest={filterOptions?.interest}
            />

            {/* filter section start */}
            <div className={styles.homeSortWrapper}>
              <div className={styles.homeSort}>
                <SortFilter
                  isDark={true}
                  handleApplyFilter={onApplyButtonClick}
                  handleSortChange={onSortChange}
                  handleAreaOfInterest={onAreaOfInterest}
                  handleResetFilter={onResetFilter}
                  tabsEnum={[
                    FILTER_BY_TABS_ENUM.CONTENT_TYPE,
                    FILTER_BY_TABS_ENUM.SPECIALITY,
                  ]}
                  selectedAreaOfInterest={selectedAreaOfInterest}
                />
              </div>
              {(filterCall ||
                isHomeContentLoading ||
                isCommunitiesListingLoading ||
                isExpertLoading) && (
                <div className={styles.loaderDiv}>
                  <BarLoader />
                </div>
              )}
            </div>

            {/* filter section end */}

            {/* Recommendation & Continue Where Left Off section start */}
            {isLoggedIn ? (
              <>
                {continueWhereLeftData?.length === 0 ? (
                  <></>
                ) : (
                  <div className={styles.homeSliderSec}>
                    <ContentSlider
                      data={continueWhereLeftData}
                      contentHeading={
                        AppConstant.HOME_PAGE.CONTINUE_WHERE_LEFT_OFF
                      }
                      slidesToShow={SLIDE_TO_SHOW_WEB.DEFAULT}
                      isViewAllExist={true}
                      pageName={pageNames.Home}
                      pageRoute={urls.homePage}
                      handleViewAllClick={() =>
                        handleViewAllClick(
                          homeListingRoutes.CONTINUE_WHERE_YOU_LEFT_OFF
                        )
                      }
                      loading={continueLeftLoading}
                    />
                  </div>
                )}
                <div className={styles.homeSliderSec}>
                  <ContentSlider
                    data={recommendedForYouData}
                    contentHeading={AppConstant.HOME_PAGE.RECOMMENDED_FOR_YOU}
                    slidesToShow={SLIDE_TO_SHOW_WEB.DEFAULT}
                    isViewAllExist={true}
                    pageName={pageNames.Home}
                    pageRoute={urls.homePage}
                    handleViewAllClick={() =>
                      handleViewAllClick(homeListingRoutes.RECOMMENDED_FOR_YOU)
                    }
                    loading={recommendedLoading}
                  />
                </div>
              </>
            ) : (
              <></>
            )}
            {/* Recommendation & Continue Where Left Off section End */}

            {/* Trending Now section start */}
            <div className={styles.homeSliderSec}>
              <ContentSlider
                data={trendingData}
                contentHeading={AppConstant.HOME_PAGE.TRENDING_NOW}
                slidesToShow={SLIDE_TO_SHOW_WEB.DEFAULT}
                isViewAllExist={true}
                pageName={pageNames.Home}
                pageRoute={urls.homePage}
                handleViewAllClick={() =>
                  handleViewAllClick(homeListingRoutes.TRENDING_NOW)
                }
                loading={trendingLoading}
              />
            </div>

            {/* Trending Now section end */}

            {/* Experts section start */}
            <div className={styles.homeSliderSec}>
              <ExpertSection
                expertData={listExperts}
                handleViewAllClick={handleExpertsViewAllClick}
                loading={expertsLoading}
              />
            </div>
            {/* Experts section end */}

            {
              <div className={styles.homeSliderSec}>
                <ContentSlider
                  data={newlyAddedData}
                  contentHeading={AppConstant.HOME_PAGE.NEWLY_ADDED}
                  slidesToShow={SLIDE_TO_SHOW_WEB.DEFAULT}
                  isViewAllExist={true}
                  pageName={pageNames.Home}
                  pageRoute={urls.homePage}
                  handleViewAllClick={() =>
                    handleViewAllClick(homeListingRoutes.NEWLY_ADDED)
                  }
                  loading={newlyAddedLoading}
                />
              </div>
            }

            {COMMUNITY_ENABLED && (
              <div className={styles.homeSliderSec}>
                <ExploreCommunitySection
                  communityData={communityData}
                  handleViewAllClick={handleViewAllClick}
                  isLoggedIn={isLoggedIn}
                  loading={communityDataLoading}
                />
              </div>
            )}

            {/* RSS News Feed Section Start */}
            <div className={styles.homeSliderSec}>
              <RssFeedSlider
                slidesToShow={SLIDE_TO_SHOW_WEB.DEFAULT}
                isViewAllExist={true}
                data={rssFeedNewsData}
                showControl={true}
                contentHeading={AppConstant.HOME_PAGE.MEDICAL_NEWS}
                handleRssCardClick={handleRssCardClick}
                handleViewAllClick={handleRssViewAll}
                loading={rssLoading}
              />
            </div>
            {/* RSS News Feed Section End */}

            <div
              className={
                listOfSurveyData?.length > 0
                  ? styles.homeSliderSec
                  : `${styles.homeSliderSec} ${styles.noBorder}`
              }
            >
              <SurveySection
                data={listOfSurveyData}
                handleSignUpFlow={handleSignUpFlow}
                isLoggedIn={isLoggedIn}
                isFeaturedLoading={isSurveyDataLoading}
              />
            </div>

            {/* Explore more section start */}
            <div className={`${styles.homeSliderSec} ${styles.noBorder}`}>
              <ExploreMoreSection
                exploreOptions={exploreOptions}
                handleViewAllClick={handleViewAllClick}
                loading={contentTypeLoading}
                selectedAreaOfInterest={selectedAreaOfInterest}
                filterOptionsInterest={filterOptions?.interest}
              />
            </div>
            {/* Explore more section end */}

            <HelpDesk
              handleClickOpen={handleHelpDeskClick}
              handleClose={handleHelpDeskClose}
              handleHelpAction={handleHelpAction}
              open={showHelpDesk}
              toggleRequestHelpDesk={toggleRequestHelpDesk}
            />
            {/* Hide for 1A */}
          </div>

          <div className={styles.homeCol4}>
            {isLoggedIn ? (
              <>
                <ProfileSummarySection
                  earnedPoints={userInfo?.points || user?.points}
                  userName={`${user?.firstName} ${user?.lastName}`}
                  userRole={role}
                  achieveMilestone={userInfo?.milestone || user?.milestone}
                  milestoneToReach={
                    userInfo?.milestoneToReach || user?.milestoneToReach
                  }
                  handleSaveProfilePic={handleSaveProfilePic}
                  profilePic={profilePic}
                  isLastMileStone={isLastMileStone}
                />

                <ProfileCompletion
                  completionPercentage={completionPercentage}
                />
              </>
            ) : (
              <SignUpSection
                mainText={t(
                  AppConstant.AUTHENTICATION_PAGE.HOME_SIGN_UP_HEADER_TEXT
                )}
                subText={t(AppConstant.AUTHENTICATION_PAGE.HOME_SIGN_UP_TEXT)}
              />
            )}
            <div className={styles.homeQuicklink}>
              <QuickLinkSection />
            </div>
          </div>
        </div>
      </div>
      <Toast
        showToast={showToast}
        isSuccess={isSuccess}
        message={toastMessage}
        subMessage={toastSubMessage}
        handleToastClose={handleToastClose}
      />
      {isUpdateProfilePicLoading && <CircularLoader size={50} thickness={4} />}
      <CookiesSection />
      <SignUpPopup open={showSignUp} handleClose={handleSignUpClose} />
    </div>
  );
};
